import React from "react";
import * as ANTD from "antd";
import "./TicketCancel.scss";
import { Link } from "react-router-dom";
import { Form, Input, Modal, Select,Menu } from 'antd';


const TicketCancel = () => {

    const { Header, Footer, Sider, Content } = ANTD.Layout;
    const [modalVisible, setModalVisible] = React.useState(false);
    const [formLayout, setFormLayout] = React.useState('vertical');



   
    const tailLayout = {
        wrapperCol: {  span: 24 },
    };

    const showModalnew = () => {
        setModalVisible({
            visible: true,
        });
    }


    

    

    return (
        <div>
            <div className="promo providerapi">
                <ANTD.Layout>
                    <div className="manage-markup-section">
                        <Content className="admin-container">
                            <ANTD.Row>
                                <ANTD.Col span={24}>
                                    <div className="card-bt-gap">

                                        <div className="card-add-heading">
                                            <h3>Cancel Ticket</h3>

                                        </div>

                                    </div>
                                </ANTD.Col>
                            </ANTD.Row>


                            <ANTD.Row>
                                <ANTD.Col span={24}>
                                    <ANTD.Card >
                                        <div className="">
                                            <h5>Enter Ticket Details</h5>
                                            <p>Check your email or SMS that you received while booking</p>
                                        </div>
                                        <ANTD.Form
                                           
                                            name="basic"
                                            initialValues={{ remember: true }}

                                        >

                                            <ANTD.Row gutter={24}>
                                                <ANTD.Col className="gutter-class" md={8} xs={24}>


                                                <ANTD.Form.Item
        label="Reference ID"
        name="Reference ID"
        rules={[{  message: 'Please input your username!' }]}
      >
        <ANTD.Input 
 autocomplete="newpassword"  />
      </ANTD.Form.Item>


                                                </ANTD.Col>

                                                <ANTD.Col className="gutter-class" md={8} xs={24}>


                                                <ANTD.Form.Item
        label="Mobile"
        name="Mobile"
        rules={[{  message: 'Please input your username!' }]}
      >
        <ANTD.Input 
 autocomplete="newpassword"  />
      </ANTD.Form.Item>


                                                </ANTD.Col>



                                                <ANTD.Col className="gutter-class" md={8} xs={24}>


<ANTD.Form.Item

>
<ANTD.Button type="primary" onClick={showModalnew} className="retrive-btn"   >Retrieve</ANTD.Button>

</ANTD.Form.Item>


</ANTD.Col>




                                                



                                            </ANTD.Row>



                                        </ANTD.Form>
                                    </ANTD.Card>
                                </ANTD.Col>
                            </ANTD.Row>


                        </Content>


                    </div>
                </ANTD.Layout>


                <Modal title={[<div><h6 style={{ marginBottom: "0px" }}><strong>Cancel Ticket</strong></h6></div>]}
                    className="promo-modal-header cancel-modal" visible={modalVisible}
                  onOk={(e) => (setModalVisible(false))} onCancel={(e) => (setModalVisible(false))} 
                  footer={[
                    <div>
                        <ANTD.Button key="add" type="primary"  >Cancel Ticket</ANTD.Button>
                       
                    </div>
                ]}
                   >
                     

                     <ANTD.Form>
                        <ANTD.Row>


                        <ANTD.Col md={24} xs={24}>

                            <p className="refence-tic"><b>Reference Number:</b>   12345678</p>
                            <p className="refence-tic"><b>Journey Details:</b>   Hyderabad <b>To</b> Bangalore on 20-09-2020 (Sangamitra -SemiSleeper AC)</p>
                            <p className="refence-tic"><b>Total Fare:</b>   1400</p>
                            <p className="refence-tic"><b>Passenger Details:</b>   Name</p>
                            <p className="refence-tic"><b>Cancellation Policy:</b>   Time:24:00 Charges:1:10:0</p>

                             
                             
                        
                      {/*   <ANTD.Descriptions>
    <ANTD.Descriptions.Item className="refence-tic" label="Reference Number">   12345678   </ANTD.Descriptions.Item>
    
  </ANTD.Descriptions>

    
  <ANTD.Descriptions>
    <ANTD.Descriptions.Item className="refence-tic"  label ="Journey Details">   Hyderabad <b>To</b> Bangalore on 20-09-2020 (Sangamitra -SemiSleeper AC)
   sed do eiusmod </ANTD.Descriptions.Item>
    
  </ANTD.Descriptions>

  <ANTD.Descriptions>
    <ANTD.Descriptions.Item className="refence-tic"  label ="Total Fare">1400
  </ANTD.Descriptions.Item>
    
  </ANTD.Descriptions>

  <ANTD.Descriptions>
    <ANTD.Descriptions.Item className="refence-tic"  label ="Passenger Details">Time:24:00 Charges:1:10:0</ANTD.Descriptions.Item>
    
  </ANTD.Descriptions>

  <ANTD.Descriptions>
    <ANTD.Descriptions.Item className="refence-tic"  label ="Cancellation Policy">Time:24:00 Charges:1:10:0</ANTD.Descriptions.Item>
    
  </ANTD.Descriptions>

   */}

                            </ANTD.Col>
                            </ANTD.Row>
                            </ANTD.Form>







                        </Modal>
            </div>
        </div>


    )







}
export default TicketCancel;