import {
  LineOutlined,
  ClockCircleOutlined,
  MailOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Tabs,
  Tooltip,
} from "antd";
import "antd/dist/antd.css";
import dateFormat from "dateformat";
import "font-awesome/css/font-awesome.min.css";
import moment from "moment";
import React, { useState } from "react";

import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import ApiClient from "../../../helpers/ApiClient";
import "./Flight.scss";

const formatFlightTime = (flightTime) => {
  let [date, time] = flightTime.split("T");
  let [hour, minute, seconds] = time.split(":");
  return `${hour}:${minute}`;
};

const calculateDuration = (from, to) => {



  let FromDate = moment(from);
  let ToDate = moment(to)

  let numberOfHours = ToDate.diff(FromDate, "hours");
  let numerOfMinutes = ToDate.diff(FromDate, "minutes");


  let days = Math.floor(numberOfHours / 24);
  let Remainder = numberOfHours % 24;
  let hours = Math.floor(Remainder);
  let minutes = Math.floor(numerOfMinutes - 60 * (hours));

  let difference = "";
  if (days > 0) {
    difference += days === 1 ? `${days} d, ` : `${days} ds, `;
  }

  difference += hours === 0 || hours === 1 ? `${hours} h ` : `${hours} h `;

  difference +=
    minutes === 0 || hours === 1 ? `${minutes} min` : `${minutes} min`;

  return difference;

  // OLD CODE IT GIVE DURATION AS NAN IN IPHONE
  
  // let fromTime = from.split("T")[0] + " " + from.split("T")[1];
  // let toTime = to.split("T")[0] + " " + to.split("T")[1];

  // let diffInMilliSeconds =
  //   Math.abs(new Date(fromTime) - new Date(toTime)) / 1000;

  // // calculate days
  // const days = Math.floor(diffInMilliSeconds / 86400);
  // diffInMilliSeconds -= days * 86400;
  // // calculate hours
  // const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  // diffInMilliSeconds -= hours * 3600;

  // // calculate minutes
  // const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  // diffInMilliSeconds -= minutes * 60;

  // let difference = "";
  // if (days > 0) {
  //   difference += days === 1 ? `${days} d, ` : `${days} ds, `;
  // }

  // difference += hours === 0 || hours === 1 ? `${hours} h ` : `${hours} h `;

  // difference +=
  //   minutes === 0 || hours === 1 ? `${minutes} min` : `${minutes} min`;

  // return difference;

};

export const FlightTimings = (props) => {
  // console.log("Flight Timings props", props);
  const flightDetailInfo = props.flightDetails;
  const [fareRulesResp, setFareRulesResp] = useState("");
  const { TabPane } = Tabs;

  const [flightDetails, setFlightDetails] = useState(false);
  const showModal = () => {
    setFlightDetails({
      visible: true,
    });
  };

  const fetchFlightFares = (str, event) => {
    let searchResp = JSON.parse(localStorage.getItem("searchResp"));
    if (str === "2") {
      let fareReqObj = {
        traceId: searchResp.traceId,
        flightId: flightDetailInfo.flightId,
        airTravelType: searchResp.airTravelType,
        mappingType: searchResp.resultsType,
        itineraryViewType: "1",
      };
      ApiClient.post("flights/airFareRules", fareReqObj)
        .then((result) => {
          return result;
        })
        .then((resp) => {
          if (resp.statusCode === 200) {
            if (resp.data) {
              if (resp.data.fareRules) {
                setFareRulesResp(resp);
                props.setFlightFareRules(resp);
              }
            }

            // setFareRulesResp(resp.data.errors[0].errorDetail);
            // props.setFlightFareRules(resp.data.errors[0].errorDetail);

          } else {
            let error_resp = {
              data: {
                fareRules: [
                  {
                    ruleDetails:
                      "Something went wrong , Fare Rules not Available",
                  },
                ],
              },
            };
            setFareRulesResp(error_resp);
            props.setFlightFareRules(error_resp);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div className="flight-timings">
        {flightDetailInfo.fareFamilies.fareFamilies.length > 0 &&
          props.showRefund ? (
          <div className="refundText">
            {flightDetailInfo.fareFamilies.fareFamilies[0].isRefundable
              ? ""
              : "Non"}{" "}
            Refundable
          </div>
        ) : null}
        <div className="timing_wrapper">
          <div className="plane-timings">
            <ul>
              <li>
                <p>
                  <span className="date">
                    {" "}
                    {moment(
                      flightDetailInfo.flightSegments[0].departureDateTime
                    ).format("DD MMM")}
                  </span>{" "}
                  {formatFlightTime(
                    flightDetailInfo.flightSegments[0].departureDateTime
                  )}
                </p>
              </li>
              <li>
                <LineOutlined />
              </li>
              <li>
                {" "}
                <div className="travel-time">
                  <p>
                    <ClockCircleOutlined />
                  </p>

                  {flightDetailInfo.flightSegments.length > 0 ? (
                    <div>
                      <p>
                        {calculateDuration(
                          flightDetailInfo.flightSegments[0].departureDateTime,
                          flightDetailInfo.flightSegments[
                            flightDetailInfo.flightSegments.length - 1
                          ].arrivalDateTime
                        )}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p>
                        {calculateDuration(
                          flightDetailInfo.flightSegments[0].departureDateTime,
                          flightDetailInfo.flightSegments[0].arrivalDateTime
                        )}
                      </p>
                    </div>
                  )}
                </div>
              </li>
              <li>
                <LineOutlined />
              </li>
              <li>
                {flightDetailInfo.flightSegments.length > 0 ? (
                  <div>
                    <p>
                      <span className="date">
                        {moment(
                          flightDetailInfo.flightSegments[
                            flightDetailInfo.flightSegments.length - 1
                          ].arrivalDateTime
                        ).format("DD MMM")}
                      </span>{" "}
                      {formatFlightTime(
                        flightDetailInfo.flightSegments[
                          flightDetailInfo.flightSegments.length - 1
                        ].arrivalDateTime
                      )}
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>
                      {formatFlightTime(
                        flightDetailInfo.flightSegments[0].arrivalDateTime
                      )}
                    </p>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>

        {props.showMail ? (
          <div className="share-section">
            <MailOutlined onClick={props.showMailModal} />
          </div>
        ) : (
          <div className="details-btn">
            <p className="details-text" onClick={showModal}>
              Details
            </p>
          </div>
        )}
        <div className="refundText">
          Fare Type:&nbsp;{flightDetailInfo.fareFamilies.fareFamilies[0].coupanType}
        </div>
      </div>

      <Modal
        className="modal-fl-heading"
        title="Flight Details"
        visible={flightDetails}
        footer={[null]}
        onOk={(e) => setFlightDetails(false)}
        onCancel={(e) => setFlightDetails(false)}
        className="flight-details-modal"
      >
        <div className="all-times-local">
          <Tabs
            defaultActiveKey="1"
            onTabClick={(str, e) => fetchFlightFares(str, e)}
          >
            <TabPane tab="Segments" key="1">
              <div className="from-to-modal-details">
                <Row gutter={[4, 16]} className="more-details-row">
                  <Col md={24} xs={24}>
                    <Row align="middle">
                      <Col md={8} xs={8} className="more-details-list">
                        <p>
                          {
                            flightDetailInfo.flightSegments[0].originName.split(
                              " "
                            )[0]
                          }
                          <span className="code">
                            ({flightDetailInfo.flightSegments[0].origin})
                          </span>
                        </p>
                        <p>
                          <strong>
                            {dateFormat(
                              flightDetailInfo.flightSegments[0]
                                .departureDateTime,
                              "HH:MM"
                            )}
                          </strong>
                          {" , "}
                          <span>
                            {dateFormat(
                              flightDetailInfo.flightSegments[0]
                                .departureDateTime,
                              "dd mmm (ddd)"
                            )}
                          </span>
                        </p>
                      </Col>
                      <Col md={8} xs={8}>
                        <div className="break-journey-city">
                          <div className="inner_wrapper">
                            {flightDetailInfo.flightSegments.map(
                              (flightSegInfo, index) =>
                                index !== 0 ? (
                                  <div
                                    className="stop_points"
                                    key={flightSegInfo.segId}
                                  >
                                    <Tooltip
                                      placement="top"
                                      overlayClassName="flightTooltip"
                                      title={() => (
                                        <div className="tooltip-data">
                                          {flightDetailInfo.flightSegments
                                            .length -
                                            1 >
                                            1 ? (
                                            <h4>Change</h4>
                                          ) : (
                                            ""
                                          )}
                                          <p>
                                            {flightSegInfo.origin +
                                              " " +
                                              flightSegInfo.originName}
                                          </p>
                                        </div>
                                      )}
                                      className="citynames-tooltip"
                                    >
                                      <span className="break-city-name pointer_cursor">
                                        {flightSegInfo.origin}
                                      </span>
                                    </Tooltip>
                                  </div>
                                ) : null
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md={8} xs={8} className="more-details-list">
                        <p>
                          {
                            flightDetailInfo.flightSegments[
                              flightDetailInfo.flightSegments.length - 1
                            ].destiantionName.split(" ")[0]
                          }

                          <span className="code">
                            (
                            {
                              flightDetailInfo.flightSegments[
                                flightDetailInfo.flightSegments.length - 1
                              ].destination
                            }
                            )
                          </span>
                        </p>
                        <p>
                          <strong>
                            {dateFormat(
                              flightDetailInfo.flightSegments[
                                flightDetailInfo.flightSegments.length - 1
                              ].arrivalDateTime,
                              "HH:MM"
                            )}
                          </strong>
                          {" , "}
                          <span>
                            {dateFormat(
                              flightDetailInfo.flightSegments[
                                flightDetailInfo.flightSegments.length - 1
                              ].arrivalDateTime,
                              "dd mmm (ddd)"
                            )}
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} sm={12} xs={12} className="trip-duration-time">
                    <p>Total trip duration:</p>
                    <p>
                      {flightDetailInfo.flightSegments.length > 0
                        ? calculateDuration(
                          flightDetailInfo.flightSegments[0]
                            .departureDateTime,
                          flightDetailInfo.flightSegments[
                            flightDetailInfo.flightSegments.length - 1
                          ].arrivalDateTime
                        )
                        : calculateDuration(
                          flightDetailInfo.flightSegments[0]
                            .departureDateTime,
                          flightDetailInfo.flightSegments[0].arrivalDateTime
                        )}{" "}
                      |
                      {flightDetailInfo.flightSegments.length - 1 === 0 ? (
                        <>Direct</>
                      ) : (
                        <>{flightDetailInfo.flightSegments.length - 1} stops </>
                      )}
                    </p>
                  </Col>
                  <Col md={12} sm={12} xs={12} className="trip-duration-time">
                    <p>class:</p>
                    <p>{props.flightSearchData.cabinClass}</p>
                  </Col>
                </Row>
              </div>

              {flightDetailInfo.flightSegments.map((flightSegment, i) => (
                <div key={i} className="total-flight-details">
                  <div className="to-fro-flight-details">
                    <div className="from-city-flight-details">
                      <span>{flightSegment.origin}</span>
                    </div>
                    <div>
                      <span>
                        departure:{" "}
                        <strong>
                          {dateFormat(flightSegment.departureDateTime, "HH:MM")}
                        </strong>
                        {" , "}
                      </span>
                      <span>
                        {dateFormat(
                          flightSegment.departureDateTime,
                          "dd mmm (ddd)"
                        )}
                      </span>
                      <p>
                        {flightDetailInfo.airLine}-{flightSegment.flightNumber}
                      </p>
                    </div>
                  </div>
                  <div className="collapsed-card"></div>
                  <div className="to-fro-flight-details">
                    <div className="from-city-flight-details">
                      <span>{flightSegment.destination}</span>
                    </div>
                    <div>
                      <span>
                        arrival:{" "}
                        <strong>
                          {formatFlightTime(flightSegment.arrivalDateTime)}
                        </strong>
                        {" , "}
                      </span>
                      <span>
                        {dateFormat(
                          flightSegment.arrivalDateTime,
                          "dd mmm (ddd)"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </TabPane>
            <TabPane tab="Fare Rules" key="2">
              <div className="fareRule-container">
                {!fareRulesResp ? (
                  <p>Loading...</p>
                ) : (
                  // fareRulesResp.data.fareRules.map((rule, i) => {
                  //   return (
                  //     <div key={i}><pre>{ReactHtmlParser(rule.ruleDetails)}</pre></div>
                  //   );
                  // })
                  <div> <pre>{ReactHtmlParser(fareRulesResp.data.fareRules[0].ruleDetails)}</pre></div>
                )}
              </div>
            </TabPane>
          </Tabs>
        </div>
      </Modal>
    </>
  );
};

const Flight = (props) => {
  // console.log("flights props", props);
  const flightInfo = props.flightInfo;
  const flightSearchData = props.flightSearchData;
  const [Emailform] = Form.useForm();
  const [openMailModal, setOpenMailModal] = useState(false);
  const showMailModal = () => {
    setOpenMailModal(true);
  };
  const openOk = (e) => {
    setOpenMailModal(false);
  };

  const OpenCancel = () => {
    setOpenMailModal(false);
  };
  const toolBreakCity = (breakCityDest, stopsCount) => {
    return (
      <div className="tooltip-data">
        {stopsCount > 1 ? <h4>Change</h4> : ""}
        <p>{breakCityDest}</p>
      </div>
    );
  };

  const sendEmail = (formData) => {
    let emailData = {
      to: formData.email,
      data: {
        url: document.location.href,
        airLine: flightInfo.airLine,
        airLineLogo: flightInfo.airLineLogo,
        airLineName: flightInfo.airLineName,
        cabinClass: flightSearchData.cabinClass,
        currency: flightInfo.fareFamilies.fareFamilies[0].currency,
        destiantionName: flightInfo.destiantionName,
        destination: flightInfo.destination,
        duration: flightInfo.duration,
        flightId: flightInfo.flightId,
        flightSegments: flightInfo.flightSegments,
        isBookOffline: flightInfo.isBookOffline,
        origin: flightInfo.origin,
        originName: flightInfo.originName,
        totalFare: Number(
          flightInfo.fareFamilies.fareFamilies[0].totalFare.toFixed(2)
        ),
      },
      cc: "",
      bcc: "",
      subject: "Flight Information",
    };

    ApiClient.post("email/flightsegment", emailData)
      .then((res) => {
        if (res.status === 200) {
          message.success("Mail Sent Successfully", 3);
          Emailform.resetFields();
          OpenCancel();
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="flight-results-container">
      <div className="combined-view">
        <div className="combined-flight-results-container">
          <Card className="combined-flight-card">
            <Row>
              <Col md={24} xs={24} className="flight-trip-details">
                <div className="itinary-details">
                  <div className="innerwrapper">
                    <div className="innerContent">
                      <div className="itinary-flight-name">
                        <img
                          className="airline-img"
                          src={flightInfo.airLineLogo}
                          alt={flightInfo.airLineLogo}
                        />
                        <p className="name">
                          {flightInfo.airLineName}
                          <span className="code">
                            {" "}
                            ({flightInfo.airLine}-
                            {flightInfo.flightSegments[0].flightNumber})
                          </span>
                        </p>
                      </div>
                      <div className="fromTo">
                        <div className="cities-from-to">
                          <div className="loc_wrapper">
                            <span className="name">
                              {
                                flightInfo.flightSegments[0].originName.split(
                                  " "
                                )[0]
                              }
                            </span>
                            <span className="code">
                              ({flightInfo.flightSegments[0].origin})
                            </span>

                            <div className="break-journey-city">
                              <div className="inner_wrapper">
                                {flightInfo.flightSegments.map(
                                  (flightSegInfo, index) =>
                                    index !== 0 ? (
                                      <div
                                        className="stop_points"
                                        key={flightSegInfo.segId}
                                      >
                                        <Tooltip
                                          placement="top"
                                          overlayClassName="flightTooltip"
                                          title={toolBreakCity(
                                            flightSegInfo.origin +
                                            " " +
                                            flightSegInfo.originName,
                                            flightInfo.flightSegments.length - 1
                                          )}
                                          className="citynames-tooltip"
                                        >
                                          <span className="break-city-name pointer_cursor">
                                            {flightSegInfo.origin}
                                          </span>
                                        </Tooltip>
                                      </div>
                                    ) : null
                                )}
                              </div>
                            </div>

                            <span className="name">
                              {
                                flightInfo.flightSegments[
                                  flightInfo.flightSegments.length - 1
                                ].destiantionName.split(" ")[0]
                              }
                            </span>
                            <span className="code">
                              (
                              {
                                flightInfo.flightSegments[
                                  flightInfo.flightSegments.length - 1
                                ].destination
                              }
                              )
                            </span>
                          </div>
                          {/* <div className="terminals">
                        <span>
                          Departure Terminal:{" "}
                          {flightInfo.flightSegments[0].departureTerminal}
                        </span>
                        <span>
                          Arrival Terminal:{" "}
                          {flightInfo.flightSegments[0].arrivalTerminal}
                        </span>
                      </div> */}
                        </div>
                        {/* <div className="arrive-dept-date">
                      <span className="dateDay">
                        {dateFormat(
                          props.flightSearchData.departureDateTime,
                          "dd mmm (ddd)"
                        )}
                      </span>
                    </div> */}
                      </div>
                      <div className="flight-class-details">
                        <span className="stopsType">
                          {flightInfo.flightSegments.length - 1 === 0 ? (
                            <>Direct</>
                          ) : (
                            <>{flightInfo.flightSegments.length - 1} stops </>
                          )}
                        </span>
                        <span className="flight-class">
                          class: {props.flightSearchData.cabinClass}
                        </span>
                      </div>
                    </div>
                    <FlightTimings
                      flightDetails={flightInfo}
                      flightSearchData={props.flightSearchData}
                      setFlightFareRules={props.setFlightFareRules}
                      showRefund={true}
                      showMail={true}
                      showMailModal={showMailModal}
                    />
                  </div>
                  <div className="available-price-details">
                    <div className="starting-price">
                      {/* <p className="starting-price-text">Starting From</p> */}
                      <p className="starting-price-number">
                        ₹ {flightInfo.fareFamilies.fareFamilies[0].adultNetFare}
                      </p>
                    </div>

                    <Modal
                      title="Send Flight Information"
                      visible={openMailModal}
                      onOk={openOk}
                      onCancel={OpenCancel}
                      footer={false}
                      className="flight-infoModal"
                      centered
                      width={400}
                    >
                      <Form onFinish={sendEmail} form={Emailform}>
                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[
                            { required: true, message: "Required!" },
                            {
                              type: "email",
                              message: "Email is not a valid email",
                            },
                          ]}
                        >
                          <Input
 autocomplete="newpassword"  placeholder="Enter Email Address" />
                        </Form.Item>

                        <Button type="primary" htmlType="submit">
                          Send
                        </Button>
                      </Form>
                    </Modal>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </div>
  );
};

export const FlightSelection = ({
  flightInfo,
  changeFlightResultsView,
  flightSearchData,
  flightDetails,
  selectedFlight,
}) => {
  const [flightArr, setFlightArr] = useState([]);

  const onClickChooseFlight = () => {
    if (
      localStorage.getItem("resultsType") === "Combined" &&
      localStorage.getItem("travelType") === "roundTrip"
    ) {
      flightInfo.flightDetails.map((flightDetObj) => {
        flightArr.push(flightDetObj);
        setFlightArr([flightArr]);
      });
      changeFlightResultsView(flightArr);
    } else {
      if (
        selectedFlight.length > 0 &&
        localStorage.getItem("travelType") === "roundTrip"
      ) {
        changeFlightResultsView([...selectedFlight, flightInfo]);
      } else {
        changeFlightResultsView([flightInfo]);
      }
    }
  };

  const [modal, setModal] = useState({
    visible: false,
    key: 1,
  });
  const showSegment = () => {
    setModal({
      visible: true,
      key: 1,
    });
  };
  const showFareRules = () => {
    setFareRulesResp("");
    fetchFlightFares();
    setModal({
      visible: true,
      key: 2,
    });
  };

  const [fareRulesResp, setFareRulesResp] = useState("");
  const fetchFlightFares = () => {
    let searchResp = JSON.parse(localStorage.getItem("searchResp"));

    let fareReqObj = {
      traceId: searchResp.traceId,
      flightId: flightInfo.flightId,
      airTravelType: searchResp.airTravelType,
      mappingType: searchResp.resultsType,
      itineraryViewType: "1",
    };
    ApiClient.post("flights/airFareRules", fareReqObj)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.statusCode === 200) {
          if (resp.data) {
            if (resp.data.fareRules) {
              setFareRulesResp(resp);
            }
          }


        } else {
          let error_resp = {
            data: {
              fareRules: [
                {
                  ruleDetails:
                    "Something went wrong , Fare Rules not Available",
                },
              ],
            },
          };
          setFareRulesResp(error_resp);
        }
      })
      .catch((err) => console.log(err));
  };


  return (
    <div className="choose-btn-block">
      <div className="infowrapper">
        <div className="infobtnwrapper">
          <p className="title" onClick={() => showSegment()}>
            Segment
          </p>
          <p className="title" onClick={() => showFareRules()}>
            Fare Rules
          </p>
          <Modal
            className="modal-fl-heading"
            title={
              modal.key === 1 ? "Flight Segment Details" : "Flight Fare Rules"
            }
            visible={modal.visible}
            footer={[null]}
            onOk={(e) =>
              setModal({
                visible: false,
                key: 1,
              })
            }
            onCancel={(e) =>
              setModal({
                visible: false,
                key: 1,
              })
            }
            className="flight-details-modal"
          >
            <div className="all-times-local">
              {modal.key === 1 ? (
                <div>
                  <div className="from-to-modal-details">
                    <Row gutter={[4, 16]} className="more-details-row">
                      <Col md={24} xs={24}>
                        <Row align="middle">
                          <Col md={8} xs={8} className="more-details-list">
                            <p>
                              {
                                flightInfo.flightSegments[0].originName.split(
                                  " "
                                )[0]
                              }
                              <span className="code">
                                ({flightInfo.flightSegments[0].origin})
                              </span>
                            </p>
                            <p>
                              <strong>
                                {dateFormat(
                                  flightInfo.flightSegments[0]
                                    .departureDateTime,
                                  "HH:MM"
                                )}
                              </strong>
                              {" , "}
                              <span>
                                {dateFormat(
                                  flightInfo.flightSegments[0]
                                    .departureDateTime,
                                  "dd mmm (ddd)"
                                )}
                              </span>
                            </p>
                          </Col>
                          <Col md={8} xs={8}>
                            <div className="break-journey-city">
                              <div className="inner_wrapper">
                                {flightInfo.flightSegments.map(
                                  (flightSegInfo, index) =>
                                    index !== 0 ? (
                                      <div
                                        className="stop_points"
                                        key={flightSegInfo.segId}
                                      >
                                        <Tooltip
                                          placement="top"
                                          overlayClassName="flightTooltip"
                                          title={() => (
                                            <div className="tooltip-data">
                                              {flightInfo.flightSegments
                                                .length -
                                                1 >
                                                1 ? (
                                                <h4>Change</h4>
                                              ) : (
                                                ""
                                              )}
                                              <p>
                                                {flightSegInfo.origin +
                                                  " " +
                                                  flightSegInfo.originName}
                                              </p>
                                            </div>
                                          )}
                                          className="citynames-tooltip"
                                        >
                                          <span className="break-city-name pointer_cursor">
                                            {flightSegInfo.origin}
                                          </span>
                                        </Tooltip>
                                      </div>
                                    ) : null
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col md={8} xs={8} className="more-details-list">
                            <p>
                              {
                                flightInfo.flightSegments[
                                  flightInfo.flightSegments.length - 1
                                ].destiantionName.split(" ")[0]
                              }

                              <span className="code">
                                (
                                {
                                  flightInfo.flightSegments[
                                    flightInfo.flightSegments.length - 1
                                  ].destination
                                }
                                )
                              </span>
                            </p>
                            <p>
                              <strong>
                                {dateFormat(
                                  flightInfo.flightSegments[
                                    flightInfo.flightSegments.length - 1
                                  ].arrivalDateTime,
                                  "HH:MM"
                                )}
                              </strong>
                              {" , "}
                              <span>
                                {dateFormat(
                                  flightInfo.flightSegments[
                                    flightInfo.flightSegments.length - 1
                                  ].arrivalDateTime,
                                  "dd mmm (ddd)"
                                )}
                              </span>
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        md={12}
                        sm={12}
                        xs={12}
                        className="trip-duration-time"
                      >
                        <p>Total trip duration:</p>
                        <p>
                          {flightInfo.flightSegments.length > 0
                            ? calculateDuration(
                              flightInfo.flightSegments[0].departureDateTime,
                              flightInfo.flightSegments[
                                flightInfo.flightSegments.length - 1
                              ].arrivalDateTime
                            )
                            : calculateDuration(
                              flightInfo.flightSegments[0].departureDateTime,
                              flightInfo.flightSegments[0].arrivalDateTime
                            )}{" "}
                          |
                          {flightInfo.flightSegments.length - 1 === 0 ? (
                            <>Direct</>
                          ) : (
                            <>{flightInfo.flightSegments.length - 1} stops </>
                          )}
                        </p>
                      </Col>
                      <Col
                        md={12}
                        sm={12}
                        xs={12}
                        className="trip-duration-time"
                      >
                        <p>class:</p>
                        <p>{flightSearchData.cabinClass}</p>
                      </Col>
                    </Row>
                  </div>

                  {flightInfo.flightSegments.map((flightSegment, i) => (
                    <div key={i} className="total-flight-details">
                      <div className="to-fro-flight-details">
                        <div className="from-city-flight-details">
                          <span>{flightSegment.origin}</span>
                        </div>
                        <div>
                          <span>
                            departure:{" "}
                            <strong>
                              {dateFormat(
                                flightSegment.departureDateTime,
                                "HH:MM"
                              )}
                            </strong>
                            {" , "}
                          </span>
                          <span>
                            {dateFormat(
                              flightSegment.departureDateTime,
                              "dd mmm (ddd)"
                            )}
                          </span>
                          <p>
                            {flightInfo.airLine}-{flightSegment.flightNumber}
                          </p>
                        </div>
                      </div>
                      <div className="collapsed-card"></div>
                      <div className="to-fro-flight-details">
                        <div className="from-city-flight-details">
                          <span>{flightSegment.destination}</span>
                        </div>
                        <div>
                          <span>
                            arrival:{" "}
                            <strong>
                              {formatFlightTime(flightSegment.arrivalDateTime)}
                            </strong>
                            {" , "}
                          </span>
                          <span>
                            {dateFormat(
                              flightSegment.arrivalDateTime,
                              "dd mmm (ddd)"
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div tab="Fare Rules" key="2">
                  <div className="fareRule-container">
                    {!fareRulesResp ? (
                      <p>Loading...</p>
                    ) : (
                      <div> <pre>{ReactHtmlParser(fareRulesResp.data.fareRules[0].ruleDetails)}</pre></div>
                      // fareRulesResp.data.fareRules.map((rule, i) => {
                      //   return (

                      //     i == 0 ? (<div key={i}>{ReactHtmlParser(rule.ruleDetails)}</div>) : null

                      //   );
                      // })
                    )}
                  </div>
                </div>
              )}
            </div>
          </Modal>
        </div>
      </div>
      <div className="choose-btn-section">
        <div className="choose-web-btn">
          <button
            className="choose-button"
            onClick={(e) => onClickChooseFlight()}
          >
            Choose
          </button>
        </div>
        {/* <div className="choose-mobile-btn">
          <Link to="/flight-checkout">
            <button className="choose-button">
              <RightOutlined />
            </button>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default Flight;
