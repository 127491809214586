import React, { useContext, useState } from "react";
import GlobalStatesContext from "./GlobalStatesContext";
import useLocalStorage from "../../helpers/useLocalStorage";
import EncHelper from "../../helpers/EncHelper";

const AuthContext = React.createContext();
export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const { updateIsLogin } = useContext(GlobalStatesContext);
  const [user, setUser] = useLocalStorage("user", null);
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

  const [busAccessToken, setBusAccessToken] = useLocalStorage(
    "busAccessToken",
    null
  );
  const setChangeLoginFlag = (value) => updateIsLogin("flag", value);
  const setChangeDashboardFlag = (value) => updateIsLogin("role", value);

  const tryLocalSignin = () => {
    // console.log("famil");
    try {
      // {console.log(user, accessToken,"token")}
      if (accessToken && user) {
        
        if (user.roleId === 1 || user.roleId === 2 || user.roleId == 5) {
          // let screens = [];
          // user.Screens.forEach((element) => {
          //   screens.push(element.url);
          // });
          // EncHelper.setItem("access_screens", JSON.stringify(screens));
          setChangeDashboardFlag(true);
        } else {
          setChangeLoginFlag(false);
          setChangeDashboardFlag(false);
        }
      } else {
        setChangeLoginFlag(false);
        setChangeDashboardFlag(false);
      }
    } catch (error) {}
  };

  return (
    <AuthContext.Provider
      value={{
        tryLocalSignin,

        user,
        setUser,
        accessToken,
        setAccessToken,
        setBusAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
