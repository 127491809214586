import React, { useState, useEffect } from "react";
import "../Promo/Promo.scss";
import * as ANTD from "antd";
import {
  PlusCircleFilled,
  LoadingOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  Modal,
  Col,
  Radio,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import ApiClient from "../../helpers/ApiClient";
import moment from "moment";
const { Content } = ANTD.Layout;

const Promo = () => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const uploadActionUrl = BASE + "media/upload/singleImage";
  const initialFormData = {
    ApplicationType: "1",
    Code: "",
    Description: "",
    Discount: "",
    DiscountType: 1,
    FromAmount: "",
    Image: "",
    Name: "",
    PGType: 1,
    ServiceType: undefined,
    ToAmount: "",
    UsageLimit: 1,
    UserLimit: 1,
    ValidFrom: "12-12-2020",
    ValidTill: "12-02-2021",
  };
  const [modalVisible, setModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [disabledTillDates, setDisabledTillDates] = useState(
    moment().startOf("day")
  );
  const [tableData, setTableData] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [form] = ANTD.Form.useForm();
  const [formData, setFormData] = useState(initialFormData);
  let dateFormat = "YYYY-MM-DD";
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const disabledDates = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  const TillDatesDis = (currentDate) => {
    return currentDate < moment(disabledTillDates).startOf("day");
  };
  /********** Uploading Image **********/

  const [upLoadImage, setUpLoadImage] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  function handleChange(info) {
    if (info.file.status === "error") {
      ANTD.message.error(" Error Uploading Image", 3);
      setUpLoadImage(false);
      return;
    }
    if (info.file.status === "uploading") {
      setUpLoadImage(true);
      return;
    }
    if (info.file.status === "done") {
      if (info.file.response.status === 200) {
        setFormData({
          ...formData,
          Image: info.file.response.data.filepath,
        });
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          setImageUrl(imageUrl);
          setUpLoadImage(false);
        });
      }
      setUpLoadImage(false);
    }
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const uploadButton = (
    <div>
      {upLoadImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const updatePromoModel = (rec) => {
    setImageUrl(null);
    setFormData({ ...formData, PromoID: rec.PromoID, Image: rec.Image });
    form.setFieldsValue({
      ApplicationType: rec.ApplicationType.toString(),
      Code: rec.Code,
      Description: rec.Description,
      Discount: rec.Discount,
      DiscountType: rec.DiscountType,
      FromAmount: rec.FromAmount,
      Name: rec.Name,
      PGType: rec.PGType.toString(),
      ServiceType: rec.ServiceType.toString(),
      ToAmount: rec.ToAmount,
      UsageLimit: rec.UsageLimit,
      UserLimit: rec.UserLimit,
      ValidFrom: moment(rec.ValidFrom, dateFormat),
      ValidTill: moment(rec.ValidTill, dateFormat),
    });
    if (rec.Image !== "" && rec.Image !== "string" && rec.Image !== null) {
      const imgUrl = BASE + rec.Image.substring(1);
      setImageUrl(imgUrl);
    }
    setIsEditMode(true);
    showModal();
  };

  const addNewPromoModel = () => {
    setIsEditMode(false);
    form.resetFields();
    setImageUrl(null);
    setFormData(initialFormData);
    showModal();
  };

  const tableActions = (text, rec) => {
    return (
      <>
        <span className="edit-icon" onClick={() => updatePromoModel(rec)}>
          <i className="fa fa-edit"></i>
        </span>
        &nbsp;&nbsp;&nbsp;
        <ANTD.Popconfirm
          title="Are u sure to delete?"
          onConfirm={() => deletePromoCode(text)}
        >
          <span className="delete-icon">
            <i className="fa fa-trash"></i>
          </span>
        </ANTD.Popconfirm>
      </>
    );
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",

      sorter: (a, b) => a.SNo - b.SNo,
    },
    {
      title: "Promo Name",
      dataIndex: "Name",

      sorter: (a, b) => a.Name.localeCompare(b.Name),
      key: "Name",
    },
    {
      title: "Promo Description",
      dataIndex: "Description",

      sorter: (a, b) => a.Description.localeCompare(b.Description),
      key: "Description",
    },
    {
      title: "Code",
      dataIndex: "Code",
      key: "Code",
      sorter: (a, b) => a.Code.localeCompare(b.Code),
    },
    {
      title: "Service Type",
      dataIndex: "ServiceType",
      key: "ServiceType",
      render: (text) => {
        return getSeriveType(text);
      },
      sorter: (a, b) => {
        let valA = getSeriveType(a.ServiceType);
        let valB = getSeriveType(b.ServiceType);

        return valA.localeCompare(valB);
      },
    },
    {
      title: "Valid From",
      dataIndex: "ValidFrom",
      key: "ValidFrom",
      sorter: (a, b) => a.ValidFrom.localeCompare(b.ValidFrom),
    },
    {
      title: "Valid Till",
      dataIndex: "ValidTill",
      key: "ValidTill",
      sorter: (a, b) => a.ValidTill.localeCompare(b.ValidTill),
    },
    {
      title: "Discount Type",
      dataIndex: "DiscountType",
      key: "DiscountType",
      render: (text) => {
        return getDiscountType(text);
      },
      sorter: (a, b) => {
        let valA = getDiscountType(a.DiscountType);
        let valB = getDiscountType(b.DiscountType);

        return valA.localeCompare(valB);
      },
    },
    {
      title: "Discount",
      dataIndex: "Discount",
      key: "Discount",
      sorter: (a, b) => a.Discount.localeCompare(b.Discount),
    },
    {
      title: "From Amont",
      dataIndex: "FromAmount",
      key: "FromAmount",
      sorter: (a, b) => a.FromAmount.localeCompare(b.FromAmount),
    },
    {
      title: "To Amount",
      dataIndex: "ToAmount",
      key: "ToAmount",
      sorter: (a, b) => a.ToAmount.localeCompare(b.ToAmount),
    },
    {
      title: "Actions",
      dataIndex: "PromoID",
      render: (text, rec) => {
        return tableActions(text, rec);
      },
    },
  ];

  const getSeriveType = (num) => {
    if (num === 1) {
      return "Bus";
    } else if (num === 2) {
      return "Flight";
    } else {
      return "Not Applicable";
    }
  };

  const getDiscountType = (num) => {
    if (num === 0) {
      return "Fixed";
    } else if (num === 1) {
      return "Percentage";
    } else {
      return "Invalid";
    }
  };

  const getPromoCodes = () => {
    setDataSource([]);
    setTableData([]);
    ApiClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              ValidFrom: moment(item.ValidFrom).format(dateFormat),
              ValidTill: moment(item.ValidTill).format(dateFormat),
            };
          });
          setDataSource(data);
          setTableData(data);
        }
      })
      .catch((e) => {
        console.log("api error", e);
      });
  };

  useEffect(() => {
    getPromoCodes();
  }, []);

  // api call to get all avaialble promo Codes

  // Delete specific promo Codes
  const deletePromoCode = (id) => {
    ApiClient.delete("admin/promo/" + id)
      .then((res) => {
        if (res.status === 200) {
          ANTD.message.success("PromoCode Deleted  successfully", 3);
          getPromoCodes();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitNewPromo = (data) => {
    const newformData = {
      ...formData,
      ...data,
    };
    ApiClient.post("admin/promo", newformData).then((res) => {
      if (res.status === 200) {
        ANTD.message.success("Promo added successfully", 3);
        setModalVisible(false);
        setFormData(initialFormData);
        getPromoCodes();
      } else if (res.status === 400) {
        ANTD.message.error(" Duplicate Promo Code", 3);
      } else {
        ANTD.message.error(" Error Uploading Data", 3);
      }
    });
  };

  const submitUpdatePromo = (data) => {
    delete data.UploadImage;
    const updatedFormData = {
      ...formData,
      ...data,
    };
    ApiClient.put("admin/promo/" + formData.PromoID, {}, updatedFormData).then(
      (res) => {
        if (res.status === 200) {
          ANTD.message.success("Promo Updated  successfully", 3);
          setModalVisible(false);
          setFormData(initialFormData);
          getPromoCodes();
        } else if (res.status === 400) {
          ANTD.message.error(" Duplicate Promo Code", 3);
        } else {
          ANTD.message.error(" Error Uploading Data", 3);
        }
      }
    );
  };

  // add new Promo Code api call
  const submitFormData = (formvalues) => {
    formvalues.ValidFrom = formvalues.ValidFrom.format("YYYY-MM-DD");
    formvalues.ValidTill = formvalues.ValidTill.format("YYYY-MM-DD");
    const imageData = imageUrl ? imageUrl : " ";
    const edit_data = {
      ...formvalues,
      Image: imageData,
    };

    if (isEditMode) {
      submitUpdatePromo(formvalues);
    } else {
      submitNewPromo(formvalues);
    }
  };

  const searchData = () => {
    setShowSearchBox(true);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableData.filter(function (item) {
        if (item !== undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableData(searchResults);
    } else {
      setTableData(dataSource);
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
    setTableData(dataSource);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );
  let key = 0;
  const keyGenerate = () => {
    return key++;
  };

  return (
    <div className="promo">
      <ANTD.Layout>
        <div className="manage-markup-section">
          <Content className="container-fluid">
            <ANTD.Row>
              <ANTD.Col span={24}>
                <div className="card-bt-gap">
                  <ANTD.Card bordered={false}>
                    <div className="card-add-heading">
                      <div className="rows-count">
                        <h5>View Promocode</h5>
                        <p>{tableData.length} rows found !</p>
                      </div>
                      <div className="action-images">
                        {showSearchBox && (
                          <ANTD.Input 
 autocomplete="newpassword" 
                            placeholder="Search"
                            onChange={(e) => searchTableData(e)}
                            suffix={closeSearchInput}
                            style={{ padding: "0px 12px" }}
                          />
                        )}
                        &nbsp;&nbsp;
                        <img
                          src={search}
                          alt="search"
                          onClick={(e) => searchData(dataSource)}
                        />
                        <img
                          src={exchange}
                          alt="refresh"
                          onClick={() => getPromoCodes()}
                        />
                        <p
                          className="add-deposit-icon"
                          onClick={() => {
                            addNewPromoModel();
                          }}
                        >
                          <PlusCircleFilled />
                        </p>
                      </div>
                    </div>

                    <ANTD.Table
                      scroll={{ x: true }}
                      bordered
                      dataSource={tableData}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                      rowKey={keyGenerate}
                    />
                  </ANTD.Card>
                </div>
              </ANTD.Col>
            </ANTD.Row>
          </Content>
        </div>
      </ANTD.Layout>
      <Modal
        title="Promocode"
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <ANTD.Button key="clear" onClick={() => form.resetFields()}>
              Clear
            </ANTD.Button>
            <ANTD.Button key="add" type="primary" onClick={form.submit}>
              {isEditMode ? "Update" : "Add"}
            </ANTD.Button>
          </div>,
        ]}
        width={"1100px"}
      >
        <Form
          {...layout}
          form={form}
          onFinish={submitFormData}
          onFinishFailed={onFinishFailed}
        >
          <ANTD.Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Service"
                name="ServiceType"
                rules={[{ required: true, message: "Service is required" }]}
              >
                <Select
                  autocomplete="newpassword" placeholder="Select Service Type">
                  <Select.Option value="1">Buses</Select.Option>
                  <Select.Option value="2">Flights</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Application Type"
                name="ApplicationType"
                rules={[
                  { required: true, message: "Application Type is required" },
                ]}
              >
                <Select
                  autocomplete="newpassword" placeholder="Select Service Type">
                  <Select.Option value="1">Website</Select.Option>
                  <Select.Option value="2">Mobile</Select.Option>
                  <Select.Option value="3">Both</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </ANTD.Row>

          <ANTD.Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Promo Name"
                name="Name"
                rules={[{ required: true, message: "Promo Name is required" }]}
              >
                <Input
                  autocomplete="newpassword" name="Name" placeholder="Promo Name" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Promo Code"
                name="Code"
                rules={[{ required: true, message: "Promo Code is required" }]}
              >
                <Input
                  autocomplete="newpassword" name="Code" placeholder="Promo Code" />
              </Form.Item>
            </Col>
          </ANTD.Row>

          <ANTD.Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Valid From"
                name="ValidFrom"
                rules={[{ required: true, message: "Valid From is required" }]}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  onChange={(date) => {
                    if (moment(date) > moment(disabledTillDates)) {
                      form.setFieldsValue({
                        ValidTill: "",
                      });
                    }
                    setDisabledTillDates(moment(date).startOf("day"));
                  }}
                  disabledDate={disabledDates}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Valid Till"
                name="ValidTill"
                rules={[{ required: true, message: "Valid Till is required" }]}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  disabledDate={TillDatesDis}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </ANTD.Row>
          <ANTD.Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="From Amount "
                name="FromAmount"
                rules={[
                  { required: true },
                  {
                    validator: (_, value) => {
                      if (value > 0) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Please Enter valid Amount");
                      }
                    },
                  },
                ]}
              >
                <Input
                  autocomplete="newpassword"
                  name="FromAmount"
                  placeholder="From Amount"
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="To Amount"
                name="ToAmount"
                rules={[
                  { required: true },
                  {
                    validator: (_, value) => {
                      if (value > 0) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Please Enter valid Amount");
                      }
                    },
                  },
                ]}
              >
                <Input
                  autocomplete="newpassword" name="ToAmount" placeholder="To Amount" type="number" />
              </Form.Item>
            </Col>
          </ANTD.Row>
          <ANTD.Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Discount Type"
                name="DiscountType"
                rules={[
                  { required: true, message: "Discount Type is required" },
                ]}
              >
                <Radio.Group>
                  <Radio value={1}>Percentage</Radio>
                  <Radio value={0}>Fixed</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Discount Amount"
                name="Discount"
                rules={[
                  { required: true },
                  {
                    validator: (_, value) => {
                      if (value > 0) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Please Enter valid Amount");
                      }
                    },
                  },
                ]}
              >
                <Input
                  autocomplete="newpassword" placeholder="Discount Amount" type="number" />
              </Form.Item>
            </Col>
          </ANTD.Row>
          <ANTD.Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Usage Limit"
                name="UsageLimit"
                rules={[{ required: true, message: "Usage Limit is required" }]}
              >
                <Input
                  autocomplete="newpassword"
                  name="UsageLimit"
                  placeholder="Usage Limit"
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="User Limit"
                name="UserLimit"
                rules={[{ required: true, message: "User Limit is required" }]}
              >
                <Input
                  autocomplete="newpassword"
                  name="UserLimit"
                  placeholder="User Limit"
                  type="number"
                />
              </Form.Item>
            </Col>
          </ANTD.Row>
          <ANTD.Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="PG"
                name="PGType"
                rules={[{ required: true, message: "PG is required" }]}
              >
                <Select
                  autocomplete="newpassword" placeholder="Select Pg">
                  <Select.Option value="1">CashFree</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Promo Description"
                name="Description"
                rules={[
                  { required: true, message: "Promo Description is required" },
                ]}
              >
                <TextArea name="Description" placeholder="Description" />
              </Form.Item>
            </Col>
          </ANTD.Row>
          <Col md={12} xs={24}>
            <Form.Item label="Upload Image" name="UploadImage">
              <Upload
                name="image"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={uploadActionUrl}
                data={{ category: "promo" }}
                onChange={handleChange}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </Col>
        </Form>
      </Modal>
    </div>
  );
};
export default Promo;
