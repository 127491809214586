import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  message,
  Popconfirm,
  Row,
  Table,
  Select,
  DatePicker,
  Tooltip,
  Collapse,
  InputNumber,
  Radio,
} from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import excel from "../../assets/vector-icons/excel.png";
import search from "../../assets/vector-icons/search.png";
import exchange from "../../assets/vector-icons/exchange.png";
import moment from "moment";
import { exportExcel } from "../../ExportExcel";
import "../../../admin/Adminstyles.scss";
import BusApiClient from "../../../../src/helpers/BusApiClient";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import AutoCompleteSelect from "../../../common/AutoCompleteSelect/AutoCompleteSelect";
import Homesearch from "../../../components/search-box/SearchBox";
import Buses from "../../../components/buses-search/Buses";
import { User } from "react-feather";
import "../BusBooking/BusBooking.scss";

const { Content } = Layout;
const { Panel } = Collapse;
const BusBooking = () => {
  const { Option } = Select;
  const { user } = useAuthContext();

  const { setBusAccessToken } = useAuthContext();
  const [modalVisible, setModalVisible] = useState(false);
  const [reportsList, setReportsList] = useState([]);

  const onFinish = (data) => {
    data = {
      username: data.username,
      password: data.password,
    };
    BusApiClient.post("bus/login", data)
      .then((res) => {
        if (res.accessToken) {
          setBusAccessToken(res.accessToken);
          message.success("Success ", 3);
        } else {
          message.error(res.error.desc, 3);
        }
      })
      .catch((e) => {});
  };

  return (
    <div   >

    <Layout>
      {/* <div className="manage-markup-section">
        <Content className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false}>
              <div className="card-add-heading">
                <h5>Bus Login</h5>
                <Form
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                  layout="vertical"
                >
                <Row gutter={12} align="middle">
                    <Col span={6}>
                      <Form.Item
                        label="Username"
                        name="username"
                        rules={[
                          {
                            required: true,
                            message: "Please input your username!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                      >
                      <Input.Password />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="primary-btn bus-btn-pri"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Card>
            </div>
        </Content>
      </div> */}

 
      <div className="manage-markup-section bus-tic-more" >
         <Content className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false}>
              <div className="card-add-heading">
                <Buses />
                {/* <Homesearch
                gscontext={props.gscontext}
                hotelSearch={props.hotelContext}
                flightSearch={props.flightContext}
                /> */}
              </div>
            </Card>
          </div>
        </Content>
      </div>
    </Layout>
                </div>
  );
};
export default BusBooking;
