import React, { useState } from "react";

import * as ANTD from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";

import search from "../assets/vector-icons/search.png";

const { Content } = ANTD.Layout;
const AddProvider = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
  };
  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  let onChange = (jsDate, dateString) => {
    console.log(jsDate);
    console.log(dateString);
  };
  const tableActions = () => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="edit-icon"
          style={{ textAlign: "center" }}
          onClick={showModal}
        >
          <EditOutlined
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <ANTD.Popconfirm title="Are you sure to delete?">
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </ANTD.Popconfirm>
        </div>
      </div>
    );
  };

  const dataSource = [
    {
      provider: "Air Asia",
      service: "Flights",
      supportemail: "airasia@gmail.com",
      actions: tableActions(),
    },
    {
      provider: "Taj",
      service: "Hotels",
      supportemail: "taj@gmail.com",
      actions: tableActions(),
    },
    {
      provider: "Air India",
      service: "Flights",
      supportemail: "airindia@gmail.com",
      actions: tableActions(),
    },
  ];

  const columns = [
    {
      title: "Provider",
      dataIndex: "provider",
    },
    {
      title: "Service",
      dataIndex: "service",
    },
    {
      title: "Support Email",
      dataIndex: "supportemail",
    },
    {
      title: "Status",
      dataIndex: "no",
      key: "airline",
      render: (text, order) => (
        <ANTD.Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
    },
  ];

  /***** Radio-btn*****/
  const [statusBtn, setStatusBtn] = useState(1);
  onChange = (e) => {
    console.log("radio checked", e.target.value);
    setStatusBtn(e.target.value);
  };

  return (
    <div className="promo addprovider">
      <ANTD.Layout>
        <div className="manage-markup-section">
          <Content className="admin-container">
            <ANTD.Row>
              <ANTD.Col span={24}>
                <div className="card-bt-gap">
                  <ANTD.Card bordered={false}>
                    <div className="card-add-heading">
                      <div className="rows-count">
                        <h5>View Providers</h5>
                        <p>3 rows found !</p>
                      </div>
                      <div className="action-images">
                        <img src={search} alt="search" />
                        <img src={excel} alt="excel" />
                        <img src={pdf} alt="pdf" />

                        <p className="add-deposit-icon" onClick={showModal}>
                          <PlusCircleFilled />
                        </p>
                      </div>
                    </div>

                    <ANTD.Table
                      scroll={{ x: true }}
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                    />
                  </ANTD.Card>
                </div>
              </ANTD.Col>
            </ANTD.Row>
          </Content>
        </div>
      </ANTD.Layout>
      <ANTD.Modal
        title="Add Provider"
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"500px"}
      >
        <ANTD.Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {/* <ANTD.Row gutter={16}> */}
          <ANTD.Col md={24} xs={24}>
            <ANTD.Form.Item
              label="Service"
              rules={[{ required: true, message: "Please choose  service" }]}
              name="service"
            >
              <ANTD.Select>
                <ANTD.Select.Option value="demo">Flights</ANTD.Select.Option>
                <ANTD.Select.Option value="demo">Hotels</ANTD.Select.Option>
              </ANTD.Select>
            </ANTD.Form.Item>
          </ANTD.Col>
          <ANTD.Col md={24} xs={24}>
            <ANTD.Form.Item
              name={["provider", "name"]}
              label="Provider"
              rules={[{ required: true, message: "Please Enter provider!" }]}
            >
              <ANTD.Input 
 autocomplete="newpassword"  />
            </ANTD.Form.Item>
          </ANTD.Col>
          {/* </ANTD.Row> */}

          {/* <ANTD.Row gutter={16}> */}
          <ANTD.Col md={24} xs={24}>
            <ANTD.Form.Item
              name={["supported", "email"]}
              label="Supported Emails"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please enter email!",
                },
              ]}
            >
              <ANTD.Input 
 autocomplete="newpassword"  />
            </ANTD.Form.Item>
          </ANTD.Col>
          <ANTD.Col md={24} xs={24}>
            <ANTD.Form.Item label="Status">
              <ANTD.Radio.Group onChange={onChange} value={statusBtn}>
                <ANTD.Radio value={1}>Active</ANTD.Radio>
                <ANTD.Radio value={2}>Inactive</ANTD.Radio>
              </ANTD.Radio.Group>
            </ANTD.Form.Item>
          </ANTD.Col>
          {/* </ANTD.Row> */}

          <ANTD.Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
            <ANTD.Button type="primary" htmlType="submit">
              Add
            </ANTD.Button>
            <ANTD.Button type="primary" danger className="cancel-btn">
              Clear
            </ANTD.Button>
          </ANTD.Form.Item>
        </ANTD.Form>
      </ANTD.Modal>
    </div>
  );
};

export default AddProvider;
