import React, { useState, useEffect } from "react";
import "./FlightFilters.scss";
import { Card, Checkbox, Collapse, Slider, Tooltip } from "antd";
import moment from "moment";
import TimeRangeSlider from "react-time-range-slider";
import { airLinesMap } from "./flightUtils";

const timeRange = { start: "00:00", end: "23:59" };

const FlightFilters = (props) => {
  const { Panel } = Collapse;
  const {
    flightAirSearchResp,
    selectedTripType,
    updateFlightAirSearchRespObj,
    ResetAirlineMatrix,
  } = props;
  let flightDetails =
    selectedTripType === 1
      ? flightAirSearchResp.flightDetails
      : flightAirSearchResp.ibFlightDetails;
  const [filters, setFilters] = useState({});
  const [departureVal, setDepartureVal] = useState(timeRange);
  const [arrivalVal, setArrivalVal] = useState(timeRange);
  const [priceRange, setPriceRange] = useState([]);
  let count = flightDetails.length;
  const [resultCount, setResultCount] = useState(0);
  useEffect(() => {
    let resCount = flightDetails.filter((item) => item.isVisible).length;
    setResultCount(resCount);
  }, [flightDetails]);
  let visibleCount = 0;
  let filtersObj = {
    stops: [],
    price: { minPrice: 0, maxPrice: 0, maxPriceRange: 0, minPriceRange: 0 },
    departure: {},
    arrival: {},
    airlines: [],
    fareTypes: [],
    connect: [],
  };
  const onChange = (event, filterType, filterId) => {
    let { checked } = event.target;

    setFilters({
      ...filters,
      [filterType]: filters[filterType].map((filt) =>
        filt.id == filterId ? { ...filt, isChecked: checked } : filt
      ),
    });
    scrollToListTop();
  };

  const scrollToListTop = () => {
    document.getElementsByClassName("results_tab")[0].scrollIntoView({
      behavior: "smooth",
    });
  };

  const checkIfExist = (filterTypeObj, id) =>
    filterTypeObj.filter((obj) => obj["id"] === id).length === 0;

  const setDynamicFilters = () => {
    setDepartureVal(timeRange);
    setArrivalVal(timeRange);

    for (let i = 0; i < flightDetails.length; i++) {
      //Setting Stops Filters
      const flightSegLength = flightDetails[i].flightSegments.length;
      checkIfExist(filtersObj.stops, flightSegLength) &&
        filtersObj.stops.push({
          id: flightSegLength,
          label:
            flightSegLength - 1 === 0
              ? "Direct"
              : `${flightSegLength - 1} Stop(s)`,
          isChecked: false,
        });

      //Setting Price Range
      let flightTotPrice = Math.round(
        // flightDetails[i].fareFamilies.fareFamilies[0].totalFare
        flightDetails[i].fareFamilies.fareFamilies[0].adultNetFare
      );
      if (i === 0) {
        filtersObj.price.minPrice = flightTotPrice;
      }
      let minFare = flightTotPrice;
      if (flightTotPrice > filtersObj.price.maxPrice) {
        filtersObj.price.maxPrice = filtersObj.price.maxPriceRange = flightTotPrice;
      }
      if (minFare < filtersObj.price.minPrice) {
        filtersObj.price.minPrice = minFare;
      }
      //Setting Departure Filter
      filtersObj.departure = timeRange;
      filtersObj.price.minPriceRange = filtersObj.price.minPrice;
      //Setting Arrival Filter
      filtersObj.arrival = timeRange;

      //Setting Airlines Filters
      checkIfExist(filtersObj.airlines, flightDetails[i].airLine) &&
        filtersObj.airlines.push({
          id: flightDetails[i].airLine,
          label: flightDetails[i].airLineName,
          isChecked: false,
        });

      //Setting Fare Type Filters
      filtersObj.fareType = [
        { id: "refundable", label: "Refundable", isChecked: false },
        { id: "nonRefundable", label: "Non Refundable", isChecked: false },
      ];

      //Setting Fare Types Filters
      // let { fareType } = flightDetails[i].fareFamilies.fareFamilies[0];
      // checkIfExist(filtersObj.fareTypes, fareType) &&
      //   filtersObj.fareTypes.push({
      //     id: fareType,
      //     label: fareType,
      //     isChecked: false,
      //   });

      //Setting Fare Types Filters
      let { coupanType } = flightDetails[i].fareFamilies.fareFamilies[0];
      checkIfExist(filtersObj.fareTypes, coupanType) &&
        filtersObj.fareTypes.push({
          id: coupanType,
          label: coupanType,
          isChecked: false,
        });

      //Setting Connecting Location Filters
      if (flightSegLength > 1) {
        const { flightSegments } = flightDetails[i];
        const connFlightsSegments = flightSegments.filter(
          (segment, index) => index !== flightSegLength - 1
        );
        connFlightsSegments.map(({ destination, destiantionName }) => {
          checkIfExist(filtersObj.connect, destination) &&
            filtersObj.connect.push({
              id: destination,
              label: destiantionName,
              isChecked: false,
            });
        });
      }
    }
    setFilters(filtersObj);

    setPriceRange([filtersObj.price.minPrice, filtersObj.price.maxPrice]);
  };

  const checkedFilters = (filterType) => {
    return filters[filterType].filter((filter) => filter.isChecked);
  };

  const mapFlightWithFilter = (flight) => {
    let isVisible = true;

    const stopsChecked = checkedFilters("stops");
    if (
      stopsChecked.length &&
      !stopsChecked
        .map((stop) => stop.id)
        .includes(flight.flightSegments.length)
    ) {
      isVisible = false;
    }

    const flightTotPrice = flight.fareFamilies.fareFamilies[0].totalFare;
    if (
      !(
        flightTotPrice >= filters.price.minPrice &&
        flightTotPrice <= filters.price.maxPrice
      )
    ) {
      isVisible = false;
    }

    const departureTime = moment(
      flight.flightSegments[0].departureDateTime
    ).format("HH:mm");
    if (
      !(
        departureTime >= filters.departure.start &&
        departureTime <= filters.departure.end
      )
    ) {
      isVisible = false;
    }

    const arrivalTime = moment(
      flight.flightSegments[flight.flightSegments.length - 1].arrivalDateTime
    ).format("HH:mm");
    if (
      !(
        arrivalTime >= filters.arrival.start &&
        arrivalTime <= filters.arrival.end
      )
    ) {
      isVisible = false;
    }

    const airlinesChecked = checkedFilters("airlines");
    if (
      airlinesChecked.length &&
      !airlinesChecked.map((airline) => airline.id).includes(flight.airLine)
    ) {
      isVisible = false;
    }

    const fareTypeChecked = checkedFilters("fareType"),
      { isRefundable } = flight.fareFamilies.fareFamilies[0];
    if (
      fareTypeChecked.length &&
      !fareTypeChecked
        .map((ftype) => ftype.id)
        .includes(isRefundable ? "refundable" : "nonRefundable")
    ) {
      isVisible = false;
    }

    // const fareTypesChecked = checkedFilters("fareTypes");
    // if (fareTypesChecked.length) {
    //   const { fareType } = flight.fareFamilies.fareFamilies[0];
    //   if (!fareTypesChecked.map((ftypes) => ftypes.id).includes(fareType)) {
    //     isVisible = false;
    //   }
    // }

    const fareTypesChecked = checkedFilters("fareTypes");
    if (fareTypesChecked.length) {
      const { coupanType } = flight.fareFamilies.fareFamilies[0];
      if (!fareTypesChecked.map((ftypes) => ftypes.id).includes(coupanType)) {
        isVisible = false;
      }
    }

    const connectChecked = checkedFilters("connect");
    if (connectChecked.length) {
      const { flightSegments } = flight,
        connFlightsSegments = flightSegments.filter(
          (segment, index) => index !== flightSegments.length - 1
        );
      if (
        !connectChecked
          .map((conn) => conn.id)
          .some((connDest) =>
            connFlightsSegments
              .map(({ destination }) => destination)
              .includes(connDest)
          )
      ) {
        isVisible = false;
      }
    }

    isVisible && visibleCount++;

    setResultCount(visibleCount);

    return { ...flight, isVisible: isVisible };
  };

  const applyFilters = () => {
    if (selectedTripType === 1) {
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,
        flightDetails: flightDetails.map((flight) =>
          mapFlightWithFilter(flight, "1")
        ),
      });
    } else {
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,

        ibFlightDetails: flightDetails.map((flight) =>
          mapFlightWithFilter(flight, "2")
        ),
      });
    }
  };

  const appendZero = (hrMn) => {
    return ("0" + hrMn.hours).slice(-2) + ":" + ("0" + hrMn.minutes).slice(-2);
  };

  const priceChangeCompleteHandler = (priceVal) => {
    setFilters({
      ...filters,
      price: { ...filters.price, minPrice: priceVal[0], maxPrice: priceVal[1] },
    });
    scrollToListTop();
  };

  const timeChangeCompleteHandler = (filterType, time) => {
    console.log(time);
    // if (time.start.hours < 0) {
    //   return;
    // }
    setFilters({
      ...filters,
      [filterType]: {
        start: appendZero(time.start),
        end: appendZero(time.end),
      },
    });
    scrollToListTop();
  };

  const priceChangeHandler = (price) => {
    setPriceRange(price);
  };

  const depTimeHandler = (time) => {
    if (
      Number(time.start.split(":")[0]) >= 0 &&
      Number(time.end.split(":")[0]) >= 0
    ) {
      setDepartureVal(time);
    }
  };

  const arrTimeHandler = (time) => {
    if (
      Number(time.start.split(":")[0]) >= 0 &&
      Number(time.end.split(":")[0]) >= 0
    ) {
      setArrivalVal(time);
    }
  };

  useEffect(() => {
    setDynamicFilters();
  }, [selectedTripType]);

  useEffect(() => {
    Object.keys(filters).length && applyFilters();
  }, [filters]);

  const handleClear = (filterType) => {
    let initFilterType;
    if (filterType === "price") {
      initFilterType = {
        ...filters[filterType],
        minPrice: filters.price.minPriceRange,
        maxPrice: filters.price.maxPriceRange,
      };
      setPriceRange([filters.price.minPriceRange, filters.price.maxPriceRange]);
    } else if (filterType === "departure" || filterType === "arrival") {
      initFilterType = timeRange;

      if (filterType == "departure") {
        setDepartureVal(timeRange);
      } else {
        setArrivalVal(timeRange);
      }
    } else {
      initFilterType = filters[filterType].map((filt) => ({
        ...filt,
        isChecked: false,
      }));
    }
    setFilters({
      ...filters,
      [filterType]: initFilterType,
    });
    scrollToListTop();
  };

  const extraPanel = (filterType) => (
    <span
      onClick={(e) => {
        e.stopPropagation();
        handleClear(filterType);
      }}
    >
      Clear
    </span>
  );

  return (
    <div className="bus-filters-wrapper">
      <Card>
        <div className="bus-results">
          <p>
            Showing {resultCount} Of {count} Flights
          </p>
        </div>
        <div className="bus-filter-header">
          <p className="fil-text">Filters</p>
          <p
            className="clear-text"
            onClick={() => {
              ResetAirlineMatrix(true);
              setDynamicFilters();
            }}
          >
            Clear all
          </p>
        </div>
        <div className="collapse-wrapper">
          <div className="bus-stops-filter">
            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIconPosition={"right"}
            >
              <Panel header="Stops" key="1" extra={extraPanel("stops")}>
                {filters.stops &&
                  filters.stops.map((stop, i) => (
                    <p key={i + stop.id}>
                      <Checkbox
                        checked={stop.isChecked}
                        onChange={(e) => onChange(e, "stops", stop.id)}
                      >
                        {stop.label}
                      </Checkbox>
                    </p>
                  ))}
              </Panel>
            </Collapse>
          </div>
        </div>
        <div className="collapse-wrapper">
          <div className="bus-dro-pts ">
            <Collapse bordered={false}
              className="scroll"
              defaultActiveKey={["1"]} expandIconPosition={"right"}>
              <Panel
                header={"Fare Types"}
                key="1"
                extra={extraPanel("fareTypes")}
              >
                {filters.fareTypes &&
                  filters.fareTypes.map((ftypes) => (
                    <p>
                      <Checkbox
                        checked={ftypes.isChecked}
                        onChange={(e) =>
                          onChange(e, "fareTypes", ftypes.id)
                        }
                      >
                        {ftypes.label}
                      </Checkbox>
                    </p>
                  ))}
              </Panel>
            </Collapse>
          </div>
        </div>
        <div className="collapse-wrapper">
          <div className="bus-timings ">
            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIconPosition={"right"}
            >
              <Panel header="Price" key="1" extra={extraPanel("price")}>
                {filters.price && (
                  <Slider
                    range
                    step={1}
                    defaultValue={[
                      filters.price.minPrice,
                      filters.price.maxPrice,
                    ]}
                    value={priceRange}
                    min={filters.price.minPriceRange}
                    max={filters.price.maxPriceRange}
                    onChange={priceChangeHandler}
                    onAfterChange={priceChangeCompleteHandler}
                  />
                )}
                <div className="slider-label">
                  <span>₹ {priceRange[0]}</span>
                  <span>₹ {priceRange[1]}</span>
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
        <div className="collapse-wrapper">
          <div className="bus-type ">
            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIconPosition={"right"}
            >
              <Panel
                header="Departure Time"
                key="1"
                extra={extraPanel("departure")}
              >
                <TimeRangeSlider
                  disabled={false}
                  format={24}
                  maxValue={timeRange.end}
                  minValue={timeRange.start}
                  name={"dep_time_range"}
                  onChangeComplete={(time) =>
                    timeChangeCompleteHandler("departure", time)
                  }
                  onChange={depTimeHandler}
                  step={15}
                  value={departureVal}
                />
                <div className="slider-label">
                  <span>{departureVal.start}</span>
                  <span>{departureVal.end}</span>
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
        <div className="collapse-wrapper">
          <div className="bus-travels ">
            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIconPosition={"right"}
            >
              <Panel
                header="Arrival Time"
                key="1"
                extra={extraPanel("arrival")}
              >
                <TimeRangeSlider
                  disabled={false}
                  format={24}
                  maxValue={timeRange.end}
                  minValue={timeRange.start}
                  name={"arr_time_range"}
                  onChangeComplete={(time) =>
                    timeChangeCompleteHandler("arrival", time)
                  }
                  onChange={arrTimeHandler}
                  step={15}
                  value={arrivalVal}
                />
                <div className="slider-label">
                  <span>{arrivalVal.start}</span>
                  <span>{arrivalVal.end}</span>
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
        <div className="collapse-wrapper">
          <div className="bus-bor-pts ">
            <Collapse
              bordered={false}
              className="scroll"
              expandIconPosition={"right"}
            >
              <Panel header={"Airlines"} key="1" extra={extraPanel("airlines")}>
                {filters.airlines &&
                  filters.airlines.map((airline, i) => (
                    <p key={i + airline.id}>
                      <Checkbox
                        checked={airline.isChecked}
                        onChange={(e) => onChange(e, "airlines", airline.id)}
                      >
                        {airline.label}
                      </Checkbox>
                    </p>
                  ))}
              </Panel>
            </Collapse>
          </div>
        </div>
        <div className="collapse-wrapper">
          <div className="bus-dro-pts ">
            <Collapse
              bordered={false}
              className="scroll"
              expandIconPosition={"right"}
            >
              <Panel
                header={"Fare Type"}
                key="1"
                extra={extraPanel("fareType")}
              >
                {filters.fareType &&
                  filters.fareType.map((ftype, i) => (
                    <p key={i + ftype.id}>
                      <Checkbox
                        checked={ftype.isChecked}
                        onChange={(e) => onChange(e, "fareType", ftype.id)}
                      >
                        {ftype.label}
                      </Checkbox>
                    </p>
                  ))}
              </Panel>
            </Collapse>
          </div>
        </div>
        {/* <div className="collapse-wrapper">
          <div className="bus-dro-pts locations">
            <Collapse
              bordered={false}
              className="scroll"
              expandIconPosition={"right"}
            >
              <Panel
                header={"Connecting Locations"}
                key="1"
                extra={extraPanel("connect")}
              >
                {filters.connect &&
                  filters.connect.map((conn, i) => (
                    <p key={conn.id + i}>
                      <Checkbox
                        checked={conn.isChecked}
                        onChange={(e) => onChange(e, "connect", conn.id)}
                      >
                        <Tooltip
                          placement="topLeft"
                          overlayClassName="locTooltip"
                          title={<span> {conn.label}</span>}
                        >
                          {conn.label}
                        </Tooltip>
                      </Checkbox>
                    </p>
                  ))}
              </Panel>
            </Collapse>
          </div>
        </div> */}
      </Card>
    </div>
  );
};
export default FlightFilters;
