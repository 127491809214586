import React, { useState, useEffect } from "react";
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    Modal,
    message,
    Popconfirm,
    Row,
    Table,
} from "antd";
import {
    PlusCircleFilled,
    EditOutlined,
    DeleteOutlined,
    CloseOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import { exportExcel } from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";

const { Content } = Layout;
const { Search, TextArea } = Input;

const Query = () => {
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [QueryList, setQueryList] = useState([]);
    const [tableList, setTableList] = useState([]);
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [currentId, setCurrentId] = useState(-1);
    const [showSearchBox, setShowSearchBox] = useState(false);

    useEffect(() => {
        getQueryList();
    }, []);

    const getQueryList = () => {
        ApiClient.get("query/getquery")
            .then((res) => {
                if (res.isSuccess) {
                    let data = res.data.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                        };
                    });
                    setQueryList(data);
                    setTableList(data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const add = (val) => {
        ApiClient.post("query/savequery", val)
            .then((response) => {
                return response;
            })
            .then((response) => {
                if (response.isSuccess) {
                    setModalVisible(false);
                    message.success("Successfully added Query", 3);
                    getQueryList();
                    form.resetFields();
                } else if (!response.isSuccess) {
                    message.error("Query Already existed", 3);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleModal = () => {
        if (showUpdateBtn) {
            setShowUpdateBtn(false);
            form.resetFields();
        }
        setModalVisible(true);
    };

    const deleteQuery = (Record) => {
        let Id = Record.id;
        ApiClient.delete("query/deletequery/" + Id)
            .then((res) => {
                if (res.isSuccess) {
                    message.success("Query Deleted  successfully", 3);
                    getQueryList();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const updateQuery = (val) => {
        ApiClient.put(`query/updatequery/${currentId.id}`, {}, val).then(
            (resp) => {
                if (resp.isSuccess) {
                    getQueryList();
                    setModalVisible(false);
                    message.success("Query Updated successfully", 3);
                    setCurrentId(-1);
                }
            }
        );
    };

    const submitForm = (val) => {
        let obj = {
            queryName: val.queryName,
            queryDescription: val.queryDescription,
            status: true
        };
        if (showUpdateBtn) updateQuery(obj);
        else 
        {
            add(obj);
           // form.resetFields();
        };
    };

    const tableActions = (currentRecord, Name) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <EditOutlined
                        onClick={() => editCurrentItem(currentRecord, Name)}
                        style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
                    />
                </div>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <Popconfirm
                        title="Are you sure want to delete?"
                        onConfirm={() => deleteQuery(Name)}
                    >
                        <DeleteOutlined
                            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                        />
                    </Popconfirm>
                </div>
            </div>
        );
    };

    const editCurrentItem = (id, Name) => {
        setShowUpdateBtn(true);
        setCurrentId(id);
        form.setFieldsValue({
            queryName: Name.queryName,
            queryDescription: Name.queryDescription,
        });
        setModalVisible(true);
    };

    let lastIndex = 0;
    const uniqueKey = () => {
        return lastIndex++;
    };

    const searchData = () => {
        setShowSearchBox(true);
    };

    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "SNo",
            sorter: (a, b) => a.SNo - b.SNo,
        },
        {
            title: "Query Name",
            dataIndex: "queryName",
            sorter: (a, b) => a.name.localeCompare(b.name),
        },

        {
            title: "Query Description",
            dataIndex: "queryDescription",
            sorter: (a, b) => a.name.localeCompare(b.name),
            ellipsis: true,
        },

        {
            title: "Actions",
            render: (id, name) => tableActions(id, name),
        },
    ];

    const searchTableData = (e) => {
        var searchVal = e.target.value;
        if (searchVal.length > 0) {
            const searchResults = tableList.filter(function (item) {
                if (item != undefined) {
                    return JSON.stringify(item)
                        .toLowerCase()
                        .includes(searchVal.toLowerCase());
                }
            });
            setTableList(searchResults);
        } else {
            setTableList(QueryList);
        }
    };

    const closeSearch = () => {
        setShowSearchBox(false);
        setTableList(QueryList);
    };

    const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

    const handleExcel = () => {
        let excelData = tableList;
        excelData = excelData.map((item, i) => ({
            SNo: i + 1,
            queryName: item.queryName,
            queryDescription: item.queryDescription,
        }));

        exportExcel(excelData, "Query");
    };
    const getDataByName = (val) => {
        let obj = {
            filter: {
                id: 0,
                serviceType: "Buses",
                operatorName: "string",
                //isActive: true
            },
            page: 0,
        };
        ApiClient.post("query/getquery/list", obj)
            .then((response) => {
                return response;
            })
            .then((response) => {
                if (response.isSuccess) {
                    let data = response.results.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                        };
                    });

                    setQueryList(data);
                    setTableList(data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div className="outer-background-all">
        <div className="manage-markup-section">
                <Content className="container-fluid">
                    <div className="card-bt-gap">
                        <Card bordered={false} style={{borderRadius:"25px"}}>
                            <Row className="card-add-heading">
                                <Col md={14} xs={12}>
                                    <div className="rows-count">
                                        <h5>View Query</h5>
                                        <p>{tableList.length} rows found !</p>
                                    </div>
                                </Col>
                                <Col md={10} xs={12}>
                                    <Row>
                                        <Col md={14} xs={24}>
                                            <Form name="search">
                                                <AutoCompleteSelect
                                                    formItemProps={{
                                                        label: "",
                                                        name: "Search",
                                                        rules: [{ required: true, message: "Required!" }],
                                                    }}
                                                    selectProps={{
                                                        placeholder: "Search Query Name",
                                                        onSelect: (val) => getDataByName(val),
                                                    }}
                                                    api={"codes/getQueryautosuggestion/"}
                                                    keytext="name"
                                                    keyvalue="name"
                                                />
                                            </Form>
                                        </Col>
                                        <Col md={10} xs={24}>
                                            <div className="action-images">
                                                &nbsp;&nbsp;
                                                {/* <img src={search} alt="search" onClick={() => searchData()} /> */}
                                                <img
                                                    src={excel}
                                                    alt="excel"
                                                    onClick={() => handleExcel()}
                                                />
                                                <img
                                                    src={exchange}
                                                    alt="exchange"
                                                    onClick={() => {
                                                        setTableList([]);
                                                        getQueryList();
                                                    }}
                                                />
                                                <PlusCircleFilled
                                                    className="add-deposit-icon"
                                                    onClick={handleModal}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {tableList.length > 0 ? (
                                <Table
                                    scroll={{ x: true }}
                                    bordered
                                    rowKey={uniqueKey}
                                    dataSource={tableList}
                                    columns={columns}
                                    pagination={{
                                        defaultPageSize: 25,
                                        showSizeChanger: true,
                                        pageSizeOptions: ["25", "50", "100", "125"],
                                    }}
                                />
                            ) : (
                                "No Record Found"
                            )}
                        </Card>
                    </div>
                </Content>
            </div>
            <Modal
                title={[
                    <div>
                        <h6 style={{ marginBottom: "0px" }}>
                            <strong>
                                {showUpdateBtn ? "Update Query" : "Add Query"}
                            </strong>
                        </h6>
                    </div>,
                ]}
                className="promo-modal-header"
                visible={modalVisible}
                onOk={(e) => setModalVisible(false)}
                onCancel={(e) => setModalVisible(false)}
                footer={[
                    <Button key="add" type="primary" onClick={form.submit}>
                        {showUpdateBtn ? "Update" : "Add"}
                    </Button>,
                ]}
            >
                <Form layout="vertical" form={form} onFinish={submitForm}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Query"
                                name="queryName"
                                rules={[{ required: true, message: "Please enter Query" }]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="queryDescription" label="Query Description" rules={[{ required: false }]}>
                                <TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};
export default Query;
