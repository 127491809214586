import React, { useEffect } from "react";
import queryString from "query-string";
import { Row, Col, Layout, Card } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

const PgSuccess = () => {
  const params = queryString.parse(document.location.search);
  // useEffect(() => {

  // }, []);

  return (
    <div className="collapseCustomWhite">
      <div className=" providerapi">
        <Layout className="ourteam-bg">
          <div className="thankyou-panel">
            <div className="page-width-1">
              <Card>
                <Row justify="center">
                  <Col md="24" xs={24}>
                    {/* <img
                      className="img-thanku"
                      src={
                        require("../../../assets/images/thank-you-img.jpg")
                          .default
                      }
                      alt="search-img"
                    /> */}

                    <h5>
                     Your Transaction has been succesfully completed.
                    </h5>

                  
                  </Col>
                </Row>
              </Card>
            </div>
          </div>
        </Layout>
      </div>
    </div>
  );
};

export default PgSuccess;
