import React, { useState, useEffect } from "react";

import { Button, Col, Row, Tooltip } from "antd";
import "../SeperatedView/SeperatedView.scss";
import Flight, { FlightSelection, FlightTimings } from "../Flight/Flight";
import CustomNoResultFound from "../../ErrorPages/CustomNoResultFound";

const SeperatedView = (props) => {
  const flightInfo = props.selectedFlight;
  const [flightList, setFlightList] = useState([]);
  const JourneyType = props.journeyType;
  useEffect(() => {
    let visibleData = props.flightDetails.filter((item) => item.isVisible);
    setFlightList(visibleData);
  }, [props.flightDetails]);

  const editOnclick = () => {
    // setJourneyType("onward");
    // setFlightList(props.flightDetails);

    props.changeFlightResultsView([]);
    props.setOnwardFlights();
  };
  const toolBreakCity = (breakCityDest) => {
    return (
      <div className="tooltip-data">
        <h4>Change</h4>
        <p>{breakCityDest}</p>
      </div>
    );
  };
  return (
    <div className="flights-seperated-view">
      {JourneyType === "return" ? (
        flightInfo.length > 0 ? (
          <div className="edit-onward">
            <div className="card-results">
              <div className="card-results-travel-details">
                <div className="onward_wrapper">
                  <p className="title"> Onward</p>
                  <p className="title"> Selection</p>
                </div>
                <div className="itinary_wrapper">
                  <div className="itinary-details">
                    <div className="itinary-flight-name">
                      <img
                        className="airline-img"
                        src={flightInfo[0].airLineLogo}
                        alt={flightInfo[0].airLineLogo}
                      />
                      <p className="name">{flightInfo[0].airLineName}</p>
                    </div>
                    <div className="fromTo">
                      <div className="cities-from-to">
                        <div className="loc_wrapper">
                          <span className="name">
                            {
                              flightInfo[0].flightSegments[0].originName.split(
                                " "
                              )[0]
                            }
                          </span>
                          <span className="code">
                            ({flightInfo[0].flightSegments[0].origin})
                          </span>

                          <div className="break-journey-city">
                            <div className="inner_wrapper">
                              {flightInfo[0].flightSegments.map(
                                (flightSegInfo, index) =>
                                  index !== 0 ? (
                                    <div
                                      className="stop_points"
                                      key={flightSegInfo.segId}
                                    >
                                      <Tooltip
                                        placement="top"
                                        title={toolBreakCity(
                                          flightSegInfo.origin +
                                            " " +
                                            flightSegInfo.originName
                                        )}
                                        className="citynames-tooltip"
                                      >
                                        <span className="break-city-name pointer_cursor">
                                          {flightSegInfo.origin}
                                        </span>
                                      </Tooltip>
                                    </div>
                                  ) : null
                              )}
                            </div>
                          </div>

                          <span className="name">
                            {
                              flightInfo[0].flightSegments[
                                flightInfo[0].flightSegments.length - 1
                              ].destiantionName.split(" ")[0]
                            }
                          </span>
                          <span className="code">
                            (
                            {
                              flightInfo[0].flightSegments[
                                flightInfo[0].flightSegments.length - 1
                              ].destination
                            }
                            )
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="slectedtiming">
                    <FlightTimings
                      flightDetails={flightInfo[0]}
                      flightSearchData={props.flightSearchData}
                    />
                    <div className="flight-class-details">
                      <span className="stopsType">
                        {flightInfo[0].flightSegments.length - 1 === 0 ? (
                          <>Direct</>
                        ) : (
                          <>{flightInfo[0].flightSegments.length - 1} stops </>
                        )}{" "}
                        | {props.flightSearchData.cabinClass}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="btncontainer">
                  <p className="price">
                    <span>₹</span>
                    {flightInfo[0].fareFamilies.fareFamilies[0].adultNetFare.toFixed(
                      2
                    )}{" "}
                  </p>
                  <Button onClick={editOnclick} className="tg-primary-btn">
                    Change
                  </Button>
                </div>
              </div>
            </div>
            <p className="returntext"> Select For Return</p>
          </div>
        ) : null
      ) : null}

      {flightList.length > 0 ? (
        <div className="resultsScrollWrapper">
          {flightList.map((flight) => (
            <div className="flightCardWrapper">
              <Flight
                flightInfo={flight}
                key={flight.flightId}
                // changeFlightResultsView={changeFlightResultsView}
                flightSearchData={props.flightSearchData}
                setFlightFareRules={props.setFlightFareRules}
              />

              <div className="choose-btn-wrapper flights-btn-view">
                <FlightSelection
                  flightInfo={flight}
                  changeFlightResultsView={props.changeFlightResultsView}
                  flightSearchData={props.flightSearchData}
                  flightDetails={props.flightDetails}
                  selectedFlight={props.selectedFlight}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <CustomNoResultFound title={"No Flights Available"} />
      )}
    </div>
  );
};

export default SeperatedView;
