import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, Card, Col, Layout, Skeleton, Row } from "antd";

import "./Busticketsuccess.scss";
const { Content } = Layout;

const Busticketsuccess = ({ busTicketsDetails, loading }) => {
 
  let history = useHistory();
  const goToTicket = () => {
    history.push(`/admin/bus_ticket?refNo=${busTicketsDetails.refNo}`);
  };

  return (
    <Content className="admin-container cms-pages-width retrieve-linebar">
      <Card className="retrieve-booking busmt">
        {loading ? (
          <Skeleton active paragraph={{ rows: 2 }} />
        ) : busTicketsDetails.status === "CONFIRMED" ? (
          <>
            <div className="paymet-booking-tic">
              <h4>Bus Ticket Booking Payment Successful</h4>
              <p>
                A confirmation email has been sent your provided email address
              </p>
              <p className="d-flex">
                {" "}
                <span>Ref No : {busTicketsDetails.refNo}</span>{" "}
                <span className="px-5">
                  Ticket No : {busTicketsDetails.tktNo}
                </span>{" "}
              </p>
              <p></p>
              <p className="d-flex">
                {" "}
                <span>PNR No : {busTicketsDetails.pnrNo}</span>{" "}
                <span className="px-5">
                  Status : <span className="succes-pay-value">{busTicketsDetails.status}</span>
                </span>{" "}
              </p>
              <p></p>
            </div>

            <Row>
              <Col lg={6} md={8} xs={12}>
                <Button
                  className="tic-retrive-book"
                  onClick={() => goToTicket()}
                >
                  {/* <Link
                    target="_blank"
                    to={`/bus_ticket?tktNo=${busTicketsDetails.tktNo}`}
                  >
                    Retrieve Booking
                  </Link> */}
                  Retrieve Booking
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <div className="paymet-booking-tic">
            <h4>Bus Ticket Booking Failed</h4>
            {busTicketsDetails.status === "BOOK_FAILED" ? (
              <p>Error : {busTicketsDetails.error}</p>
            ) : null}

            <p>
              Status : <span className="succes-pay-value">Failed</span>
            </p>
          </div>
        )}
      </Card>
    </Content>
  );
};

export default Busticketsuccess;
