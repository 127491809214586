import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  message,
  Popconfirm,
  Row,
  Table,
  Select,
  DatePicker,
  Spin
} from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
import { exportExcel } from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../helpers/ApiClient";
import { useAuthContext } from "../../common/providers/AuthProvider";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import SessionTimeOutModal from "../../common/SessionTimeOutModal/SessionTimeOutModal";
const { Content } = Layout;


export const getTicketDetails = (tktNo, record) => {
  let url = "/admin/bus_ticket";
  return <Link to={url + "?refNo=" + tktNo}>{tktNo}</Link>;
};

const ConsolidateStatements = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [siteAdminList, setSiteAdminList] = useState([]);
  const [statementList, setStatementList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [currentId, setCurrentId] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [table, setTable] = useState(false);
  const [reportRole, setReportRole] = useState(0);
  const [arrivalDate, setArrivalDate] = useState(null);
  const [weekendDate, setWeekendDate] = useState(null);
  const [ledgerReport, setLedgerReport] = useState([])
  const { user } = useAuthContext();
  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const [filterdata, setfilterdata] = useState(Object);
  const [isSessionTimeOutModel, setIsSessionTimeOutModel] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState();
  const [ReportType, setReportType] = useState('Distinct');

  let userId = 1;
  let pageObj = {
    page: 0,
  };
  const apisOptions = [
    { name: "NONE", value: "NONE" },
    { name: "IRCT", value: "IRCT" },
    { name: "REDB", value: "REDB" },
    { name: "BTLA", value: "BTLA" },
    { name: "EZIF", value: "EZIF" },
    { name: "TRYR", value: "TRYR" },
    { name: "ETST", value: "ETST" },
    { name: "ITSG", value: "ITSG" },
    { name: "VRLT", value: "VRLT" },
    { name: "ZGBS", value: "ZGBS" },
    { name: "YLBS", value: "YLBS" },
    { name: "ABHI", value: "ABHI" },
  ]
  const LedgerRecordsCol = [
    {
      title: "CompanyName",
      dataIndex: "companyName",
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: "Ref No",
      dataIndex: "referenceNo",
      render: (ref, record) => getTicketDetails(ref, record),
      sorter: (a, b) => a.referenceNo.localeCompare(b.referenceNo),
    },
    {
      title: "PNR",
      dataIndex: "pnr",
      sorter: (a, b) => a.referenceNo.localeCompare(b.referenceNo),
    },
    {
      title: "Operator",
      dataIndex: "operator",

    },
    {
      title: "TicketId",
      dataIndex: "ticketId",

    },


    {
      title: "RouteName",
      dataIndex: "routeName",
    },

    {
      title: "JryDate",
      dataIndex: "journeyDate",
      sorter: (a, b) => a.journeyDate.localeCompare(b.journeyDate),
      render: (journeyDate) => moment(journeyDate).format("DD-MM-YYYY")
    },
    {
      title: "Pax Count",
      dataIndex: "noOfPax",

    },

    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.referenceNo.localeCompare(b.referenceNo),
    },
    {
      title: "TicketAmount",
      dataIndex: "ticketAmount"
    },
    {
      title: "BaseFare",
      dataIndex: "baseFare"
    },
    {
      title: "OpGST",
      dataIndex: "opgst",
    },
    {
      title: "ServiceCharge",
      dataIndex: "serviceCharge",

    },
    {
      title: "Commission",
      dataIndex: "commission",
      sorter: (a, b) => a.commission - b.commission,
    },
    {
      title: "GST",
      dataIndex: "gst",
    },

    {
      title: "TDS",
      dataIndex: "tds",
      sorter: (a, b) => a.tds - b.tds,
    },
    {
      title: "C_Charges",
      dataIndex: "cancellationCharges",
      sorter: (a, b) => a.cancellationCharges - b.cancellationCharges,
    },
    {
      title: "ServiceTaxOnCC",
      dataIndex: "serviceTaxOnCC",
    },

    {
      title: "OpenBalance",
      dataIndex: "openBalance",
      sorter: (a, b) => a.openBalance - b.openBalance,
    },
    {
      title: "TransactionAmount",
      dataIndex: "amount"
    },
    {
      title: "CloseBalance",
      dataIndex: "closeBalance",
      sorter: (a, b) => a.closeBalance - b.closeBalance,
    },
    {
      title: "RefundAmount",
      dataIndex: "refundAmount",
      sorter: (a, b) => a.refundAmount - b.refundAmount,
    },
    {
      title: "RevokeCommission",
      dataIndex: "revokeCommission",
      sorter: (a, b) => a.revokeCommission - b.revokeCommission,
    },
    {
      title: "RefundGST",
      dataIndex: "refundGST",
      sorter: (a, b) => a.refundGST - b.refundGST,
    },
    {
      title: "RefundTDS",
      dataIndex: "refundTDS",
      sorter: (a, b) => a.refundTDS - b.refundTDS,
    },
    {
      title: "AdminCommission",
      dataIndex: "adminCommission",
      sorter: (a, b) => a.adminCommission - b.adminCommission,
    },
    {
      title: "ProfitOrLoss",
      dataIndex: "profitOrLoss",
      sorter: (a, b) => a.profitOrLoss - b.profitOrLoss,
    },
    {
      title: "MarkUpAmount",
      dataIndex: "markUpAmount",
      sorter: (a, b) => a.markUpAmount - b.markUpAmount,
    },
    {
      title: "isDebit",
      dataIndex: "isDebit",
      render: (isDebit, records) => isDebit ? "True" : "False"
    },
    {
      title: "Txn Date",
      dataIndex: "transactionDate",
      sorter: (a, b) => a.transactionDate.localeCompare(b.transactionDate),
      render: (journeyDate) => moment(journeyDate).format("DD-MM-YYYY HH:mm")
    },


    // {
    //   title: "BookingDate",
    //   dataIndex: "bookingDate",
    //   sorter: (a, b) => a.transactionDate.localeCompare(b.transactionDate),
    //   render: (bookingDate) => moment(bookingDate).format("DD-MM-YYYY HH:mm")

    // },
    {
      title: "Txn Type",
      dataIndex: "TransactionTypeName"
    },
    {
      title: "API",
      dataIndex: "supplierName"
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
    }
  ];

  const ConsolidateLedgerRecordsCol = [
    {
      title: "CompanyName",
      dataIndex: "companyName",
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: "Pax Count",
      dataIndex: "noOfPax",

    },
    {
      title: "TicketAmount",
      dataIndex: "ticketAmount"
    },
    {
      title: "BaseFare",
      dataIndex: "baseFare"
    },
    {
      title: "OpGST",
      dataIndex: "opgst",
    },
    {
      title: "ServiceCharge",
      dataIndex: "serviceCharge",

    },
    {
      title: "Commission",
      dataIndex: "commission",
      sorter: (a, b) => a.commission - b.commission,
    },
    {
      title: "GST",
      dataIndex: "gst",
    },

    {
      title: "TDS",
      dataIndex: "tds",
      sorter: (a, b) => a.tds - b.tds,
    },
    {
      title: "C_Charges",
      dataIndex: "cancellationCharges",
      sorter: (a, b) => a.cancellationCharges - b.cancellationCharges,
    },
    {
      title: "ServiceTaxOnCC",
      dataIndex: "serviceTaxOnCC",
    },

    // {
    //   title: "OpenBalance",
    //   dataIndex: "openBalance",
    //   sorter: (a, b) => a.openBalance - b.openBalance,
    // },
    {
      title: "TransactionAmount",
      dataIndex: "amount"
    },
    // {
    //   title: "CloseBalance",
    //   dataIndex: "closeBalance",
    //   sorter: (a, b) => a.closeBalance - b.closeBalance,
    // },
    {
      title: "RefundAmount",
      dataIndex: "refundAmount",
      sorter: (a, b) => a.refundAmount - b.refundAmount,
    },
    {
      title: "RevokeCommission",
      dataIndex: "revokeCommission",
      sorter: (a, b) => a.revokeCommission - b.revokeCommission,
    },
    {
      title: "RefundGST",
      dataIndex: "refundGST",
      sorter: (a, b) => a.refundGST - b.refundGST,
    },
    {
      title: "RefundTDS",
      dataIndex: "refundTDS",
      sorter: (a, b) => a.refundTDS - b.refundTDS,
    },
    {
      title: "AdminCommission",
      dataIndex: "adminCommission",
      sorter: (a, b) => a.adminCommission - b.adminCommission,
    },
    {
      title: "ProfitOrLoss",
      dataIndex: "profitOrLoss",
      sorter: (a, b) => a.profitOrLoss - b.profitOrLoss,
    },
    {
      title: "MarkUpAmount",
      dataIndex: "markUpAmount",
      sorter: (a, b) => a.markUpAmount - b.markUpAmount,
    },
    // {
    //   title: "isDebit",
    //   dataIndex: "isDebit",
    //   render: (isDebit, records) => isDebit ? "True" : "False"
    // }
  ];
  useEffect(() => {
    let ledgerObj = {
      filter: {
        fromDate: null,
        toDate: null,
        refNo: null,
        userId: null,
        status: null,
        pnrNumber: null,
        showBookings: null,
        searchType: "2",
        supplier: null,
        reportType: "Distinct"
      },
      page: 0

    }
    getLedgerReport(ledgerObj)
    // getSiteAdminList();
    setfilterdata(ledgerObj)
    //  getAccountStatements(userId, pageObj);
    ReportType == "Distinct" ? setColumns(LedgerRecordsCol) : setColumns(ConsolidateLedgerRecordsCol)
  }, []);

  const TransactionType = (txType) => {
    let TransactionTypeName = ''
    switch (txType) {
      case 1: {
        TransactionTypeName = "Deposit";
        break;
      }
      case 2: {
        TransactionTypeName = "BusBooking";
        break;
      }
      case 3: {
        TransactionTypeName = "BusTicketCancel";
        break;
      }
      case 4: {
        TransactionTypeName = "Flight";
        break;
      }
      default: TransactionTypeName = ''
    }
    return TransactionTypeName
  }
  const getSiteAdminList = () => {
    let data = {
      page: 0,
    };
    ApiClient.post("user/getuserslist/list", data)
      .then((res) => {
        if (res.isSuccess) {
          let data = res.results.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setSiteAdminList(data);
        }
      })
      .catch((e) => {
        console.log("api error", e);
      });
  };

  const onChangeFromDate = (current, dateString) => {
    // Can not select days before today and toda
    setArrivalDate(dateString);
  };
  const onChangeToDate = (current, dateString) => {
    // Can not select days before today and today
    setWeekendDate(dateString);
  };
  const disabledFromDate = (current) => {
    if (weekendDate != null) {
      return current.valueOf() > (new Date(weekendDate));
    }
    // return current && current > moment().endOf('day');

    return current.valueOf() > Date.now();
  }
  const disabledToDate = (current) => {
    // Can not select days before today and today
    return current.valueOf() > Date.now() || current.valueOf() < (new Date(arrivalDate));
  };


  const getAccountStatements = (UserId, obj) => {
    ApiClient.post(`account/accountstatement/${UserId}`, obj)
      .then((res) => {
        if (res.isSuccess) {
          let data = res.results.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              TransactionTypeName: TransactionType(item.transactionType)
            };
          });
          setStatementList(data);
          setTableList(data);
          // setTable(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getLedgerReport = (obj) => {
    console.log(isLoading, "isLoading");
    setIsLoading(true);
    ApiClient.post(`booking/busconsolidateledgerreport`, obj)
      .then((res) => {
        /** res will be return undefined if the API is UnAuthorize   */
        if (res == "TypeError: Failed to fetch") {
          setIsSessionTimeOutModel(true)
        }

        if (res.isSuccess) {

          let data = res.results.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              TransactionTypeName: TransactionType(item.transactionType)
            };
          });
          setIsLoading(false);
          setTableList(data);
          setLedgerReport(data)

        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const handleTimeAndDate = (value) => {

    if (value === "5") {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };

  const handleModal = () => {
    if (showUpdateBtn) {
      setShowUpdateBtn(false);
      form.resetFields();
    }
    setModalVisible(true);
  };

  const submitForm = (val) => {
    setIsLoading(true);
    val.reportType = val.reportType == undefined ? "Distinct" : val.reportType
    setReportType(val.reportType)

    val.reportType == "Distinct" ? setColumns(LedgerRecordsCol) : setColumns(ConsolidateLedgerRecordsCol)

    let ledgerobj = {
      filter: {
        fromDate: val.fromDate ?? null,
        toDate: val.toDate ?? null,
        userId: val.reportsOf === 0 ? user.id : val.userId,
        //  userId: user.role.toUpperCase() === "ADMIN" ? val.reportsOf === 0 ? 1 : val.userId : user.id,
        searchType: val.searchType ?? "2", //"Today=2"
        supplier: val.supplier == "NONE" || val.supplier == undefined ? null : val.supplier,
        reportType: val.reportType ?? "Distinct"
      },
      page: 0,
    };
    if (val.Ref) ledgerobj.filter.refNo = val.Ref;
    if (val.pnrNumber) ledgerobj.filter.pnrNumber = val.pnrNumber;
    if (val.status) ledgerobj.filter.status = val.status;

    if (ledgerobj) {
      getLedgerReport(ledgerobj);
      setfilterdata(ledgerobj);
      //  console.log(filterdata)
    }
  };

  const tableActions = (currentRecord, Name) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord, Name)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        {/* <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure want to delete?"
            onConfirm={() => deleteSuppliers(Name)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div> */}
      </div>
    );
  };

  const editCurrentItem = (id, data) => {
    setShowUpdateBtn(true);
    setCurrentId(id);
    form.resetFields();
    setModalVisible(true);
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };

  const searchData = () => {
    setShowSearchBox(true);
  };




  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(getLedgerReport);
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
    setTableList(getLedgerReport);
  };

  const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

  const handleExcel = () => {
    let excelData = tableList;
    if (ReportType == "Distinct") {

      excelData = excelData.map((item, i) => ({
        CompanyName: item.companyName,
        RefNo: item.referenceNo,
        PNR: item.pnr,
        Operator: item.operator,
        TicketId: item.ticketId,
        RouteName: item.routeName,
        JourneyDate: moment(item.journeyDate).format("DD-MM-YYYY HH:mm"),
        PaxCount: item?.noOfPax,
        Status: item.status,
        TicketAmount: item.ticketAmount,
        BaseFare: item.baseFare,
        OpGST: item.opgst,
        ServiceCharge: item.serviceCharge,
        Commission: item.commission,
        GST: item.gst,
        TDS: item.tds,
        CancellationCharges: item.cancellationCharges,
        ServiceTaxOnCC: item.serviceTaxOnCC,
        OpenBalance: item.openBalance,
        TransactionAmount: item.amount,
        CloseBalance: item.closeBalance,
        RefundAmount: item.refundAmount,
        RevokeCommission: item.revokeCommission,
        RefundGST: item.refundGST,
        RefundTDS: item.refundTDS,
        AdminCommission: item.adminCommission,
        ProfitOrLoss: item.profitOrLoss,
        MarkUpAmount: item.markUpAmount,
        isDebit: item.isDebit == 0 ? false : true,
        TransactionDate: moment(item.transactionDate).format("DD-MM-YYYY HH:mm"),
        TransactionType: TransactionType(item.transactionType),
        API: item.supplierName,
        Remarks: item.remarks,
      }));

    } else {
      excelData = excelData.map((item, i) => ({
        CompanyName: item.companyName,
        PaxCount: item?.noOfPax,
        TicketAmount: item.ticketAmount,
        BaseFare: item.baseFare,
        OpGST: item.opgst,
        ServiceCharge: item.serviceCharge,
        Commission: item.commission,
        GST: item.gst,
        TDS: item.tds,
        CancellationCharges: item.cancellationCharges,
        ServiceTaxOnCC: item.serviceTaxOnCC,
        // OpenBalance: item.openBalance,
        TransactionAmount: item.amount,
        // CloseBalance: item.closeBalance,
        RefundAmount: item.refundAmount,
        RevokeCommission: item.revokeCommission,
        RefundGST: item.refundGST,
        RefundTDS: item.refundTDS,
        AdminCommission: item.adminCommission,
        ProfitOrLoss: item.profitOrLoss,
        MarkUpAmount: item.markUpAmount,
        // isDebit: item.isDebit == 0 ? false : true,
      }));
    }
    exportExcel(excelData, "Statements");
  };

  return (
    <div className="outer-background-all">
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false} style={{ borderRadius: "25px" }}>
              {/* <div className="card-add-heading"> */}
              <h5>Statements</h5>
              <Form
                layout="vertical"
                form={form}
                onFinish={submitForm}
              // initialValues={{
              //   fromDate: "",
              //   toDate: "",
              //   page: 0,
              // }}
              >
                <Row gutter={12} align="middle">
                  <Col md={5} xs={24}>
                    <Form.Item
                      label="Reports of"
                      name="reportsOf"
                    // rules={[{ required: true, message: "Required" }]}
                    >
                      <Select
                        placeholder="Select"
                        onSelect={(val) => setReportRole(val)}
                      >
                        <Option value={0}>Self</Option>
                        <Option value={5}>Specific Company</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {reportRole === 5 && (
                    <Col md={5} sm={12} xs={24}>
                      <AutoCompleteSelect
                        formItemProps={{
                          label: "Company Name",
                          name: "userId",
                          rules: [
                            {
                              required: true,
                              message: "Please enter User Name",
                            },
                          ],
                        }}
                        selectProps={{
                          placeholder: "Please Enter User Name",
                        }}
                        api={"user/userautosuggestion/"}
                        keytext="name"
                        keyvalue="id"
                      />

                    </Col>
                  )}
                  <Col md={5} xs={24}>
                    <Form.Item
                      label="Search Type"
                      name="searchType"

                    >
                      <Select placeholder="select Status" onChange={handleTimeAndDate} defaultValue="2">
                        <Option value="1">Lasthour </Option>
                        <Option value="2">Today </Option>
                        <Option value="3">Yesterday </Option>
                        <Option value="4">Lastweek </Option>
                        <Option value="5">CustomDates  </Option>
                      </Select>
                    </Form.Item>

                  </Col>
                  {isDateDisplay ? (<>
                    <Col md={4} sm={12} xs={24}>
                      <Form.Item
                        label="From Date"
                        name="fromDate"
                      // rules={[
                      //   { required: true, message: "Please enter From Date" },
                      // ]}
                      >
                        <DatePicker
                          format="YYYY-MM-DD"
                          placeholder="Please Enter From Date"
                          style={{ width: "100%" }}
                          disabledDate={disabledFromDate}
                          onChange={(date, dateString) =>
                            onChangeFromDate(date, dateString)}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={4} sm={12} xs={24}>
                      <Form.Item
                        label="To Date"
                        name="toDate"

                      >
                        <DatePicker
                          format="YYYY-MM-DD"
                          placeholder="Please Enter To Date"
                          style={{ width: "100%" }}
                          disabledDate={disabledToDate}
                          onChange={(date, dateString) =>
                            onChangeToDate(date, dateString)

                          }
                        />
                      </Form.Item>
                    </Col>

                  </>) : null}
                  <Col md={4} sm={12} xs={24}>
                    <Form.Item
                      label="PNR No"
                      name="pnrNumber"

                    >
                      <Input placeholder="Please Enter PNR Number" />
                    </Form.Item>
                  </Col>
                  <Col md={4} sm={12} xs={24}>
                    <Form.Item
                      label="Reference No"
                      name="Ref"
                    >
                      <Input placeholder="Please Enter Reference Number" />
                    </Form.Item>
                  </Col>
                  <Col md={4} sm={12} xs={24}>
                    <Form.Item
                      label="Status"
                      name="status"

                    >
                      <Select placeholder="Please select Status">
                        <Option value={0}>NONE </Option>
                        <Option value={1}>BLOCKED </Option>
                        <Option value={2}>CONFIRMED </Option>
                        <Option value={3}>TRAVELLED </Option>
                        <Option value={4}>CANCELLED </Option>
                        <Option value={5}>CANCELLED PARTIALLY </Option>
                        <Option value={6}>BLOCK FAILED </Option>
                        <Option value={7}>BLOCK PENDING </Option>
                        <Option value={8}>BOOK FAILED </Option>
                        <Option value={9}>BOOK PENDING </Option>
                        <Option value={10}>CANCEL FAILED </Option>
                        <Option value={11}>CANCEL PENDING </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={4} sm={12} xs={24}>
                    <Form.Item name="supplier" label="supplier">
                      <Select placeholder="Please select Service Name">
                        {apisOptions.map(item => (<Option value={item.value}>{item.name}</Option>))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={4} sm={12} xs={24}>
                    <Form.Item
                      label="Report Type"
                      name="reportType"
                    >
                      <Select
                        placeholder="Select"
                        onSelect={(val) => setReportRole(val)}
                        defaultValue="Distinct"
                      >
                        <Option value="Consolidate">Consolidate</Option>
                        <Option value="Distinct">Distinct </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Button
                      key="add"
                      className="site-admin-btn-11"
                      type="primary"
                      onClick={form.submit}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>
              {isLoading ? (<>
                <Spin
                  size="large"
                  tip="Loading..."
                  style={{ width: "100%" }}
                />
              </>) : tableList.length > 0 ? (
                <>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <h5>View Account Statements</h5>
                      <p>{tableList.length} rows found !</p>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          autocomplete="newpassword"
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px", maxWidth: "249px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={() => searchData()}
                      />
                      <img
                        src={excel}
                        alt="excel"
                        onClick={() => handleExcel()}
                      />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => {
                          setTableList([]);
                          getLedgerReport(filterdata);
                        }}
                      />
                      {/* <p className="add-deposit-icon" onClick={handleModal}>
                    <PlusCircleFilled />
                  </p> */}
                    </div>
                  </div>

                  <Table
                    scroll={{ x: true }}
                    bordered
                    rowKey={uniqueKey}
                    dataSource={tableList}
                    columns={columns}
                    pagination={{
                      defaultPageSize: 25,
                      showSizeChanger: true,
                      pageSizeOptions: ["25", "50", "100", "125"],
                    }}
                  />
                </>
              )
                : (
                  <b>No Results Found!</b>
                )}
              {/* </div> */}
            </Card>
          </div>
        </Content>
        {
          isSessionTimeOutModel ? (<SessionTimeOutModal isModelVisible={isSessionTimeOutModel} />) : null
        }
      </div>
    </div>
  );
};
export default ConsolidateStatements;
