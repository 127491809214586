import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  message,
  Popconfirm,
  Row,
  Table,
  Select,
} from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import { exportExcel } from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
import SessionTimeOutModal from "../../common/SessionTimeOutModal/SessionTimeOutModal";
const { Content } = Layout;

const Screens = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [screensList, setScreensList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [currentId, setCurrentId] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [isSessionTimeOutModel, setIsSessionTimeOutModel] = useState(false)

  useEffect(() => {
    getScreensList();
    getCategoryList();
  }, []);

  const getCategoryList = () => {
    ApiClient.get("codes/getcategories")
      .then((res) => {
        /** res will be return undefined if the API is UnAuthorize   */
        if (res == "TypeError: Failed to fetch") {
          setIsSessionTimeOutModel(true)
        }
        if (res.isSuccess) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setCategoryList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getScreensList = () => {
    ApiClient.get("codes/getscreens")
      .then((res) => {
        /** res will be return undefined if the API is UnAuthorize   */
        if (res == "TypeError: Failed to fetch") {
          setIsSessionTimeOutModel(true)
        }
        if (res.isSuccess) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setScreensList(data);
          setTableList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const add = (val) => {
    ApiClient.post("codes/savescreen", val)
      .then((response) => {
        return response;
      })
      .then((response) => {
        /** res will be return undefined if the API is UnAuthorize   */
        if (response == "TypeError: Failed to fetch") {
          setIsSessionTimeOutModel(true)
        }
        if (response.isSuccess) {
          setModalVisible(false);
          message.success("Successfully added screens", 3);
          getScreensList();
          form.resetFields();
        } else if (!response.isSuccess) {
          message.error("Screens Already existed", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleModal = () => {
    if (showUpdateBtn) {
      setShowUpdateBtn(false);
      form.resetFields();
    }
    setModalVisible(true);
  };

  const deleteScreen = (Record) => {
    let Id = Record.id;
    ApiClient.delete("codes/deletescreen/" + Id)
      .then((res) => {
        /** res will be return undefined if the API is UnAuthorize   */
        if (res== "TypeError: Failed to fetch") {
          setIsSessionTimeOutModel(true)
        }
        if (res.isSuccess) {
          message.success("Screens Deleted  successfully", 3);
          getScreensList();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const updateScreen = (val) => {
    ApiClient.put(`codes/updatescreen/${currentId.id}`, {}, val).then(
      (resp) => {
        /** res will be return undefined if the API is UnAuthorize   */
        if (resp == "TypeError: Failed to fetch") {
          setIsSessionTimeOutModel(true)
        }
        if (resp.isSuccess) {
          getScreensList();
          setModalVisible(false);
          message.success("Screens Updated successfully", 3);
          setCurrentId(-1);
        }
      }
    );
  };

  const submitForm = (val) => {
    let obj = {
      ...val,
      isActive: true,
    };
    if (showUpdateBtn) updateScreen(obj);
    else add(obj);
  };

  const tableActions = (currentRecord, Name) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord, Name)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure want to delete?"
            onConfirm={() => deleteScreen(Name)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const editCurrentItem = (id, data) => {
    setShowUpdateBtn(true);
    setCurrentId(id);
    form.setFieldsValue({
      ...data,
    });
    setModalVisible(true);
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };

  const searchData = () => {
    setShowSearchBox(true);
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    {
      title: "Category Name",
      dataIndex: "categoryName",
      sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
    },
    {
      title: "Screens Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Path Name",
      dataIndex: "path",
      // sorter: (a, b) => a.path.localeCompare(b.path),
    },

    {
      title: "Actions",
      render: (id, name) => tableActions(id, name),
    },
  ];

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(screensList);
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
    setTableList(screensList);
  };

  const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

  const handleExcel = () => {
    let excelData = tableList;
    excelData = excelData.map((item, i) => ({
      SNo: i + 1,
      ScreenName: item.name,
      CategoryName: item.categoryName,
    }));

    exportExcel(excelData, "Screens");
  };
  const getDataByName = (val) => {
    let obj = {
      filter: {
        name: val,
        isActive: true,
      },
      page: 0,
    };
    ApiClient.post("codes/getscreens/list", obj)
      .then((response) => {
        return response;
      })
      .then((response) => {
        /** res will be return undefined if the API is UnAuthorize   */
        if (response == "TypeError: Failed to fetch") {
          setIsSessionTimeOutModel(true)
        }
        if (response.isSuccess) {
          let data = response.results.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });

          setScreensList(data);
          setTableList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="outer-background-all">
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false} style={{ borderRadius: "25px" }}>
              <Row className="card-add-heading">
                <Col md={14} xs={12}>
                  <div className="rows-count">
                    <h5>View Screens</h5>
                    <p>{tableList.length} rows found !</p>
                  </div>
                </Col>
                <Col md={10} xs={12}>
                  <Row>
                    <Col md={14} xs={24}>
                      <Form name="search">
                        <AutoCompleteSelect
                          formItemProps={{
                            label: "",
                            name: "Search",
                            rules: [{ required: true, message: "Required!" }],
                          }}
                          selectProps={{
                            placeholder: "Search Screen Name",
                            onSelect: (val) => getDataByName(val),
                          }}
                          api={"codes/getscreenautosuggestion/"}
                          keytext="name"
                          keyvalue="name"
                        />
                      </Form>
                    </Col>
                    <Col md={10} xs={24}>
                      <div className="action-images">
                        &nbsp;&nbsp;
                        {/* <img src={search} alt="search" onClick={() => searchData()} /> */}
                        <img
                          src={excel}
                          alt="excel"
                          onClick={() => handleExcel()}
                        />
                        <img
                          src={exchange}
                          alt="exchange"
                          onClick={() => {
                            setTableList([]);
                            getScreensList();
                          }}
                        />
                        <PlusCircleFilled
                          className="add-deposit-icon"
                          onClick={handleModal}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {tableList.length > 0 ? (
                <Table
                  scroll={{ x: true }}
                  bordered
                  rowKey={uniqueKey}
                  dataSource={tableList}
                  columns={columns}
                  pagination={{
                    defaultPageSize: 25,
                    showSizeChanger: true,
                    pageSizeOptions: ["25", "50", "100", "125"],
                  }}
                />
              ) : (
                "No Record Found"
              )}
            </Card>
          </div>
        </Content>
      </div>
      {
        isSessionTimeOutModel ? (<SessionTimeOutModal isModelVisible={isSessionTimeOutModel} />) : null
      }
      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>
                {showUpdateBtn ? "Update Screens" : "Add Screens"}
              </strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header bci-panel-admin-parts"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <Button key="add" type="primary" onClick={form.submit}>
            {showUpdateBtn ? "Update" : "Add"}
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm}>
          <Row gutter={12}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Screens Name"
                name="name"
                rules={[{ required: true, message: "Please enter screens" }]}
              >
                <Input autocomplete="newpassword" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Category"
                name="categoryId"
                rules={[{ required: true, message: "Please enter screens" }]}
              >
                <Select placeholder="Please select Category Type">
                  {categoryList.map((item) => (
                    <Option value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label="Mask Name" name="maskName">
                <Input
                  autocomplete="newpassword"
                  placeholder="Please Enter Mask Name"
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label="Path " name="path">
                <Input
                  autocomplete="newpassword"
                  placeholder="Please Enter Path Name"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default Screens;
