import React, { useEffect, useState } from "react";

import Flight, { FlightSelection } from "../Flight/Flight";

import CustomNoResultFound from "../../ErrorPages/CustomNoResultFound";
import { Col, Row } from "antd";

const CombinedView = (props) => {
  const flightList = props.flightList;

  const airTravelType = localStorage.getItem("travelType");
  const [combinedOnewayFlightList, setCombinedOneWayFlightList] = useState([]);
  const [combinedTwowayFlightList, setCombinedTwoWayFlightList] = useState([]);
  useEffect(() => {
    if (flightList.length > 0) {
      if (airTravelType === "oneWay") {
        let visibleData = flightList.filter((item) => item.isVisible);

        setCombinedOneWayFlightList(visibleData);
      } else {
        let tempResults = [];
        flightList.map((flightsData) => {
          let flightTemp = [];
          flightsData.flightDetails.map((flight) => {
            let flightObj = flight;
            flightObj["fareFamilies"] = flightsData.fareFamilies;
            flightTemp.push(flightObj);
          });
          tempResults.push(flightsData);
        });

        let visibleData = tempResults.map((item) => item.isVisible);
        setCombinedTwoWayFlightList(visibleData);
      }
    }
  }, [flightList]);
  const [selectedJourneyType, setSelectedJourneyType] = useState("onward");

  return flightList.length > 0 ? (
    airTravelType === "oneWay" ? (
      combinedOnewayFlightList.length > 0 ? (
        combinedOnewayFlightList.map((flight, i) => (
          <div className="flightCardWrapper">
            <Flight
              flightInfo={flight}
              key={flight.flightId}
              flightSearchData={props.flightSearchData}
              setFlightFareRules={props.setFlightFareRules}
            />

            <div className="choose-btn-wrapper flights-btn-view">
              <FlightSelection
                flightInfo={flight}
                changeFlightResultsView={props.changeFlightResultsView}
                flightSearchData={props.flightSearchData}
                setSelectedJourneyType={setSelectedJourneyType}
                selectedFlight={props.selectedFlight}
              />
            </div>
          </div>
        ))
      ) : (
        <CustomNoResultFound title={"No Flights Available"} />
      )
    ) : combinedTwowayFlightList.length > 0 ? (
      combinedTwowayFlightList.map((flights, i) => (
        <div key={i} className="combine-roundtrip-view">
          <Row>
            <Col md={18}>
              {flights.flightDetails.map((flight) => (
                <Flight
                  flightInfo={flight}
                  key={flight.flightId}
                  flightSearchData={props.flightSearchData}
                  setFlightFareRules={props.setFlightFareRules}
                />
              ))}
            </Col>
            <Col md={6}>
              <div className="choose-btn-wrapper">
                <FlightSelection
                  flightInfo={flights}
                  changeFlightResultsView={props.changeFlightResultsView}
                  flightSearchData={props.flightSearchData}
                  selectedFlight={props.selectedFlight}
                />
              </div>
            </Col>
          </Row>
        </div>
      ))
    ) : (
      <CustomNoResultFound title={"No Flights Available"} />
    )
  ) : null;
};

export default CombinedView;
