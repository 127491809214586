import React, { useContext, useEffect, useState, Suspense } from "react";
import { Col, Row, Modal, Button, Collapse } from "antd";
import "./BusResults.scss";
import { GlobalStatesContext } from "../../../common/providers";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import queryString from "query-string";
import BusSort from "../BusSort/BusSort";
import ApiClient from "../../../helpers/BusApiClient";
import Buses from "../../../components/buses-search/Buses";
import CustomNoResultFound from "../../../components/ErrorPages/CustomNoResultFound";
import FilterSkeleton from "../../../components/FilterSkeleton/FilterSkeleton";
//import BusFilter from "./BusFilter/BusFilter";
import BusResultCards from "./BusResultCards/BusResultCards";
import BusSkeleton from "./Skeleton/BusSkeleton";
import SessionTimeOutModal from "../../../common/SessionTimeOutModal/SessionTimeOutModal";

const BusFilter = React.lazy(() => import('./BusFilter/BusFilter'))


const BusResults = () => {
  const { user } = useAuthContext();
  const {
    state: { busSearchResultObj },
    updateBusSearchResultObj,
    updateSelectedBusData,
    RemovePromo,
  } = useContext(GlobalStatesContext);
  const [isSessionTimeOutModel, setIsSessionTimeOutModel] = useState(false)


  let busInitial = {
    trips: [],
    origin: "",
    destination: "",
  };

  const { Panel } = Collapse;

  const onChange = (key) => {
    console.log(key);
  };

  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalVisiblenew, setModalVisiblenew] = React.useState(false);
  const showModalbusfilters = () => {
    setModalVisible({
      visible: true,
    });
  };

  const showModalbussort = () => {
    setModalVisiblenew({
      visible: true,
    });
  };
  const [busResultsList, setBusResultsList] = useState(busInitial);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getBusesAvailability();
    RemovePromo();
  }, []);

  /* Buses Search Availability */
  const getBusesAvailability = () => {

    let param = queryString.parse(document.location.search);

    let origin = param?.sourceName;
    let destination = param?.destinationName;
    let originId = param?.sourceId;
    let destinationId = param?.destinationId;
    let formData = {
      trips: [
        {
          src: param?.sourceId,
          dst: param?.destinationId,
          doj: param?.journeyDate,
        },
      ],
      ext: {
        timeout: 60000,
        filters: true,
        tags: param?.tags != "" ? param.tags.split(",") : null,
        Api: param?.Api != "" ? param.Api.split(",") : null,
      },
    };
    fetchBusAvailability(
      formData,
      origin,
      destination,
      originId,
      destinationId,
      1
    );
  };

  const fetchBusAvailability = (
    formData,
    origin,
    destination,
    originId,
    destinationId,
    type
  ) => {
    setIsLoading(true);
    updateBusSearchResultObj({});
    setBusResultsList(busInitial);

    ApiClient.post("bus/search", formData)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp) {

          if (resp == "TypeError: Failed to fetch") {
            setIsSessionTimeOutModel(true)
          }
          if (resp?.trips && resp?.trips?.length > 0) {
            let busType = []
            let suppliers = []
            let supplierCount = {}
            let busTrips = {
              ...resp,
              data: {
                // trips: resp.trips[0].buses.map((trip) => ({
                //   ...trip,
                //   isVisible: true,
                // })),
                trips: resp.trips[0].buses.map((trip) => {

                  // trip.isVisible = true
                  if (!busType.filter(pt => pt?.id == trip.type).length > 0) {
                    busType.push({
                      id: trip.type,
                      label: trip.type,
                      isChecked: false,
                      count: resp?.trips[0]?.buses.filter(c => c.type == trip.type).length
                    })
                  }

                  if (!suppliers.filter(sp => sp?.id == trip.ecode).length > 0) {
                    suppliers.push({
                      id: trip.ecode,
                      label: trip?.ecode?.substr(trip?.ecode?.length - 4),
                      isChecked: false,
                      count: resp?.trips[0]?.buses.filter(c => c.ecode == trip.ecode).length
                    })
                  }

                  { supplierCount[trip?.ecode?.substr(trip?.ecode?.length - 4)] = (supplierCount[trip?.ecode?.substr(trip?.ecode?.length - 4)] || 0) + 1 }
                  return { ...trip, isVisible: true, }
                }),
                filters: { ...resp?.trips[0]?.filters, busType, suppliers, supplierCount },
                origin: origin,
                destination: destination,
                originId: originId,
                destinationId: destinationId,
                traceId: resp.traceId,
                doj:resp?.trips?.[0]?.summary?.doj,

              },
            };
            updateBusSearchResultObj({ ...busTrips, tripType: type });
            setBusResultsList(busTrips.data);
            setIsLoading(false)
          }
        }

        // setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="combined-results-container">
        {/* <div className="modify-combined-search-container buses-div-bg"> */}


        <Collapse className="collapse-modify-search" defaultActiveKey={['0']} onChange={onChange}>
          <Panel header="Modify Search" key="1">
            <div className="modify-combined-search-container">
              <div className="modify-combined-block block-of-info-bus">
                {/* <div className="accordion" id="accordionExample">
              <div className="card hotels-search-card-value">
                <div
                  className="card-head hide-icon-heading-two"
                  id="headingOne"
                >
                  <span
                    className="hotels-hide-search"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Modify Search
                  </span>
                </div>

                <div
                  id="collapseOne"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="search-modify-block bus-res-cc2"> */}
                <Buses modifySearch={true} busesSearchAPI={getBusesAvailability} />
                {/* </div>
                  </div>
                  </div>
                  </div>
                </div>*/}
              </div>
            </div>
          </Panel>
        </Collapse>

        <div className="fbt-tabs-bg tabs-fbt-space">
          <div className="tabs-container filters-number-tab">
            <Row
              gutter={16}
              className="margins-alignments"
            >
              {isLoading ? (
                <>
                  <Col md={6} className="filter-section-bus">
                    <FilterSkeleton />
                  </Col>
                  <Col md={18} sm={24} xs={24}>
                    <BusSkeleton />
                  </Col>
                </>
              ) : Object.keys(busResultsList).length > 0 &&
                // busSearchResultObj?.data &&
                busSearchResultObj?.data?.trips?.length > 0 ? (
                <>
                  <Col md={6} className="filter-section-bus">
                    <Suspense fallback={<div>Loading...</div>}>
                      <BusFilter
                        busSearchResultObj={busSearchResultObj}
                        setBusResultsList={setBusResultsList}
                      />
                    </Suspense>
                  </Col>
                  <Col md={18} sm={24} xs={24}>
                    <BusResultCards
                      updateSelectedBusData={updateSelectedBusData}
                      trips={busResultsList.trips}
                      busSearchResultObj={busSearchResultObj}
                      setBusResultsList={setBusResultsList}
                      ot={busResultsList}
                    />
                  </Col>
                </>
              ) : (
                <Col md={24} sm={24} xs={24}>
                  <CustomNoResultFound title={"No Buses Available"} />
                </Col>
              )}
            </Row>
          </div>
        </div>
      </div>
      {!isLoading ? (
        <Row className="fiters_wrapper">
          <Col md={12} xs={12} className="tc">
            <span className="title" onClick={showModalbusfilters}>
              {" "}
              <i className="fa fa-filter" aria-hidden="true"></i>
              &nbsp;Filters
            </span>
          </Col>
          <Col md={12} xs={12} className="tc">
            <span className="title" onClick={showModalbussort}>
              <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
              &nbsp;Sort by
            </span>
          </Col>
        </Row>
      ) : null}
      {
        isSessionTimeOutModel ? (<SessionTimeOutModal isModelVisible={isSessionTimeOutModel} />) : null
      }
      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong> Flight Filters</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button type="primary" onClick={() => setModalVisible(false)}>
              Close
            </Button>
          </div>,
        ]}
      >
        <Row>
          <Col md={24} xs={24}>
            <div>
              <BusFilter
                busSearchResultObj={busSearchResultObj}
                setBusResultsList={setBusResultsList}
              />
            </div>
          </Col>
        </Row>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Sort by</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header sort-modal-mobiletr"
        visible={modalVisiblenew}
        onOk={(e) => setModalVisiblenew(false)}
        onCancel={(e) => setModalVisiblenew(false)}
        footer={[
          <div>
            <Button type="primary">Apply</Button>
            {/*  <Button key="submit" type="danger" >Reset</Button> */}
          </div>,
        ]}
      >
        <Row>
          <Col md={24} xs={24}>
            <div>
              <BusSort
                busSearchResultObj={busSearchResultObj}
                setBusResultsList={setBusResultsList}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default BusResults;
