import React, { useState, useEffect } from "react";
import { Button, Row, Card, Checkbox, Col, Form, Input, Select } from "antd";
import FareDetails from "./FareDetails";
import BusDetails from "./BusDetails";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import "./Checkout.scss";
import { useHistory } from "react-router-dom";
import { getPassengerData } from "../../../helpers/PassegerData";
import moment from "moment";
const { Option } = Select;
const Checkout = (props) => {
  const { user } = useAuthContext();
  const { updateBusPassengerData, RemovePromo } = props.gscontext;
  const { selectedBusData } = props.gscontext.state;
  const { combineSearchData } = props.gscontext.state;
  const [onlyPassengersDetails, setOnlyPassengersDetails] = useState({});
  const [form] = Form.useForm();



  useEffect(() => {
    loadpassengerData();
    RemovePromo();
  }, []);
  // console.log(".......",combineSearchData,selectedBusData)
  const loadpassengerData = () => {
    let userID = 1;
    if (user) {
      if (user.UserID) {
        userID = user.UserID;
      }
    }
    if (userID != 1) {
      getPassengerData(userID).then((data) => {
        if (data.status) {
          form.setFieldsValue({ mobileNo: data.Mobile, emailId: data.Email });
        }
      });
    }
  };

  let history = useHistory();
  const updatePassengersData = () => {
    form
      .validateFields()
      .then((data) => {
        const { emailId, mobileNo } = data;
        sessionStorage.setItem("BusPaxFormData", JSON.stringify(data));
        sessionStorage.setItem(
          "BusPassengersDetails",
          JSON.stringify({ ...onlyPassengersDetails, emailId, mobileNo })
        );
        let busPaxArr = [];

        let userID = 1;
        if (user) {
          if (user.UserID) {
            userID = user.UserID;
          }
        }

        busPaxArr.push({
          tripId: selectedBusData[0].busCardId,
          userId: userID,
          searchId: selectedBusData[0].searchId,
          boardingId: selectedBusData[0].boardingTimes.PointId,
          droppingId: selectedBusData[0].droppingTimes.PointId,
          noofSeats: selectedBusData[0].seatsInfo.length,
          emailId: emailId,
          mobileNo: mobileNo,
          countryCode: "91",
          passengerDetails: buildPassengerArray(selectedBusData[0].seatsInfo),
        });
        if (props.gscontext.state.busData.type === "twoWay") {
          busPaxArr.push({
            tripId: selectedBusData[1].busCardId,
            searchId: selectedBusData[1].searchId,
            boardingId: selectedBusData[1].boardingTimes.PointId,
            droppingId: selectedBusData[1].droppingTimes.PointId,
            noofSeats: selectedBusData[1].seatsInfo.length,
            userId: userID,

            emailId: emailId,
            mobileNo: mobileNo,
            countryCode: "91",
            passengerDetails: buildPassengerArray(selectedBusData[1].seatsInfo),
          });
        }
        updateBusPassengerData(busPaxArr);
        history.push("/bus-review");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const buildPassengerArray = (selectedBusData) => {
    return selectedBusData.map((seat) => {
      return {
        age:
          onlyPassengersDetails[seat.SeatNo] &&
            onlyPassengersDetails[seat.SeatNo].age
            ? onlyPassengersDetails[seat.SeatNo].age
            : "10",
        name:
          onlyPassengersDetails[seat.SeatNo] &&
            onlyPassengersDetails[seat.SeatNo].name
            ? onlyPassengersDetails[seat.SeatNo].name
            : "",
        countryCode: "91",
        title:
          onlyPassengersDetails[seat.SeatNo] &&
            onlyPassengersDetails[seat.SeatNo].title
            ? onlyPassengersDetails[seat.SeatNo].title
            : "Mr",
        gender:
          onlyPassengersDetails[seat.SeatNo] &&
            onlyPassengersDetails[seat.SeatNo].gender
            ? onlyPassengersDetails[seat.SeatNo].gender
            : "M",
        seatNo: seat.SeatNo,
        fare: seat.NetFare,
        seatCodes: seat.SeatCode,
        serviceTax: seat.Servicetax,
        markup: seat.markup,
        serviceCharge: seat.OperatorServiceCharge,
      };
    });
  };

  useEffect(() => {
    let BusPaxFormData = JSON.parse(sessionStorage.getItem("BusPaxFormData"));
    let BusPassengersDetails = JSON.parse(
      sessionStorage.getItem("BusPassengersDetails")
    );

    if (BusPaxFormData && BusPassengersDetails) {
      form.setFieldsValue({
        ...BusPaxFormData,
      });
      setOnlyPassengersDetails(BusPassengersDetails);
    }
  }, []);

  return (
    <div className="buscheckout_bg">
      <div className="buscheckout_wrapper">
        <Row gutter={[16, 16]}>
          <Col md={24} sm={24} xs={24}>
            <Form form={form} layout="vertical">
              <h3 className="title">Bus Details</h3>
              <Row gutter={[16, 16]}>
                <Col md={17} sm={16} xs={24}>
                  <div className="left_wrapper">
                    <BusDetails />
                  </div>
                  <div className="paxinfo_wrapper">
                    <h3 className="title">Passenger Details</h3>
                    <Card>
                      {selectedBusData[0].seatsInfo &&
                        selectedBusData[0].seatsInfo.map(
                          (selectedSeat, index) => (
                            <div>
                              <b>Seat No:{selectedSeat.SeatNo}</b>
                              <b>{selectedBusData.length > 1 ? `, Return Seat No:${selectedBusData[1].seatsInfo[index].SeatNo}` : null}</b>
                              <Row gutter={[16, 16]}>
                                <Col md={4} sm={12} xs={24}>
                                  <Form.Item
                                    name={"Title_" + index}
                                    label="Title"
                                    rules={[
                                      { required: true, message: "Required" },
                                    ]}
                                  >
                                    <Select
 autocomplete="newpassword" 
                                      onSelect={(val) => {
                                        setOnlyPassengersDetails({
                                          ...onlyPassengersDetails,
                                          [selectedSeat.SeatNo]: {
                                            ...onlyPassengersDetails[
                                            selectedSeat.SeatNo
                                            ],
                                            title: val,
                                          },
                                        });
                                      }}
                                      className="inputbg"
                                    >
                                      <Option value="Mr">Mr</Option>
                                      <Option value="Ms">Ms</Option>
                                      <Option value="Mrs">Mrs</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col md={8} sm={12} xs={24}>
                                  <Form.Item
                                    name={"Name_" + index}
                                    label="Name"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                  >
                                    <Input
 autocomplete="newpassword" 
                                      className="inputbg"
                                      autoComplete="off"
                                      onChange={(e) =>
                                        form.setFieldsValue({
                                          [`Name_${index}`]: e.target.value.trimStart(),
                                        })
                                      }
                                      onBlur={(e) => {
                                        if (e.target.value.trim()) {
                                          setOnlyPassengersDetails({
                                            ...onlyPassengersDetails,
                                            [selectedSeat.SeatNo]: {
                                              ...onlyPassengersDetails[
                                              selectedSeat.SeatNo
                                              ],
                                              name: e.target.value.trim(),
                                            },
                                          });
                                        }
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={8} sm={12} xs={24}>
                                  <Form.Item
                                    name={"Age_" + index}
                                    label="Age"
                                    rules={[
                                      { required: true, message: "Required" },

                                      {
                                        pattern: "^(0?[1-9]|[1-9][0-9])$",
                                        message:
                                          "Age Should be between 1 to 99",
                                      },
                                    ]}
                                  >
                                    <Input
 autocomplete="newpassword" 
                                      className="inputbg"
                                      onBlur={(e) => {
                                        setOnlyPassengersDetails({
                                          ...onlyPassengersDetails,
                                          [selectedSeat.SeatNo]: {
                                            ...onlyPassengersDetails[
                                            selectedSeat.SeatNo
                                            ],
                                            age: e.target.value,
                                          },
                                        });
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={4} sm={12} xs={24}>
                                  <Form.Item
                                    name={"Gender_" + index}
                                    label="Gender"
                                    rules={[
                                      { required: true, message: "Required" },
                                    ]}
                                  >
                                    <Select
 autocomplete="newpassword" 
                                      onSelect={(val) => {
                                        setOnlyPassengersDetails({
                                          ...onlyPassengersDetails,
                                          [selectedSeat.SeatNo]: {
                                            ...onlyPassengersDetails[
                                            selectedSeat.SeatNo
                                            ],
                                            gender: val,
                                          },
                                        });
                                      }}
                                    >
                                      <Option value="M">Male</Option>
                                      <Option value="F">Female</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          )
                        )}
                    </Card>
                  </div>

                  <div className="paxcontact_wrapper">
                    <h3 className="title">Contact Details</h3>
                    <Card>
                      <Row gutter={16}>
                        <Col md={10} sm={12} xs={24}>
                          <Form.Item
                            label="Phone number"
                            name="mobileNo"
                            className="phno"
                            rules={[
                              {
                                required: true,
                                message: "Required",
                              },
                              {
                                minLength: 10,
                                maxLength: 10,
                                pattern: "^[0-9]{10}$",
                                message: "Must be 10 digits",
                              },
                            ]}
                          >
                            <Input
 autocomplete="newpassword"  addonBefore="+91" className="inputbg" />
                          </Form.Item>
                        </Col>
                        <Col md={10} sm={12} xs={24}>
                          <Form.Item
                            label="Email"
                            name="emailId"
                            rules={[
                              { required: true, message: "Required" },
                              { type: "email", message: "Invalid Email" },
                            ]}
                          >
                            <Input
 autocomplete="newpassword"  className="inputbg" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </Col>

                <Col md={7} sm={8} xs={24}>
                  <div className="right_wrapper">
                    <FareDetails
                      selectedBusData={props.gscontext.state.selectedBusData}
                    />
                  </div>
                </Col>

                <Col md={17} sm={16} xs={24}>
                  <Card>
                    <Form.Item
                      name="agreement"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject("Should accept agreement"),
                        },
                      ]}
                    >
                      <Checkbox
                      // onChange={(e) => {
                      //   setPassengersDetails({
                      //     ...passengersDetails,
                      //     termsAccepted: e.target.checked,
                      //   });
                      // }}
                      >
                        I Agree To All The{" "}
                        <a href="/termsofconditions" target="_blank">
                          {"  Terms & Conditions"}
                        </a>{" "}
                        and{" "}
                        <a href="/privacypolicy" target="_blank">
                          {" "}
                          Privacy Policy.
                        </a>
                      </Checkbox>
                    </Form.Item>

                    <div className="btn_wrapper">
                      <Button
                        type="primary"
                        onClick={() => {
                          updatePassengersData();
                        }}
                      >
                        Continue to Payment
                      </Button>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Checkout;
