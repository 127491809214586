import React, { useContext, useState, useEffect } from "react";
import { Button, Row, Card, Col, Form, message, Spin } from "antd";

import { useHistory } from "react-router-dom";
import { GlobalStatesContext } from "../../../common/providers";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import FareDetails, { getBusGrandTotal } from "../Checkout/FareDetails";
import BusDetails from "../Checkout/BusDetails";
import ApiClient from "../../../helpers/BusApiClient";
import PaymentGateway from "../../../helpers/PaymentGateway";
import { LoadingOutlined } from "@ant-design/icons";
import "../Checkout/Checkout.scss";

const BusReview = () => {
  const { user } = useAuthContext();
  const {
    state: {
      busPersist: { selectedBusData },
      busPassengerData,
      otherData: { promoData, ConvFee },
    },
  } = useContext(GlobalStatesContext);
  let history = useHistory();
  const [form] = Form.useForm();

  const [passengersData, setPassengersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (busPassengerData.length > 0) {
      busPassengerData.map((paxData) => {
        if (paxData.passengerDetails) {
          let buildDataForPassenger = paxData.passengerDetails.map((pData) => ({
            Title: pData.title,
            Age: pData.age,
            Name: pData.name,
            Gender: pData.gender,
            SeatNo: pData.seatNo,
            EmailId: busPassengerData.emailId,
            MobileNo: busPassengerData.mobileNo,
          }));
          setPassengersData([...passengersData, ...buildDataForPassenger]);
        }
      });
    } else {
      history.go(-1);
    }
  }, [busPassengerData]);

  const busBlocking = (busPassengerDataObj, type) => {
    setIsLoading(true);

    let data = {
      ...busPassengerDataObj,
      ext: {
        timeout: 60000,
      },
      // userId: user?.UserID ?? 1,
      // roleType: user?.Role?.RoleId ?? 4,
      // membership: user?.Membership ?? 1,
      // promoData: {
      //   ...promoData,
      //   tripType: type,
      // },
      // convienenceData: ConvFee,
    };
    ApiClient.post("bus/block", data)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        setIsLoading(false);
        if (resp) {
          if (resp.trips) {
            if (resp.trips.length > 0) {
              history.push(`/admin/bus-success?ref=${resp.trips[0].refNo}`);
            }
            // processPayment(resp.data.BookingReferenceNo);
          } else {
            message.error("Unable To Book Ticket: " + resp?.error?.desc??'');
          }
        } else {
          message.error("Server Error: " + resp?.error?.desc??'');
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const goTo = () => {
    form
      .validateFields()
      .then(() => {
        busBlocking(busPassengerData[0], "oneWay");
      })
      .catch((e) => {});
  };

  const [pgDisplay, setPgDisplay] = useState(false);
  const [pgData, setPgData] = useState({});

  const processPayment = (oid) => {
    let { totalFareCalculations, totalCommission } = getBusGrandTotal(
      selectedBusData,
      ConvFee,
      promoData
    );
    let commissionAmount = 0;
    if (user?.Role?.RoleId === 5) {
      commissionAmount = Number(totalCommission);
    }

    let data = {
      OrderId: oid,
      OrderAmount: parseFloat(totalFareCalculations),
      OrderCurrency: "INR",
      OrderNote: "Static Note",
      CustomerName: passengersData[0].Name,
      CustomerEmail: busPassengerData[0].emailId,
      CustomerPhone: busPassengerData[0].mobileNo,
      TDS: 0,
      GST: 0,
      Commission: commissionAmount, // for agent only
    };

    setPgData(data);

    setPgDisplay(true);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );

  return (
    <div className="buscheckout_bg">
      <div className="buscheckout_wrapper">
        <Row gutter={[16, 16]}>
          <Col md={24} sm={24} xs={24}>
            <Form form={form} layout="vertical">
              <h3 className="title">Bus Details</h3>
              <Row gutter={[16, 16]}>
                <Col md={17} sm={16} xs={24}>
                  <div className="left_wrapper">
                    <BusDetails selectedBusData={selectedBusData} />
                  </div>
                </Col>

                <Col md={7} sm={8} xs={24}>
                  <div className="right_wrapper">
                    <FareDetails
                      selectedBusData={selectedBusData}
                      isPromoVisible={true}
                    />
                  </div>
                  {pgDisplay == false ? (
                    <Card>
                      <div className="btn_wrapper">
                        <Button
                          type="primary"
                          onClick={() => {
                            goTo();
                          }}
                        >
                          {isLoading ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            "Book and Pay "
                          )}
                        </Button>
                      </div>
                    </Card>
                  ) : (
                    <PaymentGateway data={pgData} />
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default BusReview;
