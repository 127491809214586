import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Collapse,
  message,
} from "antd";

import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
import { useAuthContext } from "../../common/providers/AuthProvider";

const { Content } = Layout;
const ChangePassword = () => {
  const [form] = Form.useForm();
  const { user } = useAuthContext();
  const [activeCategory, setActiveCategory] = useState([]);
  const [visible, setVisible] = useState(false);

  const submitForm = (value) => {
    submitContactUsDetails(value);
  };

  const submitContactUsDetails = (formMapData) => {
    let Id = user?.id ?? null;
    ApiClient.put(`user/changepassword/${Id}`, {}, formMapData)
      .then((response) => {
        if (response.isSuccess) {
          form.resetFields();
          message.success("Password Changed Successfully", 3);
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Layout>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row justify="center">
            {/* <Col md={20} sm={12} xs={24}> */}
              <div className="card-bt-gap">
                <div className="card-bt-gap">
                  <Card bordered={false}>
                    {/* <div className="card-add-heading"> */}
                      <h5 className="text-center">Change Password</h5>
                      <Form layout="vertical" form={form} onFinish={submitForm}>
                        <Row gutter={12} align="middle">
                        <Col md={7} sm={12} xs={24}>
                            <Form.Item
                              label="Old Password "
                              name="oldPassword"
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <Input.Password placeholder="Enter OldPassword" />
                            </Form.Item>
                          </Col>

                          <Col md={7} sm={12} xs={24}>
                            <Form.Item
                              label="New Password "
                              name="newPassword"
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <Input.Password placeholder="Enter NewPassword" />
                            </Form.Item>
                          </Col>

                          <Col md={4} sm={12} xs={24}  >
                            <Button key="add" type="primary" htmlType="submit"  className="site-admin-btn-11">
                              Submit
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    {/* </div> */}
                  </Card>
                </div>
              </div>
            {/* </Col> */}
          </Row>
        </Content>
      </div>
    </Layout>
  );
};
export default ChangePassword;
