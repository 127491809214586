import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  message,
  Popconfirm,
  Row,
  Table,
  Select,
  InputNumber,
  Radio,
} from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import { exportExcel } from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
import SessionTimeOutModal from "../../common/SessionTimeOutModal/SessionTimeOutModal";
const { Content } = Layout;

const Operators = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [operatorList, setOperatorList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [currentId, setCurrentId] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isSessionTimeOutModel, setIsSessionTimeOutModel] = useState(false)

  useEffect(() => {
    getOperatorList();
  }, []);

  const getOperatorList = () => {
    let page = { page: 0 };
    ApiClient.post("operators/getoperator/list", page)
      .then((res) => {
        /** res will be return undefined if the API is UnAuthorize   */
        if (res == "TypeError: Failed to fetch") {
          setIsSessionTimeOutModel(true)
        }
        if (res.isSuccess) {
          let data = res.results.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setOperatorList(data);
          setTableList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const add = (val) => {
    ApiClient.post("operators/saveoperator", val)
      .then((response) => {
        return response;
      })
      .then((response) => {
        /** res will be return undefined if the API is UnAuthorize   */
        if (response == "TypeError: Failed to fetch") {
          setIsSessionTimeOutModel(true)
        }
        if (response.isSuccess) {
          setModalVisible(false);
          message.success("Successfully added operator", 3);
          getOperatorList();
          form.resetFields();
        } else if (!response.isSuccess) {
          message.error("Operators Already existed", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleModal = () => {
    if (showUpdateBtn) {
      setShowUpdateBtn(false);
      form.resetFields();
    }
    setModalVisible(true);
  };

  const deleteOperator = (Record) => {
    let Id = Record.id;
    ApiClient.delete("operators/deleteoperator/" + Id)
      .then((res) => {
        /** res will be return undefined if the API is UnAuthorize   */
        if (res == "TypeError: Failed to fetch") {
          setIsSessionTimeOutModel(true)
        }
        if (res.isSuccess) {
          message.success("Operators Deleted  successfully", 3);
          getOperatorList();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const updateOperator = (val) => {
    ApiClient.put(`operators/updateoperator/${currentId.id}`, {}, val).then(
      (resp) => {
        /** res will be return undefined if the API is UnAuthorize   */
        if (resp == "TypeError: Failed to fetch") {
          setIsSessionTimeOutModel(true)
        }
        if (resp.isSuccess) {
          getOperatorList();
          setModalVisible(false);
          message.success("Operators Updated successfully", 3);
          setCurrentId(-1);
        }
      }
    );
  };

  const submitForm = (val) => {
    let obj = {
      ...val,
    };
    if (showUpdateBtn) updateOperator(obj);
    else add(obj);
  };

  const tableActions = (currentRecord, Name) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord, Name)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure want to delete?"
            onConfirm={() => deleteOperator(Name)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const editCurrentItem = (id, data) => {
    setShowUpdateBtn(true);
    setCurrentId(id);
    form.setFieldsValue({
      ...data,
    });
    setModalVisible(true);
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };

  const searchData = () => {
    setShowSearchBox(true);
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    {
      title: "Operater",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "isRtc",
      dataIndex: "isRtc",
      render: (isRtc) => isRtc ? "True" : "False"
    },
    {
      title: "Service Type",
      dataIndex: "serviceType",
      sorter: (a, b) => a.serviceType.localeCompare(b.serviceType),
    },

    {
      title: "Actions",
      render: (id, name) => tableActions(id, name),
    },
  ];

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(operatorList);
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
    setTableList(operatorList);
  };

  const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

  const handleExcel = () => {
    // console.log("comming-1", tableList);
    let excelData = tableList;
    excelData = excelData.map((item, i) => ({
      SNo: i + 1,
      Operator: item.name,
      ServiceType: item.serviceType,
    }));

    exportExcel(excelData, "Operators");
  };

  return (
    <div className="outer-background-all">
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false} style={{ borderRadius: "25px" }}>
              <div className="card-add-heading">
                <div className="rows-count">
                  <h5>View Operators</h5>
                  <p>{tableList.length} rows found !</p>
                </div>
                <div className="action-images">
                  {showSearchBox && (
                    <Input
                      autocomplete="newpassword"
                      placeholder="Search"
                      onChange={(e) => searchTableData(e)}
                      suffix={closeSearchInput}
                      style={{ padding: "0px 12px" }}
                    />
                  )}
                  &nbsp;&nbsp;
                  <img src={search} alt="search" onClick={() => searchData()} />
                  <img src={excel} alt="excel" onClick={() => handleExcel()} />
                  <img
                    src={exchange}
                    alt="exchange"
                    onClick={() => {
                      setTableList([]);
                      getOperatorList();
                    }}
                  />
                  <p className="add-deposit-icon" onClick={handleModal}>
                    <PlusCircleFilled />
                  </p>
                </div>
              </div>

              <Table
                scroll={{ x: true }}
                bordered
                rowKey={uniqueKey}
                dataSource={tableList}
                columns={columns}
                pagination={{
                  defaultPageSize: 25,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50", "100", "125"],
                }}
              />
            </Card>
          </div>
        </Content>
      </div>
      {
        isSessionTimeOutModel ? (<SessionTimeOutModal isModelVisible={isSessionTimeOutModel} />) : null
      }
      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>
                {showUpdateBtn ? "Update Operators" : "Add Operators"}
              </strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header bci-panel-admin-parts"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <Button key="add" type="primary" onClick={form.submit}>
            {showUpdateBtn ? "Update" : "Add"}
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm}>
          <Row gutter={12}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Service Type"
                name="serviceType"
                rules={[
                  { required: true, message: "Please enter Service Type" },
                ]}
              >
                <Select placeholder="Please select Service Type">
                  <Option value="Buses">Buses</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Operator Name"
                name="name"
                rules={[
                  { required: true, message: "Please enter Operator Name" },
                ]}
              >
                <Input
                  autocomplete="newpassword"
                  placeholder="Please Enter Operator Name"
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label="RTC" name="isRtc">
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>


            <Col md={12} xs={24}>
              <Form.Item label="Active" name="isActive">
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default Operators;
