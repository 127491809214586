import {
  CloseOutlined,
  LoadingOutlined,
  PlusCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Table,
  Popconfirm,
  Upload,
} from "antd";

import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import ApiClient from "../../helpers/ApiClient";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";

import search from "../assets/vector-icons/search.png";
import ReactHtmlParser from "react-html-parser";
import "react-quill/dist/quill.snow.css";
import "../CMS/CMS.scss";
const { Option } = Select;
const { TextArea } = Input;
const { Content } = Layout;
const CMS = (props) => {
  const [form] = Form.useForm();
  const [editmode, setEditMode] = useState(false);

  const [content, setContent] = useState("");
  const [cmsList, setCMSList] = useState([]);
  const [excelList, setExcelList] = useState([]);
  const [addTitle, setTitle] = useState();

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    getCMSList();
  }, []);

  const CMS = [
    {
      id: 1,
      name: "Our Team",
    },
    {
      id: 2,
      name: "About Us",
    },
    {
      id: 3,
      name: "Testimonials",
    },
    {
      id: 4,
      name: "FAQs",
    },
    {
      id: 5,
      name: "Terms of Conditions",
    },
    {
      id: 6,
      name: "Privacy Policy",
    },
    {
      id: 7,
      name: "Disclaimer",
    },
    {
      id: 8,
      name: "Careers",
    },
    {
      id: 9,
      name: "Social Responsibility",
    },
    {
      id: 10,
      name: "Check Flight Info",
    },
    {
      id: 11,
      name: "Refund Policy",
    },
    {
      id: 12,
      name: "Investor Relations",
    },
  ];

  const getCMSList = () => {
    const CMSId = selectedValue;
    ApiClient.get("admin/cms")
      .then((response) => {
        if (response.status == 200) {
          console.log("return value:", response.data.pages);
          console.log(
            "retur description:",
            response.data.pages.map((se) => {
              return se.Description;
            })
          );
          setCMSList(response.data.pages);

          defineExcelData(response.data.pages);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const addCms = (formData) => {
    let reqObj = {
      CMSID: selectedValue,
      UserID: 0,
      CMSBannerID: 0,
      Title: addTitle,
      Description: content,
      Image: "",
    };
    ApiClient.post("admin/cms", reqObj).then((res) => {
      if (res.status == 200) {
        console.log(res);

        setModalVisible(false);
        getCMSList();
      }
      if (res.status == 400) {
        message.error("Email already exist");
      }
    });
  };
  const defineExcelData = (data) => {
    console.log("define excelllll", data);
    const filteredData = data.map((item) => {
      const obj = {
        CMSID: item.CMSID,
        UserID: item.UserID,
        CMSBannerID: item.CMSBannerID,
        Description: item.Description,
        Title: item.Title,
        Image: item.Image,
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  function exportPDF() {
    console.log("pdf successssssss");
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "CMSList";
    const headers = [
      ["CMSID", "UserID", "CMSBannerID", "Title", "Description", "Image"],
    ];
    const data = excelList.map((item) => [
      item.CMSID,
      item.UserID,
      item.CMSBannerID,
      item.Title,
      item.Description,
      item.Image,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Roles.pdf");
  }

  const getTextInput = (event) => {
    setTitle(event.target.value);
  };
  const getDescription = (content) => {
    console.log(content);
    setContent(content);
  };
  const searchTableData = (e) => {
    console.log("serchValue--", e.target.value);
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = cmsList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setCMSList(searchResults);
    } else {
      getCMSList();
    }
    console.log("serchData----------1234", cmsList);
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    lastIndex++;
    return lastIndex;
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );
  const searchData = (searchData) => {
    console.log("data111", searchData);
    setShowSearchBox(true);
  };

  handleChange = (contentChange) => {
    setContent(contentChange);
  };
  const handleChangevalue = (event) => {
    // alert(event);
    // console.log(`selected ${value}`);
    setSelectedValue(event);
  };

  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    if (editmode) {
      setEditMode(false);
      setContent("");
      setSelectedValue("");
    }
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  /********** Uploading Image **********/

  const [upLoadImage, setUpLoadImage] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  function handleChange(info) {
    if (info.file.status === "uploading") {
      setUpLoadImage(true);
      return;
    }
    if (info.file.status === "done") {
      console.log(info.file.status);
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImageUrl(imageUrl);
        setUpLoadImage(false);
      });
    }
  }
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  const showTitle = (Title) => {
    var titlrresult = Title.map((res) => {
      return res.title;
    });
    console.log(titlrresult);
    return <div>{titlrresult}</div>;
    // console.log( Title.map((res)=>{return <div><span>{res.title}</span></div>}))
  };
  const showDescription = (des) => {
    var description = des.map((res) => {
      return res.description;
    });
    return <div>{ReactHtmlParser(description)}</div>;
  };

  const uploadButton = (
    <div>
      {upLoadImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  const modules = {
    toolbar: [
      [{ font: [] }, { align: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote", "link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { color: [] },
      ],

      ["clean"],
    ],
  };
  const columns = [
    {
      title: "Page",
      dataIndex: "CMSName",
      refno: "",
      sorter: {
        compare: (a, b) => a.source - b.source,
      },
      key: "source",
    },
    {
      title: "Title",
      dataIndex: "Description",
      render: (Description) => showTitle(Description),
    },
    {
      title: "Image",
      dataIndex: "image",
    },
    {
      title: "Description",
      dataIndex: "Description",
      render: (Description) => showDescription(Description),
    },
    // {
    //   title: "Content Description",
    //   dataIndex: "prdescription",
    //   refno: "",
    //   sorter: {
    //     compare: (a, b) => a.source - b.source,
    //     // multiple: 1
    //   },
    //   // sorter: true,
    //   key: "source",
    //   // filter: this.getColumnSearchProp('source')
    // },
    // {
    //     title: 'Status',
    //     dataIndex: 'attachment',
    //     key: 'airline',
    //     render:(text,order) =>
    //     <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked />
    // },
    {
      dataIndex: "Actions",
      title: "Actions",
      render: (data, rec, all) => (
        <span className="edit-icon" onClick={() => onRecordEdit(rec)}>
          <i className="fa fa-edit"></i>
        </span>
      ),

      // render: updateformatWithIcon(),
    },
    {
      dataIndex: "Delete",
      title: "Delete",
      render: (text, record) => (
        // this.dataSource.length >= 1 ? (
        <Popconfirm title="Are u sure to delete?">
          <span className="delete-icon">
            <i className="fa fa-trash"></i>
          </span>
        </Popconfirm>
      ),
      // ) : null,
    },
  ];

  const [fileList, setFileList] = useState([]);

  const uploadonChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const onRecordEdit = (recForEdit) => {
    setEditMode(true);
    setSelectedValue(recForEdit.CMSID);
    console.log("Cms id", selectedValue);
    setContent(recForEdit.Description.map((i) => i.description).toString());
    console.log("edit", recForEdit);
    // form.setFieldsValue({
    //   page: recForEdit.CMSID,
    // });
    showModal();
  };

  const updateCms = async (formData) => {
    let updateRequestObject = {
      CMSID: selectedValue,
      Description: content,
    };
    console.log("cmsEdit", updateRequestObject);
    let response = await ApiClient.put(
      `admin/cms/${selectedValue}`,
      {},
      updateRequestObject
    );
    if (response) {
      if (response.statusCode == 200) {
        message.success("Update Cms successfully");
        getCMSList();
      } else {
        message.error("Error Updating  Cms");
      }
    } else {
      message.error("Server Error");
    }
    setModalVisible(false);
  };
  const submitForm = (formData) => {
    if (editmode) {
      updateCms(formData);
    } else {
      addCms(formData);
    }
  };
  return (
    <Layout>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            {/*  <Col span={24}>
                <div className="card-bt-gap">
                  <Card bordered={false}>
                    <div className="card-add-heading">
                      <h5>Add Page Content </h5>
                      <p className="add-deposit-icon" onClick={showModal}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </Card>
                </div>
              </Col> */}
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <h5>View Page</h5>
                      <p>{cmsList.length} rows found !</p>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
 autocomplete="newpassword" 
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData(cmsList)}
                      />
                      <img
                        src={excel}
                        alt="excel"
                        onClick={(e) => props.exportExcel(excelList, "CMS")}
                      />
                      <img src={pdf} alt="pdf" onClick={(e) => exportPDF()} />

                      <p className="add-deposit-icon" onClick={showModal}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>

                  <Table
                    bordered
                    rowKey={uniqueKey}
                    scroll={{ x: true }}
                    dataSource={cmsList}
                    columns={columns}
                    className="cms-table"
                  />
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>
      <Modal
        title="Page Content"
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button type="primary" onclick={closeModal}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" onClick={form.submit}>
              {editmode ? "Update" : "Save"}
            </Button>
          </div>,
        ]}
        width={"1100px"}
      >
        <Form name="basic" form={form} onFinish={submitForm}>
          <Row>
            <Col md={12} xs={24}>
              <Form.Item label="Page">
                <Select
 autocomplete="newpassword"  onSelect={handleChangevalue} value={selectedValue}>
                  {/* {Object.keys(Info).map(key=>(
                    <Option key={key} value={Info[key].id} >
                      {Info[key].name}
                    </Option>
                  ))} */}
                  {CMS.map((cms, i) => (
                    <Option key={i} value={cms.id}>
                      {cms.name}
                    </Option>
                  ))}
                  {/* <Option value="aboutus">About us</Option>
                  <Option value="contactus">Contact Us</Option>
                  <Option value="privacypolicy">Privacy Policy</Option> */}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={12} xs={24}>
              <Form.Item label="Title" name="title">
                <TextArea
                  value={addTitle}
                  autoSize={{ minRows: 2, maxRows: 5 }}
                  onChange={getTextInput}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col md={12} xs={24}>
              <Form.Item label="Upload">
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={uploadonChange}
                  onPreview={onPreview}
                >
                  {fileList.length < 5 && "+ Upload"}
                </Upload>
              </Form.Item>
            </Col>

            <Col md={24} xs={24}>
              <Form.Item>
                <ReactQuill
                  modules={modules}
                  placeholder="please input ..."
                  onChange={getDescription}
                  value={content}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Layout>
  );
};
export default CMS;
