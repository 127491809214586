import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
} from "antd";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";

import {
  CaretRightOutlined,
  CloseOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import moment from "moment";
import ApiClient from "../../helpers/ApiClient";
import { exportExcel } from "../ExportExcel";
const { Option } = Select;
const { Panel } = Collapse;
const { Content } = Layout;
const PGLogs = () => {
  let dateFormat = "DD-MM-YYYY";

  const [showTable, setShowTable] = useState(false);

  // const [showRequestModal, setRequestModal] = useState(false);
  const [pgLogs, setPgLogs] = useState([]);
  const [tableData, setTableData] = useState([]);

  // const [pgRequest, setPgRequest] = useState("");
  // const [pgResponse, setPgResponse] = useState("");
  const [ApiRequest, setApiRequest] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const [showAPIRequestModal, setAPIRequestModal] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const [loading, setLoading] = useState(false);

  // const PGLogsReqRes = (record) => {
  //   return (
  //     <div className="eyewrapper">
  //       <EyeOutlined
  //         onClick={(e) => {
  //           setPgRequest(record.PGRequest);
  //           setPgResponse(record.PGResponse);
  //           setRequestModal(true);
  //         }}
  //       />
  //     </div>
  //   );
  // };

  const getValueFromString = (data, ch) => {
    try {
      let json = JSON.parse(data);
      console.log("checking", { json, ch });
      return json[ch];
    } catch (e) {
      console.log(e);
      return " ";
    }
  };
  function copyToClipboard(text) {
    const elem = document.createElement("textarea");
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  }
  const disabledFutureDate = (currentDate) => {
    return currentDate >= moment();
  };
  const APIRequest = (rec) => {
    return (
      <div className="eyewrapper eyered">
        <EyeOutlined
          onClick={(e) => {
            setApiRequest(rec.ApiRequest);
            setApiResponse(rec.ApiResponse);
            setAPIRequestModal(true);
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    {
      title: "Reference Number",
      dataIndex: "ReferenceID",
      sorter: (a, b) => a.ReferenceID - b.ReferenceID,
    },
    // {
    //   title: "Pg Log",
    //   dataIndex: "ReferenceID",
    //   render: (_, r) => {
    //     return PGLogsReqRes(r);
    //   },
    // },
    {
      title: "Pg Log",
      dataIndex: "ReferenceID",
      render: (_, r) => {
        return APIRequest(r);
      },
    },
    {
      title: "Order Id",
      dataIndex: "OrderID",
      sorter: (a, b) => a.OrderID.localeCompare(b.OrderID),
    },
    {
      title: "PG Name",
      dataIndex: "pgName",
      render: (_, r) => {
        return getValueFromString(r.ApiRequest, "PgType") === 1
          ? "Cashfree"
          : "RazorPay";
      },
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      sorter: (a, b) => a.Amount.localeCompare(b.Amount),
    },
    {
      title: "Amount CRD",
      dataIndex: "Currency",
    },
    {
      title: "Payment Status",
      dataIndex: "Status",
      render: (t) => (
        <p
          style={{
            fontWeight: "600",
            color: t === "SUCCESS" ? "green" : "red",
          }}
        >
          {t}
        </p>
      ),
    },
    {
      title: "Payment Mode",
      dataIndex: "PaymentMode",
      sorter: (a, b) => a.PaymentMode.localeCompare(b.PaymentMode),
    },
    {
      title: "Transaction Id",
      dataIndex: "TransactionId",
      sorter: (a, b) => a.TransactionId.localeCompare(b.TransactionId),
    },
    {
      title: "Name",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Phone",
      dataIndex: "Phone",
      sorter: (a, b) => a.Phone.localeCompare(b.Phone),
    },
    {
      title: "Email",
      dataIndex: "Email",
      sorter: (a, b) => a.Email.localeCompare(b.Email),
    },

    {
      title: "Created Date",
      dataIndex: "CreatedDate",
      sorter: (a, b) => a.CreatedDate.localeCompare(b.CreatedDate),
    },
    {
      title: "Modified Date",
      dataIndex: "ModifiedDate",
      sorter: (a, b) => a.ModifiedDate.localeCompare(b.ModifiedDate),
    },
  ];

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableData.filter(function (item) {
        if (item !== undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableData(searchResults);
    } else {
      setTableData(pgLogs);
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
    setTableData(pgLogs);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };

  const handleExcel = () => {
    const excelData = tableData.map((item) => {
      return {
        ...item,
      };
    });

    exportExcel(excelData, "pgLogsDetails");
  };

  const handleTimeAndDate = (value) => {
    if (value === "4") {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };

  const getPGLogs = (values) => {
    setLoading(true);
    setShowTable(true);
    setPgLogs([]);

    setTableData([]);
    ApiClient.get("admin/pgReports", values)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              Status: item.Status === 1 ? "SUCCESS" : "FAILED",
              CreatedDate: moment(item.CreatedDate).format(dateFormat),
              ModifiedDate: moment(item.ModifiedDate).format(dateFormat),
            };
          });

          setPgLogs(data);
          setTableData(data);
        }
        setLoading(false);
      })
      .catch((e) => {
        setPgLogs([]);
        setTableData([]);
        setLoading(false);
        console.log("api error", e);
      });
  };

  const onFormSubmit = (values) => {
    setShowTable(false);
    // if (values.dateType === "0") {
    //   values.fromDate = moment(new Date().getTime() - 3600000).format();
    //   values.toDate = moment().format();
    // } else if (values.dateType === "1") {
    //   values.fromDate = moment().startOf("day").format();
    //   values.toDate = moment().format();
    // } else if (values.dateType === "2") {
    //   values.fromDate = moment().subtract(1, "day").startOf("day").format();
    //   values.toDate = moment().format();
    // } else if (values.dateType === "3") {
    //   values.fromDate = moment().subtract(1, "months").startOf("day").format();
    //   values.toDate = moment().format();
    // } else if (values.dateType === "4") {
    values.fromDate = moment(values.fromDate, dateFormat)
      .startOf("day")
      .format();
    values.toDate = moment(values.toDate, dateFormat).format();
    // }

    // delete values.dateType;
    getPGLogs(values);
  };
  let key = 0;
  const keyGenerate = () => {
    return key++;
  };

  return (
    <Layout>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Collapse
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <Tooltip placement="top" title={isActive ? "Show" : "Hide"}>
                      <CaretRightOutlined rotate={isActive ? 90 : -90} />
                    </Tooltip>
                  )}
                  expandIconPosition="right"
                  className="panel_wrapper"
                  defaultActiveKey={["1"]}
                >
                  <Panel header="PG Logs" key="1">
                    <Form
                      layout="vertical"
                      initialValues={{
                        // dateType: "1",
                        paymentStatus: "",
                        referenceNo: "",
                      }}
                      onFinish={onFormSubmit}
                    >
                      <Row gutter={16}>
                        {/* <Col className="gutter-row" md={6} xs={24}>
                          <Form.Item label="Time and Date" name="dateType">
                            <Select
 autocomplete="newpassword" 
                              placeholder="Please select"
                              onChange={handleTimeAndDate}
                            >
                              <Option value="0">Last 1 hour </Option>
                              <Option value="1">Today</Option>
                              <Option value="2">Yesterday</Option>
                              <Option value="3">Last 1 month</Option>
                              <Option value="4">Custom Dates</Option>
                            </Select>
                          </Form.Item>
                        </Col> */}

                        {/* {isDateDisplay ? (
                          <> */}
                        <Col className="gutter-row" md={6} xs={24}>
                          <Form.Item
                            label="From Date"
                            name="fromDate"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="Select From date"
                              format={dateFormat}
                              disabledDate={disabledFutureDate}
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={6} xs={24}>
                          <Form.Item
                            label="To Date"
                            name="toDate"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="Select To date"
                              format={dateFormat}
                              disabledDate={disabledFutureDate}
                            />
                          </Form.Item>
                        </Col>
                        {/* </>
                        ) : null} */}
                        <Col className="gutter-class" md={6} xs={24}>
                          <Form.Item
                            label="Reference Number"
                            name="referenceNo"
                          >
                            <Input
 autocomplete="newpassword"  placeholder="Enter Reference Number" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={6} xs={24}>
                          <Form.Item
                            label="Payment Status"
                            name="paymentStatus"
                          >
                            <Select
 autocomplete="newpassword"  placeholder="Please select">
                              <Option value={0}>Success</Option>
                              <Option value={1}>Fail</Option>
                              <Option value={2}>Pending</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="reports-btns">
                        <Button type="primary" htmlType="submit">
                          Search
                        </Button>
                        <Button
                          type="danger"
                          onClick={(e) => setShowTable(false)}
                        >
                          Reset
                        </Button>
                      </Row>
                    </Form>
                  </Panel>
                </Collapse>
              </div>
            </Col>
            {showTable && (
              <Col span={24}>
                <div className="card-bt-gap">
                  <Card bordered={false}>
                    {tableData.length > 0 ? (
                      <>
                        <div className="card-add-heading">
                          <div className="rows-count">
                            <h5>View PG Logs</h5>
                            <p>{tableData.length} rows found !</p>
                          </div>
                          <div className="action-images">
                            {showSearchBox && (
                              <Input
 autocomplete="newpassword" 
                                placeholder="Search"
                                onChange={(e) => searchTableData(e)}
                                suffix={closeSearchInput}
                                style={{ padding: "0px 12px" }}
                              />
                            )}
                            &nbsp;&nbsp;
                            <img
                              src={search}
                              alt="search"
                              onClick={(e) => searchData()}
                            />
                            <img
                              src={excel}
                              alt="excel"
                              onClick={handleExcel}
                            />
                          </div>
                        </div>

                        <Table
                          bordered
                          dataSource={tableData}
                          columns={columns}
                          scroll={{ x: true }}
                          pagination={{
                            defaultPageSize: 25,
                            showSizeChanger: true,
                            pageSizeOptions: ["25", "50", "100", "125"],
                          }}
                          rowKey={keyGenerate}
                        />
                      </>
                    ) : loading ? (
                      <Spin
                        size="large"
                        tip="Loading..."
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <b>No Results Found!</b>
                    )}
                  </Card>
                </div>
              </Col>
            )}
          </Row>
          {/* <Modal
            visible={showRequestModal}
            title="PG Logs"
            onCancel={(e) => {
              setRequestModal(false);
            }}
            footer={false}
          >
            <div>
              <div className="copy-btn">
                <Popconfirm
                  title="Code Copied, Click on OK"
                  onConfirm={() =>
                    copyToClipboard(
                      "PG Request: " +
                        pgRequest +
                        ", PG Response :" +
                        pgResponse
                    )
                  }
                  onCancel={false}
                  okText="Ok"
                  cancelText="No"
                >
                  <Button>Copy</Button>
                </Popconfirm>
              </div>
              <p>
                <strong>PG Request:</strong>
              </p>
              <pre>{pgRequest}</pre>
            </div>
            <div>
              <p>
                <strong>PG Response:</strong>
              </p>
              <pre>{pgResponse}</pre>
            </div>
          </Modal> */}
          <Modal
            width="900px"
            visible={showAPIRequestModal}
            title="PG LOGS"
            onCancel={(e) => {
              setAPIRequestModal(false);
            }}
            footer={false}
          >
            <div>
              <div className="copy-btn">
                <Popconfirm
                  title="Code Copied, Click on OK"
                  onConfirm={() =>
                    copyToClipboard(
                      "API Request: " +
                        ApiRequest +
                        ", API Response :" +
                        ApiResponse
                    )
                  }
                  onCancel={false}
                  okText="Ok"
                  cancelText="No"
                >
                  <Button>Copy</Button>
                </Popconfirm>
              </div>
              <h6>PG Request</h6>
              <pre>{ApiRequest} </pre>
              <br />
              <h6>PG Response</h6>
              <pre>{ApiResponse} </pre>
            </div>
          </Modal>
        </Content>
      </div>
    </Layout>
  );
};

export default PGLogs;
