import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { TgProvider } from "../src/common/providers/TgProvider";
import GlobalStatesProvider from "../src/common/providers/GlobalStatesProvider";
import { AuthProvider } from "../src/common/providers/AuthProvider";
ReactDOM.render(
  <React.StrictMode>
    <TgProvider>
      <GlobalStatesProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </GlobalStatesProvider>
    </TgProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
