import React, { useContext, useEffect, useState } from "react";
import "./FareDetails.scss";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Modal,
  Skeleton,
  message,
} from "antd";
import moment from "moment";
import GlobalStatesContext from "../../../common/providers/GlobalStatesContext";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import APIClient from "../../../helpers/ApiClient";
import img2 from "../../../assets/images/Offers/img2.jpg";
import { CloseOutlined } from "@ant-design/icons";
export const getBusGrandTotal = (selectedBusData, ConvFee, promoData) => {
  let fareInfoData =
    selectedBusData.length > 0
      ? selectedBusData.map((selectBusObj) => selectBusObj.fareInfo)
      : null;

  fareInfoData = fareInfoData.filter((i) => i != undefined);
  

  let totalBaseFare = fareInfoData.reduce(
    (acc, value) => Number(value.baseFare) + Number(acc),
    0
  );
  let totalGst = fareInfoData.reduce(
    (acc, value) => Number(value.gst) + Number(acc),
    0
  );

  let total = Number(totalBaseFare) + Number(totalGst);
  let convamount = 0;
  let discount = 0;

 // let discount = Number(promoData.Discount);

  if (promoData.DiscountType == 1) {
    discount = Number(
      (total / 100) * Number(promoData.Discount)
    );
   // return parseFloat((total / 100) * promoData.Discount);
  } else {
    discount = Number(promoData.Discount);
  //  return parseFloat(promoData.Discount);
  }

  if (ConvFee.type == 1) {
    convamount = Number(ConvFee.amount);
  } else {
    convamount = Number(
      (total / 100) * Number(ConvFee.amount ? ConvFee.amount : 0)
    );
  }
  total = total + Number(convamount);

  let totalFareCalculations = Number(total);
  totalFareCalculations = Number(
    totalFareCalculations - Number(discount)
  ).toFixed(2);

  return {
    totalBaseFare: Number(totalBaseFare).toFixed(2),
    totalGst: Number(totalGst).toFixed(2),

    discount,
    totalFareCalculations,
  };
};

const FareDetails = ({ selectedBusData, isPromoVisible }) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const [form] = Form.useForm();
  const { user } = useAuthContext();
  const globalContext = useContext(GlobalStatesContext);
  const { promoData } = globalContext.state.otherData;
  const { ConvFee } = globalContext.state.otherData;
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [promoCode, setPromoCode] = useState([]);
  let dateFormat = "YYYY-MM-DD";
  let {
    totalBaseFare,
    totalGst,
    discount,
    totalFareCalculations,
  } = getBusGrandTotal(selectedBusData, ConvFee, promoData);


  useEffect(() => {
    globalContext.AddConvFee(1);
  }, []);

  const getpromo = () => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) =>
            moment(item.ValidTill, dateFormat).isSameOrAfter(moment())
          );
          if (data.length > 0) {
            let busPromo = data.filter(
              (promo) =>
                promo.ServiceType === 1 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );

            setPromoCode(busPromo);
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setPromoCode([]);
        setIsLoading(false);
        console.error(error);
      });
  };

  const validatePromo = (value) => {
    let userID = 1;
    if (user) {
      if (user.UserID) {
        userID = user.UserID;
      }
    }
    let data = {
      ServiceType: value.ServiceType,
      FromAmount: value.FromAmount,
      ToAmount: value.ToAmount,
      promoId: value.Code,
      UserId: userID,
    };
    APIClient.post("admin/validate/promo", data)
      .then((res) => {
        if (res.status === 200) {
          handleApply(value.Code);
        }
        if (res.status === 400) {
          message.error(res.data);
          setModalVisible(false);
        }
      })
      .catch((error) => {
        setModalVisible(false);
      });
  };

  const handleApply = (code) => {
    form.setFieldsValue({
      promo: code,
    });
    setModalVisible(false);
    form.submit();
  };

  const OfferSkeleton = () => {
    return (
      <Col className="gutter-row" md={6} sm={12} xs={24}>
        <div className="coupon-card">
          <div className="images-slider">
            <div
              style={{
                backgroundColor: "#f2f2f2",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Skeleton.Image />
            </div>
          </div>
          <div className="coupon-content">
            <div className="coupon-code">
              <div className="codes">
                <Skeleton.Input style={{ width: 50 }} active size="small" />
              </div>

              <div className="copy-code">
                <Skeleton.Input style={{ width: 50 }} active size="small" />
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  };

  const OffersBox = ({ id, promo }) => {
    return (
      <Col key={id} className="gutter-row" md={6} sm={12} xs={24}>
        <div className="coupon-card">
          <div className="image-wrapper">
            <img
              src={promo.Image ? BASE + promo.Image.substring(1) : img2}
              alt="promo_img"
            />
          </div>
          <div className="coupon-content">
            <div className="coupon-code">
              <div className="codes">
                <p>{promo.Name}</p>
                <h2>{promo.Code}</h2>
              </div>

              <div className="copy-code" onClick={() => validatePromo(promo)}>
                <p>Apply</p>
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  };
  const handleFocus = () => {
    getpromo();
    setModalVisible(true);
  };

  return (
    <>
      <div className="busFareDetail_wrapper">
        <Card>
          <div className="price_wrapper">
            <p className="text">Base Fare</p>
            <p className="price">₹ {totalBaseFare ? totalBaseFare : ""}</p>
          </div>

          <div className="price_wrapper">
            <p className="text">Operator GST</p>
            <p className="price">₹ {totalGst ? totalGst : ""}</p>
          </div>

          <div className="price_wrapper">
            <p className="text">Discount</p>
            {/* <p className="price">
              ₹ {promoData.status ? Number(discount).toFixed(2) : " 0.00"}
            </p> */}
            <p className="price">
              {promoData.DiscountType == 1
                ? promoData.Discount + " %"
                : "₹ " + Number(promoData.Discount).toFixed(2)}
            </p>
          </div>

          <div className="price_wrapper">
            <p className="text">Convenience Fee</p>
            <p className="price">
              {" "}
              {ConvFee.type == 1
                ? "₹ " + Number(ConvFee.amount).toFixed(2) 
                : Number(ConvFee.amount).toFixed(2) + " %"}
            </p>
          </div>
          <div className="price_wrapper total_price">
            <p className="text">Total:</p>
            <p className="price">
              ₹ {totalFareCalculations ? totalFareCalculations : ""}
            </p>
          </div>
        </Card>
      </div>
      {!isPromoVisible ? (
        <div className="buspromo_wrapper">
          {promoData.status == false ? (
            <Card>
              <p className="name">Apply Promo</p>

              <Form
                layout="vertical"
                form={form}
                onFinish={(d) => {
                  globalContext.AddPromo(d.promo, 1);
                }}
              >
                <Row gutter={[16, 16]}>
                  <Col md={16} sm={12} xs={12}>
                    <Form.Item
                      name="promo"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Input
 autocomplete="newpassword" 
                        className="inputbg"
                        placeholder="Enter Your Promo code"
                        autoComplete="off"
                        onClick={() => handleFocus()}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={12} xs={12}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Apply
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          ) : (
            <Card className="flight-cards-details">
              <div className="promo-card-header">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="name"
                >
                  Promo Coupon{" "}
                  <CloseOutlined
                    onClick={() => {
                      globalContext.RemovePromo();
                      form.resetFields();
                    }}
                  />{" "}
                </p>
              </div>
              <div className="promo-input">
                <p>
                  <span className="applied"> {promoData.Code} </span> Promo Code
                  Applied
                </p>
              </div>
            </Card>
          )}
        </div>
      ) : null}

      <Modal
        title="Offers"
        className="offers-modal"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1000px"}
      >
        <Row gutter={[8, 16]}>
          {isLoading ? (
            <OfferSkeleton />
          ) : promoCode.length > 0 ? (
            promoCode.map((promo, i) => (
              <OffersBox key={"bus" + i} id={i + "bus"} promo={promo} />
            ))
          ) : null}
        </Row>
      </Modal>
    </>
  );
};
export default FareDetails;
