import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Layout,
  Row,
  Select,
  Spin,
  Table,
  Input,
  message,
  Modal
} from "antd";
import {
  QuestionCircleOutlined,
  EyeOutlined
} from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import "./Bookingreports.scss";
import { exportExcel } from "../ExportExcel";
import excel from "../assets/vector-icons/excel.png";

import APIClient from "../../helpers/BusApiClient";

import { Link, useHistory } from "react-router-dom";
import SessionTimeOutModal from "../../common/SessionTimeOutModal/SessionTimeOutModal";
const { Option } = Select;
const { Content } = Layout;

export const getTicketDetails = (tktNo, record) => {
  let url = "/admin/bus_ticket";
  return <Link to={url + "?refNo=" + tktNo}>{tktNo}</Link>;
};

const Bookingreports = () => {
  let history = useHistory();
  const [reportRole, setReportRole] = useState(0);
  const [form] = Form.useForm();
  const [tableList, setTableList] = useState([]);
  const [reportsList, setReportsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const { user } = useAuthContext();
  const [columns, setColumns] = useState([])
  const [logs, setLogs] = useState([])

  const presentDate = moment();
  const [arrivalDate, setArrivalDate] = useState(null);
  const [weekendDate, setWeekendDate] = useState(null);

  const [showLogsModal, setshowLogsModal] = useState(false)
  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const [isSessionTimeOutModel, setIsSessionTimeOutModel] = useState(false)

  const onChangeFromDate = (current, dateString) => {
    // Can not select days before today and toda
    setArrivalDate(dateString);
  };
  const onChangeToDate = (current, dateString) => {
    // Can not select days before today and today
    setWeekendDate(dateString);
  };
  const disabledFromDate = (current) => {
    if (weekendDate != null) {
      return current.valueOf() > (new Date(weekendDate));
    }
    // return current && current > moment().endOf('day');

    return current.valueOf() > Date.now();
  }
  const disabledToDate = (current) => {
    // Can not select days before today and today
    return current.valueOf() > Date.now() || current.valueOf() < (new Date(arrivalDate));
  };

  const handleTimeAndDate = (value) => {

    if (value === "5") {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };

  const quickHelp = (referenceNumber) => {

    history.push(`/admin/siteadmins-quickhelp?RefNo=${referenceNumber}`);

  };

  const tableActions = (currentRecord) => {

    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <QuestionCircleOutlined
            onClick={() => quickHelp(currentRecord?.referenceNumber)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
      </div>
    );
  };

  const getSupplierClientLogs = (currentRecord) => {

    let ReqObj = {
      traceId: currentRecord.traceId,
      bookingStatus: currentRecord.status ?? null,
      api: currentRecord.supplierName ?? null
    }

    APIClient.post(`bus/supplierClientLogs`, ReqObj)
      .then((res) => {
        /** res will be return undefined if the API is UnAuthorize   */
        if (res == "TypeError: Failed to fetch") {
          setIsSessionTimeOutModel(true)
        }
        if (res.length > 0) {
          setLogs(res)
        }
        setshowLogsModal(true)
      })
      .catch((error) => {
        console.error(error);
      });

  }

  const tableLogAction = (currentRecord) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EyeOutlined
            onClick={() => getSupplierClientLogs(currentRecord)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
      </div>
    );
  }

  const updateBusCancellationInfo = (Val, currentRecord) => {


    const updateInfoObj = {
      pnr: currentRecord.pnr,
      ticketId: currentRecord.ticketId,
      status: Val == "REJECTED" ? "CONFIRMED" : Val,
      ticketKey: currentRecord.ticketKey ?? null,
      busCancelledStatus: Val

    }

    ApiClient.post(`booking/updatebuscancellationinfo/${currentRecord.referenceNumber}`, updateInfoObj)
      .then((res) => {
        /** res will be return undefined if the API is UnAuthorize   */
        if (res == "TypeError: Failed to fetch") {
          setIsSessionTimeOutModel(true)
        }
        if (res.isSuccess) {

          message.success(res.message, 3);

          // let obj = {
          //   filter: {
          //     fromDate: null,
          //     toDate: null
          //   },
          //   page: 0
          // }
          // getBookingReports(obj);
          form.submit();

        } else message.error(res?.message ?? 'Falied', 3);
      })
      .catch((error) => {
        message.error(error?.message ?? 'Falied', 3);
        console.error(error);
      });
  }


  const tableBusCancellationAction = (currentRecord) => {

    return (

      currentRecord.busCancelledStatus == "BUSCANCELLED" ? (
        <Form
          layout="vertical"
          name={`form_${currentRecord.busCancelledStatus}`}
          onFinish={(val) =>
            updateBusCancellationInfo(val, currentRecord)
          }
          style={{ minWidth: "400px" }}
          autoComplete="off"
        >
          <Row gutter={4} align="middle">
            <Form.Item name="busCancelledStatus" className="cancellation-form">
              {
                currentRecord.busCancelledStatus == "BUSCANCELLED" && currentRecord.status == "BUSCANCELLED" ?
                  (<Select placeholder="Please select Service Name" onChange={(e) => updateBusCancellationInfo(e, currentRecord)}>
                    <Option value="BUSCANCELLEDANDREFUND  ">BUSCANCELLEDANDREFUND  </Option>
                  </Select>)
                  : (<Select placeholder="Please select Service Name" onChange={(e) => updateBusCancellationInfo(e, currentRecord)}>
                    <Option value="BUSCANCELLED">BUSCANCELLED</Option>
                    <Option value="BUSCANCELLEDANDREFUND">BUSCANCELLEDANDREFUND</Option>
                    <Option value="REJECTED">REJECTED</Option>
                  </Select>)
              }
            </Form.Item>

            {/* <Col md={3} xs={24}>
              <Button key="add" type="primary" htmlType="submit">
                Submit
              </Button>
            </Col> */}
          </Row>
        </Form>

      ) : (currentRecord.busCancelledStatus == "BUSCANCELLEDANDREFUND" || currentRecord.busCancelledStatus == "REJECTED" || currentRecord.busCancelledStatus == "MANUALREFUND") ? currentRecord.busCancelledStatus : currentRecord.busCancelledStatus
    );
  }

  const busColumns = [
    {
      title: "Refno",
      dataIndex: "referenceNumber",
      render: (ref, record) => getTicketDetails(ref, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",

      // render: (text) =>
      //   text.bookingDate != undefined
      //     ? moment(text.bookingDate).format("DD-MM-YYYY")
      //     : "",
      sorter: (a, b) => a.companyName?.localeCompare(b.companyName),
    },
    {
      title: "PNR",
      dataIndex: "pnr",
    },
    {
      title: "TicketID",
      dataIndex: "ticketId",
    },
    {
      title: "Route",
      //   dataIndex: "sourceName",
      // dataIndex:"destinationName",
      render: (data) => data.sourceName + "-" + data.destinationName,
    },
    {
      title: "OpName",
      dataIndex: "operatorName",
    },
    {
      title: "BkdDate",
      dataIndex: "createdOn",
      sorter: (a, b) => a.createdOn?.localeCompare(b.createdOn),
      render: (createdOn) => moment(createdOn).format("DD-MM-YYYY HH:mm")
    },
    {
      title: "JrnyDate",
      dataIndex: "journeyDate",
      sorter: (a, b) => a.journeyDate?.localeCompare(b.journeyDate),
      render: (journeyDate) => moment(journeyDate).format("DD-MM-YYYY"),
    },

    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Pax Count",
      dataIndex: "noOfPax",

    },
    {
      title: "API",
      dataIndex: "supplierName",
    },

    {
      title: "Fare",
      dataIndex: "publishedFare",
    },

    {
      title: "Base fare",
      dataIndex: "baseFare",
    },
    {
      title: "GST",
      dataIndex: "gst",
    },
    {
      title: "Tax",
      dataIndex: "serviceCharge",
    },
    {
      title: "Commission",
      dataIndex: "commission",
    },
    {
      title: "DebitedFare",
      dataIndex: "debitedFare",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
    },

    {
      title: "Actions",
      render: (rowRecords) => tableLogAction(rowRecords)
    },
    {
      title: 'BusCancellation',
      render: (rowData) => tableBusCancellationAction(rowData)
    },
  ];

  const SiteAdminBusColumn = [
    {
      title: "Refno",
      dataIndex: "referenceNumber",
      render: (ref, record) => getTicketDetails(ref, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      sorter: (a, b) => a.companyName?.localeCompare(b.companyName),
    },
    {
      title: "PNR",
      dataIndex: "pnr",
    },
    {
      title: "TicketID",
      dataIndex: "ticketId",
    },
    {
      title: "Route",
      render: (data) => data.sourceName + "-" + data.destinationName,
      // dataIndex: "sourceName",
    },
    {
      title: "OpName ",
      dataIndex: "operatorName",
    }
    ,
    {
      title: "BkdDate",
      dataIndex: "createdOn",
      render: (createdOn) => moment(createdOn).format("DD-MM-YYYY HH:mm")
    },

    {
      title: "JrnyDate",
      dataIndex: "journeyDate",
      sorter: (a, b) => a.journeyDate?.localeCompare(b.journeyDate),
      render: (journeyDate) => moment(journeyDate).format("DD-MM-YYYY"),
    },
    {
      title: "Pax Count",
      dataIndex: "noOfPax",

    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Fare",
      dataIndex: "publishedFare",
    },
    {
      title: "Base fare",
      dataIndex: "baseFare",
    },
    {
      title: "GST",
      dataIndex: "gst",
    },
    {
      title: "Tax",
      dataIndex: "serviceCharge",
    },

    {
      title: "Commission",
      dataIndex: "commission",
    },
    {
      title: "DebitedFare",
      dataIndex: "debitedFare",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
    },

    {
      title: "Actions",
      render: (rowRecords) => tableActions(rowRecords)
    }
  ]



  // const getTicketDetails = (tktNo,record) => {
  //   // let obj = {
  //   //   tktNo: tktNo,
  //   // };
  //   // APIClient.post("bus/ticket", obj)
  //   //   .then((res) => {
  //   //     if (res && res?.error) {
  //   //       message.error(res.error.desc, 3);
  //   //     } else {
  //   //       history.push(`/admin/bus_ticket?tktNo=${tktNo}`);
  //   //     }
  //   //   })
  //   //   .catch((error) => {
  //   //     message.error("Unauthorized", 3);
  //   //     console.error(error);
  //   //   });
  //   history.push(`/admin/bus_ticket?tktNo=${tktNo}`);
  // };



  const onFormSubmit = (val) => {
    let obj = {
      filter: {
        fromDate: val.fromDate ?? null,
        toDate: val.toDate ?? null,
        // userId: val.reportsOf === 0 ? user.id : val.userId,
        userId: (user.role.toUpperCase() === "ADMIN" || user.role.toUpperCase() === "BUSINESSANALYST") ? val.reportsOf === 0 ? null : val.userId : user.id,
        searchType: val.searchType ?? "2", //"Today=2"
      },
      page: 0,
    };
    if (val.refNo) obj.filter.refNo = val.refNo;
    if (val.pnrNumber) obj.filter.pnrNumber = val.pnrNumber;
    if (val.status) obj.filter.status = val.status;
    // if (!obj.filter.fromDate && !obj.filter.toDate) {
    //   delete obj.filter;
    // }
    user.role.toUpperCase() === "ADMIN" ? setColumns(busColumns) : setColumns(SiteAdminBusColumn)

    if (obj) {
      getBookingReports(obj);

    }
  };


  useEffect(() => {
    user.role.toUpperCase() === "ADMIN" ? setColumns(busColumns) : setColumns(SiteAdminBusColumn)
    let obj = {
      filter: {
        fromDate: null,
        searchType: "2",
        toDate: null
      },
      page: 0
    }

    getBookingReports(obj);


  }, [])

  const getBookingReports = (obj) => {
    setLoading(true);
    setShowTable(true);
    setReportsList([]);
    setTableList([]);
    ApiClient.post(`booking/busbookingreport`, obj)
      .then((res) => {
        /** res will be return undefined if the API is UnAuthorize   */
        if (res == "TypeError: Failed to fetch") {
          setIsSessionTimeOutModel(true)
        }
        if (res.isSuccess) {
          let data = res.results.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setReportsList(data);
          setTableList(data);
          // setTable(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const handleExcel = () => {
    let excelData = tableList;
    excelData = excelData.map((item, i) => ({
      SNo: i + 1,
      referenceNumber: item.referenceNumber,
      companyName: item.companyName,
      PNR: item.pnr,
      TicketID: item.ticketId,
      Route: `${item.sourceName}-${item.destinationName}`,
      operatorName: item.operatorName,
      paxCount: item.noOfPax,
      bookingDate: moment(item.createdOn).format("DD-MM-YYYY HH:mm"),
      journeyDate: moment(item.journeyDate).format("DD-MM-YYYY HH:mm"),
      status: item.status,
      Remarks : item.remarks,
      // sourceName: item.sourceName,
      // destinationName: item.destinationName,
      // supplierName: item.supplierName,
      publishedFare: item.publishedFare,
      BaseFare: item.baseFare,
      GST: item.gst,
      Tax: item.serviceCharge,
      Commission: item.commission,
      DebitedFare: item.debitedFare

    }));
    exportExcel(excelData, "Booking Reports");
  };

  const handelLogOkModal = () => {
    setshowLogsModal(false)
  }

  const handelLogCancelModal = () => {
    setshowLogsModal(false)
  }


  const { Panel } = Collapse;

  const onChange = (key) => {
    console.log(key);
  };

  return (
    <div className="outer-background-all">
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false} style={{ borderRadius: "25px" }}>
              <Row className="card-add-heading">
                <Col span={24}>
                  <div className="card-bt-gap">
                    <h5>Booking Reports</h5>

                    <Form
                      form={form}
                      layout={"vertical"}
                      onFinish={onFormSubmit}
                    >
                      <Row gutter={12} align="middle">
                        {(user.role.toUpperCase() === "ADMIN" || user.role.toUpperCase() === "BUSINESSANALYST") &&
                          (<Col md={5} xs={24}>
                            <Form.Item
                              label="Reports of"
                              name="reportsOf"
                            // rules={[{ required: true, message: "Required" }]}
                            >
                              <Select
                                placeholder="Select"
                                onSelect={(val) => setReportRole(val)}
                              >
                                <Option value={0}>Self</Option>
                                <Option value={5}>Specific Company</Option>
                              </Select>
                            </Form.Item>
                          </Col>)}

                        {reportRole === 5 && (
                          <Col md={5} sm={12} xs={24}>
                            <AutoCompleteSelect
                              formItemProps={{
                                label: "Company Name",
                                name: "userId",
                                rules: [
                                  {
                                    required: true,
                                    message: "Please enter User Name",
                                  },
                                ],
                              }}
                              selectProps={{
                                placeholder: "Please Enter User Name",
                              }}
                              api={"user/userautosuggestion/"}
                              keytext="name"
                              keyvalue="id"
                            />
                          </Col>
                        )}
                        <Col md={5} xs={24}>
                          <Form.Item
                            label="Search Type"
                            name="searchType"

                          >
                            <Select placeholder="select Status" onChange={handleTimeAndDate} defaultValue="2">
                              <Option value="1">Lasthour </Option>
                              <Option value="2">Today </Option>
                              <Option value="3">Yesterday </Option>
                              <Option value="4">Lastweek </Option>
                              <Option value="5">CustomDates  </Option>
                            </Select>
                          </Form.Item>

                        </Col>
                        {isDateDisplay ? (<>
                          <Col md={4} sm={12} xs={24}>
                            <Form.Item
                              label="From Date"
                              name="fromDate"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please enter From Date",
                            //   },
                            // ]}
                            >
                              <DatePicker
                                format="YYYY-MM-DD"
                                disabledDate={disabledFromDate}
                                onChange={(date, dateString) =>
                                  onChangeFromDate(date, dateString)

                                }
                                placeholder="Please Enter From Date"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={4} sm={12} xs={24}>
                            <Form.Item
                              label="To Date"
                              name="toDate"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please enter To Date",
                            //   },
                            // ]}
                            >
                              <DatePicker
                                format="YYYY-MM-DD"
                                disabledDate={disabledToDate}
                                onChange={(date, dateString) =>
                                  onChangeToDate(date, dateString)

                                }
                                placeholder="Please Enter To Date"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                        </>) : null}
                        <Col md={4} sm={12} xs={24}>
                          <Form.Item
                            label="Reference No"
                            name="refNo"
                          // rules={[
                          //   { required: true, message: "Please enter To Date" },
                          // ]}
                          >
                            <Input placeholder="Please Reference Number" />
                          </Form.Item>
                        </Col>
                        <Col md={4} sm={12} xs={24}>
                          <Form.Item
                            label="PNR No"
                            name="pnrNumber"
                          // rules={[
                          //   { required: true, message: "Please enter To Date" },
                          // ]}
                          >
                            <Input placeholder="Please Enter PNR Number" />
                          </Form.Item>
                        </Col>
                        <Col md={4} sm={12} xs={24}>
                          <Form.Item
                            label="Status"
                            name="status"
                          // rules={[
                          //   { required: true, message: "Please enter To Date" },
                          // ]}
                          >
                            <Select placeholder="Please select Status">
                              <Option value={0}>NONE </Option>
                              <Option value={1}>BLOCKED </Option>
                              <Option value={2}>CONFIRMED </Option>
                              <Option value={3}>TRAVELLED </Option>
                              <Option value={4}>CANCELLED </Option>
                              <Option value={5}>CANCELLED PARTIALLY </Option>
                              <Option value={6}>BLOCK FAILED </Option>
                              <Option value={7}>BLOCK PENDING </Option>
                              <Option value={8}>BOOK FAILED </Option>
                              <Option value={9}>BOOK PENDING </Option>
                              <Option value={10}>CANCEL FAILED </Option>
                              <Option value={11}>CANCEL PENDING </Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col >
                          <Button
                            key="add"
                            className="site-admin-btn-11"
                            type="primary"
                            onClick={form.submit}
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Col>
                {showTable ? (
                  <Col span={24}>
                    {tableList.length > 0 ? (
                      <>
                        <div className="card-add-heading">
                          <div className="rows-count">
                            <div>
                              <h5>View Booking Reports {tableList.length}</h5>
                            </div>
                          </div>
                          <div className="action-images">
                            {/* {showSearchBox && (
                                  <Input
                                    placeholder="Search"
                                    onChange={(e) => searchTableData(e)}
                                    suffix={closeSearchInput}
                                    style={{ padding: "0px 12px" }}
                                  />
                                )}
                                &nbsp;&nbsp;
                                <img
                                  src={search}
                                  alt="search"
                                  onClick={(e) => searchData()}
                                /> */}
                            <img
                              src={excel}
                              alt="excel"
                              onClick={() => handleExcel()}
                            />
                          </div>
                        </div>

                        <div className="mt-2">
                          <Table
                            scroll={{ x: true }}
                            bordered
                            dataSource={tableList}
                            // columns={busColumns}
                            columns={columns}
                            pagination={{
                              defaultPageSize: 10,
                              showSizeChanger: true,
                              pageSizeOptions: ["10", "25", "50", "100", "125"],
                            }}
                          />
                        </div>
                      </>
                    ) : loading ? (
                      <Spin
                        size="large"
                        tip="Loading..."
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <b>No Results Found!</b>
                    )}
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Card>
          </div>
        </Content>
        {
          isSessionTimeOutModel ? (<SessionTimeOutModal isModelVisible={isSessionTimeOutModel} />) : null
        }
        <Modal
          width="900px"
          visible={showLogsModal}
          title="Logs"
          className=" modal-header-bg12"
          onOk={handelLogOkModal} onCancel={handelLogCancelModal}
        >
          {logs.map(logdata => (
            <div>
              <Collapse defaultActiveKey={['']} onChange={onChange} className="collapse-css-for-border">
                <Panel header={logdata.log} key="1"  >
                  <p><b>LogType:</b> {logdata.log}</p>
                  <p><b>API / Method:</b> {logdata.api} / {logdata.svc}</p>
                  <p><b>Request:</b> {logdata.request}</p>
                  <p><b>Response:</b> {logdata.response}</p>
                </Panel>
              </Collapse>
              {/* <p><b>LogType:</b>{logdata.log}</p>
              <p><span><b>API:</b>{logdata.api}</span> <b>Method:</b>{logdata.svc}</p>
              <p><b>Request:</b>{logdata.request}</p>
              <p><b>Response:</b>{logdata.response}</p> */}
            </div>
          ))}

        </Modal>

      </div>
    </div>
  );
};

export default Bookingreports;

