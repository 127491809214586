//import Yash from './Component/Yash'; 
import queryString from "query-string";
import React, { useEffect, useState } from 'react';
import ApiClient from "../../../helpers/BusApiClient";
import "./Avilability.scss";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import moment from "moment";

function Busavilable() {


    let busInitial = {
        trips: [],
        origin: "",
        destination: "",
    };

    const [busResultsList, setBusResultsList] = useState(busInitial);
    const [isLoading, setIsLoading] = useState(true);
    const [boardingDroppingpoint, setBoardingDroppingpoint] = useState([]);
    const [originDestination, setOriginDestination] = useState({})

    const { user } = useAuthContext();


    useEffect(() => {
        getBusesAvailability();
        // RemovePromo();
    }, []);

    /* Buses Search Availability */
    const getBusesAvailability = () => {

        let param = queryString.parse(document.location.search);

        let origin = param?.sourceName;
        let destination = param?.destinationName;
        let originId = param?.sourceId;
        let destinationId = param?.destinationId;
        let formData = {
            trips: [
                {
                    src: param?.sourceId,
                    dst: param?.destinationId,
                    doj: param?.journeyDate,
                },
            ],
            ext: {
                timeout: 60000,
                filters: true,
                tags: param?.tags != "" ? param.tags.split(",") : null,
                Api: param?.Api != "" ? param.Api.split(",") : null,
            },
        };
        setOriginDestination({
            origin, destination,
            doj: param?.journeyDate,
        })
        fetchBusAvailability(
            formData,
            origin,
            destination,
            originId,
            destinationId,
            1
        );
    };

    const fetchBusAvailability = (
        formData,
        origin,
        destination,
        originId,
        destinationId,
        type
    ) => {
        setIsLoading(true);
        // updateBusSearchResultObj({});
        setBusResultsList(busInitial);

        ApiClient.post("bus/search", formData)
            .then((result) => {
                return result;
            })
            .then((resp) => {
                if (resp) {
                    if (resp?.trips && resp?.trips?.length > 0) {
                        let busType = []
                        let suppliers = []
                        let supplierCount = {}
                        let busTrips = {
                            ...resp,
                            data: {
                                // trips: resp.trips[0].buses.map((trip) => ({
                                //   ...trip,
                                //   isVisible: true,
                                // })),
                                trips: resp.trips[0].buses.map((trip) => {

                                    // trip.isVisible = true
                                    if (!busType.filter(pt => pt?.id == trip.type).length > 0) {
                                        busType.push({
                                            id: trip.type,
                                            label: trip.type,
                                            isChecked: false,
                                            count: resp?.trips[0]?.buses.filter(c => c.type == trip.type).length
                                        })
                                    }

                                    if (!suppliers.filter(sp => sp?.id == trip.ecode).length > 0) {
                                        suppliers.push({
                                            id: trip.ecode,
                                            label: trip?.ecode?.substr(trip?.ecode?.length - 4),
                                            isChecked: false,
                                            count: resp?.trips[0]?.buses.filter(c => c.ecode == trip.ecode).length
                                        })
                                    }

                                    { supplierCount[trip?.ecode?.substr(trip?.ecode?.length - 4)] = (supplierCount[trip?.ecode?.substr(trip?.ecode?.length - 4)] || 0) + 1 }
                                    return { ...trip, isVisible: true, }
                                }),
                                filters: { ...resp?.trips[0]?.filters, busType, suppliers, supplierCount },
                                origin: origin,
                                destination: destination,
                                originId: originId,
                                destinationId: destinationId,
                                traceId: resp.traceId,

                            },
                        };
                        // updateBusSearchResultObj({ ...busTrips, tripType: type });
                        setBusResultsList(busTrips.data);
                        setIsLoading(false)
                    }
                }

                // setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });


    };


    const getboardingpoint = (data) => {
        setBoardingDroppingpoint(data)
    }


    return (
        <div>



            <div class="modal fade" id="exampleModalCenter1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter1Title" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Boarding Points</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className="popup-main-box2">
                                <div className="popup-main-box4">NAME</div>
                                <div className="popup-main-box4">TIME</div>
                                <div className="popup-main-box4">PHONE</div>
                                <div className="popup-main-box4">ADDRESS</div>
                                <div className="popup-main-box4">LANDMARK</div>
                            </div>
                            {boardingDroppingpoint?.map((item, i) => (
                                <div key={i} className="popup-main-box" >
                                    <div className="popup-main-box2">
                                        <div className="popup-main-box3">{item.name}</div>
                                        <div className="popup-main-box3">{item.time}</div>
                                        <div className="popup-main-box3">{item.phone}</div>
                                        <div className="popup-main-box3">{item.address}</div>
                                        <div className="popup-main-box3">{item.landmark}</div>
                                    </div>
                                </div>

                            ))}


                        </div>

                    </div>
                </div>
            </div>

            <div class="modal fade" id="exampleModalCenter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter2Title" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Dropping Points</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className="popup-main-box2">
                                <div className="popup-main-box4">NAME</div>
                                <div className="popup-main-box4">TIME</div>
                                <div className="popup-main-box4">PHONE</div>
                                <div className="popup-main-box4">ADDRESS</div>
                                <div className="popup-main-box4">LANDMARK</div>
                            </div>
                            {boardingDroppingpoint?.map((item, i) => (
                                <div key={i} className="popup-main-box" >
                                    <div className="popup-main-box2">
                                        <div className="popup-main-box3">{item.name}</div>
                                        <div className="popup-main-box3">{item.time}</div>
                                        <div className="popup-main-box3">{item.phone}</div>
                                        <div className="popup-main-box3">{item.address}</div>
                                        <div className="popup-main-box3">{item.landmark}</div>
                                    </div>
                                </div>

                            ))}


                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="exampleModalCenter3" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter3Title" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                                Cancellation Policy</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">

                        </div>

                    </div>
                </div>
            </div>

            <div className="destination-check1">
                <div className="destination-check">FROM:
                    <h1>{originDestination.origin}</h1>
                </div>
                <div className="destination-check">TO:
                    <h1> {originDestination.destination}</h1>
                </div>
                <div className="destination-check">Date:
                    <h1>{moment(originDestination.doj).format("DD:MM:YYYY")}</h1>
                </div>

            </div>



            {isLoading ? <h2 style={{ margin: "auto", width: "7%", marginTop: "10%" }}>Loading....</h2> :


                busResultsList?.trips?.map((availability, i) => (
                    <div key={i} className='check'>

                        <div className='check1'>
                            <h1>{availability.name}</h1>
                            <h1>{availability.type}</h1>

                        </div>
                        <div className='check2'>
                            <h1>TimeD: {availability.timeD} </h1>
                            <h1>TimeA: {availability.timeA}</h1>

                        </div>
                        <div className='check3'>

                            {user.role.toUpperCase() === "ADMIN"
                                && (
                                    <span> Supplier:<b>{availability?.ecode?.substr(availability?.ecode?.length - 4)}</b></span>
                                )}


                            {/* <h1>ID: {availability.ecode}</h1> */}
                            <h1>seat: (Total Seats): {availability.seats.total}  (Avl Seats): {availability.seats.avlAll}</h1>

                        </div>
                        <div className='check4'>
                            <h1>Duration: {moment(availability.duration).format("HH:mm")} </h1>
                            <h1>Amenities: {availability.amenities}</h1>
                            {/* <h1>Rating: {availability.rating}</h1> */}
                        </div>
                        <div className='check5'>
                            <h1>Fare:  {availability.fares.map((fare, index) => (
                                <span>
                                    {index === 0 ? "₹ " : ""}
                                    {/* {fare.total} */}
                                    {fare.base}
                                    {availability.fares.length - 1 === index ? "" : " / "}
                                </span>
                            ))}</h1>
                            <button type="button" className="button-boarding" data-toggle="modal" onClick={() => getboardingpoint(availability.boarding)} data-target="#exampleModalCenter1">
                                <p className="button-color-all">Boarding/</p>
                            </button>
                            <button type="button" className="button-boarding" data-toggle="modal" onClick={() => getboardingpoint(availability.dropping)} data-target="#exampleModalCenter2">
                                <p className="button-color-all">Dropping/</p>
                            </button>
                            <button type="button" className="button-boarding" data-toggle="modal" data-target="#exampleModalCenter3">
                                <p className="button-color-all">Cancellation</p>
                            </button>
                        </div>



                    </div>
                ))}



        </div>
    )


}

export default Busavilable;