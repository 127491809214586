import React, { useState, useEffect } from "react";
import "./BusFilter.scss";
import { Card, Checkbox, Collapse, Input, Slider, Tooltip } from "antd";
const timeFilter = [
  { id: "day", start: "06:00", end: "17:59", label: "Day", isChecked: false },
  {
    id: "night",
    start: "18:00",
    end: "05:59",
    label: "Night",
    isChecked: false,
  },
];

const BusFilter = (props) => {
  const { Panel } = Collapse;
  const { busSearchResultObj, updateBusSearchResultObj } = props;

  const { trips } = busSearchResultObj.data;

  const totalCount = props.count;
  const [resultCount, setResultCount] = useState(props.count);
  const [filters, setFilters] = useState({});
  const [priceRange, setPriceRange] = useState([]);
  const [travels, setTravels] = useState([]);
  const [boardingPoints, setBoardingPoints] = useState([]);
  const [droppingPoints, setDroppingPoints] = useState([]);

  const onChange = (event, filterType, filterId) => {
    let { checked } = event.target;

    setFilters({
      ...filters,
      [filterType]: filters[filterType].map((filt) =>
        filt.id == filterId ? { ...filt, isChecked: checked } : filt
      ),
    });
    if (filterType === "travels") {
      setTravels(
        travels.map((filt) =>
          filt.id == filterId ? { ...filt, isChecked: checked } : filt
        )
      );
    }
    if (filterType === "boardingPoints") {
      setBoardingPoints(
        boardingPoints.map((filt) =>
          filt.id == filterId ? { ...filt, isChecked: checked } : filt
        )
      );
    }
    if (filterType === "droppingPoints") {
      setDroppingPoints(
        droppingPoints.map((filt) =>
          filt.id == filterId ? { ...filt, isChecked: checked } : filt
        )
      );
    }
    scrollToListTop();
  };

  const onChangeBusType = (event, filterType, filterId) => {
    let { checked } = event.target;
    let checkedList = checkedFilters("busType");
    if (checkedList.length > 1) {
      // let AC = checkedList.filter((item) => item.id === "AC");
      // let NonAc = checkedList.filter((item) => item.id === "NONA/C");
      let checkedBusType = checkedList.filter((item) => item.id === filterId);
      if (checkedBusType.length > 0 && filterId === "SEATER") {
        setFilters({
          ...filters,
          [filterType]: filters[filterType].map((filt) =>
            // filt.id === "AC" || filt.id === "SEATER"
            //   ? { ...filt, isChecked: true }
            //   : { ...filt, isChecked: false }
            filt.id == filterId ? { ...filt, isChecked: checked } : filt
          ),
        });
      }
      if (checkedBusType.length > 0 && filterId === "NONA/C") {
        setFilters({
          ...filters,
          [filterType]: filters[filterType].map((filt) =>
            // if (filt.id === "AC") return { ...filt, isChecked: false };
            // if (filt.id === "NONA/C") return { ...filt, isChecked: true };
            // else return filt;
            filt.id == filterId ? { ...filt, isChecked: checked } : filt
          ),
        });
      }

      if (checkedBusType.length > 0 && filterId === "SLEEPER") {
        setFilters({
          ...filters,
          [filterType]: filters[filterType].map((filt) =>
            // filt.id === "AC" || filt.id === "SLEEPER"
            //   ? { ...filt, isChecked: true }
            //   : { ...filt, isChecked: false }
            filt.id == filterId ? { ...filt, isChecked: checked } : filt
          ),
        });
      }
      if (checkedBusType.length > 0 && filterId === "AC") {
        setFilters({
          ...filters,
          [filterType]: filters[filterType].map((filt) =>
            // if (filt.id === "AC") return { ...filt, isChecked: true };
            // if (filt.id === "NONA/C") return { ...filt, isChecked: false };
            // else return filt;
            filt.id == filterId ? { ...filt, isChecked: checked } : filt
          ),
        });
      }
      // if (checkedBusType.length > 0 && filterId === "SEATER") {
      //   setFilters({
      //     ...filters,
      //     [filterType]: filters[filterType].map((filt) =>
      //       filt.id === "NONA/C" || filt.id === "SEATER"
      //         ? { ...filt, isChecked: true }
      //         : { ...filt, isChecked: false }
      //     ),
      //   });
      // }
      // if (NonAc.length > 0 && filterId === "SLEEPER") {
      //   setFilters({
      //     ...filters,
      //     [filterType]: filters[filterType].map((filt) =>
      //       filt.id === "NONA/C" || filt.id === "SLEEPER"
      //         ? { ...filt, isChecked: true }
      //         : { ...filt, isChecked: false }
      //     ),
      //   });
      // }
    } else {
      setFilters({
        ...filters,
        [filterType]: filters[filterType].map((filt) =>
          filt.id == filterId ? { ...filt, isChecked: checked } : filt
        ),
      });
    }

    scrollToListTop();
  };

  //Scroll to Top of the List
  const scrollToListTop = () => {
    document.getElementsByClassName("results_tab")[0].scrollIntoView({
      behavior: "smooth",
    });
  };

  const checkIfExist = (filterTypeObj, id) =>
    filterTypeObj.filter((obj) => obj["id"] === id).length === 0;

  const setDynamicFilters = () => {
    const filtersObj = {
      price: { minPrice: 0, maxPrice: 0, maxPriceRange: 0, minPriceRange: 0 },
      timings: [],
      travels: [],
      boardingPoints: [],
      droppingPoints: [],
    };

    for (let i = 0; i < trips.length; i++) {
      let val = trips[i].startingFare.split("/").sort((a, b) => {
        return Number(b) - Number(a);
      });

      let startingFare = Number(val[0]);
      if (i === 0) {
        filtersObj.price.minPrice = Number(val[val.length - 1]);
      }
      let minFare = Number(val[val.length - 1]);
      if (startingFare > filtersObj.price.maxPrice) {
        filtersObj.price.maxPrice = filtersObj.price.maxPriceRange = startingFare;
      }

      if (minFare < filtersObj.price.minPrice) {
        filtersObj.price.minPrice = minFare;
      }
      filtersObj.price.minPriceRange = filtersObj.price.minPrice;
      //Setting Timing Filter
      filtersObj.timings = timeFilter;

      //Setting Bus Types Filters
      filtersObj.busType = [
        { type: "AC", label: "AC", id: "AC", isChecked: false },
        {
          type: "Non AC",

          label: "Non AC",
          id: "NONA/C",
          isChecked: false,
        },
        { type: "Sleeper", label: "Sleeper", id: "SLEEPER", isChecked: false },
        { type: "Seater", label: "Seater", id: "SEATER", isChecked: false },
      ];
      // filtersObj.busType = filtersAPIData.busTypes.map((bType) => ({
      //   ...bType,
      //   label: bType.type,
      //   id: bType.type,
      // }));

      //Setting Travels Filters
      const { displayName } = trips[i];
      checkIfExist(filtersObj.travels, displayName) &&
        filtersObj.travels.push({
          id: displayName,
          label: displayName,
          isChecked: false,
        });

      //Setting Boarding Points Filters
      const { boardingPoints } = trips[i];
      boardingPoints.map((bPoint) => {
        checkIfExist(filtersObj.boardingPoints, bPoint.Location) &&
          filtersObj.boardingPoints.push({
            id: bPoint.Location,
            label: bPoint.Location,
            isChecked: false,
          });
      });

      //Setting Dropping Points Filters
      const { droppingPoints } = trips[i];
      droppingPoints.map((dPoint) => {
        checkIfExist(filtersObj.droppingPoints, dPoint.Location) &&
          filtersObj.droppingPoints.push({
            id: dPoint.Location,
            label: dPoint.Location,
            isChecked: false,
          });
      });
    }

    setFilters(filtersObj);
    setTravels(filtersObj.travels);
    setBoardingPoints(filtersObj.boardingPoints);
    setDroppingPoints(filtersObj.droppingPoints);
    setPriceRange([filtersObj.price.minPrice, filtersObj.price.maxPrice]);
  };

  const checkedFilters = (filterType) => {
    return filters[filterType].filter((filter) => filter.isChecked);
  };
  const checkedBusTypeFilters = (filterType) => {
    return filters[filterType]
      .filter((filter) => filter.isChecked)
      .map((item) => item.id);
  };

  const applyFilters = () => {
    let visibleCount = 0;
    let minPrice = 0, maxPrice = 0, tempCount = 0
    // console.log(trips)
    updateBusSearchResultObj({
      ...busSearchResultObj,
      data: {
        ...busSearchResultObj.data,
        trips: trips.map((trip) => {
          let isVisible = true;

          const startingFare = Number(trip.startingFare.split("/")[0]);

          if (
            !(
              startingFare >= filters.price.minPrice &&
              startingFare <= filters.price.maxPrice
            )
          ) {
            isVisible = false;
          }

          const timingsChecked = checkedFilters("timings");
          if (timingsChecked.length === 1) {
            const { departureTime } = trip,
              { start, end, id } = timingsChecked[0];
            if (
              !(id === "night"
                ? (departureTime >= start && departureTime <= "23:59") ||
                (departureTime >= "00:00" && departureTime <= end)
                : departureTime >= start && departureTime <= end)
            ) {
              isVisible = false;
            }
          }

          const busTypeChecked = checkedFilters("busType");
          const busTypeFalseChecked = checkedBusTypeFilters("busType");
          if (busTypeChecked.length === 1) {
            if (
              busTypeChecked.filter(
                (bType) => trip.busTypeFilters.indexOf(bType.id) > -1
              ).length === 0
            ) {
              isVisible = false;
            } else if (
              busTypeFalseChecked.includes("AC") &&
              !busTypeFalseChecked.includes("NONA/C") &&
              trip.busTypeFilters.includes("NONA/C")
            ) {
              isVisible = false;
            }
          } else {
            if (
              busTypeChecked.filter((bType) => bType.id === "AC").length > 0 &&
              busTypeChecked.filter((bType) => bType.id === "SEATER").length > 0
            ) {
              if (
                !trip.busTypeFilters.includes("AC") ||
                trip.busTypeFilters.includes("NONA/C") ||
                trip.busTypeFilters.includes("SLEEPER")
              ) {
                isVisible = false;
              }
            }

            if (
              busTypeChecked.filter((bType) => bType.id === "AC").length > 0 &&
              busTypeChecked.filter((bType) => bType.id === "SLEEPER").length >
              0
            ) {
              if (
                !trip.busTypeFilters.includes("AC") ||
                trip.busTypeFilters.includes("NONA/C") ||
                trip.busTypeFilters.includes("SEATER")
              ) {
                isVisible = false;
              }
            }

            if (
              busTypeChecked.filter((bType) => bType.id === "NONA/C").length >
              0 &&
              busTypeChecked.filter((bType) => bType.id === "SEATER").length > 0
            ) {
              if (
                trip.busTypeFilters.includes("AC") ||
                trip.busTypeFilters.includes("SLEEPER")
              ) {
                isVisible = false;
              }
            }

            if (
              busTypeChecked.filter((bType) => bType.id === "NONA/C").length >
              0 &&
              busTypeChecked.filter((bType) => bType.id === "SLEEPER").length >
              0
            ) {
              if (
                trip.busTypeFilters.includes("AC") ||
                trip.busTypeFilters.includes("SEATER")
              ) {
                isVisible = false;
              }
            }
          }

          const travelsChecked = checkedFilters("travels");
          if (
            travelsChecked.length &&
            !travelsChecked
              .map((travel) => travel.id)
              .includes(trip.displayName)
          ) {
            isVisible = false;
          }

          const boardingPointsChecked = checkedFilters("boardingPoints");
          if (boardingPointsChecked.length) {
            const { boardingPoints } = trip;
            if (
              !boardingPointsChecked
                .map((bPoint) => bPoint.id)
                .some((busBoardPoint) =>
                  boardingPoints
                    .map((boardP) => boardP.Location)
                    .includes(busBoardPoint)
                )
            ) {
              isVisible = false;
            }
          }

          const droppingPointsChecked = checkedFilters("droppingPoints");
          if (droppingPointsChecked.length) {
            const { droppingPoints } = trip;
            if (
              !droppingPointsChecked
                .map((dPoint) => dPoint.id)
                .some((busDropPoint) =>
                  droppingPoints
                    .map((dropP) => dropP.Location)
                    .includes(busDropPoint)
                )
            ) {
              isVisible = false;
            }
          }

          isVisible && visibleCount++;
          // if (isVisible === true) {
          //   if (tempCount == 0) {
          //     minPrice = startingFare
          //     tempCount++
          //   } else {
          //     if (minPrice > startingFare) {
          //       minPrice = startingFare
          //     }
          //   }
          // }
          return { ...trip, isVisible: isVisible };
        }),
      },
    });
  
   // setFilters({ ...filters, price: { ...filters.price, minPrice, minPriceRange: minPrice } })
   
    setResultCount(visibleCount);
  };

  const priceChangeCompleteHandler = (priceVal) => {
    setFilters({
      ...filters,
      price: { ...filters.price, minPrice: priceVal[0], maxPrice: priceVal[1] },
    });
  };

  const priceChangeHandler = (price) => {
    setPriceRange(price);
  };

  useEffect(() => {
    if (busSearchResultObj.reset) {
      setDynamicFilters();
      delete busSearchResultObj.reset;
      updateBusSearchResultObj({ ...busSearchResultObj });
    }
  }, [busSearchResultObj]);

  useEffect(() => {
    setDynamicFilters();
  }, []);

  useEffect(() => {
    Object.keys(filters).length && applyFilters();
  }, [filters]);

  const handleClear = (filterType) => {
    let initFilterType;
    if (filterType === "price") {
      initFilterType = {
        ...filters[filterType],
        minPrice: filters.price.minPriceRange,
        maxPrice: filters.price.maxPriceRange,
      };
      setPriceRange([filters.price.minPriceRange, filters.price.maxPriceRange]);
    } else {
      initFilterType = filters[filterType].map((filt) => ({
        ...filt,
        isChecked: false,
      }));
    }
    setFilters({
      ...filters,
      [filterType]: initFilterType,
    });
    if (filterType === "travels") {
      setTravels(initFilterType);
    }
    if (filterType === "boardingPoints") {
      setBoardingPoints(initFilterType);
    }
    if (filterType === "droppingPoints") {
      setDroppingPoints(initFilterType);
    }
    scrollToListTop();
  };

  const extraPanel = (filterType) => (
    <span
      onClick={(e) => {
        e.stopPropagation();
        handleClear(filterType);
      }}
    >
      Clear
    </span>
  );

  let handleSearch = (e, type) => {
    let val = e.target.value.toUpperCase();
    if (type === "BP") {
      if (val) {
        let bp = boardingPoints.filter(
          (item) => item.label.toUpperCase().indexOf(val) > -1
        );
        setBoardingPoints(bp);
      } else {
        setBoardingPoints(filters.boardingPoints);
      }
    }
    if (type === "DP") {
      if (val) {
        let dp = droppingPoints.filter(
          (item) => item.label.toUpperCase().indexOf(val) > -1
        );
        setDroppingPoints(dp);
      } else {
        setDroppingPoints(filters.droppingPoints);
      }
    }
    if (type === "Travels") {
      if (val) {
        let travel = travels.filter(
          (item) => item.label.toUpperCase().indexOf(val) > -1
        );
        setTravels(travel);
      } else {
        setTravels(filters.travels);
      }
    }
  };

  return (
    <div className="bus-filters-wrapper">
      <Card>
        <div className="bus-results">
          <p>
            Showing {resultCount} Of {totalCount} Buses
          </p>
        </div>
        <div className="bus-filter-header">
          <p className="fil-text">Filters</p>
          <p className="clear-text" onClick={setDynamicFilters}>
            Clear all
          </p>
        </div>
        <div className="collapse-wrapper">
          <div className="bus-stops-filter">
            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIconPosition={"right"}
            >
              <Panel header="Price" key="1" extra={extraPanel("price")}>
                {filters.price && (
                  <Slider
                    range={{ draggableTrack: true }}
                    step={1}
                    defaultValue={[
                      filters.price.minPrice,
                      filters.price.maxPriceRange,
                    ]}
                    min={filters.price.minPriceRange}
                    value={priceRange}
                    max={filters.price.maxPriceRange}
                    onChange={priceChangeHandler}
                    onAfterChange={priceChangeCompleteHandler}
                  />
                )}
                <div className="slider-label">
                  <span>₹ {priceRange[0]}</span>
                  <span>₹ {priceRange[1]}</span>
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
        <div className="collapse-wrapper">
          <div className="bus-timings ">
            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIconPosition={"right"}
            >
              <Panel
                header={"Bus Timings"}
                key="1"
                extra={extraPanel("timings")}
              >
                {filters.timings &&
                  filters.timings.map((time, i) => (
                    <p key={i}>
                      <Checkbox
                        checked={time.isChecked}
                        onChange={(e) => onChange(e, "timings", time.id)}
                      >
                        {time.label}
                      </Checkbox>
                    </p>
                  ))}
              </Panel>
            </Collapse>
          </div>
        </div>
        <div className="collapse-wrapper">
          <div className="bus-type ">
            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIconPosition={"right"}
            >
              <Panel header={"Bus Type"} key="1" extra={extraPanel("busType")}>
                {filters.busType &&
                  filters.busType.map((bType, i) => (
                    <p key={i}>
                      <Checkbox
                        checked={bType.isChecked}
                        onChange={(e) =>
                          onChangeBusType(e, "busType", bType.id)
                        }
                      >
                        {bType.label}
                      </Checkbox>
                    </p>
                  ))}
              </Panel>
            </Collapse>
          </div>
        </div>
        <div className="collapse-wrapper">
          <div className="bus-travels ">
            <Collapse
              className="scroll"
              bordered={false}
              expandIconPosition={"right"}
            >
              <Panel header={"Travels"} key="1" extra={extraPanel("travels")}>
                <Input
 autocomplete="newpassword" 
                  className="busfilterinput"
                  placeholder="Search"
                  size="small"
                  onChange={(e) => handleSearch(e, "Travels")}
                />
                {travels.map((travel, i) => (
                  <p key={i}>
                    <Checkbox
                      checked={travel.isChecked}
                      onChange={(e) => onChange(e, "travels", travel.id)}
                    >
                      {travel.label}
                    </Checkbox>
                  </p>
                ))}
              </Panel>
            </Collapse>
          </div>
        </div>
        <div className="collapse-wrapper">
          <div className="bus-bor-pts locations">
            <Collapse
              bordered={false}
              className="scroll"
              expandIconPosition={"right"}
            >
              <Panel
                header={"Boarding Points"}
                key="1"
                extra={extraPanel("boardingPoints")}
              >
                <Input
 autocomplete="newpassword" 
                  className="busfilterinput"
                  placeholder="Search"
                  size="small"
                  onChange={(e) => handleSearch(e, "BP")}
                />
                {boardingPoints.map((bPoint, i) => (
                  <p key={"bp" + i + bPoint.id} className="bor-point">
                    <Checkbox
                      checked={bPoint.isChecked}
                      onChange={(e) => onChange(e, "boardingPoints", bPoint.id)}
                    >
                      <Tooltip
                        overlayClassName="locTooltip"
                        placement="topLeft"
                        title={<span> {bPoint.label}</span>}
                      >
                        {bPoint.label}
                      </Tooltip>
                    </Checkbox>
                  </p>
                ))}
              </Panel>
            </Collapse>
          </div>
        </div>
        <div className="collapse-wrapper">
          <div className="bus-dro-pts locations">
            <Collapse
              bordered={false}
              className="scroll"
              expandIconPosition={"right"}
            >
              <Panel
                header={"Dropping Points"}
                key="1"
                extra={extraPanel("droppingPoints")}
              >
                <Input
 autocomplete="newpassword" 
                  className="busfilterinput"
                  placeholder="Search"
                  size="small"
                  onChange={(e) => handleSearch(e, "DP")}
                />
                {droppingPoints.map((dPoint, i) => (
                  <p key={dPoint.id + i + "-dp"} className="drop-point">
                    <Checkbox
                      checked={dPoint.isChecked}
                      onChange={(e) => onChange(e, "droppingPoints", dPoint.id)}
                    >
                      <Tooltip
                        overlayClassName="locTooltip"
                        placement="topLeft"
                        title={<span> {dPoint.label}</span>}
                      >
                        {dPoint.label}
                      </Tooltip>
                    </Checkbox>
                  </p>
                ))}
              </Panel>
            </Collapse>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default BusFilter;
