//import Yash from './Component/Yash';
import React, { useState, PureComponent, useCallback, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Cell, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, BarChart, Bar, PieChart, Pie, Sector } from 'recharts';
import './Chart.scss';

import moment from "moment";
import {
    PrinterOutlined,
} from "@ant-design/icons";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
import { useAuthContext } from "../../common/providers/AuthProvider";

import ApiClient from "../../helpers/ApiClient";
import APIClient from "../../helpers/BusApiClient";
import { Link, useHistory } from "react-router-dom";
import {
    Button,
    Progress,
    Card,
    Col,
    Collapse,
    DatePicker,
    Skeleton,
    Form,
    Layout,
    Row,
    Tabs,
    Select,
    Spin,
    Table,
    Input,
    message,
    RadioChangeEvent
} from "antd";
import SessionTimeOutModal from '../../common/SessionTimeOutModal/SessionTimeOutModal';


const data01 = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
];
const data02 = [
    { name: 'A1', value: 100 },
    { name: 'A2', value: 300 },
    { name: 'B1', value: 100 },
    { name: 'B2', value: 80 },
    { name: 'B3', value: 40 },
    { name: 'B4', value: 30 },
    { name: 'B5', value: 50 },
    { name: 'C1', value: 100 },
    { name: 'C2', value: 200 },
    { name: 'D1', value: 150 },
    { name: 'D2', value: 50 },
];


function Dashboard() {
    let history = useHistory();
    const [reportRole, setReportRole] = useState(0);
    const [tableList, setTableList] = useState([]);
    const [reportsList, setReportsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const { user } = useAuthContext();
    const [columns, setColumns] = useState([])
    const [isDateDisplay, setIsDateDisplay] = useState(false);
    const [isDateDisplayGraph, setIsDateDisplayGraph] = useState(false);
    const presentDate = moment();
    const [arrivalDate, setArrivalDate] = useState(null);
    const [weekendDate, setWeekendDate] = useState(null);
    const [busBookingSummary, setbusBookingSummary] = useState({})
    const [searchRoutes, setsearchRoutes] = useState({})
    const [busBookingSales, setbusBookingSales] = useState({})
    const [isSessionTimeOutModel, setIsSessionTimeOutModel] = useState(false)
    const { TabPane } = Tabs;


    useEffect(() => {

        let initialBusBookingSummaryObj = {
            filter: {
                searchType: "2",
                fromDate: null,
                toDate: null,
                api: null,
                userId: (user.id == 1) ? null : user.id,
                routes_Operators_Count: 10,
                isRtc: false
            },
            page: 0
        };
        let initialBusBookingSales = {
            filter: {
                searchType: "2",
                fromDate: null,
                toDate: null,
                api: null,
                userId: (user.id == 1) ? null : user.id,
                isRtc: false
            },
            page: 0
        };
        let initialSearchRoutes = {
            searchType: "2",
            fromDate: null,
            toDate: null,
            api: null,
            userId: (user.id == 1) ? null : user.id,
            svc: "Search"
        };
        gettopsearchroutes(initialSearchRoutes);
        getbusBookingSales(initialBusBookingSales);
        getbusBookingSummary(initialBusBookingSummaryObj);
        setLoading(true);
    }, [])

    const { Option } = Select;
    const [form] = Form.useForm();
    const [graphform] = Form.useForm();


    const apisOptions = [
        { name: "NONE", value: "NONE" },
        { name: "IRCT", value: "IRCT" },
        { name: "REDB", value: "REDB" },
        { name: "BTLA", value: "BTLA" },
        { name: "EZIF", value: "EZIF" },
        { name: "TRYR", value: "TRYR" },
        { name: "ETST", value: "ETST" },
        { name: "ITSG", value: "ITSG" },
        { name: "VRLT", value: "VRLT" },
        { name: "ZGBS", value: "ZGBS" },
        { name: "YLBS", value: "YLBS" },
    ]
    const onBusBookingSummarySubmit = (val) => {
        // console.log(val)
        let busBookingSummaryObj = {
            filter: {
                searchType: val.searchType ?? "2",
                fromDate: val.fromDate ?? null,
                toDate: val.toDate ?? null,
                api: (val.api == 'NONE' || val.api == undefined) ? null : val.api,
                //userId: val.userId ?? null,
                userId: user.role.toUpperCase() === "ADMIN" ? (val.userId ?? null) : user.id,
                routes_Operators_Count: 10,
                isRtc: false
            },
            page: 0

        };
        let TopSearchRoutesObj = {
            searchType: val.searchType ?? "2",
            fromDate: val.fromDate ?? null,
            toDate: val.toDate ?? null,
            api: (val.api == 'NONE' || val.api == undefined) ? null : val.api,
            // userId: val.userId ?? null,
            userId: user.role.toUpperCase() === "ADMIN" ? (val.userId ?? null) : user.id,
            svc: "Search"
        }

        gettopsearchroutes(TopSearchRoutesObj)
        //console.log("bookingchecking",busBookingSummaryObj)
        getbusBookingSummary(busBookingSummaryObj);
    };
    const getbusBookingSummary = (obj) => {

        ApiClient.post(`dashboard/busbookingsummary`, obj)
            .then((res) => {
                // console.log("checking", res.results.totalSales)
                if (res.isSuccess) {
                    setbusBookingSummary(
                        res.results
                    );
                    // console.log(res.results.topBusOperator[0].count)
                    setLoading(false)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    // Top Search Routes Api
    const gettopsearchroutes = (obj) => {
        obj.userId = obj.userId == null ? 1 : obj.userId
        APIClient.post('bus/TopSearchRoutes', obj).then((response) => {
            //console.log(response.totalSearchCount, 'count')

            // if (response == undefined) {
            //     setIsSessionTimeOutModel(true)
            // }

            /** res will be return undefined if the API is UnAuthorize   */
            if (response == "TypeError: Failed to fetch") {
                setIsSessionTimeOutModel(true)
            }
            setsearchRoutes(response)
        })
            .catch((error) => {
                // console.log("toproute", error)
                console.error(error)
            })
    }

    const handleTimeAndDatelist = (value) => {

        if (value === "5") {
            setIsDateDisplay(true);
        } else {
            setIsDateDisplay(false);
        }
    };
    // const disabledDate = (currentDate) => {
    //     //return currentDate < moment(arrivalDate).startOf("day");
    //     return (currentDate < moment(arrivalDate).startOf("day") || (currentDate > moment(weekendDate).startOf("day")))
    // };
    // const onChangeFromDate = (momentdate, _) => {

    //     setWeekendDate(moment(momentdate, "DD-MM-YYYY").add(30, 'days'));
    //     setArrivalDate(momentdate);
    // }
    const handleTimeAndDategraph = (value) => {

        if (value === "5") {
            setIsDateDisplayGraph(true);
        } else {
            setIsDateDisplayGraph(false);
        }
    };

    const onBusBookingSales = (val) => {
        // console.log("value", val)
        let busBookingSalesObj = {
            filter: {
                searchType: val.searchType ?? 4,
                fromDate: val.fromDate ?? null,
                toDate: val.toDate ?? null,
                api: (val.api == 'NONE' || val.api == undefined) ? null : val.api,
                // userId: val.userId ?? null,
                userId: user.role.toUpperCase() === "ADMIN" ? (val.userId ?? null) : user.id,
                isRtc: false
            },
            page: 0
        };
        //console.log("bookingchecking",busBookingSummaryObj)
        getbusBookingSales(busBookingSalesObj);
    };

    const getbusBookingSales = (obj) => {
        ApiClient.post(`dashboard/busbookingsales`, obj)
            .then((res) => {

                if (res.isSuccess) {
                    let data = res.results.analyticReports.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                            searchType: item.searchType,
                            BookingCount: item.bookingCount,
                            BlockingCount: item.blockingCount,
                            CancellationCount: item.cancellationCount,
                            BookingAmount: item.bookingAmount,
                            CancellationAmount: item.cancellationAmount,
                            CancellationRefundAmount: item.cancellationAmount
                        }
                    })
                    // console.log(data, 'hgg')
                    setbusBookingSales(
                        data
                    );
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    // TopBusRoutes Selection Dates 

    const onChangeFromDate = (current, dateString) => {
        // Can not select days before today and toda
        setArrivalDate(dateString);
    };
    const onChangeToDate = (current, dateString) => {
        // Can not select days before today and today
        setWeekendDate(dateString);
    };
    const disabledFromDate = (current) => {
        if (weekendDate != null) {
            return current.valueOf() > (new Date(weekendDate));
        }
        // return current && current > moment().endOf('day');

        return current.valueOf() > Date.now();
    }
    const disabledToDate = (current) => {
        // Can not select days before today and today
        return current.valueOf() > Date.now() || current.valueOf() < (new Date(arrivalDate));
    };

    //Graph Selection Dates 

    const onChangeGraphFromDate = (current, dateString) => {
        // Can not select days before today and toda
        setArrivalDate(dateString);
    };
    const onChangeGraphToDate = (current, dateString) => {
        // Can not select days before today and today
        setWeekendDate(dateString);
    };
    const disabledGraphFromDate = (current) => {
        if (weekendDate != null) {
            return current.valueOf() > (new Date(weekendDate));
        }
        // return current && current > moment().endOf('day');
        return current.valueOf() > Date.now();
    }
    const disabledGraphToDate = (current) => {
        // Can not select days before today and today
        return current.valueOf() > Date.now() || current.valueOf() < (new Date(arrivalDate));
    };


    const printTicket = () => {
        window.print();
    };

    const getTicketDetails = (tktNo, record) => {
        let url = "/admin/bus_ticket";
        return <Link to={url + "?refNo=" + tktNo}>{tktNo}</Link>;
    };

    const bookingColumns = [

        {
            title: "CompanyName",
            dataIndex: "companyName",
        },
        {
            title: "Operator Name",
            dataIndex: "operatorName",
        },
        {
            title: "Source",
            dataIndex: "sourceName",
        },
        {
            title: "Destination",
            dataIndex: "destinationName",
        },
        {
            title: "Refno",
            dataIndex: "referenceNumber",
            render: (ref, record) => getTicketDetails(ref, record),
            sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
        },
        {
            title: "Booked Date",
            dataIndex: "createdOn",
            render: (createdOn) => moment(createdOn).format("DD-MM-YYYY"),
        },
        {
            title: "Journey Date",
            dataIndex: "journeyDate",
            render: (journeyDate) => moment(journeyDate).format("DD-MM-YYYY"),
        },
        {
            title: "Status",
            dataIndex: "status",
        },
        {
            title: 'Action',
            render: (id, name) => tableActions(id, name),
        }
    ];
    const tableActions = (currentRecord, Name) => {
        return (
            <div className="edit-icon">
                {
                    user.role.toUpperCase() === "SITEADMIN" ? (
                        <a style={{ color: "skyblue" }} onClick={() => history.push('/site-admin/ConsolidateLedger')}>
                            View More...
                        </a>
                    ) :
                        (
                            <a style={{ color: "skyblue" }} onClick={() => history.push('/admin/bookingreports')}>
                                View More...
                            </a>
                        )
                }

            </div>
        );
    };
    //  return (
    //      <div>
    //          <p onClick={() => printTicket()}>
    //              <PrinterOutlined />
    //          </p>
    //      </div>
    //  );


    const TopBusRoutesColumns = [

        {
            title: "Routes",
            dataIndex: "route",
        },
        {
            title: "Source State",
            dataIndex: "sourceState",
        },
        {
            title: "Destination State",
            dataIndex: "destinationState",
        },

        {
            title: "Count",
            dataIndex: "count",
        }
    ];
    const TopBusOperatorsColumns = [

        {
            title: "Operator",
            dataIndex: "operatorName",
        },
        {
            title: "Count",
            dataIndex: "count",
        }
    ];
    const RADIAN = Math.PI / 180;
    // const renderCustomizedPieChartLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    //     console.log("pieValue",cx, cy, midAngle, innerRadius, outerRadius, percent, index)
    //     const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    //     const x = cx + radius * Math.cos(-midAngle * RADIAN);
    //     const y = cy + radius * Math.sin(-midAngle * RADIAN);

    //     return (
    //         <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
    //             {`${(percent * 100).toFixed(0)}%`}
    //         </text>
    //     );
    // };

    const [activeIndex, setActiveIndex] = useState(0)
    const [activeTopusRoutesIndex, setActiveTopusRoutesIndex] = useState(0)

    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );
    const onTopRoutesPieEnter = useCallback(
        (_, index) => {
            setActiveTopusRoutesIndex(index);
        },
        [setActiveTopusRoutesIndex]
    );
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.operatorName}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Count ${value}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {`(Rate ${(percent * 100).toFixed(2)}%)`}
                </text>
            </g>
        );
    };

    const renderTopRoutesActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.route}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Count ${value}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {`(Rate ${(percent * 100).toFixed(2)}%)`}
                </text>
            </g>
        );
    };



    return (
        <div className='full-body'>

            <div className='nav-dashboard' >
                <div className='nav-dashboard1'>
                    <div className='nav-dashboard2'>
                        <p><strong className='for-lign-after'>Dashboard</strong></p>
                    </div>
                    <div className='nav-dashboard3'>
                        <p className='for-arrow-sign'><i style={{ color: "red", marginRight: "4%", fontSize: "25px" }} class="fa fa-home"></i><span className='span-arrow'></span>Dashboard</p>
                    </div>
                    <div className='nav-dashboard4'>
                        <p style={{ marginTop: "3%", color: "#737373" }}>Analytics</p>
                    </div>
                </div>
            </div>
            <div className='form-for-dashboard-main-box'>
                <div className='form-for-dashboard1'>
                </div>
                <div className='form-for-dashboard'>
                    <Form
                        form={form}
                        layout={"vertical"}
                        onFinish={onBusBookingSummarySubmit}
                    >
                        <Row gutter={7} align="right" >

                            {user?.role?.toUpperCase() === "ADMIN"
                                && (
                                    <Col md={7} sm={12} xs={24}  >
                                        <AutoCompleteSelect
                                            formItemProps={{
                                                // label: " ",
                                                name: "userId",
                                                // rules: [
                                                //     {
                                                //         required: true,
                                                //         message: "Please enter User Name",
                                                //     },
                                                // ],
                                            }}
                                            selectProps={{
                                                placeholder: "User Name",
                                            }}
                                            api={"user/userautosuggestion/"}
                                            keytext="name"
                                            keyvalue="id"
                                        />
                                    </Col>
                                )}
                            {user.role.toUpperCase() === "ADMIN"
                                && (
                                    <Col md={7} sm={12} xs={24}>
                                        <Form.Item name="api"
                                        //label=" "
                                        >
                                            <Select placeholder="Service Name">
                                                {apisOptions.map(item => (<Option value={item.value}>{item.name}</Option>))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                )}
                            <Col md={6} sm={12} xs={24} >
                                <Form.Item
                                    // label=" "
                                    name="searchType"
                                // rules={[
                                //   { required: true, message: "Please enter To Date" },
                                // ]}
                                >
                                    <Select placeholder="select Status" onChange={handleTimeAndDatelist} defaultValue="2">
                                        <Option value="1">Lasthour </Option>
                                        <Option value="2">Today </Option>
                                        <Option value="3">Yesterday </Option>
                                        <Option value="4">Lastweek </Option>
                                        <Option value="5">CustomDates  </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {isDateDisplay ? (<>
                                <Col md={6} sm={12} xs={24}>
                                    <Form.Item
                                        // label=" "
                                        name="fromDate"
                                    >
                                        {/* <DatePicker
                                            format="DD-MM-YYYY"
                                            placeholder="Please Enter From Date"

                                            //  disabledDate={disaleFromDate}
                                            onChange={(date, dateString) =>
                                                onChangeFromDate(date, dateString)
                                            }
                                            style={{ width: "100%" }}
                                        /> */}
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            placeholder="From Date"
                                            disabledDate={disabledFromDate}
                                            onChange={(date, dateString) =>
                                                onChangeFromDate(date, dateString)

                                            }
                                            style={{ width: "100%" }}

                                        />
                                    </Form.Item>
                                </Col>
                                <Col md={6} sm={12} xs={24}>
                                    <Form.Item
                                        name="toDate"

                                    >
                                        {/* <DatePicker
                                            format="DD-MM-YYYY"
                                            disabledDate={disaleToDate}
                                            placeholder="Please Enter To Date"
                                            style={{ width: "100%" }}
                                        /> */}
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            placeholder="To Date"
                                            disabledDate={disabledToDate}
                                            onChange={(date, dateString) =>
                                                onChangeToDate(date, dateString)

                                            }
                                            style={{ width: "100%" }}


                                        />
                                    </Form.Item>
                                </Col>
                            </>) : null}
                            <Col md={4} sm={12} xs={24}  >
                                <Button
                                    key="add"
                                    className="site-admin-btn-11"
                                    type="primary"
                                    onClick={form.submit}
                                >
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                </div>
            </div>
            <div className='info-outer-main-box'>
                <div className='info-main-box'>
                    <div className='info-main-box1'>
                        <div className='info-main-box2'>
                            <div className='info-main-box-img'>
                                <i class="fa fa-line-chart" style={{ fontSize: "30px", color: "purple" }}></i>
                            </div>
                            {/* <img className='image-css' src={graph1}></img> */}
                        </div>
                        <div className='info-main-box3'>
                            <p>Day Sales</p>
                            {loading ? <Skeleton.Button /> :
                                <h3>{busBookingSummary.daySales}</h3>
                            }
                        </div>
                    </div>
                    <div className='info-main-box1'>
                        <div className='info-main-box2'>
                            <div className='info-main-box-img'>
                                <i class="fa fa-area-chart" style={{ fontSize: "30px", color: "orange" }}></i>

                            </div>
                            {/* <img className='image-css' src={graph1}></img> */}
                        </div>
                        <div className='info-main-box3'>
                            <p>Yesterday Sales </p>
                            {loading ? <Skeleton.Button /> :
                                <h3>{busBookingSummary.yseterdaySales}</h3>
                            }
                        </div>
                    </div>
                    <div className='info-main-box1'>
                        <div className='info-main-box2'>
                            <div className='info-main-box-img'>
                                <i class="fa fa-pie-chart" style={{ fontSize: "30px", color: "skyblue" }}></i>

                            </div>
                            {/* <img className='image-css' src={graph1}></img> */}
                        </div>
                        <div className='info-main-box3'>
                            <p>Week Sales</p>
                            {loading ? <Skeleton.Button /> :
                                <h3>{busBookingSummary.weekSales}</h3>
                            }
                        </div>
                    </div>
                </div>
                <div className='info-main-box'>
                    <div className='info-main-box1'>
                        <div className='info-main-box2'>
                            <div className='info-main-box-img'>
                                <i class="fa fa-bar-chart" style={{ fontSize: "30px", color: "green" }}></i>
                            </div>
                            {/* <img className='image-css' src={graph2}></img> */}
                        </div>
                        <div className='info-main-box3'>
                            <p>Month Sales</p>
                            {loading ? <Skeleton.Button /> :
                                <h3>{busBookingSummary.monthSales}</h3>
                            }
                        </div>
                    </div>
                    <div className='info-main-box1'>
                        <div className='info-main-box2'>
                            <div className='info-main-box-img'>
                                <i class="fa fa-line-chart" style={{ fontSize: "30px", color: "blue" }}></i>

                            </div>
                            {/* <img className='image-css' src={graph3}></img> */}
                        </div>
                        <div className='info-main-box3'>
                            <p>Quarterly Sales</p>
                            {loading ? <Skeleton.Button /> :
                                <h3>{busBookingSummary.quarterlySales}</h3>
                            }
                        </div>
                    </div>
                    <div className='info-main-box1'>
                        <div className='info-main-box2'>
                            <div className='info-main-box-img'>
                                <i class="fa fa-pie-chart" style={{ fontSize: "30px", color: "red" }}></i>
                            </div>
                            {/* <img className='image-css' src={graph4}></img> */}
                        </div>
                        <div className='info-main-box3'>
                            <p>Total Sales</p>
                            {loading ? <Skeleton.Button /> :
                                <h3>{busBookingSummary.totalSales}</h3>
                            }
                        </div>
                    </div>
                </div>
            </div>


            <div className='info-outer-main-box1'>
                <div className='info-main-box'>
                    {user.role.toUpperCase() === "ADMIN"
                        ? (
                            <div className='info-main-box1'>
                                <div className='info-main-box2'>
                                    <div className='info-main-box-img'>
                                        <i class="fa fa-pie-chart" style={{ fontSize: "30px", color: "red" }}></i>
                                    </div>
                                    {/* <img className='image-css' src={graph4}></img> */}
                                </div>
                                <div className='info-main-box3'>
                                    <p>Ticket Amt / ProfitorLoss</p>
                                    {loading ? <Skeleton.Button /> :
                                        <h3>{busBookingSummary.ticketAmount} / {busBookingSummary.profitOrLoss}</h3>
                                    }
                                </div>
                            </div>
                        ) : (<div className='info-main-box1'>
                            <div className='info-main-box2'>
                                <div className='info-main-box-img'>
                                    <i class="fa fa-pie-chart" style={{ fontSize: "30px", color: "red" }}></i>
                                </div>
                                {/* <img className='image-css' src={graph4}></img> */}
                            </div>
                            <div className='info-main-box3'>
                                <p> TicketAmount </p>
                                {loading ? <Skeleton.Button /> :
                                    <h3>{busBookingSummary.ticketAmount}</h3>
                                }
                            </div>
                        </div>)}

                    <div className='info-main-box1'>
                        <div className='info-main-box2'>
                            <div className='info-main-box-img'>
                                <i class="fa fa-line-chart" style={{ fontSize: "30px", color: "purple" }}></i>
                            </div>
                            {/* <img className='image-css' src={graph1}></img> */}
                        </div>
                        <div className='info-main-box3'>
                            <p>OPGST/TDS</p>
                            {loading ? <Skeleton.Button /> :
                                <h3>{busBookingSummary.opgst} / {busBookingSummary.tds}</h3>
                            }
                        </div>
                    </div>

                    <div className='info-main-box1'>
                        <div className='info-main-box2'>
                            <div className='info-main-box-img'>
                                <i class="fa fa-line-chart" style={{ fontSize: "30px", color: "blue" }}></i>

                            </div>
                            {/* <img className='image-css' src={graph3}></img> */}
                        </div>
                        <div className='info-main-box3'>
                            <p>GST / Commission</p>
                            {loading ? <Skeleton.Button /> :
                                <h3>{busBookingSummary.gst} / {busBookingSummary.commission}</h3>
                            }
                        </div>
                    </div>
                </div>
                <div className='info-main-box'>
                    <div className='info-main-box1'>
                        <div className='info-main-box2'>
                            <div className='info-main-box-img'>
                                <i class="fa fa-bar-chart" style={{ fontSize: "30px", color: "green" }}></i>
                            </div>
                            {/* <img className='image-css' src={graph2}></img> */}
                        </div>
                        <div className='info-main-box3'>
                            <p>Service Charge / ServiceTaxONCC</p>
                            {loading ? <Skeleton.Button /> :
                                <h3>{busBookingSummary.serviceCharge} / {busBookingSummary.serviceTaxOnCC}</h3>
                            }
                        </div>
                    </div>
                    <div className='info-main-box1'>
                        <div className='info-main-box2'>
                            <div className='info-main-box-img'>
                                <i class="fa fa-pie-chart" style={{ fontSize: "30px", color: "skyblue" }}></i>

                            </div>
                            {/* <img className='image-css' src={graph1}></img> */}
                        </div>
                        <div className='info-main-box3'>
                            <p>CC Charge / Refund Amount</p>
                            {loading ? <Skeleton.Button /> :
                                <h3>{busBookingSummary.cancellationCharges} / {busBookingSummary.refundAmount}</h3>
                            }
                        </div>
                    </div>

                    <div className='info-main-box1'>
                        <div className='info-main-box2'>
                            <div className='info-main-box-img'>
                                <i class="fa fa-area-chart" style={{ fontSize: "30px", color: "orange" }}></i>

                            </div>
                            {/* <img className='image-css' src={graph1}></img> */}
                        </div>
                        <div className='info-main-box3'>
                            <p>RefundGST / RefundTDS  </p>
                            {loading ? <Skeleton.Button /> :
                                <h3>{busBookingSummary.refundGST} / {busBookingSummary.refundTDS}</h3>
                            }
                        </div>
                    </div>

                </div>
            </div>





            <div className='info-outer-main-box1'>
                <div className='info-main-box'>
                    <div className='info-main-box1'>

                        <div className='info-main-box2'>
                            <div className='info-main-box-img'>
                                <i class="fa fa-pie-chart" style={{ fontSize: "30px", color: "red" }}></i>
                            </div>
                            {/* <img className='image-css' src={graph1}></img> */}
                        </div>

                        <div className='info-main-box3'>
                            <p>Look To Book Ratio</p>
                            {loading ? <Skeleton.Button /> :
                                <h3>{busBookingSummary.bookingCount} / {searchRoutes?.totalSearchCount}</h3>
                            }
                            <Progress percent={busBookingSummary.bookingCount} status='active' showInfo={false} className="analytic-line1" />
                            <p className='for-font-looksize' style={{ color: "red" }} >Booked Tickets & Total Search Count</p>
                        </div>





                    </div>
                    <div className='info-main-box1'>
                        <div className='info-main-box2'>
                            <div className='info-main-box-img'>
                                <i class="fa fa-line-chart" style={{ fontSize: "30px", color: "blue" }}></i>
                            </div>
                            {/* <img className='image-css' src={graph1}></img> */}
                        </div>
                        <div className='info-main-box3'>
                            <p>Total Seats Booked Count </p>
                            {loading ? <Skeleton.Button /> :
                                <h3>{busBookingSummary.bookingCount} / {busBookingSummary.seatCount}</h3>
                            }
                            <Progress percent={busBookingSummary.bookingCount} status='active' showInfo={false} className="analytic-line2" />
                            <p className='for-font-looksize' style={{ color: "blue" }} >Booked Ticket & Seat Count</p>
                        </div>
                    </div>
                    <div className='info-main-box1'>
                        <div className='info-main-box2'>
                            <div className='info-main-box-img'>
                                <i class="fa fa-bar-chart" style={{ fontSize: "30px", color: "green" }}></i>
                            </div>
                            {/* <img className='image-css' src={graph1}></img> */}
                        </div>
                        <div className='info-main-box3'>
                            <p>Cancelled Seats Count</p>
                            {loading ? <Skeleton.Button /> :
                                <h3>{busBookingSummary.cancellationCount} / {busBookingSummary.cancelledSeatCount}</h3>
                            }
                            <Progress percent={busBookingSummary.cancellationCount} status='active' showInfo={false} className="analytic-line3" />
                            <p className='for-font-looksize' style={{ color: "green" }} >Cancellation Tickets & Seat Count</p>
                        </div>
                    </div>
                </div>
                <div className='info-main-box'>
                    <div className='info-main-box1'>
                        <div className='info-main-box2'>
                            <div className='info-main-box-img'>
                                <i class="fa fa-area-chart" style={{ fontSize: "30px", color: "orange" }}></i>
                            </div>
                            {/* <img className='image-css' src={graph2}></img> */}
                        </div>
                        <div className='info-main-box3'>
                            <p>Partial Cancelled Seats Count</p>
                            {loading ? <Skeleton.Button /> :
                                <h3>{busBookingSummary?.PartialCancelledCount ?? 0} / {busBookingSummary.partialCancelledSeatCount}</h3>
                            }
                            <Progress percent={busBookingSummary?.PartialCancelledCount ?? 0} status='active' showInfo={false} className="analytic-line4" />
                            <p className='for-font-looksize' style={{ color: "orange" }} >Partial Cancelled Tickets & Seat Count</p>
                        </div>
                    </div>
                    <div className='info-main-box1'>
                        <div className='info-main-box2'>
                            <div className='info-main-box-img'>
                                <i class="fa fa-pie-chart" style={{ fontSize: "30px", color: "skyblue" }}></i>
                            </div>
                            {/* <img className='image-css' src={graph3}></img> */}
                        </div>
                        <div className='info-main-box3'>
                            <p>Refunded Seats Count</p>
                            {loading ? <Skeleton.Button /> :
                                <h3>{busBookingSummary.refundSeatCount} / {busBookingSummary.refundTicketCount}</h3>
                            }
                            <Progress percent={busBookingSummary.refundSeatCount} status='active' showInfo={false} className="analytic-line5" />
                            <p className='for-font-looksize' style={{ color: "skyblue" }} >Refund Tickets & Seat Count</p>
                        </div>
                    </div>
                    <div className='info-main-box1'>
                        <div className='info-main-box2'>
                            <div className='info-main-box-img'>
                                <i class="fa fa-line-chart" style={{ fontSize: "30px", color: "purple" }}></i>
                            </div>
                            {/* <img className='image-css' src={graph4}></img> */}
                        </div>
                        <div className='info-main-box3'>
                            <p>Pending Refunded Seats </p>
                            {loading ? <Skeleton.Button /> :
                                // <h3>{Number(busBookingSummary.cancellationCount) - Number(busBookingSummary?.PartialCancelledCount ?? 0)} / {Number(busBookingSummary.cancelledSeatCount) - Number(busBookingSummary.partialCancelledSeatCount)}</h3>
                                <h3>{busBookingSummary.pendingRefundSeatCount} / {busBookingSummary.pendingRefundSeatCount}</h3>

                            }
                            <Progress percent={busBookingSummary.pendingRefundSeatCount} status='active' showInfo={false} className="analytic-line6" />
                            <p className='for-font-looksize' style={{ color: "purple" }} >Pending Refunded Tickets & Seat Count</p>
                        </div>
                    </div>
                </div>
            </div>





            <div className='info-main-box4'>
                <div className='test-1-graph1'>

                    <Tabs defaultActiveKey="1" className='main-tab-buttons'>
                        <TabPane
                            tab={
                                <i class="fa fa-table" style={{ fontSize: "24px" }}></i>
                            }
                            key="1"
                        >
                            <h1 className='set-header-css'><strong>Top Bus Routes</strong></h1>
                            <div className='test-1-graph2'>
                                {loading ? <Skeleton.Button /> :
                                    <Table pagination={false} scroll={{ x: false }} className='table-form-css' columns={TopBusRoutesColumns} dataSource={busBookingSummary.topBusRoutes} />
                                }
                                <Button onClick={() => history.push('/admin/toproutes')} style={{ color: 'white', backgroundColor: "#f0802d", marginLeft: 'auto' }}> View More</Button>
                            </div>
                        </TabPane>
                        <TabPane
                            tab={
                                <i class="fa fa-pie-chart" style={{ fontSize: "24px" }}></i>
                            }
                            key="2"
                        >
                            <h1 className='set-header-css'><strong>Top Bus Routes</strong></h1>
                            <div className='test-1-graph2'>
                                {/* <ResponsiveContainer width="100%" height="100%" aspect={2}  >
                                    <LineChart data={busBookingSummary.topBusRoutes} width={500} height={300} >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        {/* <XAxis dataKey="name" interval={'preserveStartEnd'} tickFormatter={(value) => value + " Programming"} /> */}
                                <XAxis dataKey="route" />
                                <YAxis />
                                <Tooltip contentStyle={{ backgroundColor: '#e8e8e9' }} />
                                <Legend />
                                <Line type="monotone" dataKey="count" stroke="red" activeDot={{ r: 8 }} />

                                {/* </LineChart>
                                </ResponsiveContainer> */}
                                <ResponsiveContainer width="100%" height="100%" aspect={2}>
                                    <PieChart className="pie-chart-box" style={{ height: "none" }} >
                                        {/* <Pie data={busBookingSummary.topBusOperator} dataKey="count" cx="50%" cy="50%" outerRadius={90} fill="#8884d8" label={renderCustomizedPieChartLabel} />
                                        <Legend />
                                        <Tooltip /> */}
                                        <Pie
                                            activeIndex={activeTopusRoutesIndex}
                                            activeShape={renderTopRoutesActiveShape}
                                            data={busBookingSummary.topBusRoutes}
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={80}
                                            outerRadius={100}
                                            paddingAngle={5}
                                            fill="#8884d8"
                                            dataKey="count"
                                            onMouseEnter={onTopRoutesPieEnter}

                                        >
                                            {busBookingSummary?.topBusRoutes?.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>

                                <Button onClick={() => history.push('/admin/toproutes')} style={{ color: 'white', backgroundColor: "#f0802d", marginLeft: 'auto' }}> View More</Button>
                            </div>

                        </TabPane>
                    </Tabs>
                </div>





                <div className='test-1-graph1'>
                    <Tabs defaultActiveKey="1" className='main-tab-buttons'>
                        <TabPane
                            tab={
                                <i class="fa fa-table" style={{ fontSize: "24px" }}></i>
                            }
                            key="1"
                        >
                            <h1 className='set-header-css'><strong>Top Bus Operators</strong></h1>
                            <div className='test-1-graph2'>
                                {loading ? <Skeleton.Button /> :
                                    <Table pagination={false} scroll={{ x: false }} className='table-form-css' columns={TopBusOperatorsColumns} dataSource={busBookingSummary.topBusOperator} />
                                }
                                <Button onClick={() => history.push('/admin/topbusoperators')} style={{ color: 'white', backgroundColor: "#f0802d", marginLeft: 'auto' }} > View More</Button>
                            </div>
                        </TabPane>
                        <TabPane
                            tab={
                                <i class="fa fa-pie-chart" style={{ fontSize: "24px", }}></i>
                            }
                            key="2"
                        >
                            <h1 className='set-header-css'><strong>Top Bus Operators</strong></h1>

                            <div className='test-1-graph2'>
                                {/* <ResponsiveContainer width="100%" height="100%" aspect={2}  >
                                    <LineChart data={busBookingSummary.topBusOperator} width={500} height={300} >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="operatorName" />
                                        <YAxis />
                                        <Tooltip contentStyle={{ backgroundColor: '#e8e8e9' }} />
                                        <Legend />
                                        <Line type="monotone" dataKey="count" stroke="red" activeDot={{ r: 8 }} />
                                    </LineChart>
                                </ResponsiveContainer> */}



                                <ResponsiveContainer width="100%" height="100%" aspect={2}>
                                    <PieChart className="pie-chart-box" style={{ height: "none" }} >
                                        {/* <Pie data={busBookingSummary.topBusOperator} dataKey="count" cx="50%" cy="50%" outerRadius={90} fill="#8884d8" label={renderCustomizedPieChartLabel} />
                                        <Legend />
                                        <Tooltip /> */}
                                        <Pie
                                            activeIndex={activeIndex}
                                            activeShape={renderActiveShape}
                                            data={busBookingSummary.topBusOperator}
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={80}
                                            outerRadius={100}
                                            fill="#8884d8"
                                            dataKey="count"
                                            onMouseEnter={onPieEnter}
                                        >
                                            {busBookingSummary?.topBusOperator?.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>

                                <Button onClick={() => history.push('/admin/topbusoperators')} style={{ color: 'white', backgroundColor: "#f0802d", marginLeft: 'auto' }}> View More</Button>
                            </div>

                        </TabPane>
                    </Tabs>
                </div>
            </div>



            <div className='form-for-dashboard-main-box'>
                <div className='form-for-dashboard1'>
                </div>
                <div className='form-for-dashboard'>
                    <Form
                        form={graphform}
                        layout={"vertical"}
                        onFinish={onBusBookingSales}
                    >
                        <Row gutter={7} align="right" >
                            {user.role.toUpperCase() === "ADMIN"
                                && (
                                    <Col md={7} sm={12} xs={24}  >
                                        <AutoCompleteSelect
                                            formItemProps={{
                                                // label: " ",
                                                name: "userId",
                                                // rules: [
                                                //     {
                                                //         required: true,
                                                //         message: "Please enter User Name",
                                                //     },
                                                // ],
                                            }}
                                            selectProps={{
                                                placeholder: "User Name",
                                            }}
                                            api={"user/userautosuggestion/"}
                                            keytext="name"
                                            keyvalue="id"
                                        />
                                    </Col>
                                )}
                            {user.role.toUpperCase() === "ADMIN"
                                && (
                                    <Col md={7} sm={12} xs={24}>
                                        <Form.Item name="api"
                                        //label=" "
                                        >
                                            <Select placeholder="Service Name">
                                                {apisOptions.map(item => (<Option value={item.value}>{item.name}</Option>))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                )}
                            <Col md={6} sm={12} xs={24} >
                                <Form.Item
                                    // label=" "
                                    name="searchType"
                                // rules={[
                                //   { required: true, message: "Please enter To Date" },
                                // ]}
                                >
                                    <Select placeholder="select Status" onChange={handleTimeAndDategraph} defaultValue="2">
                                        <Option value="1">Lasthour </Option>
                                        <Option value="2">Today </Option>
                                        <Option value="3">Yesterday </Option>
                                        <Option value="4">Lastweek </Option>
                                        <Option value="5">CustomDates  </Option>

                                    </Select>
                                </Form.Item>
                            </Col>
                            {isDateDisplayGraph ? (<>
                                <Col md={6} sm={12} xs={24}>
                                    <Form.Item
                                        // label=" "
                                        name="fromDate"
                                    >
                                        {/* <DatePicker
                                            format="DD-MM-YYYY"
                                            placeholder="Please Enter From Date"

                                            //  disabledDate={disaleFromDate}
                                            onChange={(date, dateString) =>
                                                onChangeFromDate(date, dateString)
                                            }
                                            style={{ width: "100%" }}
                                        /> */}
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            placeholder="From Date"
                                            // disabledDate={disabledDate}
                                            disabledDate={disabledGraphFromDate}
                                            onChange={(date, dateString) =>
                                                onChangeGraphFromDate(date, dateString)

                                            }
                                            style={{ width: "100%" }}

                                        />
                                    </Form.Item>
                                </Col>
                                <Col md={6} sm={12} xs={24}>
                                    <Form.Item
                                        name="toDate"

                                    >
                                        {/* <DatePicker
                                            format="DD-MM-YYYY"
                                            disabledDate={disaleToDate}
                                            placeholder="Please Enter To Date"
                                            style={{ width: "100%" }}
                                        /> */}
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            placeholder="To Date"
                                            // disabledDate={disabledDate}
                                            disabledDate={disabledGraphToDate}
                                            onChange={(date, dateString) =>
                                                onChangeGraphToDate(date, dateString)

                                            }
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>
                            </>) : null}
                            <Col md={4} sm={12} xs={24}  >
                                <Button
                                    key="add"
                                    className="site-admin-btn-11"
                                    type="primary"
                                    onClick={graphform.submit}
                                >
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                </div>
            </div>
            <div className='info-main-box41'>
                <div className='test-1-graph'>

                    <h1 style={{ color: "black", textAlign: "center" }}><strong>Sales Revenue</strong></h1>
                    <ResponsiveContainer width="100%" height="100%" aspect={2}>
                        <BarChart
                            width={500}
                            height={300}
                            data={busBookingSales}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="searchName" interval={0} angle={30} dx={-10} />
                            <YAxis />
                            <Tooltip contentStyle={{ backgroundColor: '#e8e8e9' }} />
                            <Legend />
                            {/* <Bar dataKey="BookingCount" fill="red" /> */}
                            <Bar dataKey="BookingAmount" fill="green" />
                            {/* <Bar dataKey="CancellationCount" fill="blue" /> */}
                            <Bar dataKey="CancellationAmount" fill="purple" />
                            <Bar dataKey="cancellationRefundAmount" fill="red" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>


                <div className='test-1-graph'>
                    <h1 style={{ color: "black", textAlign: "center" }}><strong>Sales Volume</strong></h1>
                    <ResponsiveContainer width="100%" height="100%" aspect={2} >
                        <LineChart width={200} height={100} data={busBookingSales}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="searchName" interval={0} angle={30} dx={-10} />
                            <YAxis />
                            <Tooltip contentStyle={{ backgroundColor: '#e8e8e9' }} />
                            <Legend />
                            <Line type="monotone" dataKey="BookingCount" stroke="#8884d8" activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="CancellationCount" stroke="#82ca9d" />
                            <Line type="monotone" dataKey="cancelledSeatCount" stroke="red" />
                        </LineChart>
                    </ResponsiveContainer>





                </div>


                <div className='test-1-graph2'>
                    <h1><strong>Recent Bookings</strong></h1>
                    <Table pagination={false} scroll={{ x: false }} className='table-form-css' columns={bookingColumns} dataSource={busBookingSummary.reports} />
                </div>




            </div>
            {
                isSessionTimeOutModel ? (<SessionTimeOutModal isModelVisible={isSessionTimeOutModel} />) : null
            }

            <div>
                {/* Dear ${Name} <br /><br />

                Thank you for using Outc Tour Service, Please be informed that your booking under ReferenceNo: ${ReferenceNo} is currently on a PENDING status and is yet to be confirmed. we request you to wait for the booking confirmation status from our customer support team .<br /><br />
                <p>TourName&nbsp;:&nbsp;&nbsp;&nbsp;</p>  ${TourName}<br />
                <p>Name &nbsp;:&nbsp;&nbsp;&nbsp;</p>  ${Name}<br /> 
                <p> Email &nbsp;:&nbsp;&nbsp;&nbsp;</p>  ${Email}<br />
                <p> PhoneNo &nbsp;:&nbsp;&nbsp;&nbsp;</p>  ${PhoneNo}<br />
                <p> BookingStatus &nbsp;:&nbsp;&nbsp;&nbsp;</p>  ${BookingStatus}<br />
                <p> Day &nbsp;:&nbsp;&nbsp;&nbsp;</p>  ${Day}<br />
                <p> Night &nbsp;:&nbsp;&nbsp;&nbsp;</p>  ${Night}<br />
                <p> TotalPrice &nbsp;:&nbsp;&nbsp;&nbsp;</p>  ${TotalPrice}<br />
                <p> PaidPrice &nbsp;:&nbsp;&nbsp;&nbsp;</p>  ${PaidPrice}<br />
                <p> UserId &nbsp;:&nbsp;&nbsp;&nbsp;</p>  ${UserId}<br /> */}

                {/*                 
                <table style={{width:"70%"}}>
                    <tr>
                        <th style={{border:"2px solid black", padding:"5px", color:"orange"}}>TourName:</th>
                        <td style={{border:"2px solid black", padding:"5px"}}>${TourName}</td>
                    </tr>
                    <tr>
                        <th style={{border:"2px solid black", padding:"5px", color:"orange"}}>Name:</th>
                        <td style={{border:"2px solid black", padding:"5px"}}>${Name}</td>
                    </tr>
                    <tr>
                        <th style={{border:"2px solid black", padding:"5px", color:"orange"}}>Email:</th>
                        <td style={{border:"2px solid black", padding:"5px"}}>${Email}</td>
                    </tr>
                    <tr>
                        <th style={{border:"2px solid black", padding:"5px", color:"orange"}}>PhoneNo:</th>
                        <td style={{border:"2px solid black", padding:"5px"}}>${PhoneNo}</td>
                    </tr>
                    <tr>
                        <th style={{border:"2px solid black", padding:"5px", color:"orange"}}>BookingStatus:</th>
                        <td style={{border:"2px solid black", padding:"5px"}}>${BookingStatus}</td>
                    </tr>
                    <tr>
                        <th style={{border:"2px solid black", padding:"5px", color:"orange"}}>Day:</th>
                        <td style={{border:"2px solid black", padding:"5px"}}>${Day}</td>
                    </tr>
                    <tr>
                        <th style={{border:"2px solid black", padding:"5px", color:"orange"}}>Night:</th>
                        <td style={{border:"2px solid black", padding:"5px"}}>${Night}</td>
                    </tr>
                    <tr>
                        <th style={{border:"2px solid black", padding:"5px", color:"orange"}}>TotalPrice:</th>
                        <td style={{border:"2px solid black", padding:"5px"}}>${TotalPrice}</td>
                    </tr>
                    <tr>
                        <th style={{border:"2px solid black", padding:"5px", color:"orange"}}>PaidPrice:</th>
                        <td style={{border:"2px solid black", padding:"5px"}}>${PaidPrice}</td>
                    </tr>
                    <tr>
                        <th style={{border:"2px solid black", padding:"5px", color:"orange"}}>UserId:</th>
                        <td style={{border:"2px solid black", padding:"5px"}}>${UserId}</td>
                    </tr>
                </table> */}
            </div>



        </div >
    )
}
export default Dashboard;