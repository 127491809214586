// import React from "react";
// import CmsPage from "../CmsPage/CmsPage";
// import "./Termsofconditions.scss";

// const Termsofconditions = () => {
//   return <CmsPage id={5} />;
// };

// export default Termsofconditions;



import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import "./Termsofconditions.scss";
// import { Link } from "react-router-dom";
import { CaretRightOutlined } from "@ant-design/icons";
import CmsPage from "../CmsPage/CmsPage";
import APIClient from "../../helpers/ApiClient";
import NavBarStatic from "../../common/navbar/NavSatic";

const Termsofconditions = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;

  const { Panel } = ANTD.Collapse;

  const [CMSList, setCMSList] = useState([]);
  // useEffect(() => {
  //   const getCMSList = () => {
  //     const CMSId = 11;
  //     APIClient.get("admin/cms/" + `${CMSId}`)
  //       .then((response) => {
  //         if (response.status == 200) {
  //           // console.log(
  //           //   "retur description:",
  //           //   response.data.pages.map((se) => {
  //           //     return se.Description;
  //           //   })
  //           // );
  //           setCMSList(response.data);
  //           // setDescription(response.data.pages.map(se=>{return se.Description}))
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };
  //   getCMSList();
  // }, []);


  return (
    <div className="collapseCustomWhite">
      <NavBarStatic />
      <div className="termspolicy-new">
        <ANTD.Layout className="ourteam-bg">

        </ANTD.Layout>
      </div>
      <div className="terms-container">
        <div className="hdr-trms">
          <h1>Terms and Condition</h1>
        </div>
        <div className="terms-data">
          {/* <Section title="Important: Adhere to State Guidelines">
          <p>Most states have released their own guidelines for inbound and outbound travellers vis-a-vis passes, permits, quarantine rules, and other requirements. Please go through the guidelines of your source and destination state carefully before planning your travel, for a hassle-free experience.</p>
          <a href="#passenger-guidelines">View Passenger Guidelines</a>
        </Section> */}


          {/* <h4 style={{ color: "#f0802d", fontWeight: "600" }}>Role of eTravos</h4>
          <p>eTravos only provides a technology platform that connects intending travelers with bus operators. It doesn’t operate any bus or offer the service of transportation to the User. eTravos also doesn’t act as an agent of any bus operator in the process of providing the above-mentioned technology platform services.</p>
          <p>The bus ticket booking voucher which eTravos issues to a User is solely based on the information provided or updated by the bus operator regarding the seat availability.</p>
          <p>The amenities, services, routes, fares, schedule, bus type, seat availability and any other details pertaining to the bus service are provided by the respective bus operator and eTravos has no control over such information provided by the bus operator.</p> */}

          <div className="trms-policy">
            <p style={{fontSize:"16px"}}>These Terms and Conditions, along with privacy policy or other terms  constitute a binding
              agreement by and between eTravos.</p>
            <p style={{fontSize:"16px"}}>By using our website and availing the Services, you agree that you have read and accepted these Terms. We reserve the right to modify these Terms at any time and without
              assigning any reason. It is your responsibility to periodically review these Terms to stay informed of
              updates.
            </p>
            <p>
              <span style={{ color: "#f0802d", fontWeight: "600" , fontSize:"18px"}}>The use of this website or availing of our Services is subject to the following terms of use:</span>

              <ol>
                <li>To access and use the Services, you agree to provide true, accurate and complete information to us
                  during and after registration, and you shall be responsible for all acts done through the use of your
                  registered account.</li>
                <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness,
                  performance, completeness or suitability of the information and materials offered on this website
                  or through the Services, for any specific purpose. You acknowledge that such information and
                  materials may contain inaccuracies or errors and we expressly exclude liability for any such
                  inaccuracies or errors to the fullest extent permitted by law.</li>
                <li>
                  Your use of our Services and the websiteis solely at your own risk and discretion.. You are
                  required to independently assess and ensure that the Services meet your requirements.
                </li>
                <li>
                  The contents of the Website and the Services are proprietary to Us and you will not have any
                  authority to claim any intellectual property rights, title, or interest in its contents.
                </li>
                <li>
                  You acknowledge that unauthorized use of the Website or the Services may lead to action against
                  you as per these Terms or applicable laws.

                </li>
                <li>
                  You agree to pay us the charges associated with availing the Services.</li>
                <li>You agree not to use the website and/ or Services for any purpose that is unlawful, illegal or
                  forbidden by these Terms, or Indian or local laws that might apply to you.</li>
                <li>You agree and acknowledge that website and the Services may contain links to other third party
                  websites. On accessing these links, you will be governed by the terms of use, privacy policy and
                  such other policies of such third party websites.</li>
                <li>You understand that upon initiating a transaction for availing the Services you are entering into a
                  legally binding and enforceable contract with the us for the Services.</li>
                <li>You shall be entitled to claim a refund of the payment made by you in case we are not able to
                  provide the Service. The timelines for such return and refund will be according to the specific
                  Service you have availed or within the time period provided in our policies (as applicable). In case
                  you do not raise a refund claim within the stipulated time, than this would make you ineligible for
                  a refund.</li>
                <li>Notwithstanding anything contained in these Terms, the parties shall not be liable for any failure to
                  perform an obligation under these Terms if performance is prevented or delayed by a force majeure
                  event.</li>
                <li>These Terms and any dispute or claim relating to it, or its enforceability, shall be governed by and
                  construed in accordance with the laws of India.</li>
                <li>All disputes arising out of or in connection with these Terms shall be subject to the exclusive
                  jurisdiction of the courts in Hyderabad, Andhra Pradesh
                  All concerns or communications relating to these Terms must be communicated to us using the
                  contact information provided on this website.</li>
              </ol>
            </p>
          </div>





        </div>
        {/* Add other sections (Ryde, redRail) similarly */}
      </div>


    </div>
  );
};

export default Termsofconditions;
