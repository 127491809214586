import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Collapse,
  message,
  Table,
  Modal,
  Popconfirm,
} from "antd";

import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons";

import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
import { useAuthContext } from "../../common/providers/AuthProvider";
import queryString from "query-string";
import { GlobalStatesContext } from "../../common/providers";

const { Content } = Layout;
const QuickHelp = () => {
  const [form] = Form.useForm();
  const [adminAnswerForm] = Form.useForm();
  const [activeCategory, setActiveCategory] = useState([]);
  const [visible, setVisible] = useState(false);
  const [QueryList, setQueryList] = useState([]);
  const [DetailedQueryList, setDetailedQueryList] = useState([]);
  const [submitQueryList, setsubmitQueryList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [updateDetails, setUpdateDetails] = useState({})
  const [currentId, setCurrentId] = useState(-1);
  const [modalVisible, setModalVisible] = useState(false);
  const [isQqueryLoading, setIsQueryLoading] = useState(false)
  const [columns, setColumns] = useState([])
  const [refno, setrefno] = useState([])
  const [isQueryLoaded, setIsQueryLoaded] = useState(false)

  const {
    state: { busTicketData },
    updateBusTicketData,
  } = useContext(GlobalStatesContext);


  const ticketSearchParams = queryString.parse(window.location.search);

  const { Option } = Select;

  const { user } = useAuthContext();
  // useEffect(() => {
  //   // user.role.toUpperCase() === "ADMIN" ? setColumns(busColumns) : setColumns(SiteAdminBusColumn)
  //   let obj = {
  //     filter: {
  //       fromDate: null,
  //       toDate: null
  //     },
  //     page: 0
  //   }

  //   getBookingReports(obj)

  // }, [])

  // const getBookingReports = (obj) => {
  //   ApiClient.post(`booking/busbookingreport`, obj)
  //     .then((res) => {

  //       if (res?.isSuccess === true) {
  //         setrefno(res.results.map(ref => ref.referenceNumber))
  //       }

  //     })
  // }

  const categoryChange = (key) => {
    setActiveCategory(categories[key].options);
  };
  const validateMessages = {
    required: "",
  };
  const categories = {
    Balance_Related: {
      title: "Balance Related  ",
      options: [
        "Money Debited But Showing Failed Transaction",
        " Update my wallet balance ",
      ],
    },
    Bus_Booking_Related: {
      title: "Bus Booking Related",
      options: [
        "Booking failed but wallet money reduced",
        " Unable to block a seat",
        "Showing tentative failed error while booking",
      ],
    },
    Bus_Cancellations: {
      title: "Bus Cancellations",
      options: [
        "Bus Cancelled, please refund",
        "Bus cancellation -Initiate ticket",
        "Report A Bus Cancellation",
      ],
    },
    Refunds_Related: {
      title: "Refunds Related",
      options: [
        "Refunds on Cancellation Pending",
        "Refund not issued for Bus Cancellation",
      ],
    },
    Commission_Related: {
      title: "Commission Related",
      options: ["Commission Not Credited", "Commission Issue on Statement"],
    },
    Cancellation_Related: {
      title: "Cancellation Related",
      options: ["Ticket is not cancelling, Help here"],
    },
    Bus_Operator_Service_Related: {
      title: "Bus Operator Service Related",
      options: [
        "Passenger Denied Boarding",
        "Different Seat Allocated To Passenger",
        "Service Issues Faced During Journey",
        "Late pickup / Late Drop",
        "Bus Quality",
        "Reopen bus cancellation",
        "Cancellation Policy Mismatch After Booking",
        "Different Seat/Bus Allocated To Passenger",
        "AC Not working/Poor quality/Staff behaviour/Amenities not provided",
        "Change in Boarding Point",
        "No response from BO,Bus missed",
        "Bus break down/Met with accident",
        "Others",
      ],
    },
    Recharge: {
      title: "Recharge",
      options: [
        "Amount debited but not recharged",
        "Error while recharging",
        "Others",
      ],
    },
  };
  const submitForm = (value) => {
    let formObj = {
      queryId: value.queryId,
      detailedQueryId: value.detailedQueryId,
      refNo: value.refNo,
      description: value?.description ?? '',
      adminAnswer: value?.adminAnswer ?? '',
    }
    //submitContactUsDetails(formObj);
    checkReferenceNo(formObj)
  };

  const checkReferenceNo = (formObj) => {

    ApiClient.get("booking/busbookingdetails/" + formObj.refNo)
      .then((res) => {
        if (res?.data && res?.data?.referenceNumber == formObj.refNo) {
          submitContactUsDetails(formObj);
        } else message.error("Please Provide valid Reference Number")
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const updatebuscancellation = (refNo) => {

    let updateObj = {
      pnr: busTicketData.pnr,
      ticketId: busTicketData.ticketId,
      status: busTicketData.status ?? "NONE",
      ticketKey: busTicketData.ticketKey,
      busCancelledStatus: "BUSCANCELLED ",

    }

    ApiClient.post(`booking/updatebuscancellation/${refNo}`, updateObj)
      .then((response) => {
        if (response.isSuccess) {
          // setVisible(true);
          // form.resetFields();
          // message.success(response.message, 3);
          // getSubmitQueryList()
        } else if (response.status == 404) {
          message.error(response?.message ?? 'Falied', 3);
        } else {
          message.error(response?.message ?? "Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const submitContactUsDetails = (formMapData) => {
    ApiClient.post("query/savesubmitquery", formMapData)
      .then((response) => {
        if (response.isSuccess) {
          setVisible(true);
          form.resetFields();
          message.success(response.message, 3);
          if (ticketSearchParams.isBusCancellationReq || formMapData.queryId == 24 || formMapData.queryId == 6) {
            updatebuscancellation(formMapData.refNo)
          }
          getSubmitQueryList()
        } else if (response.status == 404) {
          message.error(response?.message ?? 'Falied', 3);
        } else {
          message.error(response?.message ?? "Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };



  const editCurrentItem = (id, val) => {
    setModalVisible(true);
    setCurrentId(id);
    let currentObj = {
      id: val.id,
      queryName: val?.queryName ?? '',
      detailedQueryName: val?.detailedQueryName ?? '',
      refNo: val.refNo ?? '',
      description: val?.description ?? '',
      adminAnswer: val?.adminAnswer ?? '',
      adminAnswerDateTime: val?.adminAnswerDateTime ?? ''
    }

    // form.setFieldsValue({
    //   detailedQueryName: val.detailedQueryName,
    //   detailedQueryDescription: val.detailedQueryDescription,
    //   queryId: val.queryId,
    //   detailedQueryId: val.detailedQueryId

    // });

    setUpdateDetails(currentObj)
  };

  const tableActions = (currentRecord, Name) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord, Name)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure want to delete?"
            onConfirm={() => deleteSubmitQuery(Name)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };


  useEffect(() => {
    user.role.toUpperCase() === "ADMIN" ? setColumns(Admincolumns) : setColumns(Agentcolumns)
    // getDetailedQueryList();
    getQueryList();
    getSubmitQueryList()

  }, [])

  useEffect(() => {

    if (ticketSearchParams.isBusCancellationReq) {
      getQueryForBusCancellation()
    }

    form.setFieldsValue({
      refNo: ticketSearchParams.RefNo,
    })

  }, [ticketSearchParams.RefNo, ticketSearchParams.isBusCancellationReq]);

  const getQueryList = () => {
    setIsQueryLoaded(false)
    ApiClient.get("query/getquery")
      .then((res) => {
        if (res.isSuccess) {
          setQueryList(res.data);
          setIsQueryLoaded(true)
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const getDetailedQueryList = (queryId) => {
    ApiClient.get("query/getdetailedquery")
      .then((res) => {
        console.log(res)
        if (res.isSuccess) {
          let data = res.data.filter((dq) => dq.query.id == queryId)
          setDetailedQueryList(data);
          //setDetailedQueryList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const deleteSubmitQuery = (Record) => {
    let Id = Record.id;
    ApiClient.delete("query/deletesubmitquery/" + Id)
      .then((res) => {
        if (res.isSuccess) {
          message.success(res?.message ?? "DetailedQuery Deleted  successfully", 3);
          getSubmitQueryList();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getSubmitQueryList = () => {
    ApiClient.get("query/getsubmitquery")
      .then((res) => {
        if (res.isSuccess) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              queryName: item?.query?.queryName ?? '',
              queryId: item?.query?.id ?? 0,
              detailedQueryName: item?.detailedQuery?.detailedQueryName ?? '',
              detailedQueryId: item?.detailedQuery?.id ?? 0,
            };
          });

          let filterData = user.role.toUpperCase() === "ADMIN" ? data : data.filter(u => u.createdBy == user.id)
          console.log("user.id", user.id, filterData)
          setsubmitQueryList(filterData);
          setTableList(filterData);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateSubmitQuery = (val, updateDetails) => {
    let updateObj = {
      // queryId: 0,
      // detailedQueryId: 0,
      refNo: updateDetails.refNo,
      //description: "string",
      adminAnswer: val.adminAnswer,
      // adminAnswerDateTime: "2022-04-30T10:34:58.109Z"
    }
    ApiClient.put(`query/updatesubmitquery/${updateDetails.id}`, {}, updateObj).then(
      (resp) => {
        if (resp.isSuccess) {
          getSubmitQueryList();
          setModalVisible(false);
          message.success("DetailedQuery Updated successfully", 3);
          setCurrentId(-1);
        }
      }
    );
  };

  const RefundStatus = (status) => {
    let refundStatus = "NONE"
    switch (status) {
      case 0: refundStatus = "NONE"
        break;
      case 1: refundStatus = "REFUNDINITIATED"
        break;
      case 2: refundStatus = "REFUNDPROCESSED"
        break;
      case 3: refundStatus = "REFUNDREJECTED"
        break;
      default: refundStatus = "NONE"
    }
    return refundStatus
  }

  const Admincolumns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },

    {
      title: "ReferenceNo",
      dataIndex: "refNo",
      sorter: (a, b) => a?.refNo?.localeCompare(b?.refNo),
    },
    {
      title: "Agent",
      dataIndex: "createdByDetails",
      sorter: (a, b) => a.createdByDetails.localeCompare(b.createdByDetails),
    },

    {
      title: "RefundStatus",
      dataIndex: "refundStatus",

      render: (id) => RefundStatus(id),
    },


    {
      title: "Query",
      dataIndex: "queryName",
      sorter: (a, b) => a.queryName.localeCompare(b.queryName),
      ellipsis: true,
    },

    {
      title: "DetailedQuery",
      dataIndex: "detailedQueryName",
      sorter: (a, b) => a.detailedQueryName.localeCompare(b.detailedQueryName),
      ellipsis: true,
    },

    {
      title: "Remarks",
      dataIndex: "description",
      sorter: (a, b) => a?.description?.localeCompare(b?.description),
      ellipsis: true,
    },

    {
      title: "AdminAnswer",
      dataIndex: "adminAnswer",
      sorter: (a, b) => a.adminAnswer.localeCompare(b.adminAnswer),
      ellipsis: true,
    },

    {
      title: "Actions",
      render: (id, name) => tableActions(id, name),
    },
  ];

  const Agentcolumns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },

    {
      title: "ReferenceNo",
      dataIndex: "refNo",
      sorter: (a, b) => a.refNo.localeCompare(b.refNo),
    },
    {
      title: "RefundStatus",
      dataIndex: "refundStatus",

      render: (id) => RefundStatus(id),
    },
    {
      title: "Query",
      dataIndex: "queryName",
      sorter: (a, b) => a.queryName.localeCompare(b.queryName),
      ellipsis: true,
    },

    {
      title: "DetailedQuery",
      dataIndex: "detailedQueryName",
      sorter: (a, b) => a.detailedQueryName.localeCompare(b.detailedQueryName),
      ellipsis: true,
    },

    {
      title: "Remarks",
      dataIndex: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
      ellipsis: true,
    },

    {
      title: "AdminAnswer",
      dataIndex: "adminAnswer",
      sorter: (a, b) => a.adminAnswer.localeCompare(b.adminAnswer),
      ellipsis: true,
    }
  ];

  const SubmitQueryFormDetials = () => {
    if (Object.keys(updateDetails).length > 0)
      return (
        <div>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <p>
                <b>ReferenceNo:</b> {updateDetails?.refNo ?? ''}
              </p>
            </Col>
            <Col md={12} xs={24}>
              <p>
                <b>Query:</b> {updateDetails?.queryName ?? ''}
              </p>
            </Col>
            <Col md={12} xs={24}>
              <p>
                <b>Detailed Query:</b> {updateDetails?.detailedQueryName ?? ''}
              </p>
            </Col>
            <Col md={12} xs={24}>
              <p>
                <b>Remarks:</b> {updateDetails.description}
              </p>
            </Col>
            <Col md={12} xs={24}>
              <p>
                <b>adminAnswer:</b> {updateDetails.adminAnswer}
              </p>
            </Col>

          </Row>
        </div>
      );
  };

  const getDetailedQuery = (val) => {
    getDetailedQueryList(val)
  }

  const getQueryForBusCancellation = () => {
    ApiClient.get("query/getquery")
      .then((res) => {
        if (res.isSuccess) {
          const detailsQuery = res.data.filter(q => q.queryName.toUpperCase().includes("BUS CANCELLATION ISSUE"))
          const queryId = detailsQuery[0]?.id ?? 0
          setQueryList(res.data);

          form.setFieldsValue({
            refNo: ticketSearchParams.RefNo,
            queryId: queryId
          })

          getDetailedQueryList(queryId)
        }
      })
      .catch((error) => {
        console.error(error);
      });

  }
  const { Panel } = Collapse;

  const onChange = (key) => {
    console.log(key);
  };


  return (
    <Layout className="head-layout-check">
      <Collapse defaultActiveKey={['1']} onChange={onChange}>
        <Panel header="Quick Help" key="1">
          <div className="card-bt-gap">
            <Card bordered={false}>
              {/* <div className="card-add-heading"> */}
              <Form layout="vertical" form={form} onFinish={submitForm}>
                <Row gutter={12} align="middle">
                  <Col md={6} sm={24} xs={24}>
                    <Form.Item
                      label="Reference Number"
                      name="refNo"
                      rules={[{ required: true, message: "Required" },
                        // ({ getFieldValue }) => ({
                        //   validator(value) {
                        //     if (refno.includes(getFieldValue('refNo')))
                        //       return Promise.resolve();
                        //     else if (getFieldValue('refNo').length > 0)
                        //       return Promise.reject("Please Enter valid Reference Number");
                        //     else
                        //       return Promise.reject()
                        //   }
                        // })
                      ]}

                    >
                      <Input placeholder="Enter Reference Number" />
                    </Form.Item>
                  </Col>
                  <Col md={7} sm={24} xs={24}>
                    <Form.Item
                      label="Query "
                      name="queryId"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Select
                        // onChange={categoryChange}
                        onSelect={getDetailedQuery}
                        placeholder="Select Query"
                      >
                        {QueryList.map((item, index) => (
                          <Option key={index} value={item.id}>
                            {`${item.queryName}`}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col md={7} sm={24} xs={24}>
                    <Form.Item
                      label="Detailed Query "
                      name="detailedQueryId"
                      rules={[
                        { required: true, message: "Required" },
                      ]}
                    >
                      <Select placeholder="Select Detailed Query">
                        {DetailedQueryList.map((item, index) => (
                          <Option key={index} value={item.id}>
                            {`${item.detailedQueryName}`}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col md={24} sm={24} xs={24}>
                    <Form.Item
                      label="Remarks "
                      name="description"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Input.TextArea
                        rows={4}
                        placeholder="Enter Remarks "
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    md={24}
                    sm={24}
                    xs={24}
                    className="d-flex justify-content-center"
                  >
                    <Button key="add" type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
              {/* </div> */}
            </Card>
          </div>
        </Panel>
        <Panel header="Quick Help Report" key="2">

          <div className="manage-markup-section">
            <Content className="container-fluid">


              {tableList.length > 0 ? (
                <Table className="quick-help-table"
                  // scroll={{ x: true }}
                  bordered
                  rowKey={uniqueKey}
                  dataSource={tableList}
                  columns={columns}
                  pagination={{
                    defaultPageSize: 25,
                    showSizeChanger: true,
                    pageSizeOptions: ["25", "50", "100", "125"],
                  }}
                />
              ) : (
                "No Record Found"
              )}
            </Content>
            <Modal
              width="900px"
              visible={modalVisible}
              title="Submit Query"
              className=" modal-header-bg12"
              onCancel={(e) => {
                setModalVisible(false);
              }}
              footer={[
                <div>
                  <Button key="clear" onClick={() => adminAnswerForm.resetFields()}>
                    Clear
                  </Button>
                  <Button key="add" type="primary" onClick={adminAnswerForm.submit}>
                    Update Remarks
                  </Button>
                </div>,
              ]}
            >
              {Object.keys(updateDetails).length > 0 && (
                <div>
                  {SubmitQueryFormDetials()}
                  <Form
                    form={adminAnswerForm}
                    layout="vertical"
                    onFinish={(val) => updateSubmitQuery(val, updateDetails)}
                  >
                    <Row gutter={16}>
                      <Col className="gutter-class" md={12} xs={24}>
                        <Form.Item
                          label="Admin Remarks"
                          name="adminAnswer"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Input.TextArea
                            autocomplete="newpassword"
                            placeholder=" Admin Remarks"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
              )}
            </Modal>
          </div>
        </Panel>

      </Collapse>
    </Layout>
  );
};
export default QuickHelp;
