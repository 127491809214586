import React, { useState, useEffect } from "react";
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    Modal,
    message,
    Popconfirm,
    Row,
    Table,
    Select,
    Switch,
    Radio
} from "antd";
import {
    PlusCircleFilled,
    EditOutlined,
    DeleteOutlined,
    CloseOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import { exportExcel } from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
import { useAuthContext } from "../../common/providers/AuthProvider";

const { Content } = Layout;
const { Search, TextArea } = Input;
const ErrorCodes = () => {
    const { Option } = Select;
    const { user } = useAuthContext();
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [errorCodeList, setErrorCodeList] = useState([]);
    const [tableList, setTableList] = useState([]);
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [currentId, setCurrentId] = useState(-1);
    const [showSearchBox, setShowSearchBox] = useState(false);
  

    useEffect(() => {
        getErrorCodeList();
     
    }, []);

   

    let ErrorCodeList =
        [
            { name: "Er100: Low Balance", value: 100, },
            { name: "Er101: Tentative Booking failed", value: 101 },
            { name: "Er103: Seat is No Longer AVailable", value: 102 },
            { name: "Er103: Could not fetch trip details for POST DOJ.", value: 103 },
            { name: "Er104: Itinerary expired", value: 104 },
            { name: "Er105: Invalid boarding point", value: 105 },
            { name: "Er106: Trip is not available anymore.", value: 106 },
            { name: "Er107: SeatreservedforLadies.", value: 107 },
            { name: "Er108: No blocked itineraries found for the given blockid", value: 108 },
            { name: "Er109: Failed to book ticket", value: 109 },
            { name: "Er110: Ticket Expired", value: 110 },
            { name: "Er111: No Itinerary Found", value: 111 },
            { name: "Er112: Partial cancellation not allowed", value: 112 }
        ]


    const getErrorCodeList = () => {

        ApiClient.get("supplier/geterrorcode")
            .then((res) => {
                if (res.isSuccess) {
                    let data = res.data.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item
                        };
                    });

                    setErrorCodeList(data);
                    setTableList(data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    console.log("tableList",tableList)
    const SaveErrorCode = (data) => {
        
        ApiClient.post("supplier/saveerrorcode", data)
            .then((response) => {
                return response;
            })
            .then((response) => {
                if (response.isSuccess) {
                    setModalVisible(false);
                    message.success("Successfully added AdminCommission", 3);
                    getErrorCodeList();
                } else if (!response.isSuccess) {
                    message.error("AdminCommission Already existed", 3);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleModal = () => {
        if (showUpdateBtn) {
            setShowUpdateBtn(false);
            form.resetFields();
        }
        setModalVisible(true);
    };

    const deleteErrorCodeList = (Id) => {

        ApiClient.delete("supplier/deleteerrorcode/" + Id)
            .then((res) => {
                if (res.isSuccess) {
                    message.success("ErrorCode Deleted  successfully", 3);
                    getErrorCodeList();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const updateErrorCodeList = (Id, data) => {
        console.log("update data,",data)
        ApiClient.put(`supplier/updateerrorcode/${Id}`, {}, data)
            .then((res) => {
                if (res.isSuccess) {
                    message.success("Updated  successfully", 3);
                    setModalVisible(false);
                    getErrorCodeList()
                } else message.error(res.message ?? "Failed To Update", 3)
            })
            .catch((error) => {
                console.log(error.message);
            });
    };

    const submitForm = (val) => {
        let obj = {
            ...val,
        };
        if (showUpdateBtn) updateErrorCodeList(currentId, obj);
        else SaveErrorCode(obj);
    };

    const tableActions = (currentId, currentRecord) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <EditOutlined
                        onClick={() => editCurrentItem(currentId, currentRecord)}
                        style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
                    />
                </div>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <Popconfirm
                        title="Are you sure want to delete?"
                        onConfirm={() => deleteErrorCodeList(currentId)}
                    >
                        <DeleteOutlined
                            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                        />
                    </Popconfirm>
                </div>
            </div>
        );
    };

    const updateStatus = (val, record) => {
        let Id = record.id;
        let data = {
            errorCode: record.errorCode,
            errorMessage: record.errorMessage,
            isActive: val,
        };
        updateErrorCodeList(Id, data)
    };

    const editCurrentItem = (id, data) => {
        setShowUpdateBtn(true);
        setCurrentId(id);
        form.setFieldsValue({
            ...data
        });
        setModalVisible(true);
    };

    let lastIndex = 0;
    const uniqueKey = () => {
        return lastIndex++;
    };
  
    const searchData = () => {
        setShowSearchBox(true);
    };

    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "SNo",
            sorter: (a, b) => a.SNo - b.SNo,
        },
        {
            title: "ErrorCode",
            dataIndex: "errorCode",
            // sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: "ErrorCodeName",
            dataIndex: "errorCodeName",
            // sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: "ErrorMessage",
            dataIndex: "errorMessage",
            sorter: (a, b) => a.commission - b.commission,
        },
        {
            title: "CreatedDate",
            dataIndex: "createdOn",
            render: (createdOn) => moment(createdOn).format("DD-MM-YYYY HH:mm")
        },
        {
            title: "Status",
            dataIndex: "isActive",
            render: (isActive, record) => (
                <Switch
                    checkedChildren="Active"
                    unCheckedChildren="InActive"
                    defaultChecked={isActive}
                    onChange={(val) => updateStatus(val, record)}
                />
            ),
        },
        {
            title: "Actions",
            dataIndex: "id",
            render: (id, name) => tableActions(id, name)
        },
    ];

    const searchTableData = (e) => {
        var searchVal = e.target.value;
        if (searchVal.length > 0) {
            const searchResults = tableList.filter(function (item) {
                if (item != undefined) {
                    return JSON.stringify(item)
                        .toLowerCase()
                        .includes(searchVal.toLowerCase());
                }
            });
            setTableList(searchResults);
        } else {
            setTableList(errorCodeList);
        }
    };

    const closeSearch = () => {
        setShowSearchBox(false);
        setTableList(errorCodeList);
    };

    const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

    const handleExcel = () => {
        let excelData = tableList;
        excelData = excelData.map((item, i) => ({
            SNo: i + 1,
            ErrorCode: item.errorCode,
            ErrorCodeName: item.errorCodeName,
            ErrorMessage: item.errorMessage,
            CreatedOn:item.createdOn,
            isActive: item.isActive ? "True" : "False"
        }));

        exportExcel(excelData, "ErrorCode");
    };

    return (
        <Layout>
            <div className="manage-markup-section">
                <Content className="container-fluid">
                    <div className="card-bt-gap">
                        <Card bordered={false}>
                            <div className="card-add-heading">
                                <div className="rows-count">
                                    <h5>View ErrorCodes</h5>
                                    <p>{tableList.length} rows found !</p>
                                </div>
                                <div className="action-images">
                                    {showSearchBox && (
                                        <Input
                                            autoComplete="newpassword"
                                            placeholder="Search"
                                            onChange={(e) => searchTableData(e)}
                                            suffix={closeSearchInput}
                                            style={{ padding: "0px 12px" }}
                                        />
                                    )}
                                    &nbsp;&nbsp;
                                    <img src={search} alt="search" onClick={() => searchData()} />
                                    <img src={excel} alt="excel" onClick={() => handleExcel()} />
                                    <img
                                        src={exchange}
                                        alt="exchange"
                                        onClick={() => {
                                            setTableList([]);
                                            getErrorCodeList();
                                        }}
                                    />
                                    {user?.roleId === 1 ? (
                                        <p className="add-deposit-icon" onClick={handleModal}>
                                            <PlusCircleFilled />
                                        </p>
                                    ) : null}
                                </div>
                            </div>

                            <Table
                                scroll={{ x: true }}
                                bordered
                                rowKey={uniqueKey}
                                dataSource={tableList}
                                columns={columns}
                                pagination={{
                                    defaultPageSize: 25,
                                    showSizeChanger: true,
                                    pageSizeOptions: ["25", "50", "100", "125"],
                                }}
                            />
                        </Card>
                    </div>
                </Content>
            </div>
            <Modal
                title={[
                    <div>
                        <h6 style={{ marginBottom: "0px" }}>
                            <strong>
                                {showUpdateBtn ? "Update ErrorCode" : "Add ErrorCode"}
                            </strong>
                        </h6>
                    </div>,
                ]}
                className="promo-modal-header bci-panel-admin-parts"
                visible={modalVisible}
                onOk={(e) => setModalVisible(false)}
                onCancel={(e) => setModalVisible(false)}
                footer={[
                    <Button key="add" type="primary" onClick={form.submit}>
                        {showUpdateBtn ? "Update" : "Add"}
                    </Button>,
                ]}
            >
                <Form layout="vertical" form={form} onFinish={submitForm}>
                    <Row gutter={12}>
                        <Col span={24}>
                            <Form.Item label="ErrorCode" name="errorCode" rules={[{ required: true, message: "Required" }]}>
                                <Select placeholder="Please select Supplier">
                                    {ErrorCodeList.map((item) => (
                                        <Option value={item.value}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item name="errorMessage" label="Error Message" rules={[{ required: false }]}>
                                <TextArea />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="Active" name="isActive">
                                <Radio.Group>
                                    <Radio value={true}>True</Radio>
                                    <Radio value={false}>False</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Layout>
    );
};
export default ErrorCodes;
