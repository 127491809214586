import React from "react";
import { Route, Redirect } from "react-router-dom";
import { checkRouteAccess } from "./ProtectedMenu";

const AdminRoute = ({
  component: Component,
  accessList,
  accessToken,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return accessToken &&
          checkRouteAccess(props.location.pathname, accessList) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/unauthorized",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default AdminRoute;
