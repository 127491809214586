import React from "react";
import "./BusInvoice.scss";
import * as ReactBootstrap from "react-bootstrap";
import BusDetails from "../../Buses/Checkout/BusDetails";
import FareDetails from "../Checkout/FareDetails";
import PassengerDetails from "../Review/BusPassengerDetails/PassengerDetails";

const BusInvoice = () => {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="businvoice">
      <div className="bus-invoice">
        <div className="invoice-block ">
          <div className="invoice-header">
            <div className="invoice-company-logo">
              <div className="invoice-company-img">
                {/* <a><img _ngcontent-wpp-c1="" src="../../../assets/images/flight-icon/logo.png"></a> */}
              </div>
              <div class="invoice-title">i2space</div>
            </div>
            <div class="invoice-trip-contact-details">
              <ul>
                <li>Need help with your trip?</li>
                <li>Boarding Point Ph no.:9874563210</li>
                <li>orange travels</li>
              </ul>
            </div>
          </div>
          <div className="invoice-body">
            <div className="invoice-body-first-line">
              <div className="invoice-from-to-details">
                <div className="from-to-names">
                  <ul>
                    <li>Hyderabad</li>
                    <li>
                      <i className="fa fa-arrow-right"></i>
                    </li>
                    <li>visakhapatnam</li>
                  </ul>
                </div>
                <div className="invoice-date-time">
                  Saturday,Feb 28,2020 - static
                </div>
              </div>
              <div className="invoiceticket-number">
                <ul>
                  <li>Ticket no: 9874560</li>
                  <li>PNR no: 02314</li>
                </ul>
              </div>
            </div>
            <div className="invoice-second-line">
              <ul>
                <li className="invoice-travelsdetails">
                  <div>orange travels</div>
                  <div>AC</div>
                </li>
                <li className="invoice-reportingtimedetails">
                  <div>02:55pm</div>
                  <div>Reporting Time</div>
                </li>
                <li className="invoice-departingdetails">
                  <div>12:20</div>
                  <div>Departing Time</div>
                </li>
                <li className="invoice-numberdetails">
                  <div>1</div>
                  <div>Number Of Passengers</div>
                </li>
              </ul>
            </div>
            <div className="invoice-third-line">
              <ul>
                <li className="invoice-travelsdetails">
                  <div>Boarding Point Details</div>
                </li>
                <li className="invoice-reportingtimedetails">
                  <div>
                    {" "}
                    bunk
                    <br />
                    <span>9874563210</span>
                  </div>
                  <div>Location</div>
                </li>
                <li className="invoice-departingdetails">
                  <div>bunk</div>
                  <div>Landmark</div>
                </li>
                <li className="invoice-numberdetails">
                  <div>bunk</div>
                  <div>Address</div>
                </li>
              </ul>
            </div>
            <div className="invoice-fourth-line">
              <ul>
                <li className="invoice-travelsdetails">
                  <div>Dropping Point Details</div>
                </li>
                <li className="invoice-reportingtimedetails">
                  <div>02:23pm</div>
                  <div>Dropping Point Time</div>
                </li>
                <li className="invoice-departingdetails">
                  <div>01-Mar-2020 - static</div>
                  <div>Dropping Point Date</div>
                </li>
                <li className="invoice-numberdetails">
                  <div>Hyderabad</div>
                  <div>Address</div>
                </li>
              </ul>
            </div>
            <div className="invoice-fifth-line">
              <ul>
                <li className="invoice-travelsdetails">
                  <div>Passenger Details(Age,Gender)</div>
                  <div>i2space 28,male)</div>
                </li>
                <li className="invoice-reportingtimedetails">
                  <div>Seat Number</div>
                  <div>L9</div>
                </li>
              </ul>
            </div>
            <div className="invoice-note">
              <div class="note-indication">
                NOTE: This operator accpts mTicket,you need to carry out a print
                out
              </div>
              <div class="total-fare">
                <div>
                  Total Fare : <span class="invoice-price">Rs.1280</span>
                  <h6 class="invoice-tax-info">
                    (Rs.1280 Inclusive of GST and service charge if any)
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="invoice-terms-conditions ">
            <div className="invoice-terms-header">
              <h5>Terms and Conditions</h5>
            </div>
            <div className="invoice-content">
              <div className="contact-footer">
                <div className="terms-conditions-cancellation-policy-container">
                  <div className="terms-conditions-cancellation-policy-row">
                    <div className="terms-conditions">
                      {/* <h4>Terms and conditions</h4> */}
                      <p>
                        <span>SeatSeller*</span> only a bus ticket agent. It
                        does not operate bus services of its own. In order to
                        provide a comprehensive choice of bus operators
                        departure times and prices to customers, it has tied up
                        with many bus operators. SeatSeller advice to customers
                        is to choose bus operators they are aware of and whose
                        service they are comfortable with.
                      </p>
                    </div>
                    <div className="cancellation-policy">
                      <div>
                        <h4 className="cancellation-policy-header">
                          Cancellation Policy
                        </h4>
                      </div>
                      <div className="cancellation-policy-body">
                        <ul>
                          <li>
                            <span>Cancellation Time</span>
                            <span>Charges</span>
                          </li>
                          <li>
                            <span>Between 12/06/2020 and 12/06/2020 </span>
                            <span>100%</span>
                          </li>
                          <li>
                            <span>Between 12/06/2020 and 13/06/2020 </span>
                            <span>85%</span>
                          </li>
                          <li>
                            <span>Between 12/06/2020 and 14/06/2020 </span>
                            <span>75%</span>
                          </li>
                          <li>
                            <span>Between 12/06/2020 and 16/06/2020 </span>
                            <span>100%</span>
                          </li>
                          <li>
                            <span>Between 12/06/2020 and 20/06/2020 </span>
                            <span>65%</span>
                          </li>
                          <li>
                            <span>Between 12/06/2020 and 12/06/2020 </span>
                            <span>50%</span>
                          </li>
                          <li>
                            <span>Between 12/06/2020 and 12/06/2020 </span>
                            <span>25%</span>
                          </li>
                          <li>
                            <span>Between 12/06/2020 and 12/06/2020 </span>
                            <span>10%</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="seat-seller-responsibilities-container">
                  <div className="seat-seller-responsibilities-row">
                    <div className="responsible-for">
                      <h4 className="responsible-for-header">
                        SeatSeller responsible for
                      </h4>
                      <ol>
                        <li>
                          Issuing a valid ticket (a ticket that will be accepted
                          by the bus operator) for it's network of bus operator
                        </li>
                        <li>
                          Providing refund and support in the event of
                          cancellation{" "}
                        </li>
                        <li>
                          Providing customer support and information in case of
                          any delays/inconvenience
                        </li>
                      </ol>
                    </div>
                    <div className="not-responsible-for">
                      <h4 className="not-responsible-for-header">
                        SeatSeller not responsible for
                      </h4>
                      <ol>
                        <li>
                          The bus operator's bus not reaching/departing on time
                        </li>
                        <li>The bus operator's employees being rude </li>
                        <li>
                          The bus operator's bus seats etc not being up to the
                          customer's expectation
                        </li>
                        <li>
                          The bus operator canceling the trip due to unavoidable
                          reasons{" "}
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BusInvoice;
