import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./BusPaymentSuccess.scss";

import queryString from "query-string";
import ApiClient from "../../../helpers/BusApiClient";
import Busticketsuccess from "../../../components/Busticketsuccess/Busticketsuccess";
import BusTicketSuccSkeleton from "../../../components/Busticketsuccess/BusTicketSuccSkeleton";
// import { res } from "./file";
const PaymentSucces = () => {
  const [loading, setLoading] = useState(true);
  const [busTicketsDetails, setBusTicketsDetails] = useState({
    status: "",
    refNo: "",
    tktNo: "",
    pnrNo: "",
    error: "",
  });

  const params = queryString.parse(document.location.search);

  const getBookingStatus = (status) => {
    switch (status) {
      case 1: {
        return <span style={{ color: "#FFA500" }}> <b>FAILED</b> </span>;
      }
      case 2: {
        return (
          <span style={{ color: "#008000" }}>
            <b> CONFIRMED</b>
          </span>
        );
      }
      case 3: {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> CANCELLED</b>
          </span>
        );
      }
      case 4: {
        return (
          <span style={{ color: "#008000" }}>
            <b>PENDING</b>
          </span>
        );
      }
      case 5: {
        return (
          <span style={{ color: "#f9e218" }}>
            <b> REJECTED</b>
          </span>
        );
      }
      case 6: {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> HOLD</b>
          </span>
        );
      }
      case 7: {
        return (
          <span style={{ color: "#FF0000" }}>
            <b>CANCELLATIONREUEST</b>
          </span>
        );
      }
      case 8: {
        return (
          <span style={{ color: "#008000" }}>
            <b> CANCELLATIONPENDING</b>
          </span>
        );
      }
      case 9: {
        return (
          <span style={{ color: "#008000" }}>
            <b> CANCELLATIONINPROCESS</b>
          </span>
        );
      }
      default:
        return (<b>{status}</b>);
    }
  };

  const busBooking = (ref) => {

   // setLoading(false);
    setLoading(true);
    let obj = {
      trips: [
        {
          refNo: ref,
        },
      ],
    };
    // setBusTicketsDetails(
    //   { Api: "ETST", status: "CONFIRMED", refNo: "ejdBUPBK2G", tktNo: "ETS247B1PT277923", pnrNo: "TS220520194048613882UNQM/217721/SDMT - 011", tktKey: "clLO71YcG3IfIZ/A1Yv1pcqBDe8K\u002BlhIvU/0Pnae8yzKKI3/v77He\u002BlnjEKtDF6DRH8hsNTThmFKJNh5GkFXy4edJvFMTPlZVEl\u002BbP8Cqss=" }
    // )

    ApiClient.post("bus/book", obj)
      .then((result) => {
        return result;
      })
      .then((resp) => {
      
        if (resp?.trips && resp?.trips?.length > 0) {

          if (resp.trips[0]?.status === "CONFIRMED") {
            setBusTicketsDetails({
              ...resp.trips[0],
              error: "",
            });

          } else if (resp.trips[0]?.error) {
            setBusTicketsDetails({
              ...resp.trips[0],
              error: resp.trips[0].error.desc,
            });
          }
        }

        // if (resp?.Trips && resp?.Trips?.length > 0) {

        //   if (resp.Trips[0]?.Status == 2) {
        //     setBusTicketsDetails({
        //       status: resp?.Trips[0]?.Status ==2?"Confirmed" ?? '',
        //       refNo: resp?.Trips[0]?.RefNo ?? '',
        //       tktNo: resp?.Trips[0]?.TktNo ?? '',
        //       pnrNo: resp?.Trips[0]?.PnrNo ?? '',
        //       error: resp?.Trips[0]?.Error ?? '',
        //     });

        //   } else if (resp.Trips[0]?.Error) {
        //     setBusTicketsDetails({
        //       ...resp.Trips[0],
        //       error: resp.Trips[0]?.Error,
        //     });
        //   }
        // }

        setLoading(false);
      })
      .catch((err) => {
      
        setBusTicketsDetails({
          status: "",
          refNo: "",
          tktNo: "",
          pnrNo: "",
          error: "",
        });
        setLoading(false);
      });
  };


  useEffect(() => {
    busBooking(params.ref);
  }, []);

  return (
    <div className="success_area">
      {loading ? (
        <BusTicketSuccSkeleton />
      ) : (
        <Busticketsuccess
          busTicketsDetails={busTicketsDetails}
          loading={loading}
        />
      )}
    </div>
  );
};
export default PaymentSucces;
