import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import "./FlightsInvoice.scss";

const FlightsInvoice = (props) => {
 

  return (
    <div>
      <ReactBootstrap.Container fluid className="flightInvoice">
        <div className="flight-invoice-body">
          <ReactBootstrap.Form>
            <div className="invoice-booking-details">
              <div className="booking-heading">
                <h5>Booking Details</h5>
                <p>View Voucher |Invoices Credit Notes</p>
              </div>
              <div className="booking-details">
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col md="6">
                    <div>
                      <div className="booking-details-fields">
                        <ReactBootstrap.FormLabel>
                          {" "}
                          Booking ID :{" "}
                        </ReactBootstrap.FormLabel>
                        <p>GRN-432378-497956</p>
                      </div>
                      <div className="booking-details-fields">
                        <ReactBootstrap.FormLabel>
                          {" "}
                          Booking Date :{" "}
                        </ReactBootstrap.FormLabel>
                        <p>2020-03-02 09:30:20</p>
                      </div>
                      <div className="booking-details-fields">
                        <ReactBootstrap.FormLabel>
                          Agent ID :{" "}
                        </ReactBootstrap.FormLabel>
                        <p>ss@i2space.com</p>
                      </div>
                      <div className="booking-details-fields">
                        <ReactBootstrap.FormLabel>
                          {" "}
                          Status :{" "}
                        </ReactBootstrap.FormLabel>
                        <p>Cancellation Confirmed</p>
                      </div>
                      <div className="booking-details-fields">
                        <ReactBootstrap.FormLabel>
                          {" "}
                          Agent Contact Number :{" "}
                        </ReactBootstrap.FormLabel>
                        <p>8883563437</p>
                      </div>
                      <div className="booking-details-fields">
                        <ReactBootstrap.FormLabel>
                          {" "}
                          Allow Cancellation :{" "}
                        </ReactBootstrap.FormLabel>
                        <p>True</p>
                      </div>
                      <div className="booking-details-fields">
                        <ReactBootstrap.FormLabel>
                          {" "}
                          Fligth Confirmation Number :{" "}
                        </ReactBootstrap.FormLabel>
                        <p>
                          <a href="#!">Update Flight Confirmation Number</a>{" "}
                        </p>
                      </div>
                    </div>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col md="6">
                    <div>
                      <div className="booking-details-fields">
                        <ReactBootstrap.FormLabel>
                          {" "}
                          Booking Reference :{" "}
                        </ReactBootstrap.FormLabel>
                        <p>sbbidsk1224bjssaj</p>
                      </div>
                      <div className="booking-details-fields">
                        <ReactBootstrap.FormLabel>
                          {" "}
                          Supplier Booking Date :{" "}
                        </ReactBootstrap.FormLabel>
                        <p>2020-03-02 09:30:20</p>
                      </div>
                      <div className="booking-details-fields">
                        <ReactBootstrap.FormLabel>
                          {" "}
                          City :{" "}
                        </ReactBootstrap.FormLabel>
                        <p>Vizag</p>
                      </div>
                      <div className="booking-details-fields">
                        <ReactBootstrap.FormLabel>
                          {" "}
                          Nationality :{" "}
                        </ReactBootstrap.FormLabel>
                        <p>India</p>
                      </div>
                      <div className="booking-details-fields">
                        <ReactBootstrap.FormLabel>
                          {" "}
                          Non Refundable :{" "}
                        </ReactBootstrap.FormLabel>
                        <p>No</p>
                      </div>
                    </div>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              </div>
              <div className="sub-headings">
                <h5>Cancellation Details</h5>
              </div>
              <div className="cancellation-details">
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col md="6">
                    <div className="cancellation-fields">
                      <ReactBootstrap.Row>
                        <ReactBootstrap.Col>
                          <ReactBootstrap.FormLabel>
                            Cancellation Date :
                          </ReactBootstrap.FormLabel>
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col>
                          <p>2020-03-02 09:30:20</p>
                        </ReactBootstrap.Col>
                      </ReactBootstrap.Row>
                    </div>
                    <div className="cancellation-fields">
                      <ReactBootstrap.Row>
                        <ReactBootstrap.Col>
                          <ReactBootstrap.FormLabel>
                            Cancellation Charge :
                          </ReactBootstrap.FormLabel>
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col>
                          <p>INR 0</p>
                        </ReactBootstrap.Col>
                      </ReactBootstrap.Row>
                    </div>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col md="6">
                    <div className="cancellation-fields">
                      <ReactBootstrap.Row>
                        <ReactBootstrap.Col>
                          <ReactBootstrap.FormLabel>
                            Cancellation Status :
                          </ReactBootstrap.FormLabel>
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col>
                          <p>Confirmed</p>
                        </ReactBootstrap.Col>
                      </ReactBootstrap.Row>
                    </div>
                    <div className="cancellation-fields">
                      <ReactBootstrap.Row>
                        <ReactBootstrap.Col>
                          <ReactBootstrap.FormLabel>
                            Refund :
                          </ReactBootstrap.FormLabel>
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col>
                          <p>INR 440</p>
                        </ReactBootstrap.Col>
                      </ReactBootstrap.Row>
                    </div>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              </div>
              <div className="sub-headings">
                <h5>Flight</h5>
              </div>
              <div className="flight-details">
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col md="2">
                    <div className="flight-left-section">
                      <div className="airline-img">
                        <img
                          src={
                            "https://www.esky.eu/_fe/img/base64:YWxfbG9nb19CQS5wbmc=?s=80x29"
                          }
                          alt="airlineImg"
                        />
                      </div>
                    </div>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col md="3">
                    <div className="airline-name">
                      <h4>British Airways</h4>
                    </div>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col md="3">
                    <div className="flight-more-details">
                      <h6>BLR - DXB</h6>
                      <p>
                        <strong>Total Trip duration :</strong> 4h
                      </p>
                      <p>
                        <strong> 4:20 - 8:20</strong>
                      </p>
                      <p>
                        <strong>Airline :</strong> Oman Air
                      </p>
                      <p>
                        <strong>Flight Number:</strong> 518
                      </p>
                      <p>
                        <strong>Class: Economy</strong>
                      </p>
                    </div>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              </div>
              <div className="sub-headings">
                <h5>Price Details</h5>
              </div>
              <div className="cancellation-details">
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col md="4">
                    <div className="cancellation-fields">
                      <ReactBootstrap.Row>
                        <ReactBootstrap.Col>
                          <ReactBootstrap.FormLabel>
                            Price :
                          </ReactBootstrap.FormLabel>
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col>
                          <p>INR 518</p>
                        </ReactBootstrap.Col>
                      </ReactBootstrap.Row>
                    </div>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col md="4">
                    <div className="cancellation-fields">
                      <ReactBootstrap.Row>
                        <ReactBootstrap.Col>
                          <ReactBootstrap.FormLabel>
                            Payment Status :
                          </ReactBootstrap.FormLabel>
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col>
                          <p>Paid</p>
                        </ReactBootstrap.Col>
                      </ReactBootstrap.Row>
                    </div>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col md="4">
                    <div className="cancellation-fields">
                      <ReactBootstrap.Row>
                        <ReactBootstrap.Col>
                          <ReactBootstrap.FormLabel>
                            Payment Type :
                          </ReactBootstrap.FormLabel>
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col>
                          <p>AT_WEB</p>
                        </ReactBootstrap.Col>
                      </ReactBootstrap.Row>
                    </div>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              </div>
              <div className="pax-table" style={{ paddingTop: "0px" }}>
                <p>Charge Details</p>
                <table className="table responsive bordered">
                  <thead>
                    <th>Name</th>
                    <th>Charge Amount</th>
                    <th>Included in Price</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ borderRight: "none", fontWeight: "600" }}>
                        Net
                      </td>
                      <td style={{ border: "none" }}></td>
                      <td
                        style={{
                          border: "none",
                          borderRight: "solid 1px #ccc",
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td>Supplier Price</td>
                      <td>INR 518</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>Serivce Fee</td>
                      <td>INR 518</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td>INR 518</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td style={{ borderRight: "none", fontWeight: "600" }}>
                        GST
                      </td>
                      <td style={{ border: "none" }}></td>
                      <td
                        style={{
                          border: "none",
                          borderRight: "solid 1px #ccc",
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td>INR 518</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td>INR 24943</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="sub-headings">
                <h5>Pax Details</h5>
              </div>
              <div className="pax-table">
                <table className="table responsive bordered">
                  <thead>
                    <th>Pax Name</th>
                    <th>Pax Type</th>
                    <th>Age</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Bhavya sri</td>
                      <td>Adult</td>
                      <td>21</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="sub-headings">
                <h5>Holder Details</h5>
              </div>
              <div className="holder-details">
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col md="3">
                    <div className="holder-fields">
                      <ReactBootstrap.FormLabel>
                        Name :
                      </ReactBootstrap.FormLabel>
                      <p>Bhavya Sri</p>
                    </div>
                    <div className="holder-fields">
                      <ReactBootstrap.FormLabel>
                        Email :
                      </ReactBootstrap.FormLabel>
                      <p>ss@i2space.com</p>
                    </div>
                    <div className="holder-fields">
                      <ReactBootstrap.FormLabel>
                        Phone Number :
                      </ReactBootstrap.FormLabel>
                      <p>9876543210</p>
                    </div>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              </div>
              <div className="sub-headings">
                <h5>Booking Important Notes</h5>
              </div>
              <div className="booking-notes">
                <ul>
                  <li>
                    Taxes are subject to change based on Goods and Services Tax
                    (GST) Implementation. For more details, please contact the
                    property using the information on the reservation
                    confirmation received after booking.
                  </li>
                  <li>
                    Extra-person charges may apply and vary depending on
                    property policy. Government-issued photo identification and
                    a credit card , debit card , or cash deposit are required at
                    check-in incidental charges. Special requests are subject to
                    availability upon check-in and may incur additional charges.
                    Special requests cannot be guaranteed. Please note that
                    cultural norms ad guest policies may differ by country and
                    by property. The policies listed are provided by the
                    property.
                  </li>
                  <li>
                    If holder is not one of the paxes, one of the adult paxes
                    will be considered as holder.
                  </li>
                </ul>
              </div>
            </div>
          </ReactBootstrap.Form>
        </div>
      </ReactBootstrap.Container>
    </div>
  );
};

export default FlightsInvoice;
