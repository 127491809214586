import React from "react";
import { Table } from "antd";
import "./ticketBus.scss";
const TicketBus = ({ ticketData, setTotalPrice }) => {
  const getStatus = (status) => {
    switch (status) {
      case "BLOCKED": {
        return <span style={{ color: "#FFA500" }}> </span>;
      }
      case "CONFIRMED": {
        return (
          <span style={{ color: "#008000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "Booked": {
        return (
          <span style={{ color: "#008000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "PartiallyCancelled": {
        return (
          <span style={{ color: "#008000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "CREATED": {
        return (
          <span style={{ color: "#f9e218" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "FAILED": {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "Cancelled": {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> {status}</b>
          </span>
        );
      }
      default:
        return;
    }
  };

  const busDetailsColumns = [
    {
      title: "Operator",
      dataIndex: "operator",
      key: "operator",
    },
    {
      title: "Bus Type",
      dataIndex: "busTypeName",
      key: "busTypeName",
    },
    {
      title: "Departure Time",
      dataIndex: "departureTime",
      key: "departureTime",
    },

    {
      title: "Boarding Point",
      dataIndex: "boardingPoint",
      key: "boardingPoint",
    },
    {
      title: "Dropping Point",
      dataIndex: "droppingPoint",
      key: "droppingPoint",
    },
    {
      title: "Source",
      dataIndex: "sourceName",
    },
    {
      title: "Destination",
      dataIndex: "destinationName",
    },
  ];

  const travelcolumns = [
    {
      title: "Title",
      dataIndex: "titles",
      key: "titles",
    },
    {
      title: "Name",
      dataIndex: "names",
      key: "names",
    },

    {
      title: "Age",
      dataIndex: "ages",
      key: "ages",
    },

    {
      title: "Gender",
      dataIndex: "genders",
      key: "genders",
    },

    {
      title: "Seat No.",
      dataIndex: "seatNos",
      key: "seatNos",
    },
  ];

  const CancellationPolicy = (data) => {
    const policyStringArray = data.split(";");
    return (
      <div className="cancellation-wrapper">
        <p className="policytitle">Cancellation Policy</p>
        <ul>
          <li>
            <p>
              <b> Cancellation time </b>
            </p>
            <p>
              <b>Cancellation charges</b>
            </p>
          </li>
          {policyStringArray.map((data, index) => {
            let val = data.split(":");
            if (index === 0) {
              return (
                <li>
                  <p>{`With in ${val[1]} Hrs`} </p>
                  <p>{`${val[2]}%`}</p>
                </li>
              );
            } else if (index === policyStringArray.length - 1) {
              return (
                <li>
                  <p>{`Above ${val[0]} Hrs`} </p>
                  <p>{`${val[2]}%`}</p>
                </li>
              );
            } else {
              return (
                <li>
                  <p>{`Between ${val[0]} to ${val[1]} Hrs`} </p>
                  <p>{`${val[2]}%`}</p>
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  };
  const getConvFee = () => {
    let baseFare = getBaseFare();
    let taxes = getTaxes();
    let serTax = getSerTax();
    let total = 0;
    total = Number(baseFare) + Number(taxes) + Number(serTax);
    let convamount = 0;
    if (ticketData.convienenceData.amount) {
      if (ticketData.convienenceData.type === 1) {
        convamount = Number(ticketData.convienenceData.amount);
      } else {
        convamount = Number(
          (Number(total) / 100) *
          Number(
            ticketData.convienenceData.amount
              ? ticketData.convienenceData.amount
              : 0
          )
        );
      }
    }
    return convamount.toFixed(2);
  };
  function getBaseFare() {
    if (ticketData.passengerInfo[0].fares) {
      return Number(
        Number(ticketData.passengerInfo[0].fares) *
        ticketData.passengerInfo.length
      ).toFixed(2);
    }
  }

  function getTaxes() {
    if (ticketData.passengerInfo[0].serviceTax) {
      return Number(
        Number(ticketData.passengerInfo[0].serviceTax) *
        ticketData.passengerInfo.length
      ).toFixed(2);
    }
  }

  function getSerTax() {
    if (ticketData.passengerInfo[0].serviceCharge) {
      return Number(
        Number(ticketData.passengerInfo[0].serviceCharge) *
        ticketData.passengerInfo.length
      ).toFixed(2);
    }
  }

  const getTotal = () => {
    let baseFare = getBaseFare();
    let taxes = getTaxes();
    let serTax = getSerTax();
    let total = 0;
    total = Number(baseFare) + Number(taxes) + Number(serTax);
    total += Number(getConvFee());
    if (ticketData.promoData.Discount) {
      if (ticketData.promoData.tripType === "twoWay") {
        total -= Number(ticketData.promoData.Discount) / 2;
      } else {
        total -= Number(ticketData.promoData.Discount);
      }
    }
    setTotalPrice(total.toFixed(2));
    return total.toFixed(2);
  };

  return (
    <div className="ticket-container">
      <div className="passenger-details">
        <p>
          Passenger Contact: <span>{ticketData.mobileNo}</span>
        </p>
        <p>
          Passenger Email: <span>{ticketData.emailId}</span>
        </p>
      </div>

      <div className="booking-flight-details">
        <div className="flight-ref-details">
          <ul>
            <li>
              Ref number: <span>{ticketData.bookingRefNo}</span>
            </li>
            <li></li>
            <li>
              Journey Date:
              <span>{ticketData.JourneyDate}</span>
            </li>
          </ul>
        </div>

        <div className="flight-ref-details">
          <ul>
            <li>
              Status:
              {getStatus(ticketData.bookingStatus)}
            </li>
            <li> </li>
            <li>
              PNR:
              <span>{ticketData.pnr}</span>
            </li>
          </ul>
        </div>

        <div className="trip-details">
          <ul>
            <li></li>
            <li style={{ textAlign: "center" }}>
              Trip Name
              <div className="flight-from-to">
                <p>
                  {ticketData.sourceName} - {ticketData.destinationName}
                </p>
              </div>
            </li>
            <li></li>
          </ul>
        </div>
      </div>

      <div className="booking-flight-details-info">
        <div className="flight-deatils-text">
          <h5>Bus Details</h5>
        </div>
        <div className="flight-details-table">
          <Table
            scroll={{ x: true }}
            dataSource={[
              {
                noOfSeats: ticketData.noOfSeats,
                operator: ticketData.operator,
                busTypeName: ticketData.busTypeName,
                boardingPoint: ticketData.boardingPoint,
                droppingPoint: ticketData.droppingPoint,
                departureTime: ticketData.departureTime,
                sourceName: ticketData.sourceName,
                destinationName: ticketData.destinationName,
              },
            ]}
            columns={busDetailsColumns}
            bordered
          />
        </div>
      </div>

      <div className="booking-flight-details-info">
        <div className="flight-deatils-text">
          <h5>Travel Details</h5>
        </div>
        <div className="flight-details-table">
          <Table
            dataSource={ticketData.passengerInfo}
            columns={travelcolumns}
            scroll={{ x: true }}
            bordered
          />
        </div>
      </div>

      <div className="fare-details">
        <ul>
          <li>
            <p>Base Fare</p>
            <p className="colored-text">₹ {getBaseFare()}</p>
          </li>
          <li>
            <p>Taxes </p>
            <p className="colored-text">₹ {getTaxes()}</p>
          </li>
          <li>
            <p>Service Charges</p>
            <p className="colored-text">₹ {getSerTax()}</p>
          </li>
          <li>
            <p>Convenience Fee</p>
            <p className="colored-text">₹ {getConvFee()}</p>
          </li>
          <li>
            <p>Discount</p>
            <p className="colored-text">
              ₹{" "}
              {!ticketData.promoData.Discount
                ? "0.00"
                : ticketData.promoData.tripType === "twoWay"
                  ? Number(Number(ticketData.promoData.Discount) / 2).toFixed(2)
                  : Number(ticketData.promoData.Discount).toFixed(2)}
            </p>
          </li>
          <li>
            <p className="colored-text total-tic"> Total</p>
            <p className="colored-text total-tic">₹ {getTotal()}</p>
          </li>
          {(ticketData.bookingStatus == "Cancelled" ||ticketData.bookingStatus == "PartiallyCancelled") ? (<li>
            <p>RefundAmount</p>
            <p className="colored-text">₹ {ticketData.refundAmount}</p>
          </li>)
            : null
          }

        </ul>
      </div>
      <p style={{ color: 'red', fontSize: '12px' }}>{ticketData.partialCancellationAllowed == "true" ? `*Partial cancellation allowed` : `*Partial cancellation not allowed`}</p>
      <div className="booking-flight-details-info">
        <div className="flight-deatils-text">
          <h5>Terms and Conditions</h5>
        </div>
        <div className="termCon_wrapper">
          <p>
            1. TrusGo* is ONLY a bus ticket agent. It does not operate bus
            services of its own. In order to provide a comprehensive choice of
            bus operators, departure times and prices to customers, it has tied
            up with many bus operators. TrusGoadvice to customers is to choose
            bus operators they are aware of and whose service they are
            comfortable with{" "}
          </p>
          <div className="description">
            <p className="title">TrusGo responsibilities include:</p>
            <p>
              (1) Issuing a valid ticket (a ticket that will be accepted by the
              bus operator) for its network of bus operators
            </p>

            <p>(2) Providing refund and support in the event of cancellation</p>
            <p>
              (3) Providing customer support and information in case of any
              delays / inconvenience
            </p>
            <p className="title"> TrusGo responsibilities do NOT include:</p>
            <p>(1) The bus operator's bus not departing / reaching on time.</p>
            <p> (2) The bus operator's employees being rude. </p>
            <p>
              (3) The bus operator's bus seats etc not being up to the
              customer's expectation.
            </p>
            <p>
              (4) The bus operator canceling the trip due to unavoidable
              reasons.
            </p>
            <p>
              (5) The baggage of the customer getting lost / stolen / damaged.
            </p>
          </div>
          <p>
            2. The departure time mentioned on the ticket are only tentative
            timings. However the bus will not leave the source before the time
            that is mentioned on the ticket.
          </p>
          <p>
            3. Passengers are required to furnish the following at the time of
            boarding the bus:
          </p>
          <div className="description">
           
            <p>
              (1) A copy of the ticket (A print out of the ticket or the print
              out of the ticket e-mail).
            </p>

            <p> (2) A valid identity proof </p>
            <p>Failing to do so, they may not be allowed to board the bus.</p>
          </div>
          <p>
            4. In case one needs the refund to be credited back to his/her bank
            account, please write your cash coupon details to * The discount
          </p>

          <p>
            5. Delivery charges (if any), will not be refunded in the event of
            ticket cancellation.
          </p>

          <p>
            6. In case a booking confirmation e-mail and sms gets delayed or
            fails because of technical reasons or as a result of incorrect
            e-mail ID / phone number provided by the user etc, a ticket will be
            considered 'booked' as long as the ticket shows up on the
            confirmation page of TrusGo
          </p>
          <p>
            7. We don’t cover the insurance of the travellers.
          </p>
          {CancellationPolicy(ticketData.cancellationPolicy)}
        </div>
      </div>
    </div>
  );
};

export default TicketBus;
