import React, { useState, useEffect } from "react";
import EncHelper from "../../helpers/EncHelper";
import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../../src/helpers/ApiClient";

export const AdminAccessContext = React.createContext();
const useStateWithEnc = (key) => {
  const [accessList, setAccessList] = useState(EncHelper.getItem(key) || null);

  return [accessList, setAccessList];
};

const useStateWithLocalStorage = (key) => {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem(key) || null
  );

  return [accessToken, setAccessToken];
};

export function AdminAccessProvider({ children }) {
  const [accessList, setAccessList] = useStateWithEnc("access_screens");
  const [accessToken, setAccessToken] = useStateWithLocalStorage("accessToken");
  const [screenMapList, setScreenMapList] = useState([]);
  const { user } = useAuthContext();

  // useEffect(() => {
  //   getScreenMapList();
  // }, []);

  // const getScreenMapList = () => {
  //   let page = {
  //     page: 0,
  //   };
  //   ApiClient.post("codes/getscreenmapping/list", page)
  //     .then((res) => {
  //       if (res.isSuccess) {
  //         let data = res.results.map((item) => {
  //           return {
  //             ...item,
  //           };
  //         });
  //         setScreenMapList(data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };
  return (
    <AdminAccessContext.Provider
      value={{ accessList, setAccessList, accessToken, setAccessToken }}
    >
      {children}
    </AdminAccessContext.Provider>
  );
}
