import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  message,
  Popconfirm,
  Row,
  Table,
  Select,
  Radio,
  Switch,
  InputNumber,
  DatePicker,
} from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import { exportExcel } from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
const { Content } = Layout;

const SiteAdmin = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [userForm] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [siteAdminList, setSiteAdminList] = useState([]);
  const [siteAdminListN, setSiteAdminListN] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [membershipList, setMembershipList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [currentId, setCurrentId] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [siteAdminN, setNSiteAdmin] = useState("");
  const [ip, setIp] = useState(false);

  useEffect(() => {
    getMembershipList();
    getRolesList();
    getSiteAdminList({
      page: 0, add: 0,
    });
  }, []);

  const getRolesList = () => {
    ApiClient.get("codes/getroles")
      .then((res) => {
        if (res.isSuccess) {
          let data = res.data;
          setRolesList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getMembershipList = () => {
    ApiClient.get("codes/getmembership")
      .then((res) => {
        if (res.isSuccess) {
          let data = res.data;
          setMembershipList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getFilterSiteAdminList = (value) => {
    let obj = {
      filter: {
        id: value.userId,
        email: value.email,
        phone: value.phone,
        isActive: true,
        companyName: value.companyName,
      },
      page: 0,
    };
    if (
      !obj.filter.id &&
      !obj.filter.email &&
      !obj.filter.phone &&
      !obj.filter.isActive &&
      !obj.filter.companyName
    ) {
      delete obj.filter;
    }
    getSiteAdminList(obj);
  };


  const add = (val) => {
    // { console.log(siteAdminN,val?.username, "admin456") }
    setNSiteAdmin(val?.username);
    // { console.log(siteAdminN, "admin123") }
    ApiClient.post("user/register", val)
      .then((response) => {
        return response;
      })
      .then((response) => {
        if (response.isSuccess) {
          setModalVisible(false);
          // setIp(true);
          // { console.log(response, "resp-user") }
          message.success("Successfully added membership", 3);
          getSiteAdminList({
            page: 0, add: 1,
          });
        } else if (!response.isSuccess) {
          message.error("Membership Already existed", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // const addIp = (obj) => {
  //   // const filteredAdmins = siteAdminListN?.filter((admin) => {
  //   //   console.log(admin, "admin", admin.username === siteAdminN);
  //   //   return admin.username === siteAdminN;
  //   // });
  //   // { console.log(filteredAdmins, siteAdminListN, siteAdminN, obj, "values") }
  //   const ReqObj = {
  //     userId: filteredAdmins?.userId,
  //     ipAddress: filteredAdmins?.registeredIP,
  //   }
  //   ApiClient.post("user/useripaddress/list", ReqObj)
  //     .then((res) => {
  //       if (res.isSuccess) {
  //         // let data = res.results.map((item, index) => {
  //         //   return {
  //         //     SNo: index + 1,
  //         //     ...item,
  //         //   };
  //         // });
  //         // setUserIpAdressList(data);
  //         // setUserIPConfigTableList(data);
  //         // setClients(data);
  //         // setNSiteAdmin("");
  //         console.log("Ip Configured");
  //       } else message.error(res.message, 3);
  //     })
  //     .catch((error) => {
  //       message.error(error.message ?? "Failed To get Ip", 3);
  //       console.error(error);
  //     });
  // };
  const getSiteAdminList = (obj) => {
    ApiClient.post("user/getuserslist/list", obj)
      .then((res) => {
        if (res.isSuccess) {
          let data = res.results.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              // CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              // ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          setSiteAdminList(data);
          setTableList(data);

          // console.log(ip, obj, data, "siteadminlist");
          // if (ip || obj.add === 1) {
          //   setSiteAdminListN(data);
          //   addIp(obj);
          //   console.log("iam commingV");
          // }

        }
      })
      .catch((e) => {
        console.log("api error", e);
      });
  };

  const handleModal = () => {
    if (showUpdateBtn) {
      setShowUpdateBtn(false);
      form.resetFields();
    }
    setModalVisible(true);
  };

  // const deleteAdmin = (Record) => {
  //   let Id = Record.id;
  //   ApiClient.delete("codes/deletemembership/" + Id)
  //     .then((res) => {
  //       if (res.isSuccess) {
  //         message.success("Site User Deleted  successfully", 3);
  //         getSiteAdminList();
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };
  const updateSiteAdmin = (val) => {
    ApiClient.put(`user/updateprofile/${currentId.id}`, {}, val).then(
      (resp) => {
        if (resp.isSuccess) {
          getSiteAdminList({
            page: 0, add: 0,
          });
          setModalVisible(false);
          message.success("Membership Updated successfully", 3);
          setCurrentId(-1);
        }
      }
    );
  };

  const submitForm = (val) => {
    let obj = {
      ...val,
    };
    if (showUpdateBtn) updateSiteAdmin(obj);
    else {
      add(obj);
      // addIp(obj);
    }
  };

  const tableActions = (currentRecord, data) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord, data)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        {/* <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure want to delete?"
            onConfirm={() => deleteAdmin(data)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div> */}
      </div>
    );
  };

  const editCurrentItem = (id, data) => {
    setShowUpdateBtn(true);
    setCurrentId(id);
    form.setFieldsValue({
      ...data,
    });
    setModalVisible(true);
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };

  const searchData = () => {
    setShowSearchBox(true);
  };

  const columns = [
    //{
    // title: "Sr. No.",
    //dataIndex: "SNo",
    //key: "SNo",
    //sorter: (a, b) => a.SNo - b.SNo,
    //},
    {
      title: "CName",
      dataIndex: "companyName",
      key: "name",
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "name",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },

    {
      title: "User Name",
      dataIndex: "username",
      key: "name",
      sorter: (a, b) => a.username.localeCompare(b.username),
    },

    {
      title: "Mobile",
      dataIndex: "phone",
      key: "name",
      sorter: (a, b) => a.phone.localeCompare(b.phone),
    },
    {
      title: "Role Type",
      dataIndex: "role",
      sorter: (a, b) => a.role.localeCompare(b.role),
    },

    {
      title: "Membership",
      dataIndex: "membership",
      sorter: (a, b) => a.membership.localeCompare(b.membership),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      sorter: (a, b) => a.balance.localeCompare(b.balance),
      // render:(balance)=>{
      //           balance=balance.toString();
      //           balance=balance.slice(0,(balance.indexOf("."))+2+1)
      //           return Number(balance)
      // }
      render: (balance) => Number.parseFloat(balance).toFixed(2)
    },

    {
      title: "Status",
      dataIndex: "isActive",
      render: (isActive, record) => (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="InActive"
          defaultChecked={isActive}
          onChange={(val) => updateStatus(val, record)}
        />
      ),
    },
    {
      title: "Actions",
      render: (id, data) => tableActions(id, data),
    },
  ];
  const updateStatus = (val, record) => {
    var Id = record.id;
    let data = {
      id: Id,
      isActive: val,
    };
    ApiClient.put("user/updatestatus", {}, data)
      .then((res) => {
        if (res.status == 200) {
          message.success("Updated  successfully", 3);
          getSiteAdminList({
            page: 0, add: 0,
          });
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(siteAdminList);
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
    setTableList(siteAdminList);
  };

  const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

  const handleExcel = () => {
    let excelData = tableList;
    // console.log(excelData, "excelData");
    excelData = excelData.map((item, i) => ({
      SNo: i + 1,
      Username: item.username,
      Role: item.role,
      Membership: item.membership,
      Email: item.email,
      FirstName: item.firstName,
      LastName: item.lastName,
      Mobile: item.phone,
      ApiType: item.apiType,
      Address: item.address,
      State: item.state,
      Country: item.country,
      CompanyName: item.companyName,
      // CreatedDate: item.CreatedDate,
      // ModifiedDate: item.ModifiedDate,
    }));

    exportExcel(excelData, "Site Admins");
  };

  return (
    <div className="outer-background-all">
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false} style={{ borderRadius: "25px" }}>
              <div className="card-add-heading">
                <Form
                  layout="vertical"
                  name="addBalanceForm"
                  form={userForm}
                  onFinish={getFilterSiteAdminList}
                >
                  <Row gutter={12} align="middle">
                    <Col md={5} xs={24}>
                      <AutoCompleteSelect
                        formItemProps={{
                          label: "User",
                          name: "userId",
                          rules: [
                            {
                              required: true,
                              message: "Please enter User Name",
                            },
                          ],
                        }}
                        selectProps={{
                          placeholder: "Please Enter User Name",
                        }}
                        api={"user/userautosuggestion/"}
                        keytext="name"
                        keyvalue="id"
                      />
                    </Col>
                    <Col md={5} xs={24}>
                      <Form.Item
                        label="Email"
                        name="email"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please enter Email",
                      //   },
                      // ]}
                      >
                        <Input
                          placeholder="Please Enter Email"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={5} xs={24}>
                      <Form.Item
                        label="Phone"
                        name="phone"
                        rules={[
                          // {
                          //   required: true,
                          //   message: "Please enter Phone",
                          // },
                          {
                            minLength: 10,
                            maxLength: 10,
                            pattern: "^[0-9]{10}$",
                            message: "Must be 10 digits",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Please select Phone"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={5} xs={24}>
                      <Form.Item
                        label="Company Name"
                        name="companyName"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please enter Company Name",
                      //   },
                      // ]}
                      >
                        <Input placeholder="Please Enter Company Name" />
                      </Form.Item>
                    </Col>

                    <Col md={4} xs={24}>
                      <Button
                        className="site-admin-btn-11"
                        key="add"
                        type="primary"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Card>
            {tableList.length > 0 ? (
              <Card bordered={false} style={{ marginTop: "1%", borderRadius: "25px" }}>
                <div className="card-add-heading">
                  <div className="rows-count">
                    <h5>View Site Admin</h5>
                    <p>{tableList.length} rows found !</p>
                  </div>
                  <div className="action-images">
                    {showSearchBox && (
                      <Input
                        autocomplete="newpassword"
                        placeholder="Search"
                        onChange={(e) => searchTableData(e)}
                        suffix={closeSearchInput}
                        style={{ padding: "0px 12px" }}
                      />
                    )}
                    &nbsp;&nbsp;
                    <img
                      src={search}
                      alt="search"
                      onClick={() => searchData()}
                    />
                    <img
                      src={excel}
                      alt="excel"
                      onClick={() => handleExcel()}
                    />
                    <img
                      src={exchange}
                      alt="exchange"
                      onClick={() => {
                        setTableList([]);
                        getSiteAdminList({
                          page: 0,
                        });
                      }}
                    />
                    <p className="add-deposit-icon" onClick={handleModal}>
                      <PlusCircleFilled />
                    </p>
                  </div>
                </div>

                <Table
                  scroll={{ x: true }}
                  bordered
                  rowKey={uniqueKey}
                  dataSource={tableList}
                  columns={columns}
                  pagination={{
                    defaultPageSize: 25,
                    showSizeChanger: true,
                    pageSizeOptions: ["25", "50", "100", "125"],
                  }}
                />
              </Card>
            ) : null}
          </div>
        </Content>
      </div>
      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>
                {showUpdateBtn ? "Update Site Admin" : "Add Site Admin"}
              </strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <Button key="add" type="primary" onClick={form.submit}>
            {showUpdateBtn ? "Update" : "Add"}
          </Button>,
        ]}
        width={1000}
      >
        <Form layout="vertical" form={form} onFinish={submitForm}>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label="Role"
                name="roleId"
                rules={[{ required: true, message: "Please enter Role" }]}
              >
                <Select placeholder="Please select Role Type">
                  {rolesList.map((item) => (
                    <Option value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Membership"
                name="membershipId"
                rules={[{ required: true, message: "Please enter membership" }]}
              >
                <Select placeholder="Please select Membership Type">
                  {membershipList.map((item) => (
                    <Option value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="User Name"
                name="username"
                rules={[{ required: true, message: "Please enter username" }]}
              >
                <Input
                  autocomplete="newpassword"
                  placeholder="Enter User Name"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: "Please enter email" }]}
              >
                <Input autocomplete="newpassword" placeholder="Enter Email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Api Type"
                name="apiType"
                rules={[{ required: true, message: "Please enter Api type" }]}
              >
                <Select placeholder="Please select api type">
                  <Option value="Etravos">Etravos</Option>
                  <Option value="OwnAPI">OwnAPI </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="ISD"
                name="isd"
                rules={[{ required: true, message: "Please Enter ISD" }]}
              >
                <Input autocomplete="newpassword" placeholder="Enter ISD" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Mobile No."
                name="phone"
                rules={[{ required: true, message: "Please Mobile No" }]}
              >
                <Input
                  autocomplete="newpassword"
                  placeholder="Enter Mobile No."
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[{ required: true, message: "Please First Name" }]}
              >
                <Input
                  autocomplete="newpassword"
                  placeholder="Enter First Name"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[{ required: true, message: "Please Last Name" }]}
              >
                <Input
                  autocomplete="newpassword"
                  placeholder="Enter Last Name"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Gender"
                name="gender"
                rules={[{ required: true, message: "Please Enter Role Type" }]}
              >
                <Select placeholder="Please select Role Type">
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                  <Option value="Others">Others</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: "Please enter Title" }]}
              >
                <Input autocomplete="newpassword" placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Address"
                name="address"
                rules={[{ required: true, message: "Please enter Address" }]}
              >
                <Input autocomplete="newpassword" placeholder="Enter Address" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="City"
                name="city"
                rules={[{ required: true, message: "Please enter City Name" }]}
              >
                <Input
                  autocomplete="newpassword"
                  placeholder="Enter City Name"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="State"
                name="state"
                rules={[{ required: true, message: "Please enter State Name" }]}
              >
                <Input
                  autocomplete="newpassword"
                  placeholder="Enter State Name"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  { required: true, message: "Please enter Country Name" },
                ]}
              >
                <Input
                  autocomplete="newpassword"
                  placeholder="Enter Country Name"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Company Name"
                name="companyName"
                rules={[
                  { required: true, message: "Please enter Company Name" },
                ]}
              >
                <Input
                  autocomplete="newpassword"
                  placeholder="Enter Company Name"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Pin Code"
                name="pincode"
                rules={[{ required: true, message: "Please enter PinCode" }]}
              >
                <Input autocomplete="newpassword" placeholder="Enter PinCode" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Registered IP"
                name="registeredIP"
                rules={[
                  { required: true, message: "Please enter Registered IP" },
                ]}
              >
                <Input
                  autocomplete="newpassword"
                  placeholder="Enter Registered IP"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Active" name="isActive">
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default SiteAdmin;
