import React, { Suspense } from "react";
import "../BusResultCards/BusResultCards.scss";
import moment from "moment";
// import BusSort from "../../BusSort/BusSort";
// import BusCard from "./BusCard";

const BusSort = React.lazy(() => import('../../BusSort/BusSort'))
const BusCard = React.lazy(() => import('./BusCard'))

const BusResultCards = ({
  updateSelectedBusData,
  trips,
  busSearchResultObj,
  setBusResultsList,
  ot,
}) => {
  return (
    <div className="results-card-block">
      <Suspense fallback={<div>Loading...</div>}>
        <BusSort
          busSearchResultObj={busSearchResultObj}
          setBusResultsList={setBusResultsList}
        />
        <BusCard
          updateSelectedBusData={updateSelectedBusData}
          trips={trips}
          searchId={busSearchResultObj.data.traceId}
          otherData={{
            origin: busSearchResultObj.data.origin,
            destination: busSearchResultObj.data.destination,
            originId: busSearchResultObj.data.originId,
            destinationId: busSearchResultObj.data.destinationId,
            doj: moment(ot.doj).format("DD-MM-YYYY"),
          }}
        />
      </Suspense>
    </div>
  );
};
export default BusResultCards;
