import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  message,
  Modal,
  Popconfirm,
  Input,
  Layout,
  Radio,
  Row,
  Select,
  Table,
} from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import "./AdminUser.scss";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import ApiClient from "../../helpers/ApiClient";
import { exportExcel } from "../ExportExcel";

const { Option } = Select;
const { Content } = Layout;
const AdminUser = () => {
  const [form] = Form.useForm();

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [userList, setUserList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [userId, setUserId] = useState(-1);
  const [rolesList, setRolesList] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const showModal = () => {
    if (editMode) {
      setEditMode(false);
      setUserId(-1);
      form.resetFields();
    }
    setModalVisible(true);
  };

  const updateUser = (data) => {
    data.DailingCode = "91";

    ApiClient.put("admin/user/" + userId, {}, data)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          getAdminUsers();
          message.success(" Updated SuccessFully", 3);

          form.resetFields();
          setModalVisible(false);
          setEditMode(false);
          setUserId(-1);
        } else if (response.status === 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFinish = (values) => {
    if (editMode) {
      updateUser(values);
    } else {
      submitAdminUser(values);
    }
  };

  const submitAdminUser = (data) => {
    data.DialingCode = "91";

    ApiClient.post("admin/user", data)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          if (response.UserId.code) {
            message.error(" Eror Adding user, Duplicate Email or Mobile", 3);
          } else {
            message.success(" Added SuccessFully", 3);
            getAdminUsers();
            form.resetFields();
            setModalVisible(false);
          }
        } else if (response.status === 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const closeSearch = () => {
    setShowSearchBox(false);
    setTableList(userList);
  };
  const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

  const getRolesList = async () => {
    await ApiClient.get("admin/role")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) => item.Level === 1);
          console.log("RolesList", data);
          setRolesList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getAdminUsers = async () => {
    await ApiClient.get("admin/user")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });

          setUserList(data);
          setTableList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getRolesList();
    getAdminUsers();
  }, []);

  const deleteAdminUser = (rec) => {
    ApiClient.delete("admin/user/" + rec.UserID)
      .then((res) => {
        if (res.status === 200) {
          message.success("Deleted Successfully");
          getAdminUsers();
        } else {
          message.error("Error Deleting user");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editRecord(rec)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteAdminUser(rec)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const editRecord = (rec) => {
    setEditMode(true);
    setUserId(rec.UserID);

    let userData = rec.userDetails;
    let formData = {
      Role: parseInt(rec.Role),
      FirstName: userData.FirstName,
      LastName: userData.LastName,
      Email: rec.Email ? rec.Email : "",
      Mobile: rec.Mobile,
      Gender: userData.Gender,
      Status: rec.Status,
    };
    form.setFieldsValue({ ...formData });
    setModalVisible(true);
  };

  const getStatus = (status) => {
    if (status === 1) {
      return (
        <span style={{ color: "#008000", fontWeight: "600" }}>Active</span>
      );
    } else {
      return (
        <span style={{ color: "#ff0000", fontWeight: "600" }}>Inactive</span>
      );
    }
  };

  const columns = [
    {
      title: "Sr. No.",

      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    {
      title: "Name",
      dataIndex: "userDetails",
      render: (text) =>
        text
          ? text.FirstName
            ? text.FirstName
            : "" + " " + text.LastName
            ? text.LastName
            : ""
          : "",
      sorter: (a, b) =>
        a.FirstName && b.FirstName
          ? a.FirstName.localeCompare(b.FirstName)
          : -2,
    },
    {
      title: "Email",
      dataIndex: "Email",
      // render: (text) => (text ? (text.Email ? text.Email : "") : ""),
      // sorter: (a, b) =>
      //   a.Email && b.Email ? a.Email.localeCompare(b.Email) : -2,
      sorter:(a,b)=>a.Email-b.Email
    },
    {
      title: "Mobile",
      dataIndex: "Mobile",
      sorter: (a, b) => a.Mobile - b.Mobile,
    },
    {
      title: "Role",
      dataIndex: "Role",
      render: (data) => {
        let roleName = data;
        rolesList.forEach((role) => {
          if (role.RoleID === data) {
            roleName = role.Name;
          }
        });
        return roleName;
      },
    },
    {
      title: "Status",
      dataIndex: "Status",
      render: (status) => getStatus(status),
    },
    {
      dataIndex: "actions",
      title: "Actions",
      render: (_, rec) => tableActions(rec),
    },
  ];
  const searchData = () => {
    setShowSearchBox(true);
  };
  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(userList);
    }
  };

  const handleExcel = () => {
    let excelData = tableList;
    excelData = excelData.map((item, i) => ({
      SNo: i + 1,
      Role: item.Role,
      FirstName: item.userDetails.FirstName ? item.userDetails.FirstName : "",
      LastName: item.userDetails.LastName ? item.userDetails.LastName : "",
      Email: item.Email ? item.Email : "",
      Mobile: item.Mobile ? item.Mobile : "",
      Gender:
        item.userDetails.Gender != null
          ? item.userDetails.Gender === 1
            ? "Male"
            : "Female"
          : "",
      Status: item.Status === 1 ? "Active" : "Inactive",
    }));
    exportExcel(excelData, "Admin_Users");
  };
  let key = 0;
  const keyGenerate = () => {
    return key++;
  };

  return (
    <Layout>
      <div className="manage-markup-section">
        <Content className="admin-container">
          <div className="card-bt-gap">
            <Card bordered={false}>
              <div className="card-add-heading">
                <div className="rows-count">
                  <h5>Admin Users</h5>
                  <p>{tableList.length} rows found !</p>
                </div>
                <div className="action-images">
                  {showSearchBox && (
                    <Input
 autocomplete="newpassword" 
                      placeholder="Search"
                      onChange={(e) => searchTableData(e)}
                      suffix={closeSearchInput}
                      style={{ padding: "0px 12px" }}
                    />
                  )}
                  &nbsp;&nbsp;
                  <img src={search} alt="search" onClick={() => searchData()} />
                  <img src={excel} alt="excel" onClick={handleExcel} />
                  <img
                    src={exchange}
                    alt="exchange"
                    onClick={() => {
                      setTableList([]);
                      getAdminUsers();
                    }}
                  />
                  <p
                    className="add-deposit-icon"
                    onClick={() => {
                      form.resetFields();
                      showModal();
                    }}
                  >
                    <PlusCircleFilled />
                  </p>
                </div>
              </div>

              <Table
                bordered
                scroll={{ x: true }}
                dataSource={tableList}
                columns={columns}
                pagination={{
                  defaultPageSize: 25,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50", "100", "125"],
                }}
                rowKey={keyGenerate}
              />
            </Card>
          </div>
        </Content>
      </div>
      <Modal
        title="Add User"
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        width={"800px"}
        footer={[
          <>
            <Button key="add" type="primary" onClick={form.submit}>
              {editMode ? "Update" : "Add"}
            </Button>
            <Button key="submit" type="danger">
              Clear
            </Button>
          </>,
        ]}
      >
        <Form layout="vertical" name="basic" form={form} onFinish={onFinish}>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item label="Role" rules={[{ required: true }]} name="Role">
                <Select
 autocomplete="newpassword"  placeholder="Select Role">
                  {rolesList.map((item, i) => (
                    <Option key={i} value={item.RoleID}>
                      {item.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name="FirstName"
                label="First Name"
                rules={[
                  { required: true, message: "Please input your firstname!" },
                ]}
              >
                <Input
 autocomplete="newpassword"  />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                name="LastName"
                label="Last Name"
                rules={[
                  { required: true, message: "Please input your lastname!" },
                ]}
              >
                <Input
 autocomplete="newpassword"  />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item name="Email" label="Email">
                <Input
 autocomplete="newpassword"  />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                name="Mobile"
                label="Mobile Number"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                  {
                    minLength: 10,
                    maxLength: 10,
                    pattern: "^[0-9]{10}$",
                    message: "Must be 10 digits",
                  },
                ]}
              >
                <Input
 autocomplete="newpassword"  addonBefore="+91" style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item label="Gender" name="Gender">
                <Radio.Group>
                  <Radio value={1}>Male</Radio>
                  <Radio value={2}>Female</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item label="Status" name="Status">
                <Radio.Group
                  rules={[{ required: true, message: "Please choose Status!" }]}
                >
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Layout>
  );
};

export default AdminUser;
