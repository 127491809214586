import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Form,
  Row,
  Modal,
  message,
  Spin,
} from "antd";

import TicketBus from "./TicketBus";
import queryString from "query-string";
import APIClient from "../../helpers/BusApiClient";
import getBusTicketPrice from "./busHelper";
import TicketSidebar from "./TicketSidebar";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import "./busTicketScreen.scss";
import { useHistory } from "react-router-dom";
import ApiClient from "../../helpers/ApiClient";
import { GlobalStatesContext } from "../../common/providers";

const { Panel } = Collapse;

const BusTicketScreen = () => {
  const [form] = Form.useForm();
  let history = useHistory();
  const [cancelForm] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const ticketSearchParams = queryString.parse(window.location.search);
  const [ticketCancelErrorMsg, setTicketCancelErrorMsg] = useState(null)

  const {
    state: { busTicketData },
    updateBusTicketData,
  } = useContext(GlobalStatesContext);

  const initialState = {
    traceId: "",
    status: "",
    bus: {},
    stopB: {},
    contact: {},
    pax: [],
  };
  const initialPreState = {
    traceId: "",
    tickets: [],
  };

  const [ticketData, setTicketData] = useState(initialState);
  const [preCancelData, setPreCancelData] = useState(initialPreState);
  const [loadingTicket, setLoadingTicket] = useState(true);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  let { grandTotal } = getBusTicketPrice(ticketData);

  const getTicketDetails = (tktNo) => {
    setLoadingTicket(true);
    // let obj = {
    //   tktNo: tktNo,
    // };
    // APIClient.post("bus/ticket", obj)
    //   .then((res) => {
    //     if (res) {
    //       setTicketData(res);
    //       setLoadingTicket(false);
    //       // if (res.data.bookingStatus != "Cancelled") {
    //       //   setTicketData(res.data);
    //       //   setLoadingTicket(false);
    //       // } else {
    //       //   setTicketData(res.data);
    //       //   //message.error("Ticket has cancelled already unable view", 3);
    //       //   setLoadingTicket(false);
    //       // }
    //     }
    //   })
    //   .catch((error) => {
    //     setLoadingTicket(false);
    //     console.error(error);
    //   });

    ApiClient.get(`booking/busbookingdetails/${tktNo}`)
      .then((res) => {
        if (res) {
          // setTicketData(res);
          // setLoadingTicket(false);
          if (res.isSuccess == false) {
            setTicketData(res.data);
            setLoadingTicket(false);
            updateBusTicketData(res.data)
          } else {
            //setTicketData(res.data);
            message.error(res.message ?? "Failed", 3);
            setLoadingTicket(false);
          }
        }
      })
      .catch((error) => {
        setLoadingTicket(false);
        console.error(error);
      });

  };

  const cancelTicket = () => {
    setModalVisible(true);
  };

  const CancellationPolicy = (data) => {
    const policyStringArray = data.split(";");
    return (
      <div className="cancellation-wrapper">
        <ul>
          <li>
            <p>
              <b> Time before Departure </b>
            </p>
            <p>
              <b>Refund %</b>
            </p>
          </li>
          {policyStringArray.map((data, index) => {
            let val = data.split(":");
            if (index === 0) {
              return (
                <li>
                  <p>{`With in ${val[1]} Hrs`} </p>
                  <p>{`${val[2]}%`}</p>
                </li>
              );
            } else if (index === policyStringArray.length - 1) {
              return (
                <li>
                  <p>{`Above ${val[0]} Hrs`} </p>
                  <p>{`${val[2]}%`}</p>
                </li>
              );
            } else {
              return (
                <li>
                  <p>{`Between ${val[0]} to ${val[1]} Hrs`} </p>
                  <p>{`${val[2]}%`}</p>
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  };

  const submitPreCancelForm = (val) => {

    // if (ticketData.emailId == "" && ticketData.bookingRefNo == "") {
    //   return;
    // }

    let isCancellable = true

    if (ticketData?.partialCancellationAllowed == false) {
      if (ticketData?.passangers?.length !== val.seatNo.length) {
        isCancellable = false
        message.error("Partial Cancellation is Not allowed", 3);
      }
    }

    if (isCancellable) {

      setLoading(true);

      let data = {
        seats: val.seatNo,
        // tktNo: ticketSearchParams.tktNo,
        tktNo: ticketSearchParams.refNo,
      };

      APIClient.post("bus/precancel/", data)
        .then((res) => {
          setLoading(false);
          if (res) {
            if (res?.tickets?.length > 0) {
              if (res?.error?.code == 200) {
                message.error("Failed To Cancelled The Ticket", 7);
              } else {
                setPreCancelData(res);
                setModalVisible(false);
                form.resetFields();
                setCancelModalVisible(true);

                // /** Call the Cancel API If the PreCancel API is SUCCESS */
                // APIClient.post("bus/cancel/", data)
                //   .then((res) => {
                //     setLoading(false);
                //     if (res.status === "CANCELLED") {
                //       if (res?.error?.code == 200) {
                //         setTicketCancelErrorMsg(res?.error?.desc)
                //       }
                //       else {
                //         message.success(
                //           `Ticket Cancelled Successfully`,
                //           10
                //         );
                //         setModalVisible(false);
                //         //history.push("/admin/busbooking");

                //       }

                //     }
                //   })
                //   .catch((error) => {
                //     setLoading(false);
                //     setTicketCancelErrorMsg(error.message)
                //     console.error(error);
                //   });
              }
            } else message.error("Failed To Cancelled The Ticket", 7);
          } else message.error("Failed To Cancelled The Ticket", 7);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.message ?? "Failed To Cancelled The Ticket", 7);
          console.error(error);
        });

    }
  };
  const submitCancelTicket = () => {
    setLoading(true);
    let data = {
      // tktNo: ticketSearchParams.tktNo,
      tktNo: ticketSearchParams.refNo,
      seats: preCancelData?.tickets?.[0]?.seats,
    };
    APIClient.post("bus/cancel/", data)
      .then((res) => {
        setLoading(false);
        if (res.status === "CANCELLED") {
          if (res?.error?.code == 200) {
            setTicketCancelErrorMsg(res?.error?.desc)
          }
          message.success(
            `Ticket Cancelled Successfully For Seat(s): ${res?.seats?.join(',')} `,
            10
          );
          setCancelModalVisible(false);
          //history.push(`/admin/bus_ticket?refNo=${ticketSearchParams.refNo}`);
          getTicketDetails(ticketSearchParams.refNo);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  // useEffect(() => {
  //   getTicketDetails(ticketSearchParams.tktNo);
  // }, [ticketSearchParams.tktNo]);

  useEffect(() => {
    getTicketDetails(ticketSearchParams.refNo);
  }, [ticketSearchParams.refNo]);

  return (
    <div className="flight-ticket-collapse">
      <Card>
        {loadingTicket ? (
          <div className="fligh-ticket-container">
            <div style={{ textAlign: "center" }} className="flight-ticket">
              <Spin indicator={antIcon} tip="Loading..." />
            </div>
          </div>
        ) : (
          <div className="fligh-ticket-container">
            <div className="flight-ticket">
              <Row gutter={[32, 16]} className="ticket-row">
                <Col md={18} className="ticket-coloum">
                  <TicketBus ticketData={ticketData} />
                </Col>

                <Col xs={24} md={6} className="tic-info-flight">
                  <div className="web-tic-info">
                    <TicketSidebar
                      ticketData={ticketData}
                      ticketSearchParams={ticketSearchParams}
                      type="Bus"
                      onCancelTicket={cancelTicket}
                    />
                  </div>

                  <div className="mobile-tic-info">
                    <Collapse accordion>
                      <Panel header="Manage Tickets" key="1">
                        <TicketSidebar
                          ticketData={ticketData}
                          ticketSearchParams={ticketSearchParams}
                          type="Bus"
                          onCancelTicket={cancelTicket}
                        />
                      </Panel>
                    </Collapse>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Card>

      <Modal
        title="PreCancel Ticket"
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              onClick={form.submit}
              loading={loading}
            >
              Pre Cancel Ticket
            </Button>
          </div>,
        ]}
        width={"600px"}
      >
        {/* {ticketData.bookingStatus === "Booked" ||
        ticketData.bookingStatus === "Cancelled" ||
        ticketData.bookingStatus === "PartiallyCancelled" ? ( */}
        <div className="wrapper">
          <p>
            <b>Ticket No</b> : {ticketData?.ticketId}
          </p>
          <p>
            {/* <b>Journey Details</b> : {ticketData.bus.src} <b>TO </b> */}
            <b>Journey Details</b> : {ticketData?.sourceName} <b>TO </b>
            {ticketData.destinationName} on{" "}
            {/* {moment(ticketData.bus.timeD).format("MMM, DD-YYYY")} */}
            {(ticketData.departureTime)}
            ( {ticketData.operatorName} )
          </p>
          <p>
            {/* <b>Total Fare</b> : Rs {grandTotal} */}
            <b>Total Fare</b> : Rs {ticketData.publishedFare}
          </p>
          {/* <p className="text-center">
            <b>Cancellation Policy</b>
          </p>
          {CancellationPolicy(ticketData.cancellationPolicy)} */}
          <p>
            <b>Select Seat No.</b>
          </p>
          <Form layout="inline" form={form} onFinish={submitPreCancelForm}>
            <Form.Item
              label=" Seat No."
              name="seatNo"
              rules={[{ required: true, message: "Please Select Seat No" }]}
            >
              <Checkbox.Group>
                <Row>
                  {ticketData?.passangers?.length > 0
                    ? ticketData.passangers.map((item, i) => (
                      <Checkbox key={i} value={item.seatNo}>
                        {item.seatNo}
                      </Checkbox>
                    ))
                    : ""}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Form>
          <p>{ticketData?.partialCancellationAllowed == false && ticketData?.passangers?.length > 1 ? "Partial Cancellation is Not allowed" : null}</p>
        </div>
        {/* ) : null} */}
      </Modal>

      <Modal
        title="Cancel Ticket"
        className="promo-modal-header"
        visible={cancelModalVisible}
        onOk={(e) => setCancelModalVisible(false)}
        onCancel={(e) => setCancelModalVisible(false)}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              onClick={submitCancelTicket}
              loading={loading}
            >
              Do you confirm to Cancel Ticket
            </Button>
          </div>,
        ]}
        width={"600px"}
      >
        {preCancelData?.tickets.length > 0 ? (
          <div className="wrapper">
            {ticketCancelErrorMsg != null ? (<p style="color:red;">Error:{ticketCancelErrorMsg}</p>) : null}
            <Row>
              <Col md={24} xs={24}>
                <b>Ticket No</b> : {ticketData.ticketId}
              </Col>
              <Col md={12} xs={24}>
                <b> Ticket Type</b> : {preCancelData?.tickets[0].type}
              </Col>
              <Col md={12} xs={24}>
                <b> Ticket Seats</b> :{" "}
                {
                  ticketData?.partialCancellationAllowed == false ? (
                    <span>
                      {preCancelData?.tickets[0].seats.map((item) => item).join(',')}
                    </span>

                  ) : (<span>
                    {preCancelData?.tickets.map((item) => item.seats[0]).join(',')}
                  </span>)
                }
                {/* {preCancelData?.tickets[0].seats.map((item) => (
                  <span>{item}</span>
                ))} */}
                {/* <span>
                  {preCancelData?.tickets.map((item) => item.seats[0]).join(',')}
                </span> */}

              </Col>
              <Col md={12} xs={24}>
                <b>Total Fare</b> : Rs {ticketData.publishedFare}
              </Col>
              <Col md={12} xs={24}>
                <b>Charge</b> : Rs {preCancelData?.tickets.reduce((acc, curVal) => parseFloat(acc) + parseFloat(curVal.charge), 0)}
              </Col>
              <Col md={12} xs={24}>
                <b>Refund</b> : Rs {preCancelData?.tickets.reduce((acc, curVal) => parseFloat(acc) + parseFloat(curVal.refund), 0)}
              </Col>
            </Row>
          </div>
        ) : null}
      </Modal>
    </div>
  );
};
export default BusTicketScreen;
