import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  message,
  Popconfirm,
  Row,
  Table,
  Select,
  InputNumber,
  Radio,
} from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import { exportExcel } from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
import { useAuthContext } from "../../common/providers/AuthProvider";

const { Content } = Layout;

const BusCommissions = () => {
  const { Option } = Select;
  const { user } = useAuthContext();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [busCommList, setBusCommList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [currentId, setCurrentId] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [membershipList, setMembershipList] = useState([]);
  const [operatorsList, setOperatorsList] = useState([]);
  const [columns, setColumns] = useState([])


  useEffect(() => {
    user.role.toUpperCase() === "ADMIN" ? setColumns(admincolumns) : setColumns(siteAdminColumns)
    getBusCommList();
    getMembershipList();
    getOperatorsList();
  }, []);

  const getOperatorsList = () => {
    let page = { page: 0 };
    ApiClient.post("operators/getoperator/list", page)
      .then((res) => {
        if (res.isSuccess) {
          let data = res.results.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setOperatorsList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getMembershipList = () => {
    ApiClient.get("codes/getmembership")
      .then((res) => {
        if (res.isSuccess) {
          let data = res.data;
          setMembershipList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getBusCommList = () => {
    let obj = {
      filter: {
        membershipId: user ? user.membershipId : null,
        id: null,
        membershipId: user.role.toUpperCase() === "ADMIN" ? null : user.membershipId,
        membershipName: user.role.toUpperCase() === "ADMIN" ? null : user.membership,
        isActive: true
      },
      page: 0,
    };
    ApiClient.post("commission/getbuscommission/list", obj)
      .then((res) => {
        if (res.isSuccess) {
          let data = res.results.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setBusCommList(data);
          setTableList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const add = (val) => {
    ApiClient.post("commission/savebuscommission", val)
      .then((response) => {
        return response;
      })
      .then((response) => {
        if (response.isSuccess) {
          setModalVisible(false);
          message.success("Successfully added Commission", 3);
          getBusCommList();
          form.resetFields();
        } else if (!response.isSuccess) {
          message.error("BusCommissions Already existed", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleModal = () => {
    if (showUpdateBtn) {
      setShowUpdateBtn(false);
      form.resetFields();
    }
    setModalVisible(true);
  };

  const deleteBusCommList = (Record) => {
    let Id = Record.id;

    ApiClient.delete("commission/deletebuscommission/" + Id)
      .then((res) => {
        if (res.isSuccess) {
          message.success("BusCommissions Deleted  successfully", 3);
          getBusCommList();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const updateBusCommList = (val) => {
    ApiClient.put(
      `commission/updatebuscommission/${currentId.id}`,
      {},
      val
    ).then((resp) => {
      if (resp.isSuccess) {
        getBusCommList();
        setModalVisible(false);
        message.success("BusCommissions Updated successfully", 3);
        setCurrentId(-1);
      }
    });
  };

  const submitForm = (val) => {
    let obj = {
      ...val,
    };
    if (showUpdateBtn) updateBusCommList(obj);
    else add(obj);
  };

  const tableActions = (currentRecord, Name) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord, Name)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure want to delete?"
            onConfirm={() => deleteBusCommList(currentRecord)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const editCurrentItem = (id, data) => {
    setShowUpdateBtn(true);
    setCurrentId(id);
    form.setFieldsValue({
      ...data,
    });
    setModalVisible(true);
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };
  const getType = (type) => {
    switch (type) {
      case 1:
        return "Fixed ";
      case 2:
        return "Percentage ";
      default:
        return "";
    }
  };
  const searchData = () => {
    setShowSearchBox(true);
  };

  const admincolumns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    {
      title: "Membership",
      dataIndex: "membership",
      // sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
    },
    {
      title: "Operator",
      dataIndex: "operaterName",
      // sorter: (a, b) => a.name.localeCompare(b.name),
    },

    {
      title: "Commission Type",
      dataIndex: "commissionType",
      sorter: (a, b) => a.commissionType - b.commissionType,
      render: (commissionType) => getType(commissionType),
    },

    {
      title: "Commission",
      dataIndex: "commission",
      sorter: (a, b) => a.SNo - b.SNo,
    },

    {
      title: "Rtc",
      dataIndex: "isRtc",
      render: (isRtc) => isRtc ? "True" : "False"
      // sorter: (a, b) => a.name.localeCompare(b.name),
    },

    {
      title: "Mark Type",
      dataIndex: "markType",
      sorter: (a, b) => a.markType - b.markType,
      render: (markType) => getType(markType),
    },
    {
      title: "Mark Up",
      dataIndex: "markUp",
      sorter: (a, b) => a.markUp - b.markUp,
    },
    {
      title: "Actions",
      render: (id, name) =>
        user?.roleId === 1 ? tableActions(id, name) : null,
    },
  ];

  const siteAdminColumns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    // {
    //   title: "User Name",
    //   dataIndex: "userId",
    //   // sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
    // },
    {
      title: "Operator",
      dataIndex: "operaterName",
      // sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Commission Type",
      dataIndex: "commissionType",
      sorter: (a, b) => a.commissionType - b.commissionType,
      render: (commissionType) => getType(commissionType),
    },
    {
      title: "Commission",
      dataIndex: "commission",
      sorter: (a, b) => a.SNo - b.SNo,
    },

    // {
    //   title: "Rtc",
    //   dataIndex: "isRtc",
    //   sorter: (a, b) => a.name.localeCompare(b.name),
    // },
    // {
    //   title: "Mark Up",
    //   dataIndex: "markUp",
    //   sorter: (a, b) => a.markUp - b.markUp,
    // },
    // {
    //   title: "Mark Type",
    //   dataIndex: "markType",
    //   sorter: (a, b) => a.markType - b.markType,
    //   render: (markType) => getType(markType),
    // },

    // {
    //   title: "Actions",
    //   render: (id, name) =>
    //     user?.roleId === 1 ? tableActions(id, name) : null,
    // },
  ];

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(busCommList);
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
    setTableList(busCommList);
  };

  const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

  const handleExcel = () => {
    let excelData = tableList;
    if (user.role.toUpperCase() === "ADMIN") {
      excelData = excelData.map((item, i) => ({
        SNo: i + 1,
        Membership:item.membership,
        OperatorName: item.operaterName,
        CommissionType: item.commissionType,
        Commission: item.commission,
        MarkType: item.markType,
        MarkUp: item.markUp,
        IsRtc: item.isRtc ? "True" : "False"
      }));
    } else {
      excelData = excelData.map((item, i) => ({
        SNo: i + 1,
        OperatorName: item.operaterName,
        CommissionType: item.commissionType,
        Commission: item.commission
      }));
    }


    exportExcel(excelData, "BusCommissions");
  };

  return (
    <div className="outer-background-all">
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false} style={{ borderRadius: "25px" }}>
              <div className="card-add-heading">
                <div className="rows-count">
                  <h5>View BusCommissions</h5>
                  <p>{tableList.length} rows found !</p>
                </div>
                <div className="action-images">
                  {showSearchBox && (
                    <Input
                      autocomplete="newpassword"
                      placeholder="Search"
                      onChange={(e) => searchTableData(e)}
                      suffix={closeSearchInput}
                      style={{ padding: "0px 12px" }}
                    />
                  )}
                  &nbsp;&nbsp;
                  <img src={search} alt="search" onClick={() => searchData()} />
                  <img src={excel} alt="excel" onClick={() => handleExcel()} />
                  <img
                    src={exchange}
                    alt="exchange"
                    onClick={() => {
                      setTableList([]);
                      getBusCommList();
                    }}
                  />
                  {user?.roleId === 1 ? (
                    <p className="add-deposit-icon" onClick={handleModal}>
                      <PlusCircleFilled />
                    </p>
                  ) : null}
                </div>
              </div>

              <Table
                scroll={{ x: true }}
                bordered
                rowKey={uniqueKey}
                dataSource={tableList}
                columns={columns}
                pagination={{
                  defaultPageSize: 25,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50", "100", "125"],
                }}
              />
            </Card>
          </div>
        </Content>
      </div>
      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>
                {showUpdateBtn ? "Update BusCommissions" : "Add BusCommissions"}
              </strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header bci-panel-admin-parts"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <Button key="add" type="primary" onClick={form.submit}>
            {showUpdateBtn ? "Update" : "Add"}
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm}>
          <Row gutter={12}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Membership"
                name="membershipId"
                rules={[{ required: true, message: "Please enter membership" }]}
              >
                <Select placeholder="Please select Membership Type">
                  {membershipList.map((item) => (
                    <Option value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Mark Up Type"
                name="markType"
                rules={[
                  { required: true, message: "Please enter Mark Up Type" },
                ]}
              >
                <Select placeholder="Please select Mark Up Type">
                  <Option value="Fixed">Fixed</Option>
                  <Option value="Percentage">Percentage</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Mark Up"
                name="markUp"
                rules={[{ required: true, message: "Please enter Markup" }]}
              >
                <InputNumber
                  autocomplete="newpassword"
                  placeholder="Please Enter Markup "
                  style={{ width: "100%" }}
                  min={0}
                />
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                label="Commission Type"
                name="commissionType"
                rules={[
                  { required: true, message: "Please enter Commission Type" },
                ]}
              >
                <Select placeholder="Please select Commission Type">
                  <Option value="Fixed">Fixed</Option>
                  <Option value="Percentage">Percentage</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Commission"
                name="commission"
                rules={[{ required: true, message: "Please enter Commission" }]}
              >
                <InputNumber
                  autocomplete="newpassword"
                  placeholder="Please Enter Commission"
                  style={{ width: "100%" }}
                  min={0}
                />
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item label="Operator" name="operaterId">
                <Select placeholder="Please select Operator Type">
                  {operatorsList.map((item) => (
                    <Option value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label="RTC" name="isRtc">
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label="Active" name="isActive">
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default BusCommissions;
