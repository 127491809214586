import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  message,
  Popconfirm,
  Row,
  Table,
  Select,
  Collapse,
  Tooltip,
  Switch,
  Spin,
} from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import { exportExcel } from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
import { useAuthContext } from "../../common/providers/AuthProvider";
const { Content } = Layout;
const { Panel } = Collapse;

const Suppliers = () => {
  const { user } = useAuthContext();
  const { Option } = Select;
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [suppliersList, setSuppliersList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [tableListBal, setTableListBal] = useState([]);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [currentId, setCurrentId] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [activePanel, setActivePanel] = useState(["1"]);
  const [showBSearchBox, setShowBSearchBox] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getSuppliersList();

    // getSupplierBal();

  }, []);

  const getSuppliersList = () => {
    let page = { page: 0 };
    ApiClient.post("supplier/getsupplier/list", page)
      .then((res) => {
        if (res.isSuccess) {
          let data = res.results.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setSuppliersList(data);
          setTableList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const add = (val) => {
    ApiClient.post("supplier/savesupplier", val)
      .then((response) => {
        return response;
      })
      .then((response) => {
        if (response.isSuccess) {
          setModalVisible(false);
          message.success("Successfully added supplier", 3);
          getSuppliersList();
        } else if (!response.isSuccess) {
          message.error("Suppliers Already existed", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleModal = () => {
    form.resetFields();
    if (showUpdateBtn) {
      setShowUpdateBtn(false);
      form.resetFields();
    }
    setModalVisible(true);
  };

  const deleteSuppliers = (Record) => {
    let Id = Record.id;
    ApiClient.delete("supplier/deletesupplier/" + Id)
      .then((res) => {
        if (res.isSuccess) {
          message.success("Suppliers Deleted  successfully", 3);
          getSuppliersList();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const updateSuppliers = (val, id = null) => {
    id = id == null ? currentId.id : id
    ApiClient.put(`supplier/updatesupplier/${id}`, {}, val).then(
      (resp) => {
        if (resp.isSuccess) {
          getSuppliersList();
          setModalVisible(false);
          message.success("Suppliers Updated successfully", 3);
          setCurrentId(-1);
        }
      }
    );
  };

  const submitForm = (val) => {
    let obj = {
      ...val,
      isActive: true,
      userId: user.id,
    };
    if (showUpdateBtn) updateSuppliers(obj);
    else add(obj);
  };

  const tableActions = (currentRecord, Name) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord, Name)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure want to delete?"
            onConfirm={() => deleteSuppliers(Name)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const editCurrentItem = (id, data) => {
    setShowUpdateBtn(true);
    setCurrentId(id);
    form.setFieldsValue({
      ...data,
    });
    setModalVisible(true);
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };

  const updateStatus = (status, record) => {

    // console.log("active ", record.id)
    // setCurrentId(record.id);

    let obj = {
      ...record,
      isActive: status
    };
    updateSuppliers(obj, record.id)
  }

  const searchData = () => {
    setShowSearchBox(true);
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: "Created By",
      dataIndex: "createdByDetails",
      sorter: (a, b) => a.createdByDetails.localeCompare(b.createdByDetails),
    },
    {
      title: "Service Type",
      dataIndex: "serviceType",
      sorter: (a, b) => a.serviceType.localeCompare(b.serviceType),
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      sorter: (a, b) => a.supplier.localeCompare(b.supplier),
    },
    {
      title: "UserName",
      dataIndex: "userName",
      sorter: (a, b) => a.userName.localeCompare(b.userName),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (isActive, record) => (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="InActive"
          defaultChecked={isActive}
          onChange={(val) => updateStatus(val, record)}
        />
      ),
    },

    {
      title: "Actions",
      render: (id, name) => tableActions(id, name),
    },
  ];


  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(suppliersList);
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
    setTableList(suppliersList);
  };

  const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

  const handleExcel = () => {
    let excelData = tableList;
    excelData = excelData.map((item, i) => ({
      SNo: i + 1,
      Operator: item.name,
      ServiceType: item.serviceType,
    }));
    exportExcel(excelData, "Suppliers");
  };



  return (
    <div className="outer-background-all">
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Collapse
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <Tooltip placement="top" title={isActive ? "Show" : "Hide"}>
                      <CaretRightOutlined rotate={isActive ? 90 : -90} />
                    </Tooltip>
                  )}
                  expandIconPosition="right"
                  className="panel_wrapper"
                  defaultActiveKey={["1"]}
                  activeKey={activePanel}
                  onChange={(arrKeys) => {
                    if (arrKeys.length > 1) {
                      setActivePanel(arrKeys[arrKeys.length - 1]);
                    } else {
                      setActivePanel(arrKeys);
                    }
                  }}
                >
                  <Panel header="View Suppliers" key="1" style={{ borderBottom: "1px solid grey", boxShadow: "0px 2px 8px grey", borderRadius: "6px" }}>
                    <Card bordered={false} style={{ borderRadius: "25px" }}>
                      <div className="card-add-heading">
                        {/* <div className="rows-count">
                          {/* <h5>View Suppliers</h5> *
                        </div> */}
                        <p>{tableList.length} rows found !</p>
                        <div className="action-images">
                          {showSearchBox && (
                            <Input
                              autocomplete="newpassword"
                              placeholder="Search"
                              onChange={(e) => searchTableData(e)}
                              suffix={closeSearchInput}
                              style={{ padding: "0px 12px" }}
                            />
                          )}
                          &nbsp;&nbsp;
                          <img src={search} alt="search" onClick={() => searchData()} />
                          <img src={excel} alt="excel" onClick={() => handleExcel()} />
                          <img
                            src={exchange}
                            alt="exchange"
                            onClick={() => {
                              setTableList([]);
                              getSuppliersList();
                            }}
                          />
                          <p className="add-deposit-icon" onClick={handleModal}>
                            <PlusCircleFilled />
                          </p>
                        </div>
                      </div>

                      <Table
                        scroll={{ x: true }}
                        bordered
                        rowKey={uniqueKey}
                        dataSource={tableList}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </Card>
                  </Panel>

                </Collapse>
              </div>

            </Col>
          </Row >
        </Content >
      </div >
      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>
                {showUpdateBtn ? "Update Suppliers" : "Add Suppliers"}
              </strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header bci-panel-admin-parts"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <Button key="add" type="primary" onClick={form.submit}>
            {showUpdateBtn ? "Update" : "Add"}
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm}>
          <Row gutter={12}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Service Type"
                name="serviceType"
                rules={[
                  { required: true, message: "Please enter Service Type" },
                ]}
              >
                <Select placeholder="Please select Service Type">
                  <Option value="Buses">Buses</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Supplier Name"
                name="supplier"
                rules={[
                  { required: true, message: "Please enter Supplier Name" },
                ]}
              >
                <Select placeholder="Please select Service Name">
                  <Option value="IRCT">IRCT</Option>
                  <Option value="REDB">REDB</Option>
                  <Option value="BTLA">BTLA</Option>
                  <Option value="EZIF">EZIF</Option>
                  <Option value="TRYR">TRYR</Option>
                  <Option value="ETST">ETST</Option>
                  <Option value="ITSG">ITSG </Option>
                  <Option value="VRLT">VRLT</Option>
                  <Option value="ZGBS">ZGBS</Option>
                  <Option value="YLBS">YLBS</Option>
                  <Option value="ETRS">ETRS</Option>
                  <Option value="ABHI">ABHI</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Base Url"
                name="baseUrl"
                rules={[{ required: true, message: "Please enter BaseUrl" }]}
              >
                <Input
                  autocomplete="newpassword"
                  placeholder="Please Enter baseUrl"
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label="User Name" name="userName">
                <Input
                  autocomplete="newpassword"
                  placeholder="Please Enter User Name"
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label="Password" name="password">
                <Input.Password
                  autocomplete="newpassword"
                  placeholder="Please Enter Password"
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label="Api key" name="apikey">
                <Input
                  autocomplete="newpassword"
                  placeholder="Please Enter Api key"
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label="Remarks" name="remarks">
                <Input
                  autocomplete="newpassword"
                  placeholder="Please Enter Remarks"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div >
  );
};
export default Suppliers;
