import { Card, Modal, Row, Tooltip } from "antd";
import dateFormat from "dateformat";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FlightTimings } from "../SearchResults/Flight/Flight";
import "./FlightsCheckout.scss";

const DetailsCard = (props) => {
  // console.log("flight details card props", props);

  let travelType = localStorage.getItem("travelType");

  const [onwardFlightDetails, setOnwardFlightDetails] = useState(null);
  const [returnFlightDetails, setReturnFlightDetails] = useState(null);
  const [flightInfo, setFlightInfo] = useState(props.selectedFlightData);
  const [flightSearchData, setFlightSearchData] = useState(
    props.flightSearchData
  );
  const [moreDetails, setMoreDetails] = useState(false);
  const [detailsModalData, setDetailsModalData] = useState(null);
  const [searchDataForModal, setSearchDataForModal] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    preparingFlightDetails();
  }, [flightInfo]);

  const preparingFlightDetails = () => {
    if (travelType === "oneWay") {
      setOnwardFlightDetails(flightInfo[0]);
    } else {
      setOnwardFlightDetails(flightInfo[0]);
      setReturnFlightDetails(flightInfo[1]);
    }
  };

  const showModal = (flightDetalisObj) => {
    setDetailsModalData(flightDetalisObj);
    setSearchDataForModal(flightSearchData);
    setMoreDetails(true);
  };

  const handleOk = (e) => {
    console.log(e);
    setMoreDetails(false);
  };

  const handleCancel = (e) => {
    console.log(e);
    setMoreDetails(false);
  };
  let history = useHistory();
  const goTo = () => {
    history.goBack();
  };

  const calculateDuration = (from, to) => {
    let fromTime = from.split("T")[0] + " " + from.split("T")[1];
    let toTime = to.split("T")[0] + " " + to.split("T")[1];

    let diffInMilliSeconds =
      Math.abs(new Date(fromTime) - new Date(toTime)) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    let difference = "";
    // if (days > 0) {
    //   difference += days === 1 ? `${days} d, ` : `${days} ds, `;
    // }

    difference += hours === 0 || hours === 1 ? `${hours} h ` : `${hours} h `;

    difference +=
      minutes === 0 || hours === 1 ? `${minutes} min` : `${minutes} min`;

    return difference;
    // return days;
  };
  const toolBreakCity = (breakCityDest, stopsCount) => {
    return (
      <div className="tooltip-data">
        {stopsCount > 1 ? <h4>Change</h4> : ""}
        <p>{breakCityDest}</p>
      </div>
    );
  };

  return (
    <>
      <div className="flight-details-container">
        <div className="user-details ">
          <div className="change-flight-details">
            <h3>Flight Details</h3>
            <p
              onClick={() => {
                goTo();
              }}
            >
              Change Flights
            </p>
          </div>

          {onwardFlightDetails != null ? (
            <>
              <Card className="flight-details-card">
                <div className="airline-details">
                  <div className="airline-details-left">
                    <div className="dept_wrapper">
                      <div className="airline-img">
                        <img
                          src={onwardFlightDetails.airLineLogo}
                          alt="airLineLogo"
                        />
                        <span className="name">
                          {onwardFlightDetails.airLineName}{" "}
                        </span>
                      </div>

                      <div className="airline-dte">
                        <h5>Departure</h5>

                        <span>
                          {dateFormat(
                            onwardFlightDetails.flightSegments[0]
                              .departureDateTime,
                            "dd mmm (ddd)"
                          )}
                        </span>
                      </div>

                      <div className="direct-text">
                        <p>
                          {onwardFlightDetails.flightSegments.length === 1 ? (
                            "Direct"
                          ) : (
                            <>
                              {onwardFlightDetails.flightSegments.length - 1}{" "}
                              stops
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="airline-src-dest">
                      <p>
                        {
                          onwardFlightDetails.flightSegments[0].originName.split(
                            " "
                          )[0]
                        }
                        <span className="code">
                          ({onwardFlightDetails.flightSegments[0].origin})
                        </span>
                      </p>

                      <div className="break-journey-city">
                        <div className="inner_wrapper">
                          {onwardFlightDetails.flightSegments.map(
                            (flightSegInfo, index) =>
                              index !== 0 ? (
                                <div
                                  className="stop_points"
                                  key={flightSegInfo.segId}
                                >
                                  <Tooltip
                                    placement="top"
                                    title={toolBreakCity(
                                      flightSegInfo.origin +
                                        " " +
                                        flightSegInfo.originName,
                                      onwardFlightDetails.flightSegments
                                        .length - 1
                                    )}
                                    className="citynames-tooltip"
                                  >
                                    <span className="break-city-name pointer_cursor">
                                      {flightSegInfo.origin}
                                    </span>
                                  </Tooltip>
                                </div>
                              ) : null
                          )}
                        </div>
                      </div>

                      <p>
                        {
                          onwardFlightDetails.flightSegments[
                            onwardFlightDetails.flightSegments.length - 1
                          ].destiantionName.split(" ")[0]
                        }

                        <span className="code">
                          (
                          {
                            onwardFlightDetails.flightSegments[
                              onwardFlightDetails.flightSegments.length - 1
                            ].destination
                          }
                          )
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="trip-details">
                  <Row>
                    <FlightTimings
                      flightDetails={onwardFlightDetails}
                      flightSearchData={props.flightSearchData}
                    />
                  </Row>
                </div>
              </Card>
            </>
          ) : null}

          {travelType === "roundTrip" && returnFlightDetails != null ? (
            <>
              <Card className="flight-details-card">
                <div className="airline-details">
                  <div className="airline-details-left">
                    <div className="dept_wrapper">
                      <div className="airline-img">
                        <img
                          src={returnFlightDetails.airLineLogo}
                          alt="airLineLogo"
                        />

                        <span className="name">
                          {returnFlightDetails.airLineName}{" "}
                        </span>
                      </div>

                      <div className="airline-dte">
                        <h5>Departure</h5>

                        <span>
                          {dateFormat(
                            returnFlightDetails.flightSegments[0]
                              .departureDateTime,
                            "dd mmm (ddd)"
                          )}
                        </span>
                      </div>

                      <div className="direct-text">
                        <p>
                          {returnFlightDetails.flightSegments.length === 1 ? (
                            "Direct"
                          ) : (
                            <>
                              {returnFlightDetails.flightSegments.length - 1}{" "}
                              stops{" "}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="airline-src-dest">
                      <p>
                        {
                          returnFlightDetails.flightSegments[0].originName.split(
                            " "
                          )[0]
                        }
                        <span className="code">
                          ({returnFlightDetails.flightSegments[0].origin})
                        </span>
                      </p>

                      <div className="break-journey-city">
                        <div className="inner_wrapper">
                          {returnFlightDetails.flightSegments.map(
                            (flightSegInfo, index) =>
                              index !== 0 ? (
                                <div
                                  className="stop_points"
                                  key={flightSegInfo.segId}
                                >
                                  <Tooltip
                                    placement="top"
                                    title={toolBreakCity(
                                      flightSegInfo.origin +
                                        " " +
                                        flightSegInfo.originName,
                                      returnFlightDetails.flightSegments
                                        .length - 1
                                    )}
                                    className="citynames-tooltip"
                                  >
                                    <span className="break-city-name pointer_cursor">
                                      {flightSegInfo.origin}
                                    </span>
                                  </Tooltip>
                                </div>
                              ) : null
                          )}
                        </div>
                      </div>

                      <p>
                        {
                          returnFlightDetails.flightSegments[
                            returnFlightDetails.flightSegments.length - 1
                          ].destiantionName.split(" ")[0]
                        }
                        <span className="code">
                          (
                          {
                            returnFlightDetails.flightSegments[
                              returnFlightDetails.flightSegments.length - 1
                            ].destination
                          }
                          )
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="trip-details">
                  <Row>
                    <FlightTimings
                      flightDetails={returnFlightDetails}
                      flightSearchData={props.flightSearchData}
                    />
                  </Row>
                </div>
              </Card>
            </>
          ) : null}
        </div>
        <Modal
          title="Flight Details"
          visible={moreDetails}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={false}
          className="flight-details-modal-container"
        >
          {detailsModalData !== null ? (
            <>
              <Row className="trip-duration-strip">
                <div className="trip-time">
                  <i className="fa fa-history"></i>
                  <p>
                    Total trip duration:
                    <strong>{detailsModalData.duration}</strong>
                  </p>
                </div>
              </Row>
              {detailsModalData.flightSegments.map((flightSegObj) => (
                <>
                  <div className="trip-body">
                    <div className="trip-strip">
                      <div className="trip-body-details">
                        <p>
                          <strong>
                            {dateFormat(
                              flightSegObj.departureDateTime,
                              "HH:MM"
                            )}
                            ,
                            {dateFormat(
                              flightSegObj.arrivalDateTime,
                              "dd mmm (ddd)"
                            )}
                          </strong>
                        </p>
                        <p>
                          <strong>{flightSegObj.origin},</strong>
                          Airport({flightSegObj.origin})
                        </p>
                      </div>
                      <div className="trip-body-details">
                        <p>
                          <strong>
                            {dateFormat(flightSegObj.arrivalDateTime, "HH:MM")},
                            {dateFormat(
                              flightSegObj.arrivalDateTime,
                              "dd mmm (ddd)"
                            )}
                          </strong>
                        </p>
                        <p>
                          <strong>{flightSegObj.destination},</strong>
                          Airport({flightSegObj.destination})
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="trip-bottom-duration">
                    <p>
                      Duration:
                      <strong>
                        {moment
                          .duration(flightSegObj.journeyDuration, "minutes")
                          .format()}
                        {/* {dateFormat(flightSegObj.journeyDuration, "HH:MM")} */}
                      </strong>
                    </p>
                    <p>
                      Airline: <strong> {flightSegObj.operatingAirline}</strong>{" "}
                      | Flight Number:
                      <strong>{flightSegObj.flightNumber}</strong> | Class:
                      <strong>{flightSearchData.cabinClass}</strong>
                    </p>
                  </div>
                </>
              ))}
            </>
          ) : null}
        </Modal>
      </div>
    </>
  );
};

export default DetailsCard;
