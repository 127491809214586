import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  message,
  Popconfirm,
  Row,
  Table,
  Image,
} from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import { exportExcel } from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
import { useAuthContext } from "../../common/providers/AuthProvider";
const { Content } = Layout;

const BankDetails = () => {
  const { user } = useAuthContext();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [bankDetailsList, setBankDetailsList] = useState([
    {
      SNo: 1,
      Image:
        "http://101.53.145.46/api/v1/media/images/bankimg/I2space1634104508310.png",
      bankName: "SBI",
      acNo: "123466789",
      branchName: "Shimla",
      ifscCode: "SBISHIMLA",
    },
  ]);
  const [tableList, setTableList] = useState(bankDetailsList);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [currentId, setCurrentId] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  // setBankDetailsList([
  //   {
  //     SNo: 1,
  //     bankName: "SBI",
  //     acNo: "123466789",
  //     branchName: "Shimla",
  //     ifscCode: "SBISHIMLA",
  //   },
  // ]);

  const add = (val) => {
    // ApiClient.post("codes/saverole", val)
    //   .then((response) => {
    //     return response;
    //   })
    //   .then((response) => {
    //     if (response.isSuccess) {
    //       setModalVisible(false);
    //       message.success("Successfully added bankDetails", 3);
    //       getBankDetailsList();
    //     } else if (!response.isSuccess) {
    //       message.error("BankDetails Already existed", 3);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };

  const handleModal = () => {
    if (showUpdateBtn) {
      setShowUpdateBtn(false);
      form.resetFields();
    }
    setModalVisible(true);
  };

  const deleteRole = (Record) => {
    // let Id = Record.id;
    // ApiClient.delete("codes/deleterole/" + Id)
    //   .then((res) => {
    //     if (res.isSuccess) {
    //       message.success("BankDetails Deleted  successfully", 3);
    //       getBankDetailsList();
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };
  const updateRole = (val) => {
    // ApiClient.put(`codes/updaterole/${currentId.id}`, {}, val).then((resp) => {
    //   if (resp.isSuccess) {
    //     getBankDetailsList();
    //     setModalVisible(false);
    //     message.success("BankDetails Updated successfully", 3);
    //     setCurrentId(-1);
    //   }
    // });
  };

  const submitForm = (val) => {
    let roleObj = {
      name: val.bankDetails,
      isActive: true,
    };
    if (showUpdateBtn) updateRole(roleObj);
    else add(roleObj);
  };

  const tableActions = (currentRecord, Name) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord, Name)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure want to delete?"
            onConfirm={() => deleteRole(Name)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const editCurrentItem = (id, Name) => {
    setShowUpdateBtn(true);
    setCurrentId(id);
    form.setFieldsValue({
      bankDetails: Name.name,
    });
    setModalVisible(true);
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };

  const searchData = () => {
    setShowSearchBox(true);
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    {
      title: "BankLogo",
      dataIndex: "Image",
      render: (image) => <Image width={200} height={60} src={image} />,
    },
    {
      title: "Bank Name",
      dataIndex: "bankName",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Account Number",
      dataIndex: "acNo",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Branch Name",
      dataIndex: "branchName",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "IFSC Code",
      dataIndex: "ifscCode",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Actions",
      // render: (id, name) =>
      //   user?.roleId === 1 ? tableActions(id, name) : null,
    },
  ];

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(bankDetailsList);
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
    setTableList(bankDetailsList);
  };

  const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

  const handleExcel = () => {
    let excelData = tableList;
    excelData = excelData.map((item, i) => ({
      SNo: i + 1,
      BankName: item.bankName,

      Account_No: item.acNo,
      BranchName: item.branchName,
      IFSC_Code: item.ifscCode,
    }));

    exportExcel(excelData, "Bank Details");
  };
  const getDataByName = (val) => {
    let obj = {
      filter: {
        name: val,
        isActive: true,
      },
      page: 0,
    };
    ApiClient.post("codes/getroles/list", obj)
      .then((response) => {
        return response;
      })
      .then((response) => {
        if (response.isSuccess) {
          let data = response.results.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });

          setBankDetailsList(data);
          setTableList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Layout>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false}>
              <Row className="card-add-heading">
                <Col span={16}>
                  <div className="rows-count">
                    <h5>View Bank Details</h5>
                    <p>{tableList.length} rows found !</p>
                  </div>
                </Col>
                <Col span={8}>
                  <Row>
                    {/* <Col span={14}>
                      <Form name="search">
                        <AutoCompleteSelect
                          formItemProps={{
                            label: "",
                            name: "Search",
                            rules: [{ required: true, message: "Required!" }],
                          }}
                          selectProps={{
                            placeholder: "Search BankDetails Name",
                            onSelect: (val) => getDataByName(val),
                          }}
                          api={"codes/getroleautosuggestion/"}
                          keytext="name"
                          keyvalue="name"
                        />
                      </Form>
                    </Col> */}
                    <Col span={24}>
                      <div className="action-images">
                        &nbsp;&nbsp;
                        {/* <img src={search} alt="search" onClick={() => searchData()} /> */}
                        <img
                          src={excel}
                          alt="excel"
                          onClick={() => handleExcel()}
                        />
                        <img
                          src={exchange}
                          alt="exchange"
                          onClick={() => {
                            setTableList([]);
                            // getBankDetailsList();
                          }}
                        />
                        {user?.roleId === 1 ? (
                          <PlusCircleFilled
                            className="add-deposit-icon"
                            onClick={handleModal}
                          />
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {tableList.length > 0 ? (
                <Table
                  scroll={{ x: true }}
                  bordered
                  rowKey={uniqueKey}
                  dataSource={tableList}
                  columns={columns}
                  pagination={{
                    defaultPageSize: 25,
                    showSizeChanger: true,
                    pageSizeOptions: ["25", "50", "100", "125"],
                  }}
                />
              ) : (
                "No Record Found"
              )}
            </Card>
          </div>
        </Content>
      </div>
      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>
                {showUpdateBtn ? "Update Bank Details" : "Add Bank Details"}
              </strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <Button key="add" type="primary" onClick={form.submit}>
            {showUpdateBtn ? "Update" : "Add"}
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm}>
          <Row gutter={(12, 12)}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Bank Name"
                name="bankDetails"
                rules={[
                  { required: true, message: "Please enter bankDetails" },
                ]}
              >
                <Input autocomplete="newpassword" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Account Number"
                name="acNo"
                rules={[
                  { required: true, message: "Please enter bankDetails" },
                ]}
              >
                <Input autocomplete="newpassword" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Ifsc Code"
                name="ifscCode"
                rules={[
                  { required: true, message: "Please enter bankDetails" },
                ]}
              >
                <Input autocomplete="newpassword" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Branch Name"
                name="branchName"
                rules={[
                  { required: true, message: "Please enter bankDetails" },
                ]}
              >
                <Input autocomplete="newpassword" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Layout>
  );
};
export default BankDetails;
