import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Collapse,
  Popconfirm,
  Table,
  Checkbox,
  message,
  Space
} from "antd";

import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
import BusApiClient from "../../../src/helpers/BusApiClient";
import { useAuthContext } from "../../common/providers/AuthProvider";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
import SessionTimeOutModal from "../../common/SessionTimeOutModal/SessionTimeOutModal";

const { Content } = Layout;
const ApiConfigue = () => {
  const { user } = useAuthContext();
  const [form] = Form.useForm();
  const { Option } = Select;
  const CheckboxGroup = Checkbox.Group;
  const [activeCategory, setActiveCategory] = useState([]);
  const [visible, setVisible] = useState(false);
  const [reportRole, setReportRole] = useState(-1);
  const [apiOptions, setApiOptions] = useState([]);
  const [userSupplierList, setUserSupplierList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [currentId, setCurrentId] = useState(-1);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(0)
  const [isSessionTimeOutModel, setIsSessionTimeOutModel] = useState(false)

  const getSuppliersList = () => {
    setApiOptions([]);
    let page = { page: 0 };
    ApiClient.post("supplier/getsupplier/list", page)
      .then((res) => {
        /** res will be return undefined if the API is UnAuthorize   */
        if (res == "TypeError: Failed to fetch") {
          setIsSessionTimeOutModel(true)
        }
        if (res.isSuccess) {
          setApiOptions(res.results);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };
  const submitForm = (values) => {
    // console.log(userSupplierList, values, "value");
    let uniqueUserIds = [...new Set(userSupplierList.map(item => item.userId))];
    let userId = values?.name === 1 ? user.id : showUpdateBtn ? currentUserId : values.user;
    let obj = {
      userId: userId,
      serviceType: values.serviceType,
      supplierId: values.supplierId,
      isActive: true,
    };

    if (showUpdateBtn) UpadteUserSupplierConfig(obj);
    else {
      // {console.log(uniqueUserIds,userId,"idd")}
      if (!uniqueUserIds.includes(userId)) {
        // uniqueUserIds.push(userId);
        ApiClient.post("supplier/saveusersupplierconfig", obj)
          .then((response) => {
            /** res will be return undefined if the API is UnAuthorize   */
            if (response == "TypeError: Failed to fetch") {
              setIsSessionTimeOutModel(true)
            }
            if (response.isSuccess) {
              // setVisible(true);
              form.resetFields();
              message.success("Saved Succesfully", 3);
              getUsermapSupplierList()
            } else {
              message.error("Something Went Wrong", 3);
              getUsermapSupplierList()
            }
          })
          .catch((error) => {
            console.error(error);
          });
        // console.log("iam");
      }
      else {
        // console.log("error");
        message.error("User Already Exist", 3);
      }
      form.resetFields()
    }

  };

  const UpadteUserSupplierConfig = (val) => {
    ApiClient.put(
      `supplier/updateusersupplierconfig/${currentId}`,
      {},
      val
    ).then((resp) => {
      /** res will be return undefined if the API is UnAuthorize   */
      if (resp == "TypeError: Failed to fetch") {
        setIsSessionTimeOutModel(true)
      }
      if (resp.isSuccess) {
        form.resetFields()
        setShowUpdateBtn(false)
        getUsermapSupplierList();
        // setModalVisible(false);
        message.success("Updated successfully", 3);
        setCurrentId(-1);
      } else message.error(resp?.message ?? 'Failed To Updated')
    });
  };

  let supplierList =
    [{ name: 'IRCT', value: 1 },
    { name: 'REDB', value: 2 },
    { name: 'BTLA', value: 3 },
    { name: 'TRYR', value: 4 },
    { name: 'EZIF', value: 6 },
    { name: 'ETST', value: 7 },
    { name: 'YLBS', value: 8 },
    { name: 'ZGBS', value: 9 },
    { name: 'ITSG', value: 10 },
    { name: 'VRLT', value: 11 },
    { name: 'ABHI', value: 12 }]



  const [carrier, setCarrier] = useState([]);
  // const handleFilterName = (e, name) => {
  //   let array = [...apiOptions];
  //   if (e.target.checked) {
  //     array.push(name);
  //   } else {
  //     let index = array.indexOf(name);
  //     if (index > -1) {
  //       array.splice(index, 1);
  //     }
  //   }
  //   setApiOptions(array);
  // };

  const getEnabledSupplier = (item) => {
    let supplierIds = item?.suppliers?.split('~')
    let supplierName = []
    // console.log("supplierName", supplierIds, apiOptions)
    supplierIds.forEach(ids => {
      let supplierFilter = apiOptions.filter(sp => sp.id == ids)
      supplierName.push(supplierFilter[0].supplier)
    })

    return supplierName.join(',')
  }
  const getUsermapSupplierList = () => {
    let page = { page: 0 };
    ApiClient.post("supplier/getusermapsupplier/list", page)
      .then((res) => {
        /** res will be return undefined if the API is UnAuthorize   */
        if (res == "TypeError: Failed to fetch") {
          setIsSessionTimeOutModel(true)
        }
        if (res.isSuccess) {
          let data = res.results.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              // SupplierName: getEnabledSupplier(item)
            };
          });

          setUserSupplierList(data);
          setTableList(data);

        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteApiConf = (Record) => {
    let Id = Record.id;

    ApiClient.delete("supplier/deleteusersupplierconfig/" + Id)
      .then((res) => {
        if (res.isSuccess) {
          message.success("Deleted  successfully", 3);
          getUsermapSupplierList();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const editCurrentItem = (id, data) => {
    setShowUpdateBtn(true);
    setCurrentId(id);
    setCurrentUserId(data.userId)
    data.userId != 1 ? setReportRole(2) : setReportRole(1)
    form.setFieldsValue({
      ...data,
      supplierId: data.suppliers.split('~').map(x => parseInt(x)),
      name: data.userId != 1 ? 2 : 1,
      // user: data.userId
      user: data.companyName
    });
    // setModalVisible(true);
  };

  const RemoveCache = (userId) => {

    let body = { id: userId };
    BusApiClient.post("bus/RemoveCache", body)
      .then((res) => {
        if (res.status) {
          message.success(res.message, 3);
        } else message.success(res.message ?? "Failed To Remove the cache", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const tableActions = (currentRecord, Name) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord.id, Name)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure want to delete?"
            onConfirm={() => deleteApiConf(currentRecord)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };



  const getEnaledSupplierName = (currentRecords) => {
    const supplier = currentRecords.suppliers.split('~').map(s => parseInt(s))
    const filterResp = supplierList.filter(sp => supplier.includes(sp.value))
    return filterResp.map(sp => sp.name).join('~')

    // return (
    //   <Space size="middle">
    //     <a>{filterResp.map(sp => sp.name).join('~')}</a>
    //     <a>Clear Cache</a>
    //   </Space>
    // )
  }

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    // {
    //   title: "User Id",
    //   dataIndex: "userId",
    //   sorter: (a, b) => a.userId - b.userId,
    // },
    {
      title: "Company Name",
      dataIndex: "companyName",
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    },
    // {
    //   title: "Service Type",
    //   dataIndex: "serviceType",
    //   render: (service) => service == 1 ? "Buses" : ""
    // },

    {
      title: "API's Enabled",
      // dataIndex: "SupplierName",
      render: (records) => getEnaledSupplierName(records)
    },
    {
      title: "RemoveCache",
      dataIndex: "userId",
      render: userId => <a href="#" onClick={() => RemoveCache(userId)}> <DeleteOutlined /></a>,
      // render: (userId, record) => (
      //   <Space size="middle">
      //     <a color={"geekblue"} onClick={() => RemoveCache(userId)} >CalerCahe</a>
      //   </Space>
      // )
    },

    {
      title: "Actions",
      render: (id, name) => tableActions(id, name),
    },
  ];

  useEffect(() => {
    getSuppliersList();
    getUsermapSupplierList();
  }, []);



  return (
    <div className="outer-background-all">
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row justify="center">
            <Col md={18} sm={24} xs={24}>
              <div className="card-bt-gap">
                <div className="card-bt-gap">
                  <Card bordered={false} style={{ borderRadius: "25px" }}>
                    {/* <div className="card-add-heading"> */}
                    <h5 className="text-center">
                      Manage Client API Configurations
                    </h5>
                    <Form layout="vertical" form={form} onFinish={submitForm}>
                      <Row gutter={12} align="middle">
                        {/* <Col md={24} sm={24} xs={24}>
                            <Form.Item
                              label="Select Type"
                              name="type"
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <Radio.Group>
                                <Radio value="api">API Wise</Radio>
                                <Radio value="subtype">SubType Wise</Radio>
                                <Radio value="operator">Operator Wise</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col> */}
                        <Col md={8} sm={24} xs={24}>
                          <Form.Item
                            label="Role"
                            name="name"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <Select
                              placeholder="Please select Role"
                              onSelect={(val) => setReportRole(val)}
                            >
                              <Option value={1}>Admin</Option>
                              <Option value={2}>SiteAdmin</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        {reportRole === 2 && (
                          <Col md={8} sm={24} xs={24}>
                            <AutoCompleteSelect
                              formItemProps={{
                                label: "Company Name",
                                name: "user",
                                rules: [
                                  {
                                    required: true,
                                    message: "Please enter User Name",
                                  },
                                ],
                              }}
                              selectProps={{
                                placeholder: "Please Search User Name",
                              }}
                              api={"user/userautosuggestion/"}
                              keytext="name"
                              keyvalue="id"
                            />
                          </Col>
                        )}

                        <Col md={8} sm={24} xs={24}>
                          <Form.Item
                            label="Service "
                            name="serviceType"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <Select placeholder="Please select Service">
                              <Option value={1}>Buses</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col md={24} sm={24} xs={24}>
                          <Form.Item
                            name="supplierId"
                            label=" Supplier"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <CheckboxGroup className="w-100">
                              <Row gutter={[16, 8]}>
                                {supplierList.length > 0
                                  ? supplierList.map((item, idx) => (
                                    <Col
                                      key={idx}
                                      md={6}
                                      sm={12}
                                      xs={24}
                                      className="p-1"
                                    >
                                      <Checkbox
                                        value={item.value}
                                      // onChange={(e) =>
                                      //   handleFilterName(e, item.supplier)
                                      // }
                                      >
                                        {item.name}
                                      </Checkbox>
                                    </Col>
                                  ))
                                  : null}
                              </Row>
                            </CheckboxGroup>
                          </Form.Item>
                        </Col>
                        <Col
                          md={24}
                          sm={24}
                          xs={24}
                          className="d-flex justify-content-center"
                        >
                          <Button
                            key="update"
                            type="primary"
                            htmlType="submit"
                            className="mx-2"
                          >
                            {showUpdateBtn ? "Update" : "Add"}
                          </Button>
                          <Button
                            key="clear"
                            type="danger"
                            className="mx-2"
                            onClick={() => {
                              form.resetFields();
                            }}
                          >
                            Clear
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    {/* </div> */}
                  </Card>
                  <Card bordered={false} className="mt-3">
                    <Table
                      scroll={{ x: true }}
                      bordered
                      rowKey={uniqueKey}
                      dataSource={tableList}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Content>
      </div>
      {
        isSessionTimeOutModel ? (<SessionTimeOutModal isModelVisible={isSessionTimeOutModel} />) : null
      }
    </div>
  );
};
export default ApiConfigue;
