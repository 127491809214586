import React, { useState } from "react";
import "../FlightTicket/FlightTicket.scss";
import * as ANTD from "antd";
import CommonTicket from "./CommonTicket";
import { DownOutlined } from "@ant-design/icons";
import dateFormat from "dateformat";

const FlightTicket = (props) => {
  console.log("flight admin ticket props", props);
  const [airBookRespData, setAirBookRespData] = useState(
    props.flightContext.state.flightBookingResp.data.flightDetails[0]
  );
  const [returnAirBookRespData, setReturnAirBookRespData] = useState(
    props.flightContext.state.flightBookingResp.data.flightDetails[1]
  );
  let fareRulesData = props.flightContext.state.flightFareRulesResp
    ? props.flightContext.state.flightFareRulesResp
    : "";
  let searchValue = JSON.parse(localStorage.getItem("searchResp"));
  let travelType = localStorage.getItem("travelType");
  const { Panel } = ANTD.Collapse;
  const toggleButton = (
    <ANTD.Button
      type="primary"
      key="console"
      style={{ display: "flex", alignItems: "center" }}
      className="view-flight-ticket-btn"
    >
      View Ticket <DownOutlined />
    </ANTD.Button>
  );

  const ticketTitle = (
    <>
      <div className="flight-ticket-titles">
        <h4>Ticket Is Booked Successfully</h4>
        <span>
          A confirmation email has been sent to your provided email address
        </span>
      </div>
      <div className="ticket-full-details">
        <div className="pnr-number">
          <p>
            Onward PNR:
            <span>{airBookRespData.pnr ? airBookRespData.pnr : ""}</span>
          </p>
          {
            /* searchValue.resultsType === "Combined" &&
          searchValue.airTravelType === "roundTrip" */
            props.flightContext.state.flightBookingResp.data.flightDetails
              .length > 1 ? (
              <p>
                Return PNR:
                <span>
                  {returnAirBookRespData.pnr ? returnAirBookRespData.pnr : ""}
                </span>
              </p>
            ) : null
          }
        </div>
        <div className="status">
          <p>
            Status : <span className="booking-status">Booked</span>
          </p>
        </div>
        <div className="ref-no">
          <p>
            Ref-No. : <span>-</span>
          </p>
        </div>
      </div>
    </>
  );

  const preparePaxObjWithPassport = () => {
    let passengersObj = [];
    props.flightContext.state.flightBookingResp.data.passengers.map(
      (paxObj, paxObjIndex) => {
        let tempPaxObj = {};
        tempPaxObj["sno"] = paxObjIndex + 1;
        tempPaxObj["name"] = paxObj.firstName + " " + paxObj.lastName;
        tempPaxObj["birthdate"] = paxObj.dob ? paxObj.dob.split("T")[0] : "";
        tempPaxObj["flyerno"] = paxObj.ffNumber ? paxObj.ffNumber : ""; //"static - no flyerno";
        tempPaxObj["passportno"] = paxObj.passportNumber
          ? paxObj.passportNumber
          : "";
        tempPaxObj["issuedcountry"] = paxObj.passportIssuedCountry
          ? paxObj.passportIssuedCountry
          : "";
        tempPaxObj["expdate"] = paxObj.passportDOE
          ? paxObj.passportDOE.split("T")[0]
          : "";
        passengersObj.push(tempPaxObj);
      }
    );
    return passengersObj;
  };

  const preparePaxObjWithoutPassport = () => {
    let passengersArr = [];
    props.flightContext.state.flightBookingResp.data.passengers.map(
      (paxObj, paxObjIndex) => {
        let tempPaxObj = {};

        tempPaxObj["title"] = paxObj.title;
        tempPaxObj["paxname"] = paxObj.firstName + " " + paxObj.lastName;
        tempPaxObj["type"] =
          paxObj.paxType === "ADT"
            ? "ADULT"
            : paxObj.paxType === "CHD"
            ? "CHILD"
            : "INFANT";
        tempPaxObj["eticketnumber"] = paxObj.tickets[0].ticketNumber;
        tempPaxObj["status"] = "Confirmed";

        passengersArr.push(tempPaxObj);
      }
    );
    return passengersArr;
  };

  const prepareOnwardFlightDetails = () => {
    let flightDetailsArr = [];
    airBookRespData.flightSegments.map((segmentObj, segIndex) => {
      let tempSegmentObj = {};
      // airBookRespData.fareFamilies[0].baggage.map((baggageObj) => {
      tempSegmentObj["airlinename"] =
        segmentObj.operatingAirline + " " + segmentObj.equipmentType;
      tempSegmentObj["flightnumber"] = segmentObj.flightNumber;
      tempSegmentObj["departuretime"] = dateFormat(
        segmentObj.departureDateTime,
        "HH:MM"
      ); //segmentObj.departureDateTime.split( "T")[1];
      tempSegmentObj["departures"] = segmentObj.origin;
      tempSegmentObj["arrivalstime"] = dateFormat(
        segmentObj.arrivalDateTime,
        "HH:MM"
      ); //segmentObj.arrivalDateTime.split("T")[1];
      tempSegmentObj["arrivals"] = segmentObj.destination;
      tempSegmentObj["duration"] =
        Math.floor(segmentObj.journeyDuration / 60) +
        ":" +
        (segmentObj.journeyDuration % 60); //segmentObj.journeyDuration + "mins";
      tempSegmentObj["classtype"] = "Economy"; //segmentObj.operatingAirline;
      tempSegmentObj["baggage"] =
        "CheckIn:" +
        airBookRespData.fareFamilies[0].baggage[segIndex].baggageInfo +
        " " +
        "Hand baggage" +
        airBookRespData.fareFamilies[0].baggage[segIndex].cabinBaggageInfo;
      tempSegmentObj["airlinepnr"] = segmentObj.airlinePNR;
      flightDetailsArr.push(tempSegmentObj);
    });

    // });
    console.log(flightDetailsArr);
    return flightDetailsArr;
  };

  const prepareReturnFlightDetails = () => {
    let flightDetailsArr = [];
    returnAirBookRespData.flightSegments.map((segmentObj, segIndex) => {
      // returnAirBookRespData.fareFamilies[0].baggage.map((baggageObj) => {
      let tempSegmentObj = {};
      tempSegmentObj["airlinename"] =
        segmentObj.operatingAirline + " " + segmentObj.equipmentType;
      tempSegmentObj["flightnumber"] = segmentObj.flightNumber;
      tempSegmentObj["departuretime"] = segmentObj.departureDateTime.split(
        "T"
      )[1];
      tempSegmentObj["departures"] = segmentObj.origin;
      tempSegmentObj["arrivalstime"] = segmentObj.arrivalDateTime.split("T")[1];
      tempSegmentObj["arrivals"] = segmentObj.destination;
      tempSegmentObj["duration"] = segmentObj.journeyDuration + "mins";
      tempSegmentObj["classtype"] = "Economy"; //segmentObj.operatingAirline;
      tempSegmentObj["baggage"] =
        "CheckIn:" +
        returnAirBookRespData.fareFamilies[0].baggage[segIndex].baggageInfo +
        " " +
        "Hand baggage" +
        returnAirBookRespData.fareFamilies[0].baggage[segIndex]
          .cabinBaggageInfo;
      tempSegmentObj["airlinepnr"] = segmentObj.airlinePNR;
      flightDetailsArr.push(tempSegmentObj);
      // });
    });
    return flightDetailsArr;
  };

  return (
    <div className="flight-ticket-collapse">
      <ANTD.Result
        status="success"
        title={ticketTitle}
        className="result-block"
        extra={[
          <ANTD.Collapse bordered={false} className="collapsed-data">
            <Panel header={toggleButton}>
              <div className="fligh-ticket-container">
                <div className="flight-ticket">
                  <ANTD.Row gutter={[32, 16]} className="ticket-row">
                    <ANTD.Col md={18}>
                      <ANTD.Collapse
                        bordered={true}
                        className="collapsed-data"
                        defaultActiveKey={["1"]}
                        accordion={true}
                      >
                        <Panel header="Onward Ticket" key="1">
                          <CommonTicket
                            airBookRespData={airBookRespData}
                            preparePaxObjWithPassport={
                              preparePaxObjWithPassport
                            }
                            prepareFlightDetails={prepareOnwardFlightDetails}
                            preparePaxObjWithoutPassport={
                              preparePaxObjWithoutPassport
                            }
                            ticketFareDetails={airBookRespData}
                            passengersDetails={
                              props.flightContext.state.flightBookingResp.data
                                .passengers[0]
                            }
                          />

                          <div className="terms">
                            <div className="terms-conditions-title">
                              <h5>Terms And Conditions</h5>
                            </div>
                            <div className="terms-conditions-body">
                              <p>{fareRulesData}</p>
                            </div>
                          </div>
                        </Panel>
                        {travelType === "roundTrip" ? (
                          <Panel header="Return Ticket" key="2">
                            <CommonTicket
                              airBookRespData={returnAirBookRespData}
                              preparePaxObjWithPassport={
                                preparePaxObjWithPassport
                              }
                              prepareFlightDetails={prepareReturnFlightDetails}
                              preparePaxObjWithoutPassport={
                                preparePaxObjWithoutPassport
                              }
                              ticketFareDetails={returnAirBookRespData}
                              passengersDetails={
                                props.flightContext.state.flightBookingResp.data
                                  .passengers[0]
                              }
                            />

                            <div className="terms">
                              <div className="terms-conditions-title">
                                <h5>Terms And Conditions</h5>
                              </div>
                              <div className="terms-conditions-body">
                                <p>
                                  {fareRulesData}
                                  <br />
                                  loremmmmmmmmmmmmmmm
                                </p>
                              </div>
                            </div>
                          </Panel>
                        ) : null}
                      </ANTD.Collapse>
                    </ANTD.Col>
                    <ANTD.Col xs={24} md={6}>
                      <div className="actionable-buttons">
                        <ANTD.Collapse>
                          <Panel header="Print ETicket" key="1">
                            <div className="trip-type-selector">
                              <div className="trip-type-selector">
                                <p>Print Confirmation With Price</p>
                                <p>Print Confirmation Without Price</p>
                              </div>
                            </div>
                          </Panel>
                        </ANTD.Collapse>
                        <div className="ticket-options">
                          <p>Mail</p>
                        </div>
                        <div className="ticket-options">
                          <p>SMS</p>
                        </div>
                        <ANTD.Collapse>
                          <Panel header="Download" key="1">
                            <div className="trip-type-selector">
                              <p>Download Confirmation With Price</p>
                              <p>Download Confirmation Without Price</p>
                            </div>
                          </Panel>
                        </ANTD.Collapse>
                        <div className="ticket-options">
                          <p>Status</p>
                        </div>
                        <div className="ticket-options">
                          <p>Cancel ETicket</p>
                        </div>
                        {/* <div className="ticket-options">
                          <p>
                            {" "}
                            <a href="#/admin/invoice" target="_blank">
                              Agent Invoice
                            </a>
                          </p>
                        </div> */}
                        <div className="ticket-options">
                          <p>
                            {" "}
                            <a href="#/admin/invoice" target="_blank">
                              User Invoice
                            </a>
                          </p>
                        </div>
                        {/* <ul>
                <li>
                   <div>Print ETicket</div>
                  <div className="trip-type-selector">
                    <ANTD.Radio.Group >
                      <ANTD.Radio value={1}>With Price</ANTD.Radio>
                      <ANTD.Radio value={2}>Without Price</ANTD.Radio>
                    </ANTD.Radio.Group>
                  </div>
                 </li>
                <li>
                  <div>Mail</div>
                  <div className="trip-type-selector">
                    <ANTD.Radio.Group >
                      <ANTD.Radio value={1}>With Price</ANTD.Radio>
                      <ANTD.Radio value={2}>Without Price</ANTD.Radio>
                    </ANTD.Radio.Group>
                  </div>
                </li>
                <li>
                  <div>SMS</div>
                  <div className="trip-type-selector">
                      <ANTD.Radio.Group >
                        <ANTD.Radio value={1}>With Price</ANTD.Radio>
                        <ANTD.Radio value={2}>Without Price</ANTD.Radio>
                      </ANTD.Radio.Group>
                  </div>
                </li>
                <li>
                  <div>Download</div>
                  <div className="trip-type-selector">
                      <ANTD.Radio.Group >
                        <ANTD.Radio value={1}>With Price</ANTD.Radio>
                        <ANTD.Radio value={2}>Without Price</ANTD.Radio>
                      </ANTD.Radio.Group>
                  </div>
                </li>
                <li>
                  <div>Status</div>
                  <div className="trip-type-selector">
                      <ANTD.Radio.Group >
                        <ANTD.Radio value={1}>With Price</ANTD.Radio>
                        <ANTD.Radio value={2}>Without Price</ANTD.Radio>
                      </ANTD.Radio.Group>
                  </div>
                </li>
                <li>
                  <div>Cancel ETicket</div>
                  <div className="trip-type-selector">
                      <ANTD.Radio.Group >
                        <ANTD.Radio value={1}>With Price</ANTD.Radio>
                        <ANTD.Radio value={2}>Without Price</ANTD.Radio>
                      </ANTD.Radio.Group>
                  </div>
                  </li>
                <li>
                  <div>Agent Invoice</div>
                  <div className="trip-type-selector">
                      <ANTD.Radio.Group >
                        <ANTD.Radio value={1}>With Price</ANTD.Radio>
                        <ANTD.Radio value={2}>Without Price</ANTD.Radio>
                      </ANTD.Radio.Group>
                  </div>
                </li>
                <li>
                  <div>User Invoice</div>
                  <div className="trip-type-selector">
                      <ANTD.Radio.Group >
                        <ANTD.Radio value={1}>With Price</ANTD.Radio>
                        <ANTD.Radio value={2}>Without Price</ANTD.Radio>
                      </ANTD.Radio.Group>
                  </div>
                </li>
              </ul> */}
                      </div>
                    </ANTD.Col>
                  </ANTD.Row>
                </div>
              </div>
            </Panel>
          </ANTD.Collapse>,
        ]}
      />
    </div>
  );
};

export default FlightTicket;
