import React from "react";
import "../BusResultCards/BusResultCards.scss";
import BusCard from "./BusCard";
import BusSort from "../../BusSort/BusSort";

const BusResultCards = (props) => {
  return (
    <div className="results-card-block">
      <BusSort gscontext={props.gscontext} />
      <BusCard
        busResultObject={props.busResultObject}
        busTwowayObject={props.busTwowayObject}
        searchId={props.searchId}
        otherData={props.otherData}
        twowaySearchId={props.twowaySearchId}
        gscontext={props.gscontext}
        handleBusFilter={props.handleBusFilter}
      />
    </div>
  );
};
export default BusResultCards;
