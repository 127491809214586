import { Card, Col, Row, Layout, Skeleton } from "antd";
import React from "react";
import "./Flightticketsuccess.scss";

const { Content } = Layout;

const FlightTicketSucSkeleton = () => {
  return (
    <div className="promo providerapi">
      <Layout className="ourteam-bg">
        <div className="">
          <Content className="admin-container cms-pages-width retrieve-linebar">
            <Row className="fst-book">
              <Col md={24} xs={24}></Col>
            </Row>

            <Row>
              <Col md={24} xs={24}>
                <Card className="retrieve-booking">
                  <Row gutter={16}>
                    <Col md={12} xs={24}>
                      <h4 className="ticket-lti">Flight ltinerary</h4>
                      <Skeleton active />
                    </Col>

                    <Col md={12} xs={24} className="flight-email-dd">
                      <Row gutter={[16, 16]}>
                        <Col md={24} xs={24}>
                          <Skeleton active paragraph={{ rows: 2 }} />
                        </Col>
                        <Col md={24} xs={24}>
                          <Skeleton.Button active size="large" shape="square" />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* iiner--row--close-- */}
                </Card>
              </Col>
            </Row>
          </Content>
        </div>
      </Layout>
    </div>
  );
};

export default FlightTicketSucSkeleton;
