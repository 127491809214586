import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Upload,
  message,
  Modal,
  Layout,
  Row,
} from "antd";
import React, { useEffect, useState } from "react";
import APIClient from "../../helpers/ApiClient";
import "./Banners.scss";
const { Content } = Layout;
const { Panel } = Collapse;
const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/multipleImages";

const Logos = ({
  category,
  fileList,
  setFileList,
  formName,
  limit,
  limitSize,
  deleteBanner,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadLogoButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );

  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    // const checkFileSize = file.size < 256000;
    // if (!checkFileSize) {
    //   message.error(" Image must be smaller than 250kb!");
    // }

    //return checkJpgOrPng && checkFileSize;
    return checkJpgOrPng 
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    if (info.file.status === "uploading") {
      setIsUploading(true);
      return;
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
      setFileList(info.fileList);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setFileList(info.fileList);
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item name={formName}>
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          onRemove={(val) => deleteBanner(val.uid)}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList.length >= limitSize
              ? null
              : uploadLogoButton
            : uploadLogoButton}
        </Upload>
      </Form.Item>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};

const Banners = () => {
  const [homeForm] = Form.useForm();
  const [companyForm] = Form.useForm();

  const [companyLogo, setCompanyLogo] = useState([]);
  const [companyId, setCompanyId] = useState([]);
  const [companyAddress, setCompanyAddress] = useState([]);
  const [homeBanners, setHomeBanners] = useState([]);

  const submitCompanyData = (val) => {
    // BannersTypes enums{
    //   COMPANY_LOGO=1,
    //   ID_PROOF=2,
    //   ADDRESS=3,
    //   HOMEPAGE_BANNERS=4,
    // }
    if (val.companyLogo) {
      if (val.companyLogo.fileList.length > 0) {
        if (val.companyLogo.fileList[0].response.status === 200) {
          let data = {
            imagesPath: [
              val.companyLogo.fileList[0].response.data.filepaths[0],
            ],
            BannerType: 1,
          };
          callPostApi(data);
        }
      }
    }
    if (val.companyId) {
      if (val.companyId.fileList.length > 0) {
        if (val.companyId.fileList[0].response.status === 200) {
          let data = {
            imagesPath: [val.companyId.fileList[0].response.data.filepaths[0]],
            BannerType: 2,
          };
          callPostApi(data);
        }
      }
    }
    if (val.companyAddress) {
      if (val.companyAddress.fileList.length > 0) {
        if (val.companyAddress.fileList[0].response.status === 200) {
          let data = {
            imagesPath: [
              val.companyAddress.fileList[0].response.data.filepaths[0],
            ],
            BannerType: 3,
          };
          callPostApi(data);
        }
      }
    }
  };

  const submitHomepageData = (val) => {
    if (val.homeBanners) {
      if (val.homeBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.homeBanners.fileList.length; i++) {
          if (val.homeBanners.fileList[i].response.status === 200) {
            array.push(val.homeBanners.fileList[i].response.data.filepaths[0]);
          }
        }
        if (array.length > 0) {
          let data = {
            imagesPath: array,
            BannerType: 4,
          };
          callPostApi(data);
        }
      }
    }
  };

  const callPostApi = (data) => {
    APIClient.post("admin/banners", data)
      .then((response) => {
        if (response.status == 200) {
          getBannersList();
          message.success("Banner Uploaded SuccessFully", 3);
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getBannersList();
  }, []);

  const getBannersList = () => {
    APIClient.get("admin/banners")
      .then((res) => {
        if (res.status === 200) {
          let logoList = res.data.filter((item) => item.BannerType === 1);
          if (logoList.length > 0) {
            if (logoList[0].images.length > 0) {
              let data = {
                uid: logoList[0].images[0].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + logoList[0].images[0].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [logoList[0].images[0].path],
                  },
                },
              };
              setCompanyLogo([data]);
            }
          }

          let idList = res.data.filter((item) => item.BannerType === 2);
          if (idList.length > 0) {
            if (idList[0].images.length > 0) {
              let data = {
                uid: idList[0].images[0].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + idList[0].images[0].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [idList[0].images[0].path],
                  },
                },
              };
              setCompanyId([data]);
            }
          }
          let addressList = res.data.filter((item) => item.BannerType === 3);
          if (addressList.length > 0) {
            if (addressList[0].images.length > 0) {
              let data = {
                uid: addressList[0].images[0].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + addressList[0].images[0].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [addressList[0].images[0].path],
                  },
                },
              };
              setCompanyAddress([data]);
            }
          }

          let homeList = res.data.filter((item) => item.BannerType === 4);

          if (homeList.length > 0) {
            let array = [];
            for (let i = 0; i < homeList[0].images.length; i++) {
              array.push({
                uid: homeList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + homeList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [homeList[0].images[i].path],
                  },
                },
              });
            }
            setHomeBanners(array);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const DeleteBannerImage = (id) => {
    APIClient.delete("admin/banners/" + id)
      .then((response) => {
        if (response.status == 200) {
          getBannersList();
          message.success("Deleted Successsfully");
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <Layout>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <Card bordered={false}>
                <h5>Upload Images</h5>
                <Collapse accordion defaultActiveKey={["1"]}>
                  <Panel header="Company Logo's" key="1">
                    <Form
                      form={companyForm}
                      layout="vertical"
                      onFinish={submitCompanyData}
                    >
                      <div className="company-logos">
                        <div className="logo-section">
                          <Row>
                            <Col md={8} xs={24}>
                              <div className="upload-logo">
                                <p>Company Logo</p>

                                <Logos
                                  category="companylogo"
                                  fileList={companyLogo}
                                  setFileList={setCompanyLogo}
                                  formName="companyLogo"
                                  limit={true}
                                  limitSize={1}
                                  deleteBanner={DeleteBannerImage}
                                />
                              </div>
                            </Col>
                            <Col md={8} xs={24}>
                              <div className="upload-logo">
                                <p>ID Proof</p>

                                <Logos
                                  category="companyid"
                                  fileList={companyId}
                                  setFileList={setCompanyId}
                                  formName="companyId"
                                  limit={true}
                                  limitSize={1}
                                  deleteBanner={DeleteBannerImage}
                                />
                              </div>
                            </Col>
                            <Col md={8} xs={24}>
                              <div className="upload-logo">
                                <p>Address Proof</p>

                                <Logos
                                  category="companyaddress"
                                  fileList={companyAddress}
                                  setFileList={setCompanyAddress}
                                  formName="companyAddress"
                                  limit={true}
                                  limitSize={1}
                                  deleteBanner={DeleteBannerImage}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div
                          className="upload-btns"
                          style={{ textAlign: "right" }}
                        >
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </Panel>
                  <Panel header="Home Page Banners" key="2">
                    <Form
                      form={homeForm}
                      layout="vertical"
                      onFinish={submitHomepageData}
                    >
                      <div className="company-logos">
                        <div className="logo-section">
                          <Row>
                            <Col md={8} xs={24}>
                              <div className="upload-logo">
                                <Logos
                                  category="homebanners"
                                  fileList={homeBanners}
                                  setFileList={setHomeBanners}
                                  formName="homeBanners"
                                  limit={true}
                                  limitSize={3}
                                  deleteBanner={DeleteBannerImage}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div
                          className="upload-btns"
                          style={{ textAlign: "right" }}
                        >
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </Panel>
                </Collapse>
              </Card>
            </Col>
          </Row>
        </Content>
      </div>
    </Layout>
  );
};

export default Banners;
