import React from 'react';
import { Button } from 'antd';
import './NavStatic.scss';
import etravos from '../../assets/images/etravosLogo.png';

const NavBarStatic = () => {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <a href="/"><img src={etravos} alt="Brand Logo" /></a>
      </div>
      <div className="navbar-right">
        <Button type="primary" href="/" className="home-btn">
          Home
        </Button>
      </div>
    </nav>
  );
};

export default NavBarStatic;
