import {
  Button,
  Col,
  Collapse,
  Grid,
  Modal,
  Row,
  Skeleton,
  Tabs,
  Tooltip,
} from "antd";
import moment from "moment";
import queryString from "query-string";
import { Link } from "react-router-dom";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { TgContext } from "../../common/providers/TgProvider";
import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import BusSort from "../../pages/Buses/BusSort/BusSort";
import BusFilter from "../../pages/Buses/BusResults/BusFilter/BusFilter";
import BusResultCards from "../../pages/Buses/BusResults/BusResultCards/BusResultCards";
import CombinedOverallSearch from "../CombinedOverallSearch/CombinedOverallSearch";
import CustomNoResultFound from "../ErrorPages/CustomNoResultFound";
import FilterSkeletonLayout from "../FilterSkeleton/FilterSkeleton";
import FlightFilters from "../Flight-Filters/FlightFilters";
import FlightResultsCardSkeleton from "../FlightResultsCardSkeleton/FlightResultsCardSkeleton";
import FlightsList from "../SearchResults/FlightsList";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./CombinedResults.scss";
// import { SeperateResponse } from "./SampleSperateResults";
import BusSkeleton from "./Skeleton/BusSkeleton";
import Sort from "./Sort/Sort";
const { Panel } = Collapse;
const { useBreakpoint } = Grid;
const CombinedResults = ({ gscontext, flightContext }) => {
  const { md } = useBreakpoint();
  const [key, setKey] = useState([]);
  const BASE = process.env.REACT_APP_BASE_URL;
  const { user } = useAuthContext();
  const { logo, tabKey, updateTabKey } = useContext(TgContext);
  const dateFormat = "DD-MM-YYYY";
  const oriDateString = "YYYY-MM-DD";
  let offsetY = 80;
  let param = queryString.parse(document.location.search);
  const getCombinSearchFromQuery = () => {
    let param = queryString.parse(document.location.search);

    // return JSON.parse(param.search);
    let origin = param.origin.split("-");
    let dest = param.destination.split("-");

    return {
      adultCount: param.adult ? Number(param.adult) : 1,
      childCount: param.child ? Number(param.child) : 0,
      classType: param.class ? param.class : "Economy",
      origin: {
        name: origin[0] ? origin[0] : null,
        airports: { airportCodeIATA: origin[1] ? origin[1] : null },
        busStops: { Id: origin[2] ? origin[2] : null },
      },
      fromDate: param.from,
      infantCount: param.infant ? Number(param.infant) : 0,
      destination: {
        name: dest[0] ? dest[0] : null,
        airports: { airportCodeIATA: dest[1] ? dest[1] : null },
        busStops: { Id: dest[2] ? dest[2] : null },
      },
      toDate: param.to,
      tripType: param.tripType ? param.tripType : "oneWay",
    };
  };
  let combineSearchObj = getCombinSearchFromQuery();

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  const handleScroll = () => {
    let elem = document.querySelector(".results_tab .ant-tabs-nav");
    if (!elem) return;
    let rect = elem.getBoundingClientRect();

    offsetY = rect.top;
    if (rect.top < 10) {
      document.getElementById("stickyLogo").style.display = "block";
    } else {
      document.getElementById("stickyLogo").style.display = "none";
    }
  };

  const debounceOnChange = useCallback(debounce(handleScroll, 100), []);
  useEffect(() => {
    window.addEventListener("scroll", debounceOnChange);
    return () => window.removeEventListener("scroll", debounceOnChange);
  });
  const [minValues, setMinValues] = useState({
    minFlightPrice: 0,
    minFlightTime: 0,
    minBusPrice: 0,
    minBusTime: 0,
    minTrainPrice: 0,
    minTrainTime: 0,
  });

  const {
    updateFlightSearchObj,
    resetFlightSelectedData,
    updateFlightAirSearchRespObj,
  } = flightContext;

  const { flightAirSearchResp } = flightContext.state;
  const { flightDetails } = flightAirSearchResp;

  //Bus Variables
  const { busSearchResultObj, airlineMatrixReset } = gscontext.state;
  const {
    updateBusSearchResultObj,
    updateBusField,
    RemovePromo,
    ResetAirlineMatrix,
  } = gscontext;

  let busInitial = {
    trips: [],
    origin: "",
    destination: "",
  };

  const [busOneWayTrips, setBusOneWayTrips] = useState(busInitial);
  const [busTwoWayTrips, setBusTwoWayTrips] = useState(busInitial);
  const [selectedTripType, setSelectedTripType] = useState(1);
  // const [twowayTrips, setTwowayTrips] = useState([]);

  // const [trainsData, setTrainsData] = useState([]);
  // const [totalTrains, setTotalTrains] = useState("0");
  // let trainSelectedInfo = {
  //   depatureStation: { NameEn: "SECUNDERABAD JN", StationCode: "SC" },
  //   arrivalStation: [{ NameEn: "BANGALORE CANT", StationCode: "BNC" }],
  //   departureDate: new Date(),
  //   submitted: true,
  // };
  //gscontext.state.trainInfo;
  // const [sourceDestinationList, setSourceDestinationList] = useState({});
  // const { updateTrainFilters } = gscontext;
  const [modalVisible, setModalVisible] = useState(false);

  const [modalVisiblenew, setModalVisiblenew] = useState(false);

  const [isLoading, setIsLoading] = useState({
    flight: true,
    bus: true,
    train: true,
  });

  const { TabPane } = Tabs;

  const showModalflight = () => {
    setModalVisible({
      visible: true,
    });
  };

  const showModalsort = () => {
    setModalVisiblenew({
      visible: true,
    });
  };

  const searchAllservices = () => {
    // setUpAirsearchAfterApiResponse(SeperateResponse);
    // setIsLoading((prev) => ({ ...prev, flight: false }));
    // return;
    combineSearchObj = getCombinSearchFromQuery();
    localStorage.setItem("travelType", combineSearchObj.tripType);
    if (
      combineSearchObj.origin.airports !== null &&
      combineSearchObj.destination.airports !== null
    ) {
      setIsLoading((prev) => ({ ...prev, flight: true }));

      fetchAirSearch();
    }

    if (
      combineSearchObj.origin.busStops !== null &&
      combineSearchObj.destination.busStops !== null
    ) {
      setIsLoading((prev) => ({ ...prev, bus: true }));
      // setTrips([]);
      // setTwowayTrips([]);
      setBusTwoWayTrips(busInitial);
      setBusOneWayTrips(busInitial);
      getBusesAvailability();
    }

    // if (
    //   combineSearchObj.origin.stations !== null &&
    //   combineSearchObj.destination.stations !== null
    // ) {
    //   setIsLoading((prev) => ({ ...prev, train: true }));
    //   fetchTrains();
    // }
  };

  useEffect(() => {
    searchAllservices();
    RemovePromo();
  }, []);

  const calculateDuration = (from, to) => {


    let FromDate = moment(from);
    let ToDate = moment(to)

    let numberOfHours = ToDate.diff(FromDate, "hours");
    let numerOfMinutes = ToDate.diff(FromDate, "minutes");


    let days = Math.floor(numberOfHours / 24);
    let Remainder = numberOfHours % 24;
    let hours = Math.floor(Remainder);
    let minutes = Math.floor(numerOfMinutes - 60 * (hours));

    let difference = "";
    if (days > 0) {
      difference += days === 1 ? `${days} d, ` : `${days} ds, `;
    }

    difference += hours === 0 || hours === 1 ? `${hours} h ` : `${hours} h `;

    difference +=
      minutes === 0 || hours === 1 ? `${minutes} min` : `${minutes} min`;

    return difference;

     // OLD CODE IT GIVE DURATION AS NAN IN IPHONE

    // let fromTime = from.split("T")[0] + " " + from.split("T")[1];
    // let toTime = to.split("T")[0] + " " + to.split("T")[1];

    // let diffInMilliSeconds =
    //   Math.abs(new Date(fromTime) - new Date(toTime)) / 1000;

    // // calculate days
    // const days = Math.floor(diffInMilliSeconds / 86400);
    // diffInMilliSeconds -= days * 86400;
    // // calculate hours
    // const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    // diffInMilliSeconds -= hours * 3600;

    // // calculate minutes
    // const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    // diffInMilliSeconds -= minutes * 60;

    // let difference = "";
    // if (days > 0) {
    //   difference += days === 1 ? `${days} d, ` : `${days} ds, `;
    // }

    // difference += hours === 0 || hours === 1 ? `${hours} h ` : `${hours} h `;

    // difference +=
    //   minutes === 0 || hours === 1 ? `${minutes} min` : `${minutes} min`;

    //return difference;
  };

  const getMinFlightPrice = (flightDetails) => {
    if (flightDetails.length > 0) {
      let MinValue = flightDetails.reduce((prev, curr) =>
        prev.fareFamilies.fareFamilies[0].adultNetFare <=
          curr.fareFamilies.fareFamilies[0].adultNetFare
          ? prev
          : curr
      );

      let MinTime = calculateDuration(
        MinValue.flightSegments[0].departureDateTime,
        MinValue.flightSegments[MinValue.flightSegments.length - 1]
          .arrivalDateTime
      );
      setMinValues((prev) => ({
        ...prev,
        minFlightPrice: Math.round(
          MinValue.fareFamilies.fareFamilies[0].adultNetFare
        ),

        minFlightTime: MinTime,
      }));
    } else {
      setMinValues((prev) => ({
        ...prev,
        minFlightPrice: 0,
        minFlightTime: "0",
      }));
    }
  };

  const getMinBusPrice = ({ trips }) => {
    let FilteredData = trips.filter((item) => item.isVisible);
    if (FilteredData.length > 0) {
      let MinValue = FilteredData.reduce((prev, curr) =>
        Number(
          prev.startingFare.split("/").sort((a, b) => {
            return Number(a) - Number(b);
          })[0]
        ) <=
          Number(
            curr.startingFare.split("/").sort((a, b) => {
              return Number(a) - Number(b);
            })[0]
          )
          ? prev
          : curr
      );
      let MinTime = MinValue.duration;
      MinValue = MinValue.startingFare.split("/").sort((a, b) => {
        return Number(a) - Number(b);
      })[0];

      setMinValues((prev) => ({
        ...prev,
        minBusPrice: MinValue,
        minBusTime: MinTime,
      }));
    } else {
      setMinValues((prev) => ({
        ...prev,
        minBusPrice: 0,
        minBusTime: "0",
      }));
    }
  };

  const setUpAirsearchAfterApiResponse = (resp) => {
    //temporary added because results coming around 1000
    // resp.data.flightDetails = resp.data.flightDetails.filter(
    //   (item, i) => i < 30
    // );

    if (resp.statusCode === 200) {
      updateFlightAirSearchRespObj({
        ...resp.data,
        tripType: 1,
        flightDetails: resp.data.flightDetails.map((flight) => {
          return { ...flight, isVisible: true };
        }),
        ibFlightDetails: resp.data.ibFlightDetails.map((flight) => {
          return { ...flight, isVisible: true };
        }),
      });
      updateFlightSearchObj("traceId", resp.data.traceId);
      updateFlightSearchObj("resultsType", resp.data.resultsType);
      updateFlightSearchObj("searchId", resp.data.searchId);
      updateFlightSearchObj("cabinClass", combineSearchObj.classType);
      localStorage.setItem("resultsType", resp.data.resultsType);
      let searchResp = {
        traceId: resp.data.traceId,
        airTravelType: resp.data.airTravelType,
        resultsType: resp.data.resultsType,
        adultCount: resp.data.adultCount,
        childCount: resp.data.childCount,
        infantCount: resp.data.infantCount,
      };
      localStorage.setItem("searchResp", JSON.stringify(searchResp));
      getMinFlightPrice(resp.data.flightDetails);
    } else {
      console.log("got internal server error");
    }
  };

  /* Flight AirSearch API */
  const fetchAirSearch = () => {
    resetFlightSelectedData();
    let userID = 1;
    if (user) {
      if (user.UserID) {
        userID = user.UserID;
      }
    }

    let AirSearchReqObj = {
      originDestinations: [
        {
          departureDateTime: combineSearchObj.fromDate + "T00:00:00",
          origin: combineSearchObj.origin.airports.airportCodeIATA,
          destination: combineSearchObj.destination.airports.airportCodeIATA,
          flightDateFlex: 0,
        },
      ],
      adultCount: combineSearchObj.adultCount,
      childCount: combineSearchObj.childCount,
      infantCount: combineSearchObj.infantCount,
      cabinClass: combineSearchObj.classType,
      includeCarrier: null,
      excludeCarrier: null,
      stopOver: "None",
      airTravelType: combineSearchObj.tripType,
      flightDateFlex: 0,
      itineraryViewType: "1",
      userId: userID,
    };
    if (combineSearchObj.tripType === "roundTrip") {
      AirSearchReqObj.originDestinations.push({
        departureDateTime: combineSearchObj.toDate + "T00:00:00",
        origin: combineSearchObj.destination.airports.airportCodeIATA,
        destination: combineSearchObj.origin.airports.airportCodeIATA,
        flightDateFlex: 0,
      });
    }

    ApiClient.post("flights/airSearch", AirSearchReqObj)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        setUpAirsearchAfterApiResponse(resp);

        if (resp.statusCode === 400 || resp.statusCode === 404) {
          setMinValues((prev) => ({
            ...prev,
            minFlightPrice: 0,
            minFlightTime: 0,
          }));
        }
        setIsLoading((prev) => ({ ...prev, flight: false }));
      })
      .catch((err) => {
        setIsLoading((prev) => ({ ...prev, flight: false }));
      });
  };

  /* Buses Search Availability */
  const getBusesAvailability = async () => {
    let roleType = 3;

    let userID = 1;
    if (user) {
      if (user.UserID) {
        userID = user.UserID;
      }
    }
    if (userID != 1) {
      roleType = 2;
    }
    let origin = combineSearchObj.origin.name;
    let destination = combineSearchObj.destination.name;
    let onewayAvailabilityReqObj = {
      tripType: 1,
      sourceId: combineSearchObj.origin.busStops.Id,
      destinationId: combineSearchObj.destination.busStops.Id,
      journeyDate: moment(combineSearchObj.fromDate, oriDateString).format(
        dateFormat
      ),
      roleType: roleType,
      returnDate: "",
    };
    updateBusField("type", "oneWay");
    await fetchBusAvailability(
      onewayAvailabilityReqObj,
      origin,
      destination,
      1
    );
    if (combineSearchObj.tripType === "roundTrip") {
      let twowayAvailabilityReqObj = {
        tripType: 1,
        sourceId: combineSearchObj.destination.busStops.Id,
        destinationId: combineSearchObj.origin.busStops.Id,
        journeyDate: moment(combineSearchObj.toDate, oriDateString).format(
          dateFormat
        ),
        roleType: roleType,
        returnDate: "",
      };
      await fetchBusAvailability(
        twowayAvailabilityReqObj,
        destination,
        origin,
        2
      );
    }
  };

  function busTypeFilter(busType) {
    let arr = [];
    busType = busType.toUpperCase();

    if (
      (busType.toUpperCase().indexOf("A/C") >= 0 ||
        busType.toUpperCase().indexOf("AC") >= 0) &&
      busType.toUpperCase().indexOf("NON") === -1
    ) {
      arr.push("AC");
    }

    if (
      busType.toUpperCase().indexOf("NONAC") >= 0 ||
      busType.toUpperCase().indexOf("NON AC") >= 0 ||
      busType.toUpperCase().indexOf("NON-AC") >= 0 ||
      busType.toUpperCase().indexOf("NON A/C") >= 0
    ) {
      arr.push("NONA/C");
    }

    if (busType.toUpperCase().indexOf("SLEEPER") >= 0) {
      arr.push("SLEEPER");
    }

    if (busType.toUpperCase().indexOf("SEATER") >= 0) {
      arr.push("SEATER");
    }

    return arr;
  }

  const fetchBusAvailability = async (
    busAvailabilityReqObj,
    origin,
    destination,
    type
  ) => {
    setIsLoading((prev) => ({ ...prev, bus: true }));
    await ApiClient.post("buses/availability/price", busAvailabilityReqObj)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.statusCode === 200) {
          if (resp.data.trips != {}) {
            resp = {
              ...resp,
              data: {
                ...resp.data,
                trips: resp.data.trips.map((trip) => ({
                  ...trip,
                  isVisible: true,
                  busTypeFilters: busTypeFilter(trip.busType),
                })),
                origin: origin,
                destination: destination,
              },
            };

            if (type === 2) {
              setBusTwoWayTrips(resp.data);
              updateBusField("type", "twoWay");
            } else {
              updateBusSearchResultObj({ ...resp, tripType: type });
              setBusOneWayTrips(resp.data);
            }
          }
        } else if (resp.statusCode === 500) {
          setMinValues((prev) => ({
            ...prev,
            minBusPrice: 0,
            minBusTime: "0",
          }));
        }

        setIsLoading((prev) => ({ ...prev, bus: false }));
      })
      .catch((err) => {
        setBusTwoWayTrips(busInitial);
        setBusOneWayTrips(busInitial);
        setIsLoading((prev) => ({ ...prev, bus: false }));
        setMinValues((prev) => ({
          ...prev,
          minBusPrice: 0,
          minBusTime: "0",
        }));
      });
  };

  const handleBusFilter = (type) => {
    if (type === 2) {
      updateBusSearchResultObj({
        data: { ...busTwoWayTrips },
        tripType: 2,
        reset: true,
      });
    } else {
      updateBusSearchResultObj({
        data: { ...busOneWayTrips },
        tripType: 1,
        reset: true,
      });
    }
  };

  useEffect(() => {
    if (Object.keys(busSearchResultObj).length > 0 && busSearchResultObj.data) {
      if (busSearchResultObj.tripType === 2) {
        setBusTwoWayTrips(busSearchResultObj.data);
        getMinBusPrice(busSearchResultObj.data);
      } else {
        setBusOneWayTrips(busSearchResultObj.data);
        getMinBusPrice(busSearchResultObj.data);
      }
    }
  }, [busSearchResultObj]);

  /* Trains Search */
  // const fetchTrains = () => {
  //   let fromCode = "SC";
  //   let toCode = "BNC";
  //   ApiClient.get(`trains/TrainBetweenStation/From/${fromCode}/To/${toCode}`)
  //     .then((result) => {
  //       return result;
  //     })
  //     .then((resp) => {
  //       setIsLoading((prev) => ({ ...prev, train: false }));
  //       if (resp.statusCode === "201") {
  //         setTrainsData(resp.Trains);
  //         setTotalTrains(resp.TotalTrains);
  //         setSourceDestinationList({
  //           sources: resp.Trains.map((item) => item.Source).filter(
  //             (value, index, self) => self.indexOf(value) === index
  //           ),
  //           destinations: resp.Trains.map((item) => item.Destination).filter(
  //             (value, index, self) => self.indexOf(value) === index
  //           ),
  //         });
  //       }
  //     })
  //     .catch((err) => err);
  // };

  const onModifySearch = () => {
    searchAllservices();
  };

  return (
    <>
      <div className="combined-results-container">
        <div className="modify-combined-search-container">
          <div className="modify-combined-block">
            <Collapse
              activeKey={md ? ["1"] : key}
              showArrow={false}
              onChange={(val) => {
                setKey(val);
              }}
            >
              <Panel
                showArrow={false}
                header={<span class="hotels-hide-search">Modify Search</span>}
                key="1"
              >
                <CombinedOverallSearch
                  gscontext={gscontext}
                  flightContext={flightContext}
                  modify={true}
                  onModify={onModifySearch}
                  param={param}
                />
              </Panel>
            </Collapse>
          </div>
        </div>

        <div className="fbt-tabs-bg tabs-fbt-space">
          <div className="tabs-container">
            <Tabs
              activeKey={tabKey}
              defaultActiveKey="2"
              onTabClick={(key) => updateTabKey(key)}
              className="results_tab"
              tabBarExtraContent={{
                left: (
                  <div id="stickyLogo" className="logo tabs-extra-demo-button">
                    <Link to="/">
                      {logo && (
                        <img src={BASE + logo.substring(1)} alt="tglogo" />
                      )}
                    </Link>
                  </div>
                ),
              }}
            >
              <TabPane
                tab={
                  <span className="tab-blocks">
                    <i className="fa fa-bus"></i> Buses
                    <div className="hous-fbt">
                      {isLoading.bus ? (
                        <Skeleton.Button active size="small" />
                      ) : (
                        <>
                          <p>
                            {`Rs. ${minValues.minBusPrice} | ${minValues.minBusTime}`}
                          </p>
                          <Tooltip
                            placement="topLeft"
                            overlayClassName="locTooltip"
                            title={<span>Duration for cheapest trip - Rs</span>}
                          >
                            <InfoCircleOutlined />
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </span>
                }
                key="2"
              >
                <Row gutter={16} className="margins-alignments">
                  {isLoading.bus ? (
                    <>
                      <Col md={6} className="filter-section-bus">
                        <FilterSkeletonLayout />
                      </Col>
                      <Col md={18} sm={24} xs={24}>
                        <BusSkeleton />
                      </Col>
                    </>
                  ) : busOneWayTrips.trips.length > 0 ||
                    busTwoWayTrips.trips.length > 0 ? (
                    <>
                      <Col md={6} className="filter-section-bus">
                        {Object.keys(busSearchResultObj).length > 0 ? (
                          busSearchResultObj.data &&
                            busSearchResultObj.data.trips.length > 0 ? (
                            <BusFilter
                              busSearchResultObj={busSearchResultObj}
                              updateBusSearchResultObj={
                                updateBusSearchResultObj
                              }
                              count={
                                busSearchResultObj.tripType === 1
                                  ? busOneWayTrips.trips.length
                                  : busTwoWayTrips.trips.length
                              }
                            />
                          ) : null
                        ) : null}
                      </Col>
                      <Col md={18} sm={24} xs={24}>
                        <BusResultCards
                          setMinValues={setMinValues}
                          busResultObject={busOneWayTrips.trips}
                          busTwowayObject={busTwoWayTrips.trips}
                          searchId={busOneWayTrips.searchId}
                          otherData={{
                            origin: busOneWayTrips.origin,
                            destination: busOneWayTrips.destination,
                            origin2: busTwoWayTrips.origin,
                            destination2: busTwoWayTrips.destination,
                          }}
                          twowaySearchId={busTwoWayTrips.searchId}
                          gscontext={gscontext}
                          handleBusFilter={handleBusFilter}
                        />
                      </Col>
                    </>
                  ) : (
                    <Col md={24} sm={24} xs={24}>
                      <CustomNoResultFound title={"No Buses Available"} />
                    </Col>
                  )}
                </Row>
              </TabPane>

              <TabPane
                tab={
                  <span className="tab-blocks">
                    <i className="fa fa-plane"></i> Flights
                    <div className="hous-fbt">
                      {isLoading.flight ? (
                        <Skeleton.Button active size="small" />
                      ) : (
                        <>
                          <p>
                            {`Rs. ${minValues.minFlightPrice} | ${minValues.minFlightTime}`}
                          </p>
                          <Tooltip
                            placement="topLeft"
                            overlayClassName="locTooltip"
                            title={<span>Duration for cheapest trip - Rs</span>}
                          >
                            <InfoCircleOutlined />
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </span>
                }
                key="1"
              >
                <Row gutter={16} className="margins-alignments">
                  {isLoading.flight ? (
                    <>
                      <Col md={6} xs={0} className="filter-section-flight">
                        <FilterSkeletonLayout />
                      </Col>
                      <Col md={18} xs={24}>
                        <div className="flight-results-skeleton-container">
                          <div className="flights-skeleton-card">
                            <FlightResultsCardSkeleton />
                            <FlightResultsCardSkeleton />
                            <FlightResultsCardSkeleton />
                            <FlightResultsCardSkeleton />
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : Object.keys(flightAirSearchResp).length === 0 ||
                    flightAirSearchResp.flightDetails.length === 0 ? (
                    <Col md={24} sm={24} xs={24}>
                      <CustomNoResultFound title={"No Flights Available"} />
                    </Col>
                  ) : (
                    <>
                      <Col md={6} xs={0} className="filter-section-flight">
                        {flightDetails && (
                          <FlightFilters
                            updateFlightAirSearchRespObj={
                              flightContext.updateFlightAirSearchRespObj
                            }
                            ResetAirlineMatrix={ResetAirlineMatrix}
                            selectedTripType={selectedTripType}
                            flightAirSearchResp={flightAirSearchResp}
                          />
                        )}
                      </Col>
                      <Col md={18} xs={24}>
                        <>
                          <div className="flight-sort-container">
                            <Sort
                              flightContext={flightContext}
                              airlineMatrixReset={airlineMatrixReset}
                              ResetAirlineMatrix={ResetAirlineMatrix}
                              selectedTripType={selectedTripType}
                            />
                          </div>
                          <FlightsList
                            setSelectedTripType={setSelectedTripType}
                            flightContext={flightContext}
                            flightAirSearchResp={flightAirSearchResp}
                            updateFlightAirSearchRespObj={
                              flightContext.updateFlightAirSearchRespObj
                            }
                            getMinFlightPrice={getMinFlightPrice}
                          />
                        </>
                      </Col>
                    </>
                  )}
                </Row>

                {/* <Row gutter={32} className="margins-alignments">
                        <Col md={6} xs={0} className="filter-section-flight">
                          {isLoading.flight &&
                          Object.keys(flightAirSearchResp).length === 0 ? (
                          
                            <FilterSkeletonLayout />
                          ) : (
                            <>
                              {flightDetails && (
                                <FlightFilters
                                  flightContext={flightContext}
                                  isCombined={true}
                                />
                              )}
                            </>
                          )}
                        </Col>
                        <Col md={18} xs={24}>
                          <div className="flights-list-container">
                            {isLoading.flight &&
                            Object.keys(flightAirSearchResp).length === 0 ? (
                              <div className="flight-results-skeleton-container">
                                <div className="flights-skeleton-card">
                                  <FlightResultsCardSkeleton />
                                  <FlightResultsCardSkeleton />
                                  <FlightResultsCardSkeleton />
                                  <FlightResultsCardSkeleton />
                                </div>
                              </div>
                            ) : (
                              <>
                                {(!isLoading.flight &&
                                  Object.keys(flightAirSearchResp).length ===
                                    0) ||
                                flightAirSearchResp.flightDetails.length ===
                                  0 ? (
                                  <CustomNoResultFound
                                    title={"No Flights Available"}
                                  />
                                ) : (
                                  <>
                                    <div className="flight-sort-container">
                                      <Sort
                                        flightContext={flightContext}
                                      />
                                    </div>
                                    <FlightsList
                                      flightContext={flightContext}
                                      flightAirSearchResp={flightAirSearchResp}
                                      updateFlightSearchObj={
                                        flightContext
                                          .updateFlightSearchObj
                                      }
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </Col>
                      </Row> */}
              </TabPane>

              {/* <TabPane
                                  tab={
                                    <span className="tab-blocks">
                                      <i className="fa fa-train"></i> Trains
                                      <p
                                        style={{
                                          marginBottom: "0px",
                                          marginLeft: "31px",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {isLoading.train ? (
                                          <Skeleton.Button
                                            active
                                            size="small"
                                          />
                                        ) : (
                                          `Rs. ${minValues.minTrainPrice}`
                                        )}
                                      </p>
                                    </span>
                                  }
                                  key="3"
                                >
                                  <Row gutter={32}>
                                    <Col md={6} className="filters-sec-trains">
                                      <TrainFilters
                                        count={totalTrains}
                                        updateTrainFilters={updateTrainFilters}
                                        sourceDestinationList={
                                          sourceDestinationList
                                        }
                                      />
                                    </Col>
                                    <Col md={18} sm={24} xs={24}>
                                      <Traincard
                                        data={trainsData}
                                        selectedInfo={trainSelectedInfo}
                                      />
                                    </Col>
                                  </Row>
                                </TabPane> */}
            </Tabs>
          </div>
        </div>
      </div>

      {
        tabKey === "1" ? (
          <Row className="fiters_wrapper">
            <Col md={12} xs={12} className="tc">
              <span className="title" onClick={showModalflight}>
                {" "}
                <i className="fa fa-filter" aria-hidden="true"></i>
                &nbsp;Flight Filters
              </span>
            </Col>
            <Col md={12} xs={12} className="tc">
              <span className="title" onClick={showModalsort}>
                <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                &nbsp;Sort by
              </span>
            </Col>
          </Row>
        ) : tabKey === "2" ? (
          <Row className="fiters_wrapper">
            <Col md={12} xs={12} className="tc">
              <span className="title" onClick={showModalflight}>
                {" "}
                <i className="fa fa-filter" aria-hidden="true"></i>
                &nbsp;Bus Filters
              </span>
            </Col>
            <Col md={12} xs={12} className="tc">
              <span className="title" onClick={showModalsort}>
                <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                &nbsp;Sort by
              </span>
            </Col>
          </Row>
        ) : null
        // <Row className="fiters_wrapper">
        //   <Col md={12} xs={12} className="tc">
        //     <h5 className="title" onClick={showModalflight}>
        //       {" "}
        //       <i className="fa fa-filter" aria-hidden="true"></i>
        //       &nbsp;Train Filters
        //     </h5>
        //   </Col>
        //   <Col md={12} xs={12} className="tc">
        //     <h5 className="title" onClick={showModalsort}>
        //       <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
        //       &nbsp;Sort by
        //     </h5>
        //   </Col>
        // </Row>
      }

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong> {tabKey === "1" ? "Flight" : "Bus"} Filters</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button type="primary" onClick={() => setModalVisible(false)}>
              Close
            </Button>
          </div>,
        ]}
      >
        <Row>
          <Col md={24} xs={24}>
            {tabKey === "1" ? (
              isLoading.flight ? (
                <FilterSkeletonLayout />
              ) : Object.keys(flightAirSearchResp).length === 0 ||
                flightAirSearchResp.flightDetails.length === 0 ? null : (
                flightDetails && (
                  <FlightFilters
                    // flightContext={flightContext}
                    // isCombined={true}
                    // ResetAirlineMatrix={ResetAirlineMatrix}
                    updateFlightAirSearchRespObj={
                      flightContext.updateFlightAirSearchRespObj
                    }
                    ResetAirlineMatrix={ResetAirlineMatrix}
                    selectedTripType={selectedTripType}
                    flightAirSearchResp={flightAirSearchResp}
                  />
                )
              )
            ) : tabKey === "2" ? (
              isLoading.bus ? (
                <FilterSkeletonLayout />
              ) : busOneWayTrips.trips.length > 0 ||
                busTwoWayTrips.trips.length > 0 ? (
                Object.keys(busSearchResultObj).length > 0 ? (
                  busSearchResultObj.data &&
                    busSearchResultObj.data.trips.length > 0 ? (
                    <BusFilter
                      busSearchResultObj={busSearchResultObj}
                      updateBusSearchResultObj={updateBusSearchResultObj}
                      count={
                        busSearchResultObj.tripType === 1
                          ? busOneWayTrips.trips.length
                          : busTwoWayTrips.trips.length
                      }
                    />
                  ) : null
                ) : null
              ) : null
            ) : null}
          </Col>
        </Row>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Sort by</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisiblenew}
        onOk={(e) => setModalVisiblenew(false)}
        onCancel={(e) => setModalVisiblenew(false)}
        footer={[
          <div>
            <Button type="primary" onClick={() => setModalVisiblenew(false)}>
              Close
            </Button>
          </div>,
        ]}
      >
        {tabKey === "1" ? (
          isLoading.flight ? (
            "Loading"
          ) : Object.keys(flightAirSearchResp).length === 0 ||
            flightAirSearchResp.flightDetails.length === 0 ? null : (
            <div className="mobflightsort">
              <Sort
                flightContext={flightContext}
                airlineMatrixReset={airlineMatrixReset}
                ResetAirlineMatrix={ResetAirlineMatrix}
                selectedTripType={selectedTripType}
              />
            </div>
          )
        ) : tabKey === "2" ? (
          isLoading.bus ? (
            "Loading"
          ) : busOneWayTrips.trips.length > 0 ||
            busTwoWayTrips.trips.length > 0 ? (
            <div className="mobBusSortWrapper">
              <BusSort gscontext={gscontext} />
            </div>
          ) : (
            "  loading"
          )
        ) : (
          ""
        )}
      </Modal>
    </>
  );
};

export default CombinedResults;
