import React, { useEffect, useState } from "react";
import { Button, Form, message, Radio, Space } from "antd";

const loadScript = (src) => {
    return new Promise(resolve => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}

const CashFreeNewVersionCheckout = ({ pgData }) => {
    useEffect(() => {
        if (pgData?.paymentsessionid) {
          displayCashFreeNew();
        } else {
          console.error("Payment session ID is missing in pgData");
        }
      }, [pgData]);

    // useEffect(() => {
    //     displayCashFreeNew()
    // }, [])

    const displayCashFreeNew = async () => {

        const scriptRes = await loadScript(process.env.REACT_APP_CASH_FREE_SCRIPT)
        // const scriptRes = await loadScript(`https://sdk.cashfree.com/js/ui/2.0.0/cashfree.sandbox.js`)
        // const scriptRes = await loadScript(`https://sdk.cashfree.com/js/ui/2.0.0/cashfree.prod.js`)
        if (!scriptRes) {
            alert("Failed To Load SDK")
            return
        }

        const orderResp = pgData

        const paymentSessionId = orderResp.paymentsessionid; //cashfree session_id_link
        const cf = new window.Cashfree(paymentSessionId);
        cf.redirect();

    }

    return (
        <div>
            {/* <Button className="payment-btn" onClick={() => displayRazorpay()}>
                Continue Payment
            </Button> */}
            {/* <button id="rzp-button1">Pay</button> */}
            {/* <button onClick={displayRazorpay}>Pay with Razorpay</button> */}
        </div>
    )

}

export default CashFreeNewVersionCheckout