import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Collapse,
  Col,
  Row,
  Form,
  Select,
  DatePicker,
  Input,
  Spin,
} from "antd";

import moment from "moment";
import { useHistory } from "react-router-dom";
import { TgContext } from "../../common/providers/TgProvider";
import GlobalStatesContext from "../../common/providers/GlobalStatesContext";
import ApiClient from "../../helpers/ApiClient";
import { useAuthContext } from "../../common/providers/AuthProvider";
import BookPayCard from "./Book-Pay-Card";
import FareSummaryCard from "./FareSummaryCard";
import FlightDetailsCard from "./Flight-Details-Card";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./FlightsCheckout.scss";
import { PaxAgeValidator } from "../../helpers/CustomValidators";
import { FlightGrandTotalCard } from "./FlightGrandTotalCard";
import { AirpriceReponse } from "./SampleAirpriceResponse";
import { getPassengerData } from "../../helpers/PassegerData";
const FlightCheckout = (props) => {

  const { user } = useAuthContext();
  let history = useHistory();
  let dateformat = "DD-MM-YYYY";
  let oriDateFormat = "YYYY-MM-DD";
  const [isGstRequired, setIsGstRequired] = useState(false)
  const validateMessages = {
    required: "",
  };

  let selectedFlightData = props.flightContext.state.selectedFlight; // Selected FLight obj from state


  let flightSearchData = props.flightContext.state.flightSearchObj; //flight search obj from state
  const { updateFlightAirPrice } = props.flightContext;
  let searchValue = JSON.parse(localStorage.getItem("searchResp"));
  const [airPriceResp, setAirPriceResp] = useState({ flightDetails: [] });
  const [airPriceMainResp, setAirPriceMainResp] = useState(null);
  const [totalPaxArr, setTotalPaxArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [passengersArr, setPassengersArr] = useState([]);
  const [contactDetails, setContactDetails] = useState({
    mobile: "",
    areaCode: "",
    email: "",
    address1: "",
    city: "",
    state: "",
    countryName: "",
    countryCode: "",
    postalCode: "",
  });

  useEffect(() => {
    loadpassengerData();
  }, []);

  const loadpassengerData = () => {
    let userID = 1;
    if (user) {
      if (user.UserID) {
        userID = user.UserID;
      }
    }
    if (userID != 1) {
      getPassengerData(userID).then((data) => {
        if (data.status) {
          contact_form.setFieldsValue({
            mobile: data.Mobile,
            email: data.Email,
          });
        }
      });
    }
  };
  const [gstDetails, setGstdetails] = useState({
    companyName: "",
    gstNumber: "",
    contactNumber: "",
    companyEmail: "",
    companyAddress: "",
  });
  const [contact_form] = Form.useForm();
  const [passenger_form] = Form.useForm();
  const [gst_form] = Form.useForm();
  let resultsType = localStorage.getItem("resultsType");
  let travelType = localStorage.getItem("travelType");

  const getAirPrice = () => {
    let airPriceReqObj = {
      traceId: localStorage.getItem("traceId"), //flightSearchData.traceId,
      flightIds:
        travelType === "roundTrip" && resultsType === "Combined"
          ? [selectedFlightData[0].flightId]
          : selectedFlightData.map((flightID) => flightID.flightId),
      airTravelType: localStorage.getItem("travelType"), //flightSearchData.airTravelType,
      mappingType: localStorage.getItem("resultsType"),
      itineraryViewType: "1",
      gstDetails: {
        gstAddressLine1: "",
        gstAddressLine2: "",
        gstCity: "",
        gstState: "",
        gstpinCode: "",
        gstEmailId: "",
        gstNumber: "",
        gstPhoneNo: "",
        gstCompanyName: "",
      },
    };

    ApiClient.post("flights/airPrice", airPriceReqObj)
      .then((resp) => {
        return resp;
      })
      .then((resp) => {
        //For Debug
        // setUpFromApiResponse(AirpriceReponse);
        //end of Debug
        setUpFromApiResponse(resp);
        //let isGstRequired = false
        setIsGstRequired(resp.data.flightDetails[0].fareFamilies[0].isGstMandatory)

      })
      .catch((err) => console.log(err));
  };

  const setUpFromApiResponse = (resp) => {
    if (resp.statusCode === 200) {
      setAirPriceMainResp(resp);
      setAirPriceResp(resp.data);
      paxInfoSections(resp.data);
      updateFlightAirPrice(resp.data);
    } else if (resp.statusCode === 400) {
      setIsLoading(true);
    }
  };
  useEffect(() => {
    getAirPrice();
  }, []);

  const paxInfoSections = (airPriceRespObj) => {
    let paxInfoArr = [];
    for (let i = 0; i < searchValue.adultCount; i++) {
      let paxObj = {
        type: "ADT",
      };
      paxInfoArr.push(paxObj);
    }

    for (let i = 0; i < searchValue.childCount; i++) {
      let paxObj = {
        type: "CHD",
      };
      paxInfoArr.push(paxObj);
    }

    for (let i = 0; i < searchValue.infantCount; i++) {
      let paxObj = {
        type: "INF",
      };
      paxInfoArr.push(paxObj);
    }

    paxInfoArr.map((obj, index) => {
      let singlePaxObj = {
        title: "Mr",
        firstName: "",
        lastName: "",
        paxType: "",
        id: index,
      };

      airPriceRespObj.passengerRequiredFields
        .filter((requiredFiledsObj) => requiredFiledsObj.paxType === obj.type)
        .map((paxReqFieldsObj, i) => {
          Object.keys(paxReqFieldsObj).map((paxReqKeys) => {
            if (
              paxReqFieldsObj[paxReqKeys] === true &&
              paxReqKeys !== "address"
            ) {
              singlePaxObj[paxReqKeys] = "";
              if (
                paxReqKeys === "passengerNationality" ||
                paxReqKeys === "passportIssuedCountry"
              ) {
                singlePaxObj[paxReqKeys] = "IN";
              }
            }
          });
          singlePaxObj["paxType"] = paxReqFieldsObj.paxType;
        });
      totalPaxArr.push(singlePaxObj);

    });

    setIsLoading(true);
    setTotalPaxArr(totalPaxArr);
  };

  const { Panel } = Collapse;

  function callback(key) {
    console.log(key);
  }

  const collapseHeader = (
    <div className="add-gst-text">
      <h4>Add Your GST Details</h4>
      <small>{isGstRequired == true ? '(Required)' : '(Optional)'}</small>
    </div>
  );

  // const { Option } = Select;

  // const FFNnumber = (
  //   <div>
  //     <span>
  //       Optional <small>(Frequent Flyer Number)</small>
  //     </span>
  //   </div>
  // );

  const handlePaxField = (e, paxObjIndex, key) => {

    let paxObj = {};
    paxObj[key] = e;
    passengersArr.push(paxObj);
    totalPaxArr[paxObjIndex][key] = e;
    setPassengersArr(passengersArr);

  };

  const handleContactDetails = (e, key) => {
    contactDetails[key] = e;
    setContactDetails(contactDetails);
  };
  const getGstDetails = (e, key) => {
    gstDetails[key] = e;
    setGstdetails(gstDetails);
  };

  const redirectToPreview = () => {
    contact_form
      .validateFields()
      .then((contact_data) => {

        passenger_form
          .validateFields()
          .then((passeger_data) => {
            // onFlicghtCheckoutValidated(contact_data);
            gst_form
              .validateFields()
              .then((gstDetails) => {
                onFlicghtCheckoutValidated(
                  contact_data,
                  gstDetails,
                );
              })
              .catch((e) => {
                gst_form.scrollToField(e.errorFields[0].name);
              })
          })

          .catch((e) => {
            passenger_form.scrollToField(e.errorFields[0].name);
          });
      })
      .catch((e) => {
        if (!e.errorFields) {
          return;
        }
        contact_form.scrollToField(e.errorFields[0].name);
      });
  };

  const onFlicghtCheckoutValidated = (contactDetails, gstDetails) => {
    contactDetails.areaCode = "+91";
    if (!contactDetails.hasOwnProperty("countryName")) {
      contactDetails.countryName = "";
    }

    totalPaxArr.map((passengersObj) => {
      passengersObj["email"] = contactDetails.email;
      passengersObj["mobile"] = contactDetails.mobile;
      passengersObj["areaCode"] = contactDetails.areaCode;
      passengersObj["address_CountryCode"] = contactDetails.areaCode;
      passengersObj["address1"] = contactDetails.address1;
      passengersObj["city"] = contactDetails.city;
      passengersObj["countryName"] = contactDetails.countryName;
      passengersObj["countryCode"] = contactDetails.areaCode;
      passengersObj["state"] = contactDetails.state;
      passengersObj["postalCode"] = contactDetails.postalCode;
      passengersObj["additionalServicesIds"] = [""];
      delete passengersObj["gender"];
      //delete passengersObj["address"]; //remove this statement after resolve this key from BE
      delete passengersObj["id"];
      return passengersObj;
    });

    let userID = 1;
    if (user) {
      if (user.UserID) {
        userID = user.UserID;
      }
    }

    let airBookReqObj = {
      traceId: localStorage.getItem("traceId"),
      userId: userID,
      passengers: totalPaxArr,
      gstDetails: {
        gstAddressLine1: gstDetails.companyaddress ?? "",
        gstAddressLine2: "",
        gstCity: "",
        gstState: "",
        gstpinCode: "",
        gstEmailId: gstDetails.companyemail ?? "",
        gstNumber: gstDetails.gstnumber ?? "",
        gstPhoneNo: gstDetails.contactnumber ?? "",
        gstCompanyName: gstDetails.companyName ?? "",
      },
    };
    props.flightContext.updateAirBookState(airBookReqObj);
    history.push("/flight-review");
  };
  const disabledFutureDate = (currentDate) => {
    return currentDate > moment().startOf("day");
  };

  const PassengerFields = ({ noOfPax, paxIndex }) => {
    return (
      <Card className="flight-cards-details" key={paxIndex}>
        <div className="card-adult-text">
          <div className="pax-heading">
            {noOfPax.paxType === "ADT"
              ? "Adult"
              : noOfPax.paxType === "CHD"
                ? "Child"
                : "Infant"}
          </div>
        </div>
        <Row gutter={[16, 8]}>
          <Col md={8} sm={8} xs={24}>
            <Form.Item
              name={"title" + paxIndex}
              label="Title"
              rules={[
                { required: true, message: "Specify Title" },
                // { validator: (r, v) => validatePaxTitle(paxIndex.paxType, v) },
              ]}
            >
              <Select
 autocomplete="newpassword" 
                placeholder="Choose"
                onChange={(e) => handlePaxField(e, paxIndex, "title")}
                rules={[{ required: true, message: "Required" }]}
              >
                <Select.Option value="MR">Mr</Select.Option>
                <Select.Option value="MRS">Mrs</Select.Option>
                <Select.Option value="Ms">Ms</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={8} sm={8} xs={24}>
            <Form.Item
              name={"firstName" + paxIndex}
              label="First Name"
              rules={[
                { required: true, message: "Required" },
                { min: 2, message: "First Name must be minimum 2 characters." },
                {
                  max: 32,
                  message: "First Name must be maximum 32 characters.",
                },
              ]}
            >
              <Input
 autocomplete="newpassword" 
                className="inputbg"
                onChange={(e) =>
                  handlePaxField(e.target.value, paxIndex, "firstName")
                }
                key={`${Math.floor(Math.random() * 1000)}-min`}
              />
            </Form.Item>
          </Col>
          <Col md={8} sm={8} xs={24}>
            <Form.Item
              name={"lastName" + paxIndex}
              label="Last Name"
              rules={[
                { required: true, message: "Required" },
                { min: 2, message: "Last Name must be minimum 2 characters." },
                {
                  max: 32,
                  message: "Last Name must be maximum 32 characters.",
                },
              ]}
            >
              <Input
 autocomplete="newpassword" 
                className="inputbg"
                onChange={(e) =>
                  handlePaxField(e.target.value, paxIndex, "lastName")
                }
                key={`${Math.floor(Math.random() * 1000)}-min`}
              />
            </Form.Item>
          </Col>
          <Col md={8} sm={8} xs={24}>
            <Form.Item
              label="Gender"
              rules={[{ required: true, message: "Required" }]}
              name={"gender" + paxIndex}
            >
              <Select
 autocomplete="newpassword" 
                placeholder="Choose"
                onChange={(e) => handlePaxField(e, paxIndex, "genderType")}
                key={`${Math.floor(Math.random() * 1000)}-min`}
              >
                <Select.Option value="m">Male</Select.Option>
                <Select.Option value="f">Female</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={8} sm={8} xs={24}>
            <Form.Item
              label="Date of birth"
              rules={[
                { required: true, message: "Required" },
                { validator: (r, v) => PaxAgeValidator(noOfPax.paxType, v) },
              ]}
              name={"dob" + paxIndex}
            >
              <DatePicker
                className="inputbg"
                allowClear={false}
                onChange={(date, dateString) =>
                  handlePaxField(
                    moment(dateString, dateformat).format(oriDateFormat),
                    paxIndex,
                    "dob"
                  )
                }
                key={`${Math.floor(Math.random() * 1000)}-min`}
                format={dateformat}
                style={{ width: "100%" }}
                disabledDate={disabledFutureDate}
              />
            </Form.Item>
          </Col>

          {noOfPax.hasOwnProperty("passengerNationality") ? (
            <Col md={8} sm={8} xs={24}>
              <Form.Item
                label="Passenger Nationality"
                rules={[
                  { required: true, message: "Required" },
                  {
                    pattern: "^[a-zA-Z ]*$",
                    message: "Alphabet Characters Only",
                  },
                ]}
                name={"passportnationality" + paxIndex}
              >
                <Input
 autocomplete="newpassword" 
                  className="inputbg"
                  onChange={(e) =>
                    handlePaxField(
                      e.target.value,
                      paxIndex,
                      "passengerNationality"
                    )
                  }
                  key={`${Math.floor(Math.random() * 1000)}-min`}
                />
              </Form.Item>
            </Col>
          ) : null}

          {noOfPax.hasOwnProperty("passportNumber") ? (
            <Col md={8} sm={8} xs={24}>
              <Form.Item
                label="Passport Number"
                rules={[{ required: true, message: "Required" }]}
                name={"passportnumber" + paxIndex}
              >
                <Input
 autocomplete="newpassword" 
                  className="inputbg"
                  placeholder="Passport Number"
                  onChange={(e) =>
                    handlePaxField(e.target.value, paxIndex, "passportNumber")
                  }
                  key={`${Math.floor(Math.random() * 1000)}-min`}
                />
              </Form.Item>
            </Col>
          ) : null}

          {noOfPax.hasOwnProperty("passportDOI") ? (
            <Col md={8} sm={8} xs={24}>
              <Form.Item
                label="Passport DOI"
                className="passport-dates"
                rules={[{ required: true, message: "Required" }]}
                name={"passportDOI" + paxIndex}
              >
                <DatePicker
                  onChange={(date, dateString) =>
                    handlePaxField(dateString, paxIndex, "passportDOI")
                  }
                  key={`${Math.floor(Math.random() * 1000)}-min`}
                  format={"YYYY-MM-DD"}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          ) : null}

          {noOfPax.hasOwnProperty("passportDOE") ? (
            <Col md={8} sm={8} xs={24}>
              <Form.Item
                label="Passport DOE"
                className="passport-dates"
                rules={[{ required: true, message: "Required" }]}
                name={"passportDOE" + paxIndex}
              >
                <DatePicker
                  onChange={(date, dateString) =>
                    handlePaxField(dateString, paxIndex, "passportDOE")
                  }
                  key={`${Math.floor(Math.random() * 1000)}-min`}
                  format={"YYYY-MM-DD"}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          ) : null}

          {noOfPax.hasOwnProperty("passportIssuedCountry") ? (
            <Col md={8} sm={8} xs={24}>
              <Form.Item
                label="Passport Issue Country"
                rules={[{ required: true, message: "Required" }]}
                name={"pic" + paxIndex}
              >
                <Select
 autocomplete="newpassword" 
                  placeholder="Passport Issue Country"
                  onChange={(e) =>
                    handlePaxField(e, paxIndex, "passportIssuedCountry")
                  }
                  key={`${Math.floor(Math.random() * 1000)}-min`}
                >
                  <Select.Option value="IN">India</Select.Option>
                  <Select.Option value="AUS">Australia</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          ) : null}
        </Row>

        {/* <p>
          <Collapse>
            <Panel header={FFNnumber}>
              <div>
                <p>Frequent Flyer Details</p>
                <p>
                  Please verify the credit of your frequent flyer miles at the
                  airport checkin counter
                </p>
              </div>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                  <span>
                    <Input
 autocomplete="newpassword" 
                      placeholder="Enter FFN"
                      readOnly
                      defaultValue="vistara-830"
                      disabled
                      
                    />
                  </span>
                </Col>
                <Col xs={24} md={12}>
                  <span>
                    <Input
 autocomplete="newpassword" 
                      placeholder="Enter FFN"
                      
                      onChange={(e) =>
                        handlePaxField(e.target.value, paxIndex, "ffNumber")
                      }
                    />
                  </span>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </p> */}
      </Card>
    );
  };
  const { updateTabKey } = useContext(TgContext);

  const globalContext = useContext(GlobalStatesContext);

  return (
    <div className="checkout-section flight-wrapper farType-wrapper">
      <div className="flight-checkout">
        <div className="checkout-heading">
          <div
            className="goback"
            onClick={() => {
              updateTabKey("1");
              history.go(-1);
            }}
          >
            <ArrowLeftOutlined />
            <span>Go back and select another flight</span>
          </div>
          <h3>Fill out the form below and book your flight now!</h3>
        </div>

        <div className="checkout-container">
          <Row gutter={[16, 16]}>
            <Col md={16} sm={24} xs={24} className="checkout-bottom-part">
              <FlightDetailsCard
                selectedFlightData={selectedFlightData}
                flightSearchData={flightSearchData}
              />

              <div className="user-details contactwrapper">
                {/*    <p className="heading">Contact details</p> */}
                {!isLoading ? (
                  <p>
                    <Spin />
                  </p>
                ) : isLoading && totalPaxArr.length > 0 ? (
                  <div className="titlewrapper">
                    <p className="heading">Contact Details</p>
                    <Card className="flight-cards-details">
                      <Form
                        layout="vertical"
                        className="flight-checkout-contact-form"
                        name="contactForm"
                        form={contact_form}
                        scrollToFirstError={true}
                      >
                        <Row gutter={[16, 8]}>
                          <Col md={12} sm={12} xs={24}>
                            <Form.Item
                              label="Phone number"
                              name="mobile"
                              className="phno"
                              rules={[
                                {
                                  required: true,
                                  message: "Required",
                                },
                                {
                                  minLength: 10,
                                  maxLength: 10,
                                  pattern: "^[0-9]{10}$",
                                  message: "Must be 10 digits",
                                },
                              ]}
                            >
                              <Input
 autocomplete="newpassword"  addonBefore="+91" className="inputbg" />
                            </Form.Item>
                          </Col>

                          <Col md={12} sm={12} xs={24}>
                            <Form.Item
                              name="email"
                              label="Email"
                              rules={[
                                { required: true, message: "Required" },
                                { type: "email", message: "Invalid Email" },
                              ]}
                            >
                              <Input
 autocomplete="newpassword"  type={"email"} className="inputbg" />
                            </Form.Item>{" "}
                          </Col>
                          <Col md={12} sm={12} xs={24}>
                            <Form.Item
                              name="address1"
                              label="Address"
                              rules={[
                                {
                                  required: true,
                                  message: "Required",
                                },
                                { min: 3, message: "Invalid Address" },
                              ]}
                            >
                              <Input
 autocomplete="newpassword"  className="inputbg" />
                            </Form.Item>
                          </Col>
                          {totalPaxArr[0].city === "" ? (
                            <Col md={12} sm={12} xs={24}>
                              <Form.Item
                                name="city"
                                label="City"
                                rules={[
                                  { required: true, message: "Required" },
                                ]}
                              >
                                <Input
 autocomplete="newpassword"  className="inputbg" />
                              </Form.Item>{" "}
                            </Col>
                          ) : null}
                          {totalPaxArr[0].state === "" ? (
                            <Col md={12} sm={12} xs={24}>
                              <Form.Item
                                name="state"
                                label="State"
                                rules={[
                                  { required: true, message: "Required" },
                                ]}
                              >
                                <Input
 autocomplete="newpassword"  className="inputbg" />
                              </Form.Item>{" "}
                            </Col>
                          ) : null}
                          {totalPaxArr[0].countryName === "" ? (
                            <Col md={12} sm={12} xs={24}>
                              <Form.Item
                                name="countryName"
                                label="Country"
                                rules={[
                                  { required: true, message: "Required" },
                                ]}
                              >
                                <Input
 autocomplete="newpassword"  className="inputbg" />
                              </Form.Item>{" "}
                            </Col>
                          ) : null}
                          {totalPaxArr[0].postalCode === "" ? (
                            <Col md={12} sm={12} xs={24}>
                              <Form.Item
                                name="postalCode"
                                label="Postal Code"
                                rules={[
                                  { required: true, message: "Required" },
                                ]}
                              >
                                <Input
 autocomplete="newpassword"  className="inputbg" />
                              </Form.Item>
                            </Col>
                          ) : null}
                        </Row>
                      </Form>
                    </Card>
                  </div>
                ) : (
                  <p>{ }</p>
                )}
              </div>

              <div className="paxInfowrapper">
                {!isLoading ? (
                  <Spin />
                ) : isLoading && totalPaxArr.length > 0 ? (
                  <>
                    <p className="heading">Passenger Details</p>
                    {totalPaxArr.map((noOfPax, paxIndex) => (
                      <Form
                        layout="vertical"
                        className="passenger-form user-details "
                        validateMessages={validateMessages}
                        form={passenger_form}
                      >
                        <PassengerFields
                          noOfPax={noOfPax}
                          paxIndex={paxIndex}
                        />
                      </Form>
                    ))}
                  </>
                ) : (
                  <p>{ }</p>
                )}
              </div>

              <div className="gst-details">
                {/*   <p className="heading">GST Details</p> */}
                <Card className="gst-custom-details flight-cards-details">
                  <p className="heading">GST Details</p>
                  <div className="add-gst-block">
                    <Collapse onChange={callback} bordered={false} activeKey={[isGstRequired == true ? '1' : '0']} >
                      <Panel header={collapseHeader} key="1">
                        <Form
                          layout="vertical"
                          className="passenger-form"
                          validateMessages={validateMessages}
                          form={gst_form}
                        >
                          <Form.Item
                            label="Company Name"
                            rules={[{ required: isGstRequired, message: "Required" }]}
                            name="companyName"
                          >
                            <Input
 autocomplete="newpassword" 
                              className="contacts-value-de"
                            // onChange={(e) => {
                            //   getGstDetails(e.target.value, "company Name");
                            // }}
                            />
                          </Form.Item>
                          <Form.Item
                            label="GST Number"
                            rules={[{ required: isGstRequired, message: "Required" },
                            {
                              minLength: 15,
                              maxLength: 15,
                              pattern: "^[0-9A-Z ]{15}$",
                              message: "Must be 15 digits AlphaNumeric with caps",
                            }]}
                            name="gstnumber"
                          >
                            <Input
 autocomplete="newpassword" 
                              className="contacts-value-de"
                              placeholder="GST Number"
                            />
                          </Form.Item>
                          <Form.Item
                            label="Contact Number"
                            rules={[{ required: isGstRequired, message: "Required" },
                            {
                              minLength: 10,
                              maxLength: 10,
                              pattern: "^[0-9]{10}$",
                              message: "Must be 10 digits",
                            },]}
                            name="contactnumber"
                          >
                            <Input
 autocomplete="newpassword" 
                              className="contacts-value-de"
                              placeholder="Contact Number"
                            />
                          </Form.Item>
                          <Form.Item
                            label="Company Email"
                            rules={[{ required: isGstRequired, message: "Required" }]}
                            name="companyemail"
                          >
                            <Input
 autocomplete="newpassword" 
                              className="contacts-value-de"
                              placeholder="Company Email"
                            />
                          </Form.Item>
                          <Form.Item
                            label="Company Address"
                            rules={[{ required: isGstRequired }]}
                            name="companyaddress"
                          >
                            <Input
 autocomplete="newpassword" 
                              className="contacts-value-de"
                              placeholder="Company Address"
                            />
                          </Form.Item>
                          {/* <Button type="primary" htmlType="submit">Test</Button> */}
                        </Form>
                      </Panel>
                    </Collapse>
                  </div>
                </Card>
              </div>
            </Col>
            <Col xs={24} md={8} className="checkout-top-part">
              <div className="checkout-sticky-part">
                {!isLoading ? (
                  <p>
                    <Spin />
                  </p>
                ) : (
                  airPriceResp && (
                    <>
                      {airPriceResp.flightDetails.map((flightDetObj, i) => (
                        <FareSummaryCard
                          flightDetObj={flightDetObj}
                          currency={flightDetObj.fareFamilies[0].currency}
                          title={
                            i === 0 ? "Onward Trip Fare" : "Return Trip Fare"
                          }
                        />
                      ))}
                      <div className="grand-total-card">
                        <FlightGrandTotalCard airPriceResp={airPriceResp} />
                      </div>
                    </>
                  )
                )}
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col sm={24} md={16}>
              <BookPayCard
                isLoading={isLoading}
                bookpaycardinfo={"flight-checkout"}
                redirectToPreview={redirectToPreview}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default FlightCheckout;
