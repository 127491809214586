import React, { useState, useCallback } from "react";
import { Spin, Select } from "antd";
import APIClient from "../../helpers/ApiClient";
import { Form } from "antd";
import "./AutoCompl.scss"

const AutoCompleteSelectM = (props) => {
    const { Option } = Select;
    const [details, setDetails] = useState({
        data: [],
        fetching: false,
    });

    const [selectedValues, setSelectedValues] = useState([]); // State to track selected values

    // Debounced fetch function to call the API
    const debounceOnChange = useCallback(debounce(fetchData, 800), []);

    // Fetch data based on input value
    function fetchData(value) {
        setDetails({ data: [], fetching: true });
        APIClient.get(`${props.api}${value}`)
            .then((res) => {
                if (res.isSuccess && res) {
                    setDetails({ data: res.data, fetching: false });
                    return;
                }
                setDetails({ data: [], fetching: false });
            })
            .catch((error) => {
                console.error(error);
                setDetails({ data: [], fetching: false });
            });
    }

    // Debounce function for limiting API calls
    function debounce(func, wait) {
        let timeout;
        return function (...args) {
            const context = this;
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(context, args);
            }, wait);
        };
    }

    // Handle selection changes and pass up updated list if needed
    const handleChange = (value) => {
        setSelectedValues(value); // Update selected values in local state
        if (props.onChange) {
            props.onChange(value); // Pass selected values to parent if `onChange` is provided
        }
    };

    return (
        <Form.Item {...props.formItemProps} className="newForm">
            <Select
                mode="multiple"
                showSearch
                value={selectedValues}
                notFoundContent={details.fetching ? <Spin size="small" /> : "No Match Found"}
                filterOption={false}
                onSearch={debounceOnChange}
                onChange={handleChange} // Handle value changes
                {...props.selectProps}
                style={{ maxHeight:"200px", overflowY:"scroll"}}
            >
                {details.data.map((d, index) => (
                    <Option value={d[props.keyvalue]} key={"key" + index}>
                        {d[props.keytext]}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default AutoCompleteSelectM;
