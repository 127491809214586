import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  message,
  Popconfirm,
  Row,
  Table,
  Select,
} from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import { exportExcel } from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
const { Content } = Layout;

const ScreenMapping = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [screenMapList, setScreenMapList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [currentId, setCurrentId] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [screensList, setScreensList] = useState([]);

  useEffect(() => {
    getScreenMapList();
    getRolesList();
    getScreensList();
  }, []);

  const getScreensList = () => {
    ApiClient.get("codes/getscreens")
      .then((res) => {
        if (res.isSuccess) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setScreensList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getRolesList = () => {
    ApiClient.get("codes/getroles")
      .then((res) => {
        if (res.isSuccess) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              // CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              // ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          setRolesList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getScreenMapList = () => {
    let page = {
      page: 0,
    };
    ApiClient.post("codes/getscreenmapping/list", page)
      .then((res) => {
        if (res.isSuccess) {
          let data = res.results.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setScreenMapList(data);
          setTableList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const add = (val) => {
    ApiClient.post("codes/savescreenmapping", val)
      .then((response) => {
        return response;
      })
      .then((response) => {
        if (response.isSuccess) {
          setModalVisible(false);
          message.success("Successfully added screens", 3);
          getScreenMapList();
          form.resetFields();
        } else if (!response.isSuccess) {
          message.error("ScreenMapping Already existed", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleModal = () => {
    if (showUpdateBtn) {
      setShowUpdateBtn(false);
      form.resetFields();
    }
    setModalVisible(true);
  };

  const deleteScreenMapping = (Record) => {
    let Id = Record.id;

    ApiClient.delete("codes/deletescreenmapping/" + Id)
      .then((res) => {
        if (res.isSuccess) {
          message.success("ScreenMapping Deleted  successfully", 3);
          getScreenMapList();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateScreenMapping = (val) => {
    ApiClient.put(`codes/updatescreenmapping/${currentId.id}`, {}, val).then(
      (resp) => {
        if (resp.isSuccess) {
          getScreenMapList();
          setModalVisible(false);
          message.success("ScreenMapping Updated successfully", 3);
          setCurrentId(-1);
        }
      }
    );
  };

  const submitForm = (val) => {
    let obj = {
      roleId: val.roleId,
      screenIds: val.screenIds,
    };
    if (showUpdateBtn) updateScreenMapping(obj);
    else add(obj);
  };

  const tableActions = (currentRecord, Name) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord, Name)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure want to delete?"
            onConfirm={() => deleteScreenMapping(Name)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const editCurrentItem = (id, data) => {
    setShowUpdateBtn(true);
    setCurrentId(id);
    let screenKeys = Object.keys(data.screens).map((key) => Number(key));
    form.setFieldsValue({
      roleId: data.roleId,
      screenIds: screenKeys,
    });
    setModalVisible(true);
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };

  const searchData = () => {
    setShowSearchBox(true);
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    {
      title: "Role",
      dataIndex: "role",
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: "Screens Name",
      dataIndex: "screens",
      render: (object) => renderscreens(object),
    },

    {
      title: "Actions",
      render: (id, name) => tableActions(id, name),
    },
  ];

  const renderscreens = (object) => {
    var screenKeys = Object.keys(object);

    if (screenKeys.length > 0) {
      let screenNames = screenKeys.map((key) => {
        return ` ${object[key]}`;
      });

      return screenNames.toString();
    }
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(screenMapList);
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
    setTableList(screenMapList);
  };

  const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

  const handleExcel = () => {
    let excelData = tableList;
    excelData = excelData.map((item, i) => ({
      SNo: i + 1,
      ScreenName: item.name,
      CategoryName: item.categoryName,
    }));

    exportExcel(excelData, "ScreenMapping");
  };

  return (
    <div className="outer-background-all">
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false} style={{borderRadius:"25px"}}>
              <div className="card-add-heading">
                <div className="rows-count">
                  <h5>View ScreenMapping</h5>
                  <p>{tableList.length} rows found !</p>
                </div>
                <div className="action-images">
                  {showSearchBox && (
                    <Input
                      autocomplete="newpassword"
                      placeholder="Search"
                      onChange={(e) => searchTableData(e)}
                      suffix={closeSearchInput}
                      style={{ padding: "0px 12px" }}
                    />
                  )}
                  &nbsp;&nbsp;
                  <img src={search} alt="search" onClick={() => searchData()} />
                  <img src={excel} alt="excel" onClick={() => handleExcel()} />
                  <img
                    src={exchange}
                    alt="exchange"
                    onClick={() => {
                      setTableList([]);
                      getScreenMapList();
                    }}
                  />
                  <p className="add-deposit-icon" onClick={handleModal}>
                    <PlusCircleFilled />
                  </p>
                </div>
              </div>

              <Table
                scroll={{ x: true }}
                bordered
                rowKey={uniqueKey}
                dataSource={tableList}
                columns={columns}
                pagination={{
                  defaultPageSize: 25,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50", "100", "125"],
                }}
              />
            </Card>
          </div>
        </Content>
      </div>
      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>
                {showUpdateBtn ? "Update ScreenMapping" : "Add ScreenMapping"}
              </strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <Button key="add" type="primary" onClick={form.submit}>
            {showUpdateBtn ? "Update" : "Add"}
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm}>
          <Row gutter={12}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Roles"
                name="roleId"
                rules={[{ required: true, message: "Please enter roles" }]}
              >
                <Select placeholder="Please select Role">
                  {rolesList.map((item) => (
                    <Option value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Screens"
                name="screenIds"
                rules={[{ required: true, message: "Please enter screens" }]}
              >
                <Select
                  placeholder="Please select Screens"
                  mode="multiple"
                  allowClear
                >
                  {screensList.map((item) => (
                    <Option value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default ScreenMapping;
