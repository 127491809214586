import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Input,
  Form,
  Row,
  Modal,
  message,
  Skeleton,
} from "antd";
import moment from "moment";
import { useAuthContext } from "../../common/providers/AuthProvider";
import GlobalStatesContext from "../../common/providers/GlobalStatesContext";
import { CloseOutlined } from "@ant-design/icons";
import APIClient from "../../helpers/ApiClient";
import img1 from "../../assets/images/Offers/img1.jpg";
export const getPromoDiscount = (promoData, total) => {
  if (promoData.DiscountType == 1) {
    return parseFloat((total / 100) * promoData.Discount);
  } else {
    return parseFloat(promoData.Discount);
  }
};

export const getConvieneceFeeValue = (ConvFee, total) => {
  let convamount = 0;
  if (ConvFee.type == 1) {
    convamount = parseFloat(ConvFee.amount);
  } else {
    convamount = parseFloat(
      (total / 100) * parseFloat(ConvFee.amount ? ConvFee.amount : 0)
    );
  }
  return convamount;
};
export const getFlightGrandTotal = (airPriceResp, ConvFee, promoData) => {
  let searchValue = JSON.parse(localStorage.getItem("searchResp"));
  let MarkUp = 0;
  let totalBaseFare = 0;
  let otherCharges = 0;
  let adultCount = searchValue.adultCount;
  let childCount = searchValue.childCount;
  let infantCount = searchValue.infantCount;
  let convamount = 0;
  let discount = 0;
  airPriceResp.flightDetails.forEach((flight) => {
    MarkUp = Number(flight.fareFamilies[0].MarkUpamount);
    flight.fareFamilies.forEach((fareFamily) => {
      fareFamily.flightFares.forEach((fare) => {
        if (fare.fareCode == "Base") {
          if (fare.paxType == "ADT") {
            totalBaseFare += parseInt(adultCount) * parseFloat(fare.amount);
          } else if (fare.paxType == "CHD") {
            totalBaseFare += parseInt(childCount) * parseFloat(fare.amount);
          } else {
            totalBaseFare += parseInt(infantCount) * parseFloat(fare.amount);
          }
        } else if (fare.fareCode == "Tax") {
          // otherCharges += parseFloat(fare.amount);
          if (fare.paxType == "ADT") {
            otherCharges += parseInt(adultCount) * parseFloat(fare.amount);
          } else if (fare.paxType == "CHD") {
            otherCharges += parseInt(childCount) * parseFloat(fare.amount);
          } else {
            otherCharges += parseInt(infantCount) * parseFloat(fare.amount);
          }
        }
      });
    });
  });

  convamount = getConvieneceFeeValue(
    ConvFee,
    totalBaseFare + otherCharges + MarkUp
  );
  discount = getPromoDiscount(promoData, totalBaseFare + otherCharges);
  let amount_with_discount = totalBaseFare + otherCharges + MarkUp - discount;

  return Number(
    parseFloat(amount_with_discount) + parseFloat(convamount)
  ).toFixed(2);
};

export const FlightGrandTotalCard = ({ airPriceResp, PromoNotVisible }) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const { user } = useAuthContext();
  const [promoform] = Form.useForm();
  const globalContext = useContext(GlobalStatesContext);
  const { promoData } = globalContext.state.otherData;
  const { ConvFee } = globalContext.state.otherData;

  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [promoCode, setPromoCode] = useState([]);
  let dateFormat = "YYYY-MM-DD";
  useEffect(() => {
    globalContext.AddConvFee(2);
  }, []);

  const getpromo = () => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) =>
            moment(item.ValidTill, dateFormat).isSameOrAfter(moment())
          );
          if (data.length > 0) {
            let flightPromo = data.filter(
              (promo) =>
                promo.ServiceType === 2 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );

            setPromoCode(flightPromo);
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setPromoCode([]);
        setIsLoading(false);
        console.error(error);
      });
  };

  const handleApply = (code) => {
    promoform.setFieldsValue({
      promo: code,
    });
    setModalVisible(false);
    promoform.submit();
  };

  const validatePromo = (value) => {
    let userID = 1;
    if (user) {
      if (user.UserID) {
        userID = user.UserID;
      }
    }
    let data = {
      ServiceType: value.ServiceType,
      FromAmount: value.FromAmount,
      ToAmount: value.ToAmount,
      promoId: value.Code,
      UserId: userID,
    };
    APIClient.post("admin/validate/promo", data)
      .then((res) => {
        if (res.status === 200) {
          handleApply(value.Code);
        }
        if (res.status === 400) {
          message.error(res.data);
          setModalVisible(false);
        }
      })
      .catch((error) => {
        setModalVisible(false);
      });
  };

  const OfferSkeleton = () => {
    return (
      <Col className="gutter-row" md={6} sm={12} xs={24}>
        <div className="coupon-card">
          <div className="images-slider">
            <div
              style={{
                backgroundColor: "#f2f2f2",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Skeleton.Image />
            </div>
          </div>
          <div className="coupon-content">
            <div className="coupon-code">
              <div className="codes">
                <Skeleton.Input style={{ width: 50 }} active size="small" />
              </div>

              <div className="copy-code">
                <Skeleton.Input style={{ width: 50 }} active size="small" />
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  };

  const OffersBox = ({ id, promo }) => {
    return (
      <Col key={id} className="gutter-row" md={6} sm={12} xs={24}>
        <div className="coupon-card">
          <div className="image-wrapper">
            <img
              src={promo.Image ? BASE + promo.Image.substring(1) : img1}
              alt="promo_img"
            />
          </div>
          <div className="coupon-content">
            <div className="coupon-code">
              <div className="codes">
                <p>{promo.Name}</p>
                <h2>{promo.Code}</h2>
              </div>

              <div className="copy-code" onClick={() => validatePromo(promo)}>
                <p>Apply</p>
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  };
  const handleFocus = () => {
    getpromo();
    setModalVisible(true);
  };
  return (
    <>
      <Card className="flight-cards-details">
        <Row justify={"space-between"} className="grand_total_card_row">
          <Col>
            <p>Convenience Fee</p>
          </Col>
          <Col>
            <p>
              {ConvFee.type == 0
                ? parseFloat(ConvFee.amount).toFixed(2) + " %"
                : "₹ " + parseFloat(ConvFee.amount).toFixed(2)}
            </p>
          </Col>
        </Row>
        <Row justify={"space-between"} className="grand_total_card_row">
          <Col>
            <p>Discount</p>
          </Col>
          <Col>
            <p>
              {promoData.DiscountType == 1
                ? promoData.Discount + " %"
                : "₹ " + Number(promoData.Discount).toFixed(2)}
            </p>
          </Col>
        </Row>

        <div className="pax-total-price">
          <div className="pax-type">
            <p>Grand Total:</p>
          </div>
          <div className="total">
            <p>₹</p>
            &nbsp;
            <p className="amount">
              {getFlightGrandTotal(airPriceResp, ConvFee, promoData)}
            </p>
          </div>
        </div>
      </Card>
      {!PromoNotVisible ? (
        <div className="promo-card apply-promo-fbt">
          {promoData.status == false ? (
            <Card className="flight-cards-details">
              <div className="promo-card-header">
                <p className="name">Apply Promo</p>
              </div>
              <div className="promo-input">
                <Form
                  name="customized_form_controls"
                  layout="inline"
                  form={promoform}
                  onFinish={(d) => {
                    globalContext.AddPromo(d.promo, 2);
                  }}
                >
                  <Form.Item name="promo" className="promo-input-item">
                    <Input
 autocomplete="newpassword" 
                      className="contacts-value-de"
                      placeholder="Enter Your Promo code"
                      onClick={() => handleFocus()}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item className="promo-input-btn">
                    <Button
                      className="apply-promo-value-code"
                      type="primary"
                      htmlType="submit"
                    >
                      Apply
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Card>
          ) : (
            <Card className="flight-cards-details">
              <div className="promo-card-header">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="name"
                >
                  Promo Coupon{" "}
                  <CloseOutlined
                    onClick={() => {
                      globalContext.RemovePromo();
                      promoform.resetFields();
                    }}
                  />{" "}
                </p>
              </div>
              <div className="promo-input">
                <p>
                  <span className="applied"> {promoData.Code} </span> Promo Code
                  Applied
                </p>
              </div>
            </Card>
          )}
        </div>
      ) : null}

      <Modal
        title="Offers"
        className="offers-modal"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1000px"}
      >
        <Row gutter={[8, 16]}>
          {isLoading ? (
            <OfferSkeleton />
          ) : promoCode.length > 0 ? (
            promoCode.map((promo, i) => (
              <OffersBox key={"bus" + i} id={i + "bus"} promo={promo} />
            ))
          ) : null}
        </Row>
      </Modal>
    </>
  );
};
