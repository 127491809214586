import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Card,
  Row,
  Form,
  message,
  Upload,
  Input,
  DatePicker,
  Select,
} from "antd";
import { useAuthContext } from "../../common/providers/AuthProvider";
import "./Profile.scss";
import { LoadingOutlined, EditOutlined } from "@ant-design/icons";
import profileImg from "../../admin/assets/profileUser.png";
import ApiClient from "../../../src/helpers/ApiClient";
import moment from "moment";

const Profile = () => {
  const { user } = useAuthContext();
  const BASE = process.env.REACT_APP_BASE_URL;
  let dateFormat = "DD-MM-YYYY";
  const initialState = {
    Email: "",
    Mobile: "",
    Name: "",
    Gender: 0,
    DOB: "",
    ProfilePic: null,
  };
  const userID = user ? user.UserID : null;
  const [upLoadImage, setUpLoadImage] = useState(false);
  const uploadActionUrl = BASE + "media/upload/singleImage";
  const [imageUrl, setImageUrl] = useState();

  const [ProfileForm] = Form.useForm();
  const [mobileNo, setMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [userData, setUserData] = useState(initialState);

  const uploadLogoButton = (
    <div>
      {upLoadImage ? <LoadingOutlined /> : <img src={profileImg} alt="" />}
      <div className="ant-upload-text">Upload Image</div>
    </div>
  );

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function handleChange(info) {
    console.log(info);

    if (info.file.status === "uploading") {
      setUpLoadImage(true);
      setImageUrl(null);
      return;
    }
    if (info.file.status === "error") {
      setUpLoadImage(false);
      return;
    }
    if (info.file.status === "done") {
      if (info.file.response.status === 200) {
        setUserData({
          ...userData,
          ProfilePic: info.file.response.data.filepath,
        });

        getBase64(info.file.originFileObj, (imageUrl) => {
          setImageUrl(imageUrl);
          setUpLoadImage(false);
        });
      }
      setUpLoadImage(false);
    }
  }
  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    const checkFileSize = file.size < 256000;
    if (!checkFileSize) {
      message.error(" Image must be smaller than 250kb!");
    }

    return checkJpgOrPng && checkFileSize;
  }
  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = () => {
    if (!userID) return;
    ApiClient.get(`admin/b2c/user/${userID}`)
      .then((response) => {
        if (response.status === 200) {
          setUserData({
            ...response.data.userDetails,
          });
          if (response.data.userDetails.ProfilePic) {
            setImageUrl(
              BASE + response.data.userDetails.ProfilePic.substring(1)
            );
          }
          ProfileForm.setFieldsValue({
            ...response.data.userDetails,
            DOB: response.data.userDetails.DOB
              ? moment(response.data.userDetails.DOB)
              : "",
          });
          setMobileNo(response.data.userDetails.Mobile);
          setEmail(response.data.userDetails.Email);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  };
  const EditUserDetail = (values) => {
    delete values.UploadImage;

    console.log(values);
    let userInfo = {
      ...values,
      ProfilePic: userData.ProfilePic,
    };
    if (!userID) return;
    ApiClient.put(`admin/b2c/user/${userID}`, {}, userInfo)
      .then((res) => {
        if (res.status === 200) {
          message.success("Profile Updated");
          getUserProfile();
        } else {
          message.error("Something went wrong. Try Again");
        }
      })
      .catch((e) => {
        console.log("api error", e);
      });
  };

  const disabledFutureDate = (currentDate) => {
    return currentDate > moment().startOf("day");
  };

  return (
    <div className="profile-bg ourteam-bg">
      <div id="profile" className="retrieve-linebar">
        <Form form={ProfileForm} onFinish={EditUserDetail}>
          <Row gutter={16}>
            <Col md={6} xs={24} className="gutter-class max-width-ns">
              <Card bordered={false} className="retrieve-booking">
                <div className="profile_pic">
                  <Form.Item label="Upload Image" name="UploadImage">
                    <Upload
                      name="image"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action={uploadActionUrl}
                      data={{ category: "profilepic" }}
                      onChange={handleChange}
                    >
                      {imageUrl ? (
                        <img src={imageUrl} alt="avatar" />
                      ) : (
                        uploadLogoButton
                      )}
                    </Upload>
                  </Form.Item>
                </div>
              </Card>
            </Col>
            <Col md={18} xs={24} className="gutter-class">
              <Card bordered={false} className="retrieve-booking">
                <Row gutter={24}>
                  <Col className="gutter-class" md={20} xs={18}>
                    <h3>Profile</h3>
                    <p>Basic Info, for a faster Booking Experience</p>
                  </Col>

                  <Col className="gutter-class" md={4} xs={6}>
                    <Button
                      className="profile-btn"
                      type="primary"
                      htmlType="submit"
                    >
                      <EditOutlined />
                      Update
                    </Button>
                  </Col>
                </Row>

                <Row gutter={24} className="select-profile">
                  <Col className="gutter-class" md={24} xs={24}>
                    <Form.Item
                      rules={[
                        { required: true, message: "Please input your Name" },
                      ]}
                      name="Name"
                    >
                      <Input
                        autocomplete="newpassword"
                        className="profile-value-de-retrive"
                        placeholder="USER NAME"
                        size={"large"}
                      />
                    </Form.Item>

                    <Form.Item name="DOB">
                      <DatePicker
                        allowClear={false}
                        className="profile-value-de-retrive"
                        style={{ width: "100%" }}
                        placeholder="BIRTHDAY"
                        format={dateFormat}
                        disabledDate={disabledFutureDate}
                      />
                    </Form.Item>

                    <Form.Item name="Gender">
                      <Select
                        autocomplete="newpassword" size={"large"} placeholder="GENDER">
                        <Select.Option value={0}>Male</Select.Option>
                        <Select.Option value={1}>Female</Select.Option>
                        <Select.Option value={2}>Other</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={6} xs={24} className="gutter-class min-width-pf">
              <div className="profile_pic">
                <Form.Item name="UploadImage">
                  <Upload
                    name="image"
                    listType="picture-card"
                    className="avatar-uploader"
                    action={uploadActionUrl}
                    showUploadList={false}
                    onChange={handleChange}
                    beforeUpload={beforeUpload}
                    data={{ category: "profilepic" }}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      uploadLogoButton
                    )}
                  </Upload>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <br />

          <Row gutter={16} className="select-profile">
            <Col md={18} xs={24} className="gutter-class">
              <Card bordered={false} className="retrieve-booking">
                <Row gutter={24}>
                  <Col className="gutter-class" md={24} xs={24}>
                    <h3>Login Details</h3>
                  </Col>
                </Row>

                <Row gutter={24}>
                  {email && (
                    <Col className="gutter-class" md={24} xs={24}>
                      <Form.Item name="Email">
                        <Row gutter={5}>
                          <Col className="gutter-class" md={24}>
                            <Input
                              autocomplete="newpassword"
                              className="profile-value-de-retrive"
                              placeholder="Email"
                              readOnly
                              value={email}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                    </Col>
                  )}
                  <Col className="gutter-class" md={24} xs={24}>
                    <Form.Item
                      name="Mobile"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Mobile Number",
                        },
                      ]}
                    >
                      <Row gutter={5}>
                        <Col className="gutter-class" md={24}>
                          <Input
                            autocomplete="newpassword"
                            className="profile-value-de-retrive"
                            placeholder="Mobile Number"
                            readOnly
                            value={mobileNo}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default Profile;
