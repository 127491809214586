import React, { useContext } from "react";
import { Menu, Dropdown } from "antd";
import { Link, useHistory } from "react-router-dom";
import { TgContext } from "../providers/TgProvider";
import { useAuthContext } from "../providers/AuthProvider";
import { DownOutlined } from "@ant-design/icons";
import "../CombinedNavbar/CombinedNavbar.scss";

const CombinedNavbar = (props) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const { logo } = useContext(TgContext);
  const { setUser } = useAuthContext();
  let history = useHistory();
  let { flag } = props.gscontext.state.isLogin;
  const { updateIsLogin } = props.gscontext;
  const setChangeLoginFlag = (value) => updateIsLogin("flag", value);
  const Logout = () => {
    setChangeLoginFlag(false);
    localStorage.clear();
    setUser(null);
    history.push("/");
  };

  const menunew = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/profile">My Profile</Link>
      </Menu.Item>

      <Menu.Item key="2">
        <Link to="/bookingreports">My Bookings</Link>
      </Menu.Item>

      {/* <Menu.Item key="3">
        <Link to="/mytrips">My Trips</Link>
      </Menu.Item> */}

      <Menu.Item key="4">
        <Link to="" onClick={Logout}>
          Logout
        </Link>
      </Menu.Item>
    </Menu>
  );

  const notloggedMenu = (
    <Menu>
      <Menu.Item key="5">
        <Link to="/mytrips">My Trips</Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="combined-navbar-header">
      <div className="combined-navbar-container">
        <div className="logo">
          <Link to="/">
            {logo && <img src={BASE + logo.substring(1)} alt="tglogo" />}
            {/* require("../../assets/images/TrusGo_Logo.png") */}
          </Link>
        </div>

        <Menu className="menu-items">
          <Menu.Item key="account">
            <Dropdown
              overlay={flag ? menunew : notloggedMenu}
              trigger={["click"]}
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                My Account <DownOutlined />
              </a>
            </Dropdown>
          </Menu.Item>

          {!flag ? (
            <Menu.Item className="login-bdr" key="login">
              <Link to="/login">Login </Link>
            </Menu.Item>
          ) : null}
        </Menu>
      </div>
    </div>
  );
};
export default CombinedNavbar;
