import React from "react";
import CmsPage from "../CmsPage/CmsPage";
import "./Privacypolicy.scss";
import NavBarStatic from "../../common/navbar/NavSatic";

const Privacypolicy = () => {
  // return <CmsPage id={6} />;
  return (
    <div>
      <NavBarStatic />
      <div className="privacypol-img"></div>
      <div className="privacy-policy-n">
        <h2>Privacy Policy</h2>


        <div className="pripolicyterms">

          <p><span style={{fontWeight:"600"}}>I2space Private Limited </span>(hereinafter “eTravos”) recognizes the importance of privacy of its users and also of maintaining confidentiality of the information provided by its users as a responsible data controller and data processor.<br />

            This Privacy Policy provides for the practices for handling and securing user's Personal Information (defined hereunder) by eTravos and its subsidiaries and affiliates.</p>

          <p>This Privacy Policy is applicable to any person (‘User’) who purchases, intends to purchase, or inquire about any product(s) or service(s) made available by eTravos through any of eTravos’s customer interface channels including its website, mobile site, mobile app & offline channels including call centers and offices (collectively referred herein as "Sales Channels").

            For the purpose of this Privacy Policy, wherever the context so requires "you" or "your" shall mean User and the term "we", "us", "our" shall mean eTravos.<br />
            For the purpose of this Privacy Policy, Website means the website(s), mobile site(s) and mobile app(s).
          </p>
          <p>

            By using or accessing the Website or other Sales Channels, the User hereby agrees with the terms of this Privacy Policy and the contents herein. If you disagree with this Privacy Policy please do not use or access our Website or other Sales Channels.

            This Privacy Policy does not apply to any website(s), mobile sites and mobile apps of third parties, even if their websites/products are linked to our Website. User should take note that information and privacy practices of eTravos’s business partners, advertisers, sponsors or other sites to which eTravos provides hyperlink(s), may be materially different from this Privacy Policy.
          </p>
          <p> Accordingly, it is recommended that you review the privacy statements and policies of any such third parties with whom they interact.

            This Privacy Policy is an integral part of your User Agreement with eTravos and all capitalized terms used, but not otherwise defined herein, shall have the respective meanings as ascribed to them in the User Agreement.
          </p>
          <h3>TYPE OF INFORMATION WE COLLECT AND ITS LEGAL BASIS</h3>

          <p>The information as detailed below is collected for us to be able to provide the services chosen by you and also to fulfill our legal obligations as well as our obligations towards third parties as per our User Agreement. <br />
            <span style={{ fontWeight: "600" }}>"Personal Information"</span> of User shall include the information shared by the User and collected by us for the following purposes:</p>
          <h3>Registration on the Website: </h3>
          <ul>
            <li style={{ color: "black" }}>Information which you provide while subscribing to or registering on the Website, including but not limited to information about your personal identity such as name, gender, age etc., your contact details such as your email address, postal addresses, telephone (mobile or otherwise) and/or fax numbers. The information may also include information such as your banking details (including credit/debit card) and any other information relating to your income and/or lifestyle; billing information payment history etc. (as shared by you).</li>
          </ul>

          <h3>HOW WE USE YOUR PERSONAL INFORMATION</h3>
          <span>The Personal Information collected maybe used in the following manner:</span>

          <h6 style={{ fontWeight: "600" }}> While making a booking:</h6>
          <p>While making a booking, we may use Personal Information including, payment details which include cardholder name, credit/debit card number (in encrypted form) with expiration date, banking details, wallet details etc. as shared and allowed to be stored by you. We may also use the information of travelers list as available in or linked with your account. This information is presented to the User at the time of making a booking to enable you to complete your bookings expeditiously.</p>
          <h6 style={{ color: "blue", fontWeight: "600" }}>We may also use your Personal Information for several reasons including but not limited to:</h6>
          <ul>
            <li>• keep you informed of the transaction status;</li>
            <li>• Your usernames, passwords, email addresses and other security-related information used by you in relation to our Services.</li>
            <li>• send booking confirmations either via SMS or Whatsapp or any other messaging service.</li>
            <li>• allow our customer service to contact you, if necessary.</li>
            <li>• request for reviews of products or services or any other improvements.</li>
            <li>• send verification message(s) or email(s)</li>
            <li>• Validate/authenticate your account and to prevent any misuse or abuse</li>
          </ul>
          <h3>HOW LONG DO WE KEEP YOUR PERSONAL INFORMATION?</h3>

          <p>eTravos will retain your Personal Information on its servers for as long as is reasonably necessary for the purposes listed in this policy. In some circumstances we may retain your Personal Information for longer periods of time, for instance where we are required to do so in accordance with any legal, regulatory, tax or accounting requirements.</p>
          {/* <p>eTravos will retain your Personal Information on its servers for as long as is reasonably necessary for the purposes listed in this policy. In some circumstances we may retain your Personal Information for longer periods of time, for instance where we are required to do so in accordance with any legal, regulatory, tax or accounting requirements.</p> */}
        </div>

        {/* Add other divs (Ryde, redRail) similarly */}
      </div>
    </div >
  )
};

export default Privacypolicy;
