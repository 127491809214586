import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Layout,
  message,
  Select,
} from "antd";
import ApiClient from "../../helpers/ApiClient";
import "./Mytrips.scss";
const { Content } = Layout;
const Mytrips = () => {
  const [form] = Form.useForm();

  const verifyTicket = (data) => {
    return new Promise((resolve, reject) => {
      let type = data.type === "flight_ticket" ? 1 : 3;
      ApiClient.get(
        `admin/myBookings/details?travelType=${type}&id=${data.ref}`
      ).then((resp) => {
        let errorMessage = "No Such Ticket Found";
        if (!resp) {
          reject("Server Error");
          return;
        }

        if (!resp.data) {
          reject("No Such Ticket Found");
          return;
        }
        if (resp.data.length > 0) {
          if (resp.data[0].guestEmaiId === data.email) {
            resolve();
          } else {
            reject("Email Does Not match Refference Id");
          }
          resolve();
        } else {
          reject(errorMessage);
        }
      });
    });
  };

  const submitForm = (data) => {
    verifyTicket(data)
      .then(() => {
        let url = data.type + "?ref=" + data.ref;
        document.location.replace(url);
      })
      .catch((e) => {
        message.error(e);
      });
  };

  return (
    <div className="promo providerapi">
      <Layout className="ourteam-bg">
        <div className="">
          <Content className="admin-container cms-pages-width retrieve-linebar">
            <Row className="fst-book">
              <Col md={24} xs={24}></Col>
            </Row>

            <Form
              layout="vertical"
              form={form}
              onFinish={submitForm}
              initialValues={{ type: "flight_ticket" }}
            >
              <Row>
                <Col md={12} xs={24}>
                  <Card className="retrieve-booking">
                    <h4>Retrieve Your Booking</h4>
                    <p className="booking-email-p">
                      Enter your booking number and email address below and
                      we’ll find your ticket for you. We sent your booking
                      number to you in your confirmation email.
                    </p>
                    <Form.Item
                      label="Service Type"
                      name="type"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Select
                        autocomplete="newpassword" >
                        <Select.Option value="flight_ticket">
                          Flights
                        </Select.Option>
                        <Select.Option value="bus_ticket">Buses</Select.Option>
                      </Select>
                    </Form.Item>

                    <Row className="booking-value-id">
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Booking Number"
                          name="ref"
                          rules={[
                            {
                              required: true,
                              message: "Reference Number is required",
                            },
                          ]}
                        >
                          <Input
                            autocomplete="newpassword"
                            className="contacts-value-de-retrive"
                            size={"large"}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Email address"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Email ID is required",
                            },
                            { type: "email", message: "Invalid Email" },
                          ]}
                        >
                          <Input
                            autocomplete="newpassword"
                            className="contacts-value-de-retrive"
                            size={"large"}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12} xs={24}>
                        <Button
                          className="google-btn-booking"
                          htmlType="submit"
                        >
                          Retrieve Booking
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col md={12} xs={24}>
                  <img
                    className="email-from-book"
                    src={require("../../assets/images/email-tickets.png")}
                    alt="search-img"
                  />
                </Col>
              </Row>
            </Form>
          </Content>
        </div>
      </Layout>
    </div>
  );
};

export default Mytrips;
