
import React, { useState,useContext } from "react"
import timeout from '../../assets/images/timeout.jpg';
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    Row,
    Select,
    Collapse,
    message,
    Table,
    Modal,
    Popconfirm,
} from "antd";
import { useHistory } from "react-router-dom"
import { GlobalStatesContext } from "../../common/providers";
import './Session.scss';
const SessionTimeOutModal = ({ isModelVisible }) => {
    // console.log("hhhhh", isModelVisible)

    const {
        state: { busSearchResultObj },
        updateIsLogin,
    } = useContext(GlobalStatesContext);

    const [modalVisible, setModalVisible] = useState(isModelVisible);
    let history = useHistory();
    const Logout = () => {
        setModalVisible(false)
        updateIsLogin("flag", false);
        updateIsLogin("role", false);
        localStorage.clear();
        sessionStorage.clear();
        history.push("/");
    };

    const login = () => {
        setModalVisible(false)
        updateIsLogin("flag", false);
        updateIsLogin("role", false);
        localStorage.clear();
        sessionStorage.clear();
        history.push("/");
    }
    return (<div>
        <Modal
            width="400px"
            visible={modalVisible}
            title="Session Expired"
            className=" modal-header-bg12"
            onCancel={(e) => {
                setModalVisible(false);
            }}
            footer={[
                <div>
                    <Button key="clear" onClick={() => login()}>
                        Login Again
                    </Button>
                    <Button key="add" type="primary" onClick={() => Logout()}>
                        LogOut
                    </Button>
                </div>,
            ]}
        >
            <div className="timeout-popup-main">
            <div className="timeout-popup-main1">
                <img  className="timeout-img" src={timeout} alt="time" />
            </div>
            <div className="timeout-popup-main2">
                <h4 style={{color:"orange",}}><strong>SESSION TIMEOUT</strong></h4>
                <h6>Your Session Has Expired</h6>
                <h6>You will be redirected to the Login Page</h6>
            </div>
            </div>
         </Modal>
    </div>)

}
export default SessionTimeOutModal;