import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  message,
  Popconfirm,
  Row,
  Table,
} from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import { exportExcel } from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
const { Content } = Layout;

const Membership = () => {
  const [form] = Form.useForm();
  const { Search } = Input;
  const [modalVisible, setModalVisible] = useState(false);
  const [membershipList, setMembershipList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [currentId, setCurrentId] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);

  useEffect(() => {
    getMembershipList();
  }, []);

  const getMembershipList = () => {
    ApiClient.get("codes/getmembership")
      .then((res) => {
        if (res.isSuccess) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setMembershipList(data);
          setTableList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getDataByName = (val) => {
    let obj = {
      filter: {
        name: val,
        isActive: true,
      },
      page: 0,
    };
    ApiClient.post("codes/getmembership/list", obj)
      .then((response) => {
        return response;
      })
      .then((response) => {
        if (response.isSuccess) {
          let data = response.results.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });

          setMembershipList(data);
          setTableList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const add = (val) => {
    ApiClient.post("codes/savemembership", val)
      .then((response) => {
        return response;
      })
      .then((response) => {
        if (response.isSuccess) {
          setModalVisible(false);
          message.success("Successfully added membership", 3);
          getMembershipList();
          form.resetFields();
        } else if (!response.isSuccess) {
          message.error("Membership Already existed", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleModal = () => {
    if (showUpdateBtn) {
      setShowUpdateBtn(false);
      form.resetFields();
    }
    setModalVisible(true);
  };

  const deleteMembership = (Record) => {
    let Id = Record.id;
    ApiClient.delete("codes/deletemembership/" + Id)
      .then((res) => {
        if (res.isSuccess) {
          message.success("Membership Deleted  successfully", 3);
          getMembershipList();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const updateMembership = (val) => {
    ApiClient.put(`codes/updatemembership/${currentId.id}`, {}, val).then(
      (resp) => {
        if (resp.isSuccess) {
          getMembershipList();
          setModalVisible(false);
          message.success("Membership Updated successfully", 3);
          setCurrentId(-1);
        }
      }
    );
  };

  const submitForm = (val) => {
    let membObj = {
      name: val.membership,
      isActive: true,
    };
    if (showUpdateBtn) updateMembership(membObj);
    else add(membObj);
  };

  const tableActions = (currentRecord, Name) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord, Name)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure want to delete?"
            onConfirm={() => deleteMembership(Name)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const editCurrentItem = (id, Name) => {
    setShowUpdateBtn(true);
    setCurrentId(id);
    form.setFieldsValue({
      membership: Name.name,
    });
    setModalVisible(true);
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };

  const searchData = () => {
    setShowSearchBox(true);
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    {
      title: "Membership Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Actions",
      render: (id, name) => tableActions(id, name),
    },
  ];

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(membershipList);
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
    setTableList(membershipList);
  };

  const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

  const handleExcel = () => {
    let excelData = tableList;
    excelData = excelData.map((item, i) => ({
      SNo: i + 1,
      Name: item.name,
    }));

    exportExcel(excelData, "Memberships");
  };

  return (
    <div className="outer-background-all">
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false} style={{borderRadius:"25px"}}>
              <Row className="card-add-heading">
                <Col md={14} xs={12}>
                  <div className="rows-count">
                    <h5>View Membership</h5>
                    <p>{tableList.length} rows found !</p>
                  </div>
                </Col>
                <Col md={10} xs={12}>
                  <Row>
                    <Col md={14} xs={24}>
                      <Form name="search">
                        <Search
                          placeholder="input search text"
                          onSearch={(val) => getDataByName(val)}
                          style={{ width: 250 }}
                          className="member-ship-sec"
                        />
                      </Form>
                    </Col>
                    <Col md={10} xs={24}>
                      <div className="action-images">
                        &nbsp;&nbsp;
                        {/* <img src={search} alt="search" onClick={() => searchData()} /> */}
                        <img
                          src={excel}
                          alt="excel"
                          onClick={() => handleExcel()}
                        />
                        <img
                          src={exchange}
                          alt="exchange"
                          onClick={() => {
                            setTableList([]);
                            getMembershipList();
                          }}
                        />
                        <PlusCircleFilled
                          className="add-deposit-icon"
                          onClick={handleModal}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Table
                scroll={{ x: true }}
                bordered
                rowKey={uniqueKey}
                dataSource={tableList}
                columns={columns}
                pagination={{
                  defaultPageSize: 25,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50", "100", "125"],
                }}
              />
            </Card>
          </div>
        </Content>
      </div>
      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>
                {showUpdateBtn ? "Update Membership" : "Add Membership"}
              </strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <Button key="add" type="primary" onClick={form.submit}>
            {showUpdateBtn ? "Update" : "Add"}
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Membership"
                name="membership"
                rules={[{ required: true, message: "Please enter membership" }]}
              >
                <Input autocomplete="newpassword" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default Membership;
