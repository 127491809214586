import React from "react";
import CmsPage from "../CmsPage/CmsPage";
import "./Disclaimer.scss";
const Disclaimer = () => {
  // return <CmsPage id={7} />;
  return (
    <div className="Disclaim">
      <div className="disc-ban">

      </div>
      <div className="disc-policy">
        <h2>Disclaimar</h2>
        <div className="ourcomp">
          <img src={require("../../assets/images/etravos-logo-1.png")} alt="" />
          <p>At eTravos, our vision is to make travel simple and fun for all, and our core values guide us in making this possible. These core values can be seen in the projects that we undertake, and the way in which we solve problems for our customers. They are a representation of OUR BEING & OUR DOING. Each member of the eTravos family is guided by them each and every day.</p>
        </div>
        <div className="cont-inf">
          <div className="contimg">
            <img src={require("../../assets/images/disc/1.png")} alt="" />
          </div>
          <div className="contpara">
            <h6>Curious</h6>
            <p>I am inquisitive, I ask questions to absorb, reflect and solve . I strive to learn & understand how my work is connected to others. I am flexible and open to ideas.</p>
          </div>
        </div>
        <div className="cont-inf">

          <div className="contpara">
            <h6>Customer Focus</h6>
            <p>We use customer lens proactively to anticipate and understand customer expectations. We keep customer in the center of our deliberations, decisions and debates to champion their interest.</p>
          </div>
          <div className="contimg">
            <img src={require("../../assets/images/disc/4.png")} alt="" />
          </div>
        </div>
        <div className="cont-inf">
          <div className="contimg">
            <img src={require("../../assets/images/disc/6.png")} alt="" />
          </div>
          <div className="contpara">
            <h6>Commitment to Results</h6>
            <p>We take the highest level of ownership and accountability for every task at hand.We achieve superior results and execute well even in the face of all odds.</p>
          </div>
        </div>
        <div className="cont-inf">

          <div className="contpara">
            <h6>Continuous Improvement</h6>
            <p>We make ongoing efforts to enhance our products, services and processes. We believe that incremental changes are the cornerstones of breakthrough innovations.</p>
          </div>
          <div className="contimg">
            <img src={require("../../assets/images/disc/2.png")} alt="" />
          </div>
        </div>
        <div className="cont-inf">
          <div className="contimg">
            <img src={require("../../assets/images/disc/5.png")} alt="" />
          </div>
          <div className="contpara">
            <h6>Caring</h6>
            <p>I am genuine & helpful, I support & empathise, I nurture and invest in relationships, I collaborate & co-create.</p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Disclaimer;
