import React, { useEffect } from "react";
import { Checkbox, Table } from "antd";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import {
  getConvieneceFeeValue,
  getPromoDiscount,
} from "../../components/FlightsCheckout/FlightGrandTotalCard";

const TicketFlight = ({ ticketData, source, destination, fareRulesResp }) => {
  let dateFormat = "DD-MM-YYYY";
  const fullRowIndex = 2;

  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };
    if (row.empty) {
      obj.props.colSpan = 0;
    }
    return obj;
  };

  const columns = [
    {
      title: "Airline Name",
      dataIndex: "flightCode",
      key: "airlinename",
      render: (rec, data, index) => {
        if (data.empty) {
          return {
            children: (
              <div className="returnTitle">
                <ul>
                  <li>
                    GDS/Airline PNR:{" "}
                    <span className="bold-text">{ticketData.returnPnr} </span>
                  </li>
                  <li>
                    <p className="roundtripSeprator">Return Flight Details</p>
                  </li>
                  <li>
                    Travel Date:{" "}
                    <span>
                      {moment(
                        ticketData.returnSegment[0].departureDateTime
                      ).format(dateFormat)}
                    </span>
                  </li>
                </ul>
              </div>
            ),
            props: {
              colSpan: 5,
            },
          };
        } else {
          return rec;
        }
      },
    },
    {
      title: "Flight Number",
      dataIndex: "flightNumber",
      key: "flightnumber",
      render: renderContent,
    },
    {
      title: "Flights",
      dataIndex: "departureTerminal",
      key: "departures",
      render: (data, rec, index) => {
        const obj = {
          children: (
            <p>
              {rec.origin} - {rec.destination}
            </p>
          ),
          props: {},
        };
        if (rec.empty) {
          obj.props.colSpan = 0;
        }
        return obj;
      },
    },
    {
      title: "Departure Time",
      dataIndex: "departureDateTime",
      key: "departuretime",
      render: (d, r, index) => {
        const obj = {
          children: moment(d).format("hh:mm A"),
          props: {},
        };
        if (r.empty) {
          obj.props.colSpan = 0;
        }
        return obj;
      },
    },

    {
      title: "Arrivals Time",
      dataIndex: "arrivalDateTime",
      key: "arrivalstime",
      render: (d, r, index) => {
        const obj = {
          children: moment(d).format("hh:mm A"),
          props: {},
        };
        if (r.empty) {
          obj.props.colSpan = 0;
        }
        return obj;
      },
    },
  ];

  const travelcolumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Passenger Name",
      dataIndex: "paxname",
      render: (t, rec) => (
        <>
          {rec.firstName} {rec.lastName}
        </>
      ),
    },
    {
      title: "Type",
      dataIndex: "paxType",
      key: "paxType",
    },
  ];

  let flightsegments = ticketData.oneWaySegment || [];
  let flightFares = ticketData.oneWayflightFare.fares || [];
  let markup = ticketData.markup;

  if (ticketData.tripType == "TwoWay") {
    flightsegments = [
      ...ticketData.oneWaySegment,
      { empty: true },
      ...ticketData.returnSegment,
    ];
    flightFares = [
      ...ticketData.oneWayflightFare.fares,
      ...ticketData.returnflightFare.fares,
    ];
  }

  const getTaxCharges = () => {
    let amount = flightFares
      .filter((item) => item.FareCode != "Base")
      .reduce((total, item) => {
        if (item.PaxType == "ADT") {
          return total + parseFloat(item.Amount * ticketData.pax.adults);
        } else if (item.PaxType == "CHD") {
          return total + parseFloat(item.Amount * ticketData.pax.childCount);
        } else {
          return total + parseFloat(item.Amount * ticketData.pax.infantCount);
        }
      }, 0);
    return Number(amount).toFixed(2);
  };

  const getBaseAmount = () => {
    let amount = flightFares
      .filter((item) => item.FareCode == "Base")
      .reduce((total, item) => {
        if (item.PaxType == "ADT") {
          return total + Number(Number(item.Amount) * ticketData.pax.adults);
        } else if (item.PaxType == "CHD") {
          return (
            total + Number(Number(item.Amount) * ticketData.pax.childCount)
          );
        } else if (item.PaxType == "INF") {
          return (
            total + Number(Number(item.Amount) * ticketData.pax.infantCount)
          );
        }
      }, 0);
    return Number(amount + Number(markup)).toFixed(2);
  };

  const getTotalFlightFareForTicket = () => {
    let base = parseFloat(getBaseAmount());
    let tax = parseFloat(getTaxCharges());
    let convienenceFee = getConvFeeValue();
    let discount = getDicountValue();
    return Number(base + tax + convienenceFee - discount).toFixed(2);
  };

  const getRefundAmount = () => {
    return ticketData.RefundAmount ?? "0.00";
  }
  const getDicountValue = () => {
    return ticketData.promoData ? flightDiscount() : "0.00";
  };
  const flightDiscount = () => {
    let discountValue = getPromoDiscount(
      ticketData.promoData,
      ticketData.oneWayflightFare.totalFare
    );
    return Number(discountValue).toFixed(2);
  };

  const getConvFeeValue = () => {
    let totalwithtax =
      parseFloat(getBaseAmount()) + parseFloat(getTaxCharges());
    return ticketData.convienenceData
      ? getConvieneceFeeValue(ticketData.convienenceData, totalwithtax)
      : "0.00";
  };
  return (
    <div className="flight-ticket-collapse">
      <div className="ticket-container">
        <div className="passenger-details">
          <p>
            Passenger Contact: <span>+91 {ticketData.guestMobileNo}</span>
          </p>
          <p>
            Passenger Email: <span>{ticketData.guestEmaiId}</span>
          </p>
        </div>

        <div className="booking-flight-details">
          <div className="flight-ref-details">
            <ul>
              <li>
                Ref number: <span>{ticketData.id}</span>
              </li>
              <li>
                Booking Date:
                <span>
                  {ticketData.bookingDate
                    ? moment(ticketData.bookingDate).format(dateFormat)
                    : ""}
                </span>
              </li>
            </ul>
          </div>
          <div className="flight-fare-details">
            <ul>
              <li>
                GDS/Airline PNR:
                <span className="bold-text">{ticketData.pnr} </span>
              </li>
              <li>
                Status:
                <span
                  className={
                    ticketData.BookingStatus === "CANCELLED"
                      ? "colored-text"
                      : "confirmed"
                  }
                >
                  {ticketData.BookingStatus}
                </span>
              </li>
              <li>
                Fare Type:
                <span>{ticketData.fareType}</span>
              </li>
            </ul>
          </div>
          <div className="trip-details">
            <ul>
              <li>
                Trip Type:{" "}
                <span>
                  {ticketData.tripType == "TwoWay" ? "RoundTrip" : "Oneway"}
                </span>
              </li>
              <li>
                <div className="text-center">Trip Name</div>

                <div className="flight-from-to">
                  <p>
                    {source} - {destination}
                  </p>
                </div>
              </li>
              <li>
                Travel Date:
                <span>{moment(ticketData.departure).format(dateFormat)}</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="booking-flight-details-info">
          <div className="flight-deatils-text">
            <h5>
              {ticketData.tripType == "TwoWay" ? "Onward" : ""} Flight Details
            </h5>
          </div>
          <div className="flight-details-table">
            <Table
              dataSource={flightsegments}
              columns={columns}
              scroll={{ x: true }}
              bordered
            />
          </div>
        </div>

        <div className="booking-flight-details-info">
          <div className="flight-deatils-text">
            <h5>Travel Details</h5>
          </div>
          <div className="flight-details-table">
            <Table
              dataSource={ticketData.passengers}
              columns={travelcolumns}
              scroll={{ x: true }}
              bordered
            />
          </div>
        </div>

        <div className="fare-details">
          <ul>
            <li>
              <p>Base Fare</p>
              <p className="colored-text">₹ {getBaseAmount()}</p>
            </li>
            <li>
              <p>Surge and Taxes:YQ: </p>
              <p className="colored-text">₹ {getTaxCharges()}</p>
            </li>
            <li>
              <p>Conveneience Fee</p>
              <p className="colored-text">
                ₹ {Number(getConvFeeValue()).toFixed(2)}
              </p>
            </li>
            <li>
              <p>Discount:</p>
              <p className="colored-text">₹ {getDicountValue()}</p>
            </li>
            <li>
              <p className="colored-text total-tic">Grand Total</p>
              <p className="colored-text total-tic">
                ₹ {getTotalFlightFareForTicket()}
              </p>
            </li>
            {
              ticketData.BookingStatus !== "CONFIRMED" ? <li>

                <p>RefundAmount:</p>
                <p className="colored-text">₹ {getRefundAmount()}</p>

              </li> : null
            }

          </ul>
        </div>
        <div className="booking-flight-details-info">
          <div className="flight-deatils-text">
            <h5>Terms and Conditions</h5>
          </div>
          <div className="termCon_wrapper farerules">
            {!fareRulesResp ? (
              <p>Loading...</p>
            ) : (
              // fareRulesResp.data.fareRules.map((rule, i) => {
              //   return <div key={i}> <pre>{ReactHtmlParser(rule.ruleDetails)}</pre></div>;
              // })
              <div> <pre>{ReactHtmlParser(fareRulesResp.data.fareRules[0].ruleDetails)}</pre></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketFlight;
