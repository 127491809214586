import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  message,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
} from "antd";
import { useAuthContext } from "../../common/providers/AuthProvider";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";

import {
  CaretRightOutlined,
  CloseOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import moment from "moment";
import ApiClient from "../../helpers/ApiClient";
import { exportExcel } from "../ExportExcel";
const { Option } = Select;
const { Panel } = Collapse;
const { Content } = Layout;
const PGLogs = () => {
  const { user } = useAuthContext();

  let dateFormat = "DD-MM-YYYY";

  const [showTable, setShowTable] = useState(false);

  const [cancellationList, setCancellationList] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [refundRecord, setRefundRecord] = useState({});
  const [ApiResponse, setApiResponse] = useState("");
  const [refundModal, setRefundModal] = useState(false);
  const [airRefundModal, setAirRefundModal] = useState(false);
  const [showAPIRequestModal, setAPIRequestModal] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refundform] = Form.useForm();
  const [airRefundform] = Form.useForm();
  const [cancelReqForm] = Form.useForm();
  const getValueFromString = (data, ch) => {
    try {
      let json = JSON.parse(data);
      console.log("checking", { json, ch });
      return json[ch];
    } catch (e) {
      console.log(e);
      return " ";
    }
  };
  function copyToClipboard(text) {
    const elem = document.createElement("textarea");
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  }

  const [disabledTillDates, setDisabledTillDates] = useState(
    moment().startOf("day")
  );
  const disabledFutureDate = (currentDate) => {
    return currentDate >= moment();
  };
  const TillDatesDis = (currentDate) => {
    return currentDate < moment(disabledTillDates).startOf("day");
  };

  const getStatus = (status) => {
    // CREATED = 1,
    // BLOCKED = 2,
    // CONFIRMED = 3,
    // CANCELLED = 4,
    // PARTIALCANCELLED = 5,
    // CANCELLATIONREQUESTED = 6
    switch (status) {
      case 1: {
        return <p>CREATED</p>;
      }

      case 2: {
        return <p>BLOCKED</p>;
      }

      case 3: {
        return <p>CONFIRMED</p>;
      }
      case 4: {
        return <p>CANCELLED</p>;
      }
      case 5: {
        return <p>PARTIAL CANCELLED</p>;
      }
      case 6: {
        return <p>CANCELLATION REQUESTED</p>;
      }

      default:
        return;
    }
  };

  const getRefundStatus = (status) => {
    // RefundPending  = 1,
    // Refunded = 2,
    // Rejected = 3
    switch (status) {
      case 1: {
        return <p>REFUND PENDING</p>;
      }

      case 2: {
        return <p>REFUNDED</p>;
      }

      case 3: {
        return <p>REJECTED</p>;
      }

      default:
        return;
    }
  };

  const showRefundModal = () => {
    setRefundModal(true);
    refundform.resetFields();
  };

  const showAirRefundModal = () => {
    setAirRefundModal(true);
    airRefundform.resetFields();
  };

  const tableActions = (_, rec) => {
    return (
      <>
        {rec.CancellationStatus === 6 && rec.RefundStatus === 1 && (
          <Button
            onClick={() => {
              setRefundRecord(rec);
              showRefundModal();
            }}
          >
            Refund Cancellation
          </Button>
        )}

        {rec.CancellationStatus === 4 && rec.RefundStatus === 1 && (
          <Button
            onClick={() => {
              setRefundRecord(rec);
              showAirRefundModal();
            }}
          >
            Air Refunded Ticket
          </Button>
        )}
      </>
    );
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    {
      title: "Reference Number",
      dataIndex: "ReferenceNumber",
      sorter: (a, b) => a.ReferenceNumber - b.ReferenceNumber,
    },

    {
      title: "Cancellation ID",
      dataIndex: "CancellationID",
    },
    {
      title: "PNR",
      dataIndex: "PNR",
      sorter: (a, b) => a.PNR.localeCompare(b.PNR),
    },
    {
      title: "Segment",
      dataIndex: "Segment",
    },

    {
      title: "Airline Charges",
      dataIndex: "AirlineCharges",
    },

    {
      title: "Service Charge",
      dataIndex: "ServiceCharge",
    },

    {
      title: "Refund Amount",
      dataIndex: "RefundAmount",
    },

    {
      title: "Cancellation Charges",
      dataIndex: "CancellationCharges",
    },
    {
      title: "Cancelled Remarks",
      dataIndex: "CancelledRemarks",
    },

    {
      title: "Passengers",
      dataIndex: "Passengers",
      sorter: (a, b) => a.Passengers.localeCompare(b.Passengers),
    },
    {
      title: "Cancellation Status",
      dataIndex: "CancellationStatus",
      render: (status) => getStatus(status),
    },
    {
      title: "Refunds Status",
      dataIndex: "RefundStatus",
      render: (status) => getRefundStatus(status),
    },
    {
      title: "Created Date",
      dataIndex: "CreatedDate",
      sorter: (a, b) => a.CreatedDate.localeCompare(b.CreatedDate),
    },
    {
      title: "Modified Date",
      dataIndex: "ModifiedDate",
      sorter: (a, b) => a.ModifiedDate.localeCompare(b.ModifiedDate),
    },
    {
      title: "Action",

      render: (text, rec) => {
        return tableActions(text, rec);
      },
    },
  ];

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableData.filter(function (item) {
        if (item !== undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableData(searchResults);
    } else {
      setTableData(cancellationList);
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
    setTableData(cancellationList);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };

  const handleExcel = () => {
    const excelData = tableData.map((item) => {
      return {
        ...item,
      };
    });

    exportExcel(excelData, "cancellationListDetails");
  };

  const handleTimeAndDate = (value) => {
    if (value === "4") {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };

  const getPGLogs = (values) => {
    setLoading(true);
    setShowTable(true);
    setCancellationList([]);

    setTableData([]);
    ApiClient.get("admin/airCancellationFilter", values)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,

              CreatedDate: moment(item.CreatedDate).format(dateFormat),
              ModifiedDate: moment(item.ModifiedDate).format(dateFormat),
            };
          });
          setCancellationList(data);
          setTableData(data);
        }
        setLoading(false);
      })
      .catch((e) => {
        setCancellationList([]);
        setTableData([]);
        setLoading(false);
        console.log("api error", e);
      });
  };

  const onFormSubmit = (values) => {
    setShowTable(false);

    values.fromDate = moment(values.fromDate, dateFormat)
      .startOf("day")
      .format();
    values.toDate = moment(values.toDate, dateFormat).format();

    getPGLogs(values);
  };
  let key = 0;
  const keyGenerate = () => {
    return key++;
  };
  const RefundCancellationSubmit = (values, CancellationID) => {
    let userID = 1;
    if (user) {
      if (user.UserID && user.Role === 1) {
        userID = user.UserID;
      }
    }
    let data = {
      CancellationId: CancellationID,

      CancelledBy: userID,
      ...values,
    };
    ApiClient.post("admin/flightRefundCancellation", data)
      .then((res) => {
        if (res.status === 200) {
          message.success("Refund Cancellation Successfully", 3);
          setRefundModal(false);
          setRefundRecord({});
          cancelReqForm.submit();
        } else {
          message.error(" Failed", 3);
        }
      })
      .catch((e) => {
        console.log("api error", e);
      });
  };

  const AirRefundSubmit = (values, CancellationID) => {
    let userID = 1;
    if (user) {
      if (user.UserID && user.Role === 1) {
        userID = user.UserID;
      }
    }
    let data = {
      CancellationId: CancellationID,

      CancelledBy: userID,
      ...values,
    };
    ApiClient.post("admin/flightRefundedTicket", data)
      .then((res) => {
        if (res.status === 200) {
          message.success("Success", 3);
          setAirRefundModal(false);
          setRefundRecord({});
          cancelReqForm.submit();
        } else {
          message.error(" Failed", 3);
        }
      })
      .catch((e) => {
        console.log("api error", e);
      });
  };

  const RefundCancellationDetials = () => {
    let CancellationRequest = refundRecord.CancellationRequest
      ? JSON.parse(refundRecord.CancellationRequest)
      : {};
    if (Object.keys(CancellationRequest).length > 0)
      return (
        <div>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <p>
                <b>ReferenceNumber:</b> {CancellationRequest.ReferenceNumber}
              </p>
            </Col>

            <Col md={12} xs={24}>
              <p>
                <b>PNR:</b> {CancellationRequest.PNR}
              </p>
            </Col>
            <Col md={24} xs={24}>
              <p>
                <b> Segments Details:</b>
              </p>{" "}
              {CancellationRequest.Segments.map((item, index) => (
                <div style={{ marginLeft: "15px" }}>
                  <p>
                    <b>Flight Name:</b> {item.flightCode} ({item.flightNumber})
                  </p>
                  <p>
                    <b>Origin / Destination:</b> {item.origin} /{" "}
                    {item.destination}
                  </p>
                </div>
              ))}
            </Col>
          </Row>
        </div>
      );
  };

  return (
    <Layout>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Collapse
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <Tooltip placement="top" title={isActive ? "Show" : "Hide"}>
                      <CaretRightOutlined rotate={isActive ? 90 : -90} />
                    </Tooltip>
                  )}
                  expandIconPosition="right"
                  className="panel_wrapper"
                  defaultActiveKey={["1"]}
                >
                  <Panel header="Cancellation Request" key="1">
                    <Form
                      layout="vertical"
                      initialValues={{
                        cancellationId: "",
                      }}
                      form={cancelReqForm}
                      onFinish={onFormSubmit}
                    >
                      <Row gutter={16}>
                        <Col className="gutter-row" md={6} xs={24}>
                          <Form.Item
                            label="From Date"
                            name="fromDate"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="Select From date"
                              format={dateFormat}
                              onChange={(date) => {
                                if (moment(date) > moment(disabledTillDates)) {
                                  cancelReqForm.setFieldsValue({
                                    toDate: "",
                                  });
                                }
                                setDisabledTillDates(
                                  moment(date).startOf("day")
                                );
                              }}
                              disabledDate={disabledFutureDate}
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={6} xs={24}>
                          <Form.Item
                            label="To Date"
                            name="toDate"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="Select To date"
                              format={dateFormat}
                              disabledDate={TillDatesDis}
                            />
                          </Form.Item>
                        </Col>
                        {/* </>
                        ) : null} */}
                        <Col className="gutter-class" md={6} xs={24}>
                          <Form.Item
                            label="Cancellation ID"
                            name="cancellationId"
                          >
                            <Input
 autocomplete="newpassword"  />
                          </Form.Item>
                        </Col>
                        {/* <Col className="gutter-row" md={6} xs={24}>
                          <Form.Item
                            label="Payment Status"
                            name="paymentStatus"
                          >
                            <Select
 autocomplete="newpassword"  placeholder="Please select">
                              <Option value={0}>Success</Option>
                              <Option value={1}>Fail</Option>
                              <Option value={2}>Pending</Option>
                            </Select>
                          </Form.Item>
                        </Col> */}
                      </Row>
                      <Row className="reports-btns">
                        <Button type="primary" htmlType="submit">
                          Search
                        </Button>
                        <Button
                          type="danger"
                          onClick={(e) => setShowTable(false)}
                        >
                          Reset
                        </Button>
                      </Row>
                    </Form>
                  </Panel>
                </Collapse>
              </div>
            </Col>
            {showTable && (
              <Col span={24}>
                <div className="card-bt-gap">
                  <Card bordered={false}>
                    {tableData.length > 0 ? (
                      <>
                        <div className="card-add-heading">
                          <div className="rows-count">
                            <h5>View Cancellation Request</h5>
                            <p>{tableData.length} rows found !</p>
                          </div>
                          {/* <div className="action-images">
                            {showSearchBox && (
                              <Input
 autocomplete="newpassword" 
                                placeholder="Search"
                                onChange={(e) => searchTableData(e)}
                                suffix={closeSearchInput}
                                style={{ padding: "0px 12px" }}
                              />
                            )}
                            &nbsp;&nbsp;
                            <img
                              src={search}
                              alt="search"
                              onClick={(e) => searchData()}
                            />
                            <img
                              src={excel}
                              alt="excel"
                              onClick={handleExcel}
                            />
                          </div> */}
                        </div>

                        <Table
                          bordered
                          dataSource={tableData}
                          columns={columns}
                          scroll={{ x: true }}
                          pagination={{
                            defaultPageSize: 25,
                            showSizeChanger: true,
                            pageSizeOptions: ["25", "50", "100", "125"],
                          }}
                          rowKey={keyGenerate}
                        />
                      </>
                    ) : loading ? (
                      <Spin
                        size="large"
                        tip="Loading..."
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <b>No Results Found!</b>
                    )}
                  </Card>
                </div>
              </Col>
            )}
          </Row>
          <Modal
            width="900px"
            visible={refundModal}
            title="Refund Cancellation"
            onCancel={(e) => {
              setRefundModal(false);
            }}
            footer={[
              <div>
                <Button key="clear" onClick={() => refundform.resetFields()}>
                  Clear
                </Button>
                <Button key="add" type="primary" onClick={refundform.submit}>
                  Update
                </Button>
              </div>,
            ]}
          >
            {Object.keys(refundRecord).length > 0 && (
              <div>
                {RefundCancellationDetials()}
                <Form
                  form={refundform}
                  layout="vertical"
                  onFinish={(val) =>
                    RefundCancellationSubmit(val, refundRecord.CancellationID)
                  }
                >
                  <Row gutter={16}>
                    <Col className="gutter-class" md={6} xs={24}>
                      <Form.Item
                        label="Airline Charges"
                        name="AirlineCharges"
                        rules={[
                          { required: true, message: "Required" },
                          {
                            validator: (_, value) => {
                              if (value > 0) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject(
                                  "Please Enter valid Amount"
                                );
                              }
                            },
                          },
                        ]}
                      >
                        <Input
 autocomplete="newpassword"  type="number" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-class" md={6} xs={24}>
                      <Form.Item
                        label="Service Charge"
                        name="ServiceCharge"
                        rules={[
                          { required: true, message: "Required" },
                          {
                            validator: (_, value) => {
                              if (value > 0) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject(
                                  "Please Enter valid Amount"
                                );
                              }
                            },
                          },
                        ]}
                      >
                        <Input
 autocomplete="newpassword"  type="number" />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-class" md={6} xs={24}>
                      <Form.Item
                        label="Cancelled Remarks"
                        name="CancelledRemarks"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Input
 autocomplete="newpassword"  />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
          </Modal>
          <Modal
            width="900px"
            visible={airRefundModal}
            title="Air Refunded Ticket"
            onCancel={(e) => {
              setAirRefundModal(false);
            }}
            footer={[
              <div>
                <Button key="clear" onClick={() => airRefundform.resetFields()}>
                  Clear
                </Button>
                <Button key="add" type="primary" onClick={airRefundform.submit}>
                  Update
                </Button>
              </div>,
            ]}
          >
            {Object.keys(refundRecord).length > 0 && (
              <div>
                {RefundCancellationDetials()}
                <Form
                  form={airRefundform}
                  layout="vertical"
                  onFinish={(val) =>
                    AirRefundSubmit(val, refundRecord.CancellationID)
                  }
                >
                  <Row gutter={16}>
                    <Col className="gutter-class" md={6} xs={24}>
                      <Form.Item
                        label="Refund Amount"
                        name="RefundAmount"
                        rules={[
                          { required: true, message: "Required" },
                          {
                            validator: (_, value) => {
                              if (value > 0) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject(
                                  "Please Enter valid Amount"
                                );
                              }
                            },
                          },
                        ]}
                      >
                        <Input
 autocomplete="newpassword"  type="number" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-class" md={6} xs={24}>
                      <Form.Item
                        label="Cancellation Charge"
                        name="CancellationCharges"
                        rules={[
                          { required: true, message: "Required" },
                          {
                            validator: (_, value) => {
                              if (value > 0) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject(
                                  "Please Enter valid Amount"
                                );
                              }
                            },
                          },
                        ]}
                      >
                        <Input
 autocomplete="newpassword"  type="number" />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-class" md={6} xs={24}>
                      <Form.Item
                        label="Cancellation Remarks"
                        name="CancellationRemarks"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Input
 autocomplete="newpassword"  />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
          </Modal>
        </Content>
      </div>
    </Layout>
  );
};

export default PGLogs;
