import React from "react";
import "../subscribe/Subscribe.scss";
import * as ReactBootstrap from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import APIClient from "../../../src/helpers/ApiClient";
import * as ANTD from "antd";
// import { Checkbox } from "antd";

const Subscribe = () => {
  const [addValue, setValue] = useState();

  function getInputValue(event) {
    // console.log("value", event.target.value);
    setValue(event.target.value);
  }
  const Add = () => {
    let reqObj = {
      DeviceOS: 3,
      ApplicationType: 1,
      Email: addValue,
      CreatedBy: 0,
      Status: 0,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
    };
    APIClient.post("admin/subscriberReport", reqObj).then((res) => {
      if (res.status == 200) {
        // console.log(res);
        ANTD.message("successfully added Email", 3);
        // getSubscriberList();
      }
      if (res.status == 400) {
        ANTD.message.error("Email already exist");
      }
    });
  };

  return (
    <div>
      <div>
        <section className="need-hotel-room-section">
          <div className="hotel-room">
            <div className="hotel-icon"></div>
            <div className="hotel-room-text">
              <div className="room-title">
                <p>
                  Need a hotel room?
                  <br />
                  <b>We've got deals for you!</b>
                </p>
                <Link to="/hotels">
                  <span>Get deals!</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div id="subscribe" className="d-none d-md-block">
        <div className="subscribe-wrapper">
          <h2>Enjoy up to 40% discounts!</h2>
          <h3 className="d-none">
            Thank you for signing up. Soon we will send you the best deals!
          </h3>
          <p className="sub-title">Subscribe today</p>
          <ReactBootstrap.Form>
            <ReactBootstrap.Form.Row>
              <ReactBootstrap.Col>
                <ReactBootstrap.Form.Control
                  size="lg"
                  placeholder="Your e-mail address"
                  onChange={getInputValue}
                />
              </ReactBootstrap.Col>
              <ReactBootstrap.Col md={2}>
                <ReactBootstrap.Button
                  className="subscribe-btn"
                  size="lg"
                  variant="primary"
                  type="submit"
                  onClick={Add}
                >
                  Subscribe
                </ReactBootstrap.Button>
              </ReactBootstrap.Col>
            </ReactBootstrap.Form.Row>
            <ReactBootstrap.Form.Check
              custom
              type="checkbox"
              id="subscribe-check"
              label="Unlock the limited offers for Regular Customers.
             I agree to receive marketing information (via newsletter) from i2Space. 
             including promotional offers at the e-mail address provided by me.
             
             By ticking the checkbox, providing the e-mail address and choosing'Subscribe' (collectively) 
             you consent to the processing of your personal data"
            />
          </ReactBootstrap.Form>
        </div>
        <div className="subscribe-thanking subscribe-wrapper d-none">
          <h4>
            Thank you for signing up. Soon we will send you the best deals!
          </h4>
          <ReactBootstrap.Button
            className="add-email-subscribe"
            size="lg"
            variant="primary"
            type="submit"
          >
            Add next e-mail address
          </ReactBootstrap.Button>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
