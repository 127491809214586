import React, { useContext, useState } from "react";
import { Card, Col, Row, Form, Popconfirm, Input } from "antd";
import FareSummaryCard from "../FlightsCheckout/FareSummaryCard";

import BookPayCard from "../FlightsCheckout/Book-Pay-Card";
import "./Flights-review.scss";
import ApiClient from "../../helpers/ApiClient";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import GlobalStatesContext from "../../common/providers/GlobalStatesContext";
import moment from "moment";
import {
  FlightGrandTotalCard,
  getFlightGrandTotal,
} from "../FlightsCheckout/FlightGrandTotalCard";
const Review = (props) => {
  const globalContext = useContext(GlobalStatesContext);
  const { promoData } = globalContext.state.otherData;
  const { ConvFee } = globalContext.state.otherData;
 
  const dateFormat = "DD-MM-YYYY";
  const oriDateFormat = "YYYY-MM-DD";
  let passengerDetails = props.flightContext.state.airBookStateObj;
 
  let airPriceRespData = props.flightContext.state.flightAirPriceResp;
  const { updateAirBookRespState } = props.flightContext;

  let history = useHistory();
  const fetchAirBook = () => {
    //add Dicount and Convience Fee
    passengerDetails.promoData = promoData;
    passengerDetails.convienenceData = ConvFee;
    ApiClient.post("flights/airBlock", passengerDetails)
      .then((resp) => {
        return resp;
      })
      .then((resp) => {
        if (resp.statusCode === 200) {
         
          updateAirBookRespState(resp);
          processPayment(resp.data.BookingRefNo);
        } else {
          console.log("airbook feching failed");
        }
      })
      .catch((err) => console.log(err));
  };

  const [pgDisplay, setPgDisplay] = useState(false);
  const [pgData, setPgData] = useState({});
  const processPayment = (oid) => {
    const userDetails = {
      ...passengerDetails.passengers[0],
    };
    // this is payment data
    let amount_total = getFlightGrandTotal(
      airPriceRespData,
      ConvFee,
      promoData
    );
    let data = {
      OrderId: oid,
      OrderAmount: amount_total,
      OrderCurrency: "INR",
      OrderNote: "FLIGHT TICKET",
      CustomerName: userDetails.firstName + " " + userDetails.lastName,
      CustomerEmail: userDetails.email,
      CustomerPhone: userDetails.mobile,
    };
    // set PgData and Display
    setPgDisplay(true);
    setPgData(data);
  };

  return (
    <section className="flight-wrapper">
      <div className="flight-checkout review-container">
        <div className="checkout-heading">
          <Popconfirm
            placement="right"
            title="Are you sure you want to navigate back from this page you may lose data?"
            onConfirm={() => {
              history.go(-1);
            }}
            overlayClassName="flighttoast"
          >
            <div className="goback">
              <ArrowLeftOutlined />
              <span>Go back </span>
            </div>
          </Popconfirm>
        </div>
        <Row gutter={[16, 16]} className="flight-review-row">
          <Col md={16} xs={24}>
            <>
              <div className="user-details">
                <p className="heading">Passengers</p>
                <Card className="flight-cards-details">
                  {passengerDetails?.passengers?.map(
                    (passengerDetailsObj, passengerIndex) => (
                      <div key={passengerIndex}>
                        <div className="card-adult-text">
                          <div className="pax-heading">
                            <div className="pax-heading">
                              <p>
                                {passengerDetailsObj.paxType === "ADT"
                                  ? "Adult"
                                  : passengerDetailsObj.paxType === "CHD"
                                    ? "Child"
                                    : "Infant"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <Form
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          layout="horizontal"
                          className="passenger-form"
                        >
                          <Form.Item label="Name" name="passengerName">
                            <Input
 autocomplete="newpassword" 
                              className="contacts-value-de"
                              size={"large"}
                              plaintext
                              readOnly
                              defaultValue={
                                passengerDetailsObj.title +
                                " " +
                                passengerDetailsObj.firstName +
                                " " +
                                passengerDetailsObj.lastName
                              }
                            />
                          </Form.Item>
                          <Form.Item label="Gender" name="gender">
                            <Input
 autocomplete="newpassword" 
                              className="contacts-value-de"
                              size={"large"}
                              plaintext
                              readOnly
                              defaultValue={
                                passengerDetailsObj.genderType === "m"
                                  ? "Male"
                                  : "Female"
                              }
                            />
                          </Form.Item>
                          <Form.Item label="Date of birth" name="dob">
                            <Input
 autocomplete="newpassword" 
                              className="contacts-value-de"
                              size={"large"}
                              plaintext
                              readOnly
                              defaultValue={moment(
                                passengerDetailsObj.dob,
                                oriDateFormat
                              ).format(dateFormat)}
                            />
                          </Form.Item>
                          {passengerDetailsObj.passportDOI ? (
                            <Form.Item label="D.O.I" name="docName">
                              <Input
 autocomplete="newpassword" 
                                size={"large"}
                                plaintext
                                readOnly
                                defaultValue={passengerDetailsObj.passportDOI}
                              />
                            </Form.Item>
                          ) : null}
                          {passengerDetailsObj.passportDOE ? (
                            <Form.Item
                              label="Document expiration date"
                              name="doe"
                            >
                              <Input
 autocomplete="newpassword" 
                                size={"large"}
                                plaintext
                                readOnly
                                defaultValue={passengerDetailsObj.passportDOE}
                              />
                            </Form.Item>
                          ) : null}
                        </Form>
                      </div>
                    )
                  )}
                </Card>
              </div>

              {passengerDetails?.gstDetails?.gstNumber === "" ? null : (
                <div className="user-details">
                  <p className="heading">GST Details</p>
                  <Card className="checkout-custom-card">
                    <div className="card-adult-text">
                      <div className="pax-heading">
                        <div className="pax-heading">
                          <p>GST Details</p>
                        </div>
                      </div>
                    </div>
                    <Form
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      layout="horizontal"
                      className="passenger-form"
                    >
                      <Form.Item
                        label="Company Name"
                        rules={[{ required: true }]}
                        name="companyname"
                      >
                        <Input
 autocomplete="newpassword" 
                          size={"large"}
                          plaintext
                          readOnly
                          defaultValue={
                            passengerDetails?.gstDetails?.gstCompanyName
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label="GST Number"
                        rules={[{ required: true }]}
                        name="gstNumber"
                      >
                        <Input
 autocomplete="newpassword" 
                          size={"large"}
                          plaintext
                          readOnly
                          defaultValue={passengerDetails?.gstDetails?.gstNumber}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Contact Number"
                        rules={[{ required: true }]}
                        name="contactNumber"
                      >
                        <Input
 autocomplete="newpassword" 
                          size={"large"}
                          plaintext
                          readOnly
                          defaultValue={passengerDetails?.gstDetails?.gstPhoneNo}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Company Email"
                        rules={[{ required: true }]}
                        name="companyemail"
                      >
                        <Input
 autocomplete="newpassword" 
                          size={"large"}
                          plaintext
                          readOnly
                          defaultValue={passengerDetails?.gstDetails?.gstEmailId}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Company Address"
                        rules={[{ required: true }]}
                        name="companyaddress"
                      >
                        <Input
 autocomplete="newpassword" 
                          size={"large"}
                          plaintext
                          readOnly
                          defaultValue={
                            passengerDetails?.gstDetails?.gstAddressLine1
                          }
                        />
                      </Form.Item>
                    </Form>
                  </Card>
                </div>
              )}
            </>
          </Col>
          <Col xs={24} md={8}>
            <div className="checkout-sticky-part">
              {airPriceRespData?.flightDetails?.map((flightDetObj, i) => (
                <FareSummaryCard
                  flightDetObj={flightDetObj}
                  currency={flightDetObj.fareFamilies[0].currency}
                  title={i === 0 ? "Onward Trip Fare" : "Return Trip Fare"}
                />
              ))}

              <div className="grand-total-card">
                <FlightGrandTotalCard
                  airPriceResp={airPriceRespData}
                  PromoNotVisible={true}
                />
              </div>
              <BookPayCard
                isLoading={true}
                bookpaycardinfo={"flight-review"}
                fetchAirBook={fetchAirBook}
                pgDisplay={pgDisplay}
                pgData={pgData}
              />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Review;
