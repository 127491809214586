import React, { useEffect } from "react";
import { message } from "antd";
import {
  BrowserRouter as Router,
  useLocation,
  withRouter,
  Route,
  Switch,
} from "react-router-dom";
import "./App.scss";

import { useAuthContext } from "./common/providers/AuthProvider";
import { AdminAccessProvider } from "./common/providers/AdminAccessProvider";

import GlobalStatesContext from "./common/providers/GlobalStatesContext";
import CombinedNavbar from "./common/CombinedNavbar/CombinedNavbar";
import Administration from "./admin/Administration";
import UserNavigations from "./common/navbar/userNavigations";
import CombinedFooter from "./common/CombinedFooter/CombinedFooter";
import Login from "./components/Login/LoginBan";
import Nav from "./common/navbar/Nav";
import Footer from "./common/footer/Footer";
import CombinedHome from "./components/CombinedHome/CombinedHome";
function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

const App = () => {
  const {
    tryLocalSignin,
    // isLoading
  } = useAuthContext();

  useEffect(() => {
    tryLocalSignin();
    message.config({
      duration: 3,
      maxCount: 1,
    });
  }, []);
  // if (isLoading) {
  //   return null;
  // }
  return (
    <GlobalStatesContext.Consumer>
      {(gscontext) => (
        <Router>
          <ScrollToTop>
            {gscontext.state.isLogin.role ? (
              <AdminAccessProvider>
                <Administration updateIsLogin={gscontext.updateIsLogin} />
              </AdminAccessProvider>
            ) : gscontext.state.isLogin.flag ? (
              <div className="main_body">
                <div className="main_content">
                  <CombinedNavbar gscontext={gscontext} />
                  <UserNavigations gscontext={gscontext} />
                </div>
              </div>
            ) : (
              <div className="main_body">
                <div className="main_content">
                <UserNavigations gscontext={gscontext} />
                </div>
                <Footer/>
              </div>
              // <>
              //   <Switch>
              //     <Route
              //       exact
              //       path={"/"}
              //       render={() => <UserNavigations gscontext={gscontext} />}
              //     />


              //   </Switch>

              //   {/* <UserNavigations gscontext={gscontext} />
              //   <Footer /> */}
              // </>
            )}
          </ScrollToTop>
        </Router>
      )}
    </GlobalStatesContext.Consumer>
  );
};

export default App;
