import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import ApiClient from "../../helpers/ApiClient";
import "antd/dist/antd.css";
import { exportExcel } from "../ExportExcel";
const { Content } = Layout;
const { Option } = Select;

const ProviderApi = (props) => {
  const [modalVisible, setModalVisible] = useState(false);

  const [apiProviders, setApiProviders] = useState([]);
  const [providers, setProviders] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showUpdateBtnFlag, setShowUpdateBtnFlag] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  // const [bindTableData,setBindTableData] = useState(apiProviders);
  const [apiProviderForm] = Form.useForm();

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 23 },
  };

  /* ========== API's ================ */

  useEffect(() => {
    getAPIProvidersList();
    getProvidersList();
    // setBindTableData(apiProviders);
  }, []);

  const getAPIProvidersList = () => {
    ApiClient.get("admin/apiProviders")
      .then((res) => {
        if (res.statusCode == 200) {
          setApiProviders(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getProvidersList = () => {
    ApiClient.get("admin/providers")
      .then((resp) => {
        if (resp.statusCode == 200) {
          setProviders(resp.data);

          setServicesList(
            resp.data.length > 0 &&
            resp.data.map((item, i) => {
              return (
                <Option key={i} value={item.ProviderID}>
                  {item.Provider}
                </Option>
              );
            })
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /* ========== ADD APIProvider Start ========= */
  const onFinish = (values) => {
    console.log("Success:", values);
    showUpdateBtnFlag ? putAPiProvider(values) : postAPiProvider(values);
  };

  /* ========= Edit / update apiProvider ========== */

  const postAPiProvider = (values) => {
    let Trip_Type = values.TripType.toString();
    let data = {
      ProviderID: values.provider,
      ServiceType: values.ServiceType,
      APIURL: values.apiUrl,
      APIKey: values.apiConsumerKey,
      APISecretKey: values.apiConsumerSecretKey,
      TripType: [Trip_Type],
      Status: values.Status,
      AutoTicketing: values.AutoTicketing,
      DeviceOS: 3,
      ApplicationType: 1,
      CreatedBy: 0,
      DeviceOSVersion: "",
      DeviceToken: "",
      FirBaseToken: "",
    };

    ApiClient.post("admin/apiProviders", data)
      .then((res) => {
        if (res.statusCode == 200) {
          getAPIProvidersList();
          setModalVisible(false);
          apiProviderForm.resetFields();
          message.success("Successfully added api Provider", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const putAPiProvider = (values) => {
    let apiPID = currentRecord.APIProviderID;
    let Trip_Type = values.TripType.toString();
    let editObj = {
      ServiceType: values.ServiceType,
      APIURL: values.apiUrl,
      APIKey: values.apiConsumerKey,
      APISecretKey: values.apiConsumerSecretKey,
      TripType: [Trip_Type],
      Status: values.Status,
      AutoTicketing: values.AutoTicketing,
      DeviceOS: 3,
      ApplicationType: 1,
      CreatedBy: 0,
      DeviceOSVersion: "",
      DeviceToken: "",
      FirBaseToken: "",
    };

    ApiClient.put(`admin/apiProviders/${apiPID}`, {}, editObj)
      .then((response) => {
        if (response.statusCode == 200) {
          getAPIProvidersList();
          setModalVisible(false);
          message.success("API Provider Updated successfully", 3);
        } else if (response.statusCode == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    // }
  };

  function handelEdit(cur_Record) {
    setCurrentRecord(cur_Record);
    setShowUpdateBtnFlag(true);
    apiProviderForm.setFieldsValue({
      ServiceType: cur_Record.ServiceType,
      provider: cur_Record.ProviderID?.Provider,
      apiUrl: cur_Record.APIURL,
      apiConsumerKey: cur_Record.APIKey,
      apiConsumerSecretKey: cur_Record.APISecretKey,
      TripType: JSON.parse("[" + cur_Record.TripType + "]"),
      AutoTicketing: cur_Record.AutoTicketing,
      Status: cur_Record.Status,
    });
    setModalVisible(true);
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const tableActions = (c_Record) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => handelEdit(c_Record)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteAPIProvider(c_Record)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const deleteAPIProvider = (record) => {
    var Id = record.APIProviderID;
    ApiClient.delete("admin/apiProviders/" + Id)
      .then((res) => {
        if (res.statusCode == 200) {
          getAPIProvidersList();
          message.success("API Provider Deleted successfully", 3);
        }
      })
      .catch((e) => {
        console.log("delete api error", e);
      });
  };

  function chooseServiceType(ServiceType) {
    switch (ServiceType) {
      case 1:
        return "Flight";
      case 2:
        return "Hotel";
      case 3:
        return "Bus";
      case 4:
        return "Trains";
      default:
        return null;
    }
  }

  function chooseTripType(res) {
    let result = "";
    if (res.indexOf("1") != -1) {
      result += "OneWay,";
    }
    if (res.indexOf("2") != -1) {
      result += " Round-Trip,";
    }

    if (res.indexOf("3") != -1) {
      result += " Multicity";
    }
    return result;
  }

  function chooseProviderName(pName) {
    return pName;
  }

  const columns = [
    {
      title: "Service",
      render: (record) => chooseServiceType(record.ServiceType),
      sorter: (a, b) =>
        a.ServiceType.toString().localeCompare(b.ServiceType.toString()),
    },
    {
      title: "API Provider Name",
      render: (record) => chooseProviderName(record.ProviderID?.Provider),
      sorter: (a, b) =>
        a.ProviderID?.Provider.toString().localeCompare(
          b.ProviderID?.Provider.toString()
        ),
    },
    {
      title: "Trip Type",
      render: (record) => chooseTripType(record.TripType),
    },
    {
      title: "Auto Ticketing",
      dataIndex: "AutoTicketing",
      key: "AutoTicketing",
      render: (val) => (val ? "No" : "Yes"),
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "name",
      render: (status) => getStatus(status),
    },
    {
      title: "Actions",
      render: (record) => tableActions(record),
    },
  ];
  const getStatus = (status) => {
    return (
      <div style={!status ? { color: "#ff0000" } : { color: "#008000" }}>
        <b>{!status ? "Inactive" : "Active"}</b>
      </div>
    );
  };

  /***** Radio-btn*****/

  const searchData = (searchData) => {
    setShowSearchBox(true);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;

    if (searchVal.length > 0) {
      const searchRes = apiProviders.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setApiProviders(searchRes);
    } else {
      getAPIProvidersList();
    }
  };

  const handleShowAddModal = () => {
    apiProviderForm.resetFields();
    setShowUpdateBtnFlag(false);
    setModalVisible(true);
  };

  /* ============ Implement search =========== */

  /* =========== excel start ========== */

  const handleExcel = (e) => {
    const excelCustomData = apiProviders.map((item) => {
      const obj = {
        Service: chooseServiceType(item.ServiceType),
        API_Provider_Name: item.ProviderID.Provider,
        Trip_Type: chooseTripType(item.TripType),
        API_URL: item.APIURL,
        APIKey: item.APIKey,
        APISecretKey: item.APISecretKey,
      };
      return obj;
    });
    exportExcel(excelCustomData, "APIProviders");
  };

  /* =========== excel END ========== */

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  let lastIndex = 0;
  const uniqueKey = () => {
    lastIndex++;
    return lastIndex;
  };

  return (
    <div className="promo providerapi">
      <Layout>
        <div className="manage-markup-section">
          <Content className="admin-container">
            <Row>
              <Col span={24}>
                <div className="card-bt-gap">
                  <Card bordered={false}>
                    <div className="card-add-heading">
                      <div className="rows-count">
                        <h5>View Providers API's</h5>
                        <p>{apiProviders.length} rows found !</p>
                      </div>
                      <div className="action-images">
                        {showSearchBox && (
                          <Input
                            autocomplete="newpassword"
                            placeholder="Search"
                            onChange={(e) => searchTableData(e)}
                            suffix={closeSearchInput}
                            style={{ padding: "0px 12px" }}
                          />
                        )}
                        &nbsp;&nbsp;
                        <img
                          src={search}
                          alt="search"
                          onClick={(e) => searchData(apiProviders)}
                        />
                        <img
                          src={excel}
                          alt="excel"
                          onClick={(e) => handleExcel(e)}
                        />
                        <p className="add-deposit-icon">
                          <PlusCircleFilled onClick={handleShowAddModal} />
                        </p>
                      </div>
                    </div>

                    <Table
                      scroll={{ x: true }}
                      bordered
                      rowKey={uniqueKey}
                      dataSource={apiProviders}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </Card>
                </div>
              </Col>
            </Row>
          </Content>
        </div>
      </Layout>
      <Modal
        title={showUpdateBtnFlag ? "Update Provider" : "Add Provider"}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          showUpdateBtnFlag ? (
            <Button type="primary" onClick={apiProviderForm.submit}>
              Update
            </Button>
          ) : (
            <Button type="primary" onClick={apiProviderForm.submit}>
              Add
            </Button>
          ),
        ]}
        width={"1140px"}
      >
        <Form
          form={apiProviderForm}
          {...layout}
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row>
            <Col md={8} xs={24}>
              <Form.Item
                name="ServiceType"
                label="Service"
                rules={[{ required: true, message: "Please choose  service" }]}
              >
                <Select
                  autocomplete="newpassword" >
                  <Select.Option value={1}>Flights</Select.Option>
                  <Select.Option value={3}>Buses</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                name="provider"
                label="API Provider"
                rules={[{ required: true, message: "Please Select provider!" }]}
              >
                <Select
                  autocomplete="newpassword" >{servicesList}</Select>
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label="API URL"
                name="apiUrl"
                rules={[{ required: true, message: "Please input URL!" }]}
              >
                <Input
                  autocomplete="newpassword" />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label="API Consumer Key"
                name="apiConsumerKey"
                rules={[
                  { required: true, message: "Please input consumer key!" },
                ]}
              >
                <Input
                  autocomplete="newpassword" />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label="API Consumer Secret Key"
                name="apiConsumerSecretKey"
                rules={[
                  { required: true, message: "Please input secret key!" },
                ]}
              >
                <Input
                  autocomplete="newpassword" />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                name="TripType"
                label="Trip Type"
                rules={[{ required: true, message: "Required" }]}
              >
                <Checkbox.Group>
                  <Row>
                    <Checkbox value={1}>One way</Checkbox>
                    <Checkbox value={2}>Round-trip</Checkbox>
                    <Checkbox value={3}>Multicity</Checkbox>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                name="AutoTicketing"
                label="Auto Ticketing"
                rules={[{ required: true, message: "Required" }]}
              >
                <Radio.Group>
                  <Radio value={0}>Yes</Radio>
                  <Radio value={1}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label="Status"
                name="Status"
                rules={[{ required: true, message: "Required" }]}
              >
                <Radio.Group>
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default ProviderApi;
