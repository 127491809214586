import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./PaymentSuccess.scss";

import queryString from "query-string";
import ApiClient from "../../helpers/ApiClient";
import Busticketsuccess from "../../components/Busticketsuccess/Busticketsuccess";
import BusTicketSuccSkeleton from "../../components/Busticketsuccess/BusTicketSuccSkeleton";
import Flightticketsuccess from "../../components/Flightticketsuccess/Flightticketsuccess";
import FlightTicketSucSkeleton from "../../components/Flightticketsuccess/FlightTicketSucSkeleton";
// import { res } from "./file";
const PaymentSucces = () => {
  const [type, setType] = useState("");
  const [isBooked, setIsBooked] = useState(false);
  const [actionUrl, setActionUrl] = useState("");
  const [flightTicketsDetails, setFlightTicketsDetails] = useState({
    BookingRefNo: null,
    flightDetails: [],
    ticketAllData: {},
    loading: true,
  });
  const [busTicketsDetails, setBusTicketsDetails] = useState({
    twoWay: false,
    refNo1: null,
    refNo2: null,
    loading: true,
  });

  const params = queryString.parse(document.location.search);

  const airBook = (value) => {
    // const response = res;
    // console.log(res);
    // setFlightTicketsDetails({
    //   BookingRefNo: response.data.BookingRefNo,
    //   flightDetails: response.data.flightDetails,
    //   ticketAllData: response.charges.ticketAllData,
    //   loading: false,
    // });
    // return;
    ApiClient.post("flights/airBook/" + value)
      .then((response) => {

        if (response.statusCode === 200) {
          setFlightTicketsDetails({
            BookingRefNo: response.data.BookingRefNo,
            flightDetails: response.data.flightDetails,
            ticketAllData: response.charges.ticketAllData,
            loading: false,
          });
          return;
        }
        setFlightTicketsDetails({
          BookingRefNo: null,
          flightDetails: [],
          ticketAllData: {},
          loading: false,
        });
      })
      .catch((error) => {
        setFlightTicketsDetails({
          BookingRefNo: null,
          flightDetails: [],
          ticketAllData: {},
          loading: false,
        });
        console.error(error);
      });
  };

  const busBookApi = async (preBookingRefId, ticketNo) => {
    setBusTicketsDetails((prev) => ({
      ...prev,
      loading: true,
    }));
    await ApiClient.get("buses/bookTicket?refNo=" + preBookingRefId)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.statusCode === 200) {
          if (ticketNo == 2) {
            setBusTicketsDetails((prev) => ({
              ...prev,

              refNo2: resp.data.referenceNo,
            }));
          } else {
            setBusTicketsDetails((prev) => ({
              ...prev,

              refNo1: resp.data.referenceNo,
            }));
          }
        }
        setBusTicketsDetails((prev) => ({
          ...prev,
          loading: false,
        }));
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  const busBooking = async (ref) => {
    setType("BUS");
    let busResObj = JSON.parse(localStorage.getItem("twoWayBusData"));
    let ticketNo = 1;

    if (busResObj) {
      if (busResObj.length > 1) {
        setBusTicketsDetails({
          twoWay: true,
          refNo1: null,
          refNo2: null,
          loading: true,
        });
        await busBookApi(busResObj[0].BookingReferenceNo, ticketNo);
        ticketNo = 2;
      }
    }

    await busBookApi(ref, ticketNo);
  };

  useEffect(() => {
    if (params.ref && params.ref.indexOf("TSG-F") != -1) {
      setType("FLIGHT");
      airBook(params.ref);
    } else {
      busBooking(params.ref);
    }
    // if (params && params.Edit === "edit") {
    //   console.log("url params", params);
    //   setIsDateDisplay(false);
    //   form.setFieldsValue({
    //     dateType: params.dateType,
    //     travelType: Number(params.travelType),
    //     fromDate: moment(params.fromDate),
    //     toDate: moment(params.toDate),
    //   });
    // }
  }, []);

  return (
    <div className="success_area">
      {type === "FLIGHT" ? (
        flightTicketsDetails.loading ? (
          <FlightTicketSucSkeleton />
        ) : (
          <Flightticketsuccess flightTicketsDetails={flightTicketsDetails} />
        )
      ) : null}
      {type === "BUS" ? (
        busTicketsDetails.loading ? (
          <BusTicketSuccSkeleton />
        ) : (
          <Busticketsuccess busTicketsDetails={busTicketsDetails} />
        )
      ) : null}
    </div>
  );

  // return (
  //   <div className="pay_success_area">
  //     <div className="inner_area">
  //       <div className="pay_success">
  //         <h4>Payment Successfull</h4>
  //         <span>
  //           A confirmation email has been sent to your provided email address
  //         </span>
  //       </div>

  //       <div class="ticket-full-details">
  //         <div className="ref-no">
  //           <p>
  //             Ref-No. : <span>{params.ref}</span>
  //           </p>
  //         </div>
  //         <div className="status">
  //           <p>
  //             Status : <span className="booking-status">Success</span>
  //           </p>
  //         </div>
  //       </div>

  //       <div className="pay_success">
  //         <h4>Booking Status </h4>
  //         {processed ? (
  //           isBooked ? (
  //             <>
  //               <Button type="primary" key="console">
  //                 <Link to={actionUrl}>View Ticket</Link>
  //               </Button>

  //               {busTicket2.twoWay == true ? (
  //                 busTicket2.loading ? (
  //                   <>
  //                     {" "}
  //                     Loading... <Spin />
  //                   </>
  //                 ) : (
  //                   <>
  //                     {" "}
  //                     <Button type="primary" key="console">
  //                       <Link to={busTicket2.actionUrl}>View Ticket 2</Link>
  //                     </Button>
  //                   </>
  //                 )
  //               ) : null}
  //             </>
  //           ) : (
  //             <p style={{ color: "red", fontSize: "18px" }}>
  //               Sorry Ticket Not Booked ! Please Contact Admin
  //             </p>
  //           )
  //         ) : (
  //           <span>
  //             Loading...
  //             <Spin />
  //           </span>
  //         )}
  //       </div>
  //     </div>
  //   </div>
  // );
};
export default PaymentSucces;
