import React, { useState, useEffect } from "react";
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    Modal,
    message,
    Popconfirm,
    Row,
    Table,
    Select,
   
} from "antd";
import {
    PlusCircleFilled,
    EditOutlined,
    DeleteOutlined,
    CloseOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import { exportExcel } from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
const { Content } = Layout;

const BalanceUpdate = () => {
    const { Option } = Select;
    const [form] = Form.useForm();
    const [userForm] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [siteAdminList, setSiteAdminList] = useState([]);
    const [siteAdmin, setSiteAdmin] = useState([]);
   
    const [tableList, setTableList] = useState([]);
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [currentId, setCurrentId] = useState(-1);
    const [showSearchBox, setShowSearchBox] = useState(false);
    // { console.log(siteAdminList, siteAdmin, "listtt") }
    useEffect(() => {
        // getMembershipList();
        // getRolesList();
        getSiteAdminList({
            page: 0,
        });
        getSiteAdmin({
            page: 0,
        });
    }, []);




    const getSiteAdmin = (obj) => {
        // console.log(obj, "adminlist");
        ApiClient.post("user/getuserslist/list", obj)
            .then((res) => {
                if (res.isSuccess) {
                    let data = res.results.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                            
                        };
                    });
                    setSiteAdmin(data);
                  
                }
            })
            .catch((e) => {
                console.log("api error", e);
            });
    };
    const getSiteAdminList = (obj) => {
        ApiClient.get("user/getuserlowbalancelist", obj)
            .then((res) => {
                if (res.isSuccess) {
                    let data = res.data.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                          
                        };
                    });
                    setSiteAdminList(data);
                    setTableList(data);
                }
            })
            .catch((e) => {
                console.log("api error", e);
            });
    };

    const add = (val) => {
        ApiClient.post("user/savelowbalance", val)
            .then((response) => {
                return response;
            })
            .then((response) => {
                if (response.isSuccess) {
                    setModalVisible(false);
                    message.success("Successfully added", 3);
                    getSiteAdminList({
                        page: 0,
                    });
                } else if (!response.isSuccess) {
                    message.error("Already existed", 3);
                }
                form.resetFields();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleModal = () => {
        if (showUpdateBtn) {
            setShowUpdateBtn(false);
            form.resetFields();
        }
        setModalVisible(true);
    };

    const updateSiteAdmin = (val) => {
        ApiClient.put(`user/updatelowbalance/${currentId.id}`, {}, val).then(
            (resp) => {
                if (resp.isSuccess) {
                    getSiteAdminList({
                        page: 0,
                    });
                    setModalVisible(false);
                    message.success(" Updated successfully", 3);
                    setCurrentId(-1);
                    form.resetFields();
                }
            }
        );
    };

    const submitForm = (val) => {
        // console.log(val, "submit");
        let obj = {
            ...val,
        };
        if (showUpdateBtn) updateSiteAdmin(obj);
        else add(obj);
    };
    // { console.log(tableList, "tableList") }
    const tableActions = (currentRecord, data) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <EditOutlined
                        onClick={() => editCurrentItem(currentRecord, data)}
                        style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
                    />
                </div>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <Popconfirm
                        title="Are you sure want to delete?"
                        onConfirm={() => deleteAdmin(data)}
                    >
                        <DeleteOutlined
                            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                        />
                    </Popconfirm>
                </div>
            </div>
        );
    };

    const editCurrentItem = (id, data) => {
        // console.log(id, data, "edit");
        setShowUpdateBtn(true);
        setCurrentId(id);
        form.setFieldsValue({
            ...data,
        });
        setModalVisible(true);
    };
    const deleteAdmin = (Record) => {
        let Id = Record.id;
        ApiClient.delete("user/deletelowbalance/" + Id)
            .then((res) => {
                if (res.isSuccess) {
                    message.success(" Deleted  successfully", 3);
                    getSiteAdminList({
                        page: 0,
                    });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    let lastIndex = 0;
    const uniqueKey = () => {
        return lastIndex++;
    };

    const searchData = () => {
        setShowSearchBox(true);
    };

    const columns = [
        {
            title: "S.No",
            dataIndex: "SNo",
            key: "SNo",
            
        },
        {
            title: "Company Name",
            dataIndex: "clientId",
            key: "name",
            render: (clientId) => {
                // { console.log(clientId, siteAdmin.filter(item => item.id === clientId), "famil") }
                const user = siteAdmin.find(item => item.id === clientId); // Match clientId to siteAdmin data
                // {console.log(user,"ussssfamil")}
                return user?.companyName;
            },
           
        },



        {
            title: "Avl Balance",
            dataIndex: "clientId",
            key: "name",
            render: (clientId) => {
                const user = siteAdmin.find(item => item.id === clientId); // Match clientId to siteAdmin data
                // { console.log(user, "ussssfamil") }
                return Number.parseFloat(user?.balance).toFixed(2);
            },
        },
        {
            title: "Threshold limit",
            dataIndex: "thresholdValue",
            // sorter: (a, b) => a.thresholdValue.localeCompare(b.thresholdValue),

            render: (thresholdValue) => Number.parseFloat(thresholdValue).toFixed(2)
        },

       
        {
            title: "Actions",
            render: (id, data) => tableActions(id, data),
        },
    ];
   

    const searchTableData = (e) => {
        var searchVal = e.target.value;
        if (searchVal.length > 0) {
            const searchResults = tableList.filter(function (item) {
                if (item != undefined) {
                    return JSON.stringify(item)
                        .toLowerCase()
                        .includes(searchVal.toLowerCase());
                }
            });
            setTableList(searchResults);
        } else {
            setTableList(siteAdminList);
        }
    };

    const closeSearch = () => {
        setShowSearchBox(false);
        setTableList(siteAdminList);
    };

    const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

    const handleExcel = () => {
        let excelData = tableList;
        // console.log(excelData, "excelData");

        excelData = excelData.map((list, i) => {
            const user = siteAdmin.find(item => item.id === list.clientId);

            return {
                SNo: i + 1,
                CompanyName: user.username,
                AvlBalance: user.balance,
                ThresholdLimit: list.thresholdValue,
            };
        });

        exportExcel(excelData, "Site Admins");
    };

    return (
        <div className="outer-background-all">
            <div className="manage-markup-section">
                <Content className="container-fluid">
                    <div className="card-bt-gap">
                      
                        {tableList.length > 0 ? (
                            <Card bordered={false} style={{ marginTop: "1%", borderRadius: "25px" }}>
                                <div className="card-add-heading">
                                    <div className="rows-count">
                                        <h5>View Low Balance Alert</h5>
                                        <p>{tableList.length} rows found !</p>
                                    </div>
                                    <div className="action-images">
                                        {/* {showSearchBox && (
                                            <Input
                                                autocomplete="newpassword"
                                                placeholder="Search"
                                                onChange={(e) => searchTableData(e)}
                                                suffix={closeSearchInput}
                                                style={{ padding: "0px 12px" }}
                                            />
                                        )} */}
                                        {/* &nbsp;&nbsp;
                                        <img
                                            src={search}
                                            alt="search"
                                            onClick={() => searchData()}
                                        /> */}
                                        <img
                                            src={excel}
                                            alt="excel"
                                            onClick={() => handleExcel()}
                                        />
                                        <img
                                            src={exchange}
                                            alt="exchange"
                                            onClick={() => {
                                                setTableList([]);
                                                getSiteAdminList({
                                                    page: 0,
                                                });
                                            }}
                                        />
                                        <p className="add-deposit-icon" onClick={handleModal}>
                                            <PlusCircleFilled />
                                        </p>
                                    </div>
                                </div>

                                <Table
                                    scroll={{ x: true }}
                                    bordered
                                    rowKey={uniqueKey}
                                    dataSource={tableList}
                                    columns={columns}
                                    pagination={{
                                        defaultPageSize: 25,
                                        showSizeChanger: true,
                                        pageSizeOptions: ["25", "50", "100", "125"],
                                    }}
                                />
                            </Card>
                        ) : <Card bordered={false} style={{ marginTop: "1%", borderRadius: "25px" }}>
                        <div className="card-add-heading">
                            <div className="rows-count">
                                <h5>View Low Balance Alert</h5>
                                <p>{tableList.length} rows found !</p>
                            </div>
                            <div className="action-images">
                                {/* {showSearchBox && (
                                    <Input
                                        autocomplete="newpassword"
                                        placeholder="Search"
                                        onChange={(e) => searchTableData(e)}
                                        suffix={closeSearchInput}
                                        style={{ padding: "0px 12px" }}
                                    />
                                )} */}
                                {/* &nbsp;&nbsp;
                                <img
                                    src={search}
                                    alt="search"
                                    onClick={() => searchData()}
                                /> */}
                                {/* <img
                                    src={excel}
                                    alt="excel"
                                    onClick={() => handleExcel()}
                                /> */}
                                <img
                                    src={exchange}
                                    alt="exchange"
                                    onClick={() => {
                                        setTableList([]);
                                        getSiteAdminList({
                                            page: 0,
                                        });
                                    }}
                                />
                                <p className="add-deposit-icon" onClick={handleModal}>
                                    <PlusCircleFilled /> <span style={{fontSize:"12px", paddingLeft:"5px"}}> Add Alert List</span>
                                </p>
                            </div>
                        </div>

                        {/* <Table
                            scroll={{ x: true }}
                            bordered
                            rowKey={uniqueKey}
                            dataSource={tableList}
                            columns={columns}
                            pagination={{
                                defaultPageSize: 25,
                                showSizeChanger: true,
                                pageSizeOptions: ["25", "50", "100", "125"],
                            }}
                        /> */}
                        
                    </Card>}
                    </div>
                </Content>
            </div>
            <Modal
                title={[
                    <div>
                        <h6 style={{ marginBottom: "0px" }}>
                            <strong>
                                {showUpdateBtn ? "Update Low Balance Alert" : "Add Low Balance Alert"}
                            </strong>
                        </h6>
                    </div>,
                ]}
                className="promo-modal-header"
                visible={modalVisible}
                onOk={(e) => setModalVisible(false)}
                onCancel={(e) => setModalVisible(false)}
                footer={[
                    <Button key="add" type="primary" onClick={form.submit}>
                        {showUpdateBtn ? "Update" : "Add"}
                    </Button>,
                ]}
                width={1000}
            >
                <Form layout="vertical" form={form} onFinish={submitForm}>
                    <Row gutter={12}>
                        {showUpdateBtn ? <Col md={5} xs={24} >
                            <Form.Item
                                label="User"
                                name="clientId"
                            // style={{ border: " 1px solid #d9d9d9" }}
                            >
                                <div style={{
                                    border: "1px solid #d9d9d9",
                                    borderRadius: "2px",
                                    padding: "4px 11px",
                                    height: "32px",
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "#f5f5f5"
                                }}>
                                    {siteAdmin.find(item => item.id === form.getFieldValue("clientId"))?.username || "Unknown User"}
                                </div>

                            </Form.Item>
                        </Col>
                            :
                            <Col md={5} xs={24}>
                                <AutoCompleteSelect
                                    formItemProps={{
                                        label: "Company Name",
                                        name: "clientId",
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please enter User Name",
                                            },
                                        ],
                                    }}
                                    selectProps={{
                                        placeholder: "Please Enter User Name",
                                    }}
                                    api={"user/userautosuggestion/"}
                                    keytext="name"
                                    keyvalue="id"
                                />
                            </Col>}

                        <Col span={12}>
                            <Form.Item
                                label="Threshold Limit"
                                name="thresholdValue"
                                rules={[{ required: true, message: "Please enter maximum amount to be maintained" }]}
                            >
                                <Input placeholder="Please enter amount" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </Modal>
        </div >
    );
};
export default BalanceUpdate;
