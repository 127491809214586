import React, { useEffect, useState } from "react";
import { Card, message } from "antd";
import { Link } from "react-router-dom";
import "./paymentFailure.scss";
import ApiClient from "../../helpers/ApiClient";
import queryString from "query-string";
function PaymentFailure() {
  // let orderId = JSON.parse(localStorage.getItem("OrderId"))
  const [isLoading, setIsLoading] = useState(true)
  const [paymentResp, setPaymentResp] = useState()
  const getPaymentByRefNo = async (orderId) => {
    await ApiClient.get("admin/pgOrdersByRefNo/" + orderId,)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.status === 200) {
          setPaymentResp(resp.data)
          setIsLoading(false)
        } else {
          message.error("Server Error: " + resp.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });


  }

  useEffect(() => {
    //let orderId = JSON.parse(localStorage.getItem("OrderId"))
    const params = queryString.parse(document.location.search);
    getPaymentByRefNo(params.ref)
  })
  return (
    <div className="pgFailureWrapper">
      {
        !isLoading ? (
          <Card>
            <h2 className="title">Your payment failed !!</h2>
            <p className="subtitle">
              Please call or write to our customer care team for more details{" "}
              <span className="mail">contact@trusgo.com</span>. Please note your
              reference ID: <span className="tid">{paymentResp.OrderId}</span> and Payment ID:{" "}
              <span className="tid">{paymentResp.HashKey}</span>
            </p>
          </Card>
        ) : <p>Loading....</p>
      }

    </div>
  );
}

export default PaymentFailure;
