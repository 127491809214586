import {
    Button,
    Card,
    Col,
    Collapse,
    DatePicker,
    Form,
    Layout,
    Row,
    Select,
    Spin,
    Table,
    Input,
    message
} from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import "./MissingRoutes.scss"
import { exportExcel } from "../ExportExcel";
import excel from "../assets/vector-icons/excel.png";

import APIClient from "../../helpers/BusApiClient";

import { Link, useHistory } from "react-router-dom";
import SessionTimeOutModal from "../../common/SessionTimeOutModal/SessionTimeOutModal";
const { Option } = Select;
const { Content } = Layout;

export const getTicketDetails = (tktNo, record) => {

    let url = "/admin/bus_ticket";
    return <Link to={url + "?refNo=" + tktNo}>{tktNo}</Link>;
};

const MissingRoutes = () => {
    let history = useHistory();
    const [reportRole, setReportRole] = useState(0);
    const [form] = Form.useForm();
    const [tableList, setTableList] = useState([]);
    const [reportsList, setReportsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const { user } = useAuthContext();
    const [columns, setColumns] = useState([])
    const [isDateDisplay, setIsDateDisplay] = useState(false);

    const presentDate = moment();
    // const [arrivalDate, setArrivalDate] = useState(moment());
    // const [weekendDate, setWeekendDate] = useState(moment())
    const [arrivalDate, setArrivalDate] = useState(null);
    const [weekendDate, setWeekendDate] = useState(null);
    const [isSessionTimeOutModel, setIsSessionTimeOutModel] = useState(false)

    useEffect(() => {
        user.role.toUpperCase() === "ADMIN" || user.role.toUpperCase() === "BUSINESSANALYST"  ? setColumns(MissingRoutesColumns) : setColumns(SiteAdminMissingRoutesColumns)
        let obj = {
            searchType: "Today",
            fromDate: null,
            toDate: null,
            api: null,
            // userId: user.role.toUpperCase() === "ADMIN" ? null : user.id,
            userId: user.roleId !== 2 ? null : user.id,
            showType: 'Consolidate'
        };
        getMissingRoutes(obj);
    }, [])

    const MissingRoutesColumns = [
        // {
        //     title: "UserName",
        //     dataIndex: "userName",
        // },
        {
            title: "Source",
            dataIndex: "source",
        },
        {
            title: "Destination",
            dataIndex: "destination",
        },
        {
            title: "Journey Date",
            dataIndex: "journeyDate",
            sorter: (a, b) => a.journeyDate?.localeCompare(b.journeyDate),
            render: (journeyDate) => moment(journeyDate).format("DD-MM-YYYY HH:MM"),
        },

        {
            title: "API",
            dataIndex: "api",
        },
        {
            title: "Count",
            dataIndex: "count",
        },

        {
            title: "Log Date",
            dataIndex: "logDate",
            render: (logDate) => moment(logDate).format("DD-MM-YYYY HH:MM"),
        }
    ];

    const SiteAdminMissingRoutesColumns = [

        {
            title: "Source",
            dataIndex: "source",
        },
        {
            title: "Destination",
            dataIndex: "destination",
        },
        {
            title: "Journey Date",
            dataIndex: "journeyDate",
            sorter: (a, b) => a.journeyDate?.localeCompare(b.journeyDate),
            render: (journeyDate) => moment(journeyDate).format("DD-MM-YYYY HH:MM"),
        },

        // {
        //     title: "API",
        //     dataIndex: "api",
        // },
        {
            title: "Count",
            dataIndex: "count",
        },

        {
            title: "Log Date",
            dataIndex: "logDate",
            render: (logDate) => moment(logDate).format("DD-MM-YYYY HH:MM"),
        }
    ];


    const onFormSubmit = (val) => {
        let obj = {
            searchType: val.searchType ?? "Today",
            fromDate: val.fromDate ?? null,
            toDate: val.toDate ?? null,
            api: (val.api == 'NONE' || val.api == undefined) ? null : val.api,
            // userId: user.role.toUpperCase() === "ADMIN" ? val.userId ?? null : user.id,
            userId: user.roleId !== 2 ? null : user.id,
            showType: val.showType ?? 'Consolidate'
        };
        getMissingRoutes(obj);
    };
    const getMissingRoutes = (obj) => {
        setLoading(true);
        setShowTable(true);
        setReportsList([]);
        setTableList([]);
        APIClient.post(`bus/missingRoutes`, obj)
            .then((res) => {
                /** res will be return undefined if the API is UnAuthorize   */
                if (res == "TypeError: Failed to fetch") {
                    setIsSessionTimeOutModel(true)
                }
                if (res.length) {
                    let data = res.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                        };
                    });
                    setReportsList(data);
                    setTableList(data);
                    // setTable(true);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleTimeAndDate = (value) => {

        if (value === "CustomDates") {
            setIsDateDisplay(true);
        } else {
            setIsDateDisplay(false);
        }
    };

    const apisOptions = [
        { name: "NONE", value: "NONE" },
        { name: "IRCT", value: "IRCT" },
        { name: "REDB", value: "REDB" },
        { name: "BTLA", value: "BTLA" },
        { name: "EZIF", value: "EZIF" },
        { name: "TRYR", value: "TRYR" },
        { name: "ETST", value: "ETST" },
        { name: "ITSG", value: "ITSG" },
        { name: "VRLT", value: "VRLT" },
        { name: "ZGBS", value: "ZGBS" },
        { name: "YLBS", value: "YLBS" },
        { name: "ABHI", value: "ABHI" },
    ]

    const handleExcel = () => {
        let excelData = tableList;
        excelData = excelData.map((item, i) => ({
            SNo: i + 1,
            Source: item.source,
            Destination: item.destination,
            JourneyDate: item.journeyDate,
            API: item.api,
            Count: item.count,
            LogDate: item.logDate,
        }));
        exportExcel(excelData, "Missing Routes");
    };


    // const disaleFromDate = (currentDate) => {
    //     return currentDate < moment(presentDate).startOf("day");
    // };

    // const disaleToDate = (currentDate) => {
    //     //return currentDate < moment(arrivalDate).startOf("day");

    //     return (currentDate < moment(arrivalDate).startOf("day") || (currentDate > moment(weekendDate).startOf("day")))
    // };

    // const onChangeFromDate = (momentdate, _) => {

    //     setWeekendDate(moment(momentdate, "DD-MM-YYYY").add(7, 'days'));
    //     setArrivalDate(momentdate);
    // }

    //custome dates disable past and future dates
    const onChangeFromDate = (current, dateString) => {
        // Can not select days before today and toda
        setArrivalDate(dateString);
    };
    const onChangeToDate = (current, dateString) => {
        // Can not select days before today and today
        setWeekendDate(dateString);
    };
    const disabledFromDate = (current) => {
        if (weekendDate != null) {
            return current.valueOf() > (new Date(weekendDate));
        }
        // return current && current > moment().endOf('day');

        return current.valueOf() > Date.now();
    }
    const disabledToDate = (current) => {
        // Can not select days before today and today
        return current.valueOf() > Date.now() || current.valueOf() < (new Date(arrivalDate));
    };

    return (
        <div className="outer-background-all">
            <div className="manage-markup-section">
                <Content className="container-fluid">
                    <div className="card-bt-gap">
                        <Card bordered={false} style={{ borderRadius: "25px" }}>
                            <Row className="card-add-heading">
                                <Col span={24}>
                                    <div className="card-bt-gap">
                                        <h5>Missing Routes</h5>

                                        <Form
                                            form={form}
                                            layout={"vertical"}
                                            onFinish={onFormSubmit}
                                        >
                                            <Row gutter={12} align="middle">
                                                {/* {user.role.toUpperCase() === "ADMIN" &&
                                                    (<Col md={5} xs={24}>
                                                        <Form.Item
                                                            label="Reports of"
                                                            name="reportsOf"
                                                        // rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Select
                                                                placeholder="Select"
                                                                onSelect={(val) => setReportRole(val)}
                                                            >
                                                                <Option value={0}>Self</Option>
                                                                <Option value={5}>Specific Company</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>)} */}

                                                {/* {reportRole === 5 && (
                                                    <Col md={5} sm={12} xs={24}>
                                                        <AutoCompleteSelect
                                                            formItemProps={{
                                                                label: "Company Name",
                                                                name: "userId",
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Please enter User Name",
                                                                    },
                                                                ],
                                                            }}
                                                            selectProps={{
                                                                placeholder: "Please Enter User Name",
                                                            }}
                                                            api={"user/userautosuggestion/"}
                                                            keytext="name"
                                                            keyvalue="id"
                                                        />
                                                    </Col>
                                                )} */}
                                                {user.roleId !== 2 && (
                                                    <Col md={5} sm={12} xs={24}>
                                                        <AutoCompleteSelect
                                                            formItemProps={{
                                                                label: "Company Name",
                                                                name: "userId",
                                                                // rules: [
                                                                //     {
                                                                //         required: true,
                                                                //         message: "Please enter User Name",
                                                                //     },
                                                                // ],
                                                            }}
                                                            selectProps={{
                                                                placeholder: "Please Enter User Name",
                                                            }}
                                                            api={"user/userautosuggestion/"}
                                                            keytext="name"
                                                            keyvalue="id"
                                                        />
                                                    </Col>
                                                )}
                                                <Col md={4} sm={12} xs={24}>
                                                    <Form.Item
                                                        label="Search Type"
                                                        name="searchType"
                                                    // rules={[
                                                    //   { required: true, message: "Please enter To Date" },
                                                    // ]}
                                                    >
                                                        <Select placeholder="Please select Status" onChange={handleTimeAndDate} defaultValue="Today">
                                                            <Option value="Lasthour">Lasthour </Option>
                                                            <Option value="Today">Today </Option>
                                                            <Option value="Yesterday">Yesterday </Option>
                                                            <Option value="Lastweek">Lastweek </Option>
                                                            <Option value="CustomDates">CustomDates  </Option>

                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                {isDateDisplay ? (<>
                                                    <Col md={4} sm={12} xs={24}>
                                                        <Form.Item
                                                            label="From Date"
                                                            name="fromDate"
                                                        >
                                                            <DatePicker
                                                                format="YYYY-MM-DD"
                                                                placeholder="Please Enter From Date"

                                                                //  disabledDate={disaleFromDate}
                                                                // onChange={(date, dateString) =>
                                                                //     onChangeFromDate(date, dateString)
                                                                // }
                                                                onChange={(date, dateString) =>
                                                                    onChangeFromDate(date, dateString)
                                                                }
                                                                disabledDate={disabledFromDate}
                                                                style={{ width: "100%" }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={4} sm={12} xs={24}>
                                                        <Form.Item
                                                            label="To Date"
                                                            name="toDate"

                                                        >
                                                            <DatePicker
                                                                format="YYYY-MM-DD"
                                                                // disabledDate={disaleToDate}
                                                                disabledDate={disabledToDate}
                                                                onChange={(date, dateString) =>
                                                                    onChangeToDate(date, dateString)

                                                                }
                                                                placeholder="Please Enter To Date"
                                                                style={{ width: "100%" }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </>) : null}

                                                { user.roleId !== 2 && (
                                                    <Col md={4} sm={12} xs={24}>
                                                        <Form.Item name="api" label="Supplier">
                                                            <Select placeholder="Please select Service Name">
                                                                {apisOptions.map(item => (<Option value={item.value}>{item.name}</Option>))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                )}

                                                <Col md={4} sm={12} xs={24}>
                                                    <Form.Item
                                                        label="Result Type"
                                                        name="showType"
                                                    >
                                                        <Select
                                                            placeholder="Select"
                                                            onSelect={(val) => setReportRole(val)}
                                                        >
                                                            <Option value="Consolidate">Consolidate</Option>
                                                            <Option value="Distinct">Distinct </Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>


                                                <Col span={2}>
                                                    <Button
                                                        key="add"
                                                        className="site-admin-btn-11"
                                                        type="primary"
                                                        onClick={form.submit}
                                                    >
                                                        Search
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Col>
                                {showTable ? (
                                    <Col span={24}>
                                        {tableList.length > 0 ? (
                                            <>
                                                <div className="card-add-heading">
                                                    <div className="rows-count">
                                                        <div>
                                                            <h5>{tableList.length} Missing Routes </h5>
                                                        </div>
                                                    </div>
                                                    <div className="action-images">
                                                        {/* {showSearchBox && (
                                    <Input
                                      placeholder="Search"
                                      onChange={(e) => searchTableData(e)}
                                      suffix={closeSearchInput}
                                      style={{ padding: "0px 12px" }}
                                    />
                                  )}
                                  &nbsp;&nbsp;
                                  <img
                                    src={search}
                                    alt="search"
                                    onClick={(e) => searchData()}
                                  /> */}
                                                        <img
                                                            src={excel}
                                                            alt="excel"
                                                            onClick={() => handleExcel()}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="mt-2">
                                                    <Table
                                                        scroll={{ x: true }}
                                                        bordered
                                                        dataSource={tableList}
                                                        // columns={busColumns}
                                                        columns={columns}
                                                        pagination={{
                                                            defaultPageSize: 10,
                                                            showSizeChanger: true,
                                                            pageSizeOptions: ["10", "25", "50", "100", "125"],
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        ) : loading ? (
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{ width: "100%" }}
                                            />
                                        ) : (
                                            <b>No Results Found!</b>
                                        )}
                                    </Col>
                                ) : (
                                    ""
                                )}
                            </Row>
                        </Card>
                    </div>
                </Content>
            </div>
            {
                isSessionTimeOutModel ? (<SessionTimeOutModal isModelVisible={isSessionTimeOutModel} />) : null
            }
        </div>
    );
};

export default MissingRoutes;
