import React from "react";

import { Button, Col, Row } from "antd";
import { Link } from "react-router-dom";
import i2space from "../../assets/images/I2space.png"
import {
  BrowserRouter as Router,
  useLocation,
  withRouter,
  Route,
  Switch,
} from "react-router-dom";
// import { useSytContext } from "../providers/SytProvider";
// import { AppConstants } from "../../helpers/constants";
import {
  FacebookOutlined,
  YoutubeOutlined,
  TwitterOutlined,
  InstagramOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import "../footer/Footer.scss";
import { useAuthContext } from "../../common/providers/AuthProvider";

const Footer = () => {
  //   const { siteDetails } = useSytContext();
  //   const {
  //     isLogin: { flag, agent,isGuest },
  //     user,
  //     setUser,
  //     resetIsLogin,
  //   } = useAuthContext();
  //   let services = user?.Services ? user?.Services : [];
  const { pathname } = useLocation();

  return (
    <div id="footer">


      <div className="footer_bottom_section">
        <div className="footer_container">
          <div className="footer_links">
            <div className="footer_column">
              <p>Company</p>
              <ul>
                {/* {console.log(pathname, "path")} */}
                {pathname != "/" &&
                  <li><Link to="/">Home</Link></li>}
                {pathname != "/AboutUs" &&
                  <li><Link to="/AboutUs">About Us</Link></li>}

                {/* {pathname != "/disclaimer" &&
                  <li><Link to="/disclaimer">Disclaimer</Link></li>} */}
                {pathname != "/termsofconditions" &&
                  <li><Link to="/termsofconditions">Terms & Conditions</Link></li>}
              </ul>
            </div>

            <div className="footer_column">
              <p>Support</p>
              <ul>
                <li><Link to="/contact_us_form">Contact Us</Link></li>
                <li><Link to="/Refundpolicy">Refund Policy</Link></li>
                {pathname != "/privacypolicy" &&
                  <li><Link to="/privacypolicy">Privacy Policy</Link></li>}
                {/* <li><Link to="/termsofconditions">Terms & Conditions</Link></li> */}
              </ul>
            </div>
           

            {/* <div className="footer_column">
              <p>Contact Us</p>
              <ul>
                <li><i className="fa fa-phone"></i> +91 999999999</li>
                <li><i className="fa fa-envelope-o"></i> support@etravos.com</li>
              </ul>
            </div> */}
          </div>
          <div className="footer_t_section">
            <div className="footer_copyright">
              <span>A Product of <img className="i2s" src={i2space} alt="" width={30} /> space</span>
              <div style={{ display: "flex" }}>Copyright &copy; e<span style={{ color: "#f0802d", fontWeight: "600" }}>T </span>ravos. All rights reserved.</div>
            </div>

            <div className="footer_social_info">
             
              <a href="https://www.facebook.com/etravos.etravos?mibextid=ZbWKwL" target="blank"className="social_icon"><FacebookOutlined /></a>
              <a href="https://www.youtube.com/@etravos6636" className="social_icon" target="blank"><YoutubeOutlined /></a>
              <a target="blank" href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFqoS2iq54R1AAAAZM039ao52Yrjj1Lbt0xOT72o0wv6dnF7syymSqJdCy3G8iyiHeQ3W5Ek83dDW1Ccf0lXlHys_nDCA6CC9N5Y-sE96epugIpahvo4khNMeA00TiyWYS8v6w=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fetravos-travel-technology-services%2F" className="social_icon"><LinkedinOutlined /></a>
              <a href="https://www.instagram.com/etravos?igsh=MTBpeXN5cHlseHZkZA==" target="blank"className="social_icon"><InstagramOutlined /></a>
            </div>
          </div>
        </div>
      </div>
      <div className="comp-add">
        <div className="contfd">
          <p style={{ color: "#ffff", fontSize:"18px" }}>Corporate Head Office</p>
          <p style={{ color: "grey" , fontSize:"14px", fontWeight: "600"}}>Hyderabad</p>
          <p>Address: Plot No.1, H.No.23-304/1, <br /> Kriti Mansion, 1st floor, HMT Sathavahana Nagar, <br />Kukatpally Housing Board, Hyderabad-500085, Telangana
            <br />
            Land Mark: Beside Kalamandir
            <br />
            {/* Phone: (+91) 040-64626301 /02 /03 */}
            Email: <span style={{ color: "blue" }}>bci@i2space.com, info@i2space.com</span></p>
        </div>
        <hr />
        <div className="contfd">
          <p style={{ color: "grey", fontSize:"14px", fontWeight: "600" }}>Bussiness hours</p>
          <p>All our business and tech team are working remotely, any information needed, please send a mail to <span style={{ color: "blue" }}>info@i2space.com</span> or submit the enquiry form in Contact us page</p>
        </div>
      </div>
    </div>

  );
};

export default Footer;
