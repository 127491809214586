import React, { useState, useEffect } from "react";
import "./FlightsList.scss";

import SeperatedView from "./SeperatedView/SeperatedView";
import CombinedView from "./CombinedView/CombinedView";
import { useHistory } from "react-router-dom";

const FlightsList = (props) => {
  const {
    setSelectedTripType,
    flightContext,
    flightAirSearchResp,
    updateFlightAirSearchRespObj,
    getMinFlightPrice,
  } = props;

  // const [airTravelType, setAirTravelType] = useState("roundTrip");
  const [airTravelType, setAirTravelType] = useState("oneWay");
  const [resultsType, setResultsType] = useState(null);

  const [journeyType, setJourneyType] = useState("onward");
  const [airSearchRespForSeparate, setAirSearchRespForSeparate] = useState(
    flightAirSearchResp.flightDetails
  );

  let selectedFlightData = flightContext.updateSelectedFlight
    ? flightContext.updateSelectedFlight
    : null;

  const changeFlightResultsView = (flightObj) => {
    if (flightObj.length === 0) {
      selectedFlightData([]);
      return;
    }
    selectedFlightData(flightObj);
    localStorage.setItem("traceId", flightAirSearchResp.traceId);
    localStorage.setItem("searchId", flightAirSearchResp.searchId);
    // localStorage.setItem("journeyType", "return");

    if (flightAirSearchResp.resultsType === "Separate") {
      // setJourneyType("return");
      if (journeyType === "onward") {
        changeJourneyType();
        setReturnFlightResults(flightAirSearchResp.ibFlightDetails);
      } else {
        history.push("/flight-checkout");
      }
    } else {
      history.push("/flight-checkout");
    }
  };

  useEffect(() => {
    setResultsType(flightAirSearchResp.resultsType);
  }, []);

  useEffect(() => {
    if (journeyType == "onward") {
      setAirSearchRespForSeparate(flightAirSearchResp.flightDetails);
      let visibleData = flightAirSearchResp.flightDetails.filter(
        (item) => item.isVisible
      );
      getMinFlightPrice(visibleData);
    } else {
      setAirSearchRespForSeparate(flightAirSearchResp.ibFlightDetails);
      setSelectedTripType(2);
      let visibleData = flightAirSearchResp.ibFlightDetails.filter(
        (item) => item.isVisible
      );
      getMinFlightPrice(visibleData);
    }
  }, [flightAirSearchResp]);

  let history = useHistory();
  const setOnwardFlights = () => {
    setAirSearchRespForSeparate(flightAirSearchResp.flightDetails);
    setSelectedTripType(1);
    setJourneyType("onward");
  };

  const changeJourneyType = () => {
    setSelectedTripType(2);
    setJourneyType("return");
  };
  const setReturnFlightResults = (flights) => {
    setJourneyType("return");
    setAirSearchRespForSeparate(flights);
  };

  return (
    <div className="result-bar">
      {airTravelType === "roundTrip" ? (
        <div>Selected journeyType : {journeyType}</div>
      ) : null}

      {resultsType != null ? (
        resultsType === "Combined" ? (
          <div className="resultsScrollWrapper">
            <CombinedView
              flightList={flightAirSearchResp.flightDetails}
              changeFlightResultsView={changeFlightResultsView}
              selectedFlight={flightContext.state.selectedFlight}
              flightSearchData={flightContext.state.flightSearchObj}
              setFlightFareRules={flightContext.updateFlightFares}
            />
          </div>
        ) : (
          <SeperatedView
            flightSearchData={flightContext.state.flightSearchObj}
            selectedFlight={flightContext.state.selectedFlight}
            flightDetails={airSearchRespForSeparate}
            changeFlightResultsView={changeFlightResultsView}
            setOnwardFlights={setOnwardFlights}
            journeyType={journeyType}
          />
        )
      ) : null}
    </div>
  );
};

export default FlightsList;
