import React, { useEffect, useState } from "react";
import * as ANTD from "antd";
import "./TravelCalendar.scss";
import ApiClient from "../../helpers/ApiClient";
import { Link } from "react-router-dom";
import moment from "moment";
import { createReportFromApiReponse } from "./CalenderHelpers";
const TravelCalendar = () => {
  const { Content } = ANTD.Layout;
  const { Option } = ANTD.Select;
  const [serviceType, setServiceType] = useState(0);
  const [bookings, setBookings] = useState({
    flights: [],
    buses: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const getBookingListData = () => {
    let url = "admin/myBookings/report?travelType=0";
    ApiClient.get(url)
      .then((res) => {
        setIsLoading(false);
        if (res.statusCode == 200) {
          setBookings(createReportFromApiReponse(res.data));
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getBookingListData();
  }, []);

  /* =========Api Calls===*/

  const cellContent = ({ flights, buses }, date, type) => {
    let formatedDate = moment(date).format("YYYY-MM-DD");
    let monthStartDate = moment(date).startOf("month").format("YYYY-MM-DD");
    let monthEndDate = moment(date).endOf("month").format("YYYY-MM-DD");

    return (
      <div className="travelDateContent">
        {flights > 0 && (serviceType == 0 || serviceType == 1) ? (
          <Link
            to={`/admin/reports?travelType=1&${
              type === 1
                ? `dateType=1&fromDate=${formatedDate}&toDate=${formatedDate}`
                : `dateType=1&fromDate=${monthStartDate}&toDate=${monthEndDate}`
            }&edit=edit`}
          >
            <div>
              <span>
                <i className="fa fa-plane"></i> {flights}
              </span>
            </div>
          </Link>
        ) : null}

        {buses > 0 && (serviceType == 0 || serviceType == 3) ? (
          <Link
            to={`/admin/reports?travelType=3&${
              type === 1
                ? `dateType=1&fromDate=${formatedDate}&toDate=${formatedDate}`
                : `dateType=1&fromDate=${monthStartDate}&toDate=${monthEndDate}`
            }&edit=edit`}
          >
            <div>
              <span>
                <i className="fa fa-bus" /> {buses}
              </span>
            </div>
          </Link>
        ) : null}
      </div>
    );
  };

  const dateCellRender = (dated) => {
    let formatedDate = moment(dated).format("DD-MM-YYYY");
    let data = {
      flights: bookings.flights.filter(
        (booking) => booking.formatedDate == formatedDate
      ).length,
      buses: bookings.buses.filter(
        (booking) => booking.formatedDate == formatedDate
      ).length,
    };
    return (
      //   <ul className="events">
      //     {listData.map((item) => (
      //       <li key={item.content}>
      //         <ANTD.Badge status={item.type} text={item.content} />
      //       </li>
      //     ))}
      //   </ul>
      cellContent(data, dated, 1)
    );
  };

  const monthCellRender = (dated) => {
    let month = moment(dated).month();
    let year = moment(dated).year();
    let data = {
      flights: bookings.flights.filter(
        (booking) => booking.month == month && booking.year == year
      ).length,
      buses: bookings.buses.filter(
        (booking) => booking.month == month && booking.year == year
      ).length,
    };
    return cellContent(data, dated, 2);
  };
  return (
    <ANTD.Layout>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <div className="travel-calendar">
            <ANTD.Card bordered={false}>
              <h5>Travel Calendar</h5>
              <ANTD.Form>
                <ANTD.Row>
                  <ANTD.Col span={8}>
                    <ANTD.Form.Item label="Service Type">
                      <ANTD.Select
                        placeholder="Select Service Type"
                        onChange={(v) => setServiceType(v)}
                      >
                        <Option value={0}>All</Option>
                        <Option value={1}>Flights</Option>
                        <Option value={3}>Buses</Option>
                      </ANTD.Select>
                    </ANTD.Form.Item>
                  </ANTD.Col>
                </ANTD.Row>
              </ANTD.Form>
              {isLoading ? (
                <ANTD.Spin />
              ) : (
                <ANTD.Calendar
                  dateCellRender={dateCellRender}
                  monthCellRender={monthCellRender}
                />
              )}
            </ANTD.Card>
          </div>
        </Content>
      </div>
    </ANTD.Layout>
  );
};

export default TravelCalendar;
