import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Popconfirm,
  Table,
  Radio,
  Row,
  Select,
  Upload,
} from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  FacebookOutlined,
  GoogleOutlined,
  InstagramOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  FormOutlined,
  DeleteOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import PersonalInfo from "./PersonalInfo";
import Profile from "./Profile";
import APIClient from "../../helpers/ApiClient";

const { Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;

const BusinessAddress = () => {
  return (
    <Row gutter={16}>
      <Col className="gutter-class" md={6} xs={24}>
        <Form.Item
          label="Company Name"
          name="CompanyName"
          rules={[
            {
              required: true,
              message: "Required Field",
            },
          ]}
        >
          <Input
 autocomplete="newpassword"  placeholder="Enter Company Name" />
        </Form.Item>
      </Col>
      <Col className="gutter-class" md={6} xs={24}>
        <Form.Item
          label="Email"
          name="Email"
          rules={[
            {
              required: true,
              message: "Required Field",
            },
          ]}
        >
          <Input
 autocomplete="newpassword"  placeholder="Enter Email" />
        </Form.Item>
      </Col>
      <Col className="gutter-class" md={6} xs={24}>
        <Form.Item label="Mobile Number" name="mno">
          <Row gutter={5}>
            <Col className="gutter-class" md={8}>
              <Form.Item
                name="CountryCode"
                rules={[
                  {
                    required: true,
                    message: " ",
                  },
                ]}
              >
                <Select
 autocomplete="newpassword"  value="91">
                  <Option value="91">+91</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-class" md={16}>
              <Form.Item
                name="MobileNumber"
                rules={[
                  {
                    required: true,
                    message: "Required Field",
                  },
                ]}
              >
                <Input
 autocomplete="newpassword"  placeholder="Enter Mobile number" name="MobileNumber" />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Col>
      <Col className="gutter-class" md={6} xs={24}>
        <Form.Item label="Alternate Number" name="ano">
          <Row gutter={5}>
            <Col className="gutter-class" md={8}>
              <Form.Item
                name="AlternateCountryCode"
                rules={[
                  {
                    required: true,
                    message: " ",
                  },
                ]}
              >
                <Select
 autocomplete="newpassword"  value="91">
                  <Option value="91">+91</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-class" md={16}>
              <Form.Item
                name="AlternateMobileNumber"
                rules={[
                  {
                    required: true,
                    message: "Required Field",
                  },
                ]}
              >
                <Input
 autocomplete="newpassword"  placeholder="Enter Alternate number" />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Col>
    </Row>
  );
};

const Settings = () => {
  const initialState = {
    Email: "",
    Mobile: "",
    Name: "",
    Gender: 0,
    DOB: "",
    ProfilePic: null,
  };
  const initialSettings = {
    UserID: 1,
    userCurrency: {},
    userDetails: {},
    role: {},
    userBusinessDetails: {},
    userEmailConfig: {},
    userGstVat: {},
    userSocialDetails: {},
    BusinessAddress: [],
  };
  const [userSettings, setUserSettings] = useState(initialSettings);
  const [isBusiAddrEdit, setIsBusiAddrEdit] = useState(false);

  const [PaymentGatewayDetails, setPaymentGatewayDetails] = useState([]);
  const [PGEdit, setPGEdit] = useState(false);
  const [CFEdit, setCFEdit] = useState(false);
  const [userData, setUserData] = useState(initialState);
  const [convenienceFee, setconvenienceFee] = useState([]);

  const [businessAddressID, setBusinessAddressID] = useState(-1);

  const [PGEditId, setPGEditId] = useState(-1);

  const [BusinessDetailsForm] = Form.useForm();
  const [GstVatForm] = Form.useForm();
  const [EmailForm] = Form.useForm();

  const [CurrencyForm] = Form.useForm();
  const [PersonalInfoForm] = Form.useForm();
  const [PaymentGatewayForm] = Form.useForm();
  const [ConvenienceFeeForm] = Form.useForm();
  const [ProfilePicForm] = Form.useForm();
  const [BusinessForm] = Form.useForm();
  const [socialForm] = Form.useForm();

  const GetConvenienceFeeData = () => {
    APIClient.get("admin/convenienceFee")
      .then((response) => {
        if (response.status == 200) {
          let filterData = response.data.filter(
            (item) => item.ServiceType === 1 || item.ServiceType === 2
          );
          setconvenienceFee(filterData);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const UpdateConvenienceFeeData = (values) => {
    if (values.Value < 1) {
      return;
    }
    let data = {
      ...values,

      CreatedBy: "1",
      DeviceOS: 1,
      ApplicationType: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
    };

    APIClient.put("admin/convenienceFee/" + convId, {}, data)
      .then((response) => {
        if (response.status == 200) {
          setCFEdit(false);
          setConvId(-1);

          message.success("Convenience Fee Added Successsfully");
          ConvenienceFeeForm.resetFields();
          GetConvenienceFeeData();
          return;
        }
        message.error("Something Went Wrong", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const AddConvenienceFee = (values) => {
    if (values.Value < 1) {
      return;
    }
    let data = {
      ...values,

      CreatedBy: "1",
      DeviceOS: 1,
      ApplicationType: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
    };

    APIClient.post("admin/convenienceFee", data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Convenience Fee Added Successsfully");
          GetConvenienceFeeData();
          return;
        }
        message.error("Something Went Wrong", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const DeleteConvenienceFeeData = (id) => {
    APIClient.delete("admin/convenienceFee/" + id)
      .then((response) => {
        if (response.status == 200) {
          GetConvenienceFeeData();
          message.success("Convenience Fee Deleted Successsfully");
          return;
        }
        message.error("Something Went Wrong", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const [convId, setConvId] = useState(-1);
  const columns = [
    {
      title: "Service",
      dataIndex: "ServiceType",
      refno: "",
      sorter: {
        compare: (a, b) => a.ServiceType - b.ServiceType,
        // multiple: 1
      },
      // sorter: true,
      key: "ServiceType",
      render: (text) => {
        switch (text) {
          case 1:
            return "Bus";

          case 2:
            return "Flight";

          default:
            return;
        }
      },
    },
    {
      title: "Convenience Fee Type",
      dataIndex: "ConvenienceFeeType",
      key: "ConvenienceFeeType",
      render: (text) => {
        switch (text) {
          case 0:
            return "Percentage";

          case 1:
            return "Fixed";

          default:
            return;
        }
      },
    },
    {
      title: "Value",
      dataIndex: "Value",
      key: "Value",
    },
    {
      dataIndex: "ID",
      title: "Actions",
      render: (text, record, index) => (
        <span
          className="edit-icon"
          onClick={() => {
            setCFEdit(true);
            setConvId(text);
            ConvenienceFeeForm.setFieldsValue({
              ServiceType: record.ServiceType,
              Value: record.Value,
              ConvenienceFeeType: record.ConvenienceFeeType === 1 ? 1 : 0,
            });
          }}
        >
          <i className="fa fa-edit"></i>
        </span>
      ),
    },
    {
      dataIndex: "Delete",
      title: "Delete",
      render: (text, record) => (
        <Popconfirm
          title="Are u sure to delete?"
          onConfirm={() => DeleteConvenienceFeeData(record.ID)}
        >
          <span className="delete-icon">
            <i className="fa fa-trash"></i>
          </span>
        </Popconfirm>
      ),
      // ) : null,
    },
  ];

  const columnsBusiness = [
    {
      title: "Address",
      dataIndex: "Address",
      key: "Address",
    },
    {
      title: "City",
      dataIndex: "CityID",
      key: "city",
    },
    {
      title: "Postal/Zip Code",
      dataIndex: "PostalCode",
      key: "PostalCode",
    },
    {
      title: "State",
      dataIndex: "StateID",
      key: "state",
    },
    {
      title: "Country",
      dataIndex: "CountryID",
      key: "country",
    },
    {
      dataIndex: "Actions",
      title: "Actions",
      render: (text, record, index) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <span
            className="edit-icon"
            onClick={() => {
              updateBusinessAddress(record);
            }}
          >
            <FormOutlined />
          </span>
          <Popconfirm
            title="Are u sure to delete?"
            onConfirm={() => deleteBusinessAddress(record.BusinessAddressID)}
          >
            <span className="delete-icon">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const deleteBusinessAddress = (id) => {
    APIClient.delete("admin/businessAddress/" + id).then((res) => {
      if (res.status == 200) {
        message.success("Business Address Deleted  successfully", 3);
        getSettingsData();
      }
    });
  };

  const updateBusinessAddress = (record) => {
    setIsBusiAddrEdit(true);
    setBusinessAddressID(record.BusinessAddressID);
    BusinessForm.setFieldsValue({
      Address: record.Address,
      PostalCode: record.PostalCode,
      StateID: record.StateID,
      CityID: record.CityID,
      CountryID: record.CountryID,
    });
  };

  const handleSocial = (value) => {
    let data = {
      UserID: 1,
      ...value,
      Status: 0,
      CreatedBy: 0,
      DeviceOS: 0,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      ApplicationType: 0,
      FirBaseToken: "string",
    };

    APIClient.post("admin/socialMedia", data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Social Details Added SuccessFully", 3);
          getSettingsData();
          return;
        } else if (response.status == 404) {
          message.error("User Not Found", 3);
          return;
        }
        message.error("Something Went Wrong", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const columnsGateway = [
    {
      title: "Gateway Name",
      dataIndex: "PGName",
      key: "PGName",
    },
    {
      title: "Merchant ID",
      dataIndex: "MerchantId",
      key: "MerchantID",
    },
    {
      title: "Application Secret",
      dataIndex: "SecretKey",
      key: "SecretKey",
    },

    {
      dataIndex: "Actions",
      title: "Actions",
      render: (text, record, index) => (
        <span
          className="edit-icon"
          onClick={() => {
            PaymentGatewayForm.resetFields();
            setPGEdit(true);
            if (record.PGImage != null) {
              setPgImageUrl(
                BASE + record.PGImage.substr(1, record.PGImage.length)
              );
            }

            setPGEditId(record.PGID);

            PaymentGatewayForm.setFieldsValue({
              PGName: record.PGName,
              MerchantId: record.MerchantId,
              SecretKey: record.SecretKey,
              PGImage: record.PGImage,
            });
          }}
        >
          <i className="fa fa-edit"></i>
        </span>
      ),
    },
    {
      dataIndex: "PGID",
      title: "Delete",
      render: (text, record) => (
        <Popconfirm
          title="Are u sure to delete?"
          onConfirm={() => {
            DeletePaymentGateway(text);
          }}
        >
          <span className="delete-icon">
            <i className="fa fa-trash"></i>
          </span>
        </Popconfirm>
      ),
      // ) : null,
    },
  ];

  useEffect(() => {
    getUserProfile();
    getSettingsData();
    GetPaymentGateway();
    GetConvenienceFeeData();
  }, []);

  const getUserProfile = () => {
    let userID = 1;
    APIClient.get(`admin/b2c/user/${userID}`)
      .then((response) => {
        if (response.status == 200) {
          setUserData((prev) => ({
            ...prev,
            ...response.data.userDetails,
          }));
          PersonalInfoForm.setFieldsValue({
            Email: response.data.userDetails.Email,
            Phone: response.data.userDetails.Mobile,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getSettingsData = () => {
    let userID = 1;
    APIClient.get(`admin/settings/${userID}`)
      .then((res) => {
        if (res.status == 200) {
          const { settings } = res.data;

          BusinessDetailsForm.setFieldsValue({
            ...settings.userBusinessDetails,
          });
          PersonalInfoForm.setFieldsValue({
            ...settings.userDetails,
          });
          GstVatForm.setFieldsValue({ ...settings.userGstVat });
          EmailForm.setFieldsValue({
            ...settings.userEmailConfig,
          });
          CurrencyForm.setFieldsValue({
            ...settings.userCurrency,
          });
          socialForm.setFieldsValue({
            ...settings.userSocialDetails,
          });

          setUserSettings((prev) => ({ ...prev, ...settings }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const personalInfoApi = (values) => {
    APIClient.post("admin/personalInfo", {
      UserID: 1,
      ...values,
    })
      .then((response) => {
        if (response.status == 200) {
          message.success("Personal Information Updated SuccessFully", 3);

          return;
        } else if (response.status == 404) {
          message.error("User Not Found", 3);
          return;
        }
        message.error("Something Went Wrong", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // GST/VAT API CALL
  const gstVatApi = (values) => {
    APIClient.post("admin/GstVat", {
      UserID: 1,
      ...values,
    })
      .then((response) => {
        if (response.status == 200) {
          message.success("GST/VAT Updated SuccessFully", 3);

          return;
        } else if (response.status == 404) {
          message.error("User Not Found", 3);
          return;
        }
        message.error("Something Went Wrong", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitBusinessDetails = (values) => {
    delete values.ano;
    APIClient.post("admin/businessDetails", { UserID: 1, ...values })
      .then((response) => {
        if (response.status == 200) {
          message.success("Business Details Updated SuccessFully", 3);

          return;
        } else if (response.status == 404) {
          message.error("User Not Found", 3);
          return;
        }
        message.error("Something Went Wrong", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitBusinessForm = (values) => {
    let data = {
      UserID: 1,
      ...values,
    };
    isBusiAddrEdit ? udateBusiAddr(data) : submitBusiAddr(data);
  };

  const submitBusiAddr = (formData) => {
    APIClient.post("admin/businessAddress", formData)
      .then((response) => {
        if (response.status == 200) {
          message.success("Business Address Added SuccessFully", 3);
          getSettingsData();
          BusinessForm.resetFields();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const udateBusiAddr = (formData) => {
    const data = {
      BusinessAddressID: businessAddressID,
      ...formData,
    };
    APIClient.post("admin/updateBusinessAddress", data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Business Address Updated SuccessFully", 3);
          getSettingsData();
          setBusinessAddressID(-1);
          setIsBusiAddrEdit(false);
          BusinessForm.resetFields();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitEmailConfig = (values) => {
    APIClient.post("admin/emailConfig", { UserID: 1, ...values })
      .then((response) => {
        if (response.status == 200) {
          message.success("Email Details Updated SuccessFully", 3);
        } else if (response.status == 404) {
          message.error("User Not Found", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetPaymentGateway = () => {
    APIClient.get("admin/pgKeys")
      .then((response) => {
        if (response.status == 200) {
          setPaymentGatewayDetails(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const DeletePaymentGateway = (id) => {
    APIClient.delete("admin/pgKeys/" + id)
      .then((response) => {
        if (response.status == 200) {
          GetPaymentGateway();
          message.success("Deleted Successfully");
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdatePaymentGateway = (data) => {
    APIClient.put("admin/pgKeys/" + PGEditId, [], data)
      .then((response) => {
        if (response.status == 200) {
          GetPaymentGateway();
          PaymentGatewayForm.resetFields();
          setPGEdit(false);
          message.success("Updated Successfully", 3);
        } else {
          message.error("Something went wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const SetPaymentGateway = (values) => {
    values.PGImage = values.PGImage.file.response.data.filepath;

    let data = {
      CreatedBy: 0,
      DeviceOS: 0,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
      ...values,
    };

    if (PGEdit) {
      UpdatePaymentGateway(data);
      return;
    }

    APIClient.post("admin/pgKeys", data)
      .then((response) => {
        if (response.status == 200) {
          GetPaymentGateway();
          PaymentGatewayForm.resetFields();
          message.success("Added Successfully", 3);
        } else {
          message.error("Something went wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [upLoadImage, setUpLoadImage] = useState(false);
  const [PgimageUrl, setPgImageUrl] = useState();
  const uploadButton = (
    <div>
      {upLoadImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  function handleUploadChange(info) {
    if (info.file.status === "error") {
      message.error(" Error Uploading Image", 3);
      setUpLoadImage(false);
      return;
    }
    if (info.file.status === "uploading") {
      setUpLoadImage(true);
      return;
    }
    if (info.file.status === "done") {
      if (info.file.response.status == 200) {
        getBase64(info.file.originFileObj, (imageUrl) => {
          setPgImageUrl(imageUrl);
          setUpLoadImage(false);
        });
      }
      setUpLoadImage(false);
    }
  }
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const BASE = process.env.REACT_APP_BASE_URL;
  const uploadActionUrl = BASE + "media/upload/singleImage";

  const handleProfilePic = (values) => {
    const UserID = 1;
    const { ProfilePic } = values;
    const { file } = ProfilePic;
    if (file.response.status == 200) {
      let profilePic = {
        ...userData,
        ProfilePic: file.response.data.filepath,
      };
      APIClient.put(`admin/b2c/user/${UserID}`, {}, profilePic)
        .then((response) => {
          if (response.status == 200) {
            message.success("Profile Pic Updated SuccessFully", 3);
            getUserProfile();
          } else if (response.status == 404) {
            message.error("Not Found", 3);
          } else {
            message.error("Something Went Wrong", 3);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  let key = 0;
  const keyGenerate = () => {
    return key++;
  };

  return (
    <Layout>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row gutter={16}>
            <Col className="gutter-class left-details" md={18} xs={24}>
              <Card bordered={false}>
                <h5>Settings</h5>
                <Collapse accordion defaultActiveKey={["1"]}>
                  <Panel header="Personal Information" key="1">
                    <Form
                      name="personal_form"
                      onFinish={personalInfoApi}
                      form={PersonalInfoForm}
                      layout="vertical"
                    >
                      <div className="company-logos">
                        <div className="logo-section">
                          <PersonalInfo />
                        </div>
                        <div
                          className="upload-btns"
                          style={{ textAlign: "right" }}
                        >
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </Panel>
                  <Panel header="Business Details" key="2">
                    <Form
                      form={BusinessDetailsForm}
                      name="business_details_form"
                      onFinish={submitBusinessDetails}
                      layout="vertical"
                    >
                      <div className="company-logos">
                        <div className="logo-section">
                          <BusinessAddress />
                        </div>
                        <div
                          className="upload-btns"
                          style={{ textAlign: "right" }}
                        >
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </Panel>
                  {/* <Panel header="Business" key="3">
                    <Form
                      form={BusinessForm}
                      name="BusinessForm"
                      onFinish={submitBusinessForm}
                      layout="vertical"
                    >
                      <div className="company-logos">
                        <div className="logo-section">
                          <Row gutter={16}>
                            <Col className="gutter-class" md={6} xs={24}>
                              <Form.Item
                                label="Address"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required Field",
                                  },
                                ]}
                                name="Address"
                              >
                                <Input
 autocomplete="newpassword"  placeholder="Enter Address" />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-class" md={6} xs={24}>
                              <Form.Item
                                label="City"
                                name="CityID"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required Field",
                                  },
                                ]}
                              >
                                <Input
 autocomplete="newpassword"  placeholder="Enter City" />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-class" md={6} xs={24}>
                              <Form.Item
                                label="Postal code/Zip code"
                                name="PostalCode"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required Field",
                                  },
                                ]}
                              >
                                <Input
 autocomplete="newpassword"  placeholder="Enter zip code" />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-class" md={6} xs={24}>
                              <Form.Item
                                label="State"
                                name="StateID"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required Field",
                                  },
                                ]}
                              >
                                <Input
 autocomplete="newpassword"  placeholder="Enter State" />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-class" md={6} xs={24}>
                              <Form.Item
                                label="Country"
                                name="CountryID"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required Field",
                                  },
                                ]}
                              >
                                <Input
 autocomplete="newpassword"  placeholder="Enter Country" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div
                          className="upload-btns"
                          style={{ textAlign: "right" }}
                        >
                          <Button
                            type="primary"
                            style={{ marginRight: "5px" }}
                            htmlType="submit"
                          >
                            {isBusiAddrEdit ? "Update" : "Submit"}
                          </Button>
                          <Button
                            type="primary"
                            onClick={() => BusinessForm.resetFields()}
                          >
                            Clear
                          </Button>
                          {isBusiAddrEdit ? (
                            <Button
                              type="primary"
                              style={{ marginLeft: "5px" }}
                              onClick={() => {
                                BusinessForm.resetFields();
                                setBusinessAddressID(-1);
                                setIsBusiAddrEdit(false);
                              }}
                            >
                              Reset
                            </Button>
                          ) : null}
                        </div>
                      </div>

                      <Table
                        className=" fee-conven"
                        scroll={{ x: true }}
                        bordered
                        dataSource={userSettings.BusinessAddress}
                        columns={columnsBusiness}
                        rowKey={keyGenerate}
                      />
                    </Form>
                  </Panel> */}
                  <Panel header="GST" key="4">
                    <Form
                      name="gstvat_details_form"
                      onFinish={gstVatApi}
                      form={GstVatForm}
                      layout="vertical"
                    >
                      <div className="company-logos">
                        <div className="logo-section">
                          <div className="gst-section">
                            <Row gutter={16}>
                              <Col className="gutter-class" md={6} xs={24}>
                                <Form.Item
                                  label="GST Company Name"
                                  name="GstCompanyName"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Input
 autocomplete="newpassword"  placeholder="Enter GST Company Name" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={6} xs={24}>
                                <Form.Item
                                  label="GST Number"
                                  name="GstNumber"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Input
 autocomplete="newpassword"  placeholder="Enter GST Number" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={6} xs={24}>
                                <Form.Item
                                  label="GST Company Contact Number"
                                  name="GstCompanyContactNumber"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Input
 autocomplete="newpassword"  placeholder="Enter Contact Number" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={6} xs={24}>
                                <Form.Item
                                  label="GST Company Email"
                                  name="GstCompanyEmail"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Input
 autocomplete="newpassword"  placeholder="Enter GST Company Email" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={6} xs={24}>
                                <Form.Item
                                  label="GST Company Address"
                                  name="GstCompanyAddress"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Input
 autocomplete="newpassword"  placeholder="Enter GST Company Address" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div
                          className="upload-btns"
                          style={{ textAlign: "right" }}
                        >
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </Panel>
                  {/* <Panel header="Email" key="5">
                    <Form
                      name="email_details_form"
                      onFinish={submitEmailConfig}
                      form={EmailForm}
                      layout="vertical"
                    >
                      <div className="company-logos">
                        <div className="logo-section">
                          <Row gutter={16}>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="Email"
                                name="Email"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required Field",
                                  },
                                ]}
                              >
                                <Input
 autocomplete="newpassword"  placeholder="Enter Email" id="Email" />
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="Email Password"
                                name="EmailPassword"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required Field",
                                  },
                                ]}
                              >
                                <Input
 autocomplete="newpassword" 
                                  placeholder="Enter Email Password"
                                  id="EmailPassword"
                                />
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="SMTP Host"
                                name="SMTPHost"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required Field",
                                  },
                                ]}
                              >
                                <Input
 autocomplete="newpassword" 
                                  placeholder="Enter SMTP Host"
                                  id="SMTPHost"
                                />
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="SMTP Port"
                                name="SMTPPort"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required Field",
                                  },
                                ]}
                              >
                                <Input
 autocomplete="newpassword" 
                                  placeholder="Enter SMTP Port"
                                  id="SMTPPort"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div
                          className="upload-btns"
                          style={{ textAlign: "right" }}
                        >
                          <Button type="primary" style={{ marginRight: "5px" }}>
                            Test
                          </Button>
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </Panel> */}

                  <Panel header="Social Media" key="9">
                    <Form
                      name="socialForm"
                      onFinish={handleSocial}
                      form={socialForm}
                      layout="vertical"
                    >
                      <div className="company-logos">
                        <div className="logo-section">
                          <Row gutter={16}>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label={
                                  <span>
                                    <FacebookOutlined />
                                  </span>
                                }
                                name="FacebookUrl"
                              >
                                <Input
 autocomplete="newpassword"  placeholder="" />
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="Facebook AppId"
                                name="FacebookAppID"
                              >
                                <Input
 autocomplete="newpassword"  placeholder="Enter " />
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label={
                                  <span>
                                    <GoogleOutlined />
                                  </span>
                                }
                                name="GoogleUrl"
                              >
                                <Input
 autocomplete="newpassword"  placeholder="" />
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="Google AppId"
                                name="GoogleAppID"
                              >
                                <Input
 autocomplete="newpassword"  placeholder="Enter " />
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label={
                                  <span>
                                    <InstagramOutlined />
                                  </span>
                                }
                                name="Instagram"
                              >
                                <Input
 autocomplete="newpassword"  placeholder="" />
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label={
                                  <span>
                                    <TwitterOutlined />
                                  </span>
                                }
                                name="Twitter"
                              >
                                <Input
 autocomplete="newpassword"  placeholder="" />
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label={
                                  <span>
                                    <LinkedinOutlined />
                                  </span>
                                }
                                name="Linkedin"
                              >
                                <Input
 autocomplete="newpassword"  placeholder="" />
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label={
                                  <span>
                                    <YoutubeOutlined />
                                  </span>
                                }
                                name="Youtube"
                              >
                                <Input
 autocomplete="newpassword"  placeholder="" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div
                          className="upload-btns"
                          style={{ textAlign: "right" }}
                        >
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </Panel>
                  <Panel header="Payment Gateway Details" key="10">
                    <Form
                      name="pg_details_form"
                      onFinish={SetPaymentGateway}
                      form={PaymentGatewayForm}
                      layout="vertical"
                    >
                      <div className="company-logos">
                        <div className="logo-section">
                          <Row gutter={16}>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="Payment Gateway Name"
                                name="PGName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required Field",
                                  },
                                ]}
                              >
                                <Select
 autocomplete="newpassword"  placeholder="Select Gateway">
                                  <Option value="Cashfree">Cashfree</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="Application ID"
                                name="MerchantId"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required Field",
                                  },
                                ]}
                              >
                                <Input
 autocomplete="newpassword"  placeholder="Enter Application ID" />
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="Application Secret"
                                name="SecretKey"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required Field",
                                  },
                                ]}
                              >
                                <Input
 autocomplete="newpassword"  placeholder="Enter Secret ID" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <Row>
                          <Form.Item name="PGImage">
                            <Upload
                              name="image"
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              action={uploadActionUrl}
                              onChange={handleUploadChange}
                              data={{ category: "pgimage" }}
                            >
                              {PgimageUrl ? (
                                <img
                                  src={PgimageUrl}
                                  alt="avatar"
                                  style={{ width: "100%" }}
                                />
                              ) : (
                                uploadButton
                              )}
                            </Upload>
                          </Form.Item>
                        </Row>
                        <div
                          className="upload-btns"
                          style={{ textAlign: "right" }}
                        >
                          <Button
                            type="primary"
                            style={{ marginRight: "5px" }}
                            onClick={() => {
                              PaymentGatewayForm.resetFields();
                              setPGEdit(false);
                            }}
                          >
                            Clear
                          </Button>
                          <Button type="primary" htmlType="submit">
                            {PGEdit ? "Update" : "Submit"}
                          </Button>
                        </div>
                      </div>
                      <Table
                        className=" fee-conven"
                        scroll={{ x: true }}
                        bordered
                        dataSource={PaymentGatewayDetails}
                        columns={columnsGateway}
                        rowKey={keyGenerate}
                      />
                    </Form>
                  </Panel>
                  <Panel header="Convenience Fee" key="11">
                    <Form
                      name="pg_details_form"
                      onFinish={
                        CFEdit ? UpdateConvenienceFeeData : AddConvenienceFee
                      }
                      form={ConvenienceFeeForm}
                      layout="vertical"
                    >
                      <div className="company-logos">
                        <div className="logo-section">
                          <Row gutter={16}>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="Service:"
                                name="ServiceType"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required Field",
                                  },
                                ]}
                              >
                                <Select
 autocomplete="newpassword"  placeholder="Select Service">
                                  <Option value={1}>Bus</Option>
                                  <Option value={2}>Flight</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="Value:"
                                name="Value"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required Field",
                                  },
                                ]}
                              >
                                <Input
 autocomplete="newpassword" Number
                                  min={1}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="Convenience Fee Type:"
                                name="ConvenienceFeeType"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required Field",
                                  },
                                ]}
                              >
                                <Radio.Group>
                                  <Radio value={0}>Percentage</Radio>
                                  <Radio value={1}>Fixed</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div
                          className="upload-btns"
                          style={{ textAlign: "right" }}
                        >
                          <Button type="primary" htmlType="submit">
                            {CFEdit ? "Update" : "Add"}
                          </Button>
                          &nbsp;
                          <Button
                            type="primary"
                            onClick={() => ConvenienceFeeForm.resetFields()}
                          >
                            Clear
                          </Button>
                        </div>

                        <Table
                          scroll={{ x: true }}
                          className="fee-conven"
                          bordered
                          dataSource={convenienceFee}
                          columns={columns}
                          rowKey={keyGenerate}
                        />
                      </div>
                    </Form>
                  </Panel>
                </Collapse>
              </Card>
            </Col>
            <Col className="gutter-class" md={6} xs={24}>
              <Card bordered={false}>
                <Form form={ProfilePicForm} onFinish={handleProfilePic}>
                  <Profile userData={userData} userRole={userSettings.role} />
                </Form>
              </Card>
            </Col>
          </Row>
        </Content>
      </div>
    </Layout>
  );
};

export default Settings;
