import React, { useState, useEffect } from "react";
import {
  ClockCircleOutlined,
  CoffeeOutlined,
  ArrowRightOutlined,
  ShareAltOutlined,
  RightOutlined,
  ShoppingOutlined,
  DesktopOutlined,
  InfoCircleOutlined,
  UserOutlined,
  WifiOutlined,
  MailOutlined,
  LinkOutlined,
  MessageOutlined,
  AntDesignOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import * as ReactBootstrap from "react-bootstrap";
import * as ANTD from "antd";
import "antd/dist/antd.css";
import "font-awesome/css/font-awesome.min.css";

import "../../../components/SearchResults/Flight/Flight.scss";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import APIClient from "../../../helpers/ApiClient";

export const FlightTimings = (props) => {
  // console.log("Flight Timings props", props);
  //   const flightDetailInfo = props.flightDetails;
  //   const [fareRulesResp, setFareRulesResp] = useState("");
  //   const { TabPane } = ANTD.Tabs;

  //   const fetchFlightFares = (str, event) => {
  //     let searchResp = JSON.parse(localStorage.getItem("searchResp"));
  //     if (str === "2") {
  //       let fareReqObj = {
  //         traceId: localStorage.getItem("traceId"), //searchResp.traceId,
  //         flightId: flightDetailInfo.flightId,
  //         airTravelType: localStorage.getItem("travelType"), //searchResp.airTravelType,
  //         mappingType: localStorage.getItem("resultsType"), //searchResp.resultsType,
  //         itineraryViewType: "1",
  //       };
  //       APIClient.post("flights/airFareRules", fareReqObj)
  //         .then((result) => {
  //           return result;
  //         })
  //         .then((resp) => {
  //           if (resp.statusCode === 200) {
  //             setFareRulesResp(resp.data.errors[0].errorDetail);
  //             props.setFlightFareRules(resp.data.errors[0].errorDetail);
  //             console.log("fare rules response---->", resp);
  //           }
  //         })
  //         .catch((err) => console.log(err));
  //     }
  //   };

  //   const flightTotalFare = (fareInfo) => {
  //     let totalFare = fareInfo.reduce((acc, val) => acc + val.amount, 0);
  //     return totalFare;
  //   };

  //   const formatFlightTime = (flightTime) => {
  //     let [date, time] = flightTime.split("T");
  //     let [hour, minute, seconds] = time.split(":");
  //     return `${hour}:${minute}`;
  //   };

  //   const calculateDuration = (from, to) => {
  //     let fromTime = from.split("T")[0] + " " + from.split("T")[1];
  //     let toTime = to.split("T")[0] + " " + to.split("T")[1];

  //     let diffInMilliSeconds =
  //       Math.abs(new Date(fromTime) - new Date(toTime)) / 1000;

  //     // calculate days
  //     const days = Math.floor(diffInMilliSeconds / 86400);
  //     diffInMilliSeconds -= days * 86400;
  //     // calculate hours
  //     const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  //     diffInMilliSeconds -= hours * 3600;

  //     // calculate minutes
  //     const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  //     diffInMilliSeconds -= minutes * 60;

  //     let difference = "";
  //     if (days > 0) {
  //       difference += days === 1 ? `${days} d, ` : `${days} ds, `;
  //     }

  //     difference += hours === 0 || hours === 1 ? `${hours} h ` : `${hours} h `;

  //     difference +=
  //       minutes === 0 || hours === 1 ? `${minutes} min` : `${minutes} min`;

  //     return difference;
  //     // return days;
  //   };

  //   const calculateJourneyDuration = (from, to) => {};
  //   const { Panel } = ANTD.Collapse;
  //   // const toolTipText = <span>prompt text</span>

  return (
    <div className="flight-timings">
      <div className="plane-timings">
        <ul>
          <li>
            <p>
              {/* {formatFlightTime(
                  flightDetailInfo.flightSegments[0].departureDateTime
                )} */}
              23:05
            </p>
          </li>
          <li>
            <ArrowRightOutlined />
          </li>
          <li>
            {/* {flightDetailInfo.flightSegments.length > 0 ? (
                <div>
                  <p>
                    {formatFlightTime(
                      flightDetailInfo.flightSegments[
                        flightDetailInfo.flightSegments.length - 1
                      ].arrivalDateTime
                    )}
                  </p>
                </div>
              ) : (
                <div>
                  <p>
                    {formatFlightTime(
                      flightDetailInfo.flightSegments[0].arrivalDateTime
                    )}
                  </p>
                </div>
              )} */}
            <div>
              <p> 00:40</p>
            </div>
          </li>
        </ul>
      </div>
      <div className="travel-time">
        <p>
          <ClockCircleOutlined />
        </p>
        <p>
          {/* {" "}
            {flightDetailInfo.flightSegments.length > 0 ? (
              <div>
                <p>
                  {calculateDuration(
                    flightDetailInfo.flightSegments[0].departureDateTime,
                    flightDetailInfo.flightSegments[
                      flightDetailInfo.flightSegments.length - 1
                    ].arrivalDateTime
                  )}
                </p>
              </div>
            ) : (
              <div>
                <p>
                  {calculateDuration(
                    flightDetailInfo.flightSegments[0].departureDateTime,
                    flightDetailInfo.flightSegments[0].arrivalDateTime
                  )}
                </p>
              </div>
            )} */}{" "}
          1 h 35 min
        </p>
      </div>
      <div className="details-btn"></div>
    </div>
  );
};

const FlightLayout = (props) => {
  const flightInfo = props.flightInfo;

  const flightTotalFare = (fareInfo) => {
    let totalFare = Number(
      fareInfo.reduce((acc, val) => acc + val.amount, 0)
    ).toFixed();
    return totalFare;
  };

  const formatFlightTime = (flightTime) => {
    let [date, time] = flightTime.split("T")[1];
    let [hour, minute, seconds] = time.split(":");
    return `${hour}:${minute}`;
  };
  return (
    <div className="flight-results-container">
      <div className="combined-view">
        <div className="combined-flight-results-container">
          <ANTD.Card className="combined-flight-card">
            <ANTD.Row>
              <ANTD.Col md={24} xs={24} className="flight-trip-details">
                <div className="itinary-details">
                  <div className="itinary-flight-name">
                    <img
                      className="airline-img"
                      src="http://www.gstatic.com/flights/airline_logos/70px/6E.png"
                      //   src={flightInfo.airLineLogo}
                      //   alt={flightInfo.airLineLogo}
                    />
                  </div>
                  <div className="fromTo">
                    <div className="cities-from-to">
                      <ANTD.Tooltip
                        placement="top"
                        className="citynames-tooltip"
                      >
                        <span className="code">
                          {/* {flightInfo.flightSegments[0].origin} */}HYD
                        </span>
                      </ANTD.Tooltip>

                      {/* {flightInfo.flightSegments.map((flightSegInfo, index) =>
                        index !== 0 ? (
                          <div
                            className="break-journey-city"
                            key={flightSegInfo.segId}
                          >
                            <p className="code-line-break"></p>
                            <ANTD.Tooltip
                              placement="top"
                              title={toolBreakCity(flightSegInfo.destination)}
                              className="citynames-tooltip"
                            >
                              <span className="break-city-name">
                                {flightSegInfo.origin}
                              </span>
                            </ANTD.Tooltip>
                          </div>
                        ) : null
                      )}
                      <ANTD.Tooltip
                        placement="top"
                        title={toolTipToText(
                          flightInfo.flightSegments[
                            flightInfo.flightSegments.length - 1
                          ].destination
                        )}
                        className="citynames-tooltip"
                      >
                        <span className="code">
                          {
                            flightInfo.flightSegments[
                              flightInfo.flightSegments.length - 1
                            ].destination
                          }
                        </span>
                      </ANTD.Tooltip> */}
                      <ANTD.Tooltip
                        placement="top"
                        // title={toolTipToText(
                        //   flightInfo.flightSegments[
                        //     flightInfo.flightSegments.length - 1
                        //   ].destination
                        // )}
                        className="citynames-tooltip"
                      >
                        <span className="code">BLR</span>
                      </ANTD.Tooltip>
                    </div>

                    <div className="arrive-dept-date">
                      <span className="dateDay">
                        {/* {dateFormat(
                          props.flightSearchData.departureDateTime,
                          "dd mmm (ddd)"
                        )} */}
                        26 Nov (Thu)
                      </span>
                    </div>
                  </div>
                  <div className="flight-class-details">
                    <span className="stopsType">
                      {/* {flightInfo.flightSegments.length === 0 ? ( */}
                      {/* <>Direct</> */}
                      {/* ) : (
                        <>{flightInfo.flightSegments.length - 1} stops </>
                        
                      )} */}
                      0 stops
                    </span>
                    <span className="flight-class">
                      class: Economy
                      {/* class: {props.flightSearchData.cabinClass} */}
                    </span>
                  </div>
                </div>
                <FlightTimings
                //   flightDetails={flightInfo}
                //   setFlightFareRules={props.setFlightFareRules}
                />
              </ANTD.Col>
            </ANTD.Row>
          </ANTD.Card>
        </div>
      </div>
    </div>
  );
};

export const FlightSelection = () => {
  // console.log("selected flight props", props);
  //   const flightInfo = props.flightInfo;
  //   const [flightArr, setFlightArr] = useState([]);

  //   const [selectedJourneyType, setSelectedJourneyType] = useState("onward");
  //   const [flightList, setFlightList] = useState(props.flightDetails);

  //   const flightTotalFare = (fareInfo) => {
  //     let totalFare = Number(
  //       fareInfo.reduce((acc, val) => acc + val.amount, 0)
  //     ).toFixed();
  //     return totalFare;
  //   };

  return (
    <>
      <div className="choose-btn-block">
        <div>
          <div className="choose-btn-price">
            <span className="currencyType">
              {/* {flightInfo.fareFamilies.fareFamilies[0].currency} */}INR
            </span>
            <span className="currency">
              {/* {flightInfo.fareFamilies.fareFamilies[0].totalFare.toFixed(2)} */}
              1955.50
            </span>
          </div>
          <div className="right-price-section">
            <span className="price-text">
              Price for{" "}
              {/* {props.flightSearchData.adultCount +
                props.flightSearchData.infantCount +
                props.flightSearchData.childCount} */}
              passenger,
              <br />
              Oneway
              {/* {props.flightSearchData.airTravelType === "oneWay"
                ? "Oneway"
                : "Round Trip"} */}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default FlightLayout;
