import React, { useState, useEffect, createContext } from "react";
import moment from "moment";

//TODO - Move to static file
const defFlightSearchObj = {
  airTravelType: "oneWay",
  oneWayType: "1",
  traceId: "",
  stopOver: "",
  departureDateTime: moment(new Date()).format("YYYY-MM-DD"),
  returnDateTime: "",
  origin: "",
  destination: "",
  adultCount: 1,
  childCount: 0,
  infantCount: 0,
  cabinClass: "Economy",
  resultsType: "",
  searchId: "",
};

export const FlightContext = createContext();

export const FlightContextProvider = (props) => {
  //Hydrating Flight Data from localstorage
  const flightPersistJson =
    JSON.parse(localStorage.getItem("flightPersist")) || {};
  let initFlightSearchObj =
    flightPersistJson.flightSearchObj || defFlightSearchObj;
  let initSelectedFlight = flightPersistJson.selectedFlight || [];

  const [state, setState] = useState({});

  const [flightSearchObj, setFlightSearchObj] = useState(initFlightSearchObj);

  const [originDestinationsArr, setOriginDestinationsArr] = useState([
    {
      departureDateTime: "2020-09-25T00:00:00",
      origin: "LON",
      destination: "BOM",
    },
  ]);

  const [flightSearch, setFlightSearch] = useState({
    originDestinations: originDestinationsArr,
    adultCount: 1,
    childCount: 0,
    infantCount: 0,
    cabinClass: "Economy",
    includeCarrier: null,
    excludeCarrier: null,
    stopOver: "None",
    airTravelType: "oneWay",
    flightDateFlex: 0,
    itineraryViewType: "1",
  });

  const [flightFareRulesResp, setFlightFareRulesResp] = useState("");

  const [selectedFlight, setSelectedFlight] = useState(initSelectedFlight);

  const [airBookStateObj, setAirBookStateObj] = useState({});

  const [flightBookingResp, setFlightBookingResp] = useState({});

  const [flightAirSearchResp, setFlightAirSearchResp] = useState({});

  const [flightAirPriceResp, setFlightAirPriceResp] = useState({});

  /* Update Flight Search obj  */
  const updateFlightSearchObj = (attribute, value) => {
    setFlightSearchObj({ ...flightSearchObj, [attribute]: value });
  };
  /* Update Flight Search obj  */
  const updateFlightSearch = (attribute, value) => {
    setFlightSearch({ ...flightSearch, [attribute]: value });
  };

  /* Update Origin and Destination Obj */
  const updateOriginDest = (value) => {
    // console.log(originDestinationsArr);
    // console.log(value);
    // setOriginDestinationsArr([value]);
    // console.log(originDestinationsArr);
    // setOriginDestinationsArr([...value]);
    originDestinationsArr.map(() => setOriginDestinationsArr(value));
    setFlightSearch({ ...flightSearch, originDestinations: value });
  };

  /* Update Flight fare Rules data */
  const updateFlightFares = (FareRulesResp) => {
    setFlightFareRulesResp(FareRulesResp);
  };

  /* Update selected flight Obj */
  const updateSelectedFlight = (flightObj) => {
    setSelectedFlight(flightObj);
  };

  /* Update airbook request Obj */
  const updateAirBookState = (airBookReq) => {
    setAirBookStateObj(airBookReq);
  };

  /* Update airbook resp Obj */
  const updateAirBookRespState = (airBookResp) => {
    setFlightBookingResp(airBookResp);
  };

  /* Update Flight Search obj  */
  const updateFlightAirSearchRespObj = (flightAirResponse) => {
    setFlightAirSearchResp(flightAirResponse);
  };

  /* Update Airprice resp Obj */
  const updateFlightAirPrice = (airPriceRespObj) => {
    setFlightAirPriceResp(airPriceRespObj);
  };

  const resetFlightSearchObj = (flightSearchObj) => {
    setFlightSearchObj(flightSearchObj || defFlightSearchObj);
  };

  useEffect(() => {
    // console.log("Flight search state:", flightSearch);
    // console.log("Flight origin Dest state:", originDestinationsArr);
    // console.log("Selected Flight state:", selectedFlight);
  }, [flightSearch, selectedFlight]);

  useEffect(() => {
    //Flight Data in localstorage
    localStorage.setItem(
      "flightPersist",
      JSON.stringify({
        flightSearchObj: flightSearchObj,
        selectedFlight: selectedFlight,
      })
    );
  }, [flightSearchObj, selectedFlight]);

  return (
    <FlightContext.Provider
      value={{
        state: {
          ...state,
          flightSearch,
          originDestinationsArr,
          flightSearchObj,
          flightFareRulesResp,
          selectedFlight,
          airBookStateObj,
          flightBookingResp,
          flightAirPriceResp,
          flightAirSearchResp,
        },
        updateFlightSearch: updateFlightSearch,
        updateOriginDest: updateOriginDest,
        updateFlightSearchObj: updateFlightSearchObj,
        updateFlightFares: updateFlightFares,
        updateSelectedFlight: updateSelectedFlight,
        updateAirBookState: updateAirBookState,
        updateAirBookRespState: updateAirBookRespState,
        updateFlightAirSearchRespObj: updateFlightAirSearchRespObj,
        updateFlightAirPrice: updateFlightAirPrice,
        resetFlightSearchObj: resetFlightSearchObj,
        resetFlightSelectedData: () => {
          setSelectedFlight([]);
        },
      }}
    >
      {props.children}
    </FlightContext.Provider>
  );
};
