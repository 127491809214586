
import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Popconfirm,
    Modal,
    Popover,
    Row,
    Select,
    Table,
} from "antd";
import ReactHtmlParser from 'react-html-parser'
import modules from "../../helpers/ReactQuilModels"
import ReactQuill from "react-quill";
import AutoCompleteSelectM from '../../common/AutoCompleteSelect/AutoCompleteSelectM'
import "./MailNotification.scss";
// import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import ApiClient from "../../helpers/ApiClient";
import {
    CloseOutlined,
    InfoCircleTwoTone,
    PlusCircleFilled,
    InfoCircleOutlined,
} from "@ant-design/icons";


const { Option } = Select;


const MailNotification = () => {
    // const { user } = useAuthContext();
    const [MailNotificationForm] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [content, setContent] = useState("");
    const [mailList, setmailList] = useState([]);
    const [excelList, setExcelList] = useState([]);
    const [clients, setClients] = useState([]);
    const [showSearchBox, setShowSearchBox] = useState(false);

    const [cmsSearchId, setCmsSearchId] = useState(1);
    const [siteAdmins, setSiteAdmins] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [currID, setCurrID] = useState(-1);
    const [selectedUserIds, setSelectedUserIds] = useState([]);

    // Update CheckclientId to set selectedUserIds array
    const CheckclientId = (value) => {
        setSelectedUserIds(value);
        if (!value || !clients) return;
    };


    useEffect(() => {
        getList();
        getSiteAdmin({
            page: 0,
        });
    }, []);

    const getList = (reqBody) => {
        ApiClient.get("user/getnotifications")
            .then((res) => {
                // console.log(res, "list");
                if (res.isSuccess) {
                    let data = res.data.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                        };
                    });
                    setmailList(data);

                } else message.error(res.message, 3);
            })
            .catch((error) => {
                message.error(error.message ?? "Failed To get", 3);
                console.error(error);
            });

    }


    const onFinish = (values) => {
        if (!content) {
            message.error("Description Required");
            return;
        }
        const userIdsString = selectedUserIds.join(",");
        // console.log(userIdsString, selectedUserIds, values, content, "userIdsString");
        // Create request body
        const requestBody = {
            // ...values,
            clients: userIdsString,
            subject: values?.subject,
            body: content,
        };
        // console.log(requestBody, "requestBody");


        // if (isEdit) {
        //     // delete req.CreatedBy;
        //     // req.ModifiedBy = user?.UserID;
        //     updateMail(requestBody);
        // } else {
        createMmail(requestBody);
        // }
    };
    const getSiteAdmin = (obj) => {
        // console.log(obj, "adminlist");
        ApiClient.post("user/getuserslist/list", obj)
            .then((res) => {
                if (res.isSuccess) {
                    let data = res.results.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,

                        };
                    });
                    setSiteAdmins(data);
                    console.log(data, "user");

                }
            })
            .catch((e) => {
                console.log("api error", e);
            });
    };
    const createMmail = (reqBody) => {
        ApiClient.post("user/savenotifications", reqBody)
            .then((res) => {
                // console.log(res, "respp");
                setModalVisible(false);
                // if (res.isSuccess) {
                //     let data = res.results.map((item, index) => {
                //         return {
                //             SNo: index + 1,
                //             ...item,
                //         };
                //     });

                getList();

                // } else message.error(res.message, 3);
            })
            .catch((error) => {
                message.error(error.message ?? "Failed To get", 3);
                console.error(error);
            });
    };
    const updateMail = (reqBody) => {
        ApiClient.post("user/savenotifications", reqBody)
            .then((res) => {
                // console.log(res, "respp");
                // if (res.isSuccess) {
                //     let data = res.results.map((item, index) => {
                //         return {
                //             SNo: index + 1,
                //             ...item,
                //         };
                //     });
                //     setUserIpAdressList(data);
                //     setUserIPConfigTableList(data);
                //     setClients(data);
                // } else message.error(res.message, 3);
            })
            .catch((error) => {
                message.error(error.message ?? "Failed To get", 3);
                console.error(error);
            });
    };



    const handleDescription = (content) => {
        // console.log(content, "contt");
        if (content === "<p><br></p>") {
            content = "";
        }
        setContent(content);
    };
    const searchTableData = (e) => {
        var searchVal = e.target.value;
        if (searchVal.length > 0) {
            const searchResults = mailList.filter(function (item) {
                if (item != undefined) {
                    return JSON.stringify(item)
                        .toLowerCase()
                        .includes(searchVal.toLowerCase());
                }
            });
            setmailList(searchResults);
        } else {
            // getCMSList();
        }
    };

    let lastIndex = 0;
    const uniqueKey = () => {
        lastIndex++;
        return lastIndex;
    };

    const closeSearch = () => {
        setShowSearchBox(false);
    };
    const closeSearchInput = (
        <div>
            <CloseOutlined onClick={closeSearch} />
        </div>
    );
    const searchData = () => {
        setShowSearchBox(true);
    };

    const toggleModal = () => {
        if (isEdit) {
            setIsEdit(false);
        }
        MailNotificationForm.resetFields();
        setContent("");
        setModalVisible((prev) => !prev);
    };



    const columns = [
        {
            title: "User Name",
            dataIndex: "clients", // Assuming "clients" is the dataIndex where IDs are stored in each row
            render: (clientIds) => {
                // Convert clientIds to an array of numbers if it's a string of IDs
                const idsArray = clientIds.split(',').map(Number);

                // Filter users to match the IDs in the current row
                const matchedUsers = siteAdmins.filter(user => idsArray.includes(user.id));

                return (
                    <div style={{ lineHeight: '1.5', display: 'flex', maxWidth: "max-content", overflow: "auto" }}>
                        {matchedUsers.map((user, index) => (
                            <div key={user.id}>
                                <li>{user.username}
                                    {index < matchedUsers.length - 1 ? ", " : ""}
                                </li>
                            </div>
                        ))}
                    </div>
                );
            }
        },

        {
            title: "Subject",
            dataIndex: "subject",
            render: (text) => (
                <div style={{ maxHeight: '100px', marginBottom: 5, overflowY: 'auto', lineHeight: '1.5' }}>{ReactHtmlParser(text)}</div>
            ),
        },
        {
            title: "Context",
            dataIndex: "body",
            render: (text) => (
                <div style={{ maxHeight: '400px', marginBottom: 5, overflowY: 'auto', lineHeight: '1.5' }}>{ReactHtmlParser(text)}</div>
            ),
        },

        {
            title: "Actions",
            dataIndex: "id",
            render: (id, data) => tableActions(id, data),
        },
    ];
    const [isMailVisible, setIsMailVisible] = useState(false);
    const [mailContent, setMailContent] = useState('');
    const showContextModal = (context) => {

        setMailContent(context);
        setIsMailVisible(true);
    };

    const handleModalClose = () => {
        setIsMailVisible(false);
        setMailContent('');
    };

    const updateCms = (currentData) => {
        setIsEdit(true);
        setModalVisible(true);
        setCurrID(currentData.Id);
        setContent(currentData.Description);
        MailNotificationForm.setFieldsValue({
            // CMSID: currentData.CMSID,
            // Title: currentData.Title,
        });
    };
    const DeleteMail = (Id) => {

        ApiClient.delete("user/deletenotifications/" + Id)
            .then((res) => {
                if (res.isSuccess) {
                    message.success(res.message ?? "Deleted  successfully", 3);
                    // getUserIpAddressList(initialReqObj);
                } else message.error(res.message ?? "Failed To Deleted", 3)
            })
            .catch((error) => {
                console.error(error);
                message.error("Failed To Deleted IpAddress", 3)
            });
    }

    const tableActions = (Id, data) => {
        return (
            <div>
                {/* {console.log(Id, data, "famik")} */}
                {/* <span className="edit-icon" onClick={() => updateCms(rec)}>
                    <i className="fa fa-edit"></i>
                </span> */}
                <span className="edit-icon" onClick={() => showContextModal(data.body)} style={{ cursor: 'pointer' }}>
                    {/* <InfoCircleOutlined
                        style={{ marginLeft: 8, color: '#1890ff', cursor: 'pointer' }}
                    // onClick={() => showContextModal(data.body)} 
                    /> */}
                    <i className="fa fa-info-circle"></i> View Mail
                </span>
                &nbsp;&nbsp;&nbsp;
                <Popconfirm
                    title="Are u sure to delete?"
                    onConfirm={() => DeleteMail(Id)}
                >
                    <span className="delete-icon" style={{ cursor: "pointer" }}>
                        <i className="fa fa-trash"></i> Delete this Record
                    </span>
                </Popconfirm>
            </div>
        );
    };
    return (
        <div><div className="manage-markup-section">
            <div className="container-fluid">
                <div className="card-bt-gap">
                    <Card bordered={false}>
                        <div className="card-add-heading">
                            <div className="rows-count">
                                <div>


                                </div>
                            </div>
                            <div className="action-images">
                                {/* {showSearchBox && (
                                    <Input
                                        placeholder="Search"
                                        onChange={(e) => searchTableData(e)}
                                        suffix={closeSearchInput}
                                        style={{ padding: "0px 12px" }}
                                    />
                                )}
                                <img
                                    src={search}
                                    alt="search"
                                    onClick={(e) => searchData(mailList)}
                                /> */}

                                <p className="add-deposit-icon" onClick={toggleModal}>
                                    <PlusCircleFilled /> <span style={{ fontSize: "12px", paddingLeft: "5px" }}>Send Mail </span>
                                </p>
                            </div>

                        </div>

                        <Form>
                            <Row>
                                <Col span={8}>
                                    <Form.Item label="Page">
                                        <Select
                                            placeholder="Select Page"
                                            onChange={(id) => {
                                                // getCMSList(id);
                                                // setCmsSearchId(id);
                                            }}
                                            defaultValue={cmsSearchId}
                                            value={cmsSearchId}
                                        >
                                            {/* {cmsOptions.map((cms) => (
                                                <Option key={"cmsoption" + cms.id} value={cms.id}>
                                                    {cms.name}
                                                </Option>
                                            ))} */}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        <Table
                            scroll={{ x: true }}
                            bordered
                            rowKey={uniqueKey}
                            dataSource={mailList}
                            columns={columns}
                            className="cms-table"
                        />
                    </Card>
                </div>
            </div>
        </div>

            <Modal
                title={isEdit ? "Update Mail Notification" : "Add Mail Notification"}
                className="promo-modal-header modal-header-bg12"
                visible={modalVisible}
                onOk={toggleModal}
                onCancel={toggleModal}
                footer={[
                    <div>
                        <Button type="primary" htmlType="submit" onClick={MailNotificationForm.submit}>
                            {isEdit ? "Update" : "Add"}
                        </Button>
                        <Button type="primary" danger onClick={() => toggleModal()}>
                            Cancel
                        </Button>
                    </div>,
                ]}
                width={"700px"}
            >
                <Form layout="vertical" name="basic" form={MailNotificationForm} onFinish={onFinish}>
                    <Row gutter={12}>
                        <Col md={24} xs={24} >
                            <AutoCompleteSelectM
                                formItemProps={{
                                    label: "User",
                                    name: "userId",
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please enter User Name",
                                        },
                                    ],
                                }}
                                selectProps={{
                                    placeholder: "Please Enter UserName",
                                }}
                                api={"user/userautosuggestion/"}
                                keytext="name"
                                keyvalue="id"
                                onChange={(value) => CheckclientId(value)}
                            />

                        </Col>

                        <Col md={24} xs={24}>
                            <Form.Item
                                label="Subject"
                                name="subject"
                                rules={[{ required: true, message: "Required" }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item label="Description (Required)">
                        <ReactQuill
                            modules={modules}
                            value={content}
                            placeholder="Type Here ...."
                            onChange={handleDescription}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            {/* {console.log("mail")} */}
            <Modal
                title="Context Details"
                visible={isMailVisible}
                onCancel={handleModalClose}
                footer={[
                    <Button key="close" onClick={handleModalClose}>
                        Close
                    </Button>
                ]}
            >
                <div style={{ lineHeight: '1.5' }}>
                    {ReactHtmlParser(mailContent)}
                </div>
            </Modal>
        </div>
    )
}

export default MailNotification