import React, { useContext, useState, useRef, useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Card,
  Collapse,
  Panel,
  Radio,
  Input,
  Checkbox,
} from "antd";
import { useHistory } from "react-router-dom";

// import { GlobalStatesContext } from "../../common/providers";
import BusesAutoSelect from "../../common/AutoCompleteSelect/BusesAutoSelect";
import { useAuthContext } from "../../common/providers/AuthProvider";
import moment from "moment";
import queryString from "query-string";
// import arrowImg from "../../assets/images/flight-arrow.png";
import { SearchOutlined } from "@ant-design/icons";
import "../buses-search/Buses.scss";

const Buses = ({ modifySearch = false, busesSearchAPI }) => {
  // const {
  //   state: { busPersist },
  // } = useContext(GlobalStatesContext);
  const CheckboxGroup = Checkbox.Group;
  const { Panel } = Collapse;
  const city = useRef(null);
  const searchBtn = useRef();
  const [paramsTags, setParamsTags] = useState("");
  let history = useHistory();
  const tocityBox = useRef(null);
  const fromDateBox = useRef(null);

  const dateFormat = "DD-MM-YYYY";
  const [form] = Form.useForm();

  const { user } = useAuthContext();

  const validateMessages = {
    required: "",
  };

  //#region  Latest Code

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  const [showDate, setShowDate] = useState(false);
  const handleOnSubmit = (ref) => {
    ref.current.focus();
    if (ref === fromDateBox) {
      setShowDate(true);
    }
  };

  const [screen, setScreen] = useState(1)

  const selectscreen = (e) =>  {
    if (e.target) setScreen(e.target.value)
  }

  const submitForm = (values) => {
    let source = queryString.parse(values.sourceId);
    let destination = queryString.parse(values.destinationId);
    let formData = {
      sourceId: source.cityId,
      destinationId: destination.cityId,
      journeyDate: moment(values.journeyDate).format(),
      sourceName: source.cityName,
      destinationName: destination.cityName,
      tags: values.filterOptions ? values.filterOptions.toString() : "",
      Api: values.apisOptions ? values.apisOptions.toString() : ""
    };
    const query = queryString.stringify(formData);
    {
      console.log("checking submit",screen);
    //  history.push("/admin/busresultsavailable?" + query);
      history.push("/admin/busresults?" + query);
    }
    
    


    if (modifySearch) {
      busesSearchAPI();
    }

  };
  useEffect(() => {

    if (modifySearch) {
      let param = queryString.parse(document.location.search);

      form.setFieldsValue({
        sourceId: `cityName=${param.sourceName}&cityId=${param.sourceId}`,
        destinationId: `cityName=${param.destinationName}&cityId=${param.destinationId}`,
        journeyDate: moment(param.journeyDate),
        filterOptions: param.tags.split(","),
        apisOptions: param.Api.split(",")
      });
      setCarrier(param.tags.split(","));
      setApisName(param.Api.split(","))
    }
  }, []);

  // const handleSwap = () => {
  //   const sourceId = form.getFieldValue("sourceId");
  //   const destinationId = form.getFieldValue("destinationId");

  //   if (sourceId && destinationId) {
  //     form.setFieldsValue({
  //       sourceId: destinationId,
  //       destinationId: sourceId,
  //     });
  //     setDataFlip((prev) => !prev);
  //   }
  // };
  const filterOptions = [
    { name: "Seater", value: "Seater" },
    { name: "Sleeper", value: "Sleeper" },
    { name: "Ac", value: " Ac" },
    { name: "NonAc", value: "NonAc" },

    { name: "LiveTracking", value: "LiveTracking" },
    { name: "MTicket", value: "MTicket" },
    { name: "SingleLadies", value: "SingleLadies" },
    { name: "Washroom ", value: "Washroom" },
    { name: "Cancel ", value: "Cancel " },
    { name: "Wifi ", value: "Wifi " },
    { name: "Partial Cancel ", value: "PartialCancel " },
    { name: "RTC ", value: "rtc " },
    { name: "Refund ", value: "refund " },
    { name: "Reschedule ", value: "reschedule " },
    { name: "Safety ", value: "safety " },
    { name: "Social Distance ", value: "socialDistance " },
    { name: "Non RTC", value: "Non RTC" },
    { name: "Morning", value: "morning" },
    { name: "Afternoon", value: "afternoon" },
    { name: "Evening", value: "evening" },
    { name: "Night", value: "night" },
  ];

  const apisOptions = [
    { name: "IRCT", value: "IRCT" },
    { name: "REDB", value: "REDB" },
    { name: "BTLA", value: "BTLA" },
    { name: "EZIF", value: "EZIF" },
    { name: "TRYR", value: "TRYR" },
    { name: "ETST", value: "ETST" },
    { name: "ITSG", value: "ITSG" },
    { name: "VRLT", value: "VRLT" },
    { name: "ZGBS", value: "ZGBS" },
    { name: "YLBS", value: "YLBS" },
  ]

  const [carrier, setCarrier] = useState([]);
  const handleFilterName = (e, name) => {
    let array = [...carrier];
    if (e.target.checked) {
      array.push(name);
    } else {
      let index = array.indexOf(name);
      if (index > -1) {
        array.splice(index, 1);
      }
    }
    setCarrier(array);
  };

  const [apisName, setApisName] = useState([]);
  const handelApisName = (e, name) => {
    let array = [...apisName];
    if (e.target.checked) {
      array.push(name);
    } else {
      let index = array.indexOf(name);
      if (index > -1) {
        array.splice(index, 1);
      }
    }
    setApisName(array);
  }

  return (
    <div>
      <div className="hotels_search_box buses-panel-align-bd">
        <div className="hotels-wrapper">
          <Form
            form={form}
            className="hotel-search-form  bus-panel-bg"
            name="busForm"
            validateMessages={validateMessages}
            onFinish={submitForm}
            layout="vertical"
            initialValues={{ filterOptions: "", apisOptions: "" }}
          >
            <Row className="bus-search-form" gutter={16}>
              <Col md={8} xs={24} className="from-to-inputs bus-cols-info">
                <BusesAutoSelect
                  formItemProps={{
                    label: "Source",
                    name: "sourceId",
                    rules: [{ required: true }],
                  }}
                  selectProps={{
                    size: "medium",
                    placeholder: "Enter Source Name",
                   }}
                  api={"cdn/citiesMatched/"}
                  refName={city}
                  focusRef={tocityBox}
                  handleOnSubmit={handleOnSubmit}
                  modifySearch={modifySearch ? modifySearch : false}
                />
              </Col>
              <Col md={8} xs={24} className="from-to-inputs bus-cols-info">
                <BusesAutoSelect
                  formItemProps={{
                    label: "Destination",
                    name: "destinationId",
                    rules: [{ required: true }],
                  }}
                  selectProps={{
                    size: "medium",
                    placeholder: "Enter Destination Name",
                   }}
                  api={"cdn/citiesMatched/"}
                  refName={tocityBox}
                  focusRef={fromDateBox}
                  handleOnSubmit={handleOnSubmit}
                  modifySearch={modifySearch ? modifySearch : false}
                />
              </Col>
              <Col md={4} xs={24} className="from-to-inputs bus-cols-info">
                <Form.Item name="journeyDate" label="Journey Date" rules={[{ required: true }]}>
                  <DatePicker
                    allowClear={false}
                    size="medium"
                    className="search-inputs from-return-date"
                    placeholder="Journey Date"
                    
                    ref={fromDateBox}
                    format={dateFormat}
                    open={showDate}
                    disabledDate={disabledOriginDate}
                    onOpenChange={() => {
                      setShowDate((prev) => !prev);
                    }}
                  />
                </Form.Item>
              </Col>
              {/* <Radio.Group onChange={selectscreen} value={screen} className="radio-button-margin">
                <Radio value={1}>OLD</Radio>
                <Radio value={2}>NEW</Radio>
              </Radio.Group> */}

              <Col md={4} xs={24} className="bus-cols-info">
                <Form.Item className="mr-none">
                  <Button
                    size="large"
                    className="primary-btn train-search-btn bus-btn-pri"
                    ref={searchBtn}
                    htmlType="submit"
                  >
                    <SearchOutlined /> Search
                  </Button>
                </Form.Item>
              </Col>
            </Row>






            <Collapse className="collapse-margin"  >
              <Panel header="More Options" key="1" >
                <Form.Item name="filterOptions" label="" className="check-margin">
                  <CheckboxGroup className="css-for-row">
                    <Row>
                      {filterOptions.length > 0
                        ? filterOptions.map((item) => {
                          return (
                            <Col
                              key={item.value}
                              // md={3}
                              sm={8}
                              xs={24}
                              className="rows-of-more"
                            >
                              <Checkbox
                                className="checking"
                                value={item.value}
                                onChange={(e) =>
                                  handleFilterName(e, item.name)
                                }
                              >
                                {item.name}
                              </Checkbox>
                            </Col>
                          );
                        })
                        : null}
                    </Row>
                  </CheckboxGroup>
                </Form.Item>


                {user?.role?.toUpperCase() == "ADMIN" && (
                  <div className="label-panel-data">
                    <Form.Item name="apisOptions" label=" " className="check-margin">
                      <CheckboxGroup className="check-box-check">
                        <Row>
                          {apisOptions.length > 0
                            ? apisOptions.map((item) => {
                              return (
                                <Col
                                  key={item.value}
                                  //  md={3}
                                  //  sm={8}
                                  //  xs={24}
                                  className="rows-of-more2"
                                >
                                  <Checkbox
                                    value={item.value}
                                    onChange={(e) =>
                                      handelApisName(e, item.name)
                                    }
                                  >
                                    {item.name}
                                  </Checkbox>
                                </Col>
                              );
                            })
                            : null}
                        </Row>
                      </CheckboxGroup>
                    </Form.Item>
                  </div>
                )}
              </Panel>

            </Collapse>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Buses;
