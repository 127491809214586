import React, { useState, useEffect } from "react";
import { Radio } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

const BusSort = (props) => {
  const { busSearchResultObj } = props.gscontext.state;
  const { updateBusSearchResultObj } = props.gscontext;
  const [sorting, setSorting] = useState("price");
  const [sortDirection, setSortDirection] = useState("asc");
  const handleChange = (e) => {
    const selSorting = e.target.value;
    setSorting(selSorting);
    setSortDirection("asc");
    sortBuses(selSorting, "asc");
  };

  const handleClick = (e) => {
    const selSorting = e.target.value;
    if (sorting === selSorting) {
      let dir = sortDirection === "asc" ? "desc" : "asc";
      sortBuses(selSorting, dir);
      setSortDirection(dir);
    }
  };

  useEffect(() => {
    sortBuses("price", "asc");
  }, [busSearchResultObj.tripType]);

  const getSortIcon = (val) => {
    return val === "asc" ? <ArrowUpOutlined /> : <ArrowDownOutlined />;
  };

  const sortBuses = (selSorting, dir) => {
    if (Object.keys(busSearchResultObj).length > 0) {

      if (!busSearchResultObj.data.trips) return;
      let filterTrips = busSearchResultObj.data.trips.sort((busA, busB) => {
        if (selSorting === "price") {
          return dir === "asc"
            ? compareBusesPrice(busA, busB)
            : compareBusesPrice(busB, busA);
        } else if (selSorting === "duration") {
          const airlineA = busA.duration.split(":");
          const airlineB = busB.duration.split(":");
          return dir === "asc"
            ? compareBusesDur(airlineA, airlineB)
            : compareBusesDur(airlineB, airlineA);
        } else {
          const airlineA = busA[selSorting];
          const airlineB = busB[selSorting];
          return dir === "asc"
            ? compareBuses(airlineA, airlineB)
            : compareBuses(airlineB, airlineA);
        }
      });
      updateBusSearchResultObj({
        ...busSearchResultObj,
        data: {
          ...busSearchResultObj.data,
          trips: [...filterTrips],
        },
      });

    }
  };

  const compareBusesPrice = (A, B) => {
    let a = parseFloat(A.startingFare.split("/")[0]);
    let b = parseFloat(B.startingFare.split("/")[0]);

    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    if (a == b) {
      const airlineA = A.duration.split(":");
      const airlineB = B.duration.split(":");
      return compareBusesDur(airlineA, airlineB);
    }
    return 0;
  };

  const compareBusesDur = (A, B) => {
    let a = Number(A[0]);
    let b = Number(B[0]);

    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    if (a == b) {
      let mA = A[1].split(" ");
      let mB = B[1].split(" ");

      if (Number(mA[0]) < Number(mB[0])) {
        return -1;
      }
      if (Number(mA[0]) > Number(mB[0])) {
        return 1;
      }
    }
    return 0;
  };
  const compareBuses = (a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  return (
    <div className="sort-block  buses-all-panel-sort">
      <div className="results-sort-block ">
        <p className="sort-header">Sort by: </p>{" "}
        <Radio.Group onChange={handleChange} value={sorting}>
          <Radio value={"price"} onClick={handleClick}>
            <span className="checkmark">
              <div className="active-background">
                <p className="price-type">
                  Fare
                  {sorting === "price" ? getSortIcon(sortDirection) : ""}
                </p>
              </div>
            </span>
          </Radio>
          <Radio value={"departureTime"} onClick={handleClick}>
            <span className="checkmark">
              <div className="active-background">
                <p className="price-type">
                  Departure
                  {sorting === "departureTime"
                    ? getSortIcon(sortDirection)
                    : ""}
                </p>
              </div>
            </span>
          </Radio>
          <Radio value={"arrivalTime"} onClick={handleClick}>
            <span className="checkmark">
              <div className="active-background">
                <p className="price-type">
                  Arrival
                  {sorting === "arrivalTime" ? getSortIcon(sortDirection) : ""}
                </p>
              </div>
            </span>
          </Radio>

          <Radio value={"duration"} onClick={handleClick}>
            <span className="checkmark">
              <div className="active-background">
                <p className="price-type">
                  Duration
                  {sorting === "duration" ? getSortIcon(sortDirection) : ""}
                </p>
              </div>
            </span>
          </Radio>
        </Radio.Group>
      </div>
    </div>
  );
};

export default BusSort;
