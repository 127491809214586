import React from "react";
import { Button, Result } from "antd";
import { Link } from "react-router-dom";
import "./unauth.scss";
const CustomNoResultFound = ({ title }) => {
  return (
    <div className="error-404">
      <Result status="500" title={title} subTitle="" />
    </div>
  );
};

export default CustomNoResultFound;
