import React, { useState, useCallback } from "react";
import { Spin, Select } from "antd";
import APIClient from "../../helpers/ApiClient";
import { Form } from "antd";

const AutoCompleteSelect = (props) => {
  const { Option } = Select;
  const [details, setDetails] = useState({
    data: [],
    value: [],
    fetching: false,
  });
  const debounceOnChange = useCallback(debounce(fetchData, 800), []);
  function fetchData(value) {
    setDetails({ data: [], fetching: true });
    APIClient.get(`${props.api}${value}`)
      .then((res) => {
        if (res.isSuccess && res) {
          setDetails({ data: res.data, fetching: false });
          return;
        }
        setDetails({ data: [], fetching: false });
      })
      .catch((error) => {
        console.error(error);
        setDetails({ data: [], fetching: false });
      });
  }

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }
  return (
    <Form.Item {...props.formItemProps}>
      <Select
        autocomplete="newpassword"
        showSearch
        // value={details.value}
        notFoundContent={
          details.fetching ? <Spin size="small" /> : "No Match Found"
        }
        filterOption={false}
        onSearch={debounceOnChange}
        onChange={props.onChange}
        {...props.selectProps}
      >
        {details.data.map((d, index) => (
          <Option value={d[props.keyvalue]} key={"key" + index}>
            {d[props.keytext]}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default AutoCompleteSelect;
