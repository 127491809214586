import React, { useState } from "react";
import {
  Button,
  Card,
  Layout,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Table,
} from "antd";
import excel from "../assets/vector-icons/excel.png";
import exchange from "../assets/vector-icons/exchange.png";
import pdf from "../assets/vector-icons/pdf.png";
import search from "../assets/vector-icons/search.png";

const DBLogs = () => {
  const { Content } = Layout;

  const [showTable, setShowTable] = useState(false);
  const { Option } = Select;

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  const dataSource = [
    {
      sno: "1",
      errorNumber: 515,
      logDate: "18-08-2020, 04:43 PM",
      errorMessage:
        "	Cannot insert the value NULL into column 'Balance', table 'ABCD.dbo.",
      errorProcedure: "uspUpdateTaxes",
      errorLine: "18",
      errorState: "2",
      errorSeverity: "16",
    },
    {
      sno: "2",
      errorNumber: 515,
      logDate: "18-11-2020, 04:43 PM",
      errorMessage:
        "	Cannot insert the value NULL into column 'Balance', table 'ABCD.dbo.",
      errorProcedure: "uspUpdateTaxes",
      errorLine: "18",
      errorState: "2",
      errorSeverity: "16",
    },
    {
      sno: "3",
      errorNumber: 512,
      logDate: "18-08-2020, 04:43 PM",
      errorMessage:
        "	Cannot insert the value NULL into column 'Balance', table 'ABCD.dbo.",
      errorProcedure: "uspUpdateTaxes",
      errorLine: "43",
      errorState: "2",
      errorSeverity: "16",
    },
  ];

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
    },
    {
      title: "Error Number",
      dataIndex: "errorNumber",
    },
    {
      title: "Log Date",
      dataIndex: "logDate",
    },
    {
      title: "Error Message",
      dataIndex: "errorMessage",
    },
    {
      title: "Error Procedure",
      dataIndex: "errorProcedure",
    },
    {
      title: "Error Line",
      dataIndex: "errorLine",
    },
    {
      title: "Error State",
      dataIndex: "errorState",
    },
    {
      title: "Error Severity",
      dataIndex: "errorSeverity",
    },
  ];

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <Layout>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <h5>DB Logs</h5>
                  </div>
                  &nbsp;
                  <Form {...formItemLayout} layout="vertical">
                    <Row gutter={16}>
                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="Time and Date">
                          <Select
 autocomplete="newpassword" 
                            placeholder="Please select"
                            onChange={onChange}
                          >
                            <Option value="0">Last 1 hour </Option>
                            <Option value="1">Today</Option>
                            <Option value="2">Yesterday</Option>
                            <Option value="3">Last 1 month</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="From Date">
                          <DatePicker
                            style={{ width: "100%" }}
                            placeholder="Select From date"
                          />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="To Date">
                          <DatePicker
                            style={{ width: "100%" }}
                            placeholder="Select To date"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className="reports-btns">
                      <Button
                        type="primary"
                        onClick={(e) => setShowTable(true)}
                      >
                        Search
                      </Button>
                      <Button
                        type="danger"
                        onClick={(e) => setShowTable(false)}
                      >
                        Reset
                      </Button>
                    </Row>
                  </Form>
                </Card>
              </div>
            </Col>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <h5>View DB Logs</h5>
                      <p>3 rows found !</p>
                    </div>
                    <div className="action-images">
                      <img src={excel} alt="excel" />
                      <img src={pdf} alt="pdf" />
                      <img src={search} alt="search" />
                      <img src={exchange} alt="exchange" />
                    </div>
                  </div>

                  {showTable && (
                    <Table
                      bordered
                      scroll={{ x: true }}
                      dataSource={dataSource}
                      columns={columns}
                      pagination={{ pageSize: 50 }}
                    />
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>
    </Layout>
  );
};

export default DBLogs;
