import moment from "moment";
const log_name = "travel";
export const createReportFromApiReponse = (bookings) => {
  let response = {
    flights: [],
    buses: [],
  };
  bookings.forEach((booking) => {
    booking.month = moment(booking.bookingDate).month();
    booking.year = moment(booking.bookingDate).year();
    booking.formatedDate = moment(booking.bookingDate, "YYYY-MM-DD").format("DD-MM-YYYY");
    if (booking.type == "Bus") {
      response.buses.push(booking);
    } else {
      response.flights.push(booking);
    }
  });

  return response;
};
