import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { Spin } from "antd";  // Ant Design spinner
import ApiClient from "./ApiClient";
import { useAuthContext } from "../common/providers/AuthProvider";
import "../admin/Siteadmin/Siteadmin.scss";

const PgReturnUrl = () => {
  const history = useHistory();
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const url = queryString.parse(window.location.search);

    const fetchData = async () => {
      if (url.order_id) {
        const req = {
          userid: user.id,
          order_id: url.order_id,
        };

        try {
          const res = await ApiClient.post(`account/GetPgStatus`, req);
          // console.log("Response:", res);
          setLoading(false);
          if (res.isSuccess) {
            history.push("/admin/siteadmins-deposit?Status=Success");
          } else {
            history.push("/admin/siteadmins-deposit?Status=Failed");
          }
        } catch (error) {
          console.error("Error fetching payment status:", error);
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [user.id, history]);

  if (loading) {
    return (
      <div className="loader-container">
        <Spin size="large" /> {/* Ant Design spinner */}
      </div>
    );
  }

  return null; // No UI needed after redirection
};

export default PgReturnUrl;
