import React, { useState, useEffect } from "react";
import { Button, Row, Card, Checkbox, Col, Form, message, Spin, Modal,Space,Statistic } from "antd";
import FareDetails, { getBusGrandTotal } from "../Checkout/FareDetails";
import { useHistory } from "react-router-dom";
import BusDetails from "../Checkout/BusDetails";
import ApiClient from "../../../helpers/ApiClient";
import PaymentGateway from "../../../helpers/PaymentGateway";
import { LoadingOutlined } from "@ant-design/icons";
import "../Checkout/Checkout.scss";

const { Countdown } = Statistic;
const BusReview = (props) => {

  let history = useHistory();
  const [form] = Form.useForm();
  const { state } = props.gscontext;
  const blockRequestData = state.busPassengerData;
  const { selectedBusData } = state;
  const { promoData, ConvFee } = state.otherData;
  const [passengersData, setPassengersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let blockRespObjArray = [];
  useEffect(() => {
    if (blockRequestData.length > 0) {
      blockRequestData.map((paxData) => {
        if (paxData.passengerDetails) {
          let buildDataForPassenger = paxData.passengerDetails.map((pData) => ({
            Title: pData.title,
            Age: pData.age,
            Name: pData.name,
            Gender: pData.gender,
            SeatNo: pData.seatNo,
            EmailId: blockRequestData.emailId,
            MobileNo: blockRequestData.mobileNo,
          }));
          setPassengersData([...passengersData, ...buildDataForPassenger]);

        }
      });
    } else {
      history.go(-1);
    }
  }, [blockRequestData]);

  const busBlocking = async (blockRequestDataObj, type) => {
    setIsLoading(true);
    blockRequestDataObj.promoData = {
      ...promoData,
      tripType: state.busData.type,
    };
    blockRequestDataObj.convienenceData = ConvFee;
    await ApiClient.post("buses/blockticket", blockRequestDataObj)
      .then((result) => {
        return result;
      })
      .then(async (resp) => {
        setIsLoading(false);
        if (resp.statusCode === 200) {
          if (resp.data.BookingStatus === 1) {
            await blockRespObjArray.push(resp.data);

            if (state.busData.type === "twoWay" && type === "1") {
              return;
            }

            processPayment(resp.data.BookingReferenceNo);
          } else {
            message.error("Unable To Book Ticket: " + resp.data.Message);
          }
        } else {
          message.error("Server Error: " + resp.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const goTo = () => {
    form
      .validateFields()
      .then(async (data) => {
        await busBlocking(blockRequestData[0], "1");
        if (state.busData.type === "twoWay") {
          blockRequestData[1].passengerDetails = blockRequestData[1].passengerDetails.map(
            (detail, i) => ({
              ...detail,
              name: blockRequestData[0].passengerDetails[i].name,
              age: blockRequestData[0].passengerDetails[i].age,
              gender: blockRequestData[0].passengerDetails[i].gender,
              title: blockRequestData[0].passengerDetails[i].title,
            })
          );
          await busBlocking(blockRequestData[1], "2");
        }

      })
      .catch((e) => { });
  };

  const [pgDisplay, setPgDisplay] = useState(false);
  const [pgData, setPgData] = useState({});

  const processPayment = async (oid) => {


    if (state.busData.type != "oneWay") {
      if (state.busData.type === "twoWay" && blockRespObjArray.length == 2) {
        localStorage.setItem(
          "twoWayBusData",
          JSON.stringify(blockRespObjArray)
        );

      } else {
        message.error("Unable To Book Ticket");
        return;
      }
    } else {
      localStorage.removeItem("twoWayBusData");
    }

    let { totalFareCalculations } = getBusGrandTotal(
      selectedBusData,
      ConvFee,
      promoData
    );

    let data = {
      OrderId: oid,
      OrderAmount: totalFareCalculations,
      OrderCurrency: "INR",
      OrderNote: "BUS BOOKING",
      CustomerName: passengersData[0].Name,
      CustomerEmail: blockRequestData[0].emailId,
      CustomerPhone: blockRequestData[0].mobileNo,
    };
    // set PgData and Display


    setPgDisplay(true);
    setPgData(data);
    blockRespObjArray = [];
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );

  const targetTime = new Date().getTime() + 420000;

  const handelCountdown = () => {
    Modal.warning({
      title: 'Session Expired',
      content: 'Your Booking Session Expired Please Try Again',
      onOk() { history.push("/");}
    });
  }

  return (
    <div className="buscheckout_bg">
      <div className="buscheckout_wrapper">
        <Row gutter={[16, 16]}>
          <Col md={24} sm={24} xs={24}>
          <Countdown className="busCountdown"  onFinish={handelCountdown} value={targetTime} format="mm:ss" />
            <Form form={form} layout="vertical">
              <h3 className="title">Bus Details</h3>
              <Row gutter={[16, 16]}>
                <Col md={17} sm={16} xs={24}>
                  <div className="left_wrapper">
                    <BusDetails />
                  </div>
                </Col>

                <Col md={7} sm={8} xs={24}>
                  <div className="right_wrapper">
                    <FareDetails
                      selectedBusData={selectedBusData}
                      isPromoVisible={true}
                    />
                  </div>
                  {pgDisplay == false ? (
                    <Card>
                      <div className="btn_wrapper">
                        <Button
                          type="primary"
                          onClick={() => {
                            goTo();
                          }}
                        >
                          {isLoading ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            "Book and Pay "
                          )}
                        </Button>
                      </div>
                    </Card>
                  ) : (
                    <PaymentGateway data={pgData} />
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default BusReview;
