import React, { useState, useEffect } from "react";
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    Modal,
    message,
    Popconfirm,
    Row,
    Table,
    Select,
    Collapse,
    Tooltip,
    Switch,
    Spin,
} from "antd";
import {
    PlusCircleFilled,
    EditOutlined,
    DeleteOutlined,
    CloseOutlined,
    CaretRightOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import { exportExcel } from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
import { useAuthContext } from "../../common/providers/AuthProvider";
const { Content } = Layout;
const { Panel } = Collapse;

const SupplierBalances = () => {
    const { user } = useAuthContext();
    const { Option } = Select;
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [suppliersList, setSuppliersList] = useState([]);
    const [tableList, setTableList] = useState([]);
    const [tableListBal, setTableListBal] = useState([]);
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [currentId, setCurrentId] = useState(-1);
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [activePanel, setActivePanel] = useState(["1"]);
    const [showBSearchBox, setShowBSearchBox] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        // getSuppliersList();

        getSupplierBal();

    }, []);

   

   

    const handleModal = () => {
        form.resetFields();
        if (showUpdateBtn) {
            setShowUpdateBtn(false);
            form.resetFields();
        }
        setModalVisible(true);
    };

    // const deleteSuppliers = (Record) => {
    //     let Id = Record.id;
    //     ApiClient.delete("supplier/deletesupplier/" + Id)
    //         .then((res) => {
    //             if (res.isSuccess) {
    //                 message.success("Suppliers Deleted  successfully", 3);
    //                 getSuppliersList();
    //             }
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // };
    // const updateSuppliers = (val, id = null) => {
    //     id = id == null ? currentId.id : id
    //     ApiClient.put(`supplier/updatesupplier/${id}`, {}, val).then(
    //         (resp) => {
    //             if (resp.isSuccess) {
    //                 getSuppliersList();
    //                 setModalVisible(false);
    //                 message.success("Suppliers Updated successfully", 3);
    //                 setCurrentId(-1);
    //             }
    //         }
    //     );
    // };

  



    const editCurrentItem = (id, data) => {
        setShowUpdateBtn(true);
        setCurrentId(id);
        form.setFieldsValue({
            ...data,
        });
        setModalVisible(true);
    };

    let lastIndex = 0;
    const uniqueKey = () => {
        return lastIndex++;
    };



    const searchData = () => {
        setShowSearchBox(true);
    };

    
    const columnsBal = [
        {
            title: "Sr. No.",
            dataIndex: "SNo",
            sorter: (a, b) => a.SNo - b.SNo,
        },
        {
            title: "Provider Name",
            dataIndex: "providerName",
            sorter: (a, b) => a.providerName.localeCompare(b.providerName),
        },
        {
            title: "Api Balance",
            dataIndex: "apiBalance",
            sorter: (a, b) => a.apiBalance.localeCompare(b.apiBalance),
            render: (text) => parseFloat(text).toFixed(2),
        },

    ];

    const searchTableData = (e) => {
        var searchVal = e.target.value;
        if (searchVal.length > 0) {
            const searchResults = tableList.filter(function (item) {
                if (item != undefined) {
                    return JSON.stringify(item)
                        .toLowerCase()
                        .includes(searchVal.toLowerCase());
                }
            });
            setTableList(searchResults);
        } else {
            setTableList(suppliersList);
        }
    };

    const closeSearch = () => {
        setShowSearchBox(false);
        setTableList(suppliersList);
    };

    const closeSearchInput = <CloseOutlined onClick={closeSearch} />;


    const handleExcelBal = () => {
        let excelData = tableListBal;
        excelData = excelData.map((item, i) => ({
            SNo: i + 1,
            Operator: item.providerName,
            ApiBalance: item.apiBalance,
        }));
        exportExcel(excelData, "Suppliers");
    };
    const getSupplierBal = () => {
        setIsLoading(true);
        // console.log("iam not comming");
        ApiClient.get("supplier/getsupplierbalance")
            .then((res) => {
                // console.log(res, "resp");
                if (res.isSuccess) {
                    let data = res.data.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                        };
                    });
                    setIsLoading(false);
                    setTableListBal(data);

                }
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    };

    return (
        <div className="outer-background-all">
            <div className="manage-markup-section">
                <Content className="container-fluid">
                    <Row>
                        <Col span={24}>
                            <div className="card-bt-gap">
                                <Collapse
                                    bordered={false}
                                    expandIcon={({ isActive }) => (
                                        <Tooltip placement="top" title={isActive ? "Show" : "Hide"}>
                                            <CaretRightOutlined rotate={isActive ? 90 : -90} />
                                        </Tooltip>
                                    )}
                                    expandIconPosition="right"
                                    className="panel_wrapper"
                                    defaultActiveKey={["1"]}
                                    activeKey={activePanel}
                                    onChange={(arrKeys) => {
                                        if (arrKeys.length > 1) {
                                            setActivePanel(arrKeys[arrKeys.length - 1]);
                                        } else {
                                            setActivePanel(arrKeys);
                                        }
                                    }}
                                >

                                    <Panel header="View Supplier Balances" key="1" style={{ borderBottom: "1px solid grey", boxShadow: "0px 2px 8px grey", borderRadius: "6px" }}>
                                        <div className="card-bt-gap">
                                            <Card bordered={false} style={{ borderRadius: "25px" }}>
                                                {/* <div className="card-add-heading"> */}

                                                {isLoading ? (<>
                                                    <Spin
                                                        size="large"
                                                        tip="Loading..."
                                                        style={{ width: "100%" }}
                                                    />
                                                </>) : tableListBal.length > 0 ? (
                                                    <>
                                                        {/* <div className="card-add-heading"> */}
                                                        {/* < div className="rows-count">
                              <h5>View Balances Reports</h5>
                            </div> */}
                                                        <p>{tableListBal.length} rows found !</p>
                                                        <div className="action-images">
                                                            {showBSearchBox && (
                                                                <Input
                                                                    autocomplete="newpassword"
                                                                    placeholder="Search"
                                                                    onChange={(e) => searchTableData(e)}
                                                                    suffix={closeSearchInput}
                                                                    style={{
                                                                        padding: "0px 12px",
                                                                        maxWidth: "250px",
                                                                    }}
                                                                />
                                                            )}
                                                            &nbsp;&nbsp;
                                                            {/* <img
                                src={search}
                                alt="search"
                                onClick={() => searchData()}
                              /> */}
                                                            <img
                                                                src={excel}
                                                                alt="excel"
                                                                onClick={() => handleExcelBal()}
                                                            />
                                                            <img
                                                                src={exchange}
                                                                alt="exchange"
                                                                onClick={() => {
                                                                    setTableList([]);
                                                                    getSupplierBal();
                                                                }}
                                                            />
                                                            {/* <p className="add-deposit-icon" onClick={handleModal}>
                    <PlusCircleFilled />
                  </p> */}
                                                        </div>
                                                        {/* </div> */}

                                                        <Table
                                                            scroll={{ x: true }}
                                                            bordered
                                                            rowKey={uniqueKey}
                                                            dataSource={tableListBal}
                                                            columns={columnsBal}
                                                            pagination={{
                                                                defaultPageSize: 25,
                                                                showSizeChanger: true,
                                                                pageSizeOptions: ["25", "50", "100", "125"],
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    <b>No Results Found!</b>
                                                )}
                                                {/* </div> */}
                                            </Card>
                                        </div>
                                    </Panel>
                                </Collapse>
                            </div>

                        </Col>
                    </Row >
                </Content >
            </div >
          
        </div >
    );
};
export default SupplierBalances;
