import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Modal,
  Radio,
  Row,
  Select,
  TimePicker,
} from "antd";
import React, { useContext, useState } from "react";
import { TgContext } from "../../common/providers/TgProvider";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
import APIClient from "../../helpers/ApiClient";
import {
  CheckCircleOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  MailOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

import { OnlyFutureDateValidator } from "../../helpers/CustomValidators";
import "./FlightEnquiry.scss";

const FlightEnquiry = () => {
  const { Option } = Select;
  const [isReturn, setIsReturn] = useState(null);
  const [form] = Form.useForm();
  const dateFormat = "DD-MM-YYYY";
  const timeFormat = "HH:mm";
  const [visible, setVisible] = useState(false);
  const { socialLinks } = useContext(TgContext);
  const validateMessages = {
    required: "",
  };

  const submitForm = (value) => {
    if (isReturn == null) {
      message("Select one Way or Return option", 3);
      return;
    }
    APIClient.post("admin/flightEnquiry", value)
      .then((response) => {

        if (response.status == 200) {
          setVisible(true);
          form.resetFields();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleIsReturn = ({ value }) => {
    if (value == "true") setIsReturn(true);
    else setIsReturn(false);
  };
  const handleOk = () => {
    setVisible(false);
  };
  return (
    <Layout>
      <div className="manage-markup-section flighteq">
        <Layout.Content className="container">
          <Form
            layout="vertical"
            form={form}
            onFinish={submitForm}
            validateMessages={validateMessages}
          >
            <Row gutter={24}>
              <Col md={24} xs={24}>
                <h2 className="common-headings">Flight Enquiry</h2>
              </Col>
            </Row>
            <Card className="cardRounded flight-enu">
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <Form.Item
                    label="Name"
                    name="Name"
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    <Input
                      autocomplete="newpassword" className="inputbg" />
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item
                    label="Email"
                    name="Email"
                    rules={[
                      { required: true, message: "Required!" },
                      { type: "email" },
                    ]}
                  >
                    <Input
                      autocomplete="newpassword" className="inputbg" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={12} xs={24}>
                  <Form.Item
                    label="Contact Number"
                    name="ContactNumber"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                      {
                        minLength: 10,
                        maxLength: 10,
                        pattern: "^[0-9]{10}$",
                        message: "Must be 10 digits",
                      },
                    ]}
                  >
                    <Input
                      autocomplete="newpassword" className="inputbg" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <div className="card-gap ">
              <Card className="flights-gap" bordered={false}>
                <Row gutter={[16, 8]}>
                  <Col md={24}>
                    <Form.Item
                      label="Trip Type"
                      name="TripType"
                      rules={[{ required: true, message: "Required!" }]}
                    >
                      <Radio.Group
                        onChange={({ target }) => handleIsReturn(target)}
                      >
                        <Radio value="false">One way</Radio>
                        <Radio value="true">Return</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col md={12} xs={24}>
                    <AutoCompleteSelect
                      formItemProps={{
                        label: "Source (IATA Code)",
                        name: "SourceCity",
                        rules: [{ required: true, message: "Required!" }],
                      }}
                      selectProps={{ placeholder: "Source (IATA Code)" }}
                      api={"flights/airPort/search/"}
                      keytext="nameAirport"
                      keyvalue="nameAirport"
                    />
                  </Col>
                  <Col md={12} xs={24}>
                    <AutoCompleteSelect
                      formItemProps={{
                        label: "Destination (IATA Code)",
                        name: "DestinationCity",
                        rules: [{ required: true, message: "Required!" }],
                      }}
                      selectProps={{ placeholder: "Destination (IATA Code)" }}
                      api={"flights/airPort/search/"}
                      keytext="nameAirport"
                      keyvalue="nameAirport"
                    />
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item
                      label="Preferred Class"
                      name="PreferredClass"
                      rules={[{ required: true, message: "Required!" }]}
                    >
                      <Select
                        autocomplete="newpassword" placeholder="---Select---">
                        <Option value="premiumEconomy">Premium Economy</Option>
                        <Option value="Business">Business</Option>
                        <Option value="premiumFirst">Premium First</Option>
                        <Option value="Economy">Economy</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    {/* space */}
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item
                      label="Deperture Date"
                      name="DepartureDate"
                      rules={[
                        { required: true, message: "Required!" },
                        { validator: OnlyFutureDateValidator },
                      ]}
                    >
                      <DatePicker
                        className="inputbg"
                        style={{ width: "100%" }}
                        format={dateFormat}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Form.Item
                      label="Deperture Time"
                      name="DepartureTime"
                      rules={[{ required: true, message: "Required!" }]}
                    >
                      <TimePicker
                        className="inputbg"
                        popupClassName="hideOk"
                        style={{ width: "100%" }}
                        format={timeFormat}
                        placeholder="--:--"
                        showNow={false}
                        onSelect={(value) => {
                          form.setFieldsValue({
                            DepartureTime: value,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {isReturn ? (
                    <>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Return Date"
                          name="ReturnDate"
                          rules={[{ required: true, message: "Required!" }]}
                        >
                          <DatePicker
                            className="inputbg"
                            style={{ width: "100%" }}
                            format={dateFormat}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Return Time"
                          name="ReturnTime"
                          rules={[{ required: true, message: "Required!" }]}
                        >
                          <TimePicker
                            className="inputbg"
                            popupClassName="hideOk"
                            style={{ width: "100%" }}
                            format={timeFormat}
                            placeholder="--:--"
                            showNow={false}
                            onSelect={(value) => {
                              form.setFieldsValue({
                                ReturnTime: value,
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  ) : null}
                  <Col md={12} xs={24}>
                    <Form.Item
                      label="Flight Number"
                      name="OnwardFlightNumber"
                      rules={[{ required: true, message: "Required!" }]}
                    >
                      <Input
                        autocomplete="newpassword" className="inputbg" />
                    </Form.Item>
                  </Col>
                  {isReturn ? (
                    <Col md={12} xs={24}>
                      <Form.Item
                        label="Return Flight Number"
                        name="ReturnFlightNumber"
                        rules={[{ required: true, message: "Required!" }]}
                      >
                        <Input
                          autocomplete="newpassword" className="inputbg" />
                      </Form.Item>
                    </Col>
                  ) : null}

                  <Col md={12} xs={24}>
                    <AutoCompleteSelect
                      formItemProps={{
                        label: "Airline",
                        name: "OnwardFilghtName",
                        rules: [{ required: true, message: "Required!" }],
                      }}
                      selectProps={{ placeholder: "Search Airline Name" }}
                      api={"flights/airlineNames/search/"}
                      keytext="nameAirline"
                      keyvalue="nameAirline"
                    />
                  </Col>
                  {isReturn ? (
                    <Col md={12} xs={24}>
                      <AutoCompleteSelect
                        formItemProps={{
                          label: "Return Airline",
                          name: "ReturnFilghtName",
                          rules: [{ required: true, message: "Required!" }],
                        }}
                        selectProps={{ placeholder: "Search Airline Name" }}
                        api={"flights/airlineNames/search/"}
                        keytext="nameAirline"
                        keyvalue="nameAirline"
                      />
                    </Col>
                  ) : null}
                  <Col md={12} xs={24}>
                    <Form.Item
                      label="Number of Passengers"
                      name="NumberOfPassengers"
                      rules={[{ required: true, message: "Required!" }]}
                    >
                      <InputNumber
                        autocomplete="newpassword"
                        className="inputbg"
                        min={1}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} xs={24}>
                    <Form.Item
                      label="Remarks"
                      name="Remarks"
                      rules={[{ required: true, message: "Required!" }]}
                    >
                      <Input.TextArea
                        autocomplete="newpassword"
                        className="inputbg"
                        rows={3}
                        placeholder="....."
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="center" className="btn-colors">
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Row>
              </Card>
            </div>
          </Form>
        </Layout.Content>
      </div>
      <Modal
        visible={visible}
        title="Thank you for getting in touch!"
        onOk={handleOk}
        onCancel={handleOk}
        footer={[null]}
        className="successModalBox"
      >
        <Row justify="center">
          <Col>
            <div className="sucessModal">
              <CheckCircleOutlined className="modalIcon" />
              <h6>Your message has been successfully sent</h6>
              <p>
                We appreciate you submitting a contact form with us. Once we
                receive your
              </p>
              <p>
                information we'll respond as soon as we can. In the meantime,
                sit back, relax
              </p>
              <p>and enjoy the view</p>
            </div>
          </Col>
          <Col>
            <div className="socialLinks">
              <span className="sharetext">Share at</span>
              <span className="socialwrap">
                <a href={socialLinks.facebook} target="_blank">
                  <FacebookOutlined />
                </a>
              </span>
              <span className="socialwrap">
                <a href={socialLinks.twitter} target="_blank">
                  <TwitterOutlined />
                </a>
              </span>
              <span className="socialwrap">
                <a href={socialLinks.linkedin} target="_blank">
                  <LinkedinOutlined />
                </a>
              </span>
              <span className="socialwrap">
                <a href={socialLinks.GoogleUrl} target="_blank">
                  <MailOutlined />
                </a>
              </span>
            </div>
          </Col>
        </Row>
      </Modal>
    </Layout>
  );
};

export default FlightEnquiry;
