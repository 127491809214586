import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import '../BusSort/BusSort.scss';
 import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

const BusSort = ({ busSearchResultObj, setBusResultsList }) => {
  const [sorting, setSorting] = useState("price");
  const [sortDirection, setSortDirection] = useState("asc");
  const handleChange = (e) => {
    const selSorting = e.target.value;
    setSorting(selSorting);
    setSortDirection("asc");
    sortBuses(selSorting, "asc");
  };

  const handleClick = (e) => {
    const selSorting = e.target.value;
    if (sorting === selSorting) {
      let dir = sortDirection === "asc" ? "desc" : "asc";
      sortBuses(selSorting, dir);
      setSortDirection(dir);
    }
  };

  useEffect(() => {
    sortBuses("price", "asc");
  }, []);

  const sortBuses = (selSorting, dir) => {
    if (
      Object.keys(busSearchResultObj).length > 0 &&
      busSearchResultObj?.data
    ) {
      if (!busSearchResultObj.data.trips) return;
      let filterTrips = busSearchResultObj.data.trips.sort((busA, busB) => {
        if (selSorting === "price") {
          return dir === "asc"
            ? compareBusesPrice(busA, busB)
            : compareBusesPrice(busB, busA);
        } else if (selSorting === "duration") {
          const bus1 = busA.duration;
          const bus2 = busB.duration;
          return dir === "asc"
            ? compareBusesDur(bus1, bus2)
            : compareBusesDur(bus2, bus1);
        } else {
          const bus1 = busA[selSorting];
          const bus2 = busB[selSorting];
          return dir === "asc"
            ? compareBuses(bus1, bus2)
            : compareBuses(bus2, bus1);
        }
      });
      setBusResultsList({
        ...busSearchResultObj.data,
        trips: [...filterTrips],
      });
    }
  };

  const compareBusesPrice = (A, B) => {
    let a = parseFloat(A.fares[0].total);
    let b = parseFloat(B.fares[0].total);

    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    if (a == b) {
      const bus1 = A.duration;
      const bus2 = B.duration;
      return compareBusesDur(bus1, bus2);
    }
    return 0;
  };

  const compareBusesDur = (A, B) => {
    let a = Number(A);
    let b = Number(B);

    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };
  const compareBuses = (a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  const getSortIcon = (val) => {
    return val === "asc" ? (
      <CaretUpOutlined className="mr-2" />
    ) : (
      <CaretDownOutlined className="mr-2" />
    );
  };

  return (
    <div className="sort-block sort-bus-block-22 CHECK">
         
        <ANTD.Radio.Group onChange={handleChange} value={sorting}>
          <ANTD.Radio value={"price"} onClick={handleClick}>
            <span className="checkmark">
              <div className="active-background">
                <p className="price-type"> 
                 Fare {sorting === "price" ? getSortIcon(sortDirection) : ""}
                </p>
              </div>
            </span>
          </ANTD.Radio>
          <ANTD.Radio value={"timeD"} onClick={handleClick}>
            <span className="checkmark">
              <div className="active-background">
                <p className="price-type">
                  Departure{" "}
                  {sorting === "timeD" ? getSortIcon(sortDirection) : ""}
                </p>
              </div>
            </span>
          </ANTD.Radio>
          <ANTD.Radio value={"timeA"} onClick={handleClick}>
            <span className="checkmark">
              <div className="active-background">
                <p className="price-type">
                  Arrival{" "}
                  {sorting === "timeA" ? getSortIcon(sortDirection) : ""}
                </p>
              </div>
            </span>
          </ANTD.Radio>

          <ANTD.Radio value={"duration"} onClick={handleClick}>
            <span className="checkmark">
              <div className="active-background">
                <p className="price-type">
                  Duration{" "}
                  {sorting === "duration" ? getSortIcon(sortDirection) : ""}
                </p>
              </div>
            </span>
          </ANTD.Radio>
        </ANTD.Radio.Group>
     </div>
  );
};

export default BusSort;
