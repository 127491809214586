//#region lates code
import React, { useContext, useEffect, useState } from "react";
import "./FareDetails.scss";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Radio,
  Skeleton,
  message,
} from "antd";
import moment from "moment";
import { GlobalStatesContext } from "../../../common/providers";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { useAuthContext } from "../../../common/providers/AuthProvider";

import APIClient from "../../../helpers/ApiClient";

import { CloseOutlined } from "@ant-design/icons";

export const getBusGrandTotal = (selectedBusData, ConvFee, promoData) => {
  let fareInfoData =
    selectedBusData.length > 0
      ? selectedBusData.map((selectBusObj) => selectBusObj.fareInfo)
      : null;

  fareInfoData = fareInfoData.filter((i) => i != undefined);

  let totalBaseFare = fareInfoData.reduce(
    (acc, value) => Number(value.baseFare) + Number(acc),
    0
  );

  let totalGst = fareInfoData.reduce(
    (acc, value) => Number(value.gst) + Number(acc),
    0
  );

  let totalCommission = fareInfoData.reduce(
    (acc, value) => Number(value.commission) + Number(acc),
    0
  );

  let total = Number(totalBaseFare) + Number(totalGst);
  let convamount = 0;

  let discount = Number(promoData.Discount);

  if (ConvFee.type == 1) {
    convamount = Number(ConvFee.amount);
  } else {
    convamount = Number(
      (total / 100) * Number(ConvFee.amount ? ConvFee.amount : 0)
    );
  }
  total = total + Number(convamount);

  let totalFareCalculations = Number(total);
  totalFareCalculations = Number(
    totalFareCalculations - Number(discount)
  ).toFixed(2);
  // console.log(totalFareCalculations, "totalFareCalculations");
  return {
    totalBaseFare: Number(totalBaseFare).toFixed(2),
    totalGst: Number(totalGst).toFixed(2),
    discount,
    totalFareCalculations,
    totalCommission: totalCommission ? Number(totalCommission) : 0,
  };
};

const FareDetails = ({ selectedBusData, isPromoVisible }) => {
  const [form] = Form.useForm();
  // const {
  //   isLogin: { agent },
  //   user,
  // } = useAuthContext();
  const {
    state: {
      otherData: { ConvFee, promoData },
    },
    AddConvFee,
    AddPromo,
    RemovePromoConvFee,
  } = useContext(GlobalStatesContext);
  // const { activeCurrency, currencyValue } = useCurrencyContext();
  const [promoCode, setPromoCode] = useState([]);
  let dateFormat = "YYYY-MM-DD";
  let { totalBaseFare, totalGst, discount, totalFareCalculations } =
    getBusGrandTotal(selectedBusData, ConvFee, promoData);

  useEffect(() => {
    AddConvFee(3);
    getpromo();
  }, []);

  const getpromo = () => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) =>
            moment(item.ValidTill, dateFormat).isSameOrAfter(moment())
          );
          if (data.length > 0) {
            let busPromo = data.filter(
              (promo) =>
                promo.ServiceType === 1 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );

            setPromoCode(busPromo);
          }
        }
      })
      .catch((error) => {
        setPromoCode([]);

        console.error(error);
      });
  };

  const validatePromo = (code) => {
    APIClient.get("admin/validate/promo/" + code)
      .then((res) => {
        if (res.status === 200) {
          handleApply(code);
        }
        if (res.status === 400) {
          message.error(res.data);
        }
      })
      .catch((error) => {});
  };

  const handleApply = (code) => {
    form.setFieldsValue({
      promo: code,
    });

    form.submit();
  };

  return (
    <>
      <div className="busFareDetail_wrapper">
        <Card>
          <div className="price_wrapper">
            <p className="text">Base Fare</p>
            <p className="price">₹ {totalBaseFare ? totalBaseFare : ""}</p>
          </div>

          <div className="price_wrapper">
            <p className="text">GST Amount</p>
            <p className="price">₹ {totalGst ? totalGst : ""}</p>
          </div>

          <div className="price_wrapper">
            <p className="text">Discount</p>
            <p className="price">₹ {promoData.status ? discount : " 0.00"}</p>
          </div>

          <div className="price_wrapper">
            <p className="text">Convenience Fee</p>
            <p className="price">
              {" "}
              ₹ {ConvFee.type == 1 ? ConvFee.amount : ConvFee.amount + " %"}
            </p>
          </div>

          <div className="price_wrapper total_price">
            <p className="text">Total:</p>
            <p className="price">
              INR {totalFareCalculations ? totalFareCalculations : ""}
            </p>
          </div>
        </Card>
      </div>

      {/* <div className="buspromo_wrapper">
        {promoData.status == false ? (
          <Card>
            <p className="name">Apply Promo</p>

            <Form
              layout="vertical"
              form={form}
              onFinish={(d) => {
                AddPromo({
                  code: d.promo,
                  travelType: 1,
                });
              }}
            >
              <Row gutter={[16, 16]}>
                <Col md={16} sm={12} xs={12}>
                  <Form.Item
                    name="promo"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input
                      className="inputbg"
                      placeholder="Enter Your Promo code"
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col md={8} sm={12} xs={12}>
                  <Form.Item>
                    <Button type="primary" onClick={() => form.submit()}>
                      Apply
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            {promoCode.length ? (
              promoCode.map((item, key) => {
                return (
                  <>
                    <div className="promo-cp-coupons" key={key}>
                      <div className="inline-cp-promo">
                        <Form>
                          <Form.Item>
                            <Radio
                              onClick={() => validatePromo(item.Code)}
                            ></Radio>
                          </Form.Item>
                        </Form>
                        <p className="promo-key-cp">{item.Code}</p>
                        {item.DiscountType === 1 ? (
                          <p className="save-cp-offer">
                            Save {item.Discount + " %"}
                          </p>
                        ) : (
                          <p className="save-cp-offer">
                            Save INR &nbsp;
                            {item.Discount}
                          </p>
                        )}
                      </div>

                      <div className="promo-percentage-cp">
                        <p>{item.Description}</p>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <>
                <div className="promo-cp-coupons">
                  <div className="inline-cp-promo">
                    <p className="promo-key-cp">No Promo Code Available</p>
                  </div>
                </div>
              </>
            )}
          </Card>
        ) : (
          <Card className="flight-cards-details">
            <div className="promo-card-header">
              <p
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="name"
              >
                Promo Coupon{" "}
                <CloseOutlined
                  onClick={() => {
                    RemovePromoConvFee();
                    form.resetFields();
                  }}
                />{" "}
              </p>
            </div>
            <div className="promo-input">
              <p>
                <span className="applied"> {promoData.Code} </span> Promo Code
                Applied
              </p>
            </div>
          </Card>
        )}
      </div> */}
    </>
  );
};
export default FareDetails;
