import React from "react";
import { Card, Col, Row, Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import "./FlightsCheckout.scss";

const FareSummaryCard = (props) => {
  let searchValue = JSON.parse(localStorage.getItem("searchResp"));

  let fares = props.flightDetObj.fareFamilies[0].flightFares;
  let MarkUp = props.flightDetObj.fareFamilies[0].MarkUpamount
  const fareTotal = () => {
    let total = 0;
    let copyFares = fares;
    copyFares
      .filter((fareObj) => fareObj.fareTag === "Base")
      .forEach((paxFare) => {
        if (paxFare.paxType === "ADT") {
          total += paxFare.amount * searchValue.adultCount;
        } else if (paxFare.paxType === "CHD") {
          total += paxFare.amount * searchValue.childCount;
        } else {
          total += paxFare.amount * searchValue.infantCount;
        }
      });

    return Number(total + MarkUp).toFixed(2);
  };

  const paxFares = () => {
    return (
      <>
        {fares
          .filter((fareObj) => fareObj.fareTag === "Base")
          .map((paxFare, index) => (
            <div key={"paxList" + index} className="pax-count-acc-body">
              <div className="pax-type">
                <p>
                  {paxFare.paxType === "ADT" ? (
                    <>ADULT x {searchValue.adultCount}</>
                  ) : paxFare.paxType === "CHD" ? (
                    <>CHILD x {searchValue.childCount}</>
                  ) : (
                    <>INFANTx{searchValue.infantCount}</>
                  )}
                </p>
              </div>
              <div className="service-price">
                <p>
                  ₹{" "}
                  {paxFare.paxType === "ADT" ? (
                    <>
                      {Number(paxFare.amount * searchValue.adultCount).toFixed(
                        2
                      )}
                    </>
                  ) : paxFare.paxType === "CHD" ? (
                    <>
                      {Number(paxFare.amount * searchValue.childCount).toFixed(
                        2
                      )}
                    </>
                  ) : (
                    <>
                      {Number(paxFare.amount * searchValue.infantCount).toFixed(
                        2
                      )}
                    </>
                  )}
                </p>
              </div>
            </div>
          ))}
      </>
    );
  };

  // const taxCalculations = () => {
  //   let tax = 0;
  //   tax = fares
  //     .filter((fareObj) => fareObj.fareTag === "Tax")
  //     .reduce((acc, val) => acc + val.amount, 0);
  //   return Number(tax).toFixed(2);
  // };
  const taxCalculations = () => {
    let tax = 0;
    let copyFares = fares;
    copyFares
      .filter((fareObj) => fareObj.fareTag === "Tax")
      .forEach((paxFare,index) => {
        if (paxFare.paxType === "ADT") {
          tax += paxFare.amount * searchValue.adultCount;
        } else if (paxFare.paxType === "CHD") {
          tax += paxFare.amount * searchValue.childCount;
        } else {
          tax += paxFare.amount * searchValue.infantCount;
        }
      });

    return Number(tax).toFixed(2);
  };

  const flightTotalFare = () => {
    let base_total = fareTotal();
    let tax_total = taxCalculations();
    let total = parseFloat(base_total) + parseFloat(tax_total);

    return Number(total).toFixed(2);
  };

  return (
    <>
      <div className="sticky-card-container">
        <Card className="pax-count-card flight-cards-details">
          <p className="fareTitle">{props.title}</p>
          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Base Fare</p>
            </Col>
            <Col className="d-flex">
              <p>₹ {fareTotal()}</p>

              <Popover
                overlayClassName="pricepopup"
                placement="left"
                content={paxFares()}
                title=" Base Fare"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Col>
          </Row>
          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Taxes and Fees</p>
            </Col>
            <Col className="d-flex">
              <p>₹ {taxCalculations()}</p>
              <Popover
                overlayClassName="pricepopup"
                placement="left"
                content={
                  <div className="pax-count-acc-body">
                    <div className="pax-type">
                      <p>Service fee</p>
                    </div>

                    <div className="service-price">
                      <p>₹ {taxCalculations()}</p>
                    </div>
                  </div>
                }
                title="Taxes and Fees"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Col>
          </Row>

          <div className="pax-total-price">
            <div className="pax-type">
              <p>Total:</p>
            </div>
            <div className="total">
              <p>₹</p>
              &nbsp;
              <p className="amount">{flightTotalFare()}</p>
            </div>
          </div>
          <p className="all-taxes">Including all taxes and fees</p>
        </Card>
      </div>
    </>
  );
};

export default FareSummaryCard;
