import React, { useState, useEffect } from "react";
import { Card, Input, Layout, Table } from "antd";
import APIClient from "../../../src/helpers/ApiClient";
import search from "../assets/vector-icons/search.png";
import excel from "../assets/vector-icons/excel.png";
import moment from "moment";
import exchange from "../assets/vector-icons/exchange.png";
import { exportExcel } from "../ExportExcel";
import { CloseOutlined } from "@ant-design/icons";
import "../../admin/Adminstyles.scss";
const { Content } = Layout;

const Siteusers = () => {
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [siteUsersList, setSiteUsersList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const getSiteUseList = () => {
    APIClient.get("admin/siteUsers")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          setSiteUsersList(data);
          setTableList(data);
        }
      })
      .catch((e) => {
        console.log("api error", e);
      });
  };

  useEffect(() => {
    getSiteUseList();
  }, []);

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(siteUsersList);
    }
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };

  const closeSearch = () => {
    setShowSearchBox(false);
    setTableList(siteUsersList);
  };

  const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

  const columnsusers = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
      key: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "name",
      sorter: (a, b) => a.Email.localeCompare(b.Email),
    },

    {
      title: "DailingCode",
      dataIndex: "DailingCode",
      key: "name",
      sorter: (a, b) => a.DailingCode - b.DailingCode,
    },

    {
      title: "Mobile",
      dataIndex: "Mobile",
      key: "name",
      sorter: (a, b) => a.Mobile.localeCompare(b.Mobile),
    },
    {
      title: "Application Type",
      dataIndex: "DeviceType",
      sorter: (a, b) =>
        a.DeviceType && b.DeviceType
          ? a.DeviceType.localeCompare(b.DeviceType)
          : "",
    },

    {
      title: "Created Date",
      dataIndex: "CreatedDate",
      sorter: (a, b) => a.CreatedDate.localeCompare(b.CreatedDate),
    },
    {
      title: "Modified Date",
      dataIndex: "ModifiedDate",
      sorter: (a, b) => a.ModifiedDate.localeCompare(b.ModifiedDate),
    },
  ];

  // const getStatus = (status) => {
  //   return (
  //     <div style={status ? { color: "#ff0000" } : { color: "#008000" }}>
  //       <b>{status ? "Inactive" : "Active"}</b>
  //     </div>
  //   );
  // };
  const handleExcel = () => {
    let excelData = tableList;
    excelData = excelData.map((item) => ({
      SNo: item.SNo,
      Email: item.Email,
      DailingCode: item.DailingCode,
      Mobile: item.Mobile,
      ApplicationType: item.DeviceType,
      CreatedDate: item.CreatedDate,
      ModifiedDate: item.ModifiedDate,
    }));

    exportExcel(excelData, "Siteusers_Details");
  };

  return (
    <Layout>
      <div className="manage-markup-section">
        <Content className="admin-container">
          <div className="card-bt-gap">
            <Card bordered={false}>
              <div className="card-add-heading">
                <div className="rows-count">
                  <h5>Siteusers Details</h5>
                  <p>{tableList.length} rows found !</p>
                </div>
                <div className="action-images">
                  {showSearchBox && (
                    <Input
 autocomplete="newpassword" 
                      placeholder="Search"
                      onChange={(e) => searchTableData(e)}
                      suffix={closeSearchInput}
                      style={{ padding: "0px 12px" }}
                    />
                  )}
                  &nbsp;&nbsp;
                  <img
                    src={search}
                    alt="search"
                    onClick={() => setShowSearchBox(true)}
                  />
                  <img src={excel} alt="excel" onClick={() => handleExcel()} />
                  <img
                    src={exchange}
                    alt="exchange"
                    onClick={() => {
                      setTableList([]);
                      getSiteUseList();
                    }}
                  />
                </div>
              </div>

              <Table
                scroll={{ x: true }}
                style={{ color: "red" }}
                bordered
                rowKey={uniqueKey}
                columns={columnsusers}
                dataSource={tableList}
                pagination={{
                  defaultPageSize: 25,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50", "100", "125"],
                }}
              />
            </Card>
          </div>
        </Content>
      </div>
    </Layout>
  );
};
export default Siteusers;
