import { message } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import MailSender from "./MailSender";

const downloadTicketPdf = () => {
  const { height, width } = document
    .querySelector(".ant-collapse-content-box")
    .getBoundingClientRect();
  console.log(height, width);
  let top_left_margin = 15;
  let PDF_Width = width + top_left_margin * 2;
  let PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
  let canvas_image_width = width;
  let canvas_image_height = height;
  let totalPDFPages = Math.ceil(height / PDF_Height) - 1;
  console.log(PDF_Width, PDF_Height, totalPDFPages);
  html2canvas(document.querySelector(".ant-collapse-content-box"), {
    allowTaint: true,
  }).then((canvas) => {
    canvas.getContext("2d");
    console.log(
      canvas.height,

      +"  " + canvas.width
    );
    // const uri = canvas.toDataURL("image/png");
    // const pdf = new jsPDF("p", "mm", "a4");
    // var width = pdf.internal.pageSize.getWidth();
    // var height = pdf.internal.pageSize.getHeight();
    // pdf.addImage(uri, "JPEG", 0, 0, width, height);
    // pdf.save("Ticket.pdf");
    const imgData = canvas.toDataURL("image/jpeg");

    const pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
    pdf.addImage(
      imgData,
      "JPG",
      top_left_margin,
      top_left_margin,
      canvas_image_width,
      canvas_image_height
    );
    // const imgProps = pdf.getImageProperties(imgData);
    // const pdfWidth = pdf.internal.pageSize.getWidth();
    // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    // pdf.addImage(imgData, "PNG", -3, 0, pdfWidth, pdfHeight);

    for (let i = 1; i <= totalPDFPages; i++) {
      pdf.addPage([PDF_Width, PDF_Height]);
      pdf.addImage(
        imgData,
        "JPG",
        top_left_margin,
        -(PDF_Height * i) + top_left_margin * 4,
        canvas_image_width,
        canvas_image_height
      );
    }
    pdf.save("ticket.pdf");
  });
};

const sendTicketEmail = (formData, ticketData, type) => {
  // console.log("ticketData", ticketData);

  let passengersData = ticketData.passengers.map((item) => {
    return {
      FirstName: item.firstName,
      LastName: item.lastName,
      AddOnServices: "string",
    };
  });
  // console.log("passangers", passengersData);

  html2canvas(document.querySelector(".ant-collapse-content-box"), {
    scale: 0.75,
  }).then((canvas) => {
    const ticketImg = canvas.toDataURL("image/png");
    if (type == "flight") {
      let segLength = ticketData.oneWaySegment.length;
      let emailData = {
        to: formData.Email,
        data: {
          BookingDetails: {
            Email: ticketData.guestEmaiId,
            Mobile: ticketData.guestMobileNo,
            FirstName: ticketData.guestName,
            LastName: " ",
            PNR: ticketData.pnr,
            Status: ticketData.BookingStatus,
            BookingRefNo: ticketData.id,
            BookedDate: ticketData.journeyDate,
            BookedTime: ticketData.journeyDate,
          },
          FlightDetails: {
            Source: ticketData.oneWaySegment[0].origin,
            Destination: ticketData.oneWaySegment[segLength - 1].destination,
            DepatureDate: ticketData.oneWaySegment[0].departureDateTime,
            DepatureTime: ticketData.oneWaySegment[0].departureDateTime,
            ArrivalDate:
              ticketData.oneWaySegment[segLength - 1].arrivalDateTime,
            ArrivalTime:
              ticketData.oneWaySegment[segLength - 1].arrivalDateTime,
            Flight: "string",
            FareType: ticketData.fareType,
          },
          Passanger: passengersData,
          // attachment: ticketImg,
        },
        cc: "string",
        bcc: "string",
        subject: "Ticket Details",
      };
      MailSender.post("flightTicket", emailData).then((res) => {
        // console.log("MailResponse", res);
        if (res.status == 200) message.success("Ticket Sent to Email");
      });
    }
  });
};

export { downloadTicketPdf, sendTicketEmail };
