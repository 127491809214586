import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Form,
  Row,
  message,
  Modal,
  Spin,
} from "antd";
import TicketFlight from "./TicketFlight";
import TicketSidebar from "./TicketSidebar";
import queryString from "query-string";
import APIClient from "../../helpers/ApiClient";
import {
  getConvieneceFeeValue,
  getPromoDiscount,
} from "../../components/FlightsCheckout/FlightGrandTotalCard";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";

const CheckboxGroup = Checkbox.Group;
const FlightTicketScreen = ({ mode }) => {
  const { Panel } = Collapse;

  let dateFormat = "DD MMM YYYY";
  const [loading, setLoading] = useState(false);
  const ticketSearchParams = queryString.parse(window.location.search);
  const initialState = {
    id: "",
    pax: {},
    passengers: [],
    oneWaySegment: [],
    returnSegment: [],
    oneWayflightFare: {},
    oneWayBaggageInfo: [],
    returnBaggageInfo: [],
  };
  const [ticketData, setTicketData] = useState(initialState);
  const [modalVisible, setModalVisible] = useState(false);
  const [source, setSource] = useState("");
  const [loadingTicket, setLoadingTicket] = useState(true);
  const [destination, setDestination] = useState("");

  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(
      !!list.length && list.length < ticketData.passengers.length
    );
    setCheckAll(list.length === ticketData.passengers.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? ticketData.passengers : []);
    form.setFieldsValue({
      Passangers: e.target.checked ? ticketData.passengers : [],
    });
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const [form] = Form.useForm();
  useEffect(() => {
    getTicketDetails();
  }, []);
  const [fareRulesResp, setFareRulesResp] = useState();

  const fetchFareRules = (traceId) => {
    APIClient.get(`flights/getRulesFromTraceId/${traceId}`)
      .then((resp) => {
        if (resp.statusCode === 200) {
          setFareRulesResp(resp.data);
        } else {
          let error_resp = {
            data: {
              fareRules: [
                {
                  ruleDetails: "Fare Rules not Available",
                },
              ],
            },
          };
          setFareRulesResp(error_resp);
        }
      })
      .catch((err) => console.log(err));
  };

  const getTicketDetails = () => {
    setLoadingTicket(true);
    APIClient.get("admin/myBookings/details", {
      travelType: "1",
      id: ticketSearchParams.ref,
    })
      .then((res) => {
        if (res.statusCode === 200) {
          fetchFareRules(res.data[0].traceId);
          setTicketData(res.data[0]);
          setDestination(
            res.data[0].oneWaySegment[res.data[0].oneWaySegment.length - 1]
              .destination
          );
          setSource(res.data[0].oneWaySegment[0].origin);
        }
        setLoadingTicket(false);
      })
      .catch((error) => {
        setLoadingTicket(false);
        console.error(error);
      });
  };

  const flightCancelTicket = (cancelTicketType) => {
    setModalVisible(true);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  let flightsegments = ticketData.oneWaySegment || [];
  let flightFares = ticketData.oneWayflightFare.fares || [];
  let markup = ticketData.markup;

  if (ticketData.tripType == "TwoWay") {
    flightsegments = [
      ...ticketData.oneWaySegment,
      { empty: true },
      ...ticketData.returnSegment,
    ];
    flightFares = [
      ...ticketData.oneWayflightFare.fares,
      ...ticketData.returnflightFare.fares,
    ];
  }

  const getTaxCharges = () => {
    let amount = flightFares
      .filter((item) => item.FareCode != "Base")
      .reduce((total, item) => {
        if (item.PaxType == "ADT") {
          return total + parseFloat(item.Amount * ticketData.pax.adults);
        } else if (item.PaxType == "CHD") {
          return total + parseFloat(item.Amount * ticketData.pax.childCount);
        } else {
          return total + parseFloat(item.Amount * ticketData.pax.infantCount);
        }
      }, 0);
    return Number(amount).toFixed(2);
  };

  const getBaseAmount = () => {
    let amount = flightFares
      .filter((item) => item.FareCode == "Base")
      .reduce((total, item) => {
        if (item.PaxType == "ADT") {
          return total + Number(Number(item.Amount) * ticketData.pax.adults);
        } else if (item.PaxType == "CHD") {
          return (
            total + Number(Number(item.Amount) * ticketData.pax.childCount)
          );
        } else if (item.PaxType == "INF") {
          return (
            total + Number(Number(item.Amount) * ticketData.pax.infantCount)
          );
        }
      }, 0);
    return Number(amount + Number(markup)).toFixed(2);
  };

  const getTotalFlightFareForTicket = () => {
    let base = parseFloat(getBaseAmount());
    let tax = parseFloat(getTaxCharges());
    let convienenceFee = getConvFeeValue();
    let discount = getDicountValue();
    return Number(base + tax + convienenceFee - discount).toFixed(2);
  };

  const getDicountValue = () => {
    return ticketData.promoData ? flightDiscount() : "0.00";
  };
  const flightDiscount = () => {
    let discountValue = getPromoDiscount(
      ticketData.promoData,
      ticketData.oneWayflightFare.totalFare
    );
    return Number(discountValue).toFixed(2);
  };

  const getConvFeeValue = () => {
    let totalwithtax =
      parseFloat(getBaseAmount()) + parseFloat(getTaxCharges());
    return ticketData.convienenceData
      ? getConvieneceFeeValue(ticketData.convienenceData, totalwithtax)
      : "0.00";
  };

  const submitCancelForm = (val) => {
    setLoading(true);

    let segments = ticketData.oneWaySegment.map((item) => ({
      ...item,
      isReturnSegement: ticketData.tripType === "OneWay" ? false : true,
    }));
    let passangers = val.Passangers.map((item) => ({
      ...item,
      ticketNumber: ticketData.pnr,
    }));
    let data = {
      CancellationType:
        passangers.length === ticketData.passengers.length ? 1 : 2,

      ReferenceNumber: ticketData.id,
      PNR: ticketData.pnr,
      guestEmailId: ticketData.guestEmailId,
      guestMobileNo: ticketData.guestMobileNo,
      ReturnPNR:
        ticketData.tripType === "OneWay" ? "string" : ticketData.returnPnr,
      Segments: segments,
      Remarks: "string",
      Passangers: passangers,
    };

    APIClient.post("admin/airCancelRequest", data)
      .then((res) => {
        setLoading(false);
        if (res.statusCode == 200) {
         
          if (!!res.message) message.success(res.message, 10);

          // setTicketData({ bookingStatus: "Cancelled" });
          // getTicketDetails(ticketSearchParams.ref);
          setModalVisible(false);

          form.resetFields();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <div className="flight-ticket-collapse">
      <Card>
        {loadingTicket ? (
          <div className="fligh-ticket-container">
            <div style={{ textAlign: "center" }} className="flight-ticket">
              <Spin indicator={antIcon} tip="Loading..." />
            </div>
          </div>
        ) : (
          <div className="fligh-ticket-container">
            <div className="flight-ticket">
              <Row gutter={[32, 16]} className="ticket-row">
                <Col md={18}>
                  <Collapse
                    bordered={true}
                    className="collapsed-data"
                    defaultActiveKey={["1"]}
                    accordion={true}
                  >
                    <Panel key="1">
                      <TicketFlight
                        ticketData={ticketData}
                        source={source}
                        destination={destination}
                        fareRulesResp={fareRulesResp}
                      />
                    </Panel>
                  </Collapse>
                </Col>

                <Col xs={24} md={6} className="tic-info-flight">
                  <div className="web-tic-info">
                    <TicketSidebar
                      ticketData={ticketData}
                      ticketSearchParams={ticketSearchParams}
                      type="Flight"
                      onCancelTicket={flightCancelTicket}
                    />
                  </div>

                  <div className="mobile-tic-info">
                    <Collapse accordion>
                      <Panel header="Manage Tickets" key="1">
                        <TicketSidebar
                          ticketData={ticketData}
                          ticketSearchParams={ticketSearchParams}
                          type="Flight"
                          onCancelTicket={flightCancelTicket}
                        />
                      </Panel>
                    </Collapse>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Card>

      <Modal
        title="Cancel Ticket"
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              onClick={form.submit}
              loading={loading}
            >
              Cancel Ticket
            </Button>
          </div>,
        ]}
        width={"800px"}
      >
        {ticketData.BookingStatus === "CONFIRMED" ||
        ticketData.BookingStatus === "PartiallyCancelled" ? (
          <div className="wrapper">
            <p>
              <b>Reference No</b> : {ticketData.id}
            </p>
            <p>
              <b>Journey Details</b> : {source} <b>TO </b>
              {destination}
            </p>
            <p>
              <b>Total Fare</b> : Rs {getTotalFlightFareForTicket()}
            </p>

            {/* {CancellationPolicy(ticketData.cancellationPolicy)} */}
            <div className="flightCancelDetials">
              <p>
                <b>Flight Details : </b>
              </p>
              <Form form={form} onFinish={submitCancelForm}>
                <p style={{ marginBottom: "5px", marginLeft: "5px" }}>
                  <b>Onward Flight Details</b>
                </p>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {source} <b>TO </b>
                  {destination} (
                  {moment(ticketData.departure).format(dateFormat)})
                </Checkbox>
                <Form.Item
                  label=" "
                  name="Passangers"
                  rules={[
                    { required: true, message: "Please Select Passanger" },
                  ]}
                >
                  <CheckboxGroup value={checkedList} onChange={onChange}>
                    <Row>
                      {ticketData.passengers.map((item, i) => (
                        <Col md={24} sm={24} key={i + "modal"}>
                          <Checkbox value={item}>
                            {item.firstName} {item.lastName}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </CheckboxGroup>
                </Form.Item>
              </Form>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
};

export default FlightTicketScreen;
