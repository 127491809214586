import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  message,
  Popconfirm,
  Row,
  Table,
} from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import { exportExcel } from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
const { Content } = Layout;

const Role = () => {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [currentId, setCurrentId] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);

  useEffect(() => {
    getRolesList();
  }, []);

  const getRolesList = () => {
    ApiClient.get("codes/getroles")
      .then((res) => {
        if (res.isSuccess) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              // CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              // ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          setRolesList(data);
          setTableList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const add = (val) => {
    ApiClient.post("codes/saverole", val)
      .then((response) => {
        return response;
      })
      .then((response) => {
        if (response.isSuccess) {
          setModalVisible(false);
          message.success("Successfully added role", 3);
          getRolesList();
          form.resetFields();
        } else if (!response.isSuccess) {
          message.error("Role Already existed", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleModal = () => {
    if (showUpdateBtn) {
      setShowUpdateBtn(false);
      form.resetFields();
    }
    setModalVisible(true);
  };

  const deleteRole = (Record) => {
    let Id = Record.id;
    ApiClient.delete("codes/deleterole/" + Id)
      .then((res) => {
        if (res.isSuccess) {
          message.success("Role Deleted  successfully", 3);
          getRolesList();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const updateRole = (val) => {
    ApiClient.put(`codes/updaterole/${currentId.id}`, {}, val).then((resp) => {
      if (resp.isSuccess) {
        getRolesList();
        setModalVisible(false);
        message.success("Role Updated successfully", 3);
        setCurrentId(-1);
      }
    });
  };

  const submitForm = (val) => {
    let roleObj = {
      name: val.role,
      isActive: true,
    };
    if (showUpdateBtn) updateRole(roleObj);
    else add(roleObj);
  };

  const tableActions = (currentRecord, Name) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord, Name)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure want to delete?"
            onConfirm={() => deleteRole(Name)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const editCurrentItem = (id, Name) => {
    setShowUpdateBtn(true);
    setCurrentId(id);
    form.setFieldsValue({
      role: Name.name,
    });
    setModalVisible(true);
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };

  const searchData = () => {
    setShowSearchBox(true);
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    {
      title: "Role Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },

    // {
    //   title: "Created Date",
    //   dataIndex: "CreatedDate",
    //   sorter: (a, b) => a.CreatedDate.localeCompare(b.CreatedDate),
    // },
    // {
    //   title: "Modified Date",
    //   dataIndex: "ModifiedDate",
    //   sorter: (a, b) => a.ModifiedDate.localeCompare(b.ModifiedDate),
    // },
    {
      title: "Actions",
      render: (id, name) => tableActions(id, name),
    },
  ];

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(rolesList);
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
    setTableList(rolesList);
  };

  const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

  const handleExcel = () => {
    let excelData = tableList;
    excelData = excelData.map((item, i) => ({
      SNo: i + 1,
      Name: item.name,
      // CreatedDate: item.CreatedDate,
      // ModifiedDate: item.ModifiedDate,
    }));

    exportExcel(excelData, "Roles");
  };
  const getDataByName = (val) => {
    let obj = {
      filter: {
        name: val,
        isActive: true,
      },
      page: 0,
    };
    ApiClient.post("codes/getroles/list", obj)
      .then((response) => {
        return response;
      })
      .then((response) => {
        if (response.isSuccess) {
          let data = response.results.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });

          setRolesList(data);
          setTableList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="outer-background-all">
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false} style={{borderRadius:"25px"}}>
              <Row className="card-add-heading">
                <Col md={14} xs={12}>
                  <div className="rows-count">
                    <h5>View Roles</h5>
                    <p>{tableList.length} rows found !</p>
                  </div>
                </Col>
                <Col md={10} xs={12}>
                  <Row>
                    <Col md={14} xs={24}>
                      <Form name="search">
                        <AutoCompleteSelect
                          formItemProps={{
                            label: "",
                            name: "Search",
                            rules: [{ required: true, message: "Required!" }],
                          }}
                          selectProps={{
                            placeholder: "Search Role Name",
                            onSelect: (val) => getDataByName(val),
                          }}
                          api={"codes/getroleautosuggestion/"}
                          keytext="name"
                          keyvalue="name"
                        />
                      </Form>
                    </Col>
                    <Col md={10} xs={24}>
                      <div className="action-images">
                        &nbsp;&nbsp;
                        {/* <img src={search} alt="search" onClick={() => searchData()} /> */}
                        <img
                          src={excel}
                          alt="excel"
                          onClick={() => handleExcel()}
                        />
                        <img
                          src={exchange}
                          alt="exchange"
                          onClick={() => {
                            setTableList([]);
                            getRolesList();
                          }}
                        />
                        <PlusCircleFilled
                          className="add-deposit-icon"
                          onClick={handleModal}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {tableList.length > 0 ? (
                <Table
                  scroll={{ x: true }}
                  bordered
                  rowKey={uniqueKey}
                  dataSource={tableList}
                  columns={columns}
                  pagination={{
                    defaultPageSize: 25,
                    showSizeChanger: true,
                    pageSizeOptions: ["25", "50", "100", "125"],
                  }}
                />
              ) : (
                "No Record Found"
              )}
            </Card>
          </div>
        </Content>
      </div>
      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>{showUpdateBtn ? "Update Role" : "Add Role"}</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <Button key="add" type="primary" onClick={form.submit}>
            {showUpdateBtn ? "Update" : "Add"}
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Role"
                name="role"
                rules={[{ required: true, message: "Please enter role" }]}
              >
                <Input autocomplete="newpassword" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default Role;
