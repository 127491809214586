import React, { useCallback, useState, useEffect, useRef } from "react";
import "../CombinedOverallSearch/CombinedOverallSearch.scss";
import "antd/dist/antd.css";
import {
  Col,
  Row,
  Form,
  Select,
  DatePicker,
  Button,
  message,
  Input,
  Spin,
  Popover,
} from "antd";
import { EnvironmentOutlined, SwapOutlined } from "@ant-design/icons";
import ApiClient from "../../helpers/ApiClient";
import moment from "moment";
import passangerIcon from "../../assets/images/passanger.svg";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

const { Option } = Select;
const CombinedOverallSearch = (props) => {
  // console.log("combine overall search props", props);
  const tocityBox = useRef(null);
  const fromDateBox = useRef(null);
  const [searchform] = Form.useForm();
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);

  const dateFormat = "DD-MM-YYYY";
  const oriDateString = "YYYY-MM-DD";

  const { updateFlightAirSearchRespObj } = props.flightContext;
  const { updateBusSearchResultObj } = props.gscontext;
  const combineSearchData = props.gscontext.state.combineSearchData;

  const [adults, setAdults] = useState(1);
  const [child, setChild] = useState(0);
  const [infants, setInfants] = useState(0);
  const [paxError, setPaxError] = useState({
    infantErr: false,
    totalErr: false,
  });
  const [togglePax, setTogglePax] = useState(false);
  const [isFetching, setIsFetcing] = useState(false);
  const [noMatchFound, setNoMatchFound] = useState(false);
  const updataCombineSearchObj = props.gscontext.updataCombineSearchObj;

  useEffect(() => {
    if (props.modify) {
      searchform.setFieldsValue({
        fromCity: getCityName(combineSearchData.origin),
        toCity: getCityName(combineSearchData.destination),
        fromDate: moment(combineSearchData.fromDate, oriDateString),
      });
      if (combineSearchData.tripType != "oneWay") {
        searchform.setFieldsValue({
          todate: moment(combineSearchData.toDate, oriDateString),
        });
      }
    }
  }, [
    props.modify,
    combineSearchData.origin,
    combineSearchData.destination,
    combineSearchData.fromDate,
    combineSearchData.toDate,
  ]);
  useEffect(() => {
    if (props.modify) {
      setAdults(combineSearchData.adultCount);
      setChild(combineSearchData.childCount);
      setInfants(combineSearchData.infantCount);
    }
  }, [
    props.modify,
    combineSearchData.adultCount,
    combineSearchData.childCount,
    combineSearchData.infantCount,
  ]);

  useEffect(() => {
    if (props.reset) {
      searchform.resetFields();
      updataCombineSearchObj("fromDate", moment().format(oriDateString));
      updataCombineSearchObj("toDate", "");
      updataCombineSearchObj("adultCount", 1);
      updataCombineSearchObj("childCount", 0);
      updataCombineSearchObj("infantCount", 0);
    }
  }, [props.reset]);

  useEffect(() => {
    localStorage.setItem(
      "travelType",
      props.gscontext.state.combineSearchData.tripType
    );

    // checkoutUpdate();
    // setTravelType("oneWay");
    if (props.modify) {
      let param = props.param;

      // return JSON.parse(param.search);
      let origin = param.origin.split("-");
      let dest = param.destination.split("-");
      updataCombineSearchObj("origin", {
        name: origin[0] ? origin[0] : null,
        airports: { airportCodeIATA: origin[1] ? origin[1] : null },
        busStops: { Id: origin[2] ? origin[2] : null },
      });
      updataCombineSearchObj("destination", {
        name: dest[0] ? dest[0] : null,
        airports: { airportCodeIATA: dest[1] ? dest[1] : null },
        busStops: { Id: dest[2] ? dest[2] : null },
      });
      updataCombineSearchObj("fromDate", param.from);
      updataCombineSearchObj("toDate", param.to);
      updataCombineSearchObj(
        "tripType",
        param.tripType ? param.tripType : "oneWay"
      );
      updataCombineSearchObj(
        "classType",
        param.class ? param.class : "Economy"
      );
      updataCombineSearchObj(
        "adultCount",
        param.adult ? Number(param.adult) : 1
      );
      updataCombineSearchObj(
        "childCount",
        param.child ? Number(param.child) : 0
      );
      updataCombineSearchObj(
        "infantCount",
        param.infant ? Number(param.infant) : 0
      );

      searchform.resetFields();
    }
  }, [props.modify]);

  const validateMessages = {
    required: "",
  };

  const getCityName = (city) => {
    return city ? city.name : "";
  };

  let handleSearch = (query, type) => {
    if (query !== undefined && query !== null && query.trim() !== "") {
      const searchType = type;

      let searchReq = {
        city: query,
      };
      setIsFetcing(true);
      if (searchType === "FROM") setFromOptions([]);
      else setToOptions([]);
      ApiClient.post("mapping/search", searchReq)
        .then((result) => {
          return result;
        })
        .then((resp) => {
          if (resp.statusCode === 200) {
            if (searchType === "FROM") setFromOptions(resp.data);
            else setToOptions(resp.data);
            if (resp.data.length === 0) setNoMatchFound(true);
          }
          setIsFetcing(false);
        })
        .catch((err) => {
          setIsFetcing(false);
        });
    } else {
      if (type === "TO") setToOptions([]);
      else setFromOptions([]);
    }
  };

  const handleSwap = () => {
    if (combineSearchData.destination.name && combineSearchData.origin.name) {
      searchform.resetFields();
      let opt = fromOptions;
      setFromOptions(toOptions);
      setToOptions(opt);
      searchform.setFieldsValue({
        fromCity: combineSearchData.destination.name,
        toCity: combineSearchData.origin.name,
      });

      updataCombineSearchObj("origin", combineSearchData.destination);
      updataCombineSearchObj("destination", combineSearchData.origin);
    }
  };

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  const debounceOnChange = useCallback(debounce(handleSearch, 800), []);

  let handleCityName = (selected, options, type) => {
    if (selected !== undefined && selected.length > 0) {
      let searchCities = options.find((nameObj) => nameObj.name === selected);

      let transportArr = {
        ...searchCities,
        airports:
          searchCities.airports.length > 0 ? searchCities.airports[0] : null,
        stations:
          searchCities.stations.length > 0 ? searchCities.stations[0] : null,
        busStops:
          searchCities.busStops.length > 0 ? searchCities.busStops[0] : null,
      };

      if (type === "FROM") {
        updataCombineSearchObj("origin", transportArr);
      } else {
        updataCombineSearchObj("destination", transportArr);
      }
    } else {
      setToOptions([]);
      setFromOptions([]);
    }
  };

  const handleClear = (type, name) => {
    updataCombineSearchObj([type], []);
    searchform.setFieldsValue({
      [name]: "",
    });
    setToOptions([]);
    setFromOptions([]);
  };

  const onChangeOriginDate = (momentdate, dateString) => {
    let date = moment(dateString, dateFormat).format(oriDateString);
    updataCombineSearchObj("fromDate", date);
    if (combineSearchData.toDate) {
      if (
        moment(combineSearchData.toDate, oriDateString) <
        moment(dateString, dateFormat)
      ) {
        let newTodate = moment(dateString, dateFormat).add(1, "days");
        newTodate = moment(newTodate).format(oriDateString);

        updataCombineSearchObj("toDate", newTodate);
      }
    }
  };

  const onChangeDestDate = (momentdate, dateString) => {
    if (dateString && dateString != null) {
      setTravelType("roundTrip");
      let date = moment(dateString, dateFormat).format(oriDateString);
      updataCombineSearchObj("toDate", date);
    } else {
      setTravelType("oneWay");
      updataCombineSearchObj("toDate", "");
    }
  };

  const setTravelType = (val) => {
    updataCombineSearchObj("tripType", val);
  };

  const onHandleClass = (value) => {
    updataCombineSearchObj("classType", value);
  };

  const onChangePaxCount = (adultCountVal) => {
    if (Number(adults) + Number(child) + Number(infants) > 8) {
      if (
        adultCountVal == "adultInc" ||
        adultCountVal == "childInc" ||
        adultCountVal == "infantInc"
      ) {
        setPaxError((prev) => ({
          ...prev,
          totalErr: true,
        }));
        return;
      }
    } else {
      if (paxError.totalErr) {
        setPaxError((prev) => ({
          ...prev,
          totalErr: false,
        }));
      }
    }
    if (!(Number(infants) < Number(adults))) {
      if (adultCountVal == "infantInc") {
        setPaxError((prev) => ({
          ...prev,
          infantErr: true,
        }));
        return;
      }
    } else {
      if (paxError.infantErr) {
        setPaxError((prev) => ({
          ...prev,
          infantErr: false,
        }));
      }
    }

    switch (adultCountVal) {
      case "adultInc":
        setAdults(adults + 1);
        updataCombineSearchObj("adultCount", adults + 1);
        break;
      case "adultDec":
        if (adults > 1) {
          setAdults(adults - 1);
          updataCombineSearchObj("adultCount", adults - 1);
        } else return "";
        break;
      case "childInc":
        setChild(child + 1);
        updataCombineSearchObj("childCount", child + 1);
        break;
      case "childDec":
        if (child >= 1) {
          setChild(child - 1);
          updataCombineSearchObj("childCount", child - 1);
        } else return "";
        break;
      case "infantInc":
        setInfants(infants + 1);
        updataCombineSearchObj("infantCount", infants + 1);
        break;
      case "infantDec":
        if (infants >= 1) {
          setInfants(infants - 1);
          updataCombineSearchObj("infantCount", infants - 1);
        } else return "";
        break;
      default:
        break;
    }
  };

  /* Disable the previous dates */
  const disabledOriginDate = (currentDate) => {
    let checkdate = combineSearchData.toDate == "" ? currentDate < moment().startOf("day") : currentDate >moment(combineSearchData.toDate).endOf("day")
    return checkdate
  };

  const disabledDestDate = (currentDate) => {
    return currentDate < moment(combineSearchData.fromDate).startOf("day");
  };

  let history = useHistory();

  const OnFormValidated = () => {
    let checkCount = Number(adults) + Number(child) + Number(infants);
    if (checkCount > 9) {
      message.error(
        "Total Passengers Cannot Exceed 9. (Adults + Children + Infants) <= 9",
        3
      );
      return;
    }
    let { origin, destination } = combineSearchData;
    let oAirportCodeIATA = origin.airports
      ? origin.airports.airportCodeIATA
        ? origin.airports.airportCodeIATA
        : ""
      : "";
    let oBusId = origin.busStops
      ? origin.busStops.Id
        ? origin.busStops.Id
        : ""
      : "";
    let dAirportCodeIATA = destination.airports
      ? destination.airports.airportCodeIATA
        ? destination.airports.airportCodeIATA
        : ""
      : "";
    let dBusId = destination.busStops
      ? destination.busStops.Id
        ? destination.busStops.Id
        : ""
      : "";

    let query = {
      adult: combineSearchData.adultCount,
      child: combineSearchData.childCount,
      class: combineSearchData.classType,
      from: combineSearchData.fromDate,
      infant: combineSearchData.infantCount,
      to: combineSearchData.toDate,
      tripType: combineSearchData.tripType,
      origin: `${origin.name}-${oAirportCodeIATA}-${oBusId}`,
      destination: `${destination.name}-${dAirportCodeIATA}-${dBusId}`,
    };

    let queryParams = queryString.stringify(query);

    updateBusSearchResultObj({});
    updateFlightAirSearchRespObj({});
    if (props.modify) {
      history.replace("/combinedresults?" + queryParams);
      props.onModify();
    } else {
      history.push("/combinedresults?" + queryParams);
    }
  };
  const navigateToResults = () => {
    searchform
      .validateFields()
      .then((data) => {
        OnFormValidated();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const content = (
    <div className="pax-modal">
      <div className="pax-modal-wrapper">
        <div className="pax-modal-arrow"></div>
        <li>
          <div className="pax-label">
            <p>Adults</p>
            <span>over 12 years old</span>
          </div>
          <div className="pax-count">
            <i
              className="fa fa-minus"
              aria-hidden="true"
              onClick={(e) => onChangePaxCount("adultDec")}
            ></i>
            <span>{adults}</span>
            <i
              className="fa fa-plus"
              aria-hidden="true"
              onClick={(e) => onChangePaxCount("adultInc")}
            ></i>
          </div>
        </li>

        {adults > 0 ? (
          <li>
            <div className="pax-label">
              <p>Children</p>
              <span>2 - 12 years old</span>
            </div>
            <div className="pax-count">
              <i
                className="fa fa-minus"
                aria-hidden="true"
                onClick={(e) => onChangePaxCount("childDec")}
              ></i>
              <span>{child}</span>
              <i
                className="fa fa-plus"
                aria-hidden="true"
                onClick={(e) => onChangePaxCount("childInc")}
              ></i>
            </div>
          </li>
        ) : null}

        {adults > 0 ? (
          <li>
            <div className="pax-label">
              <p>Infants</p>
              <span>upto 2 years old</span>
            </div>
            <div className="pax-count">
              <i
                className="fa fa-minus"
                aria-hidden="true"
                onClick={(e) => onChangePaxCount("infantDec")}
              ></i>
              <span>{infants}</span>
              <i
                className="fa fa-plus"
                aria-hidden="true"
                onClick={(e) => onChangePaxCount("infantInc")}
              ></i>
            </div>
          </li>
        ) : null}
        {paxError.infantErr ? (
          <li>
            <span
              style={{
                fontSize: "11px",
                color: "blue",
              }}
            >
              The number of infants cannot be grater than the number of adults.
            </span>
          </li>
        ) : (
          ""
        )}
        {paxError.totalErr ? (
          <li>
            <span
              style={{
                fontSize: "11px",
                color: "blue",
              }}
            >
              {
                "Total Passengers Cannot Exceed 9. (Adults + Children + Infants) <= 9"
              }
              .
            </span>
          </li>
        ) : (
          ""
        )}

        <Button
          block
          className="pax-ready-btn"
          onClick={() => setTogglePax(false)}
        >
          Done
        </Button>
      </div>
    </div>
  );
  const onInputChange = (val) => {
    console.log(val);
  };

  return (
    <div className="combined-search-block">
      <div className="search-block ">
        <div className="search-body">
          <div className="class-container">
            <span className="class-type">Class :</span>
            <Select
 autocomplete="newpassword" 
              defaultValue="Economy"
              style={{ maxWidth: "150px", minWidth: "100px" }}
              onChange={onHandleClass}
              value={combineSearchData.classType}
            >
              <Option value="premiumEconomy">Premium Economy</Option>
              <Option value="Business">Business</Option>
              <Option value="premiumFirst">Premium First</Option>
              <Option value="Economy">Economy</Option>
            </Select>
          </div>
          <Form
            name="search-form"
            form={searchform}
            validateMessages={validateMessages}
            className="home-search-form"
          >
            <Row gutter={8} className="search-row row-from-background">
              <Col lg={4} md={11} xs={24}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please enter the From Location",
                    },
                  ]}
                  name="fromCity"
                >
                  <div className="from_wrapper">
                    <div className="icon_wrapper">
                      <EnvironmentOutlined />
                    </div>
                    <Select
 autocomplete="newpassword" 
                      autoFocus
                      size="large"
                      showSearch={true}
                      allowClear={true}
                      placeholder="From City"
                      notFoundContent={
                        isFetching ? (
                          <Spin size="small" />
                        ) : noMatchFound ? (
                          "No Matches found."
                        ) : null
                      }
                      filterOption={false}
                      onBlur={() => {
                        setNoMatchFound(false);
                      }}
                      onSearch={(val) => debounceOnChange(val, "FROM")}
                      onClear={() => handleClear("origin", "fromCity")}
                      onSelect={(value) => {
                        handleCityName(value, fromOptions, "FROM");
                        tocityBox.current.focus();
                      }}
                      defaultValue={getCityName(
                        combineSearchData.origin,
                        "fromCity"
                      )}
                    >
                      {fromOptions.map((cities, i) => {
                        if (
                          combineSearchData.destination.busStops &&
                          cities.busStops
                        ) {
                          if (cities.busStops.length > 0)
                            if (
                              combineSearchData.destination.busStops.Id ==
                              cities.busStops[0].Id
                            )
                              return null;
                        }
                        return (
                          <Option key={cities + i + "from"} value={cities.name}>
                            {cities.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Form.Item>
              </Col>
              <Col lg={1} md={2} className="search-inputs-swap-icon">
                <SwapOutlined onClick={handleSwap} />
              </Col>
              <Col lg={4} md={11} xs={24}>
                <Form.Item
                  name="toCity"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the To Location",
                    },
                  ]}
                >
                  <div className="from_wrapper">
                    <div className="icon_wrapper">
                      <EnvironmentOutlined />
                    </div>

                    <Select
 autocomplete="newpassword" 
                      defaultValue={getCityName(
                        combineSearchData.destination,
                        "toCity"
                      )}
                      size="large"
                      ref={tocityBox}
                      showSearch={true}
                      allowClear={true}
                      placeholder="To City"
                      notFoundContent={
                        isFetching ? (
                          <Spin size="small" />
                        ) : noMatchFound ? (
                          "No Matches found."
                        ) : null
                      }
                      filterOption={false}
                      onBlur={() => {
                        setNoMatchFound(false);
                      }}
                      className="search-inputs"
                      onClear={() => handleClear("destination", "toCity")}
                      onSearch={(val) => debounceOnChange(val, "TO")}
                      onSelect={(value) => {
                        handleCityName(value, toOptions, "TO");
                        fromDateBox.current.focus();
                      }}
                    >
                      {toOptions.map((cities, i) => {
                        if (
                          combineSearchData.origin.busStops &&
                          cities.busStops
                        ) {
                          if (cities.busStops.length > 0)
                            if (
                              combineSearchData.origin.busStops.Id ==
                              cities.busStops[0].Id
                            )
                              return null;
                        }
                        return (
                          <Option key={"to" + cities + i} value={cities.name}>
                            {cities.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Form.Item>
              </Col>
              <Col lg={4} md={12} xs={24}>
                <Form.Item
                  name="fromDate"
                  rules={[{ required: true }]}
                  initialValue={moment()}
                >
                  <DatePicker
                    allowClear={false}
                    size="large"
                    className="search-inputs from-return-date"
                    placeholder="Journey Date"
                    ref={fromDateBox}
                    format={dateFormat}
                    onChange={(date, dateString) =>
                      onChangeOriginDate(date, dateString)
                    }
                    disabledDate={disabledOriginDate}
                  />
                </Form.Item>
              </Col>
              <Col lg={4} md={12} xs={24}>
                <Form.Item name="todate">
                  <DatePicker
                    size="large"
                    className="search-inputs from-return-date"
                    placeholder="Return Date"
                    defaultPickerValue={
                      combineSearchData.fromDate
                        ? moment(combineSearchData.fromDate, oriDateString)
                        : ""
                    }
                    format={dateFormat}
                    onChange={(date, dateString) =>
                      onChangeDestDate(date, dateString)
                    }
                    disabledDate={disabledDestDate}
                  />
                </Form.Item>
              </Col>
              <Col lg={2} md={12} xs={24} className="from-to-inputs">
                <Form.Item className="flight-passenger">
                  <Popover
                    className="paxmodal"
                    overlayClassName="paxpopup"
                    placement="bottomRight"
                    title={null}
                    content={content}
                    trigger="click"
                    visible={togglePax}
                    onVisibleChange={() => {
                      setTogglePax((prev) => !prev);
                    }}
                  >
                    <Input
 autocomplete="newpassword" 
                      className="adult-age-per"
                      size="large"
                      prefix={
                        <img
                          className="passangericon"
                          src={passangerIcon}
                          alt="img"
                        />
                      }
                      value={adults + child + infants}
                      readOnly
                      style={{ cursor: "pointer" }}
                    />
                  </Popover>
                </Form.Item>
              </Col>
              <Col lg={4} md={12} xs={24}>
                <Button
                  size="large"
                  htmlType="submit"
                  className="search-inputs tg-primary-btn"
                  onClick={navigateToResults}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      {/* --block---close--- */}
    </div>
  );
};

export default CombinedOverallSearch;
