import React from "react";
import * as ANTD from "antd";
import "./FilterSkeleton.scss";
import { Skeleton } from "antd";

const FilterSkeletonLayout = () => {
  return (
    <div className="filter-skeleton-layout-container bd-container">
      <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />

      <ANTD.Row className="filter-header">
        <ANTD.Col md={12}>
          <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
        </ANTD.Col>
        <ANTD.Col md={12} className="clear-all-text">
          <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
        </ANTD.Col>
      </ANTD.Row>

      <div className="filter-blocks">
        <ANTD.Row className="filter-types-header">
          <ANTD.Col md={12} className="filter-type-text">
            <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
          </ANTD.Col>
          <ANTD.Col md={6} className="clear-text">
            <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
          </ANTD.Col>
          <ANTD.Col md={6} className="collpase-icon">
            <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
          </ANTD.Col>
        </ANTD.Row>

        <ANTD.Divider />
        <div className="filter-type-content">
          <ANTD.Skeleton active={true} paragraph={{ rows: 2 }} />
        </div>
      </div>
      <div className="filter-blocks">
        <ANTD.Row className="filter-types-header">
          <ANTD.Col md={12} className="filter-type-text">
            <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
          </ANTD.Col>
          <ANTD.Col md={6} className="clear-text">
            <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
          </ANTD.Col>
          <ANTD.Col md={6} className="collpase-icon">
            <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
          </ANTD.Col>
        </ANTD.Row>

        <ANTD.Divider />
        <div className="filter-slider">
          <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
        </div>
      </div>
      <div className="filter-blocks">
        <ANTD.Row className="filter-types-header">
          <ANTD.Col md={12} className="filter-type-text">
            <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
          </ANTD.Col>
          <ANTD.Col md={6} className="clear-text">
            <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
          </ANTD.Col>
          <ANTD.Col md={6} className="collpase-icon">
            <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
          </ANTD.Col>
        </ANTD.Row>

        <ANTD.Divider />
        <div className="filter-type-content">
          <ANTD.Skeleton active={true} paragraph={{ rows: 2 }} />
        </div>
      </div>
      <div className="filter-blocks">
        <ANTD.Row className="filter-types-header">
          <ANTD.Col md={12} className="filter-type-text">
            <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
          </ANTD.Col>
          <ANTD.Col md={6} className="clear-text">
            <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
          </ANTD.Col>
          <ANTD.Col md={6} className="collpase-icon">
            <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
          </ANTD.Col>
        </ANTD.Row>

        <ANTD.Divider />
        <div className="filter-type-content">
          <ANTD.Skeleton active={true} paragraph={{ rows: 2 }} />
        </div>
      </div>
      <div className="filter-blocks">
        <ANTD.Row className="filter-types-header">
          <ANTD.Col md={12} className="filter-type-text">
            <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
          </ANTD.Col>
          <ANTD.Col md={6} className="clear-text">
            <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
          </ANTD.Col>
          <ANTD.Col md={6} className="collpase-icon">
            <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
          </ANTD.Col>
        </ANTD.Row>

        <ANTD.Divider />
        <div className="filter-type-content">
          <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
        </div>
      </div>
    </div>
  );
};

export default FilterSkeletonLayout;
