import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthContext } from "../common/providers/AuthProvider";
import GlobalStatesProvider from "../common/providers/GlobalStatesProvider";
import GlobalStatesContext from "../common/providers/GlobalStatesContext";
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { accessToken } = useAuthContext();
  return (
    <GlobalStatesProvider>
      <GlobalStatesContext.Consumer>
        {(gscontext) => (
          <Route
            {...rest}
            render={(props) => {
              const { isLogin } = gscontext.state;
             
              return isLogin.flag && accessToken ? (
                <Component {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/unauthorized",
                    state: { from: props.location },
                  }}
                />
              );
            }}
          />
        )}
      </GlobalStatesContext.Consumer>
    </GlobalStatesProvider>
  );
};

export default ProtectedRoute;
