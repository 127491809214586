import React, { useEffect, useState } from "react";
import "./NewTicket.scss";
import { Col, Row, Layout, Table, Card } from "antd";
import moment from "moment";
import getBusTicketPrice from "./busHelper";
import "./NewTicket.scss";

const TicketBus = ({ ticketData }) => {
  // const [ticketData, setTicketData] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const params = queryString.parse(document.location.search);
  // useEffect(() => {
  //   getTicketData(params.tktNo);
  // }, []);
  // const getTicketData = (tktNo) => {
  //   let obj = {
  //     tktNo: tktNo,
  //   };
  //   console.log(obj, "resp");
  //   ApiClient.post("bus/ticket", obj)
  //     .then((result) => {
  //       return result;
  //     })
  //     .then((resp) => {
  //       console.log(resp, "resp");
  //       // setTicketData(resp);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //     });
  // };



  const passengersdetailscolumn = [

    {
      title: "Name",
      dataIndex: "text",
      render: (text, record) => (<span>{record.firstName} {record.lastName}</span>)
    },
    {
      title: "Age",
      dataIndex: "age",
    },
    {
      title: "Seat no",
      dataIndex: "seatNo",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
  ];


  let { baseFare, taxes, serviceCharge, convamount, grandTotal, gst } =
    getBusTicketPrice(ticketData);
  let dateFormat = "MMM, DD-YYYY";
  const getStatus = (status) => {
    switch (status) {
      case "BLOCKED": {
        return <span style={{ color: "#FFA500" }}> <b> {status}</b> </span>;
      }
      case "CONFIRMED": {
        return (
          <span style={{ color: "#008000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "Booked": {
        return (
          <span style={{ color: "#008000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "PartiallyCancelled": {
        return (
          <span style={{ color: "#008000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "CREATED": {
        return (
          <span style={{ color: "#f9e218" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "BOOK_FAILED": {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "BLOCK_FAILED": {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "CANCELLED": {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "BUSCANCELLED": {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "BUSCANCELLEDANDREFUND": {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "NONE": {
        return (
          <span style={{ color: "#008000" }}>
            <b> {status}</b>
          </span>
        );
      }
      default:
        return;
    }
  };
  const CancellationPolicy = (data) => {
    const policyStringArray = data.split(";");
    return (
      <div className="cancellation-wrapper">
        <p className="policytitle">Cancellation Policy</p>
        <ul>
          <li>
            <p>
              <strong> Cancellation time </strong>
            </p>
            <p>
              <strong>Cancellation charges</strong>
            </p>
          </li>
          {policyStringArray.map((data, index) => {
            let val = data.split(":");
            if (index === 0) {
              return (
                <li>
                  <p>{`With in ${val[1]} Hrs`} </p>
                  <p>{`${val[2]}%`}</p>
                </li>
              );
            } else if (index === policyStringArray.length - 1) {
              return (
                <li>
                  <p>{`Above ${val[0]} Hrs`} </p>
                  <p>{`${val[2]}%`}</p>
                </li>
              );
            } else {
              return (
                <li>
                  <p>{`Between ${val[0]} to ${val[1]} Hrs`} </p>
                  <p>{`${val[2]}%`}</p>
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  };

  // console.log(ticketData);

  return (
    <>
      <span className="tic-gredient-f"></span>

      <Layout className="cms-pages-width ourteam-bg">
        <Row>
          <Col md={24} xs={24}>
            <Row>
              <Col md={24} xs={24}>
                <p className="tic-city-name-d2">
                  {/* {ticketData?.bus.src} To {ticketData?.bus.dst} */}
                  {ticketData?.sourceName} <i
                    class="fa fa-long-arrow-right"
                    aria-hidden="true"
                  ></i> {ticketData?.destinationName}
                </p>
                <Card className="card-tic-f3 outer-ticket-box" >
                  <div className="outer-box">
                    <div className="inner-box-3" >
                      <div className="class-for-details">
                        <span>
                          <strong> DETAILS</strong>
                        </span>
                      </div>
                      <div className="class-for-tickitid">
                        <span>
                          <strong>Ticket No:</strong> {ticketData?.ticketId ?? ''}
                        </span>
                      </div>
                    </div>


                    <div className="mainbox-fro-all">

                      <div className="inner-box1">
                        <div className="inner-box2-1">
                          <span >  FROM &nbsp;&nbsp; - &nbsp;&nbsp;TO</span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            TIME:
                          </span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            PNR:
                          </span>
                        </div>
                        <div className="inner-box2-1">
                          Booking RefNo:
                        </div>
                      </div>



                      <div className="inner-box2">
                        <div className="inner-box2-1">
                          <span className="p-tag-use"> {ticketData?.sourceName} <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i>{ticketData?.destinationName} </span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            {ticketData.departureTime}
                          </span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            {ticketData.pnr}
                          </span>
                        </div>
                        <div className="inner-box2-1">
                          {ticketData?.referenceNumber}
                        </div>
                      </div>
                    </div>

                    <div className="mainbox-fro-all">
                      <div className="inner-box1">
                        <div className="inner-box2-1">
                          <span>Status:</span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            Operator:
                          </span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            Seat(s):
                          </span>
                        </div>
                        <div className="inner-box2-1">
                          Payment Status:
                        </div>
                      </div>


                      <div className="inner-box2">
                        <div className="inner-box2-1">
                          <span>{getStatus(ticketData?.status)}</span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            <span>{ticketData?.operatorName}</span>
                          </span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            {ticketData?.passangers?.length}
                          </span>
                        </div>
                        <div className="inner-box2-1">
                          <p>Paid</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </Card>
                {/* 
                  <div className="city-from-d2">
                    <div className="city-f3">
                      <p>{ticketData?.sourceName}</p>
                      <span>{ticketData?.operatorName}</span>
                    </div>
                    <div className="city-f3 px-2">
                      <p>
                        <i
                          class="fa fa-long-arrow-right"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </div>
                    <div className="city-f3">
                      <p>{ticketData?.destinationName}</p>
                      <span> */}
                {/* {ticketData.tripType == "TwoWay"
                          ? "RoundTrip"
                          : "Oneway"} */}
                {/* </span>
                    </div>
                    <div className="city-f3 last-date-d3"> */}
                {/* <span>
                        {moment(ticketData?.bus.timeD).format(dateFormat)}
                      </span> */}
                {/* <span>
                        {ticketData.departureTime}
                      </span>
                    </div>
                    <div className="city-f3 last-date-d3">
                      <span>
                        PNR: {ticketData.pnr}
                      </span>
                    </div>
                    <div className="city-f3 last-date-d3">
                      <span>
                        Booking RefNo: {ticketData?.referenceNumber}
                      </span>
                    </div>
                    <div className="city-f3 last-date-d3">
                      <span>
                        Ticket No: {ticketData?.ticketId ?? ''}
                      </span>
                    </div>
                  </div> */}

                {/* <Row className="row-top-f4"> */}
                {/* <Col md={5} xs={24} className="city-f4">
                      <p>{ticketData?.pnr}</p>
                      <span>PNR/Booking No</span>
                    </Col> */}
                {/* <Col md={5} xs={24} className="city-f4">
                      <p>{ticketData?.bookingRefNo}</p>
                      <span>Ticket Ref Number</span>
                    </Col> */}
                {/* <Col md={8} xs={24} className="city-f4">
                      <p */}
                {/* //   className="cnfrom-f3"
                      // className={ */}
                {/* //   ticketData.BookingStatus === "CANCELLATIONREQUESTED"
                      //     ? "colored-text"
                      //     : "confirmed"
                      // }
                      > */}
                {/* {getStatus(ticketData?.status)}
                      </p>
                      <span>Status</span>
                    </Col>
                    <Col md={8} xs={24} className="city-f4">
                      <p>{ticketData?.passangers?.length}</p>
                      <span>Seat(s)</span>
                    </Col>
                    <Col md={8} xs={24} className="city-f4">
                      <p>Paid</p>
                      <span>Payment Status</span>
                    </Col>
                  </Row> */}


                <Card className="card-tic-f1">
                  <div className="outer-box">
                    <div className="inner-box-3" >
                      <p><strong>Bus Details:&nbsp;&nbsp;</strong><span>
                        Here are the details of your bus from{" "}
                        {ticketData?.sourceName} to {ticketData?.destinationName} on{" "}
                        {ticketData?.departureTime}
                      </span> </p>
                    </div>

                    <div className="mainbox-fro-all">

                      <div className="inner-box1">
                        <div className="inner-box2-1">
                          <span >Bus Type:</span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            Departure Time:
                          </span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            Arrival Time:
                          </span>
                        </div>

                      </div>



                      <div className="inner-box2">
                        <div className="inner-box2-1">
                          <span>{ticketData?.busType}</span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            <span>{ticketData?.departureTime}</span>
                          </span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            <span>{ticketData?.arrivalTime}</span>
                          </span>
                        </div>

                      </div>
                    </div>
                  </div>
                </Card>


                <Card className="card-tic-f3">
                  <div className="outer-box">
                    <div className="inner-box-3" >
                      <p><strong>Boarding Point: &nbsp;&nbsp;</strong><span>
                        {ticketData?.sourceName} to {ticketData?.destinationName} on{" "}
                        {ticketData?.departureTime}
                      </span> </p>
                    </div>

                    <div className="mainbox-fro-all">

                      <div className="inner-box1">
                        <div className="inner-box2-1">
                          <span >Address:</span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            LandMark:
                          </span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            Location:
                          </span>
                        </div>
                        <div className="inner-box2-1">
                          Time:
                        </div>
                      </div>



                      <div className="inner-box2">
                        <div className="inner-box2-1">
                          <span>{ticketData?.boardingPoint?.address}{ticketData?.boardingPoint?.phone ? " (" + ticketData?.boardingPoint?.phone + ")" : ""}</span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            <span>{ticketData?.boardingPoint?.landMark}</span>
                          </span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            <span>{ticketData?.boardingPoint?.location}</span>
                          </span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            <span>{moment(ticketData?.boardingPoint?.time).format("h:mm A")}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>

                <Card className="card-tic-f3">


                  <div className="outer-box">
                    <div className="inner-box-3" >
                      <p><strong>Dropping Point: &nbsp;&nbsp;</strong><span>
                        {ticketData?.sourceName} to {ticketData?.destinationName} on{" "}
                        {ticketData?.arrivalTime}
                      </span> </p>
                    </div>

                    <div className="mainbox-fro-all">

                      <div className="inner-box1">
                        <div className="inner-box2-1">
                          <span >Address:</span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            LandMark:
                          </span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            Location:
                          </span>
                        </div>
                        <div className="inner-box2-1">
                          Time:
                        </div>
                      </div>



                      <div className="inner-box2">
                        <div className="inner-box2-1">
                          {ticketData?.droppingPoint?.address != "" ?
                            <span>{ticketData?.droppingPoint?.address} {ticketData?.droppingPoint?.phone ? " (" + ticketData?.droppingPoint?.phone + ")" : ""}</span> :
                            <span>{ticketData?.destinationName}</span>}
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            {ticketData?.droppingPoint?.landMark != "" ?
                              <span>{ticketData?.droppingPoint?.landMark}</span> : <span>{ticketData?.destinationName}</span>
                            }
                          </span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            
                            <span>{ticketData?.droppingPoint?.location ?? ""}</span>
                          </span>
                        </div>
                        <div className="inner-box2-1">
                          <span>
                            <span>{moment(ticketData?.droppingPoint?.time).format("h:mm A") && moment(ticketData?.arrivalTime).format("h:mm A")}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>





                {/* <Row className="info-flight-t22">
                    <Col md={24} xs={24} className="city-tt-f4">
                      <p>Bus Details</p> */}
                {/* <span>
                        Here are the details of your bus from{" "}
                        {ticketData?.bus.src} to {ticketData?.bus.dst} on{" "}
                        {moment(ticketData?.bus.timeD).format(dateFormat)}
                      </span> */}
                {/* <span>
                        Here are the details of your bus from{" "}
                        {ticketData?.sourceName} to {ticketData?.destinationName} on{" "}
                        {ticketData?.departureTime}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={8} xs={24} className="city-tt-f5">
                      <div className="logo-f3-text"> */}
                {/* <p>
                          <img
                            className=""
                            src={
                              require("../../assets/images/vistara.png").default
                            }
                            alt=""
                          />
                        </p> */}
                {/* <div className="sg-logo-flight">
                          <p>Operator</p>
                          <span> {ticketData?.operatorName}</span>
                        </div>
                      </div>
                    </Col>
                    <Col md={16} xs={24} className="city-tt-f4">
                      <div className="city-from-d3"> */}
                {/* <div className="city-f55">
                          <p>{ticketData?.bus.src}</p>
                          <span>
                            {moment(ticketData?.bus.timeD).format(dateFormat)}
                          </span>
                        </div> */}
                {/* <div className="city-f55">
                          <p>{ticketData?.sourceName}</p>
                          <span>
                            {ticketData?.departureTime}
                          </span>
                        </div>
                        <div className="city-f55">
                          <p className="dotted-line-flight"></p>
                          <span> */}
                {/* {calculateDuration(
                              ticketData.ArrivalTime,
                              ticketData.departureTime
                            )} */}
                {/* </span>
                        </div> */}
                {/* <div className="city-f55">
                          <p>{ticketData?.bus.dst}</p>
                          <span>
                            {moment(ticketData?.bus.timeA).format(dateFormat)}
                          </span>
                        </div> */}
                {/* <div className="city-f55">
                          <p>{ticketData?.destinationName}</p>
                          <span>
                            {ticketData?.arrivalTime}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="baggagge-bottom-sec">
                    <Col md={6} xs={24} className="city-tt-f6">
                      <div className="baggagge-f4">
                        <div className="allowance-baggage">
                          <p>Bus Type</p> */}
                {/* <span>{ticketData?.bus.name}</span> */}
                {/* <span>{ticketData?.busType}</span>
                        </div>
                      </div>
                    </Col>
                    <Col md={6} xs={24} className="city-tt-f6">
                      <div className="baggagge-f4">
                        <div className="allowance-baggage">
                          <p>Departure Time</p> */}
                {/* <span>
                            {moment(ticketData?.bus.timeD).format(dateFormat)}
                          </span> */}
                {/* <span>{ticketData?.departureTime}</span>
                        </div>
                      </div>
                    </Col>
                    <Col md={6} xs={24} className="city-tt-f6">
                      <div className="baggagge-f4">
                        <div className="allowance-baggage">
                          <p>Boarding Point</p>
                          {/* <span>{ticketData?.bus.src}</span> */}
                {/* <span>{ticketData?.boardingPoint?.location}({ticketData?.boardingPoint?.time})</span>
                        </div>
                      </div>
                    </Col>
                    <Col md={6} xs={24} className="city-tt-f6">
                      <div className="baggagge-f4">
                        <div className="allowance-baggage">
                          <p>Droping Point </p> */}
                {/* <span>{ticketData?.bus.dst}</span> */}
                {/* <span>{ticketData?.droppingPoint?.location}({ticketData?.droppingPoint?.time})</span>
                        </div>
                      </div>
                    </Col>
                  </Row> */}

                <Card className="card-tic-f2">
                  <div className="outer-box">
                    <div className="inner-box-3" >
                      <p><strong>Contact Details</strong>  </p>
                    </div>

                    <table className="manual-table-css">
                      <tr className="manual-table-topcolor">
                        <th>Name</th>
                        <th>Mobile Number</th>
                        <th>Email</th>
                      </tr>
                      <tr>
                        <td>{ticketData?.passangers[0].titles}{" "}
                          {ticketData?.passangers[0].firstName}{" "}
                          {ticketData?.passangers[0].lastName}</td>
                        <td>{ticketData?.mobile}</td>
                        <td>{ticketData?.emailId}</td>
                      </tr>
                    </table>
                  </div>
                </Card>


                {/* <Row>
                    <Col md={20} xs={24} className="city-tt-f4">
                      <p>Contact Details</p>
                    </Col> */}
                {/* <Col md={4} xs={24} className="modify-contact-btn">
                        <Button className="tic-btn-contact2">Modify</Button>
                                    </Col> */}
                {/* </Row>

                  <Row className="details-airlines-contact">
                    <Col md={12} xs={24} className="contact-person-d4">
                      <p>Name</p>
                      <span>
                        {ticketData?.passangers[0].titles}{" "}
                        {ticketData?.passangers[0].firstName}{" "}
                        {ticketData?.passangers[0].lastName}
                      </span>
                      <p>Email</p>
                      <span>{ticketData?.emailId}</span>
                    </Col>
                    <Col md={12} xs={24} className="contact-person-d4">
                      <p>Mobile Number</p> */}
                {/* <span>{ticketData?.contact.phone}</span> */}
                {/* <span>{ticketData?.mobile}</span> */}
                {/* <p>Home Address</p>
                      <span>
                        {ticketData.passengers[0].address},{" "}
                        {ticketData.passengers[0].passengerNationality}
                      </span> */}
                {/* </Col>
                  </Row> */}

                <Card className="card-tic-f2">

                  <div className="outer-box">

                    <div className="inner-box-3" >
                      <p><strong>Passengers Details</strong>  </p>
                    </div>

                    <Table pagination={false} scroll={{ x: false }} columns={passengersdetailscolumn} dataSource={ticketData.passangers} />

                  </div>
                </Card>


                <Card className="card-tic-f2">
                  <div className="inner-box-for-details">
                    <div className="fare-total-gst" >
                      <div style={{ textAlign: "center" }}><p><strong>Fare Details</strong></p></div>
                      <Row className="details-airlines-contact">
                        <Col md={10} xs={12} className="contact-person-d4">
                          <p>Base Fare</p>
                        </Col>
                        <Col md={6} xs={12} className="contact-person-d4">
                          {/* <span>₹ {baseFare}</span> */}
                          <span>₹ {baseFare}</span>
                        </Col>
                      </Row>
                      <Row className="details-airlines-contact">
                        <Col md={10} xs={12} className="contact-person-d4">
                          <p> Taxes:</p>
                        </Col>
                        <Col md={4} xs={12} className="contact-person-d4">
                          <span>₹ {taxes}</span>
                        </Col>
                      </Row>
                      <Row className="details-airlines-contact">
                        <Col md={10} xs={12} className="contact-person-d4">
                          <p>Service Charges</p>
                        </Col>
                        <Col md={4} xs={12} className="contact-person-d4">
                          <span>₹ {serviceCharge}</span>
                        </Col>
                      </Row>
                      <Row className="details-airlines-contact">
                        <Col md={10} xs={12} className="contact-person-d4">
                          <p>GST</p>
                        </Col>
                        <Col md={4} xs={12} className="contact-person-d4">
                          <span>₹ {gst}</span>
                        </Col>
                      </Row>
                      <Row className="details-airlines-contact">
                        <Col md={10} xs={12} className="contact-person-d4">
                          <p>Convenience Fee</p>
                        </Col>
                        <Col md={4} xs={12} className="contact-person-d4">
                          <span>₹ {convamount}</span>
                        </Col>
                      </Row>
                      {/* <Row className="details-airlines-contact">
                    <Col md={18} xs={12} className="contact-person-d4">
                      <p>Discount</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                      <span>
                        ₹{" "}
                        {!ticketData?.promoData.Discount
                          ? "0.00"
                          : ticketData?.promoData.tripType === "twoWay"
                          ? Number(
                              Number(ticketData?.promoData.Discount) / 2
                            ).toFixed(2)
                          : Number(ticketData?.promoData.Discount).toFixed(2)} 
                      </span>
                    </Col>
                  </Row> */}
                      <Row className="details-airlines-contact">
                        <Col md={10} xs={12} className="contact-person-d4">
                          <p>Total</p>
                        </Col>
                        <Col md={6} xs={12} className="contact-person-d4">
                          <span>₹ {grandTotal}</span>
                        </Col>
                      </Row>

                    </div>

                    <div className="fare-total-gst1" >
                      <div style={{ textAlign: "center" }}><p><strong>Cancellation Policy</strong></p></div>
                      <div> <p>{ticketData?.partialCancellationAllowed == false && ticketData?.passangers?.length > 1 ? "Partial Cancellation is Not allowed" : null}</p>
                      </div>
                      <Row>
                        <Col md={20} xs={24} className="city-tt-f4">
                          {/* <p>Terms and Conditions</p> */}
                          {CancellationPolicy(ticketData?.cancellationPolicy)}
                        </Col>
                      </Row>
                    </div>
                  </div>

                </Card>
                {/* <Card className="card-tic-f2">
                  <Row>
                    <Col md={20} xs={24} className="city-tt-f4">
                      {/* <p>Terms and Conditions</p> */}
                {/* {CancellationPolicy(ticketData?.cancellationPolicy)} */}
                {/* </Col>
                  </Row>  */}

                {/* <Row className="details-airlines-contact booking-flight-details-info pt-0">
                    <Col md={24} xs={24} className="contact-person-d4">
                      <div className="termCon_wrapper">
                        <p>
                          1. ShopYourTrip* is ONLY a bus ticket agent. It does
                          not operate bus services of its own. In order to
                          provide a comprehensive choice of bus operators,
                          departure times and prices to customers, it has tied
                          up with many bus operators.ShopYourTrip advice to
                          customers is to choose bus operators they are aware of
                          and whose service they are comfortable with{" "}
                        </p>
                        <div className="description">
                          <p className="title">
                            ShopYourTrip responsibilities include:
                          </p>
                          <p>
                            (1) Issuing a valid ticket (a ticket that will be
                            accepted by the bus operator) for its network of bus
                            operators
                          </p>

                          <p>
                            (2) Providing refund and support in the event of
                            cancellation
                          </p>
                          <p>
                            (3) Providing customer support and information in
                            case of any delays / inconvenience
                          </p>
                          <p className="title">
                            {" "}
                            ShopYourTrip responsibilities do NOT include:
                          </p>
                          <p>
                            (1) The bus operator's bus not departing / reaching
                            on time.
                          </p>
                          <p> (2) The bus operator's employees being rude. </p>
                          <p>
                            (3) The bus operator's bus seats etc not being up to
                            the customer's expectation.
                          </p>
                          <p>
                            (4) The bus operator canceling the trip due to
                            unavoidable reasons.
                          </p>
                          <p>
                            (5) The baggage of the customer getting lost /
                            stolen / damaged.
                          </p>
                        </div>
                        <p>
                          2. The departure time mentioned on the ticket are only
                          tentative timings. However the bus will not leave the
                          source before the time that is mentioned on the
                          ticket.
                        </p>
                        <p>
                          3. Passengers are required to furnish the following at
                          the time of boarding the bus:
                        </p>
                        <div className="description">
                          <p>
                            Passengers are required to furnish the following at
                            the time of boarding the bus:
                          </p>

                          <p>
                            (1) A copy of the ticket (A print out of the ticket
                            or the print out of the ticket e-mail).
                          </p>

                          <p> (2) A valid identity proof </p>
                          <p>
                            Failing to do so, they may not be allowed to board
                            the bus.
                          </p>
                        </div>
                        <p>
                          4. In case one needs the refund to be credited back to
                          his/her bank account, please write your cash coupon
                          details to * The discount
                        </p>

                        <p>
                          5. Delivery charges (if any), will not be refunded in
                          the event of ticket cancellation.
                        </p>

                        <p>
                          6. In case a booking confirmation e-mail and sms gets
                          delayed or fails because of technical reasons or as a
                          result of incorrect e-mail ID / phone number provided
                          by the user etc, a ticket will be considered 'booked'
                          as long as the ticket shows up on the confirmation
                          page of ShopYourTrip
                        </p>
                        <p>
                          7. Grievances and claims related to the bus journey
                          should be reported to ShopYourTrip support team within
                          2 days of your travel date.
                        </p>
                         {CancellationPolicy(ticketData?.cancellationPolicy)} 
                      </div>
                    </Col>
                  </Row> */}
                {/* </Card> */}
              </Col>
            </Row>
          </Col>

          <Col md={6} xs={24}></Col>
        </Row>
      </Layout>
    </>
  );
};

export default TicketBus;
